(function(ng) {
	'use strict';

	angular
		.module('fca.translateConfigurator')
		.provider('translateConfigurator', Provider);

	/**
	 * @ngdoc service
	 * @name fca.translateConfigurator.translateConfiguratorProvider
	 * @requires $translateProvider
	 * @description Translate pascalprecht.translate module configuration provider
	 * @example
	 * <pre>
	 * ng
	 *   .module('MyApp', ['fca.translateConfigurator'])
	 *   .config(function(translateConfiguratorProvider) {
	 *     // Use configure and use method to apply translation in application
	 *     translateConfiguratorProvider
	 *       .configure({...options})
	 *       .use('fr_CA');
	 *   });
	 * </pre>
	 */
	function Provider($translateProvider) {
		'ngInject';

		/**
		 * @ngdoc property
		 * @name cfg
		 * @propertyOf fca.translateConfigurator.translateConfiguratorProvider
		 * @type {Object}
		 */
		let cfg = this.cfg = {
			sanitizeStrategy: null,
			availableLanguages: ['fr_CA', 'en_CA'],
			preferredLanguage: 'fr_CA',
			locales: {}
		};

		/**
		 * @ngdoc method
		 * @name configure
		 * @methodOf fca.translateConfigurator.translateConfiguratorProvider
		 * @param  {Object} opts List of options to override default configs
		 * @return {Object}      Instance of daaTranslateProvider (chaining)
		 * @description Override default configuration properties
		 * @example
		 * <pre>
		 * ng
		 *   .module('MyApp', ['fca.translateConfigurator'])
		 *   .config(function(translateConfiguratorProvider) {
		 *     translateConfiguratorProvider.configure({...options});
		 *   });
		 * </pre>
		 */
		this.configure = (opts) => {
			// Override default configuration
			this.cfg = ng.extend({}, ng.copy(cfg), opts);
			cfg = this.cfg;
			// Set all available languages
			$translateProvider.registerAvailableLanguageKeys(this.cfg.availableLanguages);
			// Set sanitize strategy
			$translateProvider.useSanitizeValueStrategy(this.cfg.sanitizeStrategy);
			// Register translations
			$translateProvider.translations(this.cfg.preferredLanguage, this.cfg.locales);
			// Return instance for chaining
			return this;
		};

		/**
		 * @ngdoc
		 * @name use
		 * @methodOf fca.translateConfigurator.translateConfiguratorProvider
		 * @param  {String} pLang Language to use. If not specified use the default preferred language
		 * @return {Object}       Instance of daaTranslateProvider (chaining)
		 * @description Load translate table of content by HTTP request for specified language or config preferredLanguage
		 * @example
		 * <pre>
		 * ng
		 *   .module('MyApp', ['fca.translateConfigurator'])
		 *   .config(function(translateConfiguratorProvider) {
		 *     // If translateConfiguratorProvider not call configure method method use default config
		 *     translateConfiguratorProvider.use('fr_CA');
		 *   });
		 * </pre>
		 */
		this.use = (pLang) => {
			let preferredLanguage = pLang;
			let languages = this.cfg.availableLanguages;
			// Check if pLang is available
			if (preferredLanguage === undefined || languages.indexOf(preferredLanguage) === -1) {
				preferredLanguage = this.cfg.preferredLanguage;
			}

			// Use preferred language (trigger async loading)
			$translateProvider.use(preferredLanguage);

			// Return instance for chaining
			return this.configure({
				preferredLanguage: preferredLanguage
			});
		};

		this.$get = () => {
			/**
			 * @ngdoc method
			 * @name fca.translateConfigurator.service:translateConfigurator
			 * @description Translate configuration service
			 * @return {Object} List of all tables of content + preferredLanguage
			 * @example
			 * <pre>
			 * ng
			 *   .module('MyApp', ['fca.translateConfigurator'])
			 *   .run(function(translateConfigurator) {
			 *      console.log(translateConfigurator.translations());
			 *      console.log(translateConfigurator.preferredLanguage);
			 *   });
			 * </pre>
			 */
			let translations = $translateProvider.translations();
			let preferredLanguage = cfg.preferredLanguage;

			return {
				/**
				 * @ngdoc method
				 * @name translations
				 * @methodOf fca.translateConfigurator.service:translateConfigurator
				 * @description Return all translation table
				 * @return {Object} List of all translations keys/values for the preferredLanguage
				 */
				translations: () => {
					return translations[preferredLanguage];
				},

				/**
				 * @ngdoc property
				 * @name preferredLanguage
				 * @propertyOf fca.translateConfigurator.service:translateConfigurator
				 * @description Preferred language (configuration). Must be fr_CA or en_CA
				 * @type {String}
				 */
				preferredLanguage: preferredLanguage,

				/**
				 * @ngdoc property
				 * @name language
				 * @propertyOf fca.translateConfigurator.service:translateConfigurator
				 * @description Preferred language (configuration) without _CA postfix.
				 * @type {String}
				 */
				language: preferredLanguage.replace('_CA', '')
			};
		};
	}
})(angular);
