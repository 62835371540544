(function () {
	angular
		.module('fca.buyOnlinePersonalInfo', ['ngMessages', 'ngTouch'])
		.directive('fcaBuyOnlinePersonalInfo', FcaBuyOnlinePersonalInfo);

	function FcaBuyOnlinePersonalInfo() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				thankYouUrl: '@',
				brandCode: '@',
				vin: '@',
				globalMessageOneError : '@',
				globalMessageManyErrors : '@',
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlinePersonalInfoCtrl
		};

		function FcaBuyOnlinePersonalInfoCtrl($location, $scope, $rootScope, $element, $http, $window, userLocation, $translate, fcaBuyOnlineLeadService, fcaBuyOnlineService, gtmAnalytics, $filter, personalizationDaaScenario) {
			'ngInject';

			let $ctrl = this;
			$ctrl.phoneRegex = '^\\(?([2-9][0-9]{2})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
			$ctrl.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
			$ctrl.emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

			$ctrl.language = FCA_SITES_CONFIG.language;
			$ctrl.window = angular.element($window);

			$ctrl.formErrors = [];
			$ctrl.loading = false;
			$ctrl.errorMsgVisible = false;

			$ctrl.existAppointment = false;
			$ctrl.leadId = "";
			$ctrl.distance = "";

			let fcaCurrencyWithDollar = $filter('fcaCurrencyWithDollar');

			$ctrl.$onInit = () => {

				// Add the controller instance to the window so that the submit
				// can be called outside the scope of the controller.
				window.FcaBuyOnlinePersonalInfoCtrl = $ctrl;

				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);

				if (checkout.personalInfo) {
					$scope.personalInfo = checkout.personalInfo;
				}

				if (checkout.contactMethod) {
					$scope.contactMethod = checkout.contactMethod;
				} else {
					$scope.contactMethod = 'EMAIL';
				}

				$ctrl.distance = sessionStorage.getItem('distance');
			};

			$ctrl.fieldChanged = () => {
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				checkout.personalInfo = $scope.personalInfo;
				checkout.contactMethod = $scope.contactMethod;
				fcaBuyOnlineService.setInStorage($ctrl.vin, checkout);
			};

			$rootScope.$on('fca-custom-order:custom-order-distance-update', () => {
				$ctrl.$onInit();
			});

			$rootScope.$on('fca-sales-appointment:appointment-is-selected',
				() => {
					$ctrl.existAppointment = true;
				});

			$rootScope.$on('fca-sales-appointment:appointment-is-canceled',
				() => {
					$ctrl.existAppointment = false;
				});

			$rootScope.$on('fca-contact-a-dealer:appointment-is-confirmed',
				() => {
					fcaBuyOnlineLeadService.triggerSuccess();
				});

			$rootScope.$on('fca-buy-online:last-step-click', () => {
				$ctrl.submitLead();
			});

			$ctrl.submitLeadAjax = () => {
				$ctrl.submitLead();
			};

			$ctrl.submitLead = () => {
				$ctrl.errorMsgVisible = false;
				$ctrl.errorCount = 0;
				$scope.submitted = true;

				$("#BOFormErrors").html('');
				$(".BO-form-input-group-input").removeClass('BO-form-error');

				$("#submit-lead-button").addClass('btn-spinner');

				let lead = $ctrl.buildFieldArrayFromForm();

				fcaBuyOnlineLeadService.submitLead(
					$ctrl.brandCode,
					$ctrl.language,
					$ctrl.vin,
					lead,

					function (response) {
						// DAA Personalization destroy the local storage scenario
						personalizationDaaScenario.destroyWithVin($ctrl.vin);

						if (!response.success) {
							$ctrl.handleFormErrors(response.message, response.fieldErrors);
							$rootScope.$broadcast('fca-buy-online:enable-last-step-btn');
						} else {
							// Send confirmation emails
							let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);

							var emailData = {
								language: window.FCA_SITES_CONFIG.language,
								contactMethod: checkout.contactMethod,
								firstname: checkout.personalInfo.firstname,
								lastname: checkout.personalInfo.lastname,
								postalCode: checkout.personalInfo.postalCode,
								email: checkout.personalInfo.email,
								phone: checkout.personalInfo.phone,
								acode: $("#lead-form-id").children('input[name="acode"]').val(),
								year: $("#lead-form-id").children('input[name="year"]').val(),
								nameplateCode: $("#lead-form-id").children('input[name="nameplateCode"]').val(),
								trimDescription: $("#lead-form-id").children('input[name="trimDescription"]').val(),
								vin: $("#lead-form-id").children('input[name="vin"]').val(),
								dealerId: $("#lead-form-id").children('input[name="dealerIds"]').val(),
								brandCode: $("#lead-form-id").children('input[name="brandCode"]').val(),
								modelYearId: $("#lead-form-id").children('input[name="modelYearId"]').val(),
								irisExteriorImage: $("#lead-form-id").children('input[name="irisExteriorImage"]').val(),
								jellyPath: $("#lead-form-id").children('input[name="jellyPath"]').val(),
								msrp: checkout.msrp,
								dealerPrice: checkout.dealerPrice,
								netAmount: checkout.netAmount,
								downPayment: checkout.downPayment,
								tradeOwed: checkout.tradeOwed,
								totalDiscount: checkout.totalDiscount,
								financeMode: checkout.financeMode,
								financeNet: checkout.financeNet,
								financePayment: checkout.financePayment,
								financeRate: checkout.financeRate,
								financeRateEir: checkout.financeRateEir,
								financeTerm: checkout.financeTerm,
								frequency: checkout.frequency,
								leaseMileage: checkout.leaseMileage,
								leaseNet: checkout.leaseNet,
								leasePayment: checkout.leasePayment,
								leaseRate: checkout.leaseRate,
								leaseRateEir: checkout.leaseRateEir,
								leaseResidualValue: checkout.leaseResidualValue,
								leaseTerm: checkout.leaseTerm
							};

							if (checkout.leadReferenceNumber && checkout.leadReferenceNumber != null) {
								emailData.creditApplication = true;
							}

							if (checkout.learnMoreAboutDeliveryOptions && checkout.learnMoreAboutDeliveryOptions == true) {
								emailData.deliveryOption = true;
							}

							if (checkout.selectedAccessories) {
								let accessories = [];
								for (let [key, value] of Object.entries(checkout.selectedAccessories)) {
									if (value) {
										accessories.push(key);
									}
								}
								emailData.accessories = accessories;
							}

							if (checkout.selectedServices) {
								let services = [];
								for (let [key, value] of Object.entries(checkout.selectedServices)) {
									if (value) {
										services.push(key);
									}
								}
								emailData.services = services;
							}

							if (checkout.kbb
								&& checkout.kbb.tradeInValue) {
								var tradeIn = {
									year: checkout.kbb.year.Year,
									brand: checkout.kbb.brand.Name,
									model: checkout.kbb.model.Name,
									trim: checkout.kbb.vehicle.label,
									grade: checkout.kbb.grade.Description,
									mileage: checkout.kbb.mileage,
									value: checkout.tradeInValue
								}

								if (checkout.kbb.options) {
									tradeIn.options = checkout.kbb.options.map(
										(option) => option.Name);
								}

								emailData.tradeIn = tradeIn;
							}

							if (response.campaignControlNumberResponse != null
								&& response.campaign != null) {
								emailData.campaignControlNumber = response.campaignControlNumberResponse.code;
								emailData.campaignDescription = (window.FCA_SITES_CONFIG.language
									=== 'en') ? response.campaign.descriptionEn
									: response.campaign.descriptionFr;
								emailData.campaignDiscountAmount = response.campaign.discount;

								//send it to buy online thank you component
								$rootScope.$broadcast('fca-buy-online:campaignControlNumber',{
									campaignControlNumber: response.campaignControlNumberResponse.code,
								});
							}

							$.ajax({
								url: '/api/buy/emails',
								type: 'post',
								data: JSON.stringify(emailData),
								dataType: 'json',
								contentType: 'application/json',
								success: function (data) {
								}
							});

							if($ctrl.existAppointment) {
								$ctrl.leadId = response.leadResponse.leadId;
								$rootScope.$broadcast('fca-buy-online:submit-form',{
									leadId: $ctrl.leadId,
								});
							} else {
								fcaBuyOnlineLeadService.triggerSuccess();
							}

							$rootScope.$broadcast('fca-buy-online:enable-last-step-btn');
						}
					});
			};

			$ctrl.buildFieldArrayFromForm = () => {
				let provinceCode = userLocation.getLocationProvince();

				let lead = $("#lead-form-id").serializeArray();
				$(lead).each(function (i, field) {
					if (field.name === "leadType") {
						if ($ctrl.existAppointment) {
							field.value = "appointment";
						}
					}
				});

				lead.push({
					'name': 'provinceCode',
					'value': provinceCode,
				});

				// If we have an invalid postal code we need to remove
				// it and provoke and invalid response from server.
				// In most cases we let the invalid postal codes pass
				// because we need to keep as many leads as possible.
				// Server will return a invalid field error for postal code
				// It will be empty...

				let postalCodeValue = $scope.personalInfo ? $scope.personalInfo.postalCode : null;

				if (postalCodeValue !== null && postalCodeValue !== '') {
					let postalCodeValid = $ctrl.postalCodeRegex.test(postalCodeValue);

					if (!postalCodeValid) {
						let indexPostalCodeField = lead.findIndex(field => field.name === 'postalCode');
						if (indexPostalCodeField !== -1) {
							lead.splice(indexPostalCodeField, 1);
						}
					}
				}

				return lead;
			};

			$ctrl.handleFormErrors = (message, fieldErrors) => {
				$ctrl.formErrors = fieldErrors;

				$("#submit-lead-button").removeClass('btn-spinner');

				$ctrl.addFieldError('firstname');
				$ctrl.addFieldError('lastname');
				$ctrl.addFieldError('email');
				$ctrl.addFieldError('phone');
				$ctrl.addFieldError('cellphone');
				$ctrl.addFieldError('postalCode');

				// Show Global Error Message
				$ctrl.showGlobalErrorMessage();
			};

			$ctrl.showGlobalErrorMessage = () => {
				const formErrorsArray = $(".BO-form-error");
				$ctrl.errorCount = formErrorsArray.length;
				$ctrl.errorMsgVisible = $ctrl.errorCount > 0;

				if ($ctrl.errorMsgVisible) {
					let globalMessageKey = ($ctrl.errorCount == 1)? $ctrl.globalMessageOneError : $ctrl.globalMessageManyErrors;
					let globalMessage = $translate.instant(globalMessageKey, { errorCount: $ctrl.errorCount });
					$("#BOFormErrors").append(globalMessage);
				}

				$scope.$apply();
				$("#BOFormErrors").focus();
			};

			$ctrl.isNumericInput = (event) => {
				const key = event.keyCode;
				return ((key >= 48 && key <= 57) || // Allow Number line
					(key >= 96 && key <= 105) // Allow NumPad
				);
			};

			$ctrl.isModifierKey = (event) => {
				const key = event.keyCode;
				return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
					(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
					(key > 36 && key < 41) || // Allow left, up, right, down
					(
						// Allow Ctrl/Command + A,C,V,X,Z
						(event.ctrlKey === true || event.metaKey === true) &&
						(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
					)
			};

			$ctrl.enforceFormat = (event) => {
				if (!$ctrl.isNumericInput(event) && !$ctrl.isModifierKey(event)) {
					event.preventDefault();
				}
			};

			$ctrl.formatPhoneNumber = (event) => {
				if ($ctrl.isModifierKey(event)) {
					return;
				}

				let target = event.target;
				const input = event.target.value.replace(/\D/g, '').substring(0, 10);
				const zip = input.substring(0, 3);
				const middle = input.substring(3, 6);
				const last = input.substring(6, 10);

				if (input.length > 6) {
					target.value = "(" + zip + ")" + middle + "-" + last;
				} else if (input.length > 3) {
					target.value = "(" + zip + ")" + middle;
				} else if (input.length > 0) {
					target.value = "(" + zip;
				}
				$(target).change();
			};

			$ctrl.addFieldError = (fieldId) => {
				if ($ctrl.formErrors[fieldId]) {
					$('#' + fieldId).addClass('ng-invalid ng-touched BO-form-error').next('.BO-errors').removeClass('ng-hide');
				}
			};
		}
	}
})();
