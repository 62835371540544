/* eslint-disable no-invalid-this */
(function () {
    angular
        .module('fca.personalization.sniOffer')
        .component('fcaPersonalizationSniOffer', {
            controller: personalizationSniOffer,
            controllerAs: '$SniCtrl',
            templateUrl: '/personalization/scenario-1-2/sni-offer.html',
            bindings: {
                sniDetailsUrl: '@',
                location: '<',
                cityBackgroundUrl: '@',
                analyticscategory: '@',
                analyticsid: '@'
            }
        });

    function personalizationSniOffer($scope, $rootScope, configService, $compile, $http, userAccountService, gtmAnalytics, scenarioOrchestrator) {
        'ngInject';

        let $SniCtrl = this;
        let dialogRef;

        $SniCtrl.MQ_JELLY = $rootScope.FCA_MQ_JELLY;
        $SniCtrl.language = FCA_SITES_CONFIG.language;
        $SniCtrl.sniOfferData = {};

        $SniCtrl.miniNav = document.getElementById('zones_body_blocks_mini-nav');
        $SniCtrl.modelYearToggle = document.getElementById('zones_body_blocks_model-year-toggle');
        $SniCtrl.footer = document.querySelector('.footer');
        $SniCtrl.savedSvg = document.getElementById('addToSaved');
        $SniCtrl.nav = document.querySelector('.layout-main-nav');
        $SniCtrl.modal = document.querySelector('.sni-offer-modal-hidden');

        $SniCtrl.disclaimers = {
            netAmount: angular.element('[data-disclaimer-code="leg-net-amount"]').html(),
            msrp: angular.element('[data-disclaimer-code="leg-msrp-sni"]').html(),
            finance: angular.element('[data-disclaimer-code="leg-financing"]').html(),
            lease: angular.element('[data-disclaimer-code="leg-leasing"]').html(),
            dealerPricing: angular.element('[data-disclaimer-code="leg-dealer-pricing"]').html()
        };

        //analytics tagging value for the popup
        $SniCtrl.analyticsObject = [
            "perso-incentive-bpevents-mysaved-save-cta",
            "perso-incentive-bpevents-mysaved-exit"
        ];

        $SniCtrl.$onInit = () => {
            // Test jeep URL /data/inventories/jeep/search/personalization/vehicle?latitude=46.8259601&longitude=-71.2352226&modelYearId=20463&trimGroupCode=SRT&colorCode=PXJ
            // Test alfa URL /data/inventories/jeep/search/personalization/vehicle?latitude=43.7843&longitude=-79.5491&modelYearId=20282&trimGroupCode=Quadrifoglio&colorCode=PBX
            scenarioOrchestrator.registerScenario('fcaPersonalizationScenario_1_2');

            angular.element(document).ready(() => {
                let localStorageName = getLocalStorageName('fcaPersonalizationScenario_1_2');
                let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
                let trigger = scenarioOrchestrator.canShowModal('fcaPersonalizationScenario_1_2');
                console.debug('Trigger scenario 1.2', trigger);
                if (trigger) {
                    let latitude = $SniCtrl.location.latitude;
                    let longitude = $SniCtrl.location.longitude;
                    let modelYearId = (window.FCA_SITES_CONFIG.modelYearIds && window.FCA_SITES_CONFIG.modelYearIds.length > 0) ? window.FCA_SITES_CONFIG.modelYearIds[0] : "";
                    let trimGroupCode = fcaPersonalizationObject.trimGroupCodeScenario_1_2;
                    let colorCode = fcaPersonalizationObject.colorCodeScenario_1_2;
                    let url= `/data/inventories/jeep/search/personalization/vehicle?latitude=${latitude}&longitude=${longitude}&modelYearId=${modelYearId}&trimGroupCode=${trimGroupCode}&colorCode=${colorCode}`;
                    $http.get(url).then(success, error);
                    function success(response) {

                        $SniCtrl.sniData = response.data;
                        showModal();
                        onSuccess();
                        $SniCtrl.markFavoriteVehicles();
                        $SniCtrl.strippedDealerLink = response.data.dealer.url.replace("https://","");
                        //double for because IE11 doesn't like double forEach
                        for(let i = 0; i < $SniCtrl.sniData.jellies.length; i++) {
                            for(let j = 0; j < $SniCtrl.sniData.jellies[i].jellies.length; j++) {
                                if($SniCtrl.sniData.jellies[i].jellies[j].resolutionCode === "DESKTOP_RETINA") {
                                    $SniCtrl.jellyPath = $SniCtrl.sniData.jellies[i].jellies[j].path;
                                }
                            }
                        }
                        focusTrapActive();
                    }
                    function error(error) {
                        console.error('Response error', error);
                        if (error.status == 404) {
                            onNotFound();
                        } else {
                            onFailure();
                        }
                    }
                }
            })

            $rootScope.$on('fca-modal-is-closed', ()=>{
                if(!UA.isUserLoggedIn()) {
                    emptyFavoriteHeart();
                }
            });

            $SniCtrl.savedSvg.addEventListener("click",() => {
                if ($SniCtrl.savedSvg.querySelector('path').style.fill !== 'black') {
                    fillFavoriteHeart();
                } else {
                    if ($SniCtrl.savedSvg.querySelector('path').style.fill === "black") {
                        emptyFavoriteHeart();
                    }
                }

            });
        };

        $SniCtrl.addToFavorites = (vehicle, event) => {
            if (userAccountService.isLibraryAvailable()) {
                if (UA.isUserLoggedIn()) {
                    if (vehicle.favorited === true) {
                        //backend call to remove favorite
                        UA.removeInventoryVehicle(vehicle.favoriteId).then(
                            () => $scope.$apply(() => vehicle.favorited = false)
                    );
                    } else {
                        userAccountService.addToFavorites(vehicle).then((response) => {
                            //need $scope.$apply for update display; without, need to click outside the container to update the heart
                            $scope.$apply(() => {
                                vehicle.favorited = true;
                                vehicle.favoriteId = response.id;
                            });
                        });
                    }
                } else {
                    $SniCtrl.favoritedVehicle = vehicle;
                }
                tagAnalyticsPopup();
            }
        };

        $scope.$on('ua:userSignInSuccess', (event, data) => {

            UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                userAccountService.addToFavorites($SniCtrl.favoritedVehicle).then((response) => {
                //need $scope.$apply for update display; without, need to click outside the container to update the heart
                    $scope.$apply(() => {
                        $SniCtrl.favoritedVehicle.favorited = true;
                        $SniCtrl.favoritedVehicle.favoriteId = response.id;
                    });
                });

                $SniCtrl.markFavoriteVehicles();
            });

        });

        $SniCtrl.markFavoriteVehicles = () => {
            if (UA.isUserLoggedIn()) {
                UA.getInventoryVehicles().then((favoriteVehiclesList) => {
                    let favoriteVehicle = favoriteVehiclesList.find((favorite) => favorite.vin === $SniCtrl.sniData.vin);
                    if (favoriteVehicle !== undefined) {
                        $SniCtrl.sniData.favorited = true;
                        $SniCtrl.sniData.favoriteId = favoriteVehicle.id;
                        fillFavoriteHeart();
                    }
                });
            }
        };

        function onSuccess() {
            let localStorageName = getLocalStorageName('fcaPersonalizationScenario_1_2');
            let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
            setPostQuietThreshold(fcaPersonalizationObject);
            resetLocalStorageScenario_1_2(fcaPersonalizationObject);
            window.localStorage.setItem(localStorageName, JSON.stringify(fcaPersonalizationObject));
        }

        function onFailure() {
            let localStorageName = getLocalStorageName('fcaPersonalizationScenario_1_2');
            let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
            resetLocalStorageScenario_1_2(fcaPersonalizationObject);
            window.localStorage.setItem(localStorageName, JSON.stringify(fcaPersonalizationObject));
        }

        function onNotFound() {
            let localStorageName = getLocalStorageName('fcaPersonalizationScenario_1_2');
            let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
            fcaPersonalizationObject.triggerScenario_1_2 = false;
            setPostQuietThreshold(fcaPersonalizationObject);
            window.localStorage.setItem(localStorageName, JSON.stringify(fcaPersonalizationObject));
        }


        function resetLocalStorageScenario_1_2(fcaPersonalizationObject) {
            fcaPersonalizationObject.triggerScenario_1_2 = false;
            fcaPersonalizationObject.preQuietThresholdScenario_1_2 = null;
        }

        function setPostQuietThreshold(fcaPersonalizationObject) {
            let postQuietDelayInMinutes = window.FCA_SITES_CONFIG.personalizationPostQuietDelay;
            let nowDate = new Date();
            let postQuietThreshold = addMinutes(nowDate, postQuietDelayInMinutes);
            fcaPersonalizationObject.postQuietThresholdScenario_1_2 = postQuietThreshold;
            console.debug('Added scenario 1.2 post threshold:', postQuietThreshold);
        }

        function addMinutes(dt, minutes) {
            return new Date(dt.getTime() + minutes * 60000);
        }


        function showModal() {
            if ($SniCtrl.miniNav) {
                $SniCtrl.miniNav.style.display = 'none';
            }
            if ($SniCtrl.nav) {
                $SniCtrl.nav.style.display = 'none';
            }
            if ($SniCtrl.footer) {
                $SniCtrl.footer.style.display = 'none';
            }
            if ($SniCtrl.modelYearToggle) {
                $SniCtrl.modelYearToggle.style.display = 'none';
            }
            if ($SniCtrl.modal) {
                $SniCtrl.modal.classList.remove('sni-offer-modal-hidden');
                $SniCtrl.modal.classList.add('sni-offer-modal-shown');
            }
            let directionButtons = document.querySelector(".sni-suggestion-dealer-info-links-directions>fca-google-directions-link>a");
            if (directionButtons) {
                directionButtons.addEventListener('click', () => {
                    addTracking("", "perso-incentive-bpevents-dealerinfo-directions");
                });
            }

            document.querySelector('html').classList.add("ngdialog-open");
            document.querySelector('.sni-suggestion-close-dialog').addEventListener('click', closeModal);
            document.querySelector('.sni-offer-modal-overlay').addEventListener('click', closeModal);

            function closeOnEscPress(event) {
                if (event.key === "Escape") {
                    closeModal();
                }
            };
            document.addEventListener("keydown", closeOnEscPress);

            $scope.$on('fca-modal-is-opened', () => {
                document.removeEventListener('keydown', closeOnEscPress);
            });

            $scope.$on('fca-modal-is-closed', () => {
                document.addEventListener('keydown', closeOnEscPress);
            });
        }

        function closeModal() {
            if ($SniCtrl.modal) {
                $SniCtrl.modal.classList.add('sni-offer-modal-hidden');
                $SniCtrl.modal.classList.remove('sni-offer-modal-shown');
            }
            if ($SniCtrl.miniNav) {
                $SniCtrl.miniNav.style.display = 'block';
            }
            if ($SniCtrl.nav) {
                $SniCtrl.nav.style.display = 'block';
            }
            if ($SniCtrl.modelYearToggle) {
                $SniCtrl.modelYearToggle.style.display = 'block';
            }
            if ($SniCtrl.footer) {
                $SniCtrl.footer.style.display = 'block';
            }

            document.querySelector('html').classList.remove("ngdialog-open");

            if(document.querySelector('button.C_USI-popup-close') !== null ) {
                document.querySelector('button.C_USI-popup-close').click();
            }
        }

        function getLocalStorageName(scenarioName) {
            let localStorageModelYearId = (window.FCA_SITES_CONFIG.modelYearIds && window.FCA_SITES_CONFIG.modelYearIds.length > 0) ? window.FCA_SITES_CONFIG.modelYearIds[0] : "";
            let localStorageName = scenarioName + '_' + localStorageModelYearId;
            return localStorageName;
        }

        function fillFavoriteHeart() {
            $SniCtrl.savedSvg.querySelector('path').style.fill="black";
            $SniCtrl.savedSvg.querySelector('path').style.stroke="black";
        }

        function emptyFavoriteHeart() {
            $SniCtrl.savedSvg.querySelector('path').style.stroke="#e4e4e4";
            $SniCtrl.savedSvg.querySelector('path').style.fill="#ededed";
        }
        function tagAnalyticsPopup() {
            setTimeout(() => {
                //order is FB Gmail email postal save exit
                let emailField = document.querySelector(".C_USI-form-row input[type=email]");
                let postalCode = document.querySelector(".C_USI-form-row input[name=postalCode]");
                let saveButton = document.querySelector("#fca-ua-standalone-login-button>button[type=submit]");
                let fbButton = document.querySelector("#fca-ua-facebook-login-button>button[type=button]");
                let fbButtonSpan = document.querySelector("#fca-ua-facebook-login-button>button[type=button]>span");
                let gMailButton = document.querySelector("#fca-ua-google-login-button>button[type=button]");
                let closeCta = document.querySelector(".C_USI-popup-close>i");
                let closeCtaButton = document.querySelector(".C_USI-popup-close");

                closeCtaButton.setAttribute("data-analyticsid","perso-incentive-bpevents-mysaved-exit");
                closeCtaButton.setAttribute("data-analyticsvalue","perso-incentive-bpevents-mysaved-exit");
                closeCta.setAttribute("data-analyticsid","perso-incentive-bpevents-mysaved-exit");
                closeCta.setAttribute("data-analyticsvalue","perso-incentive-bpevents-mysaved-exit");

                let elementArray = [saveButton, closeCta];

                elementArray.forEach( function (element, index) {
                    element.setAttribute('data-analyticsid', $SniCtrl.analyticsObject[index]);
                    element.setAttribute('data-analyticsvalue', $SniCtrl.analyticsObject[index]);
                });

                saveButton.addEventListener('click',() => {
                   if(emailField.value !== "" && postalCode.value !== "") {
                       addTracking("", 'perso-incentive-bpevents-mysaved-email-');
                       addTracking("", 'perso-incentive-bpevents-mysaved-postal-');
                       addTracking("", 'perso-incentive-bpevents-mysaved-submit-');
                   }
                });

                gMailButton.addEventListener('click', ()=> {
                    addTracking("", 'perso-incentive-bpevents-mysaved-savewithgmail');
                });

                fbButtonSpan.addEventListener('click', ()=> {
                    addTracking("", 'perso-incentive-bpevents-mysaved-savewithfacebook');
                });

                $scope.$apply();

            },300);
        }

        /**
         *
         * @param elementToTrack is the element which we want to track
         * @param trackingID is the analyticsID
         */
        function addTracking(elementToTrack, trackingID) {
                gtmAnalytics.trackEvent('event', {
                    category:  `${window.FCA_SITES_CONFIG.pageCode} personalization`,
                    label: `${trackingID}`
                });
        }

        /**
         * focusTrapActive targets first and last cta and set boundaries so Tab-cycle
         * will stay in that range
         */
        function focusTrapActive() {
            if (!dialogRef) {
                setTimeout(() => {
                    const navDialogEl = document.querySelector('.sni-offers-container');
                    const dialogOverlay = document.querySelector('.sni-offer-modal-overlay');
                    if(navDialogEl && dialogOverlay) {
                        dialogRef = new Dialog(navDialogEl, dialogOverlay);
                        dialogRef.addEventListeners(
                            null,
                            'span.sni-suggestion-close-dialog>a'
                        );
                        dialogRef.open();
                    }
                }, 3000);
            }
        };
    };
})();
