(function () {
    angular
        .module('fca.uconnect')
        .component('fcaUconnect', {
            controller: FcaUconnect,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/uconnect.html',
            bindings: {
                screensJson: '<',
                featuresJson: '<',
                showTitle: '<?',
                analyticsCategory: '@',
                analyticsId: '@'
            }
        });

    function FcaUconnect($scope, $element, fcaUconnectService) {
        'ngInject';

        const $ctrl = this;

        $ctrl.isReady = false;

        $ctrl.featureCodeSelected = '';

        $ctrl.featureOverlayIsVisible = false;

        $ctrl.$onInit = () => {

            // On init use the first screen, display it, then place the hotspot about the image height and width
            // Set the hotspots top and left from xPosition and yPosition
            
            if($ctrl.showTitle === undefined) {
                $ctrl.showTitle = true;
            }

            $ctrl.updateScreenData(0);

            $ctrl.featuresList = fcaUconnectService.getFeatureListByHotspotOrder($ctrl.featuresJson, $ctrl.screensJson);

            $ctrl.listView = fcaUconnectService.addTitleToHotspot($ctrl.featuresJson, $ctrl.screensJson);

        };

        $ctrl.updateScreenData = (screenIndex) => {
            $ctrl.currentScreen = $ctrl.screensJson[screenIndex];
            const screenImg = $('.C_UCS-img');

            screenImg.on('load', function () {
                const screenHeight = screenImg.get(0).naturalHeight;
                const screenWidth = screenImg.get(0).naturalWidth;

                // set screen hotspot
                fcaUconnectService.setHotspotPercentagePosition($ctrl.currentScreen.hotspots, screenWidth, screenHeight);

                // set menu hotspot
                fcaUconnectService.setScreenPercentagePosition($ctrl.screensJson, screenWidth, screenHeight);

                $scope.$broadcast('uconnect-screen-data-ready');
                $ctrl.isReady = true;
            });

            if (screenImg) {
                if (screenImg.get(0)) {
                    if (screenImg.get(0).complete) {
                        screenImg.trigger('load');
                    }
                }
            }
        };

        // This function return the feature code selected
        // You can use it via bindings into the child you want with '<'
        $ctrl.updateFeatureCodeSelected = newFeatureCodeSelected => {
            $ctrl.featureCodeSelected = newFeatureCodeSelected;
            const $slickSlider = $element.find('.C_UCFO-slick');
            if ($ctrl.featureCodeSelected) {
                // Select the overlay for the selected hotspot feature code
                const $featureSlick = $('[data-feature-code=' + $ctrl.featureCodeSelected + ']');
                let featureSlickIndex = 0;
                if ($featureSlick.length > 1) {
                    $featureSlick.each((index, elem) => {
                        if (!$(elem).hasClass('slick-cloned')) {
                            featureSlickIndex = $(elem).data('slick-index');
                        }
                    });
                } else {
                    featureSlickIndex = $featureSlick.data('slick-index');
                }
                $slickSlider.slick('slickGoTo', featureSlickIndex, true);
            }
        };
    }
})();