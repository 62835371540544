(function () {
  angular
    .module('fca.brandApp')
    .directive('fcaAlfaHeroBanner', fcaAlfaHeroBanner);

  function fcaAlfaHeroBanner() {
    return {
      restrict: 'A',
      scope: true,
      controllerAs: 'alfaHeroBanner',
      controller: fcaAlfaHeroBannerController
    };


    function fcaAlfaHeroBannerController($scope, $element, $timeout) {
      'ngInject';

      const $ctrl = this;

      this.$onInit = () => {
        $timeout(function () {
          $element.css({ 'top': `calc(50% - ${$element[0].getBoundingClientRect().height / 2}px` })
        });
      }
    }
  }
})();
