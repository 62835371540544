(function() {
	angular
		.module('fca.commonDirectives')
		.directive('fcaSlickAnalytic', fcaSlickAnalytic);

	function fcaSlickAnalytic() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickAnalyticsCategory: "@",
				slickAnalyticsId: "@",
				carouselId: "@",
				dynamicAnalyticsValueEnabled: "@",
				dynamicAnalyticsUseIndex: "<",
				customDotsValue: "@",
				customArrowsValue: "@"
			},
			controllerAs: 'slickAnalytic',
			controller: fcaSlickAnalyticController,
		};

		function fcaSlickAnalyticController($scope, $element, gtmAnalytics, $timeout, $window) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			const $ctrl = this;
			let $carousel = $element.find('slick');

			let arrowNextClicked = false;
			let arrowPrevClicked = false;
			let dotClicked = false;

			let slickDirection;

			let rtime;
			let timeout = false;
			let delta = 200;

			let isOR;

			$ctrl.$postLink = () => {
				if (this.carouselId) {
					$carousel = $($element).find('#' + this.carouselId);
				} else {
					$carousel = $($element).find('.slick-initialized');
				}

				isOR = $('body').hasClass('OR-page');

				//Prevent resize event to trigger multiple times. Source: https://forum.jquery.com/topic/the-resizeend-event
				$(window).resize(function() {
					rtime = new Date();
					if (timeout === false) {
						timeout = true;
						setTimeout(resizeend, delta);
					}
				});

				$ctrl.getClickEvents = (event, slick, currentSlide, nextSlide) => {
					let slickDots = slick.$dots;
					if (slickDots) {
						let dotLiElements = slickDots.find('li');

						if (dotLiElements) {
							dotLiElements.on('click', function () {
								dotClicked = true;
							});
						}
					}

					let $arrow = $element.find('.slick-arrow');
					if ($arrow) {
						$arrow.on('click', function () {
							if (this.classList.contains('slick-next')) {
								arrowNextClicked = true;
							}
							if (this.classList.contains('slick-prev')) {
								arrowPrevClicked = true;
							}
						});
					}

					// Calculate direction before change slide
					if (currentSlide > nextSlide) {
						slickDirection = "left";
					} else {
						slickDirection = "right";
					}
				};

				$carousel.on('init', $ctrl.getClickEvents);

				$carousel.on('beforeChange', $ctrl.getClickEvents);

				$carousel.on('afterChange', function(event, slick) {

					if (slick.slideCount > 1) {
						// Analytics id binded
						let analyticsId = $ctrl.slickAnalyticsId;
						let currentSlideIndex = $carousel.slick('slickCurrentSlide');

						// Get analytics value
						let analyticsValue;
						if ($ctrl.dynamicAnalyticsValueEnabled) {
							if (slick.$slides[currentSlideIndex].id && !$ctrl.dynamicAnalyticsUseIndex) {
								analyticsValue = slick.$slides[currentSlideIndex].id;
							} else {
								analyticsValue = (currentSlideIndex + 1);
							}
						}
						// for fca onlyram model tray
						if ($(slick.$slides[currentSlideIndex]).find('.model-tray-item-title').length > 0) {
							analyticsValue = $(slick.$slides[currentSlideIndex]).find(
									'.model-tray-item-title').text();
						}

						//custom analytics values
						$ctrl.getAnalyticsValue = (customValue) => {
							if (customValue === "slickSlideIndex") {
								return (currentSlideIndex) + 1;
							} else if (customValue !== undefined) {
								return customValue;
							} else {
								return analyticsValue;
							}
						};

						let analyticsDotsValue = $ctrl.getAnalyticsValue($ctrl.customDotsValue);
						let analyticsArrowsValue = $ctrl.getAnalyticsValue($ctrl.customArrowsValue);

						//Send analytics value according to the event
						switch (true) {
							case arrowNextClicked:
								arrowNextClicked = false;
								$ctrl.sendGtmTrackEvent(analyticsId, 'right-arrow-' + analyticsArrowsValue);
								break;
							case arrowPrevClicked:
								arrowPrevClicked = false;
								$ctrl.sendGtmTrackEvent(analyticsId, 'left-arrow-' + analyticsArrowsValue);
								break;
							case dotClicked:
								dotClicked = false;
								$ctrl.sendGtmTrackEvent(analyticsId, 'dot-' + analyticsDotsValue);
								break;
							case slick.swipeLeft !== null:
								$ctrl.sendGtmTrackEvent(analyticsId, 'swipe-' + analyticsArrowsValue);
								break;
							default:
								break;
						}
					}
				});
			}

			$ctrl.sendGtmTrackEvent = (id, value) => {

				let gtmCategory = (isOR) ? `Only-in-a-Ram-${$ctrl.slickAnalyticsCategory}` : `${$ctrl.slickAnalyticsCategory}`;

				gtmAnalytics.trackEvent('event', {
					category: gtmCategory,
					label: `${id}-${value}`
				});
			};

			function resizeend() {
				if (new Date() - rtime < delta) {
					setTimeout(resizeend, delta);
				} else {
					timeout = false;

					// We need do re-add events listeners to the dots if user resize the window
					$carousel.find('.slick-dots li').on('click', function(e) {
						dotClicked = true;
					});
				}
			}
		}
	}
})();
