/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.closestDealer')
        .service('closestDealer', Service);

    /**
     * @ngdoc service
     * @name fca.closestDealer.service:fca.closestDealer
     * @description Find dealers http service for backend API
     * @requires $http
     * @requires $filter
     * @example
     * <pre>[TODO]</pre>
     */
    function Service($http, $filter) {
        let distance = $filter('distance');
        let dealerUrl = $filter('dealerUrl');

        /**
         * @ngdoc method
         * @name findDealers
         * @methodOf fca.closestDealer.service:fca.closestDealer
         * @description Retrieve all dealers near a location and in a specific radius
         * @param    {String} uri Backend api base url
         * @param    {Object} location Lat/lng object
         * @param    {Number} radius Radius around the location point to search
         * @return  {Object}          AngularJS promise object with array of dealers (with distance and detail url)
         */
        this.findDealers = (uri, location, brand, digital = false, radius = 50) => {
            let latitude = location.latitude;
            let longitude = location.longitude;
            let postalCode = location.postalCode;


            let url = `${uri}?` + (digital == true ? 'isDigital=true&' : '') + `brand=${brand}&longitude=${longitude}&latitude=${latitude}&radius=${radius}`;

            return $http.get(url).then((r) => {
                let d = r.data;
                d.dealers.forEach((c, i) => {
                    d.dealers[i] = ng.extend(d.dealers[i], {
                        distance: distance({
                            longitude: longitude,
                            latitude: latitude
                        }, c),
                        externalUrl: d.dealers[i].url,
                        url: dealerUrl(d.dealers[i])
                    });
                });

                // If the postal code was entered manualy, display it
                if (postalCode !== r.data.userZipCode) {
                    r.data.manualPostalCode = postalCode;
                }

                return r.data;
            }).catch(function(e) {
                console.error('error retrieving dealers', e);
            });
        };
    }
})(angular);
