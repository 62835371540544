(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021MediaCarousel', vlp2021MediaCarousel);

	function vlp2021MediaCarousel() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				sendAnalytics: '<',
				analyticscategory: '@',
				analyticsid: '@'
			},
			controllerAs: '$vlp2021MediaCarousel',
			controller: vlp2021MediaCarouselController
		};

		function vlp2021MediaCarouselController($element, $timeout, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			let analyticscategory = '';
			let analyticsid = '';

			$ctrl.$postLink = () => {
				if ($ctrl.sendAnalytics) {
					$timeout(() => {
						analyticscategory = $ctrl.analyticscategory;
						analyticsid = $ctrl.analyticsid;

						$element.find('.slick-arrow').on('click', (e) => {
							let analyticsvalue = '';
							if (e.target.classList.contains('slick-prev')) {
								analyticsvalue = '-left-arrow-slick';
							} else {
								analyticsvalue = '-right-arrow-slick';
							}
							gtmAnalytics.trackEvent('event', {
								category: 'App-Suite-' + analyticscategory,
								label: analyticsid + analyticsvalue
							});
						});

						$element.find('.slide-m-dots-fuel-engine > .slick-dots > li > button').on('click', (e) => {
							let analyticsvalue = '-dot-slick-' + e.target.innerHTML;
							gtmAnalytics.trackEvent('event', {
								category: 'App-Suite-' + analyticscategory,
								label: analyticsid + analyticsvalue
							});
						});
					});
				}
			};
		}
	}
})();
