(function() {
	angular
	.module('fca.pageLayout')
	.directive('fcaAutoshowNavigationMenu', fcaAutoshowNavigationMenu);

	//basic and strip down module for autoshow
	function fcaAutoshowNavigationMenu() {
		return {
			restrict: 'A',
			controller: fcaAutoshowNavigationMenuController,
			controllerAs: '$ctrlAutoshowNavigationMenu'
		};

		function fcaAutoshowNavigationMenuController($rootScope, $window, $timeout, $scope) {
			'ngInject';

			// Menu variables
			let $subnavs = $('[data-subnav]');
			const $html = $('html');
			let windowWidth = $window.innerWidth;
			let $subNav = $('[data-subnav]').first();
			this.currentMenu = {};
			this.isActive = false;

			this.setMenuSublinkFocusNavigation = () => {
				if ( $subNav[0] ) {
					if (!$subNav[0].classList.contains('main-nav-subnav-offers') &&
						(!$subNav[0].classList.contains('vehicles') || isAlfa) ) {
						const $subNavFocusableElements = $subNav.find('a, button, input');
						const firstFocusableElement = $subNavFocusableElements[0];
						const lastFocusableElement = $subNavFocusableElements[$subNavFocusableElements.length -1];

						if (firstFocusableElement) {
							firstFocusableElement.addEventListener('keydown', e => {
								if (e.key === 'Tab' || e.code === 'Tab') {
									if ( e.shiftKey ) {
										// shift + tab (backward <-)
										this.closeMenu();
									}
								}
							});
						}

						if (lastFocusableElement) {
							lastFocusableElement.addEventListener('keydown', e => {
								if (e.key === 'Tab' || e.code === 'Tab') {
									if ( !e.shiftKey ) {
										// tab (forward ->)
										this.closeMenu();
									}
								}
							});
						}
					}
				}
			};

			// Close current top level menu
			this.closeCurrentMenu = () => {
				if (!$.isEmptyObject(this.currentMenu)) {
					this.closeMenu();
					this.currentMenu = {};
				}
			};

			this.openMenu = () => {
				this.isActive = true;
				$scope.$apply();

				// Opening the menu(s)
				$timeout(() => {
					animateMenu($subNav);
				}, 150);
				$html.addClass('main-nav-active');

			};

			this.closeMenu = ($event) => {
				this.isActive = false;
				$scope.$apply();

				// Closing the menu(s)
				animateMenu($subNav, -375);
				$html.removeClass('main-nav-active');
			};

			this.menuClicked = () => {
				//this.setScrollingTimeout(scrollTimeout, 200);

				if (!this.isActive) {
					this.currentMenu = $subNav;
					this.openMenu();
				} else {
					this.closeMenu();
				}
			};

			this.bindKeyUp = () => {
				var menuElement = $('.mobile-nav-trigger');
				angular.element(menuElement).bind('keypress', (event) => {
					if (event.keyCode === 13 || event.keyCode === 32) {
						event.preventDefault();
						this.menuClicked();
					}
				});
			};

			this.closeOnEsc = (event) => {
				if (event.code === 'Escape' || event.key === 'Escape') {
					this.closeCurrentMenu();
				}
			}

			this.bindResize = () => {
				// Close all menus when resizing the window
				angular.element($window).on('resize', () => {
					if (windowWidth !== $window.innerWidth) {
						this.closeCurrentMenu();
					}

					windowWidth = $window.innerWidth;
				});
			};

			this.bindClick = () => {
				// Close all menus if click somewhere else on the page
				angular.element(document).bind('click', (event) => {
					let submenuClicked = $subnavs.find(event.target).length > 0;
					let submenuSecondClicked = $('.main-nav-second-subnav').find(event.target).length > 0;
					let overlayClicked = $('.overlay').find(event.target).length > 0;
					let overlayBackdropClicked = $(event.target).hasClass('overlay');
					let hasSubmenu = event.target.hasAttribute('data-open-submenu');
					let tooltipCloseClicked = $(event.target).hasClass('legal-tooltip-close') ? $(event.target).hasClass('legal-tooltip-close') : $(event.target).parent().hasClass('legal-tooltip-close');

					if (submenuClicked ||
						submenuSecondClicked ||
						overlayClicked ||
						overlayBackdropClicked ||
						hasSubmenu ||
						tooltipCloseClicked) {
							this.menuClicked();
						} else {
							this.closeCurrentMenu();
						}
				});
			};

			this.setSkipNavigation = () => {
				// timeout needed to grab disclaimers and some form elements
				$timeout(() => {
					// Find the first focusable element in main content
					const firstFocusableElement = angular.element('.layout-main-content').find('a:not(.nav-item), button:not(.nav-item), input, select, textarea').filter(':visible')[0];
					const skipNavLink = angular.element('.accessible-caption--skip-navigation');

					if (firstFocusableElement) {
						// Set the id for the anchor link target. If an id already exist, change the skip nav anchor href to match the id
						// This can be usefull if we want to do something like a scrollTo animation
						if(!firstFocusableElement.id) {
							firstFocusableElement.setAttribute('id', 'FirstElement');
						} else {
							skipNavLink.href = '#' + firstFocusableElement.id;
						}

						skipNavLink.bind('click', () => {
							firstFocusableElement.focus();
						});
					}
				}, 1000);
			};

			this.$onInit = () => {
				this.bindClick();
				this.bindResize();
				this.bindKeyUp();
				this.setMenuSublinkFocusNavigation();
				$rootScope.$on('$viewContentLoaded', this.setSkipNavigation());

				$('body').append('<div class="menu-overlay"></div>');
			};
		}

		// Disable keyboard navigation on contained elements
		function disableKeyboardNavigation(el) {
			el.find('a, button, input').attr('tabindex', '-1');
		}

		function animateMenu($menu, newWidth = 0, animationLength = 150) {
			$menu.stop().animate({
				left: newWidth
			}, animationLength);
		}
	}
})();
