(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaSameHeightSetter', fcaSameHeightSetter);

    function fcaSameHeightSetter() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                elemClassToSetHeight: '@'
            },
            controller: FcaSameHeightSetterController
        };

        function FcaSameHeightSetterController($element, $window) {
            'ngInject';

            const $ctrl = this;
            const container = $element;
            let maxHeight = 0;

            function setHeight() {
                const arrElements = $ctrl.elemClassToSetHeight ?
                    container.find(`.${$ctrl.elemClassToSetHeight}`) : container.children();

                for (let i = 0; i < arrElements.length; i++) {
                    const currentElement = $(arrElements[i]);

                    if (currentElement.height() > maxHeight) {
                        maxHeight = currentElement.height();
                    }
                }

                $(arrElements).height(maxHeight);
            }

            this.$onInit = () => {
                setHeight();
                $window.addEventListener('resize', setHeight)
            };
        }
    }
})();
