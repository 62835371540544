(function() {
	angular
		.module('fca.brandApp')
		.directive('abDatePicker', abDatePicker);

	function abDatePicker() {

		return {
			restrict: 'A',
			scope: {
				ngModel: '=',
				outputFormat: '@'
			},
			bindToController: {
			},
			controllerAs: '$abDatePicker',
			controller: abDatePickerController
		};

		function abDatePickerController($element, $scope, $timeout) {

			this.$postLink = () => {
				$timeout(function () {

					let datepickerInput = $element.find('[data-datepicker-input]');
					let datepickerWrapper = angular.element($element.find('[data-datepicker-wrapper]'));

					datepickerInput.datepicker({
						outputFormat: 'y-MM-dd',
						theme: 'contact-dealer-datepicker',
						inline: datepickerWrapper,
						gainFocusOnConstruction: false,
						firstDayOfWeek: 1,
						modal: false,
						closeButtonTitle: "Cancel"
					});

					//set the date on load
					if (angular.isDefined($scope.ngModel)) {
						if (angular.isString($scope.ngModel)) {
							if ($scope.ngModel.length != 0) {
								var dateObject = new Date($scope.ngModel);

								if (dateObject != "Invalid Date")
									mainElement.datepicker('setDate', dateObject);
							}
						} else {
							mainElement.datepicker('setDate', $scope.ngModel);
						}
					}
				}, 0, false);
			}
		}
	}
})();
