(function () {
    angular
        .module('fca.uconnect')
        .component('fcaUconnectInteractiveScreen', {
            controller: fcaUconnectInteractiveScreen,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/interactive-screen/interactive-screen.html',
            bindings: {
                screenList: '<',
                currentScreen: '<',
                updateFeatureCodeSelected: '<',
                featureOverlayIsVisible: '=',
                updateScreenData: '<',
                analyticsId: '@'
            }
        });

    function fcaUconnectInteractiveScreen() {
        'ngInject';

        const $ctrl = this;
        $ctrl.isStartOverlayVisible = true;

        $ctrl.language = window.FCA_SITES_CONFIG.language;

        $ctrl.$postLink = () => {
            //At load display the first screen
            $ctrl.changeDisplayedScreen(0);
        };

        $ctrl.$onChanges = o => {
            if ($ctrl.featureOverlayIsVisible) {
                $ctrl.closeStartOverlay();
            }
        };

        $ctrl.changeDisplayedScreen = (screenIndex) => {
            $ctrl.updateScreenData(screenIndex);
        };

        $ctrl.closeStartOverlay = () => {
            $ctrl.isStartOverlayVisible = false;
        }
    }
})();
