(function() {
	angular
		.module('alfaromeo.vlp')
		.directive('alfaVlpImgDifference', alfaVlpImgDifference);

	function alfaVlpImgDifference() {
		return {
			restrict: 'A',
			controllerAs: '$ctrl',
			controller: AlfaVlpImgDifferenceController,
		};

		function AlfaVlpImgDifferenceController($element, matchmedia, FCA_MQ_LAYOUT) {
			'ngInject';

			const $ctrl = this;

			$ctrl.selectedShot = null;

			$ctrl.$onInit = () => {
				// automatically select first shot
				const shots = $element.find('.AR_imgDiffBlock-shot');
				if( shots.length > 0 ) $ctrl.selectedShot = shots[0].id;
			};

			$ctrl.isMobile = () => {
				return !matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS);
			};
			$ctrl.selectShot = (event, shotID) => {
				$ctrl.selectedShot = shotID;
			};

		}
	}
})();
