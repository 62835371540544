(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaHeaderMenu', fcaHeaderMenuDirective);

	function fcaHeaderMenuDirective() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'headerMenuCtrl',
			controller: fcaHeaderMenuController
		};

		function fcaHeaderMenuController($window, $timeout, $rootScope) {
			this.isHamburgerMenuOpen = false;
			this.fullMenuIsOpen = false;

			this.$onInit = () => {
				this.initialSelectedYear = '';
				this.currentYear = this.initialSelectedYear;

				$timeout(() => {
					this.subnavIsClosed = window.matchMedia("(max-width: 767px)").matches ? !this.subnavIsClosed : this.subnavIsClosed;

					$window.addEventListener('resize', () => {
						this.subnavIsClosed = true;
					});
				});
			};

			this.onBurgerMenuClick = () => {
				this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
				if (this.isHamburgerMenuOpen) {
					$rootScope.$emit('onDisabledScroll');
				} else {
					$rootScope.$emit('onEnabledScroll');
					this.resetMenuSelection();
				}
			};

			this.toggleFullMenu = () => {
				this.fullMenuIsOpen = !this.fullMenuIsOpen;
			};

			this.setActiveLink = ($event) => {
				$event.preventDefault();

				const currentFirstLevelElement = $event.currentTarget.parentElement;

				//loop through list item and switch the data-link-selected attribute
				$('.lightbox-menu__list-item').each(function() {
					if (typeof this !== "undefined" && this.getAttribute('data-link-selected') === 'true') {
						this.setAttribute('data-link-selected', 'false');
					}
				});
				currentFirstLevelElement.setAttribute('data-link-selected', 'true');

				//get data-link-id and find the corresponding sub-nav
				const currentDataLinkId = currentFirstLevelElement.getAttribute('data-link-id');

				$(`[data-subnav-id]`).each(function() {
					if(typeof this !== "undefined" && this.getAttribute('data-subnav-id') === currentDataLinkId) {
						this.setAttribute('data-sub-menu-active', 'true');
						const currentSubnavElement = this;

						// set default selection for year menu
						$('.menu-vehicles__sections-list-item').attr('data-link-selected', 'false');
						const firstListItem = $(currentSubnavElement).find('.menu-vehicles__sections-list-item')[0];
						if (typeof firstListItem !== "undefined") {
							firstListItem.setAttribute('data-link-selected', 'true');
						}


					} else {
						this.setAttribute('data-sub-menu-active', 'false');
					}
				});

				this.subnavIsClosed = false;
			};

			this.setActiveYear = ($event) => {
				$event.preventDefault();
				const parentElement = $event.currentTarget.parentElement;
				// select the year
				$('.menu-vehicles__years-list-item').each(function() {
					this.setAttribute('data-link-selected', 'false');
				});
				parentElement.setAttribute('data-link-selected', 'true');
				this.currentYear = parentElement.getAttribute('data-id');

				// select the corresponding sub-menu
				$('.menu-vehicles__sections-list-item').each(function() {
					this.setAttribute('data-link-selected', 'false');
				});
				$(parentElement)
					.closest('.menu-vehicles')
					.find(`.menu-vehicles__sections-list-item[data-id="${this.currentYear}"]`)[0]
					.setAttribute('data-link-selected', 'true');
			};

			this.resetMenuSelection = () => {
				$('[data-link-selected]').attr('data-link-selected', 'false');
				$('[data-sub-menu-active]').attr('data-sub-menu-active', 'false');
				$('.initial-selection').attr('data-link-selected', 'true');
				$('.initial-subnav-selection').attr('data-sub-menu-active', 'true');
			}
		}
	}
})();
