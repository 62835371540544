(function() {
    angular
        .module('fca.mention')
        .service('mentionService', MentionService);

    function MentionService() {
        'ngInject';

        let $service = this;

        $service.apiUrl = '/api/mention/';
        $service.mentions = {};
        $service.brandCode = window.FCA_SITES_CONFIG.name;
        $service.language = window.FCA_SITES_CONFIG.language;

        $service.get = (mentionCode) => {
            $service.executeRequest($service.apiUrl + $service.brandCode + `/`, mentionCode);
        };

        $service.getMention = (mentionCode) => {
            // populate for first fetch
            if ($service.mentions[mentionCode] == undefined) {
                $service.get(mentionCode);
            }

            return $service.mentions[mentionCode] ? $service.mentions[mentionCode][$service.language] : '';
        };

        $service.executeRequest = (url, mentionCode) => {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        $service.persistMention(JSON.parse(xhr.response), mentionCode);
                    }
                }
            };
            xhr.open('GET', url + mentionCode, false);
            xhr.send();
        };

        $service.persistMention = (response, mentionCode) => {
            if(response.description) {
                $service.mentions[mentionCode] = response.description;
            }

        }
    }
})();
