(function() {
	angular
		.module('fca.commonDirectives')
		.directive('featureAccordion', featureAccordion);

	function featureAccordion() {
		'ngInject';

		return {
			restrict: 'A',
			bindToController: {
				openOnLoad: '<?',
				analyticsCategory: '@?',
				analyticsLabel: '@?'
			},
			controller: featureAccordionController,
			controllerAs: 'featureAccordion',
			scope: true,
			require: {
				accordionList: '?^featureAccordionList'
			}
		};

		function featureAccordionController ($element, $window, $timeout, gtmAnalytics) {
			'ngInject';

			let vm = this;
			vm.expanded = false;
			vm.contentHeight = 0;
			vm.accordionContent = $element.find('[data-accordion-content]');
			vm.accordionContentWrapper = $element.find('[data-accordion-content-wrapper]');
			
			vm.toggleAccordion = function() {
				if (vm.analyticsCategory != undefined && vm.analyticsLabel != undefined) {
					vm.triggerTracking();
				}
				
				if(vm.expanded) {
					vm.expanded = false;
					vm.contentHeight = 0;
					vm.accordionList.currentPosition = false;
					vm.accordionList.currentOffset = 0;
				} else {
					let contentHeight = vm.accordionContent.outerHeight();
					let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
					let accordionOffset = $element[0].getBoundingClientRect().top + scrollTop;

					vm.accordionList.scrollToAccordion(accordionOffset, contentHeight);
					vm.accordionList.closeAllAccordions();
					vm.expanded = true;
					vm.contentHeight = contentHeight + "px";

					vm.accordionContentWrapper.stop().animate({
						height: contentHeight + "px"
					}, vm.accordionList.animationLength, 'swing');
				}
			};

			vm.triggerTracking = () => {
				let trackingLabel = 'expand';

				if (vm.expanded) {
					trackingLabel = 'collapse';
				}

				gtmAnalytics.trackEvent('event', {
					id: vm.analyticsCategory,
					value: `${vm.analyticsLabel} - ${trackingLabel}`
				});
			};


			vm.updateActiveAccordion = () => {
				if(vm.expanded) {
					let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
					vm.accordionList.currentPosition = $element[0].getBoundingClientRect().top + scrollTop;
					vm.accordionList.currentOffset = vm.accordionContent.outerHeight();

					$timeout(() => {
						vm.contentHeight = vm.accordionContent.outerHeight() + "px";
					});
				}
			}

			vm.bindResize = () => {
				// Close all menus when resizing the window
				angular.element($window).on('resize', () => {
					vm.updateActiveAccordion();
				});
			};

			vm.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					vm.updateActiveAccordion();
				});
			};

			vm.$onInit = function() {
				vm.bindResize();
				vm.bindScrolling();

				if(vm.openOnLoad) {
					vm.expanded = true;
					vm.contentHeight = vm.accordionContent.outerHeight() + "px";
				}

				if (vm.accordionList) {
					vm.accordionList.addAccordion(vm);

					if(vm.openOnLoad) {
						let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
						vm.accordionList.currentPosition = $element[0].getBoundingClientRect().top + scrollTop;
						vm.accordionList.currentOffset = vm.accordionContent.outerHeight();
					}
				}
			};
		}
	}
})(angular);
