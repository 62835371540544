(function () {
    'use strict';
    angular
        .module('fca.closestDealerList')
        .component('closestDealerList', {
            controller: ClosestDealerListController,
            controllerAs: '$pageList',
            templateUrl: '/closest-dealer/closest-dealer-list/closest-dealer-list.html',
            bindings: {
                dealersList: '<',
                noResults: '<',
                itemSelected: '<',
                isPaginated: '<',
                isLoaded: '<',
                eventPrefix: '@?',
                location: '<',
                selectFavoriteDealerList: '<',
                analyticsCategory: '@',
                analyticsId: '@',
            }
        });

    /**
     * @ngdoc controller
     * @name fca.closestDealerList.controller:ClosestDealerListController
     * @description Component closestDealerList controller
     * @requires $scope
     */
    function ClosestDealerListController($scope, $element, $timeout) {
        'ngInject';

        /**
         * @ngdoc property
         * @name page
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Number}
         * @description Current active page (by default 1)
         */
        this.page = 1;

        /**
         * @ngdoc property
         * @name hasNextPage
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Boolean}
         * @description True if at the current page the list has a next page
         */
        this.hasNextPage = null;

        /**
         * @ngdoc property
         * @name totalPage
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Number}
         * @description Total page number with the current list of dealers
         */
        this.totalPage = 1;

        /**
         * @ngdoc property
         * @name perPage
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Number}
         * @description Number of item per page (10)
         */
        this.perPage = 10;

        /**
         * @ngdoc property
         * @name isPaginated
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Boolean}
         * @description True if the list is paginated (could be change by the parent component)
         */
        this.isPaginated = false;

        /**
         * @ngdoc property
         * @name noResults
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Boolean}
         * @description True if there's not results in dealers list
         */
        this.noResults = false;

        /**
         * @ngdoc property
         * @name listItems
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Array}
         * @description List of dealers for current page
         */
        this.listItems = [];

        /**
         * @ngdoc property
         * @name itemSelected
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Object}
         * @description Selected item in list
         */
        this.itemSelected = null;

        /**
         * @ngdoc property
         * @name isCarouselDisabled
         * @propertyOf fca.closestDealerList.controller:ClosestDealerListController
         * @type {Boolean}
         * @description Set to true to disable slick carousel
         */
        this.isCarouselDisabled = false;

        this.$postLink = () => {
            // disable slick for vlp 2021 pages
            if ($('body').hasClass('vlp-2021')) {
                this.isCarouselDisabled = true;
            }

            $timeout(() => {
                $scope.$on('setCarouselState', (evt, data) => {
                    this.isCarouselDisabled = data;

                    $scope.$apply();
                });
            });

            if (this.isLoaded && $element.find('.closest-slick') && $element.find('.closest-slick').hasClass('slick-initialized')) {
                $element.find('.closest-slick').slick('refresh');
            }
        };

        /**
         * @ngdoc method
         * @name $onChanges
         * @methodOf fca.closestDealerList.controller:ClosestDealerListController
         * @param  {Object} o Binding changed object
         * @description Set noResults property and paginate the list when the dealers list change
         */
        this.$onChanges = (o) => {
            let {dealersList, noResults, isPaginated} = o;
            let page = (this.isPaginated) ? 1 : null;

            if (noResults !== undefined) {
                this.noResults = noResults.currentValue;
            }

            if (dealersList !== undefined || isPaginated !== undefined) {
                this.paginate(page);
            }

        };

        /**
         * @ngdoc method
         * @name paginate
         * @methodOf fca.closestDealerList.controller:ClosestDealerListController
         * @param  {Number} page Current page
         * @description Set le current page recordset of dealers
         */
        this.paginate = (page) => {
            if (this.isPaginated && !this.noResults) {
                if (ng.isNumber(page)) {
                    this.page = page;
                }

                let length = this.dealersList.length;
                // Set total page
                this.totalPage = Math.ceil(length / this.perPage);
                // Check if list is on the last page
                if (this.page <= this.totalPage) {
                    let last = (this.perPage * this.page) - 1;
                    let start = 0;
                    let list = this.dealersList.slice(start, last);
                    let page = this.page++;
                    let hasNextPage = false;
                    if (page < this.totalPage) {
                        hasNextPage = true;
                    }

                    this.listItems = list;
                    this.page = page;
                    this.hasNextPage = hasNextPage;
                }
            } else {
                this.listItems = this.dealersList;
            }
        };
    }
})(angular);
