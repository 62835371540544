(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaTabs', fcaTabs);

	function fcaTabs() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: true,
			controllerAs: 'tabs',
			controller: fcaTabsController
		};

		function fcaTabsController($scope, $element) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			this.beforeSlideChange = () => {
				this.slickSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					// GA Tracking
					if (this.masterCarousel) {
						let trackingMessage = 'prev';
						if (nextSlide > modelSpecsGrid.currentSlideIndex) {
							trackingMessage = 'next';
						}

						gtmAnalytics.trackEvent('event', {
							category: 'models and specs',
							label: trackingMessage,
							trigger: 'models-and-specs'
						});
					}

					modelSpecsGrid.masterCarouselChange(nextSlide);
				});
			};

			this.activeTab = 0;
			// todo: refresh slick carousel on tab change
			// $scope.$watch('tabs.activeTab', function(current, prev) {
			// 	let $currentTabsPanel = $($element[0]).find('.tabs-panel.active');
			// 	if ($currentTabsPanel.length > 0) {
			// 		let $slick = $currentTabsPanel.find('slick');
			// 		if ($slick.length > 0) {
			// 			// console.log($slick[0].slick);
			// 			// $slick[0].slick.refresh();
			// 			// $slick.slick.slickSetOption(null, null, true);
			// 		}
			// 	}
			// });
		}
	}
})();
