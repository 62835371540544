(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculator')
		.directive('daaCalculatorControl', CalculatorButton);

	function CalculatorButton() {
		return {
			link: link,
			restrict: 'E',
			scope: {
				dialogModel: '@?',
				dialogModelOverride: '@?',
				dialogLocation: '<?',
				current: '<?',
				label: '@',
				analyticsCategory: '@',
				analyticsId: '@',
				analyticsValue: '@'
			},
			templateUrl: '/components/daa-calculator/button.html'
		};

		function link($scope, $element) {
			let modelOverride = ng.fromJson($scope.dialogModelOverride);

			if ($scope.analyticsCategory && $scope.analyticsId && $scope.analyticsValue) {
				$element.find("a").attr('data-analyticscategory', $scope.analyticsCategory);
				$element.find("a").attr('data-analyticsid', $scope.analyticsId);
				$element.find("a").attr('data-analyticsvalue', $scope.analyticsValue);
			}

			$scope.override = ng.extend({}, {
				current: 'cash'
			}, modelOverride);

			// Set active object on scope changes
			$scope.$watch('current', (v) => {
				$scope.override.current = v;
			});
		}
	}
})(angular);
