(function(ng) {
	angular
		.module('fca.daaContactDealer')
		.service('ContactDealer', ContactDealer);

	function ContactDealer($http, $filter, externalConfigLoader) {
		'ngInject';

		let urlConfig = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig();
		let dealersApiPath = urlConfig.dealersApiPath;
		let contactSubmitPath = urlConfig.contactSubmitPath;
		let campaignTrackerPath = urlConfig.campaignTrackerPath;
		let nameplatesApiPath = urlConfig.nameplatesApiPath;
		let vinVehicleApiPath = urlConfig.inventoriesApiPath || null;
		let distanceFn = $filter('distance');

		this.getNameplates = (brand) => {
			return $http.get(`/data/nameplates/${brand}`, {
					params: {
						daaOnly: true,
						schemes: 'v6,v7'
					}
				}).then((httpData) => {
					return httpData.data;
		});
		};

		this.getVehicle = (brand, vin) => {
			return $http.get(`${vinVehicleApiPath}/${brand}/compare/${vin}`, {
					params: {
						language: 'en',
						schemes: 'v6,v7',
						daaOnly: true
					}
				}).then((httpData) => {
					let [data] = httpData.data;
			let {
				trimGroup,
				regularPrice
			} = data;

			data = ng.extend(data, {
				brand: data.brandName,
				name: data.nameplateName,
				imageTrimName: trimGroup,
				label: trimGroup,
				imageMsrp: regularPrice
			});

			return data;
		});
		};

		this.getNameplateTrims = (brand, nameplatecode) => {
			return $http.get(`/data/nameplates/${brand}/trimgroups/${nameplatecode}`, {
					params: {
						schemes: 'v6,v7',
						daaOnly: true
					}
				}).then((httpData) => {
					return buildModelYears(httpData.data);
		});

			function buildModelYears(trimsArr) {
				/* sort trims by modelYears */
				let modelYears = [];

				trimsArr.forEach((trimGroup) => {
					let index = getIndex();

				if (index === -1) {
					modelYears.push({
						year: `${trimGroup.year}`,
						trims: []
					});
					index = getIndex();
				}
				modelYears[index].trims.push(trimGroup);

				function getIndex() {
					let foundItem = modelYears.filter((item) => {
							return item.year === trimGroup.year;
				});

					return modelYears.indexOf(foundItem[0]);
				}
			});

				return modelYears;
			}
		};

		this.getDealerObject = (brand, code) => {
			return $http.get(`/data/dealers/${brand}/${code}`)
					.then((httpData) => {
					return httpData.data;
				}).catch(function(e) {
					console.error('error retrieving dealer', e);
				});
		};

		this.getOtherDealers = (brand, selectedDealersList, latitude, longitude) => {
			let url = `/data/dealers/closest/`;

			return $http.get(url, {
					// Closest dealers must be digital
					params: {
						excludedDealers: selectedDealersList,
						latitude: latitude,
						longitude: longitude,
						isDigital: true,
						brand: brand
					}
				}).then((httpData) => {
					let data = httpData.data.dealers;
			for (let i = 0, l = data.length; i < l; i++) {
				let distance = distanceFn({
					latitude: latitude,
					longitude: longitude
				}, data[i]);

				data[i].distance = distance;
			}

				return data;
			}).catch(function(e) {
				console.error('error retrieving closest dealers', e);
			});
		};

		this.sendContact = (formData) => {
			return $http.post('/cci-app-suite/appsbe/buildAndPrice/leadSubmit', formData)
					.then((httpData) => {
					return httpData.data;
		});
		};

		this.sendToCampaignTracker = (formData) => {
			return $http.post(campaignTrackerPath, formData, {
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					transformRequest: (obj) => {
					let args = [];
			Object.keys(obj).forEach((prop) => {
				let arg = `${encodeURIComponent(prop)}=`;
			arg += encodeURIComponent(obj[prop]);
			args.push(arg);
		});
			return args.join('&');
		}
		});
		};
	}
})(angular);
