(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSearchField', fcaSearchField);

	function fcaSearchField() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'searchField',
			controller: FcaSearchFieldController
		};

		function FcaSearchFieldController($scope) {
			'ngInject';

			this.currentLang = 'en';

			this.$onInit = () => {
				this.checkLanguage();
			};

			this.checkLanguage = () => {
				if (window.FCA_SITES_CONFIG.preferredLanguage != 'en_CA') {
					this.currentLang = 'fr';
				}
			};


			this.submitSearch = ($event) => {
				$event.preventDefault();

				let brand = window.FCA_SITES_CONFIG.brandCode;
				let brandPath = window.FCA_SITES_CONFIG.brandSiteUrls[brand];
				let stringValue = this.searchString;
				let urlPath = window.FCA_SITES_CONFIG.urls.search[this.currentLang];

				if (this.searchString !== undefined) {
					window.location = `${brandPath}${urlPath}#searchTerm=${stringValue}`;
				}


				$('#search-field').focus();
			};
		}
	}
})();
