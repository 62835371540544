(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('postalCode', PostalCode);

    function PostalCode() {
        'ngInject';

        return function (input) {
            if (!input) {
                return input;
            }
            if (input.toString().length === 6) {
                return input.toString().slice(0, 3) + ' ' + input.toString().slice(3);
            } else {
                return input;
            }
        };
    }
})();
