(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaImportUrlParameters', fcaImportUrlParameters);

    function fcaImportUrlParameters($filter, $browser) {
        return {
            restrict: 'A',
            controller: fcaImportUrlParametersController,
            controllerAs: 'importUrl',
            scope: true,
        };

        function fcaImportUrlParametersController() {
            let $this = this;
            $this.urlParameters = '';

            $this.getParameters = () => {
                let url = window.location.search;
                if (url) {
                    $this.urlParameters = url;
                }
            }

            this.$onInit = () => {
                $this.getParameters();
                let url = '';
                url = window.location.search;
                $this.urlParameters = url;
            };
        }
    }
})();
