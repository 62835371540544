(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaQvStickyMiniNav', alfaQvStickyMiniNav);

	function alfaQvStickyMiniNav() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$alfaQvStickyMiniNav',
			controller: alfaQvStickyMiniNavController
		};

		function alfaQvStickyMiniNavController($scope, $element, $timeout, $window) {
			'ngInject';

			const $ctrl = this;
			const $el = $element[0];

			$ctrl.isSticky = false;
			$ctrl.mobileSubnavOpen = false;

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					this.stickyCheck();
				});
			};

			this.bindResize = () => {
				angular.element($window).on('resize', () => {
					this.stickyCheck();
				});
			};

			this.stickyCheck = () => {
				const elScrollTop = $el.getBoundingClientRect().top;

				$ctrl.isSticky = elScrollTop <= 0;

				$timeout(function() {
					$scope.$apply();
				});
			};

			this.$onInit = () => {
				this.stickyCheck();
				this.bindResize();
				this.bindScrolling();
			};
		}
	}
})();
