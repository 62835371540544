(function () {
    angular
        .module('fca.buildAndPrice.swatch')
        .component('swatch', {
            controller: Swatch,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/swatch/swatch.html',
            bindings: {
                option: '='
            }
        });

    function Swatch($scope, $rootScope, configService) {
        'ngInject';

        const $ctrl = this;
        $ctrl.hasImage = false;

        $ctrl.swatchesSource = () => {
            if (!$ctrl.option) {
                return '';
            }
            // Need to split here because the codes returned by the swatches call
            // don't have the suffixes
            let splitCode = $ctrl.option.code.split('_')[0];
            let swatchesData = configService.swatchesData;

            if (!swatchesData) {
                return '';
            }

            let categorySwatchesData = $ctrl.findCategory(swatchesData);
            if (!categorySwatchesData) {
                return '';
            }

            let swatchData = $ctrl.findOption(categorySwatchesData, splitCode);
            if (!swatchData) {
                if (categorySwatchesData.eccCode === '0037') {
                    $ctrl.hasImage = true;
                    return configService.getMissingWheelUrl();
                } else {
                    return '';
                }
            }
            if (!swatchData.path) {
                return '';
            }

            $ctrl.hasImage = true;
            return window.FCA_SITES_CONFIG.mediaBaseUrl + swatchData.path;
        }

        $ctrl.findCategory = (data) => {
            let result = undefined;
            data.forEach(element => {
                if (element.eccCode === $ctrl.option.ecc) {
                    result = element;
                }
            });
            return result;
        }

        $ctrl.findOption = (category, code) => {
            let result = undefined;
            category.options.forEach(option => {
                if (option.code === code) {
                    result = option;
                }
            });
            return result;
        }

        $ctrl.fallbackToHex = () => {
            if (!$ctrl.option) {
                return {};
            }
            return { "background-color": $ctrl.option.color };
        }
    }
})()