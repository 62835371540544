(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselInfinite', fcaSlickCarouselInfinite);

	function fcaSlickCarouselInfinite() {
		return {
			restrict: 'A',
			bindToController: {

			},
			controllerAs: 'carouselInfinite',
			controller: FcaSlickCarouselInfiniteController
		};

		function FcaSlickCarouselInfiniteController($element, $timeout, $location) {
			'ngInject';

			this.slider = $element;
			this.currentIndex = 0;
			this.dragging = false;

			this.onTouchStart = (event) => {
				this.onStartDrag(event);
				$element.bind('touchmove', this.onTouchMove);
			};
			this.onTouchMove = (event) => {
				this.onMove(event);
			};
			this.onTouchEnd = (event) => {
				this.onStopDrag(event);
				$element.unbind('touchmove', this.onTouchMove);
			};
			$element.bind('touchstart', this.onTouchStart);
			$element.bind('touchend', this.onTouchEnd);

			this.onStartDrag = (event) => {
				this.dragging = true;
				$element.addClass('dragging');
			};
			this.onStopDrag = (event) => {
				this.dragging = false;
				$element.removeClass('dragging');
			};
			this.onMove = (event) => {
				if (this.dragging) {
					this.closeTooltips();
				}
			};
			this.$onInit = () => {
				this.slider.on('init', this.onInit);
				this.slider.on('afterChange', this.onAfterChange);
				this.slider.on('beforeChange', this.onBeforeChange);
			};
			this.onInit = (event, slick) => {
				$element.find('.slick-prev').click(this.onPrev);
				$element.find('.slick-next').click(this.onNext);
				if($location.hash() && this.slider.length > 0) {
					let hashValue = '#' + $location.hash();
					let slideToActivate = $($element).find(hashValue);
					if(slideToActivate.length > 0) {
						slick.slickGoTo(parseInt(slideToActivate.attr('data-slick-index')));
						$('html, body').animate({
							scrollTop: $(slideToActivate).offset().top - getOffset()
						}, 200);
					}
				}
			};

			this.onBeforeChange = (event, slick, currentSlide, nextSlide) => {
				let iframe = $element.find('.slick-current').find('iframe');

				if (iframe.length > 0 ) {
					console.log('Pause video');
					iframe = $element.find('.slick-current').find('iframe')[0].contentWindow;
					iframe.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
				}
			};

			this.onAfterChange = (event, slick, currentSlide, nextSlide) => {
				this.currentIndex = currentSlide;
			};

			this.onPrev = (event) => {
				if (this.currentIndex <= 0) {
					this.slider.slick('slickGoTo', this.getSlideCount()-1);
				}
			};

			this.onNext = (event) => {
				if (this.currentIndex >= this.getSlideCount()-1) {
					this.slider.slick('slickGoTo', 0);
				}
			};

			this.getSlideCount = () => {
				return $element.find('.slick-slide').length;
			};

			this.getSlideCount = () => {
				return $element.find('.slick-slide').length;
			};

			this.closeTooltips = () => {
				let tooltips = $element.find('fca-legal-tooltip');
				let popper;

				tooltips.each((index, $tooltip) => {
					popper = $(`#${$tooltip.dataset.tippyPopper}`);

					// Check if tooltip opened
					let hasHeight = popper.height();

					if (popper.length) {
						// Close any open disclaimers before changing slide
						this.dragging = false;
						$timeout(function() {
							tippy.hideAll();

							$element.removeClass('dragging');
						});
					}
				});
			};

			function getOffset() {
				let panel = $('.sticky-panel.is-mini-nav');

				// Set offset to 0 if panel does not exist
				if(panel.length > 0) {
					if (!stickyPanelHeight) {
						// Clone panel to measure height of mini-nav in its sticky state
						const panelClone = panel
							.clone()
							.addClass('is-stuck')
							.css('visibility', 'hidden')
							.appendTo('body');

						// Store value to not have to repeat expensive operation
						stickyPanelHeight = panelClone.height();
						panelClone.remove();
					}

					const offset = panel.scrollTop() + stickyPanelHeight;

					return offset - 5; // -5 needed to avoid bug on initial load
				} else {
					return 0;
				}
			}
		}
	}
})();
