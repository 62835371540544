(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('phoneLink', phoneLinkFilter);

    /**
     * Formats phone numbers as links according to the spec at https://tools.ietf.org/html/rfc3966
     *   Desired format is "+[country]-[area code]-[subscriber number]"
     * This code is only tested to work for canadian/us phone numbers.
     *
     *   Example:
     * (555) 555-3331 => +1-555-555-3331
     **/
    function phoneLinkFilter() {
        'ngInject';

        return function(text) {
            let result;
            if (text) {
                const countryCode = '1';
                const numLength = 10;
                // remove any non-digit characters
                let localNumber = String(text).replace(/\D/g,'');
                // if the number starts with the country code, remove it
                if (localNumber.startsWith(countryCode) && localNumber.length === (countryCode.length + numLength)) {
                    localNumber = localNumber.substr(1);
                }
                // now we only have the area-code and subscriber number
                if (localNumber.startsWith(countryCode) && localNumber.length === (countryCode.length + numLength)) {
                    localNumber = localNumber.substr(1);
                }
                if (localNumber.length === numLength) {
                    result = "+" + countryCode + "-" + localNumber.slice(0, 3) + "-" + localNumber.slice(3, 6) + "-" + localNumber.slice(6);
                } else {
                    // fall-back, we couldn't parse the phone number properly
                    result = text;
                }
            }
            // add tel prefix
            result = "tel:" + (result || '');
            return result;
        };
    }
})();