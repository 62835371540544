(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaCustomizerBuildScenario21', fcaCustomizerBuildScenario21);

	function fcaCustomizerBuildScenario21() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@'
			},
			controllerAs: 'customizerBuildScenario21',
			controller: fcaCustomizerBuildScenario21Controller
		};

		function fcaCustomizerBuildScenario21Controller($scope, $http, $window, userLocation, scenarioOrchestrator) {
			'ngInject';
			const $ctrl = this;

			$ctrl.isActive = false;
			$ctrl.pricingData = [];
			$ctrl.trimGroup = '';
			$ctrl.startingAtPrice = '';
			$ctrl.trimIndex = '';
			$ctrl.totalDiscount = '';
			$ctrl.buildAndPriceUrl = '';
			$ctrl.offersSiteLink = '';
			$ctrl.hasIrisJelly = false;

			$ctrl.$onInit = () => {
				scenarioOrchestrator.registerScenario('fcaPersonalizationScenario_2_1');
				
				$(document).ready(() => {
					const sliverbannerElement = document.querySelector('.HP_sliverBanner + .HP_backgroundBlock');
					if(sliverbannerElement) {
						sliverbannerElement.classList.add('js-top-position-reset');
					}
				});

				$ctrl.setLocalStorageItemData();
			};

			$ctrl.getTrimGroup = acode => {
				$ctrl.pricingData.forEach(item => {
					for (let key in item) {
						if (item.hasOwnProperty(key) && key === 'acode' && item[key] === acode) {
							$ctrl.trimGroup = item['trimGroup'];
							break;
						}
					}
				});
			};

			$ctrl.getStartingAtPrice = (trimGroup) => {
				let lowerStartingAtPrice;

				$ctrl.pricingData.forEach((item, index) => {
					for (let key in item) {
						if (item.hasOwnProperty(key) && item['trimGroup'] === trimGroup && key === 'startingAtPrice') {
							if (lowerStartingAtPrice === undefined || item[key] < lowerStartingAtPrice) {
								lowerStartingAtPrice = item[key];
								$ctrl.trimIndex = index;
							}
						}
					}
				});
				$ctrl.startingAtPrice = $ctrl.formatPrice(lowerStartingAtPrice);
			};

			$ctrl.getTotalDiscount = () => {
				let discount;
				for(let i = 0; i < $ctrl.pricingData.length; i++) {
					if (i === $ctrl.trimIndex) {
						discount = $ctrl.pricingData[i].cash.incentivesAmount;
						break;
					}
				}
				$ctrl.totalDiscount = $ctrl.formatPrice(discount);
			};

			$ctrl.formatPrice = price => {
				let separator = document.documentElement.lang === 'en' ? ',' : ' ';
				let formatPrice = price.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
				if (price.includes(".")) {
					formatPrice = formatPrice.slice(0, -3);
				}
				return formatPrice;
			};

			$ctrl.setBuildAndPriceUrl = () => {
				$ctrl.buildAndPriceUrl =
					`/${document.documentElement.lang}/build-and-price/` +
					`${$ctrl.brandCode}/` +
					`${$ctrl.nameplateCode}/` +
					`${$ctrl.modelYear}/` +
					`${$ctrl.modelYearId}/${$ctrl.acode}` +
					`#scratchsave=${$ctrl.scratchSave}`;
			};

			$ctrl.getOffersSiteLink = () => {
				var region = userLocation.getLocationRegion();
				var offerPath = 'current-offers';
				if(document.documentElement.lang === "fr") {
					offerPath = 'offres-courantes';
				}

				$ctrl.offersSiteLink = '/' + document.documentElement.lang +
					`/${offerPath}/${region}` +
					`/${$ctrl.nameplateCode}`;
			}

			$ctrl.getLocalStorageItem = () => {
				let result = {};
				let storageItem = $window.localStorage.getItem($ctrl.getLocalStorageKey());
				if (storageItem) {
					result = JSON.parse(storageItem);
				}
				return result;
			};

			$ctrl.getLocalStorageKey = () => {
				return 'fcaPersonalizationScenario_2';
			}

			$ctrl.setLocalStorageItemData = () => {

				let trigger = scenarioOrchestrator.canShowModal('fcaPersonalizationScenario_2_1');
				console.debug('scenario 2.1 triggered', trigger);

				if (trigger) {
					let data = $ctrl.getLocalStorageItem();
					$ctrl.isActive = true;

					$ctrl.acode = data.configuration.acode;
					$ctrl.nameplateCode = data.configuration.nameplateCode;
					$ctrl.modelYearId = data.configuration.modelYearId;
					$ctrl.brand = data.configuration.brand;
					$ctrl.brandCode = data.configuration.brand;
					$ctrl.modelYear = data.configuration.year;
					$ctrl.scratchSave = data.configuration.scratchSave;

					$ctrl.jellyUrl = data.legacyJellyUrl;
					if (!$ctrl.jellyUrl) {
						$ctrl.jellyUrl = data.irisJellyUrl;
						$ctrl.hasIrisJelly = true;
					}

					$ctrl.nameplateName = document.documentElement.lang === 'en' ? data.vehicleName.en : data.vehicleName.fr;

					$ctrl.setPricingData();
					$ctrl.setBuildAndPriceUrl();
					$ctrl.getOffersSiteLink();
				} else {
					$ctrl.isActive = false;
				}
			};

			$ctrl.setPricingData = () => {
				var province = userLocation.getLocationProvince();
				var modelYearId = $ctrl.modelYearId;
				var brand = $ctrl.brandCode;
				var nameplateCode = $ctrl.nameplateCode;
				var modelYear = $ctrl.modelYear;
				var basePath = $ctrl.baseUrl;

				var url = `${basePath}/api/buildandprice/trims/prices?provinceCode=${province}&modelYearId=${modelYearId}&brand=${brand}&nameplate=${nameplateCode}&modelYear=${modelYear}`;
				$http.get(url).then(success, error);
				function success(response) {
					$ctrl.pricingData = response.data;
					if ($ctrl.pricingData) {
						$ctrl.getTrimGroup($ctrl.acode);
						$ctrl.getStartingAtPrice($ctrl.trimGroup);
						$ctrl.getTotalDiscount();
					}
				};
				function error(error) {
					console.error('Response error', error);
				};
			}
		}
	}
})();
