(function() {
	angular
		.module('fca.brandApp')
		.directive('uconnectBackToTop', uconnectBackToTop);

	function uconnectBackToTop() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'backToTop',
			controller: uconnectBackToTopController,
		};

		function uconnectBackToTopController() {
			this.$postLink= () => {
				$(window).scroll(function() {
					if ($(this).scrollTop() > 100) {
						$('#scroll-top-controller').fadeIn();
					} else {
						$('#scroll-top-controller').fadeOut();
					}
				});
				$('#scroll-top-controller').click(function() {
					$("html, body").animate({ scrollTop: 0 }, 600);
					return false;
				});
			}
		}
	}
})();
