(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaPanoArea', fcaAlfaPanoArea);

	function fcaAlfaPanoArea() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'alfaAlfaPanoArea',
			controller: fcaAlfaPanoAreaController
		};


		function fcaAlfaPanoAreaController($scope, $element, functionExecutionControl, matchmedia, FCA_MQ_LAYOUT) {
			'ngInject';

			const $ctrl = this;

			$ctrl.$postLink = () => {
				window.addEventListener('load', () => {
					if ($element.hasClass('-isVideo')) {
						const panoVideo = $element.find('.pano-bg video');
						const panoBoxContent = $element.find('.pano-content');

						if (matchmedia.is(FCA_MQ_LAYOUT.MOBILE_FULL)) {
							$ctrl.setContentHeight($element, panoVideo, panoBoxContent);
						}

						$(window).on('resize', functionExecutionControl.debounce(() => {
							if (matchmedia.is(FCA_MQ_LAYOUT.MOBILE_FULL)) {
								$ctrl.setContentHeight($element, panoVideo, panoBoxContent)
							}
							if (matchmedia.is(FCA_MQ_LAYOUT.NOT_MOBILE)) {
								$ctrl.resetContentHeight(panoBoxContent);
							}
						}, 250) );
					}
				});
			}

			$ctrl.setContentHeight = (container, video, content) => {
				content.css({'top': video.height(), 'height': container.height() - video.height()});
			}

			$ctrl.resetContentHeight = (content) => {
				content.css({'top': '', 'height': ''});
			}
		}
	}
})();
