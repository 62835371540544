/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.alertWindow')
        .component('buildAndPriceAlertWindow', {
            controller: BuildAndPriceAlertWindow,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/alert-window/alert-window.html'
        });

    function BuildAndPriceAlertWindow($scope, $rootScope, $element, $document, configService, $timeout) {
        'ngInject';

        let $ctrl = this;

        $ctrl.options = [];

        $ctrl.selectedOptions = [];

        $ctrl.unselectedOptions = [];

        $ctrl.mustHaveOptions = [];

        $ctrl.mainOption = {};

        $ctrl.currentScratchSave = "";

        $ctrl.currentState = "";

        $ctrl.language = "";

        $ctrl.newConfig = {};

        $ctrl.validOption = true;

        $ctrl.alertInReviewMode = false;

        $ctrl.showLoading = false;

        const OPTION_RESPONSE_STATUS = Object.freeze({
            COMPLETE: 'COMPLETE',
            MUSTHAVE: 'MUSTHAVE'
        });

        const PACKAGES_ECC = '0002';

        var lastScroll = 0;

        $ctrl.$onInit = () => {
            $ctrl.language = configService.getLanguage();

            $scope.$on('options-selector:show-missed-alert', (_, data) => {
                $ctrl.alertInReviewMode = true;

                if(data.validOption !== null) {
                    $ctrl.validOption = data.validOption
                }
                
                $ctrl.showAlert();

                $timeout(() => {
                    $ctrl.setFocusTrap();
                });
            });

            $scope.$on('options-selector:show-loading', () => {
                $ctrl.showLoadingIndicator();
            });

            window.addEventListener('scroll', event => {
                if (!$ctrl.scrollPaused) {
                    lastScroll = window.scrollY || window.pageYOffset;
                }
            });

            // When the director receives new options,
            $scope.$on('options-selector:select-options', (_, data) => {
                $ctrl.wipeOptions();
                $ctrl.hideLoadingIndicator();
                $ctrl.alertInReviewMode = !$ctrl.shouldShowAlert();
                let optionResult = data.optionResult;
                $ctrl.currentScratchSave = optionResult.scratchSave;

                $ctrl.lastOptions = $ctrl.options;
                $ctrl.options = data.optionResult.options;
                $ctrl.newConfig = data.optionResult.configuration;
                $ctrl.mainOption = data.selectedOption;

                // If user has selected not to see alerts or the selected option does not include/require further action.
                // We do not show the alert window.
                // If the user has selected not to see alerts but the selected option has additionnal content/options 
                // we do not show the alert window but we save the would be alert's content to show it later if requested.
                // If the user has selected not to see alerts but the selected option requires further action we show the alert
                // window to prompt the user for his choice.
                if (optionResult.status === OPTION_RESPONSE_STATUS.COMPLETE) {
                    $ctrl.currentState = OPTION_RESPONSE_STATUS.COMPLETE;
                    $ctrl.selectedOptions = optionResult.delta.selected;
                    $ctrl.unselectedOptions = optionResult.delta.unselected;
                    $ctrl.validOption = true;

                    if ($ctrl.validateCompleteOptions(optionResult)) {
                        const deltaOptionsCount = $ctrl.getOptionsAmount();
                        const pricingDeltaWarrantsAlert = parseInt(optionResult.delta.pricingDelta.msrp, 10) !== 0;
                        const optionResultWarrantsAlert = (deltaOptionsCount > 1 && pricingDeltaWarrantsAlert) || deltaOptionsCount > 2;
                        if ($ctrl.shouldShowAlert() && data.showAlert && optionResultWarrantsAlert) {
                            $ctrl.showAlert();
                        } else {
                            if (optionResultWarrantsAlert && data.showAlert) {
                                $ctrl.setAlertMissedFlag($ctrl.options);
                                $ctrl.setAlertMissedFlag([$ctrl.mainOption]);
                            } else {
                                $rootScope.$broadcast('alert-window:focus-on-target');
                            }
                            $ctrl.confirmOptions();
                        }
                    } else {
                        $ctrl.confirmOptions();
                        $ctrl.showInvalidCompleteOptionAlert();
                    }
                } else if (optionResult.status === OPTION_RESPONSE_STATUS.MUSTHAVE) {
                    $ctrl.showMustHaveAlert(optionResult);
                }

                $timeout(() => {
                    $ctrl.setFocusTrap();
                });
            });
        };

        $ctrl.showInvalidCompleteOptionAlert = () => {
            $ctrl.validOption = false;
            $ctrl.removeLastMissedAlert();
            $ctrl.wipeOptions();
            $ctrl.showAlert();
        }

        $ctrl.showMustHaveAlert = (optionResult) => {
            $ctrl.validOption = $ctrl.validateMustHaveOptions(optionResult);
            if ($ctrl.validOption) {
                $ctrl.currentState = OPTION_RESPONSE_STATUS.MUSTHAVE;
                $ctrl.mustHaveOptions = optionResult.options;
                $ctrl.mainOption = $ctrl.mustHaveOptions[0];
            } else {
                $ctrl.removeLastMissedAlert();
                $ctrl.wipeOptions();
            }
            $ctrl.showAlert();
        }

        $ctrl.setAlertMissedFlag = (options) => {
            options.forEach(option => {
                option.missedAlert = option.code === $ctrl.mainOption.code;
            })
        }

        $ctrl.validateCompleteOptions = (optionResult) => {
            return optionResult.options.length > 0 ||
                (optionResult.delta !== {} &&
                    (optionResult.delta.selected.length > 0 ||
                        optionResult.delta.unselected.length > 0)
                );
        }

        $ctrl.validateMustHaveOptions = (optionResult) => {
            let result = true;
            optionResult.options.forEach(element => {
                if (element.ecc === PACKAGES_ECC) {
                    result = false;
                    return;
                }
            });
            return result;
        }

        $ctrl.selectOption = (option) => {
            configService.selectOption(option, $ctrl.currentScratchSave, $ctrl.currentState);
            $ctrl.wipeOptions();
        };

        $ctrl.removeLastMissedAlert = () => {
            $ctrl.setAlertMissedFlag($ctrl.lastOptions);
        }

        $ctrl.confirmOptions = () => {
            configService.setScratchSave($ctrl.currentScratchSave);
            configService.resetSavedConfiguration();
            configService.parseConfiguration($ctrl.newConfig);
            configService.broadcastToTrimUpsell();
            $rootScope.$broadcast('alert-window:select-options-proceed', { options: [$ctrl.mainOption] });
            $rootScope.$broadcast('alert-window:select-options-proceed', { options: $ctrl.options });
            if ($element.hasClass("is-active")) {
                $ctrl.hideAlert(true);
            }
            // After options are confirmed, propagate the new configuration
            $rootScope.$broadcast('packages-selector:pricing', { pricing: $ctrl.newConfig.pricing });
            $rootScope.$broadcast('options-selector:reset-calculator-parameters', {
                acode: configService.acode,
                modelYearId: configService.modelYearId,
                nameplateCode: configService.nameplateCode,
                brandCode: configService.brandCode,
                trimName: configService.selectedTrim.trimName,
                year: configService.year,
                scratchSave: configService.scratchSave,
                packageAndOptionsCode: configService.extractPackageAndOptionsCode()
            });
        };

        $ctrl.cancel = () => {
            $ctrl.hideAlert(true);
            if (!$ctrl.alertInReviewMode) {
                $ctrl.wipeOptions();
            }
        };

        $ctrl.toggleAlerts = () => {
            configService.toggleShowAlerts();
        };

        $ctrl.showAlert = () => {
            $element.addClass("is-active");
            if (!$ctrl.showLoading) {
                $ctrl.pauseScrolling();
                $document.on('click', function (e) {
                    if ($element[0] === e.target) {
                        $ctrl.cancel();
                    }
                });
            }
        };

        $ctrl.focusTrap = (e) => {

            const focusableElements = $element.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];

            if (e.key === 'Tab' || e.code === 'Tab') {
                if ( e.shiftKey ) {
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                }
            } else if(e.key === 'Escape' || e.code === 'Escape') {
                $ctrl.cancel();
            } else {
                return;
            }
        };

        $ctrl.setFocusTrap = () => {
            const focusableElements = $element.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            const firstFocusableElement = focusableElements[0];

            firstFocusableElement.focus();

            $element[0].addEventListener('keydown', $ctrl.focusTrap);
        };

        $ctrl.hideAlert = (shouldFocus) => {
            $ctrl.resumeScrolling();
            $element.removeClass("is-active");
            $document.off('click');
            $element[0].removeEventListener('keydown', $ctrl.focusTrap);
            $rootScope.$broadcast('alert-window:alert-hidden', {focusOnOption: shouldFocus});
        };

        $ctrl.pauseScrolling = () => {
            $ctrl.scrollPaused = true;
            let heightValue = 'calc(100% + ' + lastScroll + 'px)';

            $('body').addClass('noScroll')
                .css({
                    'top': -lastScroll,
                    'height': heightValue
                });
        }

        $ctrl.resumeScrolling = () => {
            $ctrl.scrollPaused = false;
            $('body').removeClass('noScroll')
                .removeAttr('style');

            if(lastScroll !== undefined) {
                window.scrollTo(0, lastScroll);
            }
        }

        $ctrl.showLoadingIndicator = () => {
            $ctrl.showLoading = true;
            $element.addClass('no-background');
            $ctrl.showAlert();
        }

        $ctrl.hideLoadingIndicator = () => {
            $ctrl.hideAlert(false);
            $ctrl.showLoading = false;
            $element.removeClass('no-background');
        }

        $ctrl.wipeOptions = () => {
            $ctrl.selectedOptions = [];
            $ctrl.unselectedOptions = [];
            $ctrl.mustHaveOptions = [];
            $ctrl.mainOption = undefined;
            $ctrl.currentState = '';
        };

        $ctrl.getOptionsAmount = () => {
            return $ctrl.selectedOptions.length + $ctrl.unselectedOptions.length;
        }

        $ctrl.shouldShowAlert = () => {
            return configService.getShowAlerts();
        }
    }
})();
