
(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaNameplateHighlights', fcaNameplateHighlights);

    function fcaNameplateHighlights() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$fcaNameplateHighlights',
            controller: fcaNameplateHighlightsController
        };

        function fcaNameplateHighlightsController($scope, $element, $timeout, fcaVideoFactory, functionExecutionControl, functionDynamicSizing) { // eslint-disable-line max-len
            'ngInject';

            let $ctrl = this;
            $ctrl.slider = $element.find('[data-nameplate-highlight-slider]');

            $ctrl.$onInit = () => {
                $scope.$$postDigest(() => {
                    $ctrl.initYoutubeVideo();
                    $ctrl.initCarousel();

                    $(window).on('load resize orientationchange', () => {
                        $ctrl.initCarousel();
                    });

                    const videoElements = $element.find('video');
                    if(videoElements.length > 0) {
                        videoElements.each(index => {
                            // remove automatic HTML video controls
                            videoElements[index].removeAttribute( "controls" );
                        });
                    }

                    $ctrl.titleElements = $element.find('.FTF-highlights__tile .FTF-highlights__title');
                    $ctrl.descElements = $element.find('.FTF-highlights__tile .FTF-highlights__short-desc');

                    $timeout(() => {
                        $ctrl.updateAllDynamicHeight();
                    });

                    window.addEventListener('resize', (event) => {
                        functionExecutionControl.debounce($ctrl.updateAllDynamicHeight(), 400);
                    });
                });
            };

            $ctrl.initCarousel = () => {
                /* Initializes a slick carousel only on mobile screens */
                if ($(window).width() > 768) {
                    if ($ctrl.slider.hasClass('slick-initialized')) {
                        $ctrl.slider.slick('unslick');
                    }
                } else {
                    if (!$ctrl.slider.hasClass('slick-initialized')) {
                        $ctrl.slider.slick({
                            slidesToShow: 1,
                            mobileFirst: true,
                        });
                    }
                }
            };

            $ctrl.playVideo = ($event) => {
                const currentVideo = $($event.currentTarget)
                    .closest('.FTF-highlights__media')
                    .find('video')[0];

                if(currentVideo) {
                    currentVideo.play();
                    currentVideo.nextElementSibling.classList.add('FTF-highlights__video-control--is-playing');
                }
            };

            $ctrl.pauseVideo = ($event) => {
                const currentVideo = $($event.currentTarget)
                    .closest('.FTF-highlights__media')
                    .find('video')[0];

                if(currentVideo) {
                    currentVideo.pause();
                    currentVideo.nextElementSibling.classList.remove('FTF-highlights__video-control--is-playing');
                }
            };

            $ctrl.initYoutubeVideo = () => {
                const youtubeContainers = $element.find('.FTF-highlights__media .youtube-player-container');

                if(youtubeContainers.length > 0) {
                    youtubeContainers.each(index => {
                        const ytPlayerId = youtubeContainers[index].dataset.youtubeContainerId;
                        const ytVideoId = youtubeContainers[index].dataset.youtubeVideoId;
                        const loop = youtubeContainers[index].dataset.youtubeVideoLoop === 'true';
                        const autoplay = youtubeContainers[index].dataset.youtubeVideoAutoplay === 'true';

                        let player = null;

                        fcaVideoFactory.onAPIReady.then(() => {
                            player = new YT.Player(ytPlayerId, {
                                height: '390',
                                width: '640',
                                host: 'https://www.youtube.com',
                                videoId: `${ytVideoId.replace('embed/', '')}`,
                                playerVars: {
                                    rel: 0,
                                    showinfo: 0,
                                    modestbranding: 1,
                                    ecver: 2,
                                    autoplay: autoplay
                                },
                                events: {
                                    'onStateChange': e => {
                                        // video done
                                        if(e.data === 0 && loop) {
                                            player.playVideo();
                                        }
                                    }
                                }
                            });
                        });
                    });
                }
            };

            $ctrl.updateAllDynamicHeight = () => {
                functionDynamicSizing.equalHeight($ctrl.titleElements);
                functionDynamicSizing.equalHeight($ctrl.descElements);
            };
        }
    }
})();
