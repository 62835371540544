(function() {
    'use strict';

    angular
        .module('fca.uiPanels')
        .directive('fcaGoogleDirectionsLink', GoogleDirectionsLink);

    function GoogleDirectionsLink() {
        'ngInject';

        return {
            restrict: 'EA',
            controller: function($scope, $translate) {
                'ngInject';

                this.locationData = '';
                this.addressData = '';

                this.$onInit = () => {
                    this.lbl = this.lblKey;
                };

                this.$onChanges = (obj) => {
                    // Parse location data
                    if (obj.hasOwnProperty('location')) {
                        if (obj.location && obj.location.previousValue !== 'UNINITIALIZED_VALUE') {
                            let location = obj.location.currentValue;
                            if (location) {
                                this.locationData = `${location.latitude},${location.longitude}`;
                            }
                        }
                    }

                    // Parse address data
                    if (obj.hasOwnProperty('address')) {
                        this.addressData = obj.address.currentValue
                            .replace(/[,\-\(\)]+/g, '')
                            .replace(/[ ]/g, '+');
                    }

                    this.reloadUrl();
                };

                // Build the URL based on the previously compiled values
                this.reloadUrl = () => {
                    if (this.locationData.length) {
                        let url = `//www.google.com/maps/dir/${this.locationData}/`;

                        if (this.addressData.length) {
                            url += this.addressData;
                        }

                        this.url = url;
                    }
                };
            },
            controllerAs: '$dirCtrl',
            scope: {},
            bindToController: {
                address: '@',
                lblKey: '@',
                location: '<',
                directionIcon: '<',
                analyticsCategory: '@',
                analyticsId: '@',
                analyticsValue: '@'
            },
            template: '<a ng-href="{{ $dirCtrl.url }}" target="_blank" data-analyticscategory="{{$dirCtrl.analyticsCategory}}" data-analyticsid="{{$dirCtrl.analyticsId}}" data-analyticsvalue="{{$dirCtrl.analyticsValue}}">' +
            '<i ng-if="$dirCtrl.directionIcon" class="fcaicon fcaicon-get-directions"></i>' +
            '<span>{{ $dirCtrl.lblKey }}</span>' +
            '<i ng-if="$dirCtrl.directionIcon" class="fcaicon fcaicon-chevron-right"></i></a>'
        };
    }
})();
