(function() {
	'use strict';

	angular
		.module('fca.brandApp')
		.directive('fcaValidatorPhone', fcaValidatorPhone);

	function fcaValidatorPhone() {
		'ngInject';
		
		let regExp = /^[2-9][0-9]{2}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;

		return {
			link: Validator,
			require: 'ngModel',
			restrict: 'A'
		};

		function Validator($scope, $elt, $attrs, $ctrl) {
			$ctrl.$validators['validator-phone'] = (modelValue, viewValue) => {
				if ($ctrl.$isEmpty(modelValue)) {
					return true;
				} else if (!$ctrl.$error.phone && regExp.test(viewValue)) {
					return true;
				}

				return false;
			};
		}
	}
})();
