(function() {
    'use strict';

    angular
        .module('fca.dealerDetailsMap', [
            'fca.gmap'
        ])
        .controller('dealerMapCtrl', function() {
            'ngInject';

            /**
             * [dealers description]
             * @type {Array}
             */
            this.dealers = [];

            /**
             * [location description]
             * @type {[type]}
             */
            this.location = null;

            /**
             * [selected description]
             * @type {[type]}
             */
            this.selected = null;

            this.options = {
                draggable: false,
                zoomControl: false,
                maxZoomLevel: 18,
                zoomLevel: 15,
                clickable: true,
                responsive: true,
                markerActive: true,
                withLabelIndex: false,
                withInfoWindow: true,
                fitWithingBounds: false
            };

            this.$onInit = () => {
                this.initialize();
            };

            this.$onChanges = (o) => {
                this.initialize();
            };

            this.initialize = () => {
                if (this.isReady()) {
                    let lat = this.latitude;
                    let lng = this.longitude;
                    let dealer = {
                        latitude: lat,
                        longitude: lng
                    };

                    this.dealers.push(dealer);
                    this.location = dealer;
                    this.selected = dealer;

                    if (this.closeInfoWindow) {
                        this.closeInfoWindow = (this.closeInfoWindow === 'true');
                    }

                    if (this.clickable) {
                        this.options.clickable = (this.clickable === 'true');
                    }

                    if (this.maxZoom) {
                        this.options.maxZoomLevel = parseInt(this.maxZoom);
                    }

                    if (this.zoomLevel) {
                        this.options.zoomLevel = parseInt(this.zoomLevel);
                    }
                }
            };

            this.isReady = () => {
                let isReady = false;
                if (this.latitude && this.longitude) {
                    if (this.latitude !== '' && this.longitude !== '') {
                        isReady = true;
                    }
                }

                return isReady;
            };
        })
        .component('dealerMap', {
            controller: 'dealerMapCtrl',
            controllerAs: '$mapCtrl',
            templateUrl: '/dealer-details/map.html',
            bindings: {
                latitude: '@',
                longitude: '@',
                closeInfoWindow: '@',
                clickable: '@',
                maxZoom: '@',
                zoomLevel: '@'
            }
        });
})();
