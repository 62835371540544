(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaJellyLoader', AlfaJellyLoader);

	function AlfaJellyLoader() {
		const $service = this;

		const loadImage = pending => {
			pending.image = new Image();

			return new Promise((resolve, reject) => {
				pending.image.onload = () => resolve(pending);
				pending.image.onerror = reject;

				pending.image.src = pending.url;
			});
		}

		$service.loadImages = (
			preloadKey,
			urlBuilder,
			firstPov,
			viewpoints,
			onImageLoad
		) => {

			const firstToLoad = {
				preloadKey,
				pov: firstPov,
				url: urlBuilder(firstPov),
			};

			const loadLater = viewpoints.map(pov => ({
				preloadKey,
				pov,
				url: urlBuilder(pov),
			}));

			loadImage(firstToLoad)
				.then(loaded => {
					onImageLoad(loaded);

					loadLater.forEach(pending => {
						loadImage(pending).then(onImageLoad);
					});
				});
		}
	}
})();
