(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.directive('geolocationDistance', GeoDistance);

	let tpl = `
		<span class="fcaicon fcaicon-adress-location"></span> {{ $distCtrl.distance }}&nbsp;km
	`;

	function GeoDistance() {
		'ngInject';

		return {
			controller: function($filter) {
				'ngInject';

				this.distance = null;

				this.$onChanges = (o) => {
					let {
						location,
						latitude
					} = o;

					if (location && ng.isObject(location.currentValue)) {
						location = location.currentValue;
						this.calculateDistance(location);
					}
					
					if (latitude && String(latitude.currentValue).length > 0) {
						this.calculateDistance();
					}
				};

				this.calculateDistance = (location) => {
					let lat = this.latitude;
					let lng = this.longitude;
					if (location === undefined) {
						location = this.location;
					}
					
					this.distance = $filter('distance')(this.location, {
						latitude: lat,
						longitude: lng
					});
				};
			},
			controllerAs: '$distCtrl',
			restrict: 'EA',
			bindToController: {
				location: '<',
				latitude: '@',
				longitude: '@'
			},
			template: tpl
		};
	}
})(angular);
