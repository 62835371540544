/**
 * Directive to expose mediaquery states to the UI
 */
(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaLayoutTransformer', fcaLayoutTransformer);

    function fcaLayoutTransformer() {
        return {
            restrict: 'A',
            controllerAs: 'transformer',
            controller: FcaLayoutTransformerController
        };

        function FcaLayoutTransformerController(matchmedia, FCA_MQ_LAYOUT) {
            'ngInject';

            let vm = {
                isTinyPlus: false,
                isXSmallPlus: false,
                isSmallPlus: false,
                isMediumPlus: false,
                isLargePlus: false,
                isXLargePlus: false,

                isMobile: false,
                isMobileLandscape: false,
                isTablet: false,
                isDesktop: false,
                isDesktopFooter: false,

                isDesktopSmall: false,
                isDesktopLarge: false,

                isNotMobile: false,
                isNotDesktop: false,

                isRetina: false
            };

            matchmedia.on(FCA_MQ_LAYOUT.TINY_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isTinyPlus = true;
                } else {
                    vm.isTinyPlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.XSMALL_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isXSmallPlus = true;
                } else {
                    vm.isXSmallPlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.SMALL_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isSmallPlus = true;
                } else {
                    vm.isSmallPlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.MEDIUM_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isMediumPlus = true;
                } else {
                    vm.isMediumPlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.LARGE_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isLargePlus = true;
                } else {
                    vm.isLargePlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.XLARGE_PLUS, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isXLargePlus = true;
                } else {
                    vm.isXLargePlus = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.MOBILE, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isMobile = true;
                } else {
                    vm.isMobile = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.MOBILE_LANDSCAPE, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isMobileLandscape = true;
                } else {
                    vm.isMobileLandscape = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.TABLET, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isTablet = true;
                } else {
                    vm.isTablet = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isDesktop = true;
                } else {
                    vm.isDesktop = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_SMALL, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isDesktopSmall = true;
                } else {
                    vm.isDesktopSmall = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_FOOTER, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isDesktopFooter = true;
                } else {
                    vm.isDesktopFooter = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_LARGE, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isDesktopLarge = true;
                } else {
                    vm.isDesktopLarge = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.NOT_MOBILE, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isNotMobile = true;
                } else {
                    vm.isNotMobile = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.NOT_DESKTOP, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isNotDesktop = true;
                } else {
                    vm.isNotDesktop = false;
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.RETINA, function(mediaQueryList) {
                if (mediaQueryList.matches) {
                    vm.isRetina = true;
                } else {
                    vm.isRetina = false;
                }
            });

            return vm;
        }
    }
})();
