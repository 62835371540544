(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaPrintPage', fcaPrint);

	function fcaPrint() {
		return {
			restrict: 'A',
			scope: true,
			controller: FcaPrintController,
			controllerAs: 'fcaPrintPage'
		};
	}

	function FcaPrintController($timeout) {
		'ngInject';

		this.printPage = () => {
			$timeout(() => {
				window.print();
			});
		};
	}
})();
