(function () {
	angular
		.module('fca.buyOnlineAccessories')
		.directive('fcaBuyOnlineAccessories', FcaBuyOnlineServices);

	function FcaBuyOnlineServices() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				selectedAccessories: '@',
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineAccessoriesCtrl
		};

		function FcaBuyOnlineAccessoriesCtrl($scope, $rootScope, externalConfigLoader, fcaBuyOnlineAccessoriesService) {
			'ngInject';

			let $ctrl = this;

			$ctrl.$onInit = () => {

				// Initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

				// Load existing services selected

				let vin = $ctrl.vin;

				let accessories = fcaBuyOnlineAccessoriesService.getAccessoriesSelection(vin);
				if (accessories != null) {
					$ctrl.selectedAccessories = accessories;
				}
			};

			$ctrl.haveSelectedAccessoriesChanged = () => {
				let selected = $ctrl.selectedAccessories;
				let vin = $ctrl.vin;
				fcaBuyOnlineAccessoriesService.updateAccessoriesSelection(vin, selected);

				$rootScope.$broadcast('fca-custom-order:custom-order-accessories-review-update');
			}
		}
	}
})();
