(function (ng) {
	angular
	.module('fca.userAccount.myGarage.vehicleTile')
	.component('fcaVehicleTile', {
		controller: FcaVehicleTile,
		controllerAs: '$vehicleTileCtrl',
		templateUrl: '/shared/user-account/my-garage/vehicle-tile.html',
		bindings: {
			index: "@",
			uaVehicle: "<",
			userLocation: "<",
			detailsUrl: "<",
			sniSearchUrl: "<",
			eshopUrl: "@",
			eshopActivated: "@",
			eshopLabel: "@",
			eshopWindow: "@",
			netDisclaimer: "@",
			selectable: "<",
			isSelected: "=",
			isFlipped: "=",
			isEditable: "=",
			updateVehicle: "&",
			isCustomOrder: "=",
		}
	});

	function FcaVehicleTile($scope, $rootScope, $http, configService, optionsSummaryService, trimService, optionCategoryService, fcaUserIdPersistentService) {
		'ngInject';

		const mediaQueries = ['MOBILE', 'MOBILE_RETINA', 'TABLET', 'TABLET_RETINA', 'DESKTOP', 'DESKTOP_RETINA'];

		const $vehicleTileCtrl = this;

		const currentLanguage = FCA_SITES_CONFIG.language;

		$vehicleTileCtrl.editMode = false;

		$vehicleTileCtrl.paymentMode = "cash";
		$vehicleTileCtrl.pageIsReady = false;

		$vehicleTileCtrl.detailTarget = "_top";

		$vehicleTileCtrl.vehicle = {};
		$vehicleTileCtrl.vehicleMyTitle = "";
		$vehicleTileCtrl.vehicleMyNotes = "";

		$vehicleTileCtrl.contactADealerUrl = "";

		$vehicleTileCtrl.hashData = {
			paymentMode: $vehicleTileCtrl.paymentMode
		};

		$vehicleTileCtrl.selectedTiles = [];

		$vehicleTileCtrl.$onInit = () => {
			// Net Amount disclaimer is different in build&price and SNI
			$vehicleTileCtrl.disclaimers = {
				dealer: angular.element('[data-disclaimer-code="leg-dealer-pricing"]').html(),
				regular: angular.element('[data-disclaimer-code="leg-net-amount"]').html(),
				finance: angular.element('[data-disclaimer-code="leg-financing"]').html(),
				lease: angular.element('[data-disclaimer-code="leg-leasing"]').html(),
				legAmountSni: $vehicleTileCtrl.netDisclaimer
			};

			$vehicleTileCtrl.language = FCA_SITES_CONFIG.language;
			$vehicleTileCtrl.contactADealerUrl = buildContactADealerUrl();
			$vehicleTileCtrl.vehicleMyTitle = $vehicleTileCtrl.uaVehicle.title;
			$vehicleTileCtrl.vehicleMyNotes = $vehicleTileCtrl.uaVehicle.notes;

			if ($vehicleTileCtrl.eshopWindow === "true") {
				$vehicleTileCtrl.detailTarget = "_blank";
			}

			fetchData();

		};

		$vehicleTileCtrl.getUserId = () => {
			return fcaUserIdPersistentService.getCookie('userId');
		};

		$vehicleTileCtrl.fetchFeaturesData = () => {

			// we just test one property cause if one is set, all should be set
			if ($vehicleTileCtrl.vehicle.brand) {

				let ajaxUrl = (
					`/api/buildandprice/${$vehicleTileCtrl.language}/` +
					`brand/${$vehicleTileCtrl.vehicle.brand}/` +
					`nameplate/${$vehicleTileCtrl.vehicle.nameplateCode}/` +
					`year/${$vehicleTileCtrl.vehicle.modelYear}/` +
					`modelyear/${$vehicleTileCtrl.vehicle.modelYearId}/` +
					`trims/${$vehicleTileCtrl.vehicle.acode}/` +
					`packages/${$vehicleTileCtrl.vehicle.trimPkg}/` +
					`content`
				);

				$http.get(ajaxUrl).then(response => {
					$vehicleTileCtrl.features = response.data;
				}, error => {
					console.error(error);
				})
			}
		};

		// Get and display options summary for given saved build
		$vehicleTileCtrl.fetchOptionsSummary = (vehicle) => {
			if (vehicle) {
				let params = {
					acode: vehicle.acode,
					language: $vehicleTileCtrl.language,
					packageCode: vehicle.packageCode,
					provinceCode: vehicle.provinceCode,
					modelYearId: vehicle.modelYearId,
					nameplateCode: vehicle.nameplateCode,
					year: vehicle.year,
					brand: vehicle.subBrandCode ? vehicle.subBrandCode : vehicle.brandCode,
					scratchSave: vehicle.scratchsave
				};

				const apiUrlTrim = '/api/buildandprice/' + $vehicleTileCtrl.language + '/brand/' + vehicle.brandCode +
					'/nameplate/' + vehicle.nameplateCode + '/year/' + vehicle.year +
					'/modelyear/' + vehicle.modelYearId + '/trims';

				var vehicleTrim = {};
				// We get the trim and the package, they contain the powertrain information for the option summary.
				$http.get(apiUrlTrim, {cache: true}).then(trimResponse => {
					let trimGroups = trimResponse.data.trimGroups;
					let acode = vehicle.acode;
					let trimGroup = optionCategoryService.getTrimGroupForAcode(acode, trimGroups);

					for (let trimIndex = 0; trimIndex < trimGroup.trims.length; trimIndex++) {
						if (trimGroup.trims[trimIndex].acode === acode) {
							vehicleTrim = trimGroup.trims[trimIndex];
						}
					}
					vehicleTrim = trimGroup.trims.find(trim => trim.acode === acode);

					// We get the options by category for the given.
					$http.post('/api/buildandprice/config', params).then((configResponse) => {
						// Get category object from config.
						let categoryObject = optionCategoryService.getCategoriesObjects(configResponse.data);
						// Format options
						$vehicleTileCtrl.options = optionsSummaryService.formatSelectedOptions(categoryObject,
							vehicleTrim, vehicleTrim.packages[0], trimResponse.data);
					});
				});
			}
		};

		// We need to ensure that the newlines are saved and displayed.
		$vehicleTileCtrl.saveNotes = () => {
			let updatedNotes = {
				title: $vehicleTileCtrl.vehicleMyTitle,
				notes: $vehicleTileCtrl.vehicleMyNotes
			};
			if ($scope.updateNotesForm.vehiculeNotesInput.$invalid) {
				return;
			}
			$vehicleTileCtrl.updateVehicle({'id': $vehicleTileCtrl.uaVehicle.id, 'titleAndNotes': updatedNotes});
			$vehicleTileCtrl.editMode = false;
		};

		$vehicleTileCtrl.isVinMode = () => {
			return $vehicleTileCtrl.uaVehicle.vin !== undefined;
		};

		$vehicleTileCtrl.isBuildMode = () => {
			return $vehicleTileCtrl.uaVehicle.vin === undefined;
		};

		$vehicleTileCtrl.formatNameplateName = (nameplateName, brandName) => {
			if (nameplateName !== undefined && brandName !== undefined) {
				if (nameplateName.toLowerCase().includes(brandName.toLowerCase())) {
					return nameplateName.replace('4xe', '<span class="text-transform-none">4xe</span>', 'i');
				} else {
					return `${brandName} ${nameplateName.replace('4xe', '<span class="text-transform-none">4xe</span>', 'i')}`;
				}
			}
		};

		$vehicleTileCtrl.getAlternateText = () => {
			let altText = '';
			if ($vehicleTileCtrl.vehicle) {
				altText = $vehicleTileCtrl.vehicle.modelYear;

				if ($vehicleTileCtrl.vehicle.nameplateName) {
					let nameplateName = $vehicleTileCtrl.vehicle.nameplateName[$vehicleTileCtrl.language];
					let brandName = nameplateName;

					if ($vehicleTileCtrl.vehicle.brandName) {
						brandName = $vehicleTileCtrl.vehicle.brandName[$vehicleTileCtrl.language];
					}

					altText += ' ' + $vehicleTileCtrl.formatNameplateName(nameplateName, brandName);
				}

				if ($vehicleTileCtrl.vehicle.trimGroup) {
					altText += ' ' + $vehicleTileCtrl.vehicle.trimGroup[$vehicleTileCtrl.language];
				}
			}

			return altText;
		};

		$vehicleTileCtrl.flip = () => {

			$vehicleTileCtrl.isFlipped = !$vehicleTileCtrl.isFlipped;

			if ($vehicleTileCtrl.isFlipped && !isBackfaceDataFetched()) {

				if ($vehicleTileCtrl.isVinMode()) {

					$vehicleTileCtrl.fetchFeaturesData();

				} else if ($vehicleTileCtrl.isBuildMode()) {

					$vehicleTileCtrl.fetchOptionsSummary($vehicleTileCtrl.uaVehicle);
				}
			}
		};


		$vehicleTileCtrl.setCustomOrderVehicleUrl = () => {

			localStorage.setItem("savedCustomOrderVehicle", JSON.stringify($vehicleTileCtrl.uaVehicle));

			$rootScope.$broadcast('custom-order:redirect-page', true);

			const redirectULR = `${window.FCA_SITES_CONFIG.brandSiteUrls[$vehicleTileCtrl.uaVehicle.subBrandCode]}/${currentLanguage}/custom-order/${$vehicleTileCtrl.uaVehicle.brandCode}/${$vehicleTileCtrl.uaVehicle.nameplateCode}/${$vehicleTileCtrl.uaVehicle.year}/${$vehicleTileCtrl.uaVehicle.modelYearId}/${$vehicleTileCtrl.uaVehicle.dealerCode}#step=models`;

			window.location.href = redirectULR;

		};

		let fetchData = () => {

			if ($vehicleTileCtrl.isVinMode()) {
				$vehicleTileCtrl.vehicle = $vehicleTileCtrl.uaVehicle.data;
				$vehicleTileCtrl.pageIsReady = true;

			} else if ($vehicleTileCtrl.isBuildMode()) {

				// for built vehicles section, we wait to have pricing info
				$rootScope.$on('ua:vehiclePricingRetrieved', (event, data) => {

					if ($vehicleTileCtrl.uaVehicle.id === data.vehicleId) {
						$vehicleTileCtrl.vehicle = builtVehicleWithBuiltVehicle(data.vehiclePricing);
						$vehicleTileCtrl.vehicle["jellyUrl"] = buildIrisJellyUrl();
						$vehicleTileCtrl.pageIsReady = true;
					}
				});
			}
		};

		let builtVehicleWithBuiltVehicle = (vehiclePricing) => {

			let finance = {};
			if (vehiclePricing && vehiclePricing.finance) {
				finance = vehiclePricing.finance;
			}
			let lease = {};
			if (vehiclePricing && vehiclePricing.lease) {
				lease = vehiclePricing.lease;
			}

			let vehicle = {
				brand: $vehicleTileCtrl.uaVehicle.subBrandCode ? $vehicleTileCtrl.uaVehicle.subBrandCode : $vehicleTileCtrl.uaVehicle.brandCode,
				nameplateCode: $vehicleTileCtrl.uaVehicle.nameplateCode,
				modelYearId: $vehicleTileCtrl.uaVehicle.modelYearId,
				modelYear: $vehicleTileCtrl.uaVehicle.year,
				acode: $vehicleTileCtrl.uaVehicle.acode,
				trimPkg: $vehicleTileCtrl.uaVehicle.packageCode,
				regularPrice: finance.amount,
				totalDiscount: vehiclePricing.totalDiscount,
				financingValue: finance.payment,
				financingRate: finance.interestRate,
				financingTerm: finance.term,
				financeFrequency: 'weekly',
				leasingValue: lease.payment,
				leasingRate: lease.interestRate,
				leasingTerm: lease.term,
				leasingFrequency: 'weekly'
			};

			if ($vehicleTileCtrl.uaVehicle && $vehicleTileCtrl.uaVehicle.jellyType === 'legacy') {
				vehicle['jellies'] = buildLegacyJelliesObj();
			}

			vehicle['nameplateName'] = {
				en: $vehicleTileCtrl.uaVehicle.nameplateNameEn.replace($vehicleTileCtrl.uaVehicle.year, ''),
				fr: $vehicleTileCtrl.uaVehicle.nameplateNameFr.replace($vehicleTileCtrl.uaVehicle.year, '')
			};
			vehicle['trimGroup'] = {
				en: $vehicleTileCtrl.uaVehicle.trimNameEn.replace('4xe', '<span class="text-transform-none">4xe</span>', 'i'),
				fr: $vehicleTileCtrl.uaVehicle.trimNameFr.replace('4xe', '<span class="text-transform-none">4xe</span>', 'i')
			};

			return vehicle;
		};

		let buildLegacyJelliesObj = () => {
			let legacyJellies = [];
			if ($vehicleTileCtrl.uaVehicle && $vehicleTileCtrl.uaVehicle.jellyUrl) {
				let jellyUrl = $vehicleTileCtrl.uaVehicle.jellyUrl;
				let jellyPath = jellyUrl.substring(jellyUrl.indexOf('jellies/renditions'));

				mediaQueries.forEach((mediaQuery) => {
					legacyJellies.push({
						path: jellyPath,
						resolutionCode: mediaQuery
					});
				});
				return [{
					jellies: legacyJellies
				}];
			}
		};

		let buildIrisJellyUrl = () => {
			let url = '';
			if ($vehicleTileCtrl.uaVehicle.jellyType === 'iris' && $vehicleTileCtrl.uaVehicle.jellyUrl) {
				// jelly url came from build and price (when the configuration has been saved).
				// we need to adapt iris parameters to display correctly jelly in my garage page.
				url = $vehicleTileCtrl.uaVehicle.jellyUrl;
				url = url.replace(/&pov=[^&]*/, '&pov=fronthero');
				url = url.replace(/&width=[^&]*/, '&width=400');
				url = url.replace(/&x=[^&]*/g, '');
				url = url.replace(/&y=[^&]*/, '');
				url = url.replace(/&w=[^&]*/, '');
				url = url.replace(/&h=[^&]*/, '');
				url = url + '&height=255';
			}
			return url;
		};

		let buildContactADealerUrl = () => {
			
			const endpoint = $vehicleTileCtrl.language === "fr" ? 
				"contacter-un-concessionnaire" : "contact-a-dealer";

			let url = window.FCA_SITES_CONFIG.brandSiteUrls[$vehicleTileCtrl.uaVehicle.brandCode] +
				`/${$vehicleTileCtrl.language}/${endpoint}`;

			if ($vehicleTileCtrl.isVinMode()) {

				url += `#vins=${$vehicleTileCtrl.uaVehicle.vin}`;

			} else if ($vehicleTileCtrl.isBuildMode()) {

				let paint = $vehicleTileCtrl.uaVehicle.paintCode
					? $vehicleTileCtrl.uaVehicle.paintCode.substr(0, 3)
					: '';

				url += `#brand=${$vehicleTileCtrl.uaVehicle.brandCode}` +
					`&nameplate=${$vehicleTileCtrl.uaVehicle.nameplateCode}` +
					`&year=${$vehicleTileCtrl.uaVehicle.year}` +
					`&modelYearId=${$vehicleTileCtrl.uaVehicle.modelYearId}` +
					`&trim=${$vehicleTileCtrl.language === 'fr'
						? $vehicleTileCtrl.uaVehicle.trimNameFr
						: $vehicleTileCtrl.uaVehicle.trimNameEn}` +
					`&mfgCode=${$vehicleTileCtrl.uaVehicle.mfgCode}` +
					`&paint=${paint}` +
					`&acode=${$vehicleTileCtrl.uaVehicle.acode}` +
					`&provinceCode=${$vehicleTileCtrl.uaVehicle.provinceCode}` +
					`&scratchSave=${$vehicleTileCtrl.uaVehicle.scratchsave}`;
			}

			return url;
		};

		let isBackfaceDataFetched = () => {
			let result = false;

			if ($vehicleTileCtrl.isVinMode()) {

				result = $vehicleTileCtrl.features !== undefined;

			} else if ($vehicleTileCtrl.isBuildMode()) {

				result = $vehicleTileCtrl.options !== undefined;
			}
			return result;
		};
	}
})(angular);
