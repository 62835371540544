(function () {
	angular
		.module('fca.buyOnlineServices')
		.service('fcaBuyOnlineServicesService', FcaBuyOnlineServicesService);

	function FcaBuyOnlineServicesService($http, fcaBuyOnlineService) {
		'ngInject';
		let $service = this;

		$service.getServiceSelection = (vin) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			let services = checkout.selectedServices;
			return services;
		};

		$service.updateServiceSelection = (vin, services) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			checkout.selectedServices = services;
			// Saving to session storage.
			fcaBuyOnlineService.setInStorage(vin, checkout);
		};
	}
})();
