(function () {
    angular
        .module('fca.salesAppointment')
        .directive('fcaSalesAppointment', FcaSalesAppointment);

    function FcaSalesAppointment() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                brandCode: '<',
                brandCodeString: '@',
                selectedDealer: '<',
                selectedDealerString: '@',
                formInModal: '<?',
                isSelectorDealer: '<?',
                hasDealerStep: '<?',
                isBuyOnline: '<?',
                isLocateADealer: '<?'
            },
            controllerAs: '$salesCtrl',
            controller: FcaSalesAppointmentController
        };

        function FcaSalesAppointmentController($scope, $rootScope, $http, $filter,
                                               $location, $timeout, $element, $sce, gtmAnalytics, fcaSalesAppointmentService) {
            'ngInject';

            const $salesCtrl = this;

            $salesCtrl.selectedDay = null;
            $salesCtrl.localizedSelectedDay = null;
            $salesCtrl.localizedSelectedHour = null;
            $salesCtrl.analyticsSelectedDay = null;
            $salesCtrl.analyticsSelectedHour = null;
            $salesCtrl.selectedHour = null;
            $salesCtrl.selectedDealerSchedule = [];
            $salesCtrl.selectedDealerHours = [];
            $salesCtrl.currentDealer = null;
            $salesCtrl.test = [];
            $salesCtrl.testDrive = "";
            $salesCtrl.language = FCA_SITES_CONFIG.language;

            $salesCtrl.$onInit = () => {
                if ($salesCtrl.brandCodeString
                    && $salesCtrl.selectedDealerString) {
                    $salesCtrl.brandCode = $salesCtrl.brandCodeString;
                    $salesCtrl.selectedDealer = $salesCtrl.selectedDealerString;
                }

                if ($salesCtrl.brandCode && $salesCtrl.selectedDealer) {
                    $salesCtrl.gotDealerInfo($salesCtrl.brandCode,
                        $salesCtrl.selectedDealer);
                    if ($salesCtrl.appointmentDate
                        && $salesCtrl.appointmentTime) {
                        $salesCtrl.selectedDay = $salesCtrl.appointmentDate;
                        $salesCtrl.localizedSelectedDay = fcaSalesAppointmentService.localizeDate($salesCtrl.selectedDay);
                        $salesCtrl.analyticsSelectedDay = $salesCtrl.gtmFormatDate($salesCtrl.selectedDay);
                        $salesCtrl.selectedHour = $salesCtrl.appointmentTime;
                        $salesCtrl.localizedHour = fcaSalesAppointmentService.localizeDate($salesCtrl.selectedHour);
                        $salesCtrl.analyticsSelectedHour = $salesCtrl.gtmFormatHour($salesCtrl.selectedHour);
                    }
                }
                if ($salesCtrl.isBuyOnline) {
                    $salesCtrl.clearStorage();
                }
            };

            $salesCtrl.gtmFormatDate = (date) => {
                let formattedDate = date.replace(/-/g, '\/').replace(/T.+/, '');
                formattedDate = $filter('date')(new Date(formattedDate), 'dd-MM-yy');

                return formattedDate;
            };

            $salesCtrl.gtmFormatHour = (hour) => {
                const [time, modifier] = hour.split(' ');
                var [hours, minutes] = time.split(':');

                return `${hours}${minutes}-${modifier.toLowerCase()}`;
            };

            $salesCtrl.gotDealerAppointmentSchedule = (schedule) => {
                $salesCtrl.dealerSchedule = schedule;
            };

            $salesCtrl.initModal = () => {
                $salesCtrl.gotDealerInfo($salesCtrl.brandCode,
                    $salesCtrl.selectedDealer);
            };

            $salesCtrl.gotDealerInfo = (brandCode, dealerCode) => {
                $http.get(`/data/dealers/${brandCode}/dealer/${dealerCode}`).then((result) => {
                    if (result.status == 200 && result.data) {
                        $salesCtrl.currentDealer = result.data;
                        if(!$salesCtrl.isSelectorDealer) {
                            $rootScope.$broadcast('sales-appointment-dealer:dealer-selected', {dealer: $salesCtrl.currentDealer.code});
                        }
                    } else {
                        console.error("Could not retrieve dealer " + dealerCode);
                    }
                });
            };

            //format yyyy-mm-dd
            /**
             *
             * @param date
             * @returns {string}
             */
            $salesCtrl.formatDate = (date) => {
                var day = date.getDate().toString();
                var month = (1 + date.getMonth()).toString();
                var year = date.getFullYear();
                month = month.length > 1 ? month : '0' + month;
                day = day.length > 1 ? day : '0' + day;
                return year + '-' + month + '-' + day;
            };

            /**
             * convert selectedHour shortTime format to short format
             * Ex "9:00 AM" to MM/dd/yy 9:00 AM
             * @param hour
             */
            $salesCtrl.convertToShortTimeFormat = (time) => {
                let dateTimeString = "";
                let date = $salesCtrl.selectedDay;
                if($salesCtrl.selectedHour && date) {
                    let dateSplit = date.split('-');

                    let timeSplit = time.split(' ');
                    let timeHourMinSplit = timeSplit[0].split(':');

                    let hourValue;
                    if(timeSplit[1] === 'AM') {
                        if(parseInt(timeHourMinSplit[0]) === 12) {
                            hourValue = 0;
                        } else {
                            hourValue = timeHourMinSplit[0];
                        }

                    } else {
                        if(parseInt(timeHourMinSplit[0]) === 12) {
                            hourValue = timeHourMinSplit[0];
                        } else {
                            hourValue = parseInt(timeHourMinSplit[0]) + 12;
                        }
                    }
                    let minValue = timeHourMinSplit[1];
                    dateTimeString = $filter('date')(new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2], hourValue, minValue),'yyyy-MM-dd HH:mm').toString()
                }
                return dateTimeString;
            };

            /**
             * show a warning if the month of the selected day is different than the current one
             */
            $salesCtrl.showIncentivesWarning = () => {
                var show = false;

                if ($salesCtrl.selectedDay != null && $salesCtrl.selectedDay != "") {
                    var formattedSelectedDate = $salesCtrl.selectedDay.replace(/-/g, '\/').replace(/T.+/, '');
                    var selectedDateMonth = new Date(formattedSelectedDate).getMonth();
                    var currentMonth = new Date().getMonth();
                    show = selectedDateMonth != currentMonth;
                }
                return show;
            };

            /**
             * fill the selectedDealerHours with available and unavailable appointments
             * @param brandCode
             * @param dealerCode
             */
            $salesCtrl.getAvailableAppointments = (brandCode, dealerCode) => {
                var formattedDate = $salesCtrl.selectedDay;
                var formattedDateSplitted = formattedDate.split("-");

                $salesCtrl.localizedSelectedDay = fcaSalesAppointmentService.localizeDate($salesCtrl.selectedDay);
                $salesCtrl.analyticsSelectedDay = $salesCtrl.gtmFormatDate($salesCtrl.selectedDay);
                $salesCtrl.test.splice(0, $salesCtrl.test.length);
                $salesCtrl.selectedHour = null;

                //available appointments
                $http.get(`/data/dealers/appointments/available/${brandCode}/${dealerCode}`).then((result) => {
                    if (result.status == 200 && result.data) {
                        var response = result.data;
                        $salesCtrl.selectedDealerSchedule = response.dealerAppointmentAvailabilities;
                        var availableDaysNumber = $salesCtrl.selectedDealerSchedule.length;

                        //clear hours array
                        $salesCtrl.selectedDealerHours.splice(0, $salesCtrl.selectedDealerHours.length);
                        for (var daysIterator = 0; daysIterator < availableDaysNumber; daysIterator++) {
                            if ($salesCtrl.selectedDealerSchedule[daysIterator].day == formattedDate) {
                                //get all available hours of the selectionned day
								for (var hoursIterator = 0; hoursIterator < $salesCtrl.selectedDealerSchedule[daysIterator].appointmentsTime.length; hoursIterator++) {
									var appointmentTime = $salesCtrl.selectedDealerSchedule[daysIterator].appointmentsTime[hoursIterator].split(":");
									var fullHour = new Date(parseInt(formattedDateSplitted[0]), parseInt(formattedDateSplitted[1]) - 1, parseInt(formattedDateSplitted[2]), appointmentTime[0], appointmentTime[1], 0)
									$salesCtrl.selectedDealerHours.push(fullHour);
									$salesCtrl.test.push({hour: fullHour, disable: false});
								}
                                break;
                            }
                        }
                    } else {
                        console.error("could not retrieve available apppointments of " + dealerCode);
                    }
                });

                //unavailable appointments
                $http.get(`/data/dealers/appointments/${brandCode}/${dealerCode}?appointmentsDate=${formattedDate}`).then((result) => {
                    if (result.status == 200 && result.data) {
                        var unavailableAppointments = result.data;
                        if (unavailableAppointments.length > 0) {
                            for (var iteratorAppointments = 0; iteratorAppointments < unavailableAppointments.length; iteratorAppointments++) {
                                var hour = unavailableAppointments[iteratorAppointments].appointmentTime[0];
                                var minutes = unavailableAppointments[iteratorAppointments].appointmentTime[1];
                                var fullHour = new Date(
                                    parseInt(formattedDateSplitted[0]),
                                    parseInt(formattedDateSplitted[1]) - 1,
                                    parseInt(formattedDateSplitted[2]), hour,
                                    minutes, 0)

                                $salesCtrl.test.push(
                                    {hour: fullHour, disable: true});
                            }
                        }
                    } else {
                        console.error(
                            "could not retrieve Unavailable apppointments of "
                            + dealerCode);
                    }
                });

                //inacessible appointments
                $http.get(`/data/dealers/appointments/inaccessible/${brandCode}/${dealerCode}?appointmentsDate=${formattedDate}`).then((result) => {
                    if (result.status == 200 && result.data) {
                        var response = result.data;
                        $salesCtrl.selectedDealerSchedule = response.dealerAppointmentAvailabilities;
                        var availableDaysNumber = $salesCtrl.selectedDealerSchedule.length;

                        //clear hours array
                        $salesCtrl.selectedDealerHours.splice(0, $salesCtrl.selectedDealerHours.length);
                        for (var daysIterator = 0; daysIterator < availableDaysNumber; daysIterator++) {
                            if ($salesCtrl.selectedDealerSchedule[daysIterator].day == formattedDate) {
                                //get all available hours of the selectionned day
								for (var hoursIterator = 0; hoursIterator < $salesCtrl.selectedDealerSchedule[daysIterator].appointmentsTime.length; hoursIterator++) {
									var appointmentTime = $salesCtrl.selectedDealerSchedule[daysIterator].appointmentsTime[hoursIterator].split(":");
									var fullHour = new Date(parseInt(formattedDateSplitted[0]), parseInt(formattedDateSplitted[1]) - 1, parseInt(formattedDateSplitted[2]), appointmentTime[0], appointmentTime[1], 0)
									$salesCtrl.selectedDealerHours.push(fullHour);
									$salesCtrl.test.push({hour: fullHour, disable: true});
								}
                                break;
                            }
                        }
                    } else {
                        console.error("could not retrieve innacessible apppointments of " + dealerCode);
                    }
                });
            };

            /**
             *  set the date and time values and send it to components that need it through the service
             */
            $salesCtrl.selectAppointment = () => {
                //broadcast can be removed
                $rootScope.$broadcast('fca-contact-a-dealer:appointment-choosen', {
                    date: $salesCtrl.selectedDay,
                    time: $salesCtrl.selectedHour,
                    dealerData: $salesCtrl.currentDealer
                });
                $rootScope.$broadcast('fca-sales-appointment:appointment-is-selected');

                let appointmentObject = {
                    date: $salesCtrl.selectedDay,
                    time: $salesCtrl.selectedHour,
                    dealerData: $salesCtrl.currentDealer,
                    testDrive: $salesCtrl.testDrive
                };

                fcaSalesAppointmentService.setInStorage(appointmentObject);
                fcaSalesAppointmentService.appointmentChanged();

                $rootScope.$broadcast('form-dealer-selector:appointment-selected');
            };

            /**
             * SUBMIT the appointment
             * @param brandCode
             * @param dealerCode
             * @param date
             * @param time
             * @constructor
             */
            $salesCtrl.ConfirmAppointment = (brandCode, dealerCode, leadId, date, time, testDrive) => {
                let dateTimeString = $salesCtrl.convertToShortTimeFormat(time);
                if (date!="" && time!="" && leadId!="" && dateTimeString != "Invalid Date") {
                    var postData = {
                        "dealerCode": dealerCode,
                        "leadId": leadId,
                        "appointmentDateTimeString": dateTimeString,
                        "testDrive": $salesCtrl.isBuyOnline?$salesCtrl.testDriveBooked():testDrive,
                    };

                    $http({
                        url: "/data/dealers/appointment",
                        method: "POST",
                        data: JSON.stringify(postData),
                        contentType: "application/json"
                    })
                        .then(response => {
                            if (response.data.success) {
                                $salesCtrl.getAvailableAppointments(brandCode, dealerCode);
                                $rootScope.$broadcast('fca-contact-a-dealer:appointment-is-confirmed');
                                if ($salesCtrl.isBuyOnline) {
                                    $salesCtrl.clearStorage();
                                }
                            } else {
                                $rootScope.$broadcast('fca-contact-a-dealer:appointment-not-confirmed', {response: response.data});
                                console.error("Appointment not confirmed");
                            }
                        }).catch(function (errorResponse) {
                    });
                }
            };

            $scope.$on('fca-contact-a-dealer:appointment-confirmation',
                (event, data) => {
                    $salesCtrl.ConfirmAppointment(data.brandCode, data.dealerCode, data.leadId, data.date, data.time, data.testDrive);
                });

            $salesCtrl.updateHour = () => {
                $salesCtrl.localizedSelectedHour = fcaSalesAppointmentService.localizeHour($salesCtrl.selectedHour);
                $salesCtrl.analyticsSelectedHour = $salesCtrl.gtmFormatHour($salesCtrl.selectedHour);
            };

            $salesCtrl.clearStorage = () => {
                $salesCtrl.selectedDay = "";
                $salesCtrl.localizedSelectedDay = "";
                $salesCtrl.analyticsSelectedDay = "";
                $salesCtrl.selectedHour = "";
                $salesCtrl.localizedSelectedHour = "";
                $salesCtrl.analyticsSelectedHour = "";
                $salesCtrl.testDrive = "";

                let appointmentObject = {
                    date: "",
                    time: "",
                    testDrive: false
                };
                fcaSalesAppointmentService.setInStorage(appointmentObject);
                fcaSalesAppointmentService.appointmentChanged();
            };

            $salesCtrl.cancel = () => {
                $salesCtrl.clearStorage();
                $rootScope.$broadcast('fca-sales-appointment:appointment-is-canceled');
            };

            $salesCtrl.resetAppointment = () => {
                $salesCtrl.selectedDay = "";
                $salesCtrl.localizedSelectedDay = "";
                $salesCtrl.analyticsSelectedDay = "";
                $salesCtrl.selectedHour = "";
                $salesCtrl.localizedSelectedHour = "";
                $salesCtrl.analyticsSelectedHour = "";
                $salesCtrl.test = [];
                $salesCtrl.testDrive = "";
            };

            $scope.$watch('$salesCtrl.selectedDealer', function(newValue, oldValue) {
                if(newValue != oldValue) {
                    $salesCtrl.resetAppointment();
                }
            });

            $salesCtrl.testDriveBooked = () => {
                return $salesCtrl.testDrive === 'yes';
            };

            $scope.$on('fca-sales-appointment:appointment-get-hours',
                (evt, args) => {
                    $salesCtrl.selectedDay = $salesCtrl.formatDate(args.date);
                    $salesCtrl.getAvailableAppointments($salesCtrl.brandCode,$salesCtrl.selectedDealer);
                }
            );
        }
    }
})();
