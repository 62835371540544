(function () {
	angular
		.module('alfaromeo.buildAndPrice.programs-banner')
		.component('alfaProgramsBanner', {
			controller: alfaProgramsBanner,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/programs-banner/alfaromeo-programs-banner.html',
			bindings: {
				programLogoPath: '@',
				programDesc: '@',
				programLogoAltText: '@',
				programShowShopOnlineLink: '@',
				programBaseSniBuildUrl: '@',
				programShowBanner: '@',
				programCtaUrl: '@'
			}
		});

	function alfaProgramsBanner($scope, $attrs, $sce) {
		'ngInject';

		const $ctrl = this;

		$scope.$$postDigest(() => {
			$ctrl.trustedHTMLprogramDesc = $sce.trustAsHtml($ctrl.programDesc);
		});
	}
})();
