(function() {
	angular
		.module('fca.brandApp')
		.directive('vlpModelTray', vlpModelTray);

	function vlpModelTray() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				sendAnalytics: '<',
				analyticscategory: '@',
				analyticsid: '@'
			},
			controllerAs: 'modelTray',
			controller: vlpModelTrayController
		};

		function vlpModelTrayController($scope, $element, gtmAnalytics) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			const $ctrl = this;
			const $slickSlider = $element.find('slick');
			let $isToggleExpanded = false;

			let analyticscategory = '';
			let analyticsid = '';

			$ctrl.$postLink = () => {
				if ($ctrl.sendAnalytics) {
					analyticscategory = $ctrl.analyticscategory;
					analyticsid = $ctrl.analyticsid;

					$element.find('.slick-arrow').on('click', (e) => {
						let analyticsvalue = '';
						if (e.target.classList.contains('slick-prev')) {
							analyticsvalue = '-backward';
						} else {
							analyticsvalue = '-forward';
						}

						$ctrl.trackGtmEvent(analyticsvalue);
					});

				}
			};

			$slickSlider.on('swipe', function(event, slick, direction) {
				if(direction === 'left') {
					$ctrl.trackGtmEvent('-swipe-forward');
				} else {
					$ctrl.trackGtmEvent('-swipe-backward');
				}
			});

			$ctrl.trackGtmEvent = (labelSuffix) => {
				if ($ctrl.sendAnalytics) {
					gtmAnalytics.trackEvent('event', {
						category: 'App-Suite-' + analyticscategory,
						label: analyticsid + labelSuffix
					});
				}
			}

			this.onInit = () => {
				$scope.$broadcast('modelTray.init');
			}
			this.customDots = (slider, i) => {
				var nummber = i + 1;
				return'<button type="button" data-role="none" role="button" tabindex="0" data-analyticscategory="' + analyticscategory + '" data-analyticsid="' + analyticsid + '" data-analyticsvalue="dot-#' + nummber + '">' + nummber + '</button>';
			}

			this.handlerExpand = () => {
				if($isToggleExpanded) {
					$isToggleExpanded = false;
				} else {
					$isToggleExpanded = true;
				}
			}

			this.toggleExpand = () => {
				return $isToggleExpanded;
			}
		}
	}
})();
