(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaCapitalize', fcaCapitalize);

    function fcaCapitalize() {
        'ngInject';

        return function(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
    }
})();
