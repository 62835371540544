(function () {
	angular
		.module("fca.miniBuildAndPrice.model.selector")
		.component("modelSelectorMiniBp", {
			controller: modelSelectorMiniBp,
			controllerAs: "$ctrl",
			templateUrl:
				'/shared/fca-mini-build-and-price/model-selector/model-selector-mini-bp.html',
			bindings: {
				acode: '@',
				onSelectModel: "<",
				iconModelsUrl: '@',
				modelsObject: "<",
				brand: '@',
				currentDrivetrain: '<',
				drivetrainsObject: '<',
				hidePricing: '<',
				minibpAnalyticsIdBase: '@',
				withModelName: '<'
			}
		});

	function modelSelectorMiniBp($scope) {
		"ngInject";

		const $ctrl = this;
		const desktopMediaQuery = "(min-width: 1024px)";
		$ctrl.drivetrainSwitch = false;
		$ctrl.disableSwitch = false;
		$ctrl.modelsByDrivetrain = {};
		let desktopMode = null;

		$ctrl.disclaimers = {
			msrpStartingAt: angular.element('[data-disclaimer-code="leg-pricing"]').html()
		};

		$ctrl.$onInit = () => {
			$ctrl.setModelsByDrivetrain();

			$ctrl.hideClass = ($ctrl.hidePricing === true) ? 'mini-bp-price-point' : '';
		}

		$ctrl.setModelsByDrivetrain = () => {
			$ctrl.modelsByDrivetrain = [];

			if ($ctrl.modelsObject) {
				$ctrl.modelsByDrivetrain = $ctrl.modelsObject.filter(model => model.drivetrain === $ctrl.currentDrivetrain.id);
				let otherDrivetrain = $ctrl.modelsObject.filter(model => model.drivetrain !== $ctrl.currentDrivetrain.id);

				if ($ctrl.modelsByDrivetrain.length === 0 || otherDrivetrain.length === 0 || $ctrl.brand !== 'alfaromeo') {
					$ctrl.disableSwitch = true;
				}

				if ($ctrl.withModelName) {
					$ctrl.modelsByDrivetrain.forEach(model => {
						model.trimGroup = model.modelName + ' ' + model.trimGroup;
					});

					otherDrivetrain.forEach(model => {
						model.trimGroup = model.modelName + ' ' + model.trimGroup;
					});
				}
			}
		};

		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.toggleDrivetrainCheckBoxSwitch = drivetrain => {
			$ctrl.currentDrivetrain = drivetrain;
			$ctrl.setModelsByDrivetrain();
		};

		$ctrl.toggleDrivetrainButtons = drivetrainSwitch => {
			$ctrl.currentDrivetrain = drivetrainSwitch ? $ctrl.drivetrainsObject.awd : $ctrl.drivetrainsObject.rwd;
			$ctrl.setModelsByDrivetrain();
		};

		$ctrl.onModelSelected = (event, model, $fcaModalCtrl) => {
			if (event && event.target
				&& !event.target.classList.contains('legal-tooltip-label')
				&& !event.target.parentElement.classList.contains('legal-tooltip-label')
			) {
				$fcaModalCtrl.closeModal();
				$ctrl.onSelectModel(model);
			}
		};
	}
})();
