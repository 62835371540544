(function() {
	angular
		.module('fca.commonDirectives')
		.directive('fcaSimpleDropdown', fcaSimpleDropdown);

	function fcaSimpleDropdown() {
		return {
			restrict: 'A',
			controller: FcaSimpleDropdownController,
			controllerAs: 'simpleDropdown',
			scope: true,
			link: function(scope) {
				$(document).bind('click', function(event) {
					let $openSelector = angular.element('.province-selector');

					/* eslint-disable max-len */
					if(!$(event.target).closest($openSelector).length && $openSelector.hasClass('is-opened')) {
						angular.element('.province-selector.is-opened').find('.province-selector-toggle').click();
					}
				});
			}
		};
	}
	function FcaSimpleDropdownController($element, $rootScope) {
		'ngInject';
		/**
		 * @ngdoc property
		 * @name listOpened
		 * @propertyOf fca.simpleDropdown.controller:SimpleDropdownController
		 * @description Stores the state of the simple dropdown
		 * @type {Object}
		 */

		angular.element(document).ready(() => {
			new Waypoint({
				element: $element[0],
				handler: (direction) => {
					if($element.hasClass('mini-nav') && $element.hasClass('is-opened')) {
						$element.removeClass('is-opened');
					}
				}
			});
		});

		this.menuOpened = $element.attr('data-default-state') == 'opened';
		this.elsTabIndexValue = '-1';

		/**
		 * @ngdoc method
		 * @name toggleDropdown
		 * @propertyOf fca.simpleDropdown.controller:SimpleDropdownController
		 * @description Toggles the state of the view. Used to open or collapse the dropdown
		 * @example
		 *  <div fca-simple-dropdown>
         *  	<span class="footer-sitemap-title" ng-click="simpleDropdown.toggle()">title</span>
         *	</div>
		 */
		this.toggle = (event) => {
			if (event) {
				event.preventDefault();
			}
			if (this.menuOpened) {
				this.menuOpened = false;
				this.elsTabIndexValue = '-1';
			} else {
				this.menuOpened = true;
				this.elsTabIndexValue = '0';
			}
		};
	}
})();
