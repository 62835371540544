(function () {
	angular
		.module('fca.partial')
		.directive('fcaPartialRemote', fcaPartialRemote);

	function fcaPartialRemote() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				targetDiv: '@'
			},
			controllerAs: '$partialRemoteCtrl',
			controller: FcaPartialRemoteCtrl
		};
	}

	function FcaPartialRemoteCtrl($rootScope) {
		'ngInject';
		let $partialRemote = this;

		$partialRemote.isDisabled = false;

		$partialRemote.$onInit = () => {
			$rootScope.$on('partial:disable-partials', () => {
				$partialRemote.isDisabled = true;
			});

			$rootScope.$on('partial:close-partial', () => {
				$partialRemote.isDisabled = false;
			});
		}

		$partialRemote.invokeRemotePartial = () => {
			$rootScope.$emit('partial:invoke-partial', $partialRemote.targetDiv);
		}
	}
})();
