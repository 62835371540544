(function() {
	angular
		.module('fca.commonDirectives')
		.directive('featureAccordionList', featureAccordionList);

	function featureAccordionList() {
		'ngInject';

		return {
			restrict: 'A',
			bindToController: {},
			controller: featureAccordionListController,
			controllerAs: 'featureAccordionList',
			scope: true
		};

		function featureAccordionListController ($scope, $element, $rootScope, gtmAnalytics, userLocation, $timeout) {
			'ngInject';

			let vm = this;

			vm.animationLength = 350;
			vm.currentPosition = false;
			vm.currentOffset = 0;
			vm.accordions = [];

			vm.closeAllAccordions = function() {
				angular.forEach(vm.accordions, function(accordion, index) {
					accordion.expanded = false;
					accordion.contentHeight = 0;
				});
			};

			vm.scrollToAccordion = (offsetTop, contentHeight) => {
				if(vm.currentPosition && vm.currentPosition < offsetTop) {
					let targetHeight = (offsetTop - vm.currentOffset);
					$('html, body').animate({scrollTop: targetHeight}, 0);
				}

				vm.currentPosition = offsetTop;
				vm.currentOffset = contentHeight;
			}

			$rootScope.$on('open-first-accordion', (event, data) => {
                vm.closeAllAccordions();
				vm.accordions[0].expanded = true;
			});

			vm.addAccordion = function(accordion) {
				vm.accordions.push(accordion);
			};
		}
	}
})(angular);
