(function() {
    'use strict';

    angular
        .module('fca.sni.compareDetailsUrl')
        .service('fcaSniCompareDetailsUrl', FcaSniCompareDetailsUrl);

    function FcaSniCompareDetailsUrl(fcaUserIdPersistentService) {
        'ngInject';

        this.getUserId = () => {
            return fcaUserIdPersistentService.getCookie('userId');
        };

        this.getUrl = (vehicle, detailsUrl, eshopActivated, eshopUrl, affiliateId = getStoredAffiliateId()) => {
            let detailUrl = detailsUrl + vehicle.vin;

            if ((eshopActivated === 'true') && (vehicle.dealer.isDigital)) {
                detailUrl = eshopUrl + "/t1?vin="+vehicle.vin+"&dealercode="+vehicle.dealerCode+"&pid="+this.getUserId()+"&vehicle_type=new";

                storeAffiliateId();
                if (isAffiliateMode() && affiliateId) {
                    detailUrl += getAffiliateIdUrlParam();
                }

                detailUrl += "#vehicleInformation";
            }            
   
            return detailUrl;
        }
    }
})();
