/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.gmap')
        .controller('fcaGmapController', GMapController)
        .component('fcaGmap', {
            controller: 'fcaGmapController',
            controllerAs: '$gmap',
            bindings: {
                options: '<?',
                coords: '<?',
                center: '<?',
                selected: '<?',
                onSelect: '&',
                onInit: '&',
                onCenterChange: '&',
                onRadiusChange: '&?',
                onDrag: '&?',
                inComp: '@',
                markerSize: '@',
                closeInfoWindow: '<?'
            }
        });
    /**
     * @ngdoc controller
     * @name fca.gmap.controller:fcaGmapController
     * @requires $element
     * @requires $window
     * @requires fca.gmap.service:fcaGmapLoader
     */
    function GMapController($scope,
                            $rootScope,
                            $element,
                            $window,
                            fcaGmapLoader,
                            fcaGmap,
                            fcaGmapIcon,
                            $timeout) {
        'ngInject';

        const DEFAULT_ZINDEX = 1;

        const MAX_ZINDEX = 500;

        const RESPONSIVE_DEBOUNCE_DELAY = 300;

        const MIN_RADIUS = 5;

        $scope.mapIconIndex = 0;

        // Load brand name
        this.brand = window.FCA_SITES_CONFIG.name;

        this.markerSize = null;

        // Default basic font for Chrysler and Jeep
        this.brandFont = '"Montserrat", sans-serif';
        this.defaultLabelColor = 'white';
        this.activeLabelColor = 'white';

        switch(this.brand) {
            case 'alfaromeo': {
                this.brandFont = '"Sarabun", sans-serif';
                break;
            }
            case 'dodge': {
                this.brandFont = '"Oswald", sans-serif';
                break;
            }
            case 'ramtruck': {
                this.brandFont = '"Roboto Condensed", sans-serif';
                break;
            }
            case 'fiat': {
                this.brandFont = '"Lato", sans-serif';
                break;
            }
        }

        /**
         * @ngdoc property
         * @name markers
         * @propertyOf fca.gmap.controller:fcaGmapController
         * @type {Array}
         * @description
         * All coords to put on Google map.
         * Object must implement code, latitude, longitude porperties.
         */
        this.coords = [];

        /**
         * @ngdoc property
         * @name gMarkers
         * @propertyOf fca.gmap.controller:fcaGmapController
         * @type {Array}
         * @description
         * All markers Google on map
         */
        this.gMarkers = [];

        /**
         * @ngdoc property
         * @name gMap
         * @propertyOf fca.gmap.controller:fcaGmapController
         * @type {Object}
         * @description
         * Google map object
         */
        this.gMap = null;

        /**
         * @ngdoc property
         * @name gMakersBounds
         * @propertyOf fca.gmap.controller:fcaGmapController
         * @type {Object}
         * @description
         * Google map bounds for all markers on map
         */
        this.gMakersBounds = null;

        /**
         * @ngdoc property
         * @name settings
         * @propertyOf fca.gmap.controller:fcaGmapController
         * @type {Object}
         * @description
         * Injectable settings set
         */
        this.settings = {
            minZoomLevel: 5,
            maxZoomLevel: 15,
            draggable: true,
            zoomControl: true,
            clickable: true,
            responsive: false,
            zoomLevel: 15,
            markerActive: false,
            withLabelIndex: true,
            withInfoWindow: false,
            fitWithingBounds: true,
            hasSingleMarkerStyle: false
        };

        /**
         * @type {Boolean}
         * @description
         * Flag to disable zoom change callback call
         */
        let _centerChangeDisabled;

        /**
         * @type {Boolean}
         * @description
         * Flag to catch if the map was clicked
         */
        let _isClicked;

        let _isDragged;

        let _dragStarted;
        let _dragEnded;

        let _tilesLoaded = false;

        /**
         * @type {Object}
         * @description
         * Default icon shape
         */
        let _icon;

        /**
         * @type {Object}
         * @description
         * Active icon shape
         */
        let _iconActive;

        /**
         * @type {Object}
         * @description
         * Hover icon shape
         */
        let _iconHover;

        let _radiusChangedDebouncer;

        let _fitBoundsChangedDebouncer;

        let _radiusDebouncerDelay = 200;

        let _createMapTimeout = null;

        this.$onInit = () => {
            if (this.options && ng.isObject(this.options)) {
                this.settings = ng.extend({}, this.settings, this.options);
                let {zoomLevel, minZoomLevel, maxZoomLevel} = this.settings;
                if (zoomLevel !== null) {
                    if (zoomLevel > maxZoomLevel) {
                        zoomLevel = (maxZoomLevel - 1);
                    } else if (zoomLevel < minZoomLevel) {
                        zoomLevel = (minZoomLevel + 1);
                    }
                    // Set normalized value
                    this.settings.zoomLevel = zoomLevel;
                }
            }
        };

        /**
         * @ngdoc method
         * @name removeAllGMarkers
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description [TODO]
         * @return {Object} Controller instance (chaining)
         */
        this.removeAllGMarkers = () => {
            this.gMarkers.forEach((m) => {
                m.setMap(null);
            });

            /* Reset markers array */
            this.gMarkers = [];

            /* Return controller instance (chaining) */
            return this;
        };

        /**
         * @ngdoc method
         * @name defaultIconAllGMarkers
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description [TODO]
         * @param {Object} google Google map object
         * @return {Object} Controller instance (chaining)
         */
        this.defaultIconAllGMarkers = (google) => {
            this.gMarkers.forEach((m) => {
                m.setIcon(_icon);
                m.label.color = this.defaultLabelColor;
            });

            return this;
        };

        /**
         * @ngdoc method
         * @name setHoverMarkerIcon
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description [TODO]
         * @param {String} refDealerId Coord hover marker
         * @param {Object} google Google map object
         */
        this.setHoverMarkerIcon = (refDealerId, google) => {
            let panBounds = new google.maps.LatLngBounds(this.gMap.getCenter());
            let isNotEmpty = false;
            let selected = this.selected;
            let {dealerId: sDealerId, latitude: lat, longitude: lng} = selected ? selected : {};

            if (lat & lng) {
                // Add selected position in bounds
                panBounds.extend(new google.maps.LatLng(lat, lng));
            }

            for (let i = 0, l = this.gMarkers.length; i < l; i++) {
                let m = this.gMarkers[i];
                let coord = this.coords[i];
                let icon = _icon;
                let zIndex = DEFAULT_ZINDEX;
                let {latitude: lat, longitude: lng} = coord;

                if (sDealerId && sDealerId === coord.dealerId) {
                    // Not change the selected icon
                    continue;
                } else if (refDealerId && refDealerId === coord.dealerId) {
                    // Change the hover icon
                    icon = _iconHover;
                    panBounds.extend(new google.maps.LatLng(lat, lng));
                    zIndex = MAX_ZINDEX;
                    isNotEmpty = true;
                }

                // Set icon
                m.setIcon(icon);
                // Set zIndex
                m.setZIndex(zIndex);
            }

            // Default value
            _centerChangeDisabled = false;
            // Check if a marker is highlighted
            if (isNotEmpty) {
                // Lock the triggering for center change
                _centerChangeDisabled = true;
                // Move the map on highlighted marker
                this.gMap.panTo(panBounds.getCenter());
            }
        };

        /**
         * @ngdoc method
         * @name setGMarkerOnMap
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description Put all markers on map
         * @param {Object} google Google map object
         * @param {Boolean} fitBounds If true the map zoom level is adjust to the markers bounds
         * @return {Object} Controller instance (chaining)
         */
        this.setGMarkerOnMap = (google, fitBounds = false) => {
            if (this.gMap && this.coords.length) {
                /* Create bounds */
                this.gMakersBounds = new google.maps.LatLngBounds();
                /* Clickable setting */
                let clickable = this.settings.clickable;

                this.coords.forEach((coord, i) => {
                    let markerIcon = _icon;

                    if (this.settings.markerActive) {
                        // Set icon same size but with brand color
                        markerIcon = _iconHover;
                    }

                    let zIndex = DEFAULT_ZINDEX;
                    let {dealerId} = this.selected ? this.selected : {};

                    if (dealerId && coord.dealerId === dealerId) {
                        markerIcon = _iconActive;
                        zIndex = MAX_ZINDEX;
                    }
                    // Set position in the recordset
                    coord.position = (i + 1);

                    let markerData = {
                        position: new google.maps.LatLng(coord.latitude, coord.longitude),
                        map: this.gMap,
                        draggable: false,
                        icon: markerIcon,
                        clickable: clickable,
                        zIndex: zIndex
                    };

                    // Add pin number as label if needed
                    if (this.settings.withLabelIndex) {
                        markerData.label = {
                            color: this.defaultLabelColor,
                            fontFamily: this.brandFont,
                            fontSize: '13px',
                            fontWeight: '700',
                            text: i + 1 + ''
                        };

                        if (dealerId && coord.dealerId === dealerId) {
                            markerData.label.color = this.activeLabelColor;
                        }
                    }

                    // GMarker
                    let marker = new google.maps.Marker(markerData);

                    if (this.settings.withInfoWindow) {
                        // Require an html fragment to be present somewhere else on the page to populate
                        let infoWindow = new google.maps.InfoWindow({
                            content: $('.C_DD-map .info-window-container').html()
                        });
                        marker.addListener('click', () => {
                            setTimeout(function() {
                                infoWindow.open(this.gMap, marker);
                            }, 300);
                        });

                        if (this.closeInfoWindow !== true) {
                            infoWindow.open(this.gMap, marker);
                        }

                        this.gMap.panTo(marker.getPosition());
                    } else {
                        marker.addListener('click', () => {
                            this.defaultIconAllGMarkers(google);
                            marker.setIcon(_iconActive);
                            this.onSelect({
                                data: coord
                            });
                            marker.label.color = this.activeLabelColor;
                            /* Selected element */
                            this.isSelected = true;
                            /* Center map */
                            this.gMap.panTo(marker.getPosition());
                        });
                    }

                    /* Push the marker in the coords bounds */
                    this.gMakersBounds.extend(marker.position);
                    /* Push the markers in markers set */
                    this.gMarkers.push(marker);
                });

                /* Set center of map */
                this.center = this.gMakersBounds.getCenter();

                /* Adjust map position to the center of markers with optimal zoom level */
                if (fitBounds) {
                    this.gMap.fitBounds(this.gMakersBounds, 0);
                    if (_fitBoundsChangedDebouncer) {
                        $timeout.cancel(_fitBoundsChangedDebouncer);
                        _fitBoundsChangedDebouncer = null;
                    }

                    _fitBoundsChangedDebouncer = $timeout(() => {
                        let radius = fcaGmap.getMapRadius(this.gMap, google, MIN_RADIUS);

                        // Weird code to recall findDealers if fitBounds was made with a zoom out
                        // See Toronto dealers for an example
                        let options = {
                            radius: radius || 1,
                            zoomLevel: this.gMap.getZoom(),
                            location: {
                                latitude: this.gMap.getCenter().lat(),
                                longitude: this.gMap.getCenter().lng()
                            }
                        };

                        $rootScope.$broadcast('gmap: radius-change', {options});

                    }, 200);
                }

                /* Resizable map for responsive */
                this.debounceResponsive();
            }

            return this;
        };

        /**
         * @ngdoc method
         * @name debounceResponsive
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description Set the responsive map behaviour with a debounce delay
         */
        this.debounceResponsive = () => {
            if (this.settings.responsive) {
                let debouncer;
                ng.element($window).on('resize', () => {
                    if (debouncer) {
                        $timeout.cancel(debouncer);
                        debouncer = null;
                    }

                    debouncer = $timeout(() => {
                        this.gMap.fitBounds(this.gMakersBounds);
                    }, RESPONSIVE_DEBOUNCE_DELAY);
                });
            }

            return this;
        };

        /**
         * @ngdoc method
         * @name createGMap
         * @methodOf fca.gmap.controller:fcaGmapController
         * @description Create Google map instance
         * @param {Object} google Google map namespace
         * @param {Object} container DOM element map container
         */
        this.createGMap = (google, container) => {
            /* Default center of the map */
            let center = this.center;
            let zoomControl = this.settings.zoomControl;
            let draggable = this.settings.draggable;
            let zoomLevel = this.settings.zoomLevel;
            let ignoreZoomChanged;

            /* Set map */
            this.gMap = new google.maps.Map(container, {
                zoom: zoomLevel,
                center: new google.maps.LatLng(center.latitude, center.longitude),
                disableDefaultUI: true,
                zoomControl: zoomControl,
                scrollwheel: false,
                draggable: draggable,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                }
            });

            // Event handler when the map stop all actions
            this.gMap.addListener('idle', () => {
                let center = this.gMap.getCenter();
                let zoomLevel = this.gMap.getZoom();
                let radius = fcaGmap.getMapRadius(this.gMap, google, MIN_RADIUS);
                // Check if one or both variable is not undefined and true/false as needed
                if ((_dragStarted !== undefined) && _dragStarted) {
                    if(_dragEnded) {
                        _dragStarted = false;
                        _dragEnded = false;
                    }
                    let data = {
                        data: {
                            latitude: center.lat(),
                            longitude: center.lng()
                        },
                        options: {
                            radius: radius || 1,
                            zoomLevel: zoomLevel
                        }
                    };
                    this.onCenterChange(data);
                } else {
                    $timeout(() => {
                        if (this.inComp === "dealer-details") {
                            $element.find('.gm-style').attr("tabindex", "-1");
                            $element.find('iframe').attr("tabindex", "-1");
                        } else if (this.inComp === "dealer-locator") {
                            $element.find('.gm-style').attr("tabindex", "-1");
                            $element.find('iframe').attr("tabindex", "-1");
                            $element.find('a').attr("tabindex", "-1");
                        }
                    });
                }

                _centerChangeDisabled = false;
                _isClicked = false;
            });

            this.gMap.addListener('tilesloaded', () => {
                if (!_tilesLoaded) {
                    _tilesLoaded = true;
                    this.addAltOnZoomButtonImages();
                }
            });

            this.gMap.addListener('dragstart', () => {
                _dragStarted = true;
            });

            this.gMap.addListener('dragend', () => {
                _dragEnded = true;
            });

            // Event listener on zoom changed
            this.gMap.addListener('zoom_changed', () => {
                /* Lock zoom level */
                let zoom = this.gMap.getZoom();
                let minZoom = this.settings.minZoomLevel;
                let maxZoomLevel = this.settings.maxZoomLevel;
                if (zoom < minZoom) {
                    ignoreZoomChanged = true;
                    this.gMap.setZoom(minZoom);
                }

                if (zoom > maxZoomLevel) {
                    ignoreZoomChanged = true;
                    this.gMap.setZoom(maxZoomLevel);
                }

                /* Trigger zoom change */
                this.radiusTriggerChangeEvent(google);
            });

            this.gMap.addListener('click', () => {
                _isClicked = true;
            });

            this.gMap.addListener('double_click', () => {
                _isClicked = true;
            });

            this.gMap.addListener('drag', () => {
                _isClicked = false;
            });

            ng.element(this.gMap.getDiv()).on('click', () => {
                _isClicked = true;
            });

            return this;
        };

        this.addAltOnZoomButtonImages = () => {
            $timeout(() => {
                let zoomButtons = $element.find('button.gm-control-active');
                for (let item of zoomButtons) {
                    let altValue = $(item).attr("title");
                    $(item).find('img').attr("alt", altValue);
                }
            }, 250);
        };

        this.radiusTriggerChangeEvent = google => {
            if (_radiusChangedDebouncer) {
                $timeout.cancel(_radiusChangedDebouncer);
                _radiusChangedDebouncer = null;
            }

            _radiusChangedDebouncer = $timeout(() => {
                let radius = fcaGmap.getMapRadius(this.gMap, google, MIN_RADIUS);
                if (ng.isFunction(this.onRadiusChange) && _isClicked) {
                    _isClicked = false;

                    let center = this.gMap.getCenter();
                    this.onRadiusChange({
                        options: {
                            radius: radius || 1,
                            zoomLevel: this.gMap.getZoom(),
                            location: {
                                latitude: center.lat(),
                                longitude: center.lng()
                            }
                        }
                    });
                }
            }, _radiusDebouncerDelay);
        };

        this.$onChanges = o => {
            // Set the flag to not adjust zoom level of map to the markers bounds
            let fitBounds = false;
            if (o.center && o.center.previousValue !== 'UNINITIALIZED_VALUE') {
                let {previousValue, currentValue} = o.center;
                if (previousValue && currentValue) {
                    // When geolocation change
                    if (previousValue.postalCode !== currentValue.postalCode) {
                        fitBounds = true;
                    }
                }
            }

            // Check coords
            if (o.hasOwnProperty('coords') && o.coords.previousValue !== 'UNINITIALIZED_VALUE') {
                if (_createMapTimeout !== null) {
                    $timeout.cancel(_createMapTimeout);
                    _createMapTimeout = null;
                }

                if (this.center && this.center.fitBounds) {
                    fitBounds = this.center.fitBounds;
                }

                _createMapTimeout = $timeout(() => {
                    /* Load GMap library */
                    fcaGmapLoader().then((google) => {
                        if (this.gMap === null && (o.coords.currentValue.length)) {
                            // Fit map to bounds markers
                            fitBounds = this.settings.fitWithingBounds;

                            // Create map + set icons
                            this.createGMap(google, $element[0]).setIcons(google);

                            // Bind event on select change
                            $scope.$on('dealerLocator.event.onSelect', ($evt, selected) => {
                                if (selected === null) {
                                    this.defaultIconAllGMarkers(google);
                                }
                            });

                            // Bind event on hover change
                            $scope.$on('dealerLocator.event.onHoverLeave', ($evt, dealerId) => {
                                this.setHoverMarkerIcon(dealerId, google);
                            });

                        } else {
                            this.removeAllGMarkers();
                        }

                        this.setGMarkerOnMap(google, fitBounds);
                    });
                }, 10);
            }
        };

        this.setIcons = google => {
            // Default
            _icon = fcaGmapIcon.getIcon(google, this.settings.withInfoWindow || this.settings.hasSingleMarkerStyle, this.markerSize);
            // Active
            _iconActive = fcaGmapIcon.getIconActive(google, this.settings.withInfoWindow || this.settings.hasSingleMarkerStyle, this.markerSize);
            // Hover
            _iconHover = fcaGmapIcon.getIconHover(google, this.settings.withInfoWindow || this.settings.hasSingleMarkerStyle, this.markerSize);

            return this;
        };
    }
})(angular);
