(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaStoryModule', fcaStoryModule);

	function fcaStoryModule() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'storyModule',
			controller: FcaStoryModuleController,
		};

		function FcaStoryModuleController($element) {
			'ngInject';

			const $ctrl = this;
			$ctrl.contentExpanded = false;
			$ctrl.index = 0;

			$ctrl.$onInit = () => {
				$element.find('.storyModule-expandableContent-long').slideUp();
			};

			$ctrl.expandCollapseContent = () => {
				$ctrl.contentExpanded = !$ctrl.contentExpanded;

				if ($ctrl.contentExpanded) {
					$element.find('.storyModule-expandableContent-long').slideDown();
				} else {
					$element.find('.storyModule-expandableContent-long').slideUp();
				}
			}
		}
	}
})();
