(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaDealerDropdownOptions', fcaSignUp);

	function fcaSignUp() {
		return {
			restrict: 'A',
			controller: FcaDealerDropdownOptionsController,
			controllerAs: 'dealerdropdown',
			scope: true
		};

		function FcaDealerDropdownOptionsController($scope, $rootScope, $http, cookieLocation, fcaGeolocator,
													$cookies, $analytics, $filter, $timeout) {
			'ngInject';

			$scope.location = cookieLocation.getLocation();
			$scope.dealerDropdownOptions = [];
			$rootScope.selectedDealerOption = [];

			let showExpectedPurchase = false;
			let dealerSelected = false;
			let signUpCheckboxChecked = false;

			this.getDealerDropdownOptions = () => {
				let postalCode;

				if ($scope.location !== null) {
					postalCode = $scope.location.postalCode.replace(' ', '+');
				} else {
					postalCode = 'M4R+1K8';
				}

				let url = ' /data/dealers/closest-by-postal-code?postalCode='
					+ postalCode
					+ '&minResults=1&maxResults=5'
					+ '&brand=' + window.FCA_SITES_CONFIG.name;

				$http.get(url).then((r) => {
					$scope.dealerDropdownOptions = r.data.dealers;
				});
			};

			this.getShowExpectedPurchase = () => {
				return showExpectedPurchase;
			};

			this.checkDealerSelection = (selectedDealer) => {
				dealerSelected = selectedDealer !== '';
				if (!signUpCheckboxChecked) {
					showExpectedPurchase = selectedDealer !== '';
				}
			};

			$scope.$on('contest:showExpectedPurchase', (event, args) => {
				signUpCheckboxChecked = args[0];
				if (!dealerSelected) {
					showExpectedPurchase = args[0];
				}
			});

			this.setSelectedDealerInfos = (selectedDealer) => {
				if(selectedDealer) {
					let dealerObject = $filter('filter')($scope.dealerDropdownOptions, {'code': selectedDealer});
					let filteredDealerObject = dealerObject[0];
					$rootScope.selectedDealerOption = filteredDealerObject;
					$rootScope.$broadcast('defineSelectedDealer');
				} else {
					$rootScope.$broadcast('clearSelectedDealer');
				}
			};

			this.$onInit = () => {
				/* eslint-disable no-invalid-this */
				this.getDealerDropdownOptions();

				/* Listen if the location is changed outside this component */
				let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
				$scope.$on(eventUpdateName, (evt, args) => {
					$scope.location = args[0];
					this.getDealerDropdownOptions();
				});

				$rootScope.$on('dealer-dropdown-options:update', (event, newPostalCode) => {
					$scope.location.postalCode = newPostalCode;
					this.getDealerDropdownOptions();
				});
			};
		}
	}
})();
