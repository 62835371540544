(function () {
	angular
		.module("fca.loyaltyDrawerOpener")
		.directive("fcaLoyaltyDrawerOpener", fcaLoyaltyDrawerOpener);

	function fcaLoyaltyDrawerOpener() {
		return {
			restrict: "A",
			scope: true,
			bindToController: {
				targetDiv: "@",
			},
			controllerAs: "loyaltyDrawerOpener",
			controller: FcaLoyaltyDrawerOpenerController,
		};

		function FcaLoyaltyDrawerOpenerController($rootScope) {
			"ngInject";

			const $ctrl = this;

			$ctrl.triggerOpeningEvent = ($event) => {
				if ($ctrl.targetDiv && $ctrl.targetDiv !== "") {
					// emit to angularjs drawer
					$rootScope.$emit("partial:invoke-partial", $ctrl.targetDiv);
				} else {
					// emit to vuejs drawer
					document.dispatchEvent(
						new CustomEvent(
							"EVENT.loyaltyOffers.openLoyaltyDrawer",
							{
								detail: {
									trigger: $event.target,
								},
							},
						),
					);
				}
			};
		}
	}
})();
