(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSearch', fcaSearch);

	function fcaSearch() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'search',
			controller: FcaSearchController
		};

		function FcaSearchController($rootScope, $scope, $location, $timeout, $window, gtmAnalytics) {
			'ngInject';
			let changeTimeout;
			let changeDelay = 1500;
			let submitDelay = 2000;

			this.hashParam = 'searchTerm=';
			this.searchTerm = '';
			this.minChars = 3;
			this.resultsCtrl = [];
			this.totalResults = -1;

			this.$onInit = () => {
				$rootScope.$on('$locationChangeSuccess', () => {
					const hash = $location.hash();
					const id = hash.indexOf(this.hashParam);

					if (id != -1) {
						this.searchTerm = hash.substr(id + this.hashParam.length);
						$timeout(this.search);
					}
				});
			};

			this.onSubmit = () => {
				this.search();
			};

			this.onChange = () => {
				if (changeTimeout) {
					$timeout.cancel(changeTimeout);
					changeTimeout = null;
				}

				changeTimeout = $timeout(() => {
					if (this.searchTerm.length >= this.minChars) {
						this.search(true);
					}
				}, changeDelay);
			};

			this.registerResultsCtrl = (resultsCtrl) => {
				this.resultsCtrl.push(resultsCtrl);
			};

			this.updateTotalResults = () => {
				let total = 0;
				let isComplete = true;

				for (let i=0; i<this.resultsCtrl.length; i++) {
					if (this.resultsCtrl[i].totalCount != -1) {
						total += this.resultsCtrl[i].totalCount;
					} else {
						isComplete = false;
					}
				}
				if (isComplete) {
					this.totalResults = total;
				}
			};

			this.search = (isSendGAevent = false) => {

				if (this.searchTerm == '') {
					return;
				}

				// cancel any searches caused by change event
				if (changeTimeout) {
					$timeout.cancel(changeTimeout);
					changeTimeout = null;
				}

				changeTimeout = $timeout(() => {
					this.totalResults = -1;
					$scope.$broadcast('search.search', this.searchTerm);

					// refresh search term on search
					let noResultsSearchTerm = document.getElementsByClassName('search-noresults-searchterm');
					for (let i = 0; i < noResultsSearchTerm.length; i++) {
						noResultsSearchTerm[i].innerHTML = this.searchTerm;
					}

					if(isSendGAevent) {
						gtmAnalytics.trackEvent('event', {
							category: 'search',
							label: 'search bar'
						});
					}
				}, submitDelay);
			};

			this.reset = () => {
				this.searchTerm = '';
			}
		}
	}
})();
