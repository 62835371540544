(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaSelectContactReason', {
			templateUrl: '/components/contact-dealer/select-contact-reason/select-contact-reason.html', // eslint-disable-line max-len
			bindings: {
				contactReason: '<',
				onReasonChange: '&?'
			},
			controller: daaSelectContactReasonController
		});

	function daaSelectContactReasonController($rootScope) {
		'ngInject';

		this.$onInit = () => {
			const CONTACT_REASONS = ['info', 'maintenance', 'other'];
			if (this.contactReason && CONTACT_REASONS.indexOf(this.contactReason) > -1) {
				this.allowSelectContactReason = false;
			} else {
				this.allowSelectContactReason = true;
				this.setContactReason('other');
			}
		};

		this.setContactReason = (reason, event) => {
			if (event) {
				event.preventDefault();
			}

			this.contactReason = reason;
			this.onReasonChange({reason: reason});
		};
	}
})();
