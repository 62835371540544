(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculatorDialog')
		.controller('daaCalculatorDialogController', CalculatorDialogController);

	/**
	 * @ngdoc controller
	 * @name fca.daaCalculatorDialog.controller:daaCalculatorDialogController
	 * @requires $scope
	 * @requires ngDialog
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function CalculatorDialogController($scope, ngDialog) {
		/**
		 * Current location
		 * @type {Object}
		 */
		this.location = $scope.ngDialogData.location;

		/**
		 * Current Vehicle
		 * @type {Object}
		 */
		this.vehicle = $scope.ngDialogData.vehicle;

		/**
		 * Url for financing page
		 * @type {String}
		 */
		this.applyForFinancingUrl = $scope.ngDialogData.applyForFinancingUrl;

		/**
		 * Current Tab
		 * @type {String}
		 */
		this.current = $scope.ngDialogData.current;
		// Set stars for disclaimers in SNI context
		if ($scope.ngDialogData.disabledContact === 'true') {
			let {
				finance,
				leasing
			} = $scope.ngDialogData;

			if (finance) {
				if (finance.offerDisclaimer) {
					ng.extend(finance.offerDisclaimer, {
						key: '*'
					});
				}

				if (finance.priceDisclaimer) {
					ng.extend(finance.priceDisclaimer, {
						key: '*'
					});
				}
			}

			if (leasing) {
				if (leasing.offerDisclaimer) {
					ng.extend(leasing.offerDisclaimer, {
						key: '*'
					});
				}

				if (leasing.priceDisclaimer) {
					ng.extend(leasing.priceDisclaimer, {
						key: '*'
					});
				}
			}
		}

		/**
		 * Display calculator vs request form.
		 * @type {Boolean}
		 */
		this.isCalculator = true;

		/**
		 * Change display to request dealer
		 */
		this.request = () => {
			if (this.isCalculator) {
				this.isCalculator = false;
			}
		};

		this.getModalTitle = () => {
			return this.isCalculator ? 'calculator.dialog.title' : 'dealer.request.info';
		}

		/**
		 * @ngdoc method
		 * @name close
		 * @methodOf fca.daaCalculatorDialog.controller:daaCalculatorDialogController
		 * @param {Object} $evt Click Javascript event object
		 * @description [TODO]
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.close = ($evt) => {
			$evt.preventDefault();
			ngDialog.close();
		};
	}
})(angular);
