(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSiriusXm',fcaSiriusXm);

	function fcaSiriusXm() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsId: '@'
			},
			controllerAs: 'siriusXm',
			controller: FcaSiriusxmController
		};
	}

	function FcaSiriusxmController($element) {
		'ngInject';
		const $ctrl = this;
		$ctrl.isOpen = false;
		$ctrl.buttonShowHide = document.getElementById("buttonShow");
		$ctrl.$postLink = () => {
			const settings = {
				infinite: true,
				arrows: true,
				dots: true,
				centerPadding: '0px',
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				mobileFirst: true
			};

			$element.find('.siriusxm-carousel').slick(settings);
		};

		$ctrl.refreshSlick = () => {
			$element.find('.siriusxm-carousel').slick('refresh');
		};

		$ctrl.showHide = function() {
			//show hide sirius accordion
			let siriusXmWrapper = document.getElementById("siriusxm-wrapper");
			if(siriusXmWrapper.classList.contains("siriusxm-module-hidden")) {
				$ctrl.isOpen = !$ctrl.isOpen;
				siriusXmWrapper.classList.remove("siriusxm-module-hidden");
				siriusXmWrapper.classList.add("siriusxm-module-shown");
				$ctrl.refreshSlick();
			} else {
				$ctrl.isOpen = !$ctrl.isOpen;
				siriusXmWrapper.classList.add("siriusxm-module-hidden");
				siriusXmWrapper.classList.remove("siriusxm-module-shown");
			}
		};
	}

})();
