(function() {
	'use strict';

	angular
		.module('fca.commonDialog')
		.controller('fcaDialogController', FcaDialogController);

	/**
	 * @ngdoc controller
	 * @name fca.dialog.controller.fcaDialogController
	 * @description Default controller dialog
	 * @requires $scope
	 * @requires ngDialog
	 */
	function FcaDialogController($scope, ngDialog) {
		'ngInject';

		/* Confirm status to display confirm content section */
		this.isConfirm = false;

		/**
		 * @ngdoc method
		 * @name confirm
		 * @description Change isConfirm prop. value to true (can use to show confirm. content of dialog)
		 * @methodOf fca.dialog.controller:fcaDialogController
		 * @param {Object} $evt Javascript event
		 */
		this.confirm = ($evt) => {
			$evt.preventDefault();
			/* Show confirm content */
			this.isConfirm = true;
		};

		/**
		 * @ngdoc method
		 * @name close
		 * @description Close dialog box
		 * @methodOf fca.dialog.controller:fcaDialogController
		 * @param {Object} $evt Javascript event
		 */
		this.close = ($evt) => {
			$evt.preventDefault();
			ngDialog.close();
		};
	}
})();
