(function () {
  angular
  .module('fca.topicVideoAutoshow')
  .directive('fcaTopicVideoAutoshow', fcaTopicVideoAutoshow);

  function fcaTopicVideoAutoshow() {
    return {
      restrict: 'A',
      scope: true,
      bindToController: {},
      controllerAs: '$topicVideoAutoshowCtrl',
      controller: fcaTopicVideoAutoShowController
    };

    function fcaTopicVideoAutoShowController($scope, $rootScope, $window, $element) {
      'ngInject';

      const $ctrl = this;

      $ctrl.$onInit = () => {
       $($element).find('video').get(0).play()
       $($window.document).find('.AS_overlay-close-btn').bind('click',destroyVideo)
     }

		$ctrl.playPreviousVideo = (index) => {
			let numberVideos = $($element).find('video').length;
			$($element).find('video').toArray().forEach(video => video.pause());

			if( index > 0 && index <= numberVideos) {
				$($element).find('video').get(index-2).play();
			} else {
				$($element).find('video').get(numberVideos -1).play();
			}
		}

		$ctrl.playNextVideo = (index) => {
			let numberVideos = $($element).find('video').length;
			$($element).find('video').toArray().forEach(video => video.pause());

			if( index == numberVideos) {
				$($element).find('video').get(0).play();
			} else {
				$($element).find('video').get(index).play();
			}
		}

     function destroyVideo() {
      $($window.document).find('.AS_overlay-close-btn').unbind('click',destroyVideo)
      $($element).empty();
    }
  }
}
})();
