/* eslint-disable indent */
(function () {
    angular
        .module('fca.reservation')
        .service('reservationService', ReservationService);

    function ReservationService($http, $window, fcaUserIdPersistentService, userLocation) {
        'ngInject';
        let $service = this;

        // variables for payload
        // defined by callbacks of each components
        $service.selectedDealer = null;
        $service.colorCode = null;
        $service.interiorColorCode = null;
        $service.brand = null;
        $service.nameplate = null;
        $service.trim = null;
        $service.year = null;
        $service.locale = null;
        $service.acode = null;
        $service.modelYearId = null;
        $service.packageCode = null;
        $service.engineIcon = null;
        $service.transmissionIcon = null;
        $service.basePrice = null;
        $service.incentives = null;
        $service.submitting = false;

        // callback pointers
        // those are assign in the on init method of each component in the page
        $service.getReservationData = null;

        $service.submit = (apiUrl) => {
            if ($service.submitting) return;

            if (typeof $service.getReservationData === "function") {
                $service.getReservationData();
            }

            const [affiliateId, affiliateEncryptedParam, affiliateEaProgram] = getAffiliateCustomOrder();
            const urlParams = getSearchParameters();

            const urlBuildId = urlParams.get('buildId') || null;

            const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;
            const pid = persistentUid && `&pid=${persistentUid}`;

            const jellySrc = $('.reservation-jelly.show img').data('src'); // get jelly image url
            const exteriorColorSrc = $('.reservation-overview-choose-color-wrapper.-exterior .reservation-overview_selector-swatch.active img').data('src'); // get exterior color image
            const options = `${$service.colorCode},${$service.interiorColorCode}`;

            const pricing = pricingCustomOrder(
                $service.basePrice + $service.colorPrice - $service.incentives,
                'cash',
                null,
                null,
                'weekly',
                null,
                null,
                "weekly",
                0,
                0,
                0,
                $service.colorPrice,
                $service.incentives
            );

            const payload = payloadCustomOrder(
                userLocation.getLocationProvince(),
                $service.year,
                $service.brand,
                $service.nameplate,
                $service.locale,
                affiliateId,
                affiliateEncryptedParam,
                affiliateEaProgram,
                null, // campaignCode
                $service.trim,
                $service.trim, // label en ?
                $service.trim, // label fr ?
                $service.acode,
                $service.modelYearId,
                null, // modelCode
                persistentUid,
                $service.packageCode,
                '',
                jellySrc,
                $service.wheelUrl, // wheelColorUrl can be empty
                exteriorColorSrc,
                $service.engineIcon, // engine url
                $service.transmissionIcon, // transmission url
                options,
                $service.selectedDealer.code,
                urlBuildId,
                pricing
            );
            payload.reservation = true;

            $service.submitting = true;
            $http.post(apiUrl, payload).then(response => {
                const buildId = response.data.buildId;
                // Redirect to eshop
                const redirectToEshop = `${$service.eshopUrl}?buildid=${buildId}${pid}`;
                $window.location.href = redirectToEshop;
            }).catch(function (e) {
                throw new Error('error in sending reservation', e);
            }).finally(() => {
                $service.submitting = false;
            });

        };

    }
})(angular);
