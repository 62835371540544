(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaFeaturedOffersOld', fcaFeaturedOffersOld);

	function fcaFeaturedOffersOld() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				mobileCarousel: '@',
			},
			controllerAs: 'featuredOffersOld',
			controller: FcaFeaturedOffersOldController,
		};

		function FcaFeaturedOffersOldController($element, $timeout, $rootScope, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;
			$ctrl.showCompareFiller = false;
			$ctrl.showShopOnline = false;
			$ctrl.isFullLineupShown = false;

			$ctrl.$onInit = () => {
				if ($ctrl.mobileCarousel === "true") {
					/* Slick needs no get Reinitialized on window Resize after it was destroyed */
					$(window).on('load resize orientationchange', function() {
						/* Initializes a slick carousel only on mobile screens */
						if ($(window).width() > 666) {
							if ($element.hasClass('slick-initialized')) {
								$element.slick('unslick');
							}
						} else {
							if (!$element.hasClass('slick-initialized')) {
								$element.slick({
									slidesToShow: 1,
									slidesToScroll: 1,
									mobileFirst: true,
									dots: true,
									arrows: true
								});
							}
						}
					});
				}
			};

			$ctrl.$postLink = () => {
				$timeout(() => {
					if ($element.find(".featuredOffer")) {
						let nbrCards = $element.find(".featuredOffer").not(".-cardFiller, .ng-hide").length;
						if (nbrCards % 3 === 1) {
							$ctrl.showCompareFiller = true;
							$ctrl.showShopOnline = true;
							$rootScope.showORESliverOffersLanding = false;
						} else if (nbrCards % 3 === 2) {
							$ctrl.showShopOnline = true;
							$rootScope.showORESliverOffersLanding = false;
						} else {
							$rootScope.showORESliverOffersLanding = true;
						}
					}

					$ctrl.manageInteractiveElementsLineup();

					$(window).on('resize', functionExecutionControl.debounce($ctrl.manageInteractiveElementsLineup, 250) );
				});
			};

			$ctrl.manageInteractiveElementsLineup = () => {
				$timeout(() => {
					const lineupCardsHolder = $element.find('.featuredOffer-holder');
					const lineupCards = $element.find('.featuredOffer');
					const holderOffset = lineupCardsHolder.offset();

					lineupCards.each((index, element) => {
						let lineupCardOffset = $(element).offset();
						let interactiveElements = $(element).find('a, button, input, select, textarea').filter(':visible');

						if ($ctrl.isFullLineupShown) {
							$(element).attr('aria-hidden', false);
							interactiveElements.each((index, element) => {
								$(element).attr('tabindex', 0);
							})
						} else if (window.innerWidth > 768) {
							if (lineupCardOffset.top - holderOffset.top > 700) {
								$(element).attr('aria-hidden', true);
								interactiveElements.each((index, element) => {
									$(element).attr('tabindex', -1);
								})
							}
						} else {
							if (lineupCardOffset.top - holderOffset.top > 2000) {
								$(element).attr('aria-hidden', true);
								interactiveElements.each((index, element) => {
									$(element).attr('tabindex', 0-1);
								})
							}
						}
					})
				});
			}

			$ctrl.showFullLineup = () => {
				const lineupHolder = $element.find('.featuredOffer-holder');
				const fullLineUpBtn = $element.find('.featuredOffer-showAll');

				if (lineupHolder.hasClass('-offersLandingPage')) {
					lineupHolder.addClass("-active");
					fullLineUpBtn.hide();
				}

				$ctrl.isFullLineupShown = true;
				$ctrl.manageInteractiveElementsLineup();
			}
		}
	}
})();
