/* eslint-disable indent */
(function(ng) {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.gmap
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.gmap', []);
})(angular);
