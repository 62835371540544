// TBD if this is better as a component
(function() {
	angular
		.module('fca.layout.regionalOffers')
		.directive('fcaRegionSelector', fcaRegionSelector);

	function fcaRegionSelector() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				buttonLabel: '@',
				usePageRegion: '@',
				previousRegion: '@'
			},
			controllerAs: 'regionSelector',
			controller: FcaRegionSelectorController
		};

		function FcaRegionSelectorController($scope, $timeout, $rootScope, userLocation, cookieLocation, gtmAnalytics, $location, urlRegionMapping, defaultProvinceService) {
			'ngInject';


			this.$onInit = () => {
				// Watching location change in fca-userLocation.service
				$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
					// Save last region
					let locationFromCookie = cookieLocation.getLocation();
					if (locationFromCookie) {
						this.previousRegion = locationFromCookie.region;
					}
					this.updateLabel(label);
				});

				$rootScope.$on('geolocatorStatus.setLocation', (event, region, url) => {
					// If region has changed, redirect on url
					if (region && this.previousRegion && region !== this.previousRegion) {
						window.location.href = url;
					}
				});

				// Timeout is necessary
				$timeout(() => {
					this.updateLabel(userLocation.getLocationLabel());
				});
			};

			this.updateLabel = (label) => {
				if (this.usePageRegion && this.usePageRegion == "true") {
					let region = urlRegionMapping.getRegionByUrl($location.path());
					let province = defaultProvinceService.getDefaultProvince(region);
					this.buttonLabel = window.FCA_SITES_CONFIG.location[province.province]['desc'];
				} else {
					this.buttonLabel = label;
				}
			}

			/**
			 * @ngdoc method
			 * @name changeLocation
			 * @propertyOf fca.layout.regionalOffers:FcaRegionSelectorController
			 * @description Changes the location by calling fca-userLocation.service
			 */
			this.changeLocation = ($event) => {
				$event.preventDefault();

				let newRegion = $event.currentTarget.getAttribute('data-region');
				let newProvince = $event.currentTarget.getAttribute('data-province');
				let newRegionObject = {};

				let category = $event.currentTarget.getAttribute('data-analytics-category');
				let label = $event.currentTarget.getAttribute('data-analytics-label');

				if (newRegion != undefined || newRegion != '') {
					// newRegionObject = {'region': newRegion.toLowerCase()};
					newRegionObject = {'region': `${newProvince},${newRegion.toLowerCase()}`};

					userLocation.changeLocation(newRegionObject);

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label
					});
				}
			};
		}
	}
})();
