(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFadeSlider', fcaFadeSlider);

	function fcaFadeSlider() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
			},
			controllerAs: '$ctrl',
			controller: fcaFadeSliderController
		};

		function fcaFadeSliderController() {
			'ngInject';

			const $ctrl = this;
			$ctrl.currentSlide = null;

			$ctrl.$onInit = () => {
				$ctrl.currentSlide = 0;
			};

			$ctrl.setSlide = (index) => {
				$ctrl.currentSlide = index;
			};
		}


	}
})();
