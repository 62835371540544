(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaJellyBuilder', AlfaJellyBuilder);

	function AlfaJellyBuilder(alfaBp, irisResponsive, externalConfigLoader) {
		const $service = this;

		const config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

		const baseIrisPath = config.getConfig('irisJellyBaseUrl') +
			'/mediaserver/iris?';

		const apiKeys = {
			pov: 'pov',
			fabric: 'fabric',
			background: 'bkgnd',
			paint: 'paint',
			xResolution: 'width',
			yResolution: 'height',
			width: 'w',
			height: 'h',
			xOffset: 'x',
			yOffset: 'y',
			noShadow: 'noshadow',
		}

		const exteriorDimensions = irisResponsive.calcDimensions(
			{ ratio: 1920 / 1080, maxWidth: 1100, qualityRatio: 1.25 }
		);

		const exteriorDefaults = {
			[apiKeys.background]: 'white',
			[apiKeys.xResolution]: Math.round(exteriorDimensions.width),
			[apiKeys.yResolution]: Math.round(exteriorDimensions.height),
			// Cropping parameters - found after experimenting with the Iris GUI.
			[apiKeys.width]: 7700,
			[apiKeys.height]: 3500,
			[apiKeys.xOffset]: 1200,
			[apiKeys.yOffset]: 3500,
		}

		const interiorDimensions = irisResponsive.calcDimensions(
			{ ratio: 3840 / 2160, maxWidth: 1600 }
		)

		const panoramicDimensions = { width: 6000, height: 3000 };

		const createCposPackage = code => replaceChar(code, 1, 'D');

		const replaceChar = (text, index, character) => {
			return text.substr(0, index) + character + text.substr(index + 1);
		}

		const irisCodeForBrand = {
			'chrysler': 'C',
			'dodge': 'D',
			'jeep': 'J',
			'ramtruck': 'R',
			'fiat': 'X',
			'alfaromeo': 'Y'
		};

		const irisBrand = irisCodeForBrand[window.FCA_SITES_CONFIG.name];

		$service.jelly = ''

		$service.jellyUrl = ({
			jellyCategories,
			vehicle,
			mfgCode,
			packageCode,
			pov,
			exteriorOptions,
		}) => {
			const additionalOptions = $service.makeSaParameter([
				mfgCode,
				packageCode,
				createCposPackage(packageCode),
				jellyCategories.options
			]);
			//changes
			const jellyOptions = {
				[apiKeys.pov]: pov,
				client: 'FCAUS',
				market: 'U',
				brand: irisBrand,
				vehicle,
				paint: jellyCategories.paint,
				fabric: jellyCategories.fabric
			};
			if (!pov || pov.startsWith('E')) {
				Object.assign(jellyOptions, exteriorDefaults);
				if (exteriorOptions) {
					Object.assign(jellyOptions, exteriorOptions);
				}
			} else if (pov.startsWith('I')) {
				Object.assign(jellyOptions, interiorDimensions);
			} else {
				Object.assign(jellyOptions, panoramicDimensions);
			}

			for (const [apiKey, value] of Object.entries(jellyOptions)) {
				if (value == null) {
					jellyOptions[apiKey] = '';
				}
			}

			const queryString = alfaBp.encodeQueryParameters(jellyOptions)

			const jellyPath = baseIrisPath + queryString + additionalOptions

			return jellyPath;
		}

		$service.makeSaParameter = parameters => {
			return '&sa=' + parameters.join();
		}
	}
})();
