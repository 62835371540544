(function() {
	angular
		.module('fca.pageLayout')
		.directive('fcaNavigationMenu', fcaNavigationMenu);

	function fcaNavigationMenu() {
		return {
			restrict: 'A',
			controller: FcaNavigationMenuController,
			controllerAs: '$ctrlNavigationMenu'
		};

		function FcaNavigationMenuController($rootScope, $window, $timeout, $scope) {
			'ngInject';

			// Menu variables
			let $subnavs = $('[data-subnav]');
			const $html = $('html');
			let windowWidth = $window.innerWidth;
			this.menusList = [];
			this.currentMenu = {};
			this.currentSubmenu = {};
			this.isHomePage = false;

			// Scroll variables
			let scrollTimeout;
			let lastScrollTop = 0;
			let isSticky = false;
			let scrollToActive = false;
			let classNavSticky = 'main-nav-sticky';
			let classNavDown = 'nav-down';
			let navScrollOffsetValue = 200;
			let isQvPage = false;
			let isAR = false;
			let vlp2001 = false;

			this.addMenuToNavigation = (menu) => {
				this.menusList.push(menu);
			};

			this.menuClicked = (menu) => {
				this.setScrollingTimeout(scrollTimeout, 200);

				if (!menu.isActive) {
					// Open menu
					if (!menu.secondSubmenu) {
						// A top level menu was clicked

						if (!jQuery.isEmptyObject(this.currentSubmenu)) {
							// Close open submenu if case
							this.currentSubmenu.closeMenu();
						}

						this.closeCurrentMenu();
						this.currentMenu = menu;

					} else {
						// A sub level menu was clicked
						
						if (!jQuery.isEmptyObject(this.currentSubmenu)) {
							// Close open submenu if case
							this.currentSubmenu.closeMenu();
						}
						this.currentSubmenu = menu;
					}
					menu.openMenu();
				} else {
					// Close menu
					if (!jQuery.isEmptyObject(this.currentSubmenu)) {
						this.currentSubmenu.closeMenu();
					}
					menu.closeMenu();
				}
			};

			// Close current top level menu
			this.closeCurrentMenu = () => {
				if (!$.isEmptyObject(this.currentMenu)) {
					this.currentMenu.closeMenu();
					this.currentMenu = {};
				}
			};

			// Close all sub level menus
			this.closeCurrentSubMenu = () => {
				if (!$.isEmptyObject(this.currentSubmenu)) {
					this.currentSubmenu.closeMenu();
				}
			};

			this.setScrollingTimeout = (timeoutVar, timeoutTime) => {
				timeoutVar = $timeout(function() {
					timeoutVar = null;
				}, timeoutTime);
			};

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					if (!scrollTimeout && !scrollToActive) {
						this.setScrollingTimeout(scrollTimeout, 50);
							if ($(window).scrollTop()) {
								$html.addClass(classNavSticky);
								this.hasScrolled();
							} else {
								$html.removeClass(classNavSticky);
								$html.removeClass(classNavDown);
							}
						
					}
				});
			};

			this.hasScrolled = () => {
				let distScrolled = $(window).scrollTop();

				if (!isSticky) {
					$html.addClass(classNavSticky);
				}

				if (distScrolled < lastScrollTop) {
					$html.addClass(classNavDown);
					isSticky = true;
				}

				if (isSticky && distScrolled > lastScrollTop) {
					$html.removeClass(classNavDown);
					isSticky = false;
				}

				lastScrollTop = distScrolled;
			};

			this.bindKeyUp = (tooltipOpened) => {
				// Close all menus with escape key, unless a menu tooltip is opened

				if (tooltipOpened) {
					angular.element('.main-nav').unbind('keyup');
				} else {
					angular.element('.main-nav').bind('keyup', this.closeOnEsc);
				}
			};

			this.closeOnEsc = (event) => {
				if (event.code === 'Escape' || event.key === 'Escape') {
					this.closeCurrentMenu();
				}
			}

			this.bindResize = () => {
				// Close all menus when resizing the window
				angular.element($window).on('resize', () => {
					if (windowWidth !== $window.innerWidth) {
						this.closeCurrentMenu();
					}

					windowWidth = $window.innerWidth;
				});
			};

			this.bindClick = () => {
				// Close all menus if click somewhere else on the page
				angular.element(document).bind('click', (event) => {
					let submenuClicked = $subnavs.find(event.target).length > 0;
					let submenuSecondClicked = $('.main-nav-second-subnav').find(event.target).length > 0;
					let overlayClicked = $('.overlay').find(event.target).length > 0;
					let overlayBackdropClicked = $(event.target).hasClass('overlay');
					let hasSubmenu = event.target.hasAttribute('data-open-submenu');
					let tooltipCloseClicked = $(event.target).hasClass('legal-tooltip-close') ? $(event.target).hasClass('legal-tooltip-close') : $(event.target).parent().hasClass('legal-tooltip-close');

					if (submenuClicked ||
						submenuSecondClicked ||
						overlayClicked ||
						overlayBackdropClicked ||
						hasSubmenu ||
						tooltipCloseClicked) {
						return;
					}

					this.closeCurrentMenu();
				});
			};

			this.setSkipNavigation = () => {
				// timeout needed to grab disclaimers and some form elements
				$timeout(() => {
					// Find the first focusable element in main content
					const firstFocusableElement = angular.element('.layout-main-content').find('a, button, input, select, textarea').filter(':visible')[0];
					const skipNavLink = angular.element('.accessible-caption--skip-navigation');

					if (firstFocusableElement) {
						// Set the id for the anchor link target. If an id already exist, change the skip nav anchor href to match the id
						// This can be usefull if we want to do something like a scrollTo animation
						if(!firstFocusableElement.id) {
							firstFocusableElement.setAttribute('id', 'FirstElement');
						} else {
							skipNavLink.href = '#' + firstFocusableElement.id;
						}

						skipNavLink.bind('click', () => {
							firstFocusableElement.focus();
						});
					}
				}, 1000);
			};

			this.$onInit = () => {
				$timeout(() => {
					const $tabletBurgerMenu = $('.-visible-on-tablet .mobile-nav-ctn .main-nav-mobile');

					// initial functions
					disableKeyboardNavigation($tabletBurgerMenu);
				}, 50);

				this.bindClick();
				this.bindResize();
				this.bindKeyUp();

				let pageCode = window.FCA_SITES_CONFIG.pageCode;

				isQvPage = document.querySelector('body').classList.contains('qv-page');
				isAR = document.querySelector('body').classList.contains('vlp-alfaromeo');
				vlp2001 = document.querySelector('body').classList.contains('vlp-2021');

				if (pageCode == "home") {
					this.isHomePage = true;
				}
				$('body').append('<div class="menu-overlay"></div>');

				if ($('[data-sticky-main-nav]').length) {
					this.bindScrolling();
				}

				$rootScope.$on('fcaScrollTo.active', () => {
					$html.removeClass(classNavDown);
					scrollToActive = true;
					$timeout(() => {
						scrollToActive = false;
					}, 500);
				});

				/* Listen if the chat popup must be opened */
				$scope.$on('openDealersChatPopup', () => {
					this.closeCurrentMenu();
				});

				$rootScope.$on('$viewContentLoaded', this.setSkipNavigation());

				$rootScope.$on('tooltip-opened', () => {
					this.bindKeyUp(true);
				})

				$rootScope.$on('tooltip-closed', () => {
					this.bindKeyUp(false);
				})
			};
		}

		// Disable keyboard navigation on contained elements
		function disableKeyboardNavigation(el) {
			el.find('a, button, input').attr('tabindex', '-1');
		}
	}
})();
