(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaDaaPills', fcaDaaPills);

	function fcaDaaPills() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				currentPill: '@',
			},
			controllerAs: '$daaPills',
			controller: FcaDaaPillsController,
		};

		function FcaDaaPillsController($element, $scope, $rootScope) {
			'ngInject';

			const $ctrl = this;
			$ctrl.currentModel = ''
			$ctrl.hasFinance = false;
			$ctrl.hasLease = false;
			$ctrl.hasCash = false;

			const urlKeyParam = 'nameplate';

			$ctrl.$onInit = () => {
				if ($ctrl.currentPill === undefined) {
					$ctrl.currentPill = 'all';
				}
				$ctrl.setBodyClass($ctrl.currentPill);

				$ctrl.getModelFromUrl();
				$ctrl.setPills();

				$scope.$on('daa-offer:set-url-nameplate', (_, data) => {
					$ctrl.currentModel = data;
					$ctrl.setPills();
					$ctrl.switchPill('all');
				});

			}

			$ctrl.switchPill = (value) => {
				this.currentPill = value;
				$ctrl.setBodyClass(value);

				$rootScope.$broadcast('daa-offer:switch-pill', value);
			}

			$ctrl.setPills = () => {
				let nameplateClassSelector = '';

				if ($ctrl.currentModel === 'all' || $ctrl.currentModel === '' || $ctrl.currentModel === undefined) {
					nameplateClassSelector = '';
				} else {
					nameplateClassSelector = `.-offer-${$ctrl.currentModel}`;
				}

				$ctrl.hasFinance = document.querySelectorAll(`.daa-offers-list-offer-item.-finance${nameplateClassSelector}`).length > 0 ? true : false;
				$ctrl.hasLease = document.querySelectorAll(`.daa-offers-list-offer-item.-leasing${nameplateClassSelector}`).length > 0 ? true : false;
				$ctrl.hasCash = document.querySelectorAll(`.daa-offers-list-offer-item.-cash${nameplateClassSelector}`).length > 0 ? true : false;

			}

			$ctrl.getModelFromUrl = () => {
				const urlParams = new URLSearchParams(window.location.search);

				if (urlParams.has(urlKeyParam)) {
					$ctrl.currentModel = urlParams.get(urlKeyParam);
				}
			}

			$ctrl.setBodyClass = (value) => {
				let bodyClasses = document.querySelector('body').classList;

				bodyClasses.forEach((bodyClass) => {
					if (bodyClass.indexOf('-offers-pills') > -1) {
						bodyClasses.remove(bodyClass);
					}
				})

				bodyClasses.add(`${value}-offers-pills`);
			}
		}
	}
})();
