(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaStickyMiniNav', alfaStickyMiniNav);

	function alfaStickyMiniNav() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$alfaStickyMiniNav',
			controller: alfaStickyMiniNavController
		};

		function alfaStickyMiniNavController($scope, $element, $timeout, $window) {
			'ngInject';

			const $ctrl = this;

			const $el = $element[0];
			//const minStickyWidth = 768;
			const maxStickyWidth = 1023;

			$ctrl.isSticky = false;

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					this.stickyCheck();
				});
			};

			this.bindResize = () => {
				angular.element($window).on('resize', () => {
					this.stickyCheck();
				});
			};

			this.stickyCheck = () => {
				let elScrollTop = $el.getBoundingClientRect().top;

				$ctrl.isSticky = elScrollTop <= 0;

				$timeout(function() {
					$scope.$apply();
				});
			};

			this.$onInit = () => {
				this.stickyCheck();
				this.bindResize();
				this.bindScrolling();
			};
		}
	}
})();
