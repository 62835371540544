/* eslint-disable indent */
(function () {
    angular
    .module('fca.buildAndPrice.overlay')
    .directive('fcaBuildAndPriceOverlay', fcaBuildAndPriceOverlay);

    function fcaBuildAndPriceOverlay() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {},
            controllerAs: '$buildAndPriceOverlayCtrl',
            controller: FcaBuildAndPriceOverlay
        };

        function FcaBuildAndPriceOverlay($scope, $rootScope, configService, $timeout) {
            'ngInject';
            const $buildAndPriceOverlayCtrl = this;

            $buildAndPriceOverlayCtrl.language = "";
            $buildAndPriceOverlayCtrl.isActive = false;
            $buildAndPriceOverlayCtrl.scrollPause = false;
            let lastScroll;

            $buildAndPriceOverlayCtrl.$onInit = () => {
                window.addEventListener('scroll', event => {
                    if (!$buildAndPriceOverlayCtrl.scrollPaused) {
                        lastScroll = window.scrollY || window.pageYOffset;
                    }
                });
                $buildAndPriceOverlayCtrl.language = configService.getLanguage();
                // When the calculator button is clicked,
                $scope.$on('build-and-price:initialize-trade-in-estimator',
                    (event, data) => {
                        $buildAndPriceOverlayCtrl.modalDialog = $('.C_BP_OL-window');
                        // previously focused element AKA the element that called this overlay
                        $buildAndPriceOverlayCtrl.previousFocusedElement = data.previousFocusedElement;

                        $rootScope.$broadcast('trade-in-kbb:reset-trade-in-estimator');
                        $buildAndPriceOverlayCtrl.showOverlay();
                    });

                $rootScope.$on('build-and-price:close-trade-in-estimator', () => {
                    $buildAndPriceOverlayCtrl.cancel();
                    $rootScope.$broadcast('trade-in-kbb:reset-trade-in-estimator');
                });

                document.addEventListener('keydown', event => {
                    if(event.key === "Escape") {
                        event.preventDefault();
                        $buildAndPriceOverlayCtrl.cancel();
                        $rootScope.$broadcast('trade-in-kbb:reset-trade-in-estimator');
                    }
                });
            };

            $buildAndPriceOverlayCtrl.cancel = () => {
                // set focus to the button that called this overlay
                if ($buildAndPriceOverlayCtrl.previousFocusedElement) {
                    $buildAndPriceOverlayCtrl.previousFocusedElement.focus();
                }
                // Do cancel action
                // reset multi select label
                $buildAndPriceOverlayCtrl.hideOverlay();
                $rootScope.$broadcast('reset-multi-select-options');
            };

            $buildAndPriceOverlayCtrl.setFocusTrap = () => {
                $buildAndPriceOverlayCtrl.focusableElements = $buildAndPriceOverlayCtrl.modalDialog.find('a[href]:not([disabled]), a[tabindex]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
                $buildAndPriceOverlayCtrl.firstFocusableElement = $buildAndPriceOverlayCtrl.focusableElements[0];
                $buildAndPriceOverlayCtrl.lastFocusableElement = $buildAndPriceOverlayCtrl.focusableElements[$buildAndPriceOverlayCtrl.focusableElements.length - 1];

                $buildAndPriceOverlayCtrl.firstFocusableElement.focus();

                $buildAndPriceOverlayCtrl.modalDialog[0].addEventListener('keydown', $buildAndPriceOverlayCtrl.focusTrap);
            };

            $buildAndPriceOverlayCtrl.focusTrap = (e) => {
                if (e.key === 'Tab' || e.code === 'Tab') {
                    if ( e.shiftKey ) {
                        if (document.activeElement === $buildAndPriceOverlayCtrl.firstFocusableElement) {
                            $buildAndPriceOverlayCtrl.lastFocusableElement.focus();
                            e.preventDefault();
                        }
                    } else {
                        if (document.activeElement === $buildAndPriceOverlayCtrl.lastFocusableElement) {
                            $buildAndPriceOverlayCtrl.firstFocusableElement.focus();
                            e.preventDefault();
                        }
                    }
                } else if(e.key === 'Escape' || e.code === 'Escape') {
                    $buildAndPriceOverlayCtrl.cancel();
                } else {
                    return;
                }
            };

            $buildAndPriceOverlayCtrl.showOverlay = () => {
                $buildAndPriceOverlayCtrl.pauseScrolling();
                // Note: fixed elements will also need the margin adjustment (like a fixed header, if you have one).
                const scrollBarWidth = window.innerWidth
                    - document.body.offsetWidth;
                $('html')
                .css('margin-right', scrollBarWidth)
                .addClass('C_NID_OL-showing-modal');
                $buildAndPriceOverlayCtrl.isActive = true;

                $timeout(() => {
                    $buildAndPriceOverlayCtrl.setFocusTrap();
                });
            };

            $buildAndPriceOverlayCtrl.hideOverlay = () => {
                $buildAndPriceOverlayCtrl.resumeScrolling();
                
                if ($buildAndPriceOverlayCtrl.modalDialog) {
                    $buildAndPriceOverlayCtrl.modalDialog[0].removeEventListener('keydown', $buildAndPriceOverlayCtrl.focusTrap);
                }

                $('html')
                .css('margin-right', '')
                .removeClass('C_NID_OL-showing-modal');
                $buildAndPriceOverlayCtrl.isActive = false;
            };

            $buildAndPriceOverlayCtrl.pauseScrolling = () => {
                $buildAndPriceOverlayCtrl.scrollPaused = true;
                const heightValue = 'calc(100% + ' + lastScroll + 'px)';

                $('body').addClass('noScroll')
                    .css({
                        'height': heightValue,
                        'overflow': 'hidden'
                    });
            };

            $buildAndPriceOverlayCtrl.resumeScrolling = () => {
                $buildAndPriceOverlayCtrl.scrollPaused = false;
                $('body').removeClass('noScroll')
                    .removeAttr('style');

                if(lastScroll !== undefined) {
                    window.scrollTo(0, lastScroll);
                }
            };
        }
    }
})();
