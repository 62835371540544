(function() {
	angular
		.module('fca.commonLegalNotes')
		.component('fcaLegalTooltip', {
			bindings: {
				label: '@',
				type: '@',
				value: '@?',
				key: '@',
				options: '<?',
				forcePlacement: '@',
				icon: '@',
				text: '@',
				color: '@',
				analyticscategory: '@',
				analyticsid: '@',
				analyticsvalue: '@'
			},
			transclude: true,
			template: getTemplate(),
			controller: FcaLegalTooltipController
		});

	function FcaLegalTooltipController($element, $rootScope, $scope) {
		'ngInject';

		const $ctrl = this;
		let closeBtnLabel;
		let defaultOpenBtnLabel;

		if(window.FCA_SITES_CONFIG.language == 'fr') {
			closeBtnLabel = 'Fermer l\'avis';
			defaultOpenBtnLabel = 'Ouvrir l\'avis';

		} else {
			closeBtnLabel = 'Close disclaimer';
			defaultOpenBtnLabel = 'Open disclaimer';
		}

		$ctrl.defaultLabel = defaultOpenBtnLabel;

		$ctrl.$postLink = () => {

			angular.element(document).ready(() => {
				let contentNode = $element[0].querySelectorAll('.legal-tooltip-content')[0];
				let tooltipToggle = $element[0].querySelectorAll('[data-legal-tooltip-toggle]')[0];
				let tooltipCloseBtn = $element[0].querySelectorAll('[data-legal-tooltip-close]')[0];

				/* Harvest legal mention content from DOM */
				if (this.value) {
					angular.element('ng-transclude', $element[0]).html(this.value);
				}

				tooltipCloseBtn.setAttribute('aria-label', closeBtnLabel);

				/*
				 * Get the parent module box for each disclaimer trigger
				 * Cannot append to body for all disclaimer tooltips
				 * this is due to complex z-index scenarios caused by scrolling animation
				 *
				 * Setting .vr360-int-wrapper as parent div shows disclaimers
				 * in fullscreen vr-360 in Firefox and IE
				 */
				let $parentDiv;

				if($($element[0]).parents('.vr360-inst-wrapper').length > 0) {
					$parentDiv = $($element[0]).parents('.vr360-inst-wrapper');
				} else if ($($element[0]).parents('.js-legal-tooltip-container').length > 0) {
					$parentDiv = $($element[0]).parents('.js-legal-tooltip-container');
				} else {
					$parentDiv = $('body');
				}

				let isNormalPlacement = true;

				// Condition for disclaimers in panos. they need to be placed to the side to avoid popping under the the header (cannot be fixed with z-index)
				if ($parentDiv[0].dataset &&
					$parentDiv[0].dataset.groupId &&
					$parentDiv[0].dataset.groupId === 'stacked-anim-group-0') {
					isNormalPlacement = false;
					$parentDiv = $parentDiv.find('[data-tooltip-container]');
				}

				const normalPlacement = this.forcePlacement ? this.forcePlacement : 'top';
				const options = angular.extend({
					allowHTML: true,
					placement: isNormalPlacement ? normalPlacement : 'right',
					trigger: 'click',
					interactive: true,
					content: contentNode,
					theme: 'fcatooltips',
					arrow: true,
					appendTo: $parentDiv[0],
					zIndex: 10000000,
					onShow(instance) {
						$rootScope.$emit('onDisabledScroll');
						tippy.hideAll({ exclude: instance });
						
						if($element.parents('.slick-slider').length) {
							$scope.$emit('carousel.pause');
						}

						if($ctrl.color === 'dark') {
							const tooltipContainer = instance.popper.querySelector('.tippy-tooltip.fcatooltips-theme, .tippy-box[data-theme="fcatooltips"]')
							tooltipContainer.classList.add('dark-container')							
						}

						const incentive = instance.popper.querySelector('[class*=-pdf]');
						if (incentive) {							
							const incentiveType = incentive.getAttribute('class').replace('-pdf', '');
							incentive.setAttribute("href", "https://www.fcacanada.ca/incentives/disclaimers.php?incType="+incentiveType+"&lang="+window.FCA_SITES_CONFIG.language+"&brand="+window.FCA_SITES_CONFIG.name);
							incentive.setAttribute("target", "_blank");
						}

						const insideLink = instance.popper.querySelector('a');
						if(insideLink) {
							insideLink.addEventListener('click', () => {
								instance.hide();
							})
						}
					},
					onShown(instance) {
						const listenerOptions = {
							once: true
						}
						$rootScope.$emit('tooltip-opened');
						instance.popper.querySelector('[data-legal-tooltip-content]').focus();
						instance.popper.querySelector('[data-legal-tooltip-close]').addEventListener('click', () => {
							instance.hide();
							tooltipToggle.focus();
						}, listenerOptions);
						instance.popper.querySelector('.legal-tooltip-content').addEventListener('keydown', $ctrl.setFocusTrap);
					},
					onHide(instance) {
						instance.popper.querySelector('.legal-tooltip-content').removeEventListener('keydown', $ctrl.setFocusTrap);
						$rootScope.$emit('onEnabledScroll');
						$scope.$emit('carousel.resume');
					},
					onHidden() {
						$rootScope.$emit('tooltip-closed');
					}
					// more options: https://atomiks.github.io/tippyjs/#all-settings
				}, this.options);

				const tippyInst = tippy($element[0], options); // eslint-disable-line new-cap
				// Store reference to the tippy popup on the trigger (needed by pano carousel)
				$element[0].dataset.tippyPopper = tippyInst.popper.id;

				$ctrl.setFocusTrap = () => {
					let element = event.currentTarget;
					$ctrl.focusTrap(event, element, tooltipToggle);
				}
			});
		};

		$ctrl.focusTrap = (e, element, tooltipToggle) => {
			const focusableElements = $(element).find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			const firstFocusableElement = focusableElements[0];
			const lastFocusableElement = focusableElements[focusableElements.length - 1];
			const mainContainer = $(element).find('[data-legal-tooltip-content]');

			if (e.key === 'Tab' || e.code === 'Tab') {
				if ( e.shiftKey ) {
					if (document.activeElement === firstFocusableElement) {
						mainContainer.focus();
						e.preventDefault();
					} else if (document.activeElement.hasAttribute('data-legal-tooltip-content')) {
						lastFocusableElement.focus();
						e.preventDefault();
					}
				} else {
					if (document.activeElement === lastFocusableElement) {
						mainContainer.focus();
						e.preventDefault();
					}
				}
			} else if(e.key === 'Escape' || e.code === 'Escape') {
				tippy.hideAll();
				tooltipToggle.focus();
			} else {
				return;
			}
		}			
	}


	function getTemplate() {
		return `<span class="legal-tooltip"
					data-ng-class="{'legal-ibubble-container': $ctrl.type == 'ibubble'}">

					<button ng-if="$ctrl.type == 'ibubble'" 
						class="legal-tooltip-label is-ibubble"
						aria-label="{{$ctrl.defaultLabel}}"
						data-legal-tooltip-toggle
						type="button">
						<sup 
							class="fcaicon {{$ctrl.icon ? $ctrl.icon : 'fcaicon-ibubble'}}"
							data-analyticscategory="{{$ctrl.analyticscategory ? $ctrl.analyticscategory : 'legalnote'}}"
							data-analyticsid="{{$ctrl.analyticsid}}"
							data-analyticsvalue="{{$ctrl.analyticsvalue}}">
						</sup>
						<span class="tooltip-button-text" ng-if="$ctrl.text">{{$ctrl.text}}</span>
					</button>

					<button ng-if="$ctrl.type == 'hybrid'" 
						class="legal-tooltip-label is-hybrid"
						aria-label="{{$ctrl.defaultLabel}}"
						data-legal-tooltip-toggle
						data-analyticsid="{{$ctrl.analyticsid}}"
						data-analyticsvalue="{{$ctrl.analyticsvalue}}"
						type="button">
						<span class="fcaicon {{$ctrl.icon ? $ctrl.icon : 'fcaicon-hybrid'}} {{$ctrl.label ? 'has-label' : 'no-label'}}"
						data-analyticsid="{{$ctrl.analyticsid}}"
						data-analyticsvalue="{{$ctrl.analyticsvalue}}"></span>
						<span ng-if="$ctrl.label"
						data-analyticsid="{{$ctrl.analyticsid}}"
						data-analyticsvalue="{{$ctrl.analyticsvalue}}"
						class="tooltip-button-text">{{$ctrl.label}}</span>
					</button>

					<button ng-if="$ctrl.type != 'ibubble' && $ctrl.type != 'hybrid'"
						class="legal-tooltip-label is-{{::$ctrl.type}}"
						data-legal-tooltip-toggle
						data-analytics-on 
						data-analytics-event="legalnote" 
						aria-label="{{$ctrl.label === '*' ? $ctrl.defaultLabel : null}}"
						data-analytics-legalnote="legal note"
						data-analyticscategory="{{$ctrl.analyticscategory ? $ctrl.analyticscategory : 'legalnote'}}"
						type="button">
						<sup ng-class="{'star-tooltip': $ctrl.label === '*'}">{{::$ctrl.label.replace('*', '&#8270;')}}</sup>
					</button>					

					<data class="legal-tooltip-data" style="display: none;" aria-hidden="true">
						<div class="legal-tooltip-content">
							<div class="content" data-legal-tooltip-content tabindex="-1">
								<ng-transclude></ng-transclude>
							</div>
							
							<button class="legal-tooltip-close"
									data-legal-tooltip-close>
								<i class="fcaicon fcaicon-close-thin" aria-hidden="true"></i>
							</button>
						</div>
					</data>
				</span>`;
	}
})();

