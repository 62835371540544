(function () {
	angular
		.module('fca.brandApp')
		.directive('autoshowContest', AutoshowContest);

	function AutoshowContest() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				brandCode : '@',
				postalCode : '@?'
			},
			controllerAs: '$autoshowContestCtrl',
			controller: AutoshowContestController
		};

		function AutoshowContestController($timeout, $rootScope, $http) {
			'ngInject';

			const $autoshowContestCtrl = this;
			$autoshowContestCtrl.language = FCA_SITES_CONFIG.language;
			$autoshowContestCtrl.showThankYou = false;
			$autoshowContestCtrl.postalCode= 'M4R+1K8';
			$autoshowContestCtrl.showBallotConfirmation = false;
			$autoshowContestCtrl.nameplates = [];
			$autoshowContestCtrl.nameplatesSelected = [];
			$autoshowContestCtrl.dealers = [];
			$autoshowContestCtrl.brands = [];
			$autoshowContestCtrl.nameplatesMax= 4;
			$autoshowContestCtrl.brandCodeNameplate= this.brandCode;


			$autoshowContestCtrl.$onInit = () => {
				$autoshowContestCtrl.getBrands();
				$autoshowContestCtrl.getNameplates($autoshowContestCtrl.brandCode);
				$autoshowContestCtrl.getDealers();
			}

			$rootScope.$on('autoshow-overlay:closed', () => {
				$timeout(() => {
					$autoshowContestCtrl.showThankYou = false;
				});
			});

			$rootScope.$on('sign-up-submit-failed', () => {
				$autoshowContestCtrl.showThankYou = false;
			});

			$rootScope.$on('sign-up-submit-has-succeed', () => {
				$autoshowContestCtrl.displayThankYou();
			});

			$autoshowContestCtrl.displayThankYou = () => {
				$autoshowContestCtrl.showThankYou = true;
			}

			$autoshowContestCtrl.reload = () => {
				if($autoshowContestCtrl.showThankYou == true) {
					window.location.reload();
				}
			}

			/**
			 * Lists fca brands
			 */
			$autoshowContestCtrl.getBrands = () => {
				let chrysler = {code: 'chrysler' , name : 'Chrysler'};
				let dodge = {code: 'dodge' , name : 'Dodge'};
				let jeep = {code: 'jeep' , name : 'Jeep®'};
				let ram = {code: 'ramtruck' , name : 'Ram'};
				let alfa = {code: 'alfaromeo' , name : 'AlfaRomeo'};
				$autoshowContestCtrl.brands.push(chrysler,dodge,jeep,ram,alfa);
			};

			/**
			 * Gets dealers by brand and postal code
			 */
			$autoshowContestCtrl.getDealers = () => {
				//if we are in the corporate stage, brandCode is the first radio button
				if(!this.brandCode) {
					this.brandCode = $autoshowContestCtrl.brands[0].code;
				}
				if($autoshowContestCtrl.brandCode && $autoshowContestCtrl.postalCode) {
					let url = ' /data/dealers/closest-by-postal-code?postalCode='
						+ this.postalCode.replace(' ', '+')
						+ '&minResults=1&maxResults=7'
						+ '&brand=' + this.brandCode;

					$http.get(url).then((result) => {
						this.dealers = result.data.dealers;
					});
				} else {
					console.error(" Error while retrieving dealers with postalCode="+this.postalCode + " and brand="+ this.brandCode);
				}
			};


			/**
			 * Gets nameplates by brand
			 */
			$autoshowContestCtrl.getNameplates = (brandCode) => {

				$autoshowContestCtrl.nameplates.splice(0, $autoshowContestCtrl.nameplates.length);
				if (!brandCode) {
					brandCode = $autoshowContestCtrl.brands[0].code;
				}
				if (brandCode) {
					$http.get(`/data/nameplates/${brandCode}`).then((result) => {
						let response = result.data;
						if (response && result.status == 200) {
							response.forEach((nameplate) => {
								let label = $autoshowContestCtrl.language === "en" ? nameplate.label.en : nameplate.label.fr;
								let code = nameplate.code;

								$autoshowContestCtrl.nameplates.push({
									code: code,
									name : label
								});
							});
						} else {
							console.error(" Error while retrieving nameplates with brand="+ brandCode);
						}
					});
				}
				$autoshowContestCtrl.reachedTheLimit();
			};

			/**
			 * Check if the limit of selected checkboxes is reached
			 * @type {number}
			 */
			$autoshowContestCtrl.reachedTheLimit = () => {
				let reached = (this.nameplatesSelected.length >= $autoshowContestCtrl.nameplatesMax) ? true : false;
				return reached;
			}

			/**
			 * Changes the current brand
			 */
			$autoshowContestCtrl.changeBrand = (brand) => {
				this.brandCode = brand;
			}

			/**
			 * Changes the current brand
			 */
			$autoshowContestCtrl.changeBrandNameplate = (brand) => {
				this.brandCodeNameplate = brand;
				$autoshowContestCtrl.nameplates.splice(0, $autoshowContestCtrl.nameplates.length);
				$autoshowContestCtrl.getNameplates(brand);
			}


			/**
			 * Changes the current postalCode
			 */
			$autoshowContestCtrl.changePostalCode = (code) => {
				this.postalCode = code;
			}

			/**
			 * Remove nameplate pill
			 */
			$autoshowContestCtrl.removePill = (nameplateCode) => {
				this.nameplatesSelected = this.nameplatesSelected.filter(nameplate => nameplate.code != nameplateCode);
				this.shareChosenNameplates();
			}

			/**
			 * Add nameplate pill
			 */
			$autoshowContestCtrl.addPill = (index) => {
				let nameplateSelected = $autoshowContestCtrl.nameplates[index];

				if($("#nameplate-checkbox-"+index).is(":checked")) {
					if (!this.nameplateIsSelected(nameplateSelected.code)) {
						$autoshowContestCtrl.nameplatesSelected.push(nameplateSelected);
					}
				} else {
					this.removePill(nameplateSelected.code);
					//this.nameplatesSelected = this.nameplatesSelected.filter(nameplate => nameplate.code !=nameplateSelected.code);
				}
				this.shareChosenNameplates();
			}


			/**
			 * Check if a nameplate has already been selected
			 */
			$autoshowContestCtrl.nameplateIsSelected = (nameplateCode) => {
				let isSelected = false;
				if (this.nameplatesSelected.length > 0 &&
					this.nameplatesSelected.filter(nameplate => nameplate.code == nameplateCode).length > 0) {
					isSelected = true;
				}
				return isSelected;
			}

			$autoshowContestCtrl.shareChosenNameplates = () => {
				$rootScope.$broadcast('autoshow-contest-chosen-nameplates',
					{ chosenNameplates : this.nameplatesSelected });
			}
		}
	}
})();
