(function() {
	angular
		.module('fca.pageLayout')
		.directive('menuPaymentToggle', menuPaymentToggle);

	function menuPaymentToggle() {
		return {
			restrict: 'A',
			controller: MenuPaymentToggleController,
			controllerAs: '$menuPaymentToggleCtrl',
			scope: true
		};
	}
	function MenuPaymentToggleController($timeout) {
		'ngInject'
		const $ctrl = this;

		$ctrl.paymentTab = 'cash';
		$ctrl.currentBrand = '';

		$ctrl.$postLink = () => {
			$timeout(() => {
				//set the currentBrand subTitle
				$ctrl.currentBrand = document.querySelector('.current-brand-sub-title').getAttribute('data-current-brand');

				const vehicleMenuElement = document.querySelector('.main-nav-subnav-ctn.navigation-menu-item');
				if(vehicleMenuElement) {
					$ctrl.setVehicleMenuFocusTrap(vehicleMenuElement);
				}
			}, 500);
		};

		$ctrl.setVehicleMenuFocusTrap = (element) => {
			const focusableElements = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			const firstFocusableElement = focusableElements[0];
			const lastFocusableElement = focusableElements[focusableElements.length - 1];

			element.addEventListener('keydown', e => {
				// And Not a Single Tabs Was Given
				if (!(e.key === 'Tab' || e.code === 'Tab')) {
					return;
				}

				if ( e.shiftKey ) {
					// shift + tab (backward <-)
					if (document.activeElement === firstFocusableElement) {
						lastFocusableElement.focus();
						e.preventDefault();
					}
				} else {
					// tab (forward ->)
					if (document.activeElement === lastFocusableElement) {
						firstFocusableElement.focus();
						e.preventDefault();
					}
				}
			});
		};
	}
})();
