(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpStats', alfaromeoVlpStats);

	function alfaromeoVlpStats() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: alfaromeoVlpStatsController
		};

		function alfaromeoVlpStatsController($element, $rootScope, $timeout) {
			'ngInject';

			const $ctrl = this;
			let waypoint;
			let timeoutPromise;


			$ctrl.horsePowerReady = false;
			$ctrl.torqueReady = false;
			$ctrl.topSpeedReady = false;
			$ctrl.secondsMphReady = false;

			$ctrl.$postLink = () => {

				// Wait for element such as slick to reposition, to avoid some racing condition
				$timeout( () => {
					waypoint = new Waypoint({
						element: $element[0],
						handler: (direction) => {
							waypoint.destroy();
							waypoint = null;
	
							// if timeout is already triggered, cancel it
							if( timeoutPromise ) $timeout.cancel(timeoutPromise);
	
							// run a little delay before starting counters animation
							timeoutPromise = $timeout(() => {
								// start HP counter
								$ctrl.horsePowerReady = true;
							}, 750);
						},
						offset: '75%'
					});
				});
			};
			$ctrl.$onDestroy = () => {
				if( waypoint ) waypoint.destroy();
				if( timeoutPromise ) $timeout.cancel(timeoutPromise);

				waypoint = null;
				timeoutPromise = null;
			};

			$ctrl.onHorsePowerEnd = () => {
				$ctrl.torqueReady = true;
			};
			$ctrl.onTorqueEnd = () => {
				$ctrl.topSpeedReady = true;
			};
			$ctrl.onTopSpeedEnd = () => {
				$ctrl.secondsMphReady = true;
			};

		};
	};

})();
