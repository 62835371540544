(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpHeroStoryToggle', alfaromeoVlpHeroStoryToggle);

	function alfaromeoVlpHeroStoryToggle() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: alfaromeoVlpHeroStoryToggleController
		};

		function alfaromeoVlpHeroStoryToggleController() {
			'ngInject';

			const $ctrl = this;

			let selectedTab = 0;


			$ctrl.isTabSelected = (index) => {
				return index === selectedTab;
			};
			$ctrl.selectTab = (event, index) => {
				if( event ) {
					event.stopPropagation();
					event.preventDefault();
				};

				selectedTab = index;
			};

		};
	};

})();
