(function () {
	angular
		.module('fca.kbb')
		.directive(
			'fcaKbbReview',
			() => ({
				restrict: 'A',
				scope: true,
				bindToController: {
					vin: '@',
					tradeInValue: '@'
				},
				controllerAs: '$ctrl',
				controller: [
					"$scope",
					"$timeout",
					"fcaBuyOnlineService",
					($scope, $timeout, fcaBuyOnlineService) => new FcaKbbReviewCtrl($scope, $timeout, fcaBuyOnlineService)
				]
			})
		);

	class FcaKbbReviewCtrl {
		constructor($scope, $timeout, fcaBuyOnlineService) {
			this.$scope = $scope;
			this.$timeout = $timeout;
			this.fcaBuyOnlineService = fcaBuyOnlineService;
		}

		$onInit() {
			// check if we already have a trade-in-value
			let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);
			if (checkout.kbb
				&& checkout.kbb.tradeInValue != null) {
				// this is the actual trade-in value for the appraised vehicle
				this.tradeInValue = checkout.kbb.tradeInValue;

				// keep the kbb
				this.kbb = checkout.kbb;

				this.updateNetTradeIn(checkout);

				// let the view know we have a trade-in
				this.haveTradeIn = true;
			}
			// we need to know when the checkout is updated
			this.fcaBuyOnlineService.registerListener((checkout) => this.buyObjectUpdated(checkout));
		}

		/**
		 * Set the net trade-in value
		 * @param checkout
		 */
		updateNetTradeIn(checkout) {
			this.netTradeIn = 0;
			if (checkout) {
				// subtract the trade owed in the checkout object
				this.netTradeIn = (checkout.tradeInValue||0) - (checkout.tradeOwed || 0);
			}
		}

		/**
		 * It's possible for the checkout to be modified on the review step if the user modifies the trade-owed
		 * or trade-in value manually
		 *
		 * @param checkout
		 */
		buyObjectUpdated(checkout) {
			if (checkout) {
				this.updateNetTradeIn(checkout);
				this.scopeApply(); // since this can happen outside our scope
			}
		}

		/**
		 * Safe scope apply method.
		 * This will call apply on the next digest cycle.
		 */
		scopeApply() {
			this.$timeout(() => {
				this.$scope.$apply();
			});
		}
	}
})();
