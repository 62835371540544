(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaIframeLogic', fcaIframeLogic);

	function fcaIframeLogic($rootScope) {

		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$iFrameCtrl',
			controller: iframeLogic
		};

		function iframeLogic() {
			'ngInject';
			const $iFrameCtrl = this;
			//If we are in iframe, set localstorage with bool
			window.self !== window.top ? $iFrameCtrl.boolIframe = true : $iFrameCtrl.boolIframe = false;
		}
	}

})();
