(function () {
    angular
        .module('fca.salesAppointment')
        .directive('fcaSalesAppointmentDatepicker', fcaSalesAppointmentDatepicker);

    function fcaSalesAppointmentDatepicker() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                parentScope: '='
            },
            controllerAs: '$salesDatepickerCtrl',
            controller: FcaSalesAppointmentDatepickerController
        };

        function FcaSalesAppointmentDatepickerController($scope, $rootScope, $http, $filter,
                                               $location, $timeout, $element, $sce, gtmAnalytics, fcaSalesAppointmentService) {
            'ngInject';

            const $salesDatepickerCtrl = this;

            $salesDatepickerCtrl.selectedDealerSchedule = [];
            $salesDatepickerCtrl.language = FCA_SITES_CONFIG.language;

            $salesDatepickerCtrl.hasAppointments = true;

            $salesDatepickerCtrl.defaultDate = "";

            let minMaxDateFormat = $salesDatepickerCtrl.language == 'fr' ? 'yyyy-MM-dd' : 'MM/dd/yy';

            $salesDatepickerCtrl.$onInit = () => {
                $salesDatepickerCtrl.initDatepicker();
            };

            $salesDatepickerCtrl.initDatepicker = () => {
                if ($salesDatepickerCtrl.parentScope) {
                    let parentScope = $salesDatepickerCtrl.parentScope;

                    let brandCode = parentScope.brandCode;
                    let dealerCode = parentScope.selectedDealer;

                    $salesDatepickerCtrl.hasAppointments = true;

                    //available appointments
                    if (brandCode && dealerCode) {
                        $http.get(
                            `/data/dealers/appointments/available/${brandCode}/${dealerCode}`).then(
                            (result) => {
                                if (result.status == 200 && result.data) {
                                    let response = result.data;
                                    $salesDatepickerCtrl.selectedDealerSchedule = response.dealerAppointmentAvailabilities;
                                    let availableDaysNumber = $salesDatepickerCtrl.selectedDealerSchedule.length;

                                    if (availableDaysNumber > 0) {
                                        let firstDay = $salesDatepickerCtrl.selectedDealerSchedule[0].day;
                                        let lastDay = $salesDatepickerCtrl.selectedDealerSchedule[availableDaysNumber
                                        - 1].day;
                                        firstDay = firstDay.replace(/-/g,
                                            '\/').replace(/T.+/, '');
                                        lastDay = lastDay.replace(/-/g,
                                            '\/').replace(/T.+/, '');
                                        let disabledDates = $salesDatepickerCtrl.getDaysArray(
                                            new Date(firstDay),
                                            new Date(lastDay));

                                        for (var availableDaysIterator = 0;
                                            availableDaysIterator
                                            < availableDaysNumber;
                                            availableDaysIterator++) {
                                            let currentDay = $salesDatepickerCtrl.selectedDealerSchedule[availableDaysIterator].day;
                                            let totalAppointments = $salesDatepickerCtrl.selectedDealerSchedule[availableDaysIterator].appointmentsTime.length;
                                            let formattedDay = currentDay.replace(
                                                /-/g, '\/').replace(/T.+/, '');
                                            formattedDay = new Date(
                                                formattedDay);

                                            for (var disabledDatesIterator = 0;
                                                disabledDatesIterator
                                                < disabledDates.length;
                                                disabledDatesIterator++) {
                                                if (totalAppointments > 0
                                                    && formattedDay.getTime()
                                                    === disabledDates[disabledDatesIterator].getTime()) {
                                                    disabledDates.splice(
                                                        disabledDatesIterator,
                                                        1);
                                                }
                                            }
                                        }

                                        let formattedFirstDay = $filter('date')(
                                            new Date(firstDay),
                                            minMaxDateFormat);
                                        let formattedLastDay = $filter('date')(
                                            new Date(lastDay),
                                            minMaxDateFormat);

                                        $timeout(function () {
                                            $salesDatepickerCtrl.hasAppointments = true;

                                            let datepickerInput = $element.find(
                                                '[data-datepicker-input]');
                                            let datepickerWrapper = angular.element(
                                                $element.find(
                                                    '[data-datepicker-wrapper]'));

                                            datepickerInput.datepicker({
                                                outputFormat: 'y-MM-dd',
                                                theme: 'dealer-appointment-datepicker',
                                                inline: datepickerWrapper,
                                                gainFocusOnConstruction: false,
                                                firstDayOfWeek: 1,
                                                datesDisabled: disabledDates,
                                                min: formattedFirstDay,
                                                max: formattedLastDay,
                                                modal: false,
                                                closeButtonTitle: "Cancel"
                                            });

                                            $salesDatepickerCtrl.defaultDate = new Date(formattedFirstDay.replace(/-/g, '\/').replace(/T.+/, ''));

                                            datepickerInput.on('change',
                                                function () {
                                                    $salesDatepickerCtrl.sendGtmTrackEvent(
                                                        'dateselect')
                                                });

                                            $('.datepicker-month-wrap .datepicker-button').on(
                                                'click', event => {
                                                    $salesDatepickerCtrl.sendGtmTrackEvent(
                                                        'monthselect-toggle');
                                                });

                                            //set the date on load
                                            $salesDatepickerCtrl.updateDay();
                                        }, 0, false);
                                    } else {
                                        $salesDatepickerCtrl.hasAppointments = false;
                                    }
                                } else {
                                    $salesDatepickerCtrl.hasAppointments = false;
                                    console.error(
                                        "Error while retrieving availabilities for datepicker");
                                }
                            }
                        );
                    }
                }
            };

            $salesDatepickerCtrl.sendGtmTrackEvent = (gtmLabel) => {
                const page_code_active = window.BRANDS_GA.pagetype;

                gtmAnalytics.trackEvent('event', {
                    category: 'App-Suite-' + page_code_active,
                    label: 'ChooseAppointment-' + gtmLabel
                });
            };

            $salesDatepickerCtrl.updateDay = () => {
                if ($salesDatepickerCtrl.parentScope) {
                    let parentScope = $salesDatepickerCtrl.parentScope;
                    let datepickerInput = $element.find(
                        '[data-datepicker-input]');

                    //set the date on load
                    if (parentScope.selectedDay) {
                        if (angular.isString(parentScope.selectedDay)) {
                            if (parentScope.selectedDay != 0) {
                                let dateObject = parentScope.selectedDay.replace(/-/g, '\/').replace(/T.+/, '');
                                dateObject = new Date(dateObject);
                                if (dateObject != "Invalid Date") {
                                    datepickerInput.datepicker('setDate', dateObject);
                                }
                            }
                        } else {
                            datepickerInput.datepicker('setDate', parentScope.selectedDay);
                        }
                    } else {
                        $salesDatepickerCtrl.setDefaultDate();
                    }
                }
            };

            $salesDatepickerCtrl.setDefaultDate = () => {
                let datepickerInput = $element.find('[data-datepicker-input]');
                if ($salesDatepickerCtrl.defaultDate != "Invalid Date") {
                    datepickerInput.datepicker('setDate', $salesDatepickerCtrl.defaultDate);
                    $rootScope.$broadcast('fca-sales-appointment:appointment-get-hours', {date: $salesDatepickerCtrl.defaultDate});
                    $salesDatepickerCtrl.updateDay();
                }
            };

            $salesDatepickerCtrl.getDaysArray = (startDate, endDate) => {
                let dates = [];
                let currentDate = startDate;
                let addDays = function(days) {
                    var date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                };

                while (currentDate <= endDate) {
                    dates.push(currentDate);
                    currentDate = addDays.call(currentDate, 1);
                }
                return dates;
            };
        }
    }
})();
