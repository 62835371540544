(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaCompComp', fcaCompComp);

	function fcaCompComp() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'compComp',
			controller: fcaCompCompController
		};

		function fcaCompCompController($scope, $element, $timeout) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			let dragging = false;
			let container = $element[0].querySelector('.comp-comp-container .table');
			let vm = this;
			this.element = $element;

			this.onTouchStart = function(event) {
				vm.onStartDrag(event);
				vm.element.bind('touchmove', vm.onTouchMove);
			};
			this.onTouchMove = function(event) {
				vm.onMove(event);
			};
			this.onTouchEnd = function(event) {
				vm.onStopDrag(event);
				vm.element.unbind('touchmove', vm.onTouchMove);
			};
			$element.bind('touchstart', this.onTouchStart);
			$element.bind('touchend', this.onTouchEnd);

			this.onStartDrag = function(event) {
				dragging = true;
				container.classList.add('dragging');
			};
			this.onStopDrag = function(event) {
				dragging = false;
				container.classList.remove('dragging');
				$timeout(this.updateToActiveSlide);
			};
			this.onMove = function(event) {
				if (dragging) {
					let slicktrack = $element[0].querySelector('.slick-track');
					let ratio = parseFloat(this.getComputedTranslateX(slicktrack)) / slicktrack.offsetWidth;
					this.updateContainerPos(ratio);
				}
			};
			this.updateContainerPos = function(ratio) {
				container.style.left = container.offsetWidth * ratio + 'px';
			};
			this.getActiveSlide = function() {
				let index = 0;
				let node = $element[0].querySelector('.comp-comp-item.slick-current');
				while ((node = node.previousElementSibling)) {
					index++;
				}
				return index;
			};
			this.updateToActiveSlide = function() {
				let slicktrack = vm.element[0].querySelector('.slick-track');
				let ratio = -vm.getActiveSlide() / slicktrack.childElementCount;
				vm.updateContainerPos(ratio);
			};
			this.getComputedTranslateX = function(obj) {
				if (!window.getComputedStyle) return;
				let style = getComputedStyle(obj);
				let transform = style.transform || style.webkitTransform || style.mozTransform;
				let mat = transform.match(/^matrix3d\((.+)\)$/);
				if (mat) return parseFloat(mat[1].split(', ')[13]);
				mat = transform.match(/^matrix\((.+)\)$/);
				return mat ? parseFloat(mat[1].split(', ')[4]) : 0;
			};
		}
	}
})();
