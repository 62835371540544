(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaTimeline', fcaTimeline);

	function fcaTimeline() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'timeline',
			controller: fcaTimelineController
		};

		function fcaTimelineController($element) {
			'ngInject';
            let $timeline_items = $element.children();
			this.$onInit = () => {
                $timeline_items.addClass('js-hidden');
                $timeline_items.each(function() {
                    let $current_item = $( this );
                    $current_item.addClass('js-activated');
                    new Waypoint({
                        element: $current_item[0],
                        handler: function() {
                            this.destroy();
                            $current_item.removeClass('js-hidden');
                        },
                        offset: '95%'
                    });
                });
			};
		}
	}
})();
