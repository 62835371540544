(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpHeroStorySlider', alfaromeoVlpHeroStorySlider);

	function alfaromeoVlpHeroStorySlider() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: alfaromeoVlpHeroStorySliderController
		};

		function alfaromeoVlpHeroStorySliderController($scope, $element, AR_VLP_OVERLAY) {
			'ngInject';

			const $ctrl = this;

			const onOverlayOpenStarted = () => {
				isOpened = true;
			};
			const onOverlayOpenEnded = () => {
				animation = {
					from: 1.0,
					to: 0.5,
				};
			};
			const onOverlayCloseStarted = () => {
				isOpened = false;
			};

			let overlayOpenStartUnregister = null;
			let overlayOpenEndUnregister = null;
			let overlayCloseStartUnregister = null;
			
			let selectedTab = 0;
			let isOpened = true;
			let animation = {
				from: 1.0,
				to: 0.5,
			};	

			if($element.hasClass('AR_heroStory') === true ) {
				isOpened = false;
				animation = null;
			}

			$ctrl.$onInit = () => {
				overlayOpenStartUnregister = $scope.$on(AR_VLP_OVERLAY.OPEN_START, onOverlayOpenStarted);
				overlayOpenEndUnregister = $scope.$on(AR_VLP_OVERLAY.OPEN_END, onOverlayOpenEnded);
				overlayCloseStartUnregister = $scope.$on(AR_VLP_OVERLAY.CLOSE_START, onOverlayCloseStarted);
			};
			$ctrl.$onDestroy = () => {
				if( overlayOpenStartUnregister ) overlayOpenStartUnregister();
				if( overlayOpenEndUnregister ) overlayOpenEndUnregister();
				if( overlayCloseStartUnregister ) overlayCloseStartUnregister();

				overlayOpenStartUnregister = null;
				overlayOpenEndUnregister = null;
				overlayCloseStartUnregister = null;
			};


			$ctrl.isOverlayOpened = () => {
				return isOpened;
			};
			$ctrl.isTabSelected = (index) => {
				return index === selectedTab;
			};
			$ctrl.getSliderAnimation = (index) => {
				return index === 0 ? animation : null;
			};
			$ctrl.selectTab = (event, index) => {
				if( event ) {
					event.stopPropagation();
					event.preventDefault();
				};

				selectedTab = index;
			};

		};
	};

})();
