(function() {
	angular
		.module('fca.pageLayout')
		.directive('fcaNavigationMenuVehicle', fcaNavigationMenuVehicle);

	function fcaNavigationMenuVehicle() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controller: FcaNavigationMenuVehicleController,
			controllerAs: 'menuVehicle'
		};

		function FcaNavigationMenuVehicleController($rootScope, $scope, $element, $window, $timeout, gtmAnalytics, focusFunctions) {
			'ngInject';

			this.isSlickInitDone = false;
			this.linkToBind = null;

			// If a sub-menu with children is inside the main menu, we keep a reference to Main Menu Controller
			// Could be a problem if the submenu is inside another directive that is not navigation-menu-item.directive
			this.isActive = false;
			
			this.$postLink = () => {
				$timeout(() => {
					this.enableDisableButtonsInputs(false);
				});
			};
			
			if ($scope.$parent && $scope.$parent.$ctrl) {
				this.parentController = $scope.$parent.$ctrl;
				this.isActive = this.parentController.isActive;

				$scope.$parent.$watch('$ctrl.isActive', (isActive) => {
					this.enableDisableButtonsInputs(isActive);
				});
			}

			this.enableDisableButtonsInputs = (enable) => {
				let allButtons = $element.find('button').not('.legal-tooltip-close');
				for (let button of allButtons) {
					focusFunctions.enableTabIndex(button, enable);
				}
				let allInputs = $element.find('input');
				for (let input of allInputs) {
					focusFunctions.enableTabIndex(input, enable);
				}
			};

			this.vehicleMouseOver = (event) => {
				this.linkToBind = $element.find('.main-nav-subnav-vehicle-link').attr('href');

				// Remove all gradients
				$('.white-gradient').removeClass('white-gradient');

				// Put gradients on all other vehicles
				$('[fca-navigation-menu-vehicle]').not(event.currentTarget).each(function() {
					// eslint-disable-next-line no-invalid-this
					$(this).addClass('white-gradient');
				});
			};

			this.bindClickBlock = (event) => {

				event.stopPropagation();

				if (!$(event.target).hasClass('legal-tooltip-label') && !$(event.target).hasClass('star-tooltip')) {
					window.location = this.linkToBind;
				}

			};

			this.vehicleMouseOut = (event) => {
				$('.white-gradient').removeClass('white-gradient');
			};

			this.beforeChange = (event, slick, currentSlide, nextSlide, nameplate) => {
				let trackingLabel = 'next';

				if (nextSlide != undefined && nextSlide < currentSlide) {
					trackingLabel = 'prev';
				}

				if(this.isSlickInitDone) {
					gtmAnalytics.trackEvent('event', {
						category: 'top nav',
						label: `vehicles - ${nameplate} - ${trackingLabel}`
					});
				} else if (currentSlide == slick.options.initialSlide) {
					this.isSlickInitDone = true;
				}
			};
		}
	}
})();
