// Usage:
//
// const filterManager = new SniInventoryFilter();
window.SniInventoryFilter = (function() {
    // Bizarrely, the "names" of the filters given to us by the API are not the
    // names of the parameters that the backend expects. Accordingly, whenever
    // we decode/encode the url hash we need to make sure to replace the keys
    // below with their corresponding values.
    const realFilterNames = {
        model_year: 'year',
        vehicle_price: 'vehiclePrice',
        vehicle_consumption: 'vehicleConsumption',
        vehicle_drivetrain: 'vehicleDriveTrain',
        vehicle_passengers: 'vehiclePassengers',
        vehicle_cargo: 'cargoSpace',
        vehicle_payload: 'vehiclePayload',
        vehicle_towing: 'vehicleTowing',
        vehicle_usage: 'vehicleUsage',
        model_trim: 'trim',
        feature: 'feature',
        cash: 'cash',
        dealer_ship: 'distance',
        dealer_picture: 'withPhoto',
        lot_only: 'lotOnly',
        vehicle_color: 'color',
        financing_term: 'financingTerm',
        financing_payment: 'financingValue',
        leasing_term: 'leasingTerm',
        leasing_payment: 'leasingValue',
        vehicle_payment: 'vehiclePayment'
    };

    // Same object as above, but with key/value pairs inverted
    const realFiltersReversed = Object.keys(realFilterNames)
        .reduce((reversed, key) => {
            reversed[realFilterNames[key]] = key;
            return reversed;
        }, {});

    const setDelimiterCharacter = ',';

    // When the user switches payment mode, the parameters *not* associated with
    // the new payment mode mode are replaced with parameters from the new mode.
    // For example, if the user were to switch from the cash tab to the finance
    // tab, then `cash=...` would be removed from the hash in lieu of
    // `financing_term` and `financing_payment`. This object allows us to track
    // payment modes and their associated parameters.
    const financeParams = {
        'cash': ['cash'],
        'leasing': ['leasing_term', 'leasing_payment'],
        'financing': ['financing_term', 'financing_payment'],
    };

    // All the keys of the above object
    const allFinanceParams = Object.keys(financeParams).reduce((accum, key) => (
            accum.concat(financeParams[key])
        ), []);

    // Options expected: { brandCode, nameplateCode }
    const buildAPIUrl = (options, hashData) => {
        let apiUrl = `/data/inventories/${options.brandCode}/search/vehicles/${options.nameplateCode}`;

        return `${apiUrl}?${urlEncodeObject(hashData)}`;
    };

    const urlEncodeObject = (object) => (
        Object.keys(object).map(key => {
            const val = object[key];

            let valAsString;
            if (val instanceof Set) {
                if (val.size === 0) {
                    return;
                }

                valAsString = setToString(val);
            } else {
                valAsString = val;
            }

            let realKey = key;
            if (key in realFilterNames) {
                realKey = realFilterNames[key];
            }

            const encodedKey = encodeURIComponent(realKey);
            const encodedValue = encodeURIComponent(valAsString);

            return `${encodedKey}=${encodedValue}`;
        }).filter(Boolean)
          .join('&')
    );

    const setToString = (set) => {
        const values = [];

        set.forEach(value => values.push(value));

        return values.join(setDelimiterCharacter);
    };

    const writeUrlHash = (pristineData) => {
        const hashData = pruneFinanceParameters(pristineData);
        const newHash = '#' + urlEncodeObject(hashData);
        const urlParams = new URLSearchParams(window.location.search);
        const newQueryString = '?' + urlParams.toString();

        // Use replace state to avoid adding to the browser history
        history.replaceState(undefined, undefined, newHash);
        let newUrl = $('.toggle-link-data').data('language-link') + newQueryString + newHash;
        $('.main-nav-lang-toggle, .footer-province-switch-lang').attr('href', newUrl);

    };

    const pruneFinanceParameters = (hashData) => {
        const prunedObject = {};
        const validOptions = financeParams[hashData.paymentMode];

        for (const key of Object.keys(hashData)) {
            if (allFinanceParams.indexOf(key) === -1 ||
                validOptions.indexOf(key) > -1) {

                prunedObject[key] = hashData[key];
            }
        }

        return prunedObject;
    };

    const parseUrlHash = () => {
        if (!window.location.hash) {
            return {};
        }

        const rawHash = window.location.hash.substring(1);

        return rawHash.split('&').reduce((hash, part) => {
            const parts = part.split('=');

            let key = decodeURIComponent(parts[0]);
            key = realFiltersReversed[key] || key;

            let value = decodeURIComponent(parts[1]);
            if (value.indexOf(setDelimiterCharacter) >= 0) {
                value = setFromString(value);
            }

            hash[key] = value;
            return hash;
        }, {});
    };

	const parseUrlSearch = () => {
        if (!window.location.search) {
            return {};
        }

        return Object.fromEntries(new URLSearchParams(window.location.search));
    };

    const setFromString = (string) => {
        if (!string) {
            return new Set();
        }

        return new Set(string.split(setDelimiterCharacter));
    };

    const generateSortParams = (newSort) => {
        const sorts = {
            priceAscending: { sort: 'price', sortOrder: 'asc'},
            priceDescending: { sort: 'price', sortOrder: 'desc' },
            distance: { sort: 'distance', sortOrder: 'asc' },
            model: { sort: 'model', sortOrder: 'asc' },
        };

        if (!sorts.hasOwnProperty(newSort)) {
            console.error(`Invalid sort '${newSort}' passed to changeSort;`);
        }

        return sorts[newSort] || {};
    };

    const updateFilter = (filterState, filter, newValue) => {
        if (filter.type === "LOV") {
            updateLovFilter(filterState, filter, newValue);
        } else if (filter.type === "RANGE") {
            filterState[filter.name] = newValue;
        } else {
            console.error(`Unknown filter type '${filter.type}'`);
        }
    };

    // An LOV filter consists of one or more boolean options, each with their
    // own name. An example would be vehicle color; each color can be
    // independently selected and has their own name (hex code).
    const updateLovFilter = (filterState, filter, newValue) => {
        const value = newValue.toString();
        let filterSet = filterState[filter.name];

        if (!filterSet) {
            filterState[filter.name] = filterSet = new Set();
        }

        // If an LOV parameter contains a single element, it is deserialized as
        // a simple string and not as a set.
        if (!(filterSet instanceof Set)) {
            const singletonSet = new Set();
            singletonSet.add(filterSet);
            filterState[filter.name] = filterSet = singletonSet;
        }

        if (filterSet.has(value)) {
            filterSet.delete(value);

            if (filterSet.size <= 0) {
                delete filterState[filter.name];
            }
        } else {
            filterSet.add(value);
        }
    };

    const getLovValue = (filter, optionValue) => {
        if (!filter) {
            return false;
        }

        for (const option of filter.filters) {
            if (option.value === optionValue) {
                return option.selected;
            }
        }

        return false;
    };

    const getLovDisabled = (filter, optionValue) => {
        if (!filter) {
            return false;
        }

        for (const option of filter.filters) {
            if (option.value === optionValue) {
                return option.disabled;
            }
        }

        return false;
    };

    const modelFeaturesFilters = [
        'model_year', 'model_trim', 'feature' ,'vehicle_color'
    ];

    const containsDisabledOptions = (filters) => {
        for (const name of modelFeaturesFilters) {
            if (!filters[name]) {
                continue;
            }

            for (const option of filters[name].filters) {
                if (option.disabled) {
                    return true;
                }
            }
        }

        return false;
    };

    const brandCodes = ['chrysler', 'dodge', 'jeep', 'ramtruck', 'fiat'];

    // Given a list of whitespace-separated urls (generated from an FTL
    // template), return an object of { brandCode: brandLogoUrl } pairs.
    const parseLogoUrls = rawUrls => {
        const urls = rawUrls.split(/\s+/).filter(Boolean);

        if (urls.length != brandCodes.length) {
            throw Error('Unexpected number of urls in parseLogoUrls');
        }

        return urls.reduce((accum, url, index) => {
            accum[brandCodes[index]] = url;
            return accum;
        }, {});
    };

    return {
        financeParams,
        buildAPIUrl,
        writeUrlHash,
        parseUrlHash,
        setFromString,
        generateSortParams,
        updateFilter,
        getLovValue,
        getLovDisabled,
        containsDisabledOptions,
        brandCodes,
        parseLogoUrls,
        parseUrlSearch,
    };
});
