(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamPanoVideo', FcaOnlyRamPanoVideo);
	
	function FcaOnlyRamPanoVideo() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaOnlyRamPanoVideoCtrl',
			controller: FcaOnlyRamPanoVideoCtrl
		};
		
		function FcaOnlyRamPanoVideoCtrl($element, $scope, $timeout, gtmAnalytics) {

			const $ctrl = this;

			$ctrl.$postLink = () => {
				const video = $element[0].querySelector('.OR-video-pano .OR-media-video video');

				if(video) {
					video.onplay = () => {
						gtmAnalytics.trackEvent('event', {
							label: 'ram-fmb-make-it-yours-header-video-1-play',
						});
					};

					video.onpause = () => {
						gtmAnalytics.trackEvent('event', {
							label: 'ram-fmb-make-it-yours-header-video-1-pause',
						});
					};
				}
			}
		}
	}
})();