(function() {
	'use strict';

	angular
		.module('alfaromeo.vlp')
		.constant('AR_VLP_OVERLAY', {
			OPEN: 'AR_VLP_OVERLAY_OPEN',
			OPEN_START: 'AR_VLP_OVERLAY_OPEN_START',
			OPEN_END: 'AR_VLP_OVERLAY_OPEN_END',
			CLOSE: 'AR_VLP_OVERLAY_CLOSE',
			CLOSE_START: 'AR_VLP_OVERLAY_CLOSE_START',
			CLOSE_END: 'AR_VLP_OVERLAY_CLOSE_END',
		});
})();
