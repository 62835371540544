(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAnimatedFeatures', fcaAnimatedFeatures);

	function fcaAnimatedFeatures() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'animatedFeatures',
			controller: fcaAnimatedFeaturesController
		};

		function fcaAnimatedFeaturesController($scope, $element, $rootScope, $timeout) {
			'ngInject';

			const $ctrl = this;
			const scroller = scrollama();
			let itemsAreAllHidden = true;

			$ctrl.animatedFeatureContainer = $element[0].querySelector('.qv-animated-features__container');
			$ctrl.animatedFeatureBackground = $element[0].querySelector('.qv-animated-features__background');
			$ctrl.animatedFeatureBackgroundHeight = 'auto';


			$ctrl.$onInit = () => {
				$timeout(() => {
					$ctrl.scrollerInit();
				}, 2000);
			};

			$ctrl.scrollerInit = () => {
				scroller
					.setup({
						step: '.qv-animated-features__item',
						offset: 0.5,
						debug: false,
						progress: false
					})
					.onStepEnter(response => {
						response.element.classList.add('is-visible');
						itemsAreAllHidden = false;

						const videoElement = response.element.querySelector('video');

						if (videoElement && !response.element.classList.contains('video-has-played')) {
							videoElement.muted = true;
							videoElement.autoplay = false;
							videoElement.controls = false;
							const playPromise = videoElement.play();

							if (playPromise !== undefined) {
								playPromise.then(function() {
									videoElement.onended = () => {
										response.element.classList.add('video-has-played');
									};
								}).catch(function(error) {
									console.error('Error playing the video: ', error);
								});
							}
						}
					});

				window.addEventListener('resize', scroller.resize);
			};

			$rootScope.$on('onScrollingUpdate', () => {
				const parentElem = $($element[0]).parents('.vertical-scroll-block-container');
				const parentClassList = parentElem[0].classList;

				if ($ctrl.animatedFeatureBackgroundHeight !== $ctrl.animatedFeatureContainer.offsetHeight) {
					$ctrl.animatedFeatureBackgroundHeight = $ctrl.animatedFeatureContainer.offsetHeight + 80; // + 80 to add 40px spacing at top & bottom
				}

				if (!itemsAreAllHidden) {
					if (parentClassList.contains('has-passed')) {
						$('.qv-animated-features__item').removeClass('is-visible');

						itemsAreAllHidden = true;
					}
				}
			});
		}
	}
})();
