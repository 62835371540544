(function () {
	angular
		.module('fca.brandApp')
		.service('dealerDigitalRetailingService', DealerDigitalRetailingService);

	function DealerDigitalRetailingService(fcaUserIdPersistentService) {
		const $service = this;

		$service.getCssClass = (vehicle) => {
			if (vehicle.dealerDigitalRetailing && vehicle.dealerDigitalRetailing.enabled && vehicle.dealerDigitalRetailing.cssClassName) {
				return vehicle.dealerDigitalRetailing.cssClassName;
			}
		}

		$service.onStartBuyingProcessClick = (event, sniBuyUrl) => {
			if (event.currentTarget.href.includes(sniBuyUrl)) {
				event.currentTarget.setAttribute('target', '_self');
				return;
			}
			event.currentTarget.setAttribute('target', '_blank');
		}

		$service.getDealerDigitalRetailingUrl = (matrixUrl, dealerUrl, vin) => {
			let userId = fcaUserIdPersistentService.getCookie('userId');
			return matrixUrl.replace("{url}", dealerUrl).replace("{vin}", vin).replace("{persistentID}", userId);
		}
	}

})();
