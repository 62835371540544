(function() {
	angular
		.module('fca.brandApp')
		.directive('faqTabsOptionToggle', faqTabsOptionToggle);

	function faqTabsOptionToggle() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				breakdownWidth: '@',
				labelList: '<'
			},
			controllerAs: '$optionToggle',
			controller: faqTabsOptionToggleController
		};

		function faqTabsOptionToggleController($element, $timeout, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;
			$ctrl.selectedLabel = null;
			$ctrl.dropdownIsActive = false;
			$ctrl.listIsOpen = false;
			$ctrl.index = 0;

			let optionList = $element.find('[data-option-toggle-list]');
			let optionListContainer = $element.find('[data-option-toggle-list-container]');
			let toggleBtn = $element.find('[data-option-toggle-btn]');
			let focusWrapper = $element.find('[data-option-toggle-focus-wrapper]');

			$ctrl.$onInit = () => {
				$ctrl.displayCheck();
				$ctrl.bindResize();
			}

			$ctrl.bindResize = () => {
				window.addEventListener('resize', (event) => {
					functionExecutionControl.debounce($ctrl.displayCheck(), 400);
				});
			}

			$ctrl.displayCheck = () => {
				if($(window).width() >= $ctrl.breakdownWidth) {
					$ctrl.dropdownIsActive = false;
					$ctrl.closeList();
				} else {
					$ctrl.dropdownIsActive = true;

					if($ctrl.listIsOpen) {
						$ctrl.setListHeight();
					}
				}
			}

			$ctrl.undoListHeight = () => {
				if(optionListContainer) {
					optionListContainer.removeAttr('style');
				}
			}

			$ctrl.setListHeight = () => {
				if(optionList && optionListContainer) {
					let listHeight = $(optionList).outerHeight(true);
					optionListContainer.height(listHeight);
				}
			}

			$ctrl.toggleList = () => {
				if($ctrl.listIsOpen) {
					$ctrl.closeList();
				} else {
					$ctrl.openList();
				}
			};

			$ctrl.closeList = () => {
				$ctrl.listIsOpen = false;
				$ctrl.undoListHeight();

				if(focusWrapper.length > 0) {
					$timeout(() => {
						focusWrapper[0].removeEventListener('keydown', $ctrl.focusTrap);
					});
				}
			}

			$ctrl.onBtnClick = () => {
				$ctrl.focusOnBtn();
				$ctrl.closeList();
			}

			$ctrl.openList = () => {
				$ctrl.listIsOpen = true;
				$ctrl.setListHeight();

				$timeout(() => {
					$ctrl.setFocusTrap();
				});
			};

			$ctrl.setLabel = (value) => {
				$ctrl.selectedLabel = $ctrl.labelList[value].name;

				if($ctrl.dropdownIsActive && $ctrl.listIsOpen) {
					$ctrl.closeList();
				}
			}

			$ctrl.focusOnBtn = () => {
				if(toggleBtn) {
					$timeout(() => {
						toggleBtn.focus();
					})
				}
			}

			$ctrl.setFocusTrap = () => {
				if(focusWrapper) {
					$ctrl.focusableElements = focusWrapper[0].querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
					$ctrl.firstFocusableElement = $ctrl.focusableElements[0];
					$ctrl.lastFocusableElement = $ctrl.focusableElements[$ctrl.focusableElements.length - 1];

					$ctrl.firstFocusableElement.focus();

					focusWrapper[0].addEventListener('keydown', $ctrl.focusTrap);
				}
			};


			$ctrl.focusTrap = (e) => {
				if (e.key === 'Tab' || e.code === 'Tab') {
					if ( e.shiftKey ) {
						if (document.activeElement === $ctrl.firstFocusableElement) {
							$ctrl.lastFocusableElement.focus();
							e.preventDefault();
						}
					} else {
						if (document.activeElement === $ctrl.lastFocusableElement) {
							$ctrl.firstFocusableElement.focus();
							e.preventDefault();
						}
					}
				} else if(e.key === 'Escape' || e.code === 'Escape') {
					$ctrl.closeList();
				} else {
					return;
				}
			};
		}
	}
})();
