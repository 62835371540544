(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselAccessibility', fcaSlickCarouselAccessibility);

	function fcaSlickCarouselAccessibility() {

		return {
			restrict: 'A',
			bindToController: {
			},
			controllerAs: 'carouselAccessibility',
			controller: fcaSlickCarouselAccessibilityController
		};

		function fcaSlickCarouselAccessibilityController($element, $timeout, cleanCarousel) {

			this.$postLink = () => {

				$timeout(() => {
					cleanCarousel.makeAccessible($element);
				});
			}
		}
	}

})();
