(function() {
	angular
		.module("alfaromeo.audioPlayer")
		.component("alfaAudioPlayer", {
			controller: alfaAudioPlayer,
			controllerAs: "$ctrl",
			templateUrl: "/brand-specific/alfaromeo/components/audio-player/alfaromeo-audio-player.html",
			bindings: {
				url: "@",
				preload: "@",
				onComplete: "<",
				onPause: "<",
				onPlay: "<"
			}
		});

	function alfaAudioPlayer($scope, $element) {
		"ngInject";

		const audio = $element.find('audio')[0];
		const updatePlaybackProgress = () => {
			$scope.$apply(() => {
				$ctrl.audioTrackProgress.transform = `scaleX(${ Math.min(Math.max(0, audio.currentTime / audio.duration), 1) })`;
				if ($ctrl.playing === true) requestAnimationFrame(updatePlaybackProgress);
			});
		};

		const $ctrl = this;

		$ctrl.audioTrackProgress = {
			transform: 'scaleX(0)'
		};
		$ctrl.playing = false;

		$ctrl.$onDestroy = () => {
			cancelAnimationFrame(updatePlaybackProgress);
		};

		$ctrl.play = (e) => {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			};

			if ($ctrl.playing !== true) audio.play();
		};

		$ctrl.pause = (e) => {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			};

			if ($ctrl.playing === true) audio.pause();
		};

		$ctrl.updateAudioPlayingStatus = (event) => {

			$ctrl.playing = !audio.paused && !audio.ended;

			if ($ctrl.playing === true) requestAnimationFrame(updatePlaybackProgress);
			else cancelAnimationFrame(updatePlaybackProgress);

			switch (event.type) {
				case 'ended':
					if ($ctrl.onComplete && typeof $ctrl.onComplete === 'function') $ctrl.onComplete($ctrl);
					break;

				case 'pause':
					if ($ctrl.onPause && typeof $ctrl.onPause === 'function') $ctrl.onPause($ctrl);
					break;

				case 'playing':
					if ($ctrl.onPlay && typeof $ctrl.onPlay === 'function') $ctrl.onPlay($ctrl);
					break;
			}
		};
	}
})();
