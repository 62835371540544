(function () {
	angular
		.module('alfaromeo.buildAndPrice.summary')
		.component('alfaVehicleViewer', {
			controller: alfaVehicleViewer,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/summary/alfaromeo-summary-vehicle-viewer/alfaromeo-summary-vehicle-viewer.html',
			bindings: {
				jellyData: '<',
				options: '<',
				trim: '<',
				alfaPlaceholderImage: '@',
				modelYear: '@',
				brandName: '@',
				trimGroupName: '@',
				nameplate: '@',
				nameplateName: '@',
				selectedNameplateName: '@'
			}
		});

	function alfaVehicleViewer($element, $scope, $rootScope, $window, alfaJellyBuilder, alfaSummary, alfaInteriorPanorama, povHelper) {
		'ngInject';

		const $ctrl = this;

		const wheelsEcc = '0037';
		const exteriorColorEcc = '0082';
		const interiorColorEcc = '0083';
		const seatTypeEcc = '0065';
		const engineEcc = '0027';
		const transmissionEcc = '0029';

		const povJump = 3;
		let pov = 3;

		$ctrl.$onInit = () => {
			$ctrl.firstLoadDone = false;
			$ctrl.pov = 'E' + makePovAngle(pov);
		};

		$ctrl.$postLink = () => {
			$ctrl.image = $element.find("#jelly");
			$ctrl.image.get(0).onload = $ctrl.onImageLoaded.bind(this);
			$ctrl.show360Viewer = false;

			MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

			$ctrl.onSrcChangeObserver = new MutationObserver(() => {
				$scope.$apply(() => {
					$ctrl.jellyIsLoaded = false;
				});
			}).observe($ctrl.image.get(0), { attributes: true, attributeFilter: ['src'] });

			$rootScope.$on('interior-panorama:kill-360', () => {
				alfaInteriorPanorama.PSV.destroy();
				$scope.$apply(() => {
					$ctrl.show360Viewer = false;
				});
			});
		}

		$ctrl.$onChanges = changes => {
			if (changes.options.currentValue) {
				$ctrl.setSelectedOptions(changes.options.currentValue);
				$ctrl.show360Viewer = false;
			}
		}

		$ctrl.$onDestroy = () => {
			$ctrl.onSrcChangeObserver.disconnect()
			$ctrl.PSV.destroy();
		}

		$ctrl.switchToExteriorView = () => {
			$ctrl.show360Viewer = false;
			$ctrl.pov = 'E' + makePovAngle(pov)
		}

		$ctrl.switchToInteriorView = () => {
			$ctrl.pov = 'I01';
		}

		$ctrl.changePov = () => {
			if ($ctrl.pov.startWith('E')) return;
		}

		$ctrl.toggle360Viewer = () => {
			if (!$ctrl.show360Viewer) {
				const photosphereElement = $element.find('.photosphereContainer').get(0);
				alfaInteriorPanorama.setImageDimensions({ width: $ctrl.image.width(), height: $ctrl.image.height()});
				alfaInteriorPanorama.toggle360Viewer($ctrl.jellyCenterPano(), photosphereElement);
			}
			$ctrl.show360Viewer = !$ctrl.show360Viewer;
		}

		$ctrl.onImageLoaded = () => {
			$scope.$apply(() => {
				$ctrl.firstLoadDone = true;
				$ctrl.jellyIsLoaded = true;
			});
		}

		$ctrl.showLoading = () => {
			return !$ctrl.jellyIsLoaded;
		}

		$ctrl.isExterior = () => {
			return $ctrl.pov.startsWith('E');
		}

		$ctrl.isInterior = () => {
			return !$ctrl.isExterior();
		}

		$ctrl.jellyUrl = () => {
			if (!$ctrl.jellyData) {
				return;
			}

			const jellyOptions = Object.assign({
					pov: $ctrl.pov
				},
				$ctrl.jellyData
			);

			const jellyPath = alfaJellyBuilder.jellyUrl(jellyOptions);

			if ($ctrl.isExterior()) {
				$rootScope.$broadcast('jelly:exterior-jelly', jellyPath)
			}

			return jellyPath;
		}

		$ctrl.jellyUrlForPrint = () => {
			if (!$ctrl.jellyData) {
				return;
			}

			const inversedPov = $ctrl.pov[0] === 'E' ? 'I01' : 'E03';

			const jellyOptions = Object.assign({
					pov: inversedPov
				},
				$ctrl.jellyData
			);

			return alfaJellyBuilder.jellyUrl(jellyOptions);
		}

		$ctrl.jellyCenterPano = () => {
			if (!$ctrl.jellyData) {
				return;
			}

			const jellyOptions = Object.assign({
					pov: 'centerpano',
				},
				$ctrl.jellyData
			);

			return alfaJellyBuilder.jellyUrl(jellyOptions);
		}

		$ctrl.incrementJellyPov = () => {
			$ctrl.jellyIsLoaded = false;
			pov = povHelper.wrap(pov + povJump);
			$ctrl.pov = 'E' + makePovAngle(pov)
		}

		$ctrl.decrementJellyPov = () => {
			$ctrl.jellyIsLoaded = false;
			pov = povHelper.wrap(pov - povJump);
			$ctrl.pov = 'E' + makePovAngle(pov)
		}

		const makePovAngle = angle => {
			return angle > 9 ? angle.toString() : '0' + angle.toString();
		}

		$ctrl.engineDescription = () => {
			if (!$ctrl.options) return '';

			return alfaSummary.getOptionsForSection($ctrl.options.mechanical).find(option => {
				return option.ecc === engineEcc;
			}).description;
		}

		$ctrl.transmissionDescription = () => {
			if (!$ctrl.options) return '';

			return alfaSummary.getOptionsForSection($ctrl.options.mechanical).find(option => {
				return option.ecc === transmissionEcc;
			}).description;
		}

		$ctrl.setSelectedOptions = options => {
			$ctrl.wheels = alfaSummary.getOptionsForSection(options.exteriorSideSection, [], [wheelsEcc])[0];
			$ctrl.exteriorColor = alfaSummary.getOptionsForSection(options.exteriorColor, [], [exteriorColorEcc])[0];
			$ctrl.interiorColor = alfaSummary.getOptionsForSection(options.interiorColor, [], [interiorColorEcc])[0];
			$ctrl.seatType = alfaSummary.getOptionsForSection(options.interiorSection, [], [seatTypeEcc])[0];
		}

		$ctrl.altText =() => {
			let altText = '';
			if($ctrl.trimGroupName) {
				altText = $ctrl.selectedNameplateName + ' ' + $ctrl.trimGroupName + ' ' + (typeof $ctrl.exteriorColor === "undefined") ? '' : $ctrl.exteriorColor.description;
			} else {
				altText = $ctrl.selectedNameplateName + ' ' + (typeof $ctrl.exteriorColor === "undefined") ? '' : $ctrl.exteriorColor.description;
			}
			return altText;
		}

	}
})()
