(function() {
	angular
		.module('fca.core.main')
		.service('CampaignTracker', CampaignTracker);

	function CampaignTracker() {
		// private methods
		let readCookie = (name) => {
			let cookies = `; ${document.cookie}`;
			let parts = cookies.split(`; ${name}=`);

			if (parts.length === 2) {
				return parts.pop().split(';').shift();
			}
		};

		let parseCampaignsCookie = (cookie) => {
			let parsedCampaigns = [];
			let decodedCookieString = decodeURIComponent(cookie);
			let campaigns = decodedCookieString.split('|');

			if (campaigns.length > 0) {
				for (let i = 0; i < campaigns.length; i++) {
					let campaignInfo = campaigns[i].split(':');

					parsedCampaigns.unshift({
						utmCampaign: campaignInfo[0],
						utmSource: campaignInfo[1],
						utmMedium: campaignInfo[2],
						utmContent: campaignInfo[3],
						utmAudience: campaignInfo[4]
					});
				}
			}

			return parsedCampaigns;
		};

		// public methods and variables
		this.campaignsCookie = readCookie('nurunCampaignID');
		this.campaigns = parseCampaignsCookie(this.campaignsCookie);

		this.getCampaignsCookie = () => {
			return this.campaignsCookie;
		};

		this.getCampaigns = () => {
			return this.campaigns;
		};

		this.addCampaign = (campaign) => {
			this.campaigns.unshift(campaign);
		};
	}
})();
