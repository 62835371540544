(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.closestDealerList
	 */
	angular
		.module('fca.closestDealerList', []);
})();
