(function(ng) {
	'use strict';

	angular
		.module('alfaromeo.quadrifoglioMedia')
		.controller('alfaromeoQuadrifoglioMediaController', DialogMediaController);

	function DialogMediaController($scope, $rootScope, ngDialog, $translate, gtmAnalytics, $timeout) {
		'ngInject';

		const dialogData = $scope.ngDialogData;

		$scope.dialogCloseLabel = $translate.instant(dialogData.dialogCloseLabel);
		$scope.mobileAssets = dialogData.mobileAssets.split(',');
		$scope.desktopAssets = dialogData.desktopAssets.split(',');
		$scope.imagesAlt = dialogData.imagesAlt.split(',');
		$scope.startingIndex = dialogData.currentIndex;
		$scope.mobileLogo = dialogData.mobileLogo;
		$scope.mobileLogoRetina = dialogData.mobileLogoRetina;
		$scope.desktopLogo = dialogData.desktopLogo;
		$scope.desktopLogoRetina = dialogData.desktopLogoRetina;
		this.slideChanged = false;
		this.currentIndex = 1;

		this.beforeChange = (event, slick, currentSlide, nextSlide) => {
			let nextSlideNumber = nextSlide + 1;

			if(this.slideChanged || (!this.slideChanged && currentSlide === 0)) {

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label: 'vlp-gallery-arrow-slide # ' + nextSlideNumber
				});
			}

			$timeout(() => {
				this.currentIndex = nextSlideNumber;
			});

			this.slideChanged = true;
		};

		this.close = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			$rootScope.$broadcast('onModalClose');
			ngDialog.close();

			this.slideChanged = false;
		};
	}
})(angular);
