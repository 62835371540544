(function(ng) {
	angular
		.module('fca.buildAndPrice.optionsSummary')
		.service('optionsSummaryService', optionsSummaryService);

	function optionsSummaryService($http, $q, $translate, trimService, configService) {

		let $service = this;

		$service.language = $('html').attr('lang');

		const primaryColourEccCode = "0082";
		let coloursAndWheelsKey = 'colours & wheels';
		let mechanicalKey = 'mechanical';
		let entertainmentTechnologyKey = 'entertainment & technology';
		let exteriorKey = 'exterior options';
		let interiorKey = 'interior options';
		let groupKey = 'groups';
		let powertrainKey = 'powertrain';
		let safetyAndSecurityKey = 'safety & security';

		const DRIVETRAIN = 'drivetrain';
		const BOXLENGTH = 'boxLength';
		const CABTYPE = 'cabType';
		const REARWHEEL = 'rearWheel';
		const WHEELBASE = 'wheelBase';
		const TRIM_OPTION_KEYS = [DRIVETRAIN, BOXLENGTH, CABTYPE, REARWHEEL, WHEELBASE];

		$service.categoriesMapForNav = {};
		$service.categoriesMapForNav[coloursAndWheelsKey] = 'colours';
		$service.categoriesMapForNav[powertrainKey] = 'packages';
		$service.categoriesMapForNav[entertainmentTechnologyKey] = 'options';
		$service.categoriesMapForNav[exteriorKey] = 'options';
		$service.categoriesMapForNav[interiorKey] = 'options';
		$service.categoriesMapForNav[mechanicalKey] = 'options';
		$service.categoriesMapForNav[groupKey] = 'options';
		$service.categoriesMapForNav[safetyAndSecurityKey] = 'options';
		$service.isOneClassStrategy = false;

		$service.setOneClassStrategy = (isOneClassStrategy) => {
			$service.isOneClassStrategy = isOneClassStrategy;
		};


		$service.localizeCategories = () => {
			$service.categoriesMapForNav = {};

			coloursAndWheelsKey = `${$translate.instant('build-and-price.summary.colours-wheels')}`
			$service.categoriesMapForNav[coloursAndWheelsKey] = 'colours';

			mechanicalKey = `${$translate.instant('build-and-price.summary.mechanical')}`;
			$service.categoriesMapForNav[mechanicalKey] = 'options';

			entertainmentTechnologyKey = `${$translate.instant('build-and-price.summary.entertainment')}`;
			$service.categoriesMapForNav[entertainmentTechnologyKey] = 'options';

			exteriorKey = `${$translate.instant('build-and-price.summary.exterior')}`;
			$service.categoriesMapForNav[exteriorKey] = 'options';

			interiorKey = `${$translate.instant('build-and-price.summary.interior')}`;
			$service.categoriesMapForNav[interiorKey] = 'options';

			groupKey = `${$translate.instant('build-and-price.summary.groups')}`;
			$service.categoriesMapForNav[groupKey] = 'options';

			powertrainKey = $service.isOneClassStrategy ? `${$translate.instant('build-and-price.summary.package')}` : `${$translate.instant('build-and-price.summary.powertrain')}`;
			$service.categoriesMapForNav[powertrainKey] = 'packages';

			safetyAndSecurityKey = `${$translate.instant('build-and-price.summary.safety')}`;
			$service.categoriesMapForNav[safetyAndSecurityKey] = 'options';
		}

		/// Formats the selected options and configurations for display
		$service.formatSelectedOptions = (configCategoriesObjects, selectedTrim, selectedPackage, trimData) => {
			if(trimData) {
				trimService.setDictionary(trimData);
			}
			$service.categoriesObjects = configCategoriesObjects;
			let viewCategoriesObjects = {};
			$service.groupOptionsContent = {};
			$service.localizeCategories();
			viewCategoriesObjects = $service.formatPackageAndTrimInformation(selectedPackage, selectedTrim, viewCategoriesObjects, configCategoriesObjects);

			const categoriesMap = {
				exteriorColor: coloursAndWheelsKey,
				interiorColor: coloursAndWheelsKey,
				wheel: coloursAndWheelsKey,
				entertainmentTechnology: entertainmentTechnologyKey,
				exterior: exteriorKey,
				interior: interiorKey,
				mechanical: mechanicalKey,
				safetySecurity: safetyAndSecurityKey,
				group: groupKey,
				roofColor: coloursAndWheelsKey,
				seatColor: coloursAndWheelsKey
			};
			const copyCategories = $.extend(true, {}, $service.categoriesObjects);

			Object.keys(copyCategories).filter((value) => {
				return copyCategories.hasOwnProperty(value) && value;
			}).forEach((key) => {
				for (let option of copyCategories[key]) {
					if (categoriesMap[key] === undefined) {
						continue;
					}
					let desc = '';
					switch (key) {
						case 'exteriorColor':
							desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.exterior-color',
								{ color: option.description }));
							option.description = desc;
							break;
						case 'interiorColor':
							desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.interior-color',
								{ color: option.description }));
							option.description = desc;
							break;
						case 'seatColor':
							desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.seat-color',
								{ color: option.description }));
							option.description = desc;
							break;
						default:
							break;
					}
					if (option.content && categoriesMap[key] !== categoriesMap['exteriorColor']) {
						$service.formatGroupContent(option);
					}
					viewCategoriesObjects = $service.addOptionsFormatted(categoriesMap[key], option, viewCategoriesObjects);
				}
			})
			return viewCategoriesObjects;
		}

		// This format information from trim and package to give us the powertrain information
		$service.formatPackageAndTrimInformation = (selectedPackage, selectedTrim, viewCategoriesObjects, configCategoriesObjects) => {
			// warning, this method 'remembers' the powertrain information from the last time, but we can't simply flush everything
			let viewSelectedPackageAndTrim;
			if (viewCategoriesObjects[powertrainKey] && viewCategoriesObjects[powertrainKey].length > 0) {
				viewSelectedPackageAndTrim = viewCategoriesObjects[powertrainKey];
			} else {
				viewSelectedPackageAndTrim = [];
			}

			if (selectedPackage) {
				$service.formatPackageInformation(selectedPackage, viewSelectedPackageAndTrim, configCategoriesObjects);
			}

			if (selectedTrim) {
				$service.formatTrimInformation(selectedTrim, viewSelectedPackageAndTrim);
			}

			if (viewSelectedPackageAndTrim.length > 0 && viewCategoriesObjects) {
				viewCategoriesObjects[powertrainKey] = viewSelectedPackageAndTrim;
			}

			return viewCategoriesObjects;
		}

		/**
		 * The package summary comes from the enginePackage option
		 */
		$service.formatPackageInformation = (selectedPackage, viewSelectedPackageAndTrim, configCategoriesObjects) => {
			let categories = configCategoriesObjects;
			if (categories != null
				&& categories.enginePackage != null
				&& categories.enginePackage.length === 1) {
				let packageOption = categories.enginePackage[0];

				$service.replaceInArrayById(viewSelectedPackageAndTrim,{ id: 'package', code: packageOption.code, description: packageOption.description, msrp: packageOption.msrp });

				$service.groupOptionsContent[selectedPackage.code] = [
					selectedPackage.engine,
					selectedPackage.transmission,
				];

				if (selectedPackage.towing !== '') {
					const towingDesc = $translate.instant('build-and-price.summary.towing', { towing: $service.formatTowing(selectedPackage) }, undefined, false, null);
					$service.groupOptionsContent[selectedPackage.code].push(towingDesc);
				}
			}
			return viewSelectedPackageAndTrim;
		}

		$service.formatTowing = (selectedPackage) => {
			const number = $service.trunc(selectedPackage.towing);
			return `${number.toLocaleString($service.language)}lb`;
		}

		$service.trunc = (value) => {
			value = +value;
			return (value - value % 1) || (!isFinite(value) || value === 0 ? value : value < 0 ? -0 : 0);
		}

		/**
		 * utility method to remove an entry from an array, based on the id property
		 */
		$service.replaceInArrayById = (arr, value) => {
			var index = arr.map(entry => {
				return entry.id;
			}).indexOf(value.id);

			if (index != -1) {
				arr.splice(index, 1);
			}
			arr.push(value);
		};

		/// Used to get the text value of trims options from their code.
		$service.formatTrimInformation = (selectedTrim, viewSelectedPackageAndTrim) => {
			if (!selectedTrim) {
				return;
			}

			let includedText = $translate.instant('build-and-price.summary.included');
			let desc = '';
			Object.keys(selectedTrim).filter(value => {
				return selectedTrim.hasOwnProperty(value);
			}).forEach(key => {
				switch (key) {
					case DRIVETRAIN:
						desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.drivetrain',
							{ drivetrain: trimService.getDrivetrainDescription(selectedTrim[key]) }));
						$service.replaceInArrayById(viewSelectedPackageAndTrim, { id: DRIVETRAIN, description: desc, msrp: includedText });
						break;
					case BOXLENGTH:
						desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.box-length',
							{ boxLength: trimService.getBoxLengthDescription(selectedTrim[key]) }));
						$service.replaceInArrayById(viewSelectedPackageAndTrim, { id: BOXLENGTH, description: desc, msrp: includedText });
						break;
					case CABTYPE:
						desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.cab-type',
							{ cabType: trimService.getCabTypeDescription(selectedTrim[key]) }));
						$service.replaceInArrayById(viewSelectedPackageAndTrim, { id: CABTYPE, description: desc, msrp: includedText });
						break;
					case REARWHEEL:
						desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.rear-wheel',
							{ rearWheel: trimService.getRearWheelDescription(selectedTrim[key]) }));
						$service.replaceInArrayById(viewSelectedPackageAndTrim, { id: REARWHEEL, description: desc, msrp: includedText });
						break;
					case WHEELBASE:
						desc = $service.formatStringAsTitle($translate.instant('build-and-price.summary.wheel-base',
							{ wheelBase: trimService.getWheelBaseDescription(selectedTrim[key]) }));
						$service.replaceInArrayById(viewSelectedPackageAndTrim, { id: WHEELBASE, description: desc, msrp: includedText });
						break;
					default:
						break;
				}
			})
		}

		$service.formatStringAsTitle = text => {
			if ($service.language === 'en') {
				return text.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				});
			}
			return text.trim().charAt(0).toUpperCase() + text.trim().substr(1);
		}

		$service.formatGroupContent = (groupOption) => {
			// Content is a ^ separated string. Here we parse it to get the values separated into an array.
			let includedOptions = groupOption.content.split('^');

			// The content string often starts with a ^ and a space. In that case the first element is a whitespace.
			// If that is the case we remove it and set the rest of the array as the content.
			// We test to see if any characters are not whitespace.
			if (!(/\S/.test(includedOptions[0]))) {
				includedOptions = includedOptions.slice(1);
			}
			$service.groupOptionsContent[groupOption.code] = includedOptions;
		}

		$service.addOptionsFormatted = (key, option, viewCategoriesObjects) => {
			!!viewCategoriesObjects[key] ?
			viewCategoriesObjects[key].push(option) :
			viewCategoriesObjects[key] = [option];
			return viewCategoriesObjects;
		}


	}
})(angular);
