/* eslint-disable indent */
(function() {
	'use strict';

	angular
		.module('fca.dealerLocator')
		.filter('dealerUrl', DealerUrlFilter);
	/**
	 * @ngdoc filter
	 * @name fca.dealerLocator.filter:dealerUrl
	 * @description Build url to access dealer details page
	 * @requires fca.externalConfigLoader.service:externalUrlLoader
	 * @example
	 * <pre>
	 * let dealer = {
	 * 	dealerNameSlug: 'the-dealer-name-slug',
	 * 	code: 'D456',
	 * 	citySlug: 'montreal',
	 * 	provinceSlug: {
	 * 		en: 'quebec',
	 * 		fr: 'quebec'
	 * 	}
	 * };
	 *
	 * let url = $filter('dealerUrl')(dealer);
	 * // /dealers/quebec/montreal/D456-the-dealer-name-slug
	 * </pre>
	 */
	function DealerUrlFilter() {
		'ngInject';

		let uri = '/en/dealers';
		let currentLanguage = $('html').attr('lang');
		if(currentLanguage === 'fr') {
			uri = '/fr/concessionnaires';
		}

		return function(dealerInfos) {
			let name = dealerInfos.dealerNameSlug;
			let code = dealerInfos.code;
			let city = dealerInfos.citySlug;
			let province = dealerInfos.provinceSlug[currentLanguage];

			return `${uri}/${code}#${name}`;
		};
	}
})();
