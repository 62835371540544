/* eslint-disable indent */
(function () {
    angular
        .module('fca.reservation')
        .component('fcaSelectParticipatingDealer', {
            controller: SelectParticipatingDealer,
            controllerAs: '$ctrl',
            templateUrl: '/reservation/dealers/select-participating-dealer.html',
            bindings: {
                location: '<',
                currentUrl: '@',
                apiUrl: '@',
                dealersRadius:'@',
                brand: '@',
                nameplate: '@',
                trim: '@',
                year: '@',
                locale: '@',
                eshopUrl: '@',
                acode: '@',
                modelYearId: '@',
                packageCode: '@',
                engineIcon: '@',
                transmissionIcon: '@',
                title: '@'
            }
        });

    function SelectParticipatingDealer($rootScope, reservationService, $timeout, $q, $http, userLocation) {
        'ngInject';

        let $ctrl = this;

        $ctrl.dealers = [];
        $ctrl.limit = 3;

        this.$onInit = () => {
            $rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
                // If region has changed, redirect on url
                $timeout(() => {
                    $ctrl.updateLocation();
                });
            });
            $ctrl.getDealers();
            reservationService.validateUserDealerSelection = $ctrl.validateDealerSelected;
            reservationService.brand = $ctrl.brand;
            reservationService.nameplate = $ctrl.nameplate;
            reservationService.trim = $ctrl.trim;
            reservationService.year = $ctrl.year;
            reservationService.locale = $ctrl.locale;
            reservationService.eshopUrl = $ctrl.eshopUrl;
            reservationService.acode = $ctrl.acode;
            reservationService.modelYearId = $ctrl.modelYearId;
            reservationService.packageCode = $ctrl.packageCode;
            reservationService.engineIcon = $ctrl.engineIcon;
            reservationService.transmissionIcon = $ctrl.transmissionIcon;
        };

        $ctrl.updateLocation = () => {
            // We need to get list for new location
            $ctrl.getDealers();
            $ctrl.resetSelectedDealer();
        };

        $ctrl.getDealers = () => {
            $ctrl.postalCode = $ctrl.location.postalCode

            let url = $ctrl.currentUrl + '/dealers';
            let location = $ctrl.location;
            $http.get(url, {
                params: {
                    brand: $ctrl.brand,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    radius: $ctrl.dealersRadius,
                    isDigital: true,
                    withoutBranchLocation: true,
                    province: userLocation.getLocationProvince()
                }
            }).then((response) => {
                let data = response.data;
                $ctrl.dealers = data;
            }).catch(function(e) {
                console.error('error retrieving participating dealers', e);
            });
        };

        $ctrl.selectDealer = (dealer) => {
            $ctrl.selectedDealer = dealer;
            reservationService.selectedDealer = dealer;

            // Submit reservation
            reservationService.submit($ctrl.apiUrl);
        };

        $ctrl.resetSelectedDealer = () => {
            $ctrl.selectedDealer = null;
            reservationService.selectedDealer = null;
        }

        $ctrl.loadMore = function() {
            var incremented = $ctrl.limit + 3;
            $ctrl.limit = incremented > $ctrl.dealers.length ? $ctrl.dealers.length : incremented;
        };

        $ctrl.validateDealerSelected = () => {
            let defer = $q.defer()

            $ctrl.errorMsgVisible = false;

            if( $ctrl.selectedDealer == null ) {
                $ctrl.errorMsgVisible = true;
                $timeout(() => {
                    $('html, body').animate({scrollTop: $('#dealerSelectorErrors').offset().top -100 }, 'slow');
                });
                defer.reject('dealer not selected');
            } else {
                $ctrl.errorMsgVisible = false;
                defer.resolve();
            }
            return defer.promise;
        }
    }
})();