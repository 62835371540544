(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaConfirmDealerContact', {
			templateUrl: '/components/contact-dealer/confirm-dealer-contact/confirm-dealer-contact.html', // eslint-disable-line max-len
			bindings: {
				firstName: '<',
				lastName: '<',
				contactReason: '<',
				selectedDealer: '<',
				selectedVehicle: '<',
				formData: '<',
				cancelFn: '&'
			},
			controller: daaConfirmDealerContactController
		});

	function daaConfirmDealerContactController(translateConfigurator) {
		'ngInject';

		let language = translateConfigurator.language;

		this.$onInit = () => {
			this.selectedVehicleString = buildVehicleNameString(this.selectedVehicle);
		};

		this.cancel = (event) => {
			if (event) {
				event.preventDefault();
			}

			if (this.cancelFn) {
				this.cancelFn({
					event: event
				}); // warning todo, hook up this functions
			}
		};

		function buildVehicleNameString(vehicle = {}) {
			let name = '';

			if (vehicle.year && language === 'en') {
				name += `${vehicle.year} `;
			}

			if (vehicle.brand) {
				if (vehicle.brand[language]) {
					name += `${vehicle.brand[language]} `;
				} else {
					name += `${vehicle.brand} `;
				}
			}

			if (vehicle.label) {
				name += `${vehicle.label[language]} `;
			}

			if (vehicle.name) {
				name += `${vehicle.name[language]} `;
			}

			if (vehicle.year && language === 'fr') {
				name += `${vehicle.year}`;
			}

			return name.trim();
		}
	}
})();
