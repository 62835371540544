(function() {
	angular
		.module('fca.commonLegalNotes')
		.service('LegalNotes', LegalNotes);

	function LegalNotes($log) {
		'ngInject';

		const groupsDefs = [{
			name: 'disclaimer',
			range: buildNumArray(200) // allowing up to 200 unique disclaimers
		}, {
			name: 'legal',
			range: 'abcdefghijklmnopqrstuvwxyz'.split('')

		}];

		this.legalNotes = {};

		this.addLegalNote = (group, key, content) => {
			let groupIndex;

			for (let i = 0; i < groupsDefs.length; i++) {
				if (groupsDefs[i].name === group) {
					groupIndex = groupsDefs[i];
					break;
				}
			}

			/* Make sure group exists */
			if (groupIndex.length < 0) {
				$log.debug(`Cannot find group definition for group ${group}`);
				return;
			} else if (groupIndex.range.indexOf(key) < 0) {
				$log.debug(`Key ${key} is out of range in group ${group}`);
				return;
			}

			/* Add legal note group collection if does not already exists */
			if (!this.legalNotes[group]) {
				this.legalNotes[group] = {};
			}

			/* Don't add notes a second time */
			if (this.legalNotes[group][key]) {
				return;
			}

			let name = groupIndex.name;

			this.legalNotes[name] = addItemToCollection(this.legalNotes[name], key, content);
		};

		function buildNumArray(num) {
			let i = 0;
			let arr = [];

			while (i < num) {
				arr.push(`${i + 1}`);
				i++;
			}

			return arr;
		}

		function addItemToCollection(collection, key, content) {
			/* Do not show if no content */
			if (content.length > 0) {
				collection[key] = content;
			}

			/* Sort the object */
			let orderedKeys = Object.keys(collection).sort();
			let orderedObject = {};

			orderedKeys.forEach((i) => {
				orderedObject[i] = collection[i];
			});

			return orderedObject;
		}
	}
})();
