(function () {
	angular
		.module('fca.miniBuildAndPrice.interior')
		.component('interiorMiniBp', {
			controller: interiorMiniBp,
			controllerAs: '$ctrl',
			templateUrl: '/shared/fca-mini-build-and-price/interior/interior-mini-bp.html',
			bindings: {
				jellyData: '<',
				jellyTitle: '@',
				placeholderImage: '@',
				vehicleName: '@',
				minibpAnalyticsIdBase: '@',
				currentDrivetrainId: '@',
				brand: '@'
			}
		});

	function interiorMiniBp($scope, $rootScope, $element, $document, $window, $timeout, brandJellyBuilder, brandJellyLoader, brandInteriorPanorama) {
		'ngInject';

		const $ctrl = this;
		const defaultPov = 'I01';

		const isIE = /MSIE|Trident/.test(navigator.userAgent);
		const navOffset = isIE ? 0 : 57;

		$ctrl.preloadKey = '';

		const onImageLoad = loadedImage => {
			if (loadedImage.preloadKey !== $ctrl.preloadKey) return;

			$scope.$apply(() => {
				$ctrl.jellyUrl = loadedImage.url;
			});
		}

		$ctrl.$onChanges = changes => {
			if ($ctrl.show360Viewer) {
				$ctrl.show360Viewer = false;
				$ctrl.toggle360Viewer();
			}
			if (!changes.jellyData || !changes.jellyData.currentValue) {
				return;
			}

			const jellyData = changes.jellyData.currentValue;
			const newPreloadKey = $ctrl.buildJellyUrl(jellyData);

			if (newPreloadKey === $ctrl.preloadKey) return;

			$ctrl.preloadKey = newPreloadKey;
			brandJellyLoader.loadImages(
				newPreloadKey,
				$ctrl.buildJellyUrl.bind(jellyData),
				defaultPov,
				[],
				onImageLoad
			);
		}

		let interiorImage;
		let lastScrollY = -1;
		$ctrl.$postLink = () => {
			interiorImage = document.getElementById('js-AR-interior-jelly');

			window.addEventListener(
				'resize',
				() => requestAnimationFrame($ctrl.onResize)
			);

			window.addEventListener(
				'scroll', () => {
					if (!$ctrl.show360Viewer) {
						lastScrollY = window.pageYOffset;
					}
				});

			window.addEventListener(
				'keydown',
				event => $scope.$apply(onKeydown(event))
			);

			$('#mini-bp_interior-overlay-container, .photosphereContainer').on('mousedown', event => {
				event.preventDefault();
				if (event.target.id === 'mini-bp_interior-overlay-container') {
					$scope.$apply(() => {
						$ctrl.toggle360Viewer()
					});
				}
			});
			$rootScope.$on('interior-panorama:kill-360', () => {
				$ctrl.toggle360Viewer()
			});

			$ctrl.onResize();
		};

		const imageRatio = 3200 / 1800;
		$ctrl.onResize = () => {
			requestAnimationFrame(() => {
				let heightMinusNav = innerHeight - navOffset;
				const contentWidth = Math.min(innerWidth, 1600);

				const ratio = contentWidth / heightMinusNav;

				$(interiorImage).toggleClass('-skinny', ratio < imageRatio);
			});
		};

		$ctrl.buildJellyUrl = (jellyData, newPov) => {
			jellyData = jellyData || $ctrl.jellyData;
			newPov = newPov || defaultPov;

			if (!jellyData) {
				return;
			}

			const jellyOptions = Object.assign(
				{ pov: newPov },
				$ctrl.jellyData
			);

			return brandJellyBuilder.jellyUrl(jellyOptions);
		};

		$ctrl.imageIsLoaded = () => $ctrl.preloadKey === $ctrl.jellyUrl;

		$ctrl.toggle360Viewer = () => {
			const parentContainer = document.querySelector('.director-mini-bp');
			const image = $element.find('.mini-bp_interior-jelly-container').get(0);
			const mediaQuerySmallPlus = window.matchMedia("(min-width: 768px)");
			const portraitOrientation = window.matchMedia("(orientation: portrait)");
			const landscapeOrientation = window.matchMedia("(orientation: landscape)");
			const panoramaUrl = $ctrl.buildJellyUrl($ctrl.jellyData, 'centerpano');
			const photosphereElement = $element.find('.photosphereContainer').get(0);

			if (!$ctrl.show360Viewer) {
				if (mediaQuerySmallPlus.matches) {
						brandInteriorPanorama.setImageDimensions({ width: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientWidth - 100 :image.clientWidth), height: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientHeight - 100 :image.clientHeight) });

				} else {
					if (portraitOrientation.matches) {
						brandInteriorPanorama.setImageDimensions({ width: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientWidth : image.clientWidth), height: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientHeight + 100 : image.clientHeight) });
					} else if (landscapeOrientation.matches) {
						brandInteriorPanorama.setImageDimensions({ width: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientWidth: image.clientWidth), height: ($ctrl.brand == 'alfaromeo' ? parentContainer.clientHeight : image.clientHeight) });
					}
				}
				brandInteriorPanorama.toggle360Viewer(panoramaUrl, photosphereElement);
			}
			$timeout(() => {
				$ctrl.show360Viewer = !$ctrl.show360Viewer;
			});

		};

		function kill360() {
			$ctrl.show360Viewer = false;
			window.scrollTo(0, lastScrollY);
			$('body').removeClass('noScroll');
			$('.mini-bp_interior-overlay-btn').first().focus();
		}

		function onKeydown(keyEvent) {
			switch (keyEvent.keyCode) {
				case 27 :
					kill360();
					break;
			}
		}
		$ctrl.close360 = () => kill360();
	}
})()
