/* eslint-disable indent */
(function () {
	angular
		.module('fca.buildAndPrice.director')
		.service('optionCategoryService', optionCategoryService);

	function optionCategoryService() {
		// configCategoryService
		'ngInject';
		let $service = this;

		$service.categoriesMap = {
			enginePackage: 'enginePackage',
			exteriorColor: 'exteriorColor',
			interiorColor: 'interiorColor',
			wheel: 'wheel',
			entertainmentTechnology: 'entertainmentTechnology',
			exterior: 'exterior',
			interior: 'interior',
			mechanical: 'mechanical',
			group: 'group',
			roofColor: 'roofColor',
			safetySecurity: 'safetySecurity',
			seatColor: 'seatColor',
		};

		$service.getTrimGroupForAcode = (acode, trimGroups) => {
			if (!trimGroups) {
				return;
			}
			for (let i = 0; i < trimGroups.length; i++) {
				const trimGroup = trimGroups[i];
				for (let j = 0; j < trimGroup.trims.length; j++) {
					if (trimGroup.trims[j].acode === acode) {
						return trimGroup;
					}
				}
			}
			return null;
		};

		$service.getCategoriesObjects = (config) => {
			let categoriesObjects = {};
			Object.keys(config).filter(value => {
				return config.hasOwnProperty(value);
			}).forEach((key) => {
				if ($service.categoriesMap[key] !== undefined) {
					$service.parseCategory(config, key, categoriesObjects);
				}
			});
			return categoriesObjects;
		};

		$service.parseCategory = (config, category, categoriesObjects) => {
			// $rootScope.$broadcast('trim-selector:options-' + category + '-updated', { options: config[category] });


			// Flattened array of all options in that category
			let newOptions = [];
			config[category]['categories'].forEach(optionCategory => {
				optionCategory.options.forEach(option => {
					newOptions.push(option);
				})
			});

			// remove any option that's not in that category anymore
			if (categoriesObjects[category] != null) {
				categoriesObjects[category] = categoriesObjects[category].filter(currentOption => {
					return newOptions.find( newOption => newOption.code === currentOption.code ) !== undefined;
				});
			}

			config[category]['categories'].forEach(element => {
				element.options.forEach(option => {
					option.selected = $service.checkIfOptionShouldBeSelected(option);
					$service.handleOptionSelected({ category: category, option: option }, categoriesObjects);
				})
			});
		};

		$service.checkIfOptionShouldBeSelected = (option) => {
			return option.state === "S" || option.state === "G"
		};

		$service.handleOptionSelected = (optionObject, categoriesObjects) => {
			if (categoriesObjects[optionObject.category] !== undefined) {
				let savedOptionIndex = $service.findOptionIndex(optionObject, categoriesObjects);
				if (savedOptionIndex !== -1 && !optionObject.option.selected) {
					categoriesObjects[optionObject.category].splice(savedOptionIndex, 1);
				} else if (savedOptionIndex === -1 && optionObject.option.selected) {
					categoriesObjects[optionObject.category].push(optionObject.option);
				}
			} else if (optionObject.option.selected) {
				categoriesObjects[optionObject.category] = [optionObject.option];
			}
		};

		$service.findOptionIndex = (optionObject, categoriesObjects) => {
			let result = -1;
			categoriesObjects[optionObject.category].forEach((element, index) => {
				if (optionObject.option.code === element.code) {
					result = index;
					return;
				}
			});
			return result;
		};


	}
})(angular);
