(function() {
    angular
        .module('fca.brandApp')
        .directive('fcaFeatureSound', fcaFeatureSound);

    function fcaFeatureSound() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                analyticsvalue: '@',
                analyticsid: '@',
                analyticscategory:'@'
            },
            controllerAs: 'fcaFeatureSoundController',
            controller: fcaFeatureSoundController
        };

        function fcaFeatureSoundController($element, $scope, gtmAnalytics) {
            'ngInject';
            /* eslint-disable no-invalid-this */
            const $ctrl = this;
            let audioElement = null;
            $ctrl.isPlaying = false;
            $ctrl.isOR = $('body').hasClass('OR-page');

            $ctrl.$postLink = () => {
                audioElement = $($element[0]).find('audio')[0];
                audioElement.addEventListener('ended', $ctrl.isEnded, false);
            };

            $ctrl.triggerGTMEvents = (event) => {
                let gtmCategory;
                if ($ctrl.isOR) {
                    gtmCategory = `Only-in-a-Ram-${$ctrl.analyticscategory}`
                } else {
                    gtmCategory = $ctrl.analyticscategory;
                }
                let eventObject = {
                    category: gtmCategory,
                    label: `${$ctrl.analyticsid}${$ctrl.analyticsvalue}`
                };
                gtmAnalytics.trackEvent('event', eventObject);
            }

            $ctrl.toggleVideo = () => {
                if ($ctrl.isPlaying) {
                    audioElement.pause();
                    $ctrl.isPlaying = false;
                } else {
                    try {
                        audioElement.play();
                        $ctrl.isPlaying = true;
                    } catch(e) {
                        console.log(e);
                    }
                }

                $ctrl.triggerGTMEvents();
            };

            $ctrl.isEnded = () => {
                $ctrl.isPlaying = false;
                $scope.$apply();
            };
        }
    }
})();
