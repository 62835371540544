(function () {
	angular
		.module('fca.brandApp')
		.directive('qvYoutubeVideo', qvYoutubeVideo);

	function qvYoutubeVideo() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'qvYoutubeVideo',
			controller: qvYoutubeVideoController
		};

		function qvYoutubeVideoController($scope, $element, $rootScope) {
			'ngInject';

			const $ctrl = this;
			const overlayLink = $element[0].querySelector('.AR-youtube-video-block__link');
			let overlayAnimationDone = false;

			$rootScope.$on('onScrollingUpdate', () => {
				const parentElem = $($element[0]).closest('.vertical-scroll-block-container');

				if (parentElem[0].classList.contains('half-seen') && !overlayAnimationDone) {
					overlayAnimationDone = true;
					overlayLink.classList.add('is-visible');
				}
			});
		}
	}
})();
