(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaStats', fcaStats);

	function fcaStats() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'stats',
			controller: fcaStatsController
		};

		function fcaStatsController($element, $window, $rootScope, $scope, $filter) {
			'ngInject';

			this.isAnimationDone = false;
			this.isAnimationStart = false;
			this.isBackgroundCircleAppear = false;

			let circleArray;
			let canvasWidth;
			let colorIsYellow = false;

			this.$postLink = () => {
				circleArray = $($element[0]).find('.AR-stats-single-circle');
				if ($element[0].classList.contains('AR-stats--yellow')) {
					colorIsYellow = true;
				}
				circleArray.each(i => {
					this.drawCanvas(i);
				});
			};

			this.startCanvasAnimation = () => {
				const parentBlock = $($element[0]).parents('.vertical-scroll-block-container')[0];

				if ((parentBlock.classList.contains('has-not-passed') || parentBlock.classList.contains('has-passed')) &&
					(!parentBlock.classList.contains('is-current'))) {
						this.isAnimationStart = false;
						this.isAnimationDone = false;
						circleArray.each(i => {
							this.drawCanvas(i, false)
						});
					}
					if (parentBlock && !this.isAnimationDone && !this.isAnimationStart &&
						(parentBlock.classList.contains('is-current')) ) {
					this.isAnimationDone = true;
					this.isAnimationStart = true;
					circleArray.each(i => {
						this.drawCanvas(i);
					});
				}
			};

			this.drawCanvas = (index, loop = true) => {
				const currentElement = circleArray[index];
				const currentElementNumber = currentElement.dataset.number;
				const currentElementText = currentElement.querySelector('span');
				const countToNumber = parseFloat(currentElementNumber);
				canvasWidth = currentElement.clientWidth - (currentElement.clientWidth / 4);
				currentElement.style.height = `${canvasWidth}px`;
				let canvas = document.createElement('canvas');
				if (!currentElement.querySelector('.AR-stats-single-canvas')) {
					canvas.id = `stats-canvas-${index}`;
					canvas.className = "AR-stats-single-canvas";
					canvas.width = canvasWidth;
					canvas.height = canvasWidth;
					currentElement.appendChild(canvas);
				} else {
					canvas = currentElement.querySelector('.AR-stats-single-canvas');
				}

				if ((index === 0 || index === circleArray.length - 1) && !this.isBackgroundCircleAppear) {
					// Make the gray circle appear
					const backgroundCanvasWidth = parseInt(canvasWidth * 1.1, 10);
					const backgroundCanvas = document.createElement('canvas');

					this.isBackgroundCircleAppear = index === circleArray.length - 1;
					backgroundCanvas.className = "AR-stats-background-canvas";
					backgroundCanvas.width = backgroundCanvasWidth;
					backgroundCanvas.height = backgroundCanvasWidth;
					currentElement.appendChild(backgroundCanvas);
					const bgCtx = backgroundCanvas.getContext('2d');
					drawGradiantStroke(bgCtx, backgroundCanvasWidth, index, true, colorIsYellow);
					drawBackgroundCircle(bgCtx, backgroundCanvasWidth);
				}

				const ctx = canvas.getContext('2d');
				drawGradiantStroke(ctx, canvasWidth, index, false, colorIsYellow);

				const circum = Math.PI * 2,
					start = Math.PI / 2;

				let finish = 100;
				let curr = 0;

				function animate(draw_to) {
					drawCircleLine(ctx, draw_to);
					if (curr < finish) {
						const currentNumber = countToNumber * ( curr / finish );
						let displayNumber = parseFloat(currentNumber.toFixed(2)) === parseInt(currentNumber, 10) ?
							currentNumber : (currentNumber).toFixed(2);

						if(countToNumber > 9) {
							displayNumber = Math.round(displayNumber);
						}
						
						displayNumber = $filter('fcaRate')(displayNumber); 

						currentElementText.textContent = displayNumber;

						requestAnimationFrame(() => {
							animate(circum * curr / 100 + start);
						});
					} else if (finish === 0) {
						currentElementText.textContent = 0;
					} else {
						currentElementText.textContent = $filter('fcaRate')(countToNumber);
					}
					curr++;
				}
				if (this.isAnimationStart) {
					animate();
				} else if (!loop) {
					finish = 0;
					animate();
				}
			};

			this.onCanvasResize = () => {
				circleArray.each(i => {
					const currentElement = circleArray[i];
					const canvas = currentElement.querySelectorAll('canvas');
					canvasWidth = currentElement.clientWidth - (currentElement.clientWidth / 4);
					currentElement.style.height = `${canvasWidth}px`;

					if (canvas && canvas[0]) {
						canvas[0].width = canvasWidth;
						canvas[0].height = canvasWidth;
						const ctx = canvas[0].getContext('2d');
						drawGradiantStroke(ctx, canvasWidth, i, false, colorIsYellow);
						drawCircleLine(ctx, Math.PI * 3);
					}
					if (canvas && canvas[1]) {
						const bgWidth = canvasWidth * 1.1;
						const ctx = canvas[1].getContext('2d');
						canvas[1].width = bgWidth;
						canvas[1].height = bgWidth;
						drawGradiantStroke(ctx, bgWidth, i, true, colorIsYellow);
						drawBackgroundCircle(ctx, bgWidth);
					}
				});
			}

			function drawBackgroundCircle(ctx, width) {
				ctx.clearRect(0, 0, width, width);
				ctx.beginPath();
				ctx.arc((width) / 2, (width) / 2, ((width) / 2) - 10, Math.PI / 2, Math.PI * 3, false);
				ctx.stroke();
			}

			function drawCircleLine(ctx, draw_to) {
				ctx.clearRect(0, 0, canvasWidth, canvasWidth);
				ctx.beginPath();
				ctx.arc(canvasWidth / 2, canvasWidth / 2, (canvasWidth / 2) - 10, Math.PI / 2, draw_to, false);
				ctx.stroke();
			}

			function drawGradiantStroke(ctx, width, index, isBackground, isYellow) {
				const gradiantAngle = parseInt(index / 2, 10) * 75;
				const gradient = ctx.createLinearGradient(0,0, gradiantAngle,width);

				if (isBackground) {
					gradient.addColorStop(0, 'black');
					gradient.addColorStop(.5, 'black');
					gradient.addColorStop(.8, '#0f0f0f');
					gradient.addColorStop(.98, 'white');
					gradient.addColorStop(1, 'white');
				} else if (isYellow) {
					gradient.addColorStop(0, '#f7e53c');
					gradient.addColorStop(.5, '#63501b');
					gradient.addColorStop(.9, 'black');
					gradient.addColorStop(1, 'black');
				} else {
					gradient.addColorStop(0, '#FF0000');
					gradient.addColorStop(.5, '#940000');
					gradient.addColorStop(.9, 'black');
					gradient.addColorStop(1, 'black');
				}

				ctx.strokeStyle = gradient;
				ctx.lineWidth = isBackground ? 2 : parseInt(width / 65, 10);
			}

			angular.element($window).on('resize', this.onCanvasResize);
			const onScroll = $rootScope.$on('onScrollingUpdate', this.startCanvasAnimation);

			$scope.$on('$destroy', () =>{
				angular.element($window).off('resize', this.onCanvasResize);
				onScroll();
			});

		}
	}
})();
