(function () {
    angular
        .module('fca.commonDirectives')
        .directive('vehicleInventoryListItem', VehicleInventoryListItem);

    function VehicleInventoryListItem() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$vehicleInventoryListItemCtrl',
            bindToController: {
                vehicle: '=',
                globalFinanceMode: '=',
                financeModeCallback: '=',
                financeModeRegister: '='
            },
            controller: VehicleInventoryListItemCtrl
        };

        function VehicleInventoryListItemCtrl() {
            const $ctrl = this;

            $ctrl.$onInit = () => {
                // default to finance
                $ctrl.currentFinanceMode = 'finance';

                // if there's a global finance mode, try to honor it
                if (!!$ctrl.globalFinanceMode) {
                    $ctrl.changeFinanceMode($ctrl.globalFinanceMode);
                }

                // register ourselves
                if ($ctrl.financeModeRegister) {
                    $ctrl.financeModeRegister($ctrl.globalFinanceModeChanged);
                }
            };

            /**
             * Change finance mode
             */
            $ctrl.changeFinanceMode = (newFinanceMode) => {
                if (newFinanceMode === 'cash') {
                    $ctrl.currentFinanceMode = 'cash';
                } else if (newFinanceMode === 'finance' && !!$ctrl.vehicle.inventoryPricing.finance.price.value) {
                    $ctrl.currentFinanceMode = 'finance';
                } else if (newFinanceMode === 'lease' && !!$ctrl.vehicle.inventoryPricing.lease.price.value) {
                    $ctrl.currentFinanceMode = 'lease';
                }
            };

            /**
             * Triggered when the user clicks on a finance mode pill
             */
            $ctrl.clickFinanceMode = (newFinanceMode) => {
                $ctrl.changeFinanceMode(newFinanceMode);
                // if the callback method exists, call it
                if (!!$ctrl.financeModeCallback) {
                    $ctrl.financeModeCallback($ctrl.currentFinanceMode);
                }
            };

            /**
             * Triggered whenever the finance mode changes for any vehicle (including this one)
             * @param data
             */
            $ctrl.globalFinanceModeChanged = (data) => {
                $ctrl.changeFinanceMode(data);
            };

            $ctrl.getHashParameters = () => {
                // include the finance mode
                return `#activeTab=${$ctrl.currentFinanceMode}`;
            };
        }
    }
})();
