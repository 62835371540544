(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaGalleryPage', alfaGalleryPage);

	function alfaGalleryPage() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				isSlideshow: '<'
			},
			controllerAs: 'alfaGalleryPage',
			controller: alfaGalleryPageController
		};

		function alfaGalleryPageController($scope, $element, $timeout, $location, gtmAnalytics, $window) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			const $ctrl = this;
			this.$element = $element;
			this.isInit = false;
			this.activeItemId = -1; // linked to slider index
			this.activeRowId = -1;
			this.currentType = 0;
			this.$content = $('.gallery-page-grid-content', $element);
			this.$items = $('.gallery-page-grid-item', $element);
			this.focussedButton;
			let dialogRef;

			$scope.$on('galleryPageFilter.filterUpdated', (_, data) => {
				this.collapse();
				this.currentType = data.type;
				const thumbItem = $('.gallery-page-grid-item');
				const slickSlider = $('.gallery-slider-component');
				if (this.currentType !== 0) {
					const filteredItems = `.gallery-page-display-item[data-gallery-type=${this.currentType}]`;
					// when switching filters, we need to remove any filters, or else, the slider might be empty
					slickSlider.slick('slickUnfilter');
					slickSlider.slick('slickFilter', filteredItems);
					if (thumbItem.attr('data-gallery-type') !== this.currentType) {
						thumbItem.addClass('hidden');
						$(`.gallery-page-grid-item[data-gallery-type=${this.currentType}]`).removeClass('hidden');
					}
				} else {
					slickSlider.slick('slickUnfilter');
					thumbItem.removeClass('hidden');
				}
				this.calculateNewIndex();

				const slickTrack = this.$element.find('.slick-track');
				if (slickTrack.length === 1) {
					slickTrack.css('transform','translate3d(0,0,0)');
				}
			});
			this.goto = itemId => {
				if (itemId !== this.activeItemId) {
					// set current slide index to itemId
					this.activeItemId = itemId;
					this.updateActiveItem();
					$timeout(this.triggerActiveSlideContent);
				}else if (this.activeRowId === -1) {
					// expand content
					this.updateActiveItem();
					$timeout(this.triggerActiveSlideContent);
				}
			};

			this.getCustomIndex = (itemType, event) => {
				this.goto(event.currentTarget.getAttribute('data-custom-gallery-index'))
			};

			this.onBeforeChange = (event, slick, currentSlide, nextSlide) => {
				if (!this.isInit) {
					this.isInit = true;
					this.calculateNewIndex();
				} else {
					if ($(slick.$slides[nextSlide]).hasClass('gallery-page-display-item-vr360')) {
						$('.gallery-slider-component').slick("slickSetOption", "draggable", false, false);
					} else {
						$('.gallery-slider-component').slick("slickSetOption", "draggable", true, true);
					}
					$scope.$broadcast('galleryPage.collapse');
					this.updateActiveItem(nextSlide);
				}
			};

			this.calculateNewIndex = () => {
				// Since we can't rely on the ftl indexes, we need to create new indexes according to the slider.

				// Note : We can't rely on the ftl indexes because the gallery is composed of multiple arrays in Sling,
				// so whenever we reach a new array, the indexes restart.
				const slides = $('.gallery-slider-component').find('.gallery-page-slideshow-item');
				slides.each((slideIndex, slideElem) => {
					if (!$(slideElem).hasClass('slick-cloned')) {
						const $thumbItems = $ctrl.currentType === 0 ? $('.gallery-page-grid-item') : $(`.gallery-page-grid-item[data-gallery-type=${$ctrl.currentType}]`);
						$thumbItems.each((thumbIndex, thumbElem) => {
							const newSlideIndex = (slideIndex - 1);
							if (newSlideIndex === thumbIndex) {
								thumbElem.setAttribute('data-custom-gallery-index', newSlideIndex);
							}
						});
						$thumbItems[0].setAttribute('data-custom-gallery-index', 0)
					}
				});
			};

			this.updateActiveItem = (itemId = -1) => {
				let offset = 120;
				const windowWidth = $ctrl.getWindowWidth();

				if (itemId === -1) itemId = this.activeItemId;
				if (itemId === -1) {
					this.collapse();
				} else {
					const $item = $ctrl.currentType === 0 ?
						$(`.gallery-page-grid-item[data-custom-gallery-index="${itemId}"]`) :
						$(`.gallery-page-grid-item[data-custom-gallery-index="${itemId}"][data-gallery-type=${$ctrl.currentType}]`);
					const rowId = $item.data('custom-gallery-index');
					let delay = 0;

					this.focussedButton = $item;

					$ctrl.$element.css('min-height', $ctrl.$element.height());
					this.clearItems();
					if (rowId !== this.activeRowId && this.activeRowId !== -1) {
						if (this.isSlideshow) delay = 500;
					}

					if (windowWidth < 768) {
						offset = $ctrl.calculateOffset(windowWidth);
					}

					this.activeRowId = rowId;
					$timeout(() => {
						const slideShowHeight = $ctrl.$content.find('.gallery-page-slideshow').height();
						$ctrl.$content.height(slideShowHeight);
						if($item.length > 0) {
							$item.addClass('active').css('margin-bottom', $ctrl.$content.height());
							$ctrl.$content.css('top', ($item.offset().top + $item.outerHeight())).addClass('expanded');
							$ctrl.scrollToContent(($item.offset().top + offset));
							this.triggerActiveSlideContent();
						}
					}, delay);

					gtmAnalytics.trackEvent('event', {
						category: 'gallery',
						label: `image - ${$($item).data('analyticsLabel')}`
					});
				}
			};

			this.collapse = () => {
				$ctrl.$element.css('min-height', 'unset');
				if (this.activeRowId !== -1) {
					this.scrollToRow(this.activeRowId);
				}
				this.activeRowId = -1;
				this.$content.removeClass('expanded');
				this.clearItems();
				$scope.$broadcast('galleryPage.collapse');
				$timeout(() => {
					this.focussedButton.focus();
				});
			};

			this.clearItems = () => {
				$('.gallery-page-grid-item').removeClass('active').css('margin-bottom', 0);
			};

			this.scrollToRow = rowId => {
				let top = 0;
				if($(`[data-custom-gallery-index="${rowId}"]`).length > 0) {
					top = $(`[data-custom-gallery-index="${rowId}"]`).offset().top;
				}
				$('html, body').stop().animate({
					scrollTop: top
				}, 500);
			};

			this.scrollToContent = (offset) => {
				if (this.isSlideshow) {
					$('html, body').stop().animate({scrollTop: offset}, 500);
				}
			};

			this.triggerActiveSlideContent = () => {
				// trigger slide content (play video, start 360, etc.)
				const $currentSlideElem = $ctrl.$element.find('.slick-current');
				const galleryItemId = $currentSlideElem.data('slick-index');
				$scope.$broadcast('galleryPage.triggerActiveSlideContent', galleryItemId);
				if(this.$content.hasClass('.expanded')) {
					this.focusTrapActive();
				}

			};

			this.focusTrapActive = () => {
				let navDialogEl = document.querySelector(".gallery-page-grid-content");
				let dialogOverlay = document.querySelector('.gallery-page-slideshow');
				dialogRef = new Dialog(navDialogEl, dialogOverlay);
				dialogRef.addEventListeners(
					'.slick-next.slick-arrow',
					'.gallery-page-slideshow-close'
				);
				dialogRef.open();
			};

			this.getWindowWidth = () => {
				return $window.innerWidth;
			}

			this.calculateOffset = (screenWidth) => {
				if (screenWidth > 725 && screenWidth <= 767) {
					return 95;
				} else if (screenWidth > 665) {
					return Math.max(85 - (725 - screenWidth) * 0.5, 45);
				} else if (screenWidth === 360) {
					return 38;
				} else {
					return Math.max(140 - (665 - screenWidth) * 0.5, 45);
				}
			}

		}
	}
})();
