(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name fca.dialog.theme.popover
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dialog.theme.popover')
		.provider('fcaDialogThemePopover', ThemeProvider);

	function ThemeProvider() {
		'ngInject';

		/* Dialog configurations */
		let cfg = this.cfg = {
			template: '/dialogs/themes/popover/popover.html',
			appendClassName: 'fca-dialog-popover',
			closeByDocument: true
		};

		/* Theme name */
		this.name = 'popover';

		/**
		 * @description Public service
		 * @return {Object} Provider instance
		 */
		this.$get = () => {
			return cfg;
		};
	}
})();
