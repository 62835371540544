(function() {
	angular
		.module('alfaromeo.vlp')
		.directive('alfaVlpMozaic', alfaVlpMozaic);

	function alfaVlpMozaic() {
		return {
			restrict: 'A',
			controllerAs: '$ctrl',
			controller: AlfaVlpMozaic,
		};

		function AlfaVlpMozaic($element, matchmedia, FCA_MQ_LAYOUT) {
			'ngInject';

			// only for IE11 because it does not support CSS grid very well
			const isIE11 = /MSIE|Trident/.test(navigator.userAgent);
			if( !isIE11 ) return;

			const $ctrl = this;

			const LAYOUT_MOBILE = 'mobile';
			const LAYOUT_DESKTOP = 'desktop';

			let currentLayout;
			let matchMediaUnregister;
			let gridItems;

			$ctrl.$onInit = () => {
				gridItems = $element.find('.AR_mozaic-gridItem');
				if( gridItems.length === 0 ) return;

				matchMediaUnregister = matchmedia.on(FCA_MQ_LAYOUT.LARGE_PLUS, onMatchMedia);
			};
			$ctrl.$onDestroy = () => {
				if( matchMediaUnregister ) matchMediaUnregister();

				matchMediaUnregister = null;
				currentLayout = null;
				gridItems = null;
			};


			const onMatchMedia = (mediaQueryList) => {
				if( matchmedia.is(FCA_MQ_LAYOUT.LARGE_PLUS) ) desktopLayout();
				else mobileLayout();
			};
			const mobileLayout = () => {
				if( currentLayout === LAYOUT_MOBILE ) return;
				currentLayout = LAYOUT_MOBILE;

				let row = 1;
				let column = 1;

				gridItems.each((index, el) => {
					setGridData(el, column, row);

					let spanOnTwoColumns = false;

					// all these scenarios will set next item at the beginning of next row
					if( index === 0 && el.classList.contains('-type--alfa-mozaic-small') ) spanOnTwoColumns = true;
					else if( el.classList.contains('-type--alfa-mozaic-medium-vertical') ) spanOnTwoColumns = true;
					else if( el.classList.contains('-type--alfa-mozaic-medium-technology') ) spanOnTwoColumns = true;
					else if( el.classList.contains('mobile-wide') ) spanOnTwoColumns = true;
					else if( el.classList.contains('-type--alfa-mozaic-large') ) spanOnTwoColumns = true;
					else if( el.classList.contains('-type--alfa-mozaic-full-width') ) spanOnTwoColumns = true;
					else if( el.classList.contains('-type--alfa-mozaic-large-left-technology') ) spanOnTwoColumns = true;

					// if current grid item span on two columns, next item is on the next row
					if( spanOnTwoColumns ) {
						column = 1;
						row++;
					} else {
						if( column < 2 ) column++;
						else {
							column = 1;
							row++;
						}
					}
				});
			};
			const desktopLayout = () => {
				if( currentLayout === LAYOUT_DESKTOP ) return;
				currentLayout = LAYOUT_DESKTOP;

				let row = 1;
				let column = 1;
				let skipColumn = null;

				gridItems.each((index, el) => {

					// if this column is skipped due to a two rows grid item
					if( skipColumn === column ) {
						// if there is empty column at right, continue on this row
						if( column < 3 ) column++;
						else {
							// otherwise, skip on next row
							column = 1;
							row++;
						}

						// reset skipped column setting
						skipColumn = null;
					}

					setGridData(el, column, row);

					// all these scenarios will push next item by two columns
					if( el.classList.contains('-type--alfa-mozaic-large') ) column++;
					else if( el.classList.contains('-type--alfa-mozaic-large-technology') ) column++;
					else if( el.classList.contains('-type--alfa-mozaic-large-left-technology') ) column++;

					if( el.classList.contains('-type--alfa-mozaic-full-width') ) column = 3;

					// this scenario span on two rows
					// so we need to skip this column during next row
					if( el.classList.contains('-type--alfa-mozaic-medium-vertical') ) skipColumn = column;

					// if there is empty column at right, continue on this row
					if( column < 3 ) column++;
					else {
						// otherwise, skip on next row
						column = 1;
						row++;
					}
				});
			};

			const setGridData = (el, column, row) => {
				el.style['-ms-grid-column'] = column;
				el.style['-ms-grid-row'] = row;
			};

		}
	}
})();
