(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaMoparPromotions', fcaMoparPromotions);

	function fcaMoparPromotions() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'moparPromotions',
			controller: FcaMoparPromotionsController,
		};

		function FcaMoparPromotionsController($element, $timeout) {
			'ngInject';

			const $ctrl = this;
			$ctrl.isOpen = false;
			$ctrl.showFiller = false;
			$ctrl.showShopOnline = false;

			$ctrl.$onInit = () => {
				$element.find('.moparPromotions-accordionContent').slideUp();
			};

			$ctrl.$postLink = () => {
				$timeout(() => {
					if ($element.find(".moparPromotion")) {
						let nbrCards = $element.find(".moparPromotion").not(".-filler, .ng-hide").length;
						if (nbrCards % 3 === 1) {
							$ctrl.showFiller = true;
							$ctrl.showShopOnline = true;
						} else if (nbrCards % 3 === 2) {
							$ctrl.showFiller = true;
						}
					}
				});
			};

			$ctrl.toggleAccordion = () => {
				$ctrl.isOpen = !$ctrl.isOpen;

				if ($ctrl.isOpen) {
					$element.find('.moparPromotions-accordionContent').slideDown();
				} else {
					$element.find('.moparPromotions-accordionContent').slideUp();
				}
			};

			$ctrl.printPromotion = () => {
				let noPrintModules = $('#zones_body_blocks_exclusives, #zones_body_blocks_closest-dealer, #zones_footer_blocks_click-n-drive-footer, #common_blocks_family-bar');
				noPrintModules.addClass('no-print');
				if (!$ctrl.isOpen) {
					$ctrl.toggleAccordion();
				}
				$timeout(() => {
					window.print();
				}, 600);
			};
		}
	}
})();
