/* eslint-disable indent */
(function() {
    angular
    .module('fca.sni.compareVehiclePage')
    .component('sniCompareVehiclePage',{
        controller: SniCompareVehicleBar,
        controllerAs: '$ctrl',
        templateUrl: '/sni/compare-vehicle-page/compare-vehicle-page.html',
        bindings: {
            brandCode: '=',
            disclaimers: '<',
            hashData: '=hashData',
            pageIsReady: '=',
            neutralColors: '<',
            sniDetailsUrl: '@',
            userLocation: "<",
            vins: '<',
            affiliateLogoSource: '@',
            maxIrisWidth: '@',
            sniEshopUrl: '@',
            sniEshopActivated: '@'
        }
    });

    function SniCompareVehicleBar($scope, $http, $location, $timeout, fcaSniTrimService, $element, functionExecutionControl, fcaSniCompareDetailsUrl) {
        'ngInject';
        const $ctrl = this;

        $ctrl.language = window.FCA_SITES_CONFIG.language;
        $ctrl.featureClass = 'js-featureWrapper';
        $ctrl.carouselOffset = { transform: 'none' };
        $ctrl.hidePreviousArrow = true;
        $ctrl.hideNextArrow = false;

        $ctrl.$onInit = () => {
            $ctrl.setHash(true);
            $ctrl.callApi();

            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();
            $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

            if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                $('#compare-vehicle-page-container').addClass('is-affiliate');
            }

            window.addEventListener(
                'resize',
                functionExecutionControl.debounce($ctrl.onWindowResize, 250)
            );
        }

        $scope.$watch("$ctrl.vins", function(newValue, oldValue) {
            if(newValue !== oldValue) {
                $ctrl.setHash(true);
            }
        }, true);

        $ctrl.getDetailsUrl = (vehicle) => {
            return fcaSniCompareDetailsUrl.getUrl(vehicle, $ctrl.sniDetailsUrl, $ctrl.sniEshopActivated, $ctrl.sniEshopUrl, sessionStorage.getItem('affiliateId'));
        }

        $ctrl.carouselPrevious = () => {
            $ctrl.hidePreviousArrow = $ctrl.viewport._visibleIndices[0] == 0;
            $ctrl.hideNextArrow = $ctrl.viewport._visibleIndices[2] >= $ctrl.vehicles.length;
        }

        $ctrl.carouselNext = () => {
            $ctrl.hidePreviousArrow = $ctrl.viewport._visibleIndices[0] >= 0?false:true;
            $ctrl.hideNextArrow = $ctrl.viewport._visibleIndices[2] == ($ctrl.vehicles.length - 1);
        }

        $ctrl.setHash = pushHistory => {
            const hash = `mode=compare&vins=${$ctrl.vins.join(',')}`

            if (pushHistory) {
                $location.hash(hash);
            } else {
                history.replaceState(null, null, '#' + hash);
            }
        }

        $ctrl.callApi = () => {
            $ctrl.pageIsReady = false;
            const vins = $ctrl.vins.join(',');

            let apiUrl = (
                `/data/inventories/${$ctrl.brandCode}/compare/${vins}` +
                `?language=${$ctrl.language}`
            );
            if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                apiUrl += getAffiliateIdUrlParam();
            }

            if (sessionStorage.getItem('campaignCode') !== null
                && sessionStorage.getItem('campaignCode') !== 'null') {
                apiUrl += '&campaignCode=' + sessionStorage.getItem(
                    'campaignCode');
            }

            $http.get(apiUrl).then($ctrl.onApiResponse, err => {
                console.error(err);
            });
        }

        $ctrl.onApiResponse = r => {

            $ctrl.vehicles = $ctrl.sortAndDedup(r.data);

            fcaSniTrimService.setMfgCodeForMultipleVehicles($ctrl.vehicles, $ctrl.maxIrisWidth);

            $ctrl.setHash(false);

            $ctrl.viewport = new SniCompareViewport({
                visibleSize: $ctrl.numNameplateColumns(),
                totalSize: $ctrl.vehicles.length
            });

            $ctrl.calcCarouselOffset();
            $ctrl.pageIsReady = true;
			// Currently 'optional equipment' is the first item in the list, change this if we move it somewhere
			$ctrl.optionalEquipmentIndex = 0;

            $ctrl.openCategoryIndex = $ctrl.optionalEquipmentIndex;
        }

		$ctrl.sortAndDedup = (vehicles) => (
			this.vins.map(vin => {
				let vehicle = vehicles.find(vehicle => vehicle.vin === vin);
				if (vehicle && vehicle.categories) {
					vehicle.categories.sort((a, b) => {
						const isOptionalEquipment = (name) => {
							return name.en === "Optional Equipment" || name.fr === "Équipement en option";
						};
						if (isOptionalEquipment(a.name)) return -1;
						if (isOptionalEquipment(b.name)) return 1;
						return 0;
					});
				}
				return vehicle;
			})
		);

        $ctrl.removeVehicleAtIndex = (index) => {
            $ctrl.vins.splice(index, 1);
            $ctrl.vehicles.splice(index, 1);
            $ctrl.viewport.totalSize = $ctrl.vehicles.length;
            $ctrl.calcCarouselOffset();
        }

        $ctrl.nameplateIsBlurred = index => {
            //create a blurred nameplate list
            const list = Array.from($element[0].querySelectorAll('article.-blurred'));
            document.addEventListener('keyup', () => {
                //if blurred element contains focus, remove the blur
                for(let i = 0; i < list.length; i++) {
                    if(list[i].contains(document.activeElement)) {
                        list[i].classList.remove('-blurred');
                    }
                }
            });
            return !$ctrl.viewport.isVisible(index);
        }

        $ctrl.backUrl = () => location.href.replace(location.hash, '#');

        $ctrl.onCategoryToggle = (event, index) => {
            event.stopPropagation();

            if (index === $ctrl.openCategoryIndex) {
                // If the expander is already open, close it.
                $ctrl.openCategoryIndex = -1;
            } else {
                $ctrl.openCategoryIndex = index;
            }

            $ctrl.openCategoryHeight = '';
            $timeout($ctrl.setOpenCategoryHeight);
        }

        $ctrl.setOpenCategoryHeight = () => {
            $ctrl.openCategoryHeight =
                $ctrl.findMaxCategoryHeight($ctrl.openCategoryIndex);
        }

        $ctrl.findMaxCategoryHeight = index => {
            const selector = `.${$ctrl.featureClass}:nth-child(${index + 1})`;

            const categoryWrappers =
                Array.from(document.querySelectorAll(selector));

            const wrapperHeights = categoryWrappers.map(
                elem => elem.offsetHeight
            );

            return Math.max(...wrapperHeights);
        }

        $ctrl.nameplateClick = index => {
            $ctrl.viewport.shiftIntoView(index);
            $ctrl.calcCarouselOffset();

            //recalculate carousel arrows
            if ($ctrl.viewport._totalSize == (index+1)) {
                $ctrl.carouselNext();
            } else if (index === 0) {
                $ctrl.carouselPrevious();
            }
        }

        $ctrl.nameplateSwipeLeft = () => {
            $ctrl.viewport.shiftRight();
            $ctrl.calcCarouselOffset();
        }

        $ctrl.nameplateSwipeRight = () => {
            $ctrl.viewport.shiftLeft();
            $ctrl.calcCarouselOffset();
        }

        $ctrl.calcCarouselOffset = () => {
            const shiftFactor = $ctrl.viewport.shiftFactor();
            const percentage = 100 * shiftFactor / $ctrl.numNameplateColumns();
            $ctrl.carouselOffset.transform = `translateX(${percentage}%)`;
        }

        $ctrl.numNameplateColumns = () => {
            const screenWidth = Math.max(
                document.documentElement.clientWidth, window.innerWidth || 0
            );

            if (screenWidth < 667) {
                return 1;
            } else if (screenWidth < 1100) {
                return 2;
            } else {
                return 3;
            }
        }

        $ctrl.onFakeViewVehicleButtonInit = (vin) => {
            $timeout(() => {
                let fakeButton = $('#C_CVF-fake-detailButton_' + vin);
                if (fakeButton.length > 0) {
                    let leftCurrentOffset = fakeButton.offset().left;
                    fakeButton.offset({top: $('#C_CVF-detailButton_' + vin).offset().top, left: leftCurrentOffset});
                }
            });
        };

        $ctrl.onFakeViewVehicleButtonFocus = (event, vin) => {
            let detailButton = $('#C_CVF-detailButton_' + vin);
            if (detailButton.length > 0) {
                detailButton.addClass('-focus');
            }
        };

        $ctrl.onFakeViewVehicleButtonBlur = (event, vin) => {
            let detailButton = $('#C_CVF-detailButton_' + vin);
            if (detailButton.length > 0) {
                detailButton.removeClass('-focus');
            }
        };

        $ctrl.onWindowResize = () => {
            if ($ctrl.numNameplateColumns() !== $ctrl.viewport.numVisible() &&
                $ctrl.viewport) {

                $timeout(() => {
                    $ctrl.viewport = new SniCompareViewport({
                        visibleSize: $ctrl.numNameplateColumns(),
                        totalSize: $ctrl.vehicles.length
                    });

                    $ctrl.calcCarouselOffset();
                });
            }
        }

        $ctrl.insufficientVehicles = () => !$ctrl.vins || $ctrl.vins.length < 2;

        $ctrl.generateAltTag = (pModelYear, pBrandName, pNamepateName, pTrimGroup) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName + ' ' + pTrimGroup;
            if(window.FCA_SITES_CONFIG.language == 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear + ' ' + pTrimGroup;
            }
            return altText;
        }

        $ctrl.hasFeatures = (features) => {
            return features.length > 0;
        }
    }
})();
