(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name fca.dialog.theme.bodybuilder
	 * @name fca.dialog.theme.bodybuilder
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dialog.theme.bodybuilder')
		.provider('fcaDialogThemeBodybuilder', ThemeProvider);

	function ThemeProvider() {
		'ngInject';

		/* Dialog configurations */
		let cfg = this.cfg = {
			template: '/dialogs/themes/transparency/bodybuilder.html',
			appendClassName: 'fca-dialog-overlay fca-dialog-bodybuilder-overlay',
			closeByDocument: false
		};

		/* Theme name */
		this.name = 'bodybuilder';

		/**
		 * @description Public service
		 * @return {Object} Provider instance
		 */
		this.$get = () => {
			return cfg;
		};
	}
})();
