(function() {
    angular
    .module('fca.brandApp')
    .directive('alfaTestDriveOverlay', alfaTestDriveOverlay);

    function alfaTestDriveOverlay() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: true,
            controllerAs: 'ctrl',
            controller: alfaTestDriveOverlayController
        };

        function alfaTestDriveOverlayController($element, $timeout, gtmAnalytics) {
            'ngInject';
            const $ctrl = this;
            $ctrl.$overlay = $element;
            $ctrl.analyticsIdOpen = '';
            $ctrl.analyticsIdClose = '';

            const disableBodyScroll = bodyScrollLock.disableBodyScroll;
            const enableBodyScroll = bodyScrollLock.enableBodyScroll;

            $ctrl.$onInit = () => {

                $ctrl.localStorageName = $ctrl.$overlay.data('local-storage-name');

                $ctrl.analyticsIdOpen = $ctrl.$overlay.data('analyticsId-open');
                $ctrl.analyticsIdClose = $ctrl.$overlay.data('analyticsId-close');

                // local storage name can be declared in sling
                if ($ctrl.localStorageName === '' || $ctrl.localStorageName === undefined) {
                    $ctrl.localStorageName = 'testDriveDelay';
                }

                const now = new Date().getTime();
                $ctrl.targetElement = $element[0].querySelector(".AR-testDriveOverlay-content");

                const delay = $ctrl.$overlay.data('trigger-time') * 1000;

                if(now >= localStorage.getItem($ctrl.localStorageName)) {
                    $timeout(()=>{
                        $ctrl.modalDialog = $($ctrl.targetElement);

                        $('html').addClass('overlay-active');
                        $ctrl.$overlay.addClass('isActive');
                        $('body').addClass('noScroll');
                        gtmPush($ctrl.analyticsIdOpen);
                        setLocalStorage();
                        if (disableBodyScroll && bodyScrollLock.disableBodyScroll) {
                            disableBodyScroll($ctrl.targetElement);
                        }

                        $timeout(() => {
                            $ctrl.setFocusTrap();
                        }, 100);
                    }, delay);

                    $ctrl.$overlay.on('click', () => {
                        $ctrl.closeOverlay();
                        gtmPush($ctrl.analyticsIdClose);
                    });

                    $ctrl.$overlay.find('.AR-testDriveOverlay-content').on('click', (e) => {
                        e.stopPropagation();
                        $ctrl.setFocusTrap();
                    });

                    $('.js-closeOverlay').on('click', () => {
                        $ctrl.closeOverlay();
                    });
                }

                if (document.cookie.includes('alfaromeoRSVPConfirmation') && $ctrl.localStorageName === 'alfaDealerEventDelay') {
                    $timeout(()=>{
                        $ctrl.closeOverlay();
                    }, delay);
                }
            };

            $ctrl.focusTrap = (e) => {
                if (e.key === 'Tab' || e.code === 'Tab') {
                    if ( e.shiftKey ) {
                        if (document.activeElement === $ctrl.firstFocusableElement) {
                            $ctrl.lastFocusableElement.focus();
                            e.preventDefault();
                        }
                    } else {
                        if (document.activeElement === $ctrl.lastFocusableElement) {
                            $ctrl.firstFocusableElement.focus();
                            e.preventDefault();
                        }
                    }
                } else if(e.key === 'Escape' || e.code === 'Escape') {
                    $ctrl.closeOverlay();
                } else {
                    return;
                }
            };

            $ctrl.setFocusTrap = () => {
                $ctrl.focusableElements = $ctrl.modalDialog.find('a[href]:not([disabled]), a[tabindex]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
                $ctrl.firstFocusableElement = $ctrl.focusableElements[0];
                $ctrl.lastFocusableElement = $ctrl.focusableElements[$ctrl.focusableElements.length - 1];

                $ctrl.firstFocusableElement.focus();

                $ctrl.modalDialog[0].addEventListener('keydown', $ctrl.focusTrap);
            };

            $ctrl.closeOverlay = (shouldCreateDelay) => {
                if ($ctrl.modalDialog) {
                    $ctrl.modalDialog[0].removeEventListener('keydown', $ctrl.focusTrap);
                }
                $('body').removeClass('noScroll');
                $('html').removeClass('overlay-active');
                $ctrl.$overlay.removeClass('isActive');
                if (enableBodyScroll && bodyScrollLock.enableBodyScroll) {
                    enableBodyScroll($ctrl.targetElement);
                }
                setLocalStorage();
            };

            const setLocalStorage = () => {
                const now = new Date();
                const later = now.setDate(now.getDate()+3);
                localStorage.setItem($ctrl.localStorageName, later);
            };

            const gtmPush = (id) => {
                if (id === '' || id === undefined) return;

                gtmAnalytics.trackEvent('event', {
                    category: `App-Suite-vlp`,
                    label: id
                });
            };

        }
    }
})();
