(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaGallerySlider', fcaGallerySlider);

	function fcaGallerySlider() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				sendAnalytics: '<',
				analyticscategory: '@',
				analyticsid: '@'
			},
			controllerAs: '$gallerySlider',
			controller: FcaGallerySliderCtrl
		};

		function FcaGallerySliderCtrl($rootScope, $scope, $element, $timeout, gtmAnalytics, cleanCarousel) {
			'ngInject';

			const $ctrl = this;

			$ctrl.slickSlider = $element.find('.VLP2021-gallery-carrousel');
			$ctrl.sliderInitilaized = false;
			$ctrl.activeSlide = 1;

			let arrowNextClicked = false;
			let arrowPrevClicked = false;

			this.initSlickCarousel = () => {
				$ctrl.slickSlider.slick({
					autoplay: false,
					arrows: true,
					dots: false,
					speed: 500,
					infinite: true,
					swipeToSlide: false,
					touchMove: false,
					draggable: false
				});
				$ctrl.cleanCarousel();
			};

			$ctrl.cleanCarousel = () => {
				cleanCarousel.makeAccessible($ctrl.slickSlider);
			};

			this.updateSliderCount = (currentSlide, nextSlide) => {
				$timeout(() => {
					$ctrl.activeSlide = nextSlide + 1;
				});
			};

			this.trackPano = (slide, nextSlide) => {
				let dataAttr = slide.data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					$ctrl.sendTracking(category, label);
				}
			};

			this.sendTracking = (pCategory, pLabel) => {
				gtmAnalytics.trackEvent('event', {
					category: pCategory,
					label: pLabel
				});
			};

			$ctrl.slickSlider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
				// Manage tabindex for Youtube videos
				$.each( slick.$slides, function(key, value) {
					let iframeRef = $(value).find('iframe');
					if (iframeRef) {
						if (!value.classList.contains('slick-current')) {
							iframeRef.attr('tabindex','-1');
							iframeRef.attr('aria-hidden','true');
						} else {
							iframeRef.attr('tabindex','0');
							iframeRef.attr('aria-hidden','false');
						}
					}
					$(iframeRef).each(function() {
						// youtube iframe's parameter's should have enablejsapi=1
						$(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
					});
				});

				// Since the carrousel loop is infinite, we do the same to slide clones
				let clones = $ctrl.slickSlider.find('.slick-cloned');
				if (clones.length > 0 ) {
					$.each( clones, function(key, value) {
						let iframeRef = $(value).find('iframe');
						if (iframeRef) {
							iframeRef.attr('tabindex','-1');
							iframeRef.attr('aria-hidden','true');
						}
					});
				}

				let slideTitle = $(slick.$slides[currentSlide]).find('.VLP2021-gallery-carrousel-title').text();
				let analyticsValue = slideTitle ? $ctrl.analyticsid + '-' + slideTitle : $ctrl.analyticsid;
				if (arrowNextClicked) {
					$ctrl.sendTracking('App-Suite-' + $ctrl.analyticscategory, analyticsValue + '-forward');
					arrowNextClicked = false;
				} else if (arrowPrevClicked) {
					$ctrl.sendTracking('App-Suite-' + $ctrl.analyticscategory, analyticsValue + '-back');
					arrowPrevClicked = false;
				}
			});
			$ctrl.slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				$ctrl.updateSliderCount(currentSlide, nextSlide);
				let slide = slick.$slides.eq(nextSlide);
				if ($ctrl.sendAnalytics !== false) {
					$ctrl.trackPano(slide, nextSlide);
				}
			});

			$scope.$on('slide-change-to', function (event, data) {
				let newSlide = data.slide;
				$ctrl.slickSlider.slick('slickGoTo', newSlide, true);
				$ctrl.sliderInitilaized = true;
			});

			$ctrl.$onInit = () => {
				$timeout(() => {
					this.initSlickCarousel();
					$rootScope.$broadcast('fca-gallery-slider-initialized');
				});
			};

			$ctrl.$postLink = () => {
				$ctrl.slickSlider.on('init', function () {
					let $arrow = $(this).find('.slick-arrow');
					if ($arrow) {
						$arrow.on('click', function () {
							if (this.classList.contains('slick-next')) {
								arrowNextClicked = true;
							}
							if (this.classList.contains('slick-prev')) {
								arrowPrevClicked = true;
							}
						});
					}
				});
			};

			$ctrl.getSliderCount = () => {
				let count = $ctrl.slickSlider.find('.VLP2021-gallery-carrousel-slick-slide:not(.slick-cloned)').length;
				return count;
			};
        }
	}
})();
