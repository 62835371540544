(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFeatureBlock', fcaFeatureBlock);

	function fcaFeatureBlock() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'featureBlock',
			controller: fcaFeatureBlockController,
			link: fcaSlickCarouselFeatureBlockLink
		};

		function fcaFeatureBlockController($scope, $element, gtmAnalytics, userLocation, $timeout) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			let vm = this;
			if ($element.parent().hasClass('hybrid-feature-container')) {
				const TAB_PROVINCE = ['BC', 'QC'];
				let provIndex = TAB_PROVINCE.indexOf(userLocation.getLocationProvince());
				vm.index = (provIndex >= 0) ? provIndex : TAB_PROVINCE.length;
			} else if ($element.data('starting-slide-index') != undefined) {
				vm.index = $element.data('starting-slide-index');
			} else {
				vm.index = 0;
			}

			vm.$onInit = () => {
				vm.stickySlickDot();
			};

			vm.features = [];

			vm.addFeature = function(feature) {
				vm.features.push(feature);
			};

			vm.expandAll = function() {
				angular.forEach(vm.features, function(feature, index) {
					feature.expanded = true;
				});
				$timeout(function() {
					Waypoint.refreshAll();
				});
			};

			vm.closeAll = function() {
				angular.forEach(vm.features, function(feature, index) {
					feature.expanded = false;
				});
				Waypoint.refreshAll();
			};

			vm.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let $currentSlideElem = $($element[0]).find('.slick-current');
				let $videoElem = $currentSlideElem.find('video').get(0);

				$('video').each(function() {
					$(this).get(0).pause();
				});

				vm.addTracking(event, slick, currentSlide, nextSlide);
			};

			vm.beforeChangeVideo = (event, slick, currentSlide, nextSlide, twinSlider) => {
				let $currentSlideElem = $($element[0]).find('.slick-current');
				let $videoElem = $currentSlideElem.find('video').get(0);
				let $parentSlider = $(twinSlider);

				if ($videoElem) {
					$videoElem.pause();
				}

				vm.addTracking(event, slick, currentSlide, nextSlide);

				$timeout(function() {
					$parentSlider.slick('slickGoTo', nextSlide, false);
				}, 10);
			};

			vm.afterChange = (event, slick, currentSlide, nextSlide) => {
				let $currentSlideElem = $($element[0]).find('.slick-current');
				let $videoElem = $currentSlideElem.find('video').get(0);

				if ($videoElem && $videoElem.paused) {
					$videoElem.play();
				}

			};

			vm.addTracking = (event, slick, currentSlide, nextSlide) => {
				// check not being fired at page load
				if (currentSlide === nextSlide) {
					return;
				}

				let dataAttr = slick.$slides.eq(nextSlide).data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;
					let trigger = '';
					if(dataAttr.analyticsTrigger) {
						trigger = dataAttr.analyticsTrigger;
					}

					if (label) {
						gtmAnalytics.trackEvent('event', {
							category: category,
							label: label,
                            trigger: trigger
						});
					}
				}
			};

			vm.closeOpenTooltips = () => {
				tippy.hideAll();
			};

			vm.stickySlickDot = () => {

				// Element enter viewport trigger sticky dot
				angular.element(document).ready(() => {

					let slickContainer = $($element).parents('.feature-block-container');

					if (slickContainer.length) {
						$timeout(function() {

							let waypointTop = new Waypoint({
								element: $element[0],
								handler: function(direction) {
									if (direction === 'down') {
										vm.addStuck();
									} else {
										vm.removeStuck();
									}
								},
								offset: '90%'
							});

							let waypointBottom = new Waypoint({
								element: slickContainer[0],
								handler: function(direction) {
									if (direction === 'down') {
										vm.removeStuck();
									} else {
										vm.addStuck();
									}
								},
								offset: 'bottom-in-view'
							});

						});
					}
				});

			};

			vm.addStuck = () => {
				let dot = $($element).find('.slick-slider .slick-dots');
				if (dot.length > 0) {
					dot[0].classList.add('hide');
					dot[0].classList.add('stuck');
					dot[0].classList.remove('hide');
				}
			};

			vm.removeStuck = () => {
				let dot = $($element).find('.slick-slider .slick-dots');
				if (dot.length > 0) {
					dot[0].classList.remove('stuck');
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function fcaSlickCarouselFeatureBlockLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
