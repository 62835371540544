(function() {
	angular
		.module('fca.miniNav')
		.directive('fcaMiniNavItem', fcaMiniNavItem);

	function fcaMiniNavItem() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				miniNavGroupId: '@'
			},
			controller: FcaMiniNavItemController
		};

		function FcaMiniNavItemController($rootScope, $window, $element, $timeout, $location, gtmAnalytics, miniNavService) {
			'ngInject';

			// Scroll variables
			let scrollTimeout;
			let lastScrollTop = 0;
			let isSticky = false;
			let scrollToActive = false;
			let classNavSticky = 'main-nav-sticky';
			let classNavDown = 'nav-down';
			let pageLoaded = true;

			/* eslint max-len: ["error", 150] */

			this.setScrollingTimeout = (timeoutVar, timeoutTime) => {
				timeoutVar = $timeout(function() {
					timeoutVar = null;
				}, timeoutTime);
			};

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					if (!scrollTimeout && !scrollToActive) {
						this.setScrollingTimeout(scrollTimeout, 50);

						if ($(window).scrollTop() > 1500) {
							if(pageLoaded) {
								pageLoaded = false;
							} else {
								$('html').addClass(classNavSticky);
								this.hasScrolled();
							}
						} else {
							$('html').removeClass(classNavSticky);
							$('html').removeClass(classNavDown);
						}
					}
				});
			};

			this.hasScrolled = () => {
				let distScrolled = $(window).scrollTop();

				if (!isSticky) {
					$('html').addClass(classNavSticky);
				}

				if (distScrolled < lastScrollTop) {
					$('html').addClass(classNavDown);
					isSticky = true;
				}

				if (isSticky && distScrolled > lastScrollTop) {
					$('html').removeClass(classNavDown);
					isSticky = false;
				}

				lastScrollTop = distScrolled;
			};

			this.$onChanges = (changes) => {
				if (changes.miniNavGroupId && changes.miniNavGroupId.currentValue) {
					const activeClass = 'is-active';
					let highlightApplied = false;

					miniNavService.itemCollection.push(changes.miniNavGroupId.currentValue);

					// Listen for each section enetering the page (note: backend returns multiple divs with same id)
					$rootScope.$on('fcaWaypoint.reached', (event, data) => {
						let eventFromValidGroup = miniNavService.itemCollection.indexOf(data.groupId) >= 0;
						if (!eventFromValidGroup) {
							return;
						}

						if (data.groupId === this.miniNavGroupId) {
							/* Highlight link */
							$element.addClass(activeClass);
							highlightApplied = true;

							/* Handle analytics tracking */
							if (!$element[0].dataset.viewed) {
								miniNavService.setActiveItem(this.miniNavGroupId);

								// Warning: Set timeout prevents the same groupId from being tracked multiple times
								// also prevents false tracking of virtual views when doing a scrollTo
								$timeout(() => {
									let activeItem = miniNavService.getActiveItem();

									if (activeItem === this.miniNavGroupId && !$element[0].dataset.viewed) {
										// tag the groupId so it does not get tracked multiple times (backend returns multiple divs with same id)
										$element[0].dataset.viewed = true;
										trackVirtualPageView(this.miniNavGroupId);
									}
								}, 1000);
							}
						} else {
							if (highlightApplied) {
								$element.removeClass(activeClass);
								highlightApplied = false;

								// removes highlight in case element was still active (in the event there's a scroll up right after the link click)
								$element.blur();
							}
						}
					});
				}
			};

			function trackVirtualPageView(label) {
				gtmAnalytics.trackEvent('event', {
					category: 'vlp section nav',
					label: label
				});
			}

			this.$onInit = () => {
				if ($('[data-fca-sticky]').length) {
					this.bindScrolling();
				}
			};
		}
	}
})();
