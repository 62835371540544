(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaGallery', fcaGallery);

	function fcaGallery() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'galleryCtrl',
			controller: alfaGalleryController
		};

		function alfaGalleryController($rootScope, gtmAnalytics) {
			'ngInject';

			this.isModalOpen = false;
			this.currentIndex = 0;


			$rootScope.$on('onModalClose', () => {
				this.isModalOpen = false;
			})

			this.onImageClick = index => {
				let imageNumber = index + 1;
				let analyticsLabel = 'slide # ' + imageNumber;
				this.currentIndex = parseInt(index, 10);
				this.isModalOpen = !this.isModalOpen;

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label: 'vlp-gallery-expand-' + analyticsLabel
				});
			}
		}
	}
})();
