(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaLineupModule', fcaLineupModule);

	function fcaLineupModule() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'lineupModule',
			controller: fcaLineupModuleController,
		};

		function fcaLineupModuleController($scope, $element, gtmAnalytics, $timeout, $window) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			let $ctrl = this;
			let $carousel = $(".lineup-carousel");

			let arrowNextClicked = false;
			let arrowPrevClicked = false;
			let dotClicked = false;
			let trimClicked = false;

			let firstCarouselInit = 0;

			let rtime;
			let timeout = false;
			let delta = 200;
			//Prevent resize event to trigger multiple times. Source: https://forum.jquery.com/topic/the-resizeend-event
			$(window).resize(function() {
				rtime = new Date();
				if (timeout === false) {
					timeout = true;
					setTimeout(resizeend, delta);
				}
			});

			$carousel.on('init', function(event, slick) {
				let $dots = slick.$dots.find('li');
				let $arrow = $element.find('.slick-arrow');
				let $slides = $element.find('.slick-slide');
				let $slidesContentVideo = $('.slider-lineup').find('.video');

				$slides.on('click',function () {
					trimClicked = true;
				});

				$dots.on('click', function () {
					dotClicked = true;
				});

				$arrow.on('click',function () {
					if (this.classList.contains('slick-next')) {
						arrowNextClicked = true;
					}
					if (this.classList.contains('slick-prev')) {
						arrowPrevClicked = true;
					}
				});

				$slidesContentVideo.on('click',function () {
					if (!this.classList.contains('loaded')) {
						let currentSlideIndex = $carousel.slick('slickCurrentSlide');
						let analyticsId = $(slick.$slides[currentSlideIndex]).find('.model-trim-name').text();
						$ctrl.sendGtmTrackEvent("lineup-video-play-", analyticsId);
					}
				});
			});

			$carousel.on('afterChange', function(event, slick, currentSlide, nextSlide) {
				let currentSlideIndex = $carousel.slick('slickCurrentSlide');
				let analyticsId = $(slick.$slides[currentSlideIndex]).find('.model-trim-name').text();

				firstCarouselInit ++;

				if ( firstCarouselInit > 1) {
					switch (true) {
						case arrowNextClicked:
							arrowNextClicked = false;
							$ctrl.sendGtmTrackEvent('lineup-forward-', analyticsId);
							break;
						case arrowPrevClicked:
							arrowPrevClicked = false;
							$ctrl.sendGtmTrackEvent('lineup-backward-', analyticsId);
							break;
						case dotClicked:
							dotClicked = false;
							$ctrl.sendGtmTrackEvent("lineup-dots-", analyticsId);
							break;
						case trimClicked:
							trimClicked = false;
							$ctrl.sendGtmTrackEvent("lineup-trimclick-", analyticsId);
							break;
						default:
							$ctrl.sendGtmTrackEvent('lineup-swipe-', analyticsId);
							break;
					}
				}

			});

			$ctrl.sendGtmTrackEvent = (id, value) => {
				gtmAnalytics.trackEvent('event', {
					category: 'vlp lineup',
					label: `${id}-${value.toLowerCase()}`
				});
			};

			function resizeend() {
				if (new Date() - rtime < delta) {
					setTimeout(resizeend, delta);
				} else {
					timeout = false;
					firstCarouselInit = 0;
					$carousel.find('.slick-dots li').on('click', function(e) {
						dotClicked = true;
					});
				}
			}

		}
	}
})();
