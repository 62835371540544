/* eslint-disable indent */
(function () {
    angular
    .module('fca.buildAndPrice.paymentSummary')
    .component('buildAndPricePaymentSummary', {
        controller: BuildAndPricePaymentSummary,
        controllerAs: '$ctrl',
        templateUrl: '/build-and-price/payment-summary/payment-summary.html',
        bindings: {
            baseUrl: '@',
            nameplateName: '@',
            trimName: '@',
            disclaimerNetAmount: '@',
            disclaimerFinance: '@',
            disclaimerLease: '@',
            mediaPath: '@',
            iframeSrc: '@',
            videoTitle: '@',
            videoDescription: '@',
            videoDisclaimer: '@',
            nameplate: '@',
            modelYear: '@'
        }
    });

    function BuildAndPricePaymentSummary($scope, $sce, $rootScope, $location, $filter, trimService, configService, $timeout,
        $translate, userAccountService, buildAndPriceStepsService) {

        'ngInject';

        let $ctrl = this;

        // Package code is unknown at boot time,
        // A trim and package will initally be sent by the package-selector
        $ctrl.selectedTrim = {};
        $ctrl.selectedPackage = {};
        $ctrl.trimDictionary = {};
        $ctrl.totalDiscount = "";
        $ctrl.netAmount = ""; // net amount is in fact the cash net amount (only cash discounts are applied)
        $ctrl.leasePerWeek = "";
        $ctrl.leaseInterestRate = "";
        $ctrl.leaseDuration = "";
        $ctrl.financePerWeek = "";
        $ctrl.financeInterestRate = "";
        $ctrl.financeDuration = "";
        $ctrl.onStepSummary = false;
		$ctrl.language = FCA_SITES_CONFIG.language;
		$ctrl.currentStep = "summary";
        $ctrl.language = $('html').attr('lang');
        
        $ctrl.saveActionLabel = {
            'en': 'Press enter to save the build.',
            'fr': 'Appuyez sur Entrée pour enregister la construction.'
        };
        $ctrl.unsaveActionLabel = {
            'en': 'Press enter to remove from the saved builds.',
            'fr': 'Appuyez sur Entrée pour retirer des constructions enregistrées.'
        };

        $ctrl.trimGroupSelectorUrl = $location.url().substr(0, $location.url().lastIndexOf('/'));

        $ctrl.monroney = 0;
        $ctrl.adminFee = 0;

        $ctrl.$onInit = () => {

            // we need to know when the step changes
            buildAndPriceStepsService.registerStepListener($ctrl.buildAndPriceStepChanged);

            $ctrl.hashParameters = getHashParameters();
            $ctrl.paymentType = $ctrl.hashParameters.activeTab ? $ctrl.hashParameters.activeTab : 'cash';
            $ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';
            $ctrl.modelYearId = configService.modelYearId;
            $ctrl.nameplateCode = configService.nameplateCode;
            $ctrl.year = configService.year;
            $ctrl.brandCode = configService.brandCode;
            $ctrl.acode = configService.acode;
            $ctrl.provinceCode = configService.provinceCode;
            $ctrl.youtubeSrc = $sce.trustAsResourceUrl($ctrl.iframeSrc);            

            if ($ctrl.hashParameters.step === 'summary') {
                $ctrl.addAnalyticsIframe();
            }            

            // Once a trim has been determined, parse the data to show detailed information
            // rather than just codes
            $scope.$on('packages-selector:selected-trim-updated', (event, data) => {
                // Update both the trim and the package to change the displayed information
                $ctrl.selectedTrim = data.selectedTrim;
                $ctrl.selectedPackage = data.selectedTrim.packages[0];

                configService.saveTrimGroupSlingCode(data.selectedTrimGroup.slingCode);
            });

            $scope.$on('scratch-save-updated:scratch-save', (event, data) => {
                $ctrl.scratchSave = data.scratch;
            });

            $rootScope.$on('navigation: active-step-change', (event, data) => {
                if (data.step === 'summary') {
                    $ctrl.onStepSummary = true;

                    $ctrl.addAnalyticsIframe();
                }
            });

            $rootScope.$on('navigation: payment-type-changed', (_, data) => {
                $ctrl.paymentType = data.type;
            });

            $scope.$on('packages-selector:selected-package-updated', (event, data) => {
                // Update the package to change the displayed information
                $ctrl.selectedPackage = data.package;
            });

            /**
             * The event is called packages-selector but it's not accurate.
             * This is called on every option selection.
             */
            $scope.$on('packages-selector:pricing', (_, data) => {
                // keep the monroney
                $ctrl.monroney = parseInt(data.pricing.monroney.msrp);

                // admin fees are the dealer charge added when the build and price is embedded has a frame in a dealer site
                if (data.pricing.adminFee) {
                    $ctrl.adminFee = parseFloat(data.pricing.adminFee);
                } else {
                    $ctrl.adminFee = 0;
                }

                // update the net amount
                $ctrl.netAmount = $ctrl.getCashNetAmount();

                // Need to keep min finance and lease of 'Cash' incentives in case of save the build in user-account
                if (data.pricing) {
                    if(data.pricing.finance) {
                        $ctrl.minFinanceTermObj = {
                            duration: data.pricing.finance.term,
                            rate: data.pricing.finance.rate
                        };
                    }

                    if(data.pricing.lease) {
                        $ctrl.minLeaseTermObj = {
                            duration: data.pricing.lease.term,
                            rate: data.pricing.lease.rate,
                            residual: data.pricing.lease.residual
                        };
                    }
                }
            });

            $scope.$on('calculator:update-payment', (_, data) => {
                $ctrl.leasePerWeek = data.leasePayment;
                $ctrl.leaseInterestRate = $filter('number')(data.leaseRate, 2);
                $ctrl.totalDiscount = data.totalDiscount;
                $ctrl.leaseDuration = $filter('number')(data.leaseTerm, 0);
                $ctrl.financePerWeek = $filter('fcaCurrencyWithDollarSign')(data.financePayment, 0);
                $ctrl.financeInterestRate = $filter('number')(data.financeRate, 2);
                $ctrl.financeDuration = $filter('number')(data.financeTerm, 0);
                $ctrl.frequency = data.frequency;
                $ctrl.discounts = data.discounts;
                $ctrl.hasHybridIncentives = data.hasHybridIncentives;
                $ctrl.disclaimer = data.disclaimer;

                if ($ctrl.leasePerWeek) {
                    $ctrl.hasLease = true;
                    if (data.currentRates.lease) {
                        if (parseFloat(data.currentRates.lease.eir) == 0) {
                            $ctrl.leaseEirRate = 0;
                        } else {
                            $ctrl.leaseEirRate = $filter('number')(data.currentRates.lease.eir, 2);
                        }
                        if (parseFloat(data.currentRates.lease.apr) == 0) {
                            $ctrl.leaseAprRate = 0;
                        } else {
                            $ctrl.leaseAprRate = $filter('number')(data.currentRates.lease.apr, 2);
                        }
                    }
                }
                if (data.currentRates.finance) {
                    if (parseFloat(data.currentRates.finance.eir) == 0) {
                        $ctrl.financeEirRate = 0;
                    } else {
                        $ctrl.financeEirRate = $filter('number')(data.currentRates.finance.eir, 2);
                    }
                    if (parseFloat(data.currentRates.finance.apr) == 0) {
                        $ctrl.financeAprRate = 0;
                    } else {
                        $ctrl.financeAprRate = $filter('number')(data.currentRates.finance.apr, 2);
                    }
                }
                $ctrl.useBestRate = data.useBestRate;
                $ctrl.hasAltFinance = data.hasAltFinance;
                $ctrl.hasAltLease = data.hasAltLease;
                $ctrl.hasLease = data.hasLease;

                // update the cash net amount (in the case the discounts are now different)
                $ctrl.netAmount = data.cashNetAmount;

                $ctrl.changePaymentTypeFromUpdate();
            });

        };

        /**
         * cash net amount is monroney - discount total + admin fee
         */
        $ctrl.getCashNetAmount = () => {
            let result = 0;
            try {
                result = $ctrl.monroney - $ctrl.getDiscountTotal('cash') + $ctrl.adminFee;
            } catch (error) {
            } finally {
                // if the result is not over zero, we don't want anyone to see it
                if (!result > 0) {
                    result = '';
                }
            }
            return result;
        };

        $ctrl.changePaymentTypeFromUpdate = () => {
            // Return label for the pricing dropdown in the payment-summary
            // The return key is translated in the html file
            // Fallback and Broadcast through the rootscope a payment type accurate
            if ($ctrl.paymentType === 'cash') {
                $ctrl.dropdownLabel = 'build-and-price.director.cash';

            } else if ($ctrl.paymentType === 'finance' || $ctrl.paymentType === 'altFinance') {
                if ($ctrl.hasAltFinance) {
                    $ctrl.dropdownLabel = $ctrl.useBestRate ? 'build-and-price.common.finance-best-rate' : 'build-and-price.common.finance-best-payment';
                } else {
                    $ctrl.dropdownLabel = 'build-and-price.director.finance';
                }
            } else if ($ctrl.paymentType === 'lease') {
                $ctrl.dropdownLabel = 'build-and-price.director.lease';
            }
        };

        $ctrl.getDrivetrainDescription = (code) => {
            return trimService.getDrivetrainDescription(code);
        };

        $ctrl.getBoxLengthDescription = (code) => {
            return trimService.getBoxLengthDescription(code);
        };

        $ctrl.getCabTypeDescription = (code) => {
            return trimService.getCabTypeDescription(code);
        };

        $ctrl.getRearWheelDescription = (code) => {
            return trimService.getRearWheelDescription(code);
        };

        $ctrl.getWheelbaseDescription = (code) => {
            return trimService.getWheelbaseDescription(code);
        };

        // These functions are needed because in english,
        // the box lengths and the wheelbase have unescaped double quotes for the inches
        // This breaks when translating in the HTML template
        $ctrl.translateBoxLength = code => {
            let translated = $ctrl.getBoxLengthDescription(code);
            return $translate.instant(
                'build-and-price.director.box-length',
                { 'boxLength' : translated }
            )
        };

        $ctrl.translateWheelbase = code => {
            let translated = $ctrl.getWheelbaseDescription(code);
            return $translate.instant(
                'build-and-price.director.wheel-base',
                { 'wheelBase' : translated }
            )
        };

        $ctrl.isSaved = () => {
            return configService.builtVehicleSaved !== null;
        };

        $ctrl.getSaveActionLabel = () => {
            return configService.builtVehicleSaved !== null ? $ctrl.unsaveActionLabel[$ctrl.language]: $ctrl.saveActionLabel[$ctrl.language];
        };

        // TODO RM: same functoin in director.component.js so put this function in userAccountService!!!
        $ctrl.saveYourBuild = (event) => {
            if (userAccountService.isLibraryAvailable()) {
                let pricingInfo = {
                    netAmount: $ctrl.netAmount
                };
                if($ctrl.minFinanceTermObj) {
                    pricingInfo["financeTerm"] = $ctrl.minFinanceTermObj.duration;
                    pricingInfo["financeRate"] = $ctrl.minFinanceTermObj.rate;
                }
                if($ctrl.minLeaseTermObj) {
                    pricingInfo["leaseTerm"] = $ctrl.minLeaseTermObj.duration;
                    pricingInfo["leaseRate"] = $ctrl.minLeaseTermObj.rate;
                    pricingInfo["residualRate"] = $ctrl.minLeaseTermObj.residual;
                }
                if (UA.isUserLoggedIn()) {
                    configService.saveConfiguration($scope, pricingInfo);
                } else {
                    $rootScope.configurationToSave = pricingInfo;
                }
            }
        };

        $ctrl.changePaymentType = paymentType => {
            $rootScope.$broadcast('navigation: payment-type-changed', {
                'type': paymentType,
            });
        };

        $ctrl.getDiscountTotal = (paymentType) => {
            let totalDiscount = 0;
            let discounts = $ctrl.discounts;

            if (discounts) {
                switch (paymentType) {
                    case 'cash':
                        if(discounts.cash && discounts.cash.beforeTax) {
                            totalDiscount += discounts.cash.beforeTax;
                        }
                        if(discounts.cash && discounts.cash.afterTax) {
                            totalDiscount += discounts.cash.afterTax;
                        }
                        return totalDiscount;
                    case 'finance':
                        if(discounts.finance && discounts.finance.beforeTax) {
                            totalDiscount += discounts.finance.beforeTax;
                        }
                        if(discounts.finance && discounts.finance.afterTax) {
                            totalDiscount += discounts.finance.afterTax;
                        }
                        return totalDiscount;
                    case 'lease':
                        if(discounts.lease && discounts.lease.beforeTax) {
                            totalDiscount += discounts.lease.beforeTax;
                        }
                        if(discounts.lease && discounts.lease.afterTax) {
                            totalDiscount += discounts.lease.afterTax;
                        }
                        return totalDiscount;
                }
            }
            return totalDiscount;
        };

        $ctrl.hasDiscount = paymentType => {
            return $ctrl.discounts && $ctrl.discounts[paymentType] && $ctrl.discounts[paymentType].hasDiscount;
        };

        $ctrl.getDisclaimer = paymentType => {
            return $ctrl.disclaimer[paymentType];
        };

        $ctrl.hasDisclaimer = paymentType => {
            return $ctrl.disclaimer && $ctrl.disclaimer[paymentType];
        };

        /**
         * Trigger the analytics iframe as soon as the step changes to summary
         * @param stepChanged
         */
        $ctrl.buildAndPriceStepChanged = (stepChanged) => {
            // we only care when the step is the summary
            if (stepChanged && stepChanged.step === 'summary') {
                $ctrl.addAnalyticsIframe();
            }
        };        

        /**
         * The following script must be added as the first element in the body tag
         * when you click on the payment summary or you fall on the payment summary.
         * The Iframe is added for analytics purposes
         * Must be added only on Ramtruck site.
         */
        $ctrl.addAnalyticsIframe = () => {
            if(configService.brandCode === 'ramtruck') {
                // tagging
                var axel = Math.random() + "";
                var a = axel * 10000000000000;

                var iframeSrc =
                    'https://9151942.fls.doubleclick.net/activityi;src=9151942;type=retar0;cat=dynam0;' +
                    'u1=' + configService.brandCode +
                    ';u2=' + configService.nameplateCode +
                    ';u11=' + configService.paintCode +
                    ';u12=' + configService.acode +
                    ';u13=' + configService.year +
                    ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a +
                    '?';

                if ($("#analytics-dynamic-targeting").length) {
                    $("#analytics-dynamic-targeting").attr('src', iframeSrc);
                } else {
                    $('body').prepend(
                        '<iframe id="analytics-dynamic-targeting" ' +
                        'src="' + iframeSrc + '" ' +
                        'width="1" height="1" frameborder="0" style="display:none">' +
                        '</iframe>');
                }
            }


            if (typeof Mitaa != 'undefined') {
                // Mitaa script call integration
                var nameplateCode = configService.nameplateCode;
                var brandCode = configService.brandCode;
                var year = configService.year;

                var mitaaTriggerId = '';

                if (year === '2020') {
                    if (brandCode === 'ramtruck' && nameplateCode === '1500dt') {
                        mitaaTriggerId = "125c362982de4876";
                    } else if (brandCode === 'jeep'
                        && (nameplateCode === 'grandcherokee' || nameplateCode === 'wranglerjl')) {
                        mitaaTriggerId = "1ab75eebff6b4c01";
                    }
                }

                if (mitaaTriggerId !== '') {
                    console.debug('Executing Mitaa Trigger : ', mitaaTriggerId);
                    Mitaa(mitaaTriggerId);
                }

            }

        };

    }
})();
