(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSocialSharing', fcaSocialSharing);

	function fcaSocialSharing() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				brand: '@',
				nameplate: '@'
			},
			controllerAs: 'socialSharing',
			controller: FcaSocialSharingController,
		};

		function FcaSocialSharingController($element, $window, $translate) {
			'ngInject';

			this.shareExpanded = false;

			this.toggleSocialSharing = () => {
				const expandButton = $element.find(".socialSharing-expandButton");
				const content = $element.find(".socialSharing-content");
				let maxWidth = "25rem";

				if (this.shareExpanded) {
					expandButton.removeClass('active');
					expandButton.css("max-width", maxWidth);
					expandButton.css("visibility", "visible");
					content.css("max-width", "0");
					content.css("visibility", "hidden");
				} else {
					expandButton.addClass('active');
					expandButton.css("max-width", "0");
					expandButton.css("visibility", "hidden");
					content.css("max-width", maxWidth);
					content.css("visibility", "visible");
				}
				this.shareExpanded = !this.shareExpanded;
			};

			this.facebookShare = () => {
				let facebookLabel = $translate.instant('offer.nameplate-listing.facebook-share', { brand: this.brand, nameplate: this.nameplate } );
				let sharingUrl = location.origin + $('[data-facebook-sharing-url]').attr( 'data-facebook-sharing-url' );
				let shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent( sharingUrl )}&quote=${encodeURIComponent( facebookLabel )}`;
				$window.open(shareLink,'_blank',"height=300,width=495");
			}
		}
	}
})();
