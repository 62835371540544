(function() {
    angular
        .module('fca.userSignIn')
        .component('userSignInPopup', {
            controller: FcaUserSignInPopupController,
            controllerAs: '$ctrlSignInPopup',
            templateUrl: '/user-sign-in/sign-in-popup/sign-in-popup.html',
            bindings: {
                arrow: '@',
                unsubscribe: '<?',
                analyticsCategory: '@',
                analyticsId: '@',
                analyticsValue: '@',
                componentName: '@'
            }
        });

    function FcaUserSignInPopupController($rootScope, $element, $timeout, userAccountService) {
        'ngInject';

        let $ctrlSignInPopup = this;
        $ctrlSignInPopup.siteCode = FCA_SITES_CONFIG.pageCode;

        $ctrlSignInPopup.$postLink = () => {

            $ctrlSignInPopup.googleSignin = () => {
                if (userAccountService.isLibraryAvailable()) {
                    UA.googleSignin().then((response) => {
                        onSigninResponse(response);
                    });
                }
            };

            $ctrlSignInPopup.facebookSignin = () => {
                if (userAccountService.isLibraryAvailable()) {
                    UA.facebookSignin().then((response) => {
                        onSigninResponse(response);
                    });
                }
            };

            $ctrlSignInPopup.onSigninSuccess = () => {
                $('.navigation-group-item-ua-anonymous').hide();
                $('.navigation-group-item-ua-signed-in').show();
                $rootScope.$broadcast('ua:userSignInSuccess');
            };

            let onSigninResponse = (response) => {
                if (response.status === 'FAIL') {
                    $rootScope.$broadcast('user-account: login-fail', {message: response.message});
                } else {
                    $ctrlSignInPopup.onSigninSuccess();
                }
            };
        }
    }
})();

