(function() {
    'use strict';

    /**
     * @ngdoc overview
     * @name fca.closestDealerItem
     * @description Module for dealer item in list
     * @example
     * <pre>[TODO]</pre>
     */
    angular
        .module('fca.closestDealerItem', []);
})();
