/* eslint-disable indent */
(function(ng) {
	'use strict';

	angular
		.module('fca.gmap')
		.service('fcaGmap', GMapService);
	/**
	 * @ngdoc service
	 * @name fcaGmap
	 * @module fca.gmap
	 * @description
	 * Provide method to calculate distance and radius on map
	 */
	function GMapService() {
		'ngInject';

		/**
		 * @ngdoc method
		 * @name getMapRadius
		 * @methodOf  fca.gmap.service:fcaGmap
		 * @param  {Object} m      Google map object instance
		 * @param  {Object} google Google maps lib
		 * @return {Float}         Radius distance between center and top north east map
		 */
		this.getMapRadius = (m, google, minRadius) => {
			let bounds = m.getBounds();
			let northEast = bounds.getNorthEast();
			let center = bounds.getCenter();
			let dist = this.getDistanceBetween(center.toJSON().lat, center.toJSON().lng, center.toJSON().lat, northEast.toJSON().lng, google);

			let $container = ng.element(m.getDiv());
			let width = $container.width() / 2;
			let height = $container.height() / 2;
			let diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
			let ratio = width / height;
			let unit = dist / diagonal;
			let ref = (ratio >= 1) ? width : height;
			let radius = Math.floor(ref * unit);
			if (minRadius && radius < minRadius) {
				radius = minRadius;
			}
			return radius;
		};

		/**
		 * @ngdoc method
		 * @name getDistanceBetween
		 * @methodOf  fca.gmap.service:fcaGmap
		 * @param  {Object} p      First point
		 * @param  {Object} b      Second point
		 * @param  {Object} google Google maps lib
		 * @return {Float}         Distance on kilometers between the 2 points
		 */
		this.getDistanceBetween = (lat1, lng1, lat2, lng2, google) => {
			let gLatLngP = new google.maps.LatLng(lat1, lng1);
			let gLatLngB = new google.maps.LatLng(lat2, lng2);
			let dist = google.maps.geometry.spherical.computeDistanceBetween(gLatLngP, gLatLngB);
			dist /= 1000;
			return dist;
		};
	}
})(angular);
