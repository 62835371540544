(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaBuyOnlineMultiSelect', fcaBuyOnlineMultiSelect);

	function fcaBuyOnlineMultiSelect() {
		return {
			restrict: 'A',
			controller: fcaBuyOnlineMultiSelectController,
			controllerAs: 'fcaBuyOnlineMultiSelect',
			scope: true
		};

		function fcaBuyOnlineMultiSelectController() {
			'ngInject';

			const $ctrl = this;

			$ctrl.$onInit = () => {

				// Multi Select plugin -> https://codepen.io/rdmchenry/pen/OyzVEx
				const DataStatePropertyName = 'multiselect';
				const EventNamespace = '.multiselect';
				const PluginName = 'MultiSelect';

				var old = $.fn[PluginName];
				$.fn[PluginName] = plugin;
				$.fn[PluginName].Constructor = MultiSelect;
				$.fn[PluginName].noConflict = function () {
					$.fn[PluginName] = old;
					return this;
				};

				// Defaults
				$.fn[PluginName].defaults = {};

				// Static members
				$.fn[PluginName].EventNamespace = function () {
					return EventNamespace.replace(/^\./ig, '');
				};

				function plugin(option) {
					this.each(function () {
						var $target = $(this);
						var multiSelect = $target.data(DataStatePropertyName);
						var options = typeof option === typeof {} && option || {};

						if (!multiSelect) {
							$target.data(DataStatePropertyName, multiSelect = new MultiSelect(this, options));
						}

						if (typeof option === typeof "") {
							if (!(option in multiSelect)) {
								console.log("MultiSelect does not contain a method named '" + option + "'");
							}
							return multiSelect[option]();
						}
					});
				}

				function MultiSelect(element, options) {
					this.$element = $(element);
					this.options = $.extend({}, $.fn[PluginName].defaults, options);
					this.destroyFns = [];

					this.$toggle = this.$element.children('.toggle');
					this.$toggle.attr('id', this.$element.attr('id') + 'multi-select-label');
					this.$backdrop = null;

					init.apply(this);
				}

				MultiSelect.prototype.open = open;
				MultiSelect.prototype.close = close;

				function init() {
					this.$element.
					addClass('multi-select').
					attr('tabindex', 0);

					initAria.apply(this);
					initEvents.apply(this);
					updateLabel.apply(this);

					this.destroyFns.push(function () {
						return '|';
					});
				}

				function initAria() {
					this.$element.
					attr('role', 'combobox').
					attr('aria-expanded', false).
					attr('aria-haspopup', 'listbox').
					attr('aria-labelledby', this.$element.attr("aria-labelledby") + " " + this.$toggle.attr('id'));

					this.$toggle.
					attr('aria-label', '');
				}

				function initEvents() {
					var that = this;
					this.$element.
					on('click', function ($event) {
						if ($event.target !== that.$toggle[0] && !that.$toggle.has($event.target).length) {
							return;
						}

						if ($(this).hasClass('in')) {
							that.close();
						} else {
							that.open();
						}
					}).
					on('keydown', function ($event) {
						var next = false;
						switch ($event.keyCode) {
							case 32: // space
								if ($event.target !== that.$element[0]) {
									return;
								}
								$event.preventDefault();
								if ($(this).hasClass('in')) {
									that.close();
								} else {
									that.open();
								}
								break;
							case 9:
								if ($event.target !== that.$element[0]) {
									$event.preventDefault();
								}
							case 27:
								that.close();
								break;
							case 40:
								next = true;
							case 38:
								$event.preventDefault();
								var $items = $(this).
								children("ul:first").
								find(":input, button, a");

								var foundAt = $.inArray(document.activeElement, $items);
								if (next && ++foundAt === $items.length) {
									foundAt = 0;
								} else if (!next && --foundAt < 0) {
									foundAt = $items.length - 1;
								}

								$($items[foundAt]).
								trigger('focus');
						}

					}).
					on('focus', 'a, button, :input', function () {
						$(this).
						parents('li:last').
						addClass('focused');
					}).
					on('blur', 'a, button, :input', function () {
						$(this).
						parents('li:last').
						removeClass('focused');
					}).
					on('change', ':checkbox', function () {

						$(this).
						parents('li:last').
						toggleClass('selected', $(this).prop('checked'));

						var checkboxes = that.$element.
						find(":checkbox").
						filter(":checked");

						updateLabel.apply(that);
					}).
					on('mouseover', 'ul', function () {
						$(this).
						children(".focused").
						removeClass("focused");
					});
				}

				function updateLabel() {
					var pluralize = function (wordSingular, count) {
						if (count !== 1) {
							switch (true) {
								case /y$/.test(wordSingular):
									wordSingular = wordSingular.replace(/y$/, "ies");
								default:
									wordSingular = wordSingular + "s";
							}

						}
						return wordSingular;
					};

					var $checkboxes = this.$element.
					find('ul :checkbox');

					var allCount = $checkboxes.length;
					var checkedCount = $checkboxes.filter(":checked").length;
					var label = checkedCount + " " + pluralize("upgrade", checkedCount) + " selected";

					this.$toggle.
					children("label").
					text(checkedCount ? checkedCount === allCount ? '(all)' : label : 'Select an upgrade');

					this.$element.
					children('ul').
					attr("aria-label", label + " of " + allCount + " " + pluralize("upgrade", allCount));
				}

				function ensureFocus() {
					this.$element.
					children("ul:first").
					find(":input, button, a").
					first().
					trigger('focus').
					end().
					end().
					find(":checked").
					first().
					trigger('focus');
				}

				function addBackdrop() {
					if (this.$backdrop) {
						return;
					}

					var that = this;
					this.$backdrop = $("<div class='multi-select-backdrop'/>");
					this.$element.append(this.$backdrop);

					this.$backdrop.
					on('click', function () {
						$(this).
						off('click').
						remove();

						that.$backdrop = null;
						that.close();
					});
				}

				function open() {
					if (this.$element.hasClass('in')) {
						return;
					}

					this.$element.
					addClass('in');

					this.$element.
					attr('aria-expanded', true);

					addBackdrop.apply(this);
					//ensureFocus.apply(this);
				}

				function close() {
					this.$element.
					removeClass('in').
					trigger('focus');

					this.$element.
					attr('aria-expanded', false);

					if (this.$backdrop) {
						this.$backdrop.trigger('click');
					}
				}

				$(document).ready(function () {
					$('#multi-select-plugin').
					MultiSelect();
				});
			};
		}
	}
})();
