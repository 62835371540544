(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaAnimation', AlfaAnimation);

	function AlfaAnimation(povHelper) {
		const $service = this;

		$service.createCategoryZones = (ids, height, offset) => (
			ids.map(elemId => new AnimationZone({
				height,
				offset,
				animationInfo: {
					elemId,
					top: { opacity: 0 },
					bottom: { opacity: 1 },
				},
				animationEdge: 'bottom',
			}))
		);

		$service.createJellyZones = (states, height, offset) => {
			const zones = [];

			for (let i = 1; i < states.length; i++) {
				const fromState = states[i - 1];
				const toState = states[i];

				const zone = new AnimationZone({
					height,
					offset,
					animationInfo: {
						elemId: toState.elemId,
						top: fromState.properties,
						bottom: toState.properties
					}
				});

				zone.addPovHelper(povHelper);
				zones.push(zone);
			}

			return zones;
		}

		$service.findActiveAnimationZone = (animationZones, previousScrollY) => {
			const scrollingDown = pageYOffset > previousScrollY;

			// When scrolling super fast, sometimes multiple animation zones are skipped over. In this case, we want to
			// return the *last* animation zone (where the definition of "last" depends on the scroll direction).
			if (scrollingDown) {
				return animationZones.slice().reverse().find(a => a.isActive(previousScrollY));
			}

			return animationZones.find(a => a.isActive(previousScrollY));
		}
	}

	const clamp = (val, min, max) => {
		if (val < min ) return min;
		if (val > max) return max;
		return val;
	}

	class AnimationZone {
		constructor({ height, offset, animationInfo, animationEdge='top' }) {
			this.height = height;
			this.offset = offset;

			this._elem = null;
			this.elemId = animationInfo.elemId;
			this.info = animationInfo;
			this.animationEdge = animationEdge;
		}

		addPovHelper(povHelper) {
			this.povHelper = povHelper;
		}

		bottomInfo() {
			return this.info.bottom;
		}

		topInfo() {
			return this.info.top;
		}

		elem() {
			if (!this._elem) {
				this._elem = document.getElementById(this.elemId);
			}

			return this._elem;
		}

		zone() {
			const boundingRectangle = this.elem().getBoundingClientRect();
			const elemTop = boundingRectangle[this.animationEdge] + pageYOffset;

			const bottom = elemTop - this.offset;
			const top = bottom - this.height;

			return { bottom, top }
		}

		isActive(previousScrollY = null) {
			if (!this.elem()) {
				return;
			}

			const zone = this.zone();
			const currentlyInAnimationZone = zone.top <= pageYOffset && pageYOffset <= zone.bottom;

			if (previousScrollY == null) {
				return currentlyInAnimationZone;
			}

			const skippedOverAnimationZone =
				previousScrollY < zone.top && pageYOffset > zone.bottom ||
				previousScrollY > zone.bottom && pageYOffset < zone.top;

			return skippedOverAnimationZone || currentlyInAnimationZone;
		}

		startAnimation() {
			this.startState = this.topInfo();
			this.goal = this.bottomInfo();
			this.animationForwards = false;
		}

		setStartState(animationForwards, currentState) {
			this.animationForwards = animationForwards
			this.startState = currentState;

			if (animationForwards) {
				this.goal = this.bottomInfo();
			} else {
				this.goal = this.topInfo();
			}

			if (this.povHelper) {
				this.povPath = this.povHelper.pathBetween(
					this.startState.pov, this.goal.pov
				);
			}
		}

		progress() {
			const zone = this.zone();

			const offset = pageYOffset - zone.top;

			let proportion = offset / (zone.bottom - zone.top)
			proportion = clamp(proportion, 0, 1);

			if (!this.animationForwards) {
				proportion = 1 - proportion;
			}

			return proportion
		}

		_interpolate(from, to) {
			const diff = to - from;

			return from + diff * this.progress();
		}

		interpolateProperty(propertyName) {
			return this._interpolate(
				this.startState[propertyName],
				this.goal[propertyName]
			);
		}

		interpolatePov() {
			const povIndex = Math.round(this._interpolate(
				0,
				this.povPath.length - 1
			));

			return this.povPath[povIndex];
		}
	}
})();
