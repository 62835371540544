(function () {
	angular
		.module('fca.buyOnlineThankYou')
		.directive('fcaBuyOnlineThankYou', FcaBuyOnlineThankYou);

	function FcaBuyOnlineThankYou() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				vin: '@',
				dealerName: '@',
				nameplateName: '@',
				vlpUrl: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineThankYouCtrl
		};

		function FcaBuyOnlineThankYouCtrl($scope, $rootScope, $timeout, fcaBuyOnlineLeadService, gtmAnalytics) {
			'ngInject';

			let $ctrl = this;
			$ctrl.existAppointment = false;
			$ctrl.campaignControlNumber = "";

			$ctrl.initLeadSuccessListener = () => {
				$ctrl.haveThankYou = false;
				fcaBuyOnlineLeadService.registerSuccessListener($ctrl.successListener);
			};

			$ctrl.successListener = () => {
				const page_code_active = window.BRANDS_GA.pagetype;

				$ctrl.haveThankYou = true;
				$ctrl.updateScope();

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + page_code_active,
					label: 'buy_online_completed'
				});

				$scope.$broadcast('fcaModal:open');
			};

			$ctrl.updateScope = () => {
				// This is needed to avoid digest cycle,
				// thanks angular.
				$timeout(function() {
					$scope.$apply();
				});
			};

			$ctrl.$onInit = () => {
				// Init DealerName in scope.
				$scope.dealerName = $ctrl.dealerName;
				// Init Nameplate Name
				$scope.nameplateName = $ctrl.nameplateName;
				$scope.vlpUrl = $ctrl.vlpUrl;

				$ctrl.initLeadSuccessListener();
			};

			$rootScope.$on('fca-sales-appointment:appointment-is-selected',
				() => {
					$ctrl.existAppointment = true;
				});

			$rootScope.$on('fca-sales-appointment:appointment-is-canceled',
				() => {
					$ctrl.existAppointment = false;
				});

			$rootScope.$on('fca-buy-online:campaignControlNumber',
				(event, data) => {
					$ctrl.campaignControlNumber = data.campaignControlNumber;
				});
		}
	}
})();
