(function() {
    angular
        .module('fca.components.formDealerSelector', ['ngMessages'])
        .component('componentsFormDealerSelector', {
            controller: ComponentsFormDealerSelectorController,
            controllerAs: '$fDSCtrl',
            templateUrl: ['$element', '$attrs', function($element, $attrs) {
                return $attrs.templateUrl || '/components/form-dealer-selector/form-dealer-selector.html';
            }],
            bindings: {
                apiUrl: '@',
                location: "<",
                brandCode: "@",
                isDealerAppointment: "<",
                isApplyForFinancing: "<",
                isApplyForFinancingSelectorStep: "<",
                mobileInitialMaxDealers: "@",
                desktopInitialMaxDealers: "@",
                mobileDealerIncrement: "@",
                maxAmountOfDealers: "<"
            }
        });

    function ComponentsFormDealerSelectorController($scope, $rootScope, $http, dealerLocator, fcaGeolocator) {
        'ngInject';

        const $fDSCtrl = this;

        $fDSCtrl.dealers = [];
        $fDSCtrl.dealer = "";
        $fDSCtrl.dtdid = "";
        $fDSCtrl.currentDealerData = [];
        $fDSCtrl.mobileMaxDealers = 1;
        $fDSCtrl.desktopMaxDealers = 0;
        $fDSCtrl.intNbOfDealersSelected = 0;
        $fDSCtrl.dealerDisabled = false;
        $fDSCtrl.findDealersTriggered = false;
        $fDSCtrl.sniVehicles = [];
        $fDSCtrl.vehicleInventory = [];
        $fDSCtrl.isSniMode = false;

        $fDSCtrl.$onInit = () => {
            $rootScope.$on('lead-form:sni-vehicle-dealer-code', (event, data) => {
                $fDSCtrl.isSniMode = true;
                $fDSCtrl.dealer = data.dealer;
                $fDSCtrl.selectDealer($fDSCtrl.dealer);
                $fDSCtrl.loaded();
            });

            $rootScope.$on('applyForFinancing:identification-step-sent', (event, data) => {
                return fcaGeolocator.geocodeWithPostalCode(data.postalCode).then((r) => {
                    $fDSCtrl.location = r;
                    $fDSCtrl.location.locationUpdateMethod = 'postalCode';
                    if(!$fDSCtrl.isSniMode) {
                        $fDSCtrl.findDealers();
                    }
                    $fDSCtrl.findDealersTriggered = true;
                })
            });
            $rootScope.$emit('initiateDealereSelectorEvent', null);
        };

        $fDSCtrl.$onChanges = (o) => {
            if ($fDSCtrl.isDealerAppointment ||
                ($fDSCtrl.isApplyForFinancingSelectorStep
                    && $fDSCtrl.findDealersTriggered && !$fDSCtrl.isSniMode)) {
                $fDSCtrl.findDealers();
            }
        };

        $fDSCtrl.loaded = () => {
            $('.C_FDS-form-dealerLocator-loader').hide();
        };

        $fDSCtrl.loading = () => {
            $('.C_FDS-form-dealerLocator-loader').show();
        };

        $fDSCtrl.findDealers = (location) => {
            $fDSCtrl.loading();
            location = $fDSCtrl.location;
            let radius = 100;
            $fDSCtrl.intNbOfDealersSelected = 0;
            $fDSCtrl.dealerDisabled = false;
            $fDSCtrl.mobileMaxDealers = $fDSCtrl.mobileInitialMaxDealers;
            $fDSCtrl.desktopMaxDealers = $fDSCtrl.desktopInitialMaxDealers;


            return dealerLocator.findDealers($fDSCtrl.apiUrl, location, $fDSCtrl.brandCode, true, radius, $fDSCtrl.isApplyForFinancing).then((r) => {
                if(r.manualPostalCode !== undefined) {
                r.userZipCode = r.manualPostalCode;
                }
                if(r.dealers.length) {
                    $fDSCtrl.dealers = r.dealers;
                    $rootScope.$emit('initiateDealerSelectorEvent', null);
                    $fDSCtrl.loaded();
                    if($fDSCtrl.isSniMode) {
                        $fDSCtrl.selectDealer($fDSCtrl.dealer);
                    }
                } else {
                    $fDSCtrl.dealerError = 'No dealers found, please update your location';
                }
            })
        };

        $fDSCtrl.selectDealer = (dealer, zipCode) => {
            let dealerIndex = $fDSCtrl.dealers.findIndex(i => i.code === dealer);
            if ($fDSCtrl.isDealerAppointment) {
                $rootScope.$broadcast('form-dealer-selector:appointment-dealer-selected', {dealer: dealer});
            } else if (dealerIndex !== -1) {
                let selectedDealer = $fDSCtrl.dealers.filter((element) => element.code === dealer);
                selectedDealer = selectedDealer.filter((element) => element.zipPostal === zipCode);

                if ($fDSCtrl.maxAmountOfDealers > 1) {
                    if (!selectedDealer[0].selected) {
                        selectedDealer[0].selected = true;
                        $fDSCtrl.intNbOfDealersSelected++;
                        $('.C_CAD-form-dealerLocator-dealer-card').removeClass('ng-invalid ng-touched');
                        $('.C_CAD-errors-dealerIds').addClass('-invisible');
                    } else {
                        selectedDealer[0].selected = false;
                        $fDSCtrl.intNbOfDealersSelected--;
                    }
                    if ($fDSCtrl.intNbOfDealersSelected < $fDSCtrl.maxAmountOfDealers) {
                        $fDSCtrl.dealerDisabled = false;
                    } else {
                        $fDSCtrl.dealerDisabled = true;
                    }
                } else if ($fDSCtrl.maxAmountOfDealers === 1) {
                    if (!selectedDealer[0].selected) {
                        selectedDealer[0].selected = true;
                        $fDSCtrl.dealer = dealer;
                        $fDSCtrl.dtdid = selectedDealer[0].dtdid;
                        if ($fDSCtrl.isSniMode) {
                            $fDSCtrl.dealers.splice(dealerIndex, 1);
                            $fDSCtrl.dealers.unshift(selectedDealer[0]);
                            $fDSCtrl.dealerDisabled = true;
                        } else {
                            $fDSCtrl.dealerDisabled = false;
                        }
                        $fDSCtrl.dealers.forEach(function (element) {
                            if (element !== selectedDealer[0] && selectedDealer[0].selected) {
                                element.selected = false;
                            }
                        });
                        $('.C_FDS-form-dealerLocator-dealer-card').removeClass('ng-invalid ng-touched');
                        $('.C_FDS-errors-dealerIds').addClass('-invisible');
                    }
                }
            } else {
                $http.get(`/data/dealers/${$fDSCtrl.brandCode}/${dealer}`).then((r) => {
                    r.data.selected = true;
                    $fDSCtrl.dealers.unshift(r.data);
                    $fDSCtrl.dealerDisabled = true;
                });
            }
            $rootScope.$broadcast('form-dealer-selector:single-dealer-selected', {dealer: $fDSCtrl.dealer, dtdid: $fDSCtrl.dtdid});
        };

        $fDSCtrl.incrementDealers = () => {
            let max = parseInt($fDSCtrl.mobileMaxDealers);
            if(max < $fDSCtrl.dealers.length) {
                max += parseInt($fDSCtrl.mobileDealerIncrement);
                $fDSCtrl.mobileMaxDealers = max;
                if($fDSCtrl.isSniMode && $fDSCtrl.dealer !=='') {
                    $fDSCtrl.selectDealer($fDSCtrl.dealer);
                }
            }
        };
    }
})();
