// There is nearly duplicated code with Built Vehicles, Inventory Vehicles and Viewed Vehicles,
// The only difference being which UA functions are being called

(function () {
	angular
	.module('fca.userAccount.myGarage')
	.directive('fcaUserAccountMyGarageCustomVehicles', CustomVehicles);

	function CustomVehicles() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				pricingInvalidationDate: '@',
				pricingValidityInHours: '@',
				calculatorDefaultFinanceRate: '@',
				maxAcceptableLeaseRate: '@'
			},
			controllerAs: '$customVehiclesCtrl',
			controller: CustomVehiclesCtrl
		};

		function CustomVehiclesCtrl($scope, $rootScope, pricingService, $window, $http) {

			let $customVehiclesCtrl = this;

			$customVehiclesCtrl.vehicles = [];
			$customVehiclesCtrl.selectedIds = {};
			$customVehiclesCtrl.sliderActive = true;
			$customVehiclesCtrl.sliderDeactivated = false;

			const currentLanguage = FCA_SITES_CONFIG.language;

			$customVehiclesCtrl.$onInit = () => {
				$rootScope.$on('ua:libraryInitialized', () => {
					$customVehiclesCtrl.loadVehiclesAndPricing();
					if (!UA.isUserLoggedIn()) {
						$customVehiclesCtrl.sliderDeactivated = true;
					}
					$customVehiclesCtrl.checkVisibleSlides();
				});
			};

			$customVehiclesCtrl.loadVehiclesAndPricing = () => {
				if (UA.isUserLoggedIn()) {
					UA.getCustomOrderVehicles().then((vehicles) => {
						$scope.$apply(() => {
							$customVehiclesCtrl.selectedIds = {};
							$customVehiclesCtrl.vehicles = vehicles;
							vehicles.forEach((vehicle) => {
								$customVehiclesCtrl.selectedIds[vehicle.id] = false;
							});
						});

						// initialize pricing of built vehicles
						let initializedVehicleIds = pricingService.initPricingService(
							$customVehiclesCtrl.pricingInvalidationDate,
							$customVehiclesCtrl.pricingValidityInHours,
							$customVehiclesCtrl.vehicles,
							$customVehiclesCtrl.calculatorDefaultFinanceRate,
							$customVehiclesCtrl.maxAcceptableLeaseRate);
						$customVehiclesCtrl.checkVisibleSlides();

						// remove vehicles not correctly initialized
						$customVehiclesCtrl.vehicles = $customVehiclesCtrl.vehicles.filter(vehicle => {
							return initializedVehicleIds.findIndex(id => id === vehicle.id) > -1;
						});

						$scope.$broadcast('vehicleListUpdated');
					}).catch(err => {
						throw new Error('error fetching vehicles', err)
					});
				}
			};

			$customVehiclesCtrl.getCustomOrderListingUrl = () => {
				const brandCode = window.FCA_SITES_CONFIG.subBrandCode ? window.FCA_SITES_CONFIG.subBrandCode : window.FCA_SITES_CONFIG.brandCode
				return `${window.FCA_SITES_CONFIG.brandSiteUrls[brandCode]}/${currentLanguage}/custom-order`;
			}


			$customVehiclesCtrl.getSniUrl = (vehicle, baseUrl) => {
				return baseUrl + "/" + vehicle.subBrandCode + "/" + vehicle.nameplateCode;
			};

			$customVehiclesCtrl.countSelected = () => {
				let selected = Object.keys($customVehiclesCtrl.selectedIds).filter(key => {
					return $customVehiclesCtrl.selectedIds[key] === true;
				});
				return selected.length;
			};

			$customVehiclesCtrl.updateVehicle = (id, titleAndNotes) => {
				UA.updateBuiltVehicle(id, titleAndNotes).then((response) => {
					if(response) {
						$customVehiclesCtrl.vehicles.forEach(elem => {
							if (elem.id === id) {
								elem.title = response.title;
								elem.notes = response.notes;
								$scope.$apply();
							}
						});
					} else {
						// response is empty, that means this vehicle doesn't exist anymore
						$customVehiclesCtrl.loadVehiclesAndPricing();
					}
				})
			};

			$customVehiclesCtrl.removeVehicles = () => {
				let selectedVehicleIds = Object.keys($customVehiclesCtrl.selectedIds).filter(key => {
					return $customVehiclesCtrl.selectedIds[key];
				});

				selectedVehicleIds = selectedVehicleIds.map(elem => {
					return parseInt(elem);
				});

				UA.removeCustomOrderVehicles(selectedVehicleIds).then((response) => {
					$customVehiclesCtrl.loadVehiclesAndPricing();
				});
			};

			$customVehiclesCtrl.checkVisibleSlides = () => {
				let visibleSlides;
				let mobileBreakpoint = 667;
				let desktopBreakpoint = 1024;
				let windowWidth = $window.innerWidth;

				if(windowWidth < mobileBreakpoint) {
					visibleSlides = 1;
				} else if(windowWidth < desktopBreakpoint) {
					visibleSlides = 2;
				} else {
					visibleSlides = 3;
				}

				if($customVehiclesCtrl.vehicles.length == 0 ||
					$customVehiclesCtrl.vehicles.length <= visibleSlides ||
					$customVehiclesCtrl.sliderDeactivated == true) {
					$customVehiclesCtrl.sliderActive = false;
				} else {
					$customVehiclesCtrl.sliderActive = true;
				}
			};

			angular.element($window).on('resize', function () {
				$customVehiclesCtrl.checkVisibleSlides();
			});

			$customVehiclesCtrl.showAll = () => {
				$customVehiclesCtrl.sliderDeactivated = true;
				$customVehiclesCtrl.checkVisibleSlides();
				$scope.$broadcast('destroySlider');
			}
		}
	}
})();
