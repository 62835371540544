(function(ng) {
	'use strict';

	angular
		.module('fca.externalConfigLoader')
		.provider('externalConfigLoader', Provider);

	/**
	 * @ngdoc service
	 * @name fca.externalConfigLoader.externalConfigLoaderProvider
	 * @description External config loader provider. Can load and expose loaded configuration
	 * @requires $windowProvider
	 * @example
	 * <pre>
	 * // In HTML template script tag
	 * window.CONFIG = { webserviceUrl: '/api' };
	 * // In application
	 * ng
	 *   .module('MyApp', ['fca.externalConfigLoader'])
	 *   .config(function(externalConfigLoaderProvider){
	 *     let config = externalConfigLoaderProvider.loadConfig('CONFIG');
	 *     // config == { webserviceUrl: '/api' }
	 *   });
	 * </pre>
	 */
	function Provider($windowProvider) {
		'ngInject';

		let cfg;
		
		const ERRORS = {
			NAMESPACE: `External Config Exception: %NS% not exists in window object`,
			PROP: `Config Exception: %PROP% doesn't exist in configuration`
		};

		/**
		 * @ngdoc method
		 * @name loadConfig
		 * @methodOf fca.externalConfigLoader.externalConfigLoaderProvider
		 * @param  {...String} namespace Different segment of window object namesapce
		 * @return {Object}              Provider instance (chaining)
		 * @description Load window attached object with namespace reference
		 * @example
		 * <pre>
		 * // In HTML
		 * window.DATA.SEARCH.CONFIG = {};
		 * // In application
		 * ng
		 *   .module('MyApp', ['fca.externalConfigLoader'])
		 *   .config(function(externalConfigLoaderProvider) {
		 *     let cfg_A = externalConfigLoaderProvider.loadConfig('DATA', 'SEARCH').getConfig();
		 *     // cfg_A == {CONFIG: {}}
		 *     let cfg_B = externalConfigLoaderProvider.loadConfig('DATA', 'SEARCH', 'CONFIG').getConfig();
		 *     // cfg_B == {}
		 *     let cfg_C = externalConfigLoaderProvider.loadConfig('NOT_EXIST').getConfig();
		 *     // throw an error
		 *   });
		 * </pre>
		 */
		this.loadConfig = (...namespace) => {
			// Get window object
			let $window = $windowProvider.$get();

			// Loop in window to retrieve configurations
			namespace.forEach((n) => {
				if ($window[n] === undefined) {
					if (ng.isObject(cfg) && cfg.hasOwnProperty(n)) {
						cfg = ng.copy(cfg[n]);
					}
				} else {
					cfg = ng.copy($window[n]);
				}
			});

			// Throw error if cfg not founded
			if (cfg === undefined) {
				throw new Error(ERRORS.NAMESPACE.replace('%NS%', namespace.join('.')));
			}
			return this;
		};

		/**
		 * @ngdoc method
		 * @name getConfig
		 * @methodOf fca.externalConfigLoader.externalConfigLoaderProvider
		 * @param  {String} prop Config property name to return
		 * @return {Object}      Config with property name
		 * @description Access config loaded by method loadConfig()
		 * @example
		 * <pre>
		 * // In HTML
		 * window.DATA = {api: '/api', timestamp: 4000, refresh: true};
		 * ng
		 *   .module('MyApp', ['fca.externalConfigLoader'])
		 *   .config(function(externalConfigLoaderProvider) {
		 *     // Config. is loaded
		 *     externalConfigLoaderProvider.loadConfig('DATA');
		 *     // Access config property
		 *     let api = externalConfigLoaderProvider.getConfig('api');
		 *     let timestamp = externalConfigLoaderProvider.getConfig('timestamp');
		 *     let nothing = externalConfigLoaderProvider.getConfig('nothing');
		 *     // throw an error on call with nothing argument
		 *   });
		 * </pre>
		 */
		this.getConfig = (prop) => {
			let r;
			// Return all config if prop is undefined
			if (prop === undefined) {
				r = cfg;
			} else if (ng.isObject(cfg) && cfg.hasOwnProperty(prop)) {
				// Return the config
				r = cfg[prop];
			}

			// Throw error if prop not exists in config
			if (r === undefined) {
				throw new Error(ERRORS.PROP.replace('%PROP%', prop));
			} else {
				return r;
			}
		};

		this.$get = () => {
			/**
			 * @ngdoc service
			 * @name fca.externalConfigLoader.service:externalConfigLoader
			 * @description [TODO]
			 * @example
			 * <pre>[TODO]</pre>
			 */
			return {
				/**
				 * @ngdoc method
				 * @name getConfig
				 * @methodOf fca.externalConfigLoader.service:externalConfigLoader
				 * @description {@link fca.externalConfigLoader.externalConfigLoaderProvider:getConfig See provider doc}
				 * @param  {String} prop Config property name to return
				 * @return {Object}      Config with property name
				 */
				getConfig: (prop) => {
					return this.getConfig(prop);
				},
				/**
				 * @ngdoc method
				 * @name loadConfig
				 * @methodOf fca.externalConfigLoader.service:externalConfigLoader
				 * @description {@link fca.externalConfigLoader.externalConfigLoaderProvider:loadConfig See provider doc}
				 * @param  {...String} namespace Different segment of window object namesapce
				 * @return {Object}              Provider instance (chaining)
				 */
				loadConfig: this.loadConfig
			};
		};
	}
})(angular);
