(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaUpdateSlick', fcaUpdateSlick);

	function fcaUpdateSlick() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$updateSlickCtrl',
			controller: FcaUpdateSlickCtrl
		};

		function FcaUpdateSlickCtrl($rootScope, $element, $scope) {
            'ngInject';
			let $ctrl = this;
			$ctrl.slideNumber = 0;

            this.updateWithNumber = ($event) => {
				//get target index
				$ctrl.slideNumber = Array.prototype.slice.call( $event.currentTarget.parentNode.children).indexOf($event.currentTarget);
			};

			$scope.$on('fca-gallery-slider-initialized', () => {
                $rootScope.$broadcast('slide-change-to', {slide: $ctrl.slideNumber});
            });
        }
	}
})();