(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.daaForm.inputRange
	 * @description
	 * Input range module for input range form element component
	 * @example
	 * <pre>
	 * angular.module('MyApp', ['fca.daaForm.inputRange']);
	 * // In HTML
	 * <form>
	 * 	<daa-input-range min="0" max="84" step="12" id="input-range-id" name="input-range-name"></daa-input-range>
	 * </form>
	 * </pre>
	 */
	angular
		.module('fca.daaForm.inputRange', []);
})();
