(function () {
	angular
		.module('alfaromeo.buildAndPrice.powertrain')
		.component('alfaPowertrain', {
			controller: alfaPowertrain,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/powertrain/alfaromeo-powertrain.html',
			bindings: {
				engineVideo: '@',
				engineThumbnail: '@',
				engineDescription: '@',
				engineTitle: '@',
				engineSubtitle: '@',
				transmissionThumbnail: '@',
				transmissionDescription: '@',
				transmissionTitle: '@',
				transmissionSubtitle: '@',
			}
		});

	function alfaPowertrain($scope) {
		'ngInject';

		const $ctrl = this;

		const videoElementId = "js-AR_powertrain-video";

		$ctrl.isVideoPlaying = false;
		$ctrl.isVideoInitiated = false;

		$ctrl.$onInit = () => {
			$ctrl.hasVideo = Boolean($ctrl.engineVideo);
		};

		$ctrl.playVideo = () => {
			const videoElement = document.getElementById(videoElementId);

			if (!videoElement) return;

			if (videoElement.currentTime > 0 && !videoElement.paused &&
				!videoElement.ended && videoElement.readyState > 2) {
				videoElement.pause();
				$ctrl.isVideoPlaying = false;
			} else {
				videoElement.play();
				$ctrl.isVideoPlaying = true;
				$ctrl.isVideoInitiated = true;
			}
		};

		$ctrl.addEventAfterContentIsLoaded = () => {
			document.getElementById(videoElementId).addEventListener('ended', isEnded, false);
		};

		function isEnded() {
			$ctrl.isVideoPlaying = false;
			$scope.$apply();
		}
	}
})();
