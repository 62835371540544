(function () {
    angular
        .module('fca.uconnect')
        .component('fcaMenuButton', {
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/menu-button/menu-button.html',
            bindings: {
                screenList: '<',
                changeDisplayedScreen: '<',
                language: '@',
                analyticsId: '@'
            }
        });
})();
