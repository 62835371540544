(function() {
	'use strict';

	angular
		.module('fca.geolocator')
		.filter('distance', Distance);

	function Distance() {
		'ngInject';

		const R = 6378137;

		return function(p, t) {
			let dLat = toRadian(t.latitude - p.latitude);
			let dLong = toRadian(t.longitude - p.longitude);
			let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(toRadian(p.latitude)) * Math.cos(toRadian(t.latitude)) *
				Math.sin(dLong / 2) * Math.sin(dLong / 2);

			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			let distance = Math.round((R * c) / 1000);
			return distance;
		};

		function toRadian(x) {
			return x * Math.PI / 180;
		}
	}
})();
