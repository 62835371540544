// Allows the usage of the following attributes:
//     * ng-load
(function() {
	'use strict';

	angular
		.module('fca.commonDirectives', [])
		.directive('ngLoad', onLoad);

	function onLoad() {
		return {
			controller: function ($scope, $element) {
				$element.bind('load', onLoad);

				function onLoad(event) {
					var method = $element.attr('ng-load');
					$scope.$event = event;
					$scope.$apply(method);
				};
			}
		};
	};

})();
