(function () {
    angular
    .module('fca.countdown')
    .directive('fcaSimpleCountdown', fcaSimpleCountdown);

    function fcaSimpleCountdown() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                countdownDate: '@',
                filterUnits: '@'
            },
            controllerAs: '$fcaSimpleCountdownCtrl',
            controller: fcaSimpleCountdownController,
        };

        //code borrowed from /common-sites/countdown/fca-countdown.component.js
        //modified to be simplier to only output only units
        function fcaSimpleCountdownController($scope, $interval, $timeout) {
            'ngInject';

            const $ctrl = this;

            let TIME_UNITS = [
                ['seconds', 1000],
                ['minutes', 60],
                ['hours', 60],
                ['days', 24],
                // ['months', 30, 30],
                // ['years', 12, 12]
            ];

            let diffMs;
            let countingDown = true;
            let intervalPromise;
            let intervalMs = 1000;

            $ctrl.$onInit = () => {
                $ctrl.counterFinished = false;
                $ctrl.calculate();
                $ctrl.initTimeUnits();
            }

            $ctrl.calculate = () => {
                // Updating internal time difference.
                if ($ctrl.countdownDate) {
                    let finalCountdownDate = new Date(parseInt($ctrl.countdownDate));
                    // Calculating difference between current date and target date.
                    var now = new Date();
                    //in miliseconds
                    diffMs = (now.getTime() - finalCountdownDate);
                } else {
                    // Setting difference to zero if date is not provided.
                    diffMs = 0;
                }

                // Making sure difference is adjusted correctly if timer should be stopped.
                if ($ctrl.isCounterShouldBeStopped()) {
                    diffMs = 0;
                }

                // Updating the view with new value.
                $ctrl.renderCountdown();

                // Making sure counter is ticking.
                if (!$ctrl.isCounterShouldBeStopped()) {
                    $ctrl.startCounter();
                }
            };

            /**
             * Updating the view with the actual data.
             */
            $ctrl.renderCountdown = () => {
                var valueToRender = diffMs;
                if (false === countingDown && diffMs < 0) {
                    // Rendering counter as zero when counting up and
                    // target date is in the future.
                    valueToRender = 0;
                }
                angular.extend($scope, this.diffMsToTimeUnits(valueToRender));
            }

            /**
             * Starts or re-starts the counter loop.
             */
            $ctrl.startCounter = () => {
                if (intervalPromise) {
                $ctrl.clearCounter();
                }
                intervalPromise = $interval($ctrl.counterTick, intervalMs);
            }

            /**
             * Stops the counter loop.
             */
            $ctrl.clearCounter = () => {
                $interval.cancel(intervalPromise);
                intervalPromise = null;
                $ctrl.counterFinished = true;
            }

            /**
             * Counter tick handler.
             */
            $ctrl.counterTick = () => {
                // Updating internal difference value.
                diffMs += intervalMs;

                // Checking if counter should be stopped.
                if ($ctrl.isCounterShouldBeStopped()) {
                    diffMs = 0;
                    clearCounter();
                    $timeout(function () {
                        $scope.onFinish();
                    });
                }

                // Updating the view.
                $ctrl.renderCountdown();
            }

            /**
             * Indicates if counter should be stopped.
             *
             * @returns {boolean}
             */
            $ctrl.isCounterShouldBeStopped = () => {
                // No need to run counter when counting down and
                // target date is already reached.
                return (countingDown && diffMs >= 0);
            }

            /**
             * Calculating absolute millisecond value for each
             * time unit.
             */
            $ctrl.initTimeUnits = () => {
                var divider = 1;
                var filterUnits;

                //filter out units if exist
                if ($ctrl.filterUnits) {
                    var filter = $ctrl.filterUnits.split(',');

                    filterUnits = TIME_UNITS.filter(function (item) {
                        return filter.indexOf( item[0] ) < 0;
                    });

                    TIME_UNITS = filterUnits;
                }

                TIME_UNITS.forEach(function (unit) {
                    divider = divider * unit[1];
                    unit[1] = divider;
                });

                TIME_UNITS.reverse();
            }

            /**
             * Returns difference as separate time units.
             *
             * @param {int} diffMs
             *
             * @returns {object}
             */

            $ctrl.diffMsToTimeUnits = (diffMs) => {
                let result = {};
                diffMs = Math.abs(diffMs);

                angular.forEach(TIME_UNITS, function (unit) {
                    let name = unit[0];
                    let divider = unit[1];
                    let value;

                    if (diffMs > 0) {
                        let longValue = diffMs / divider;
                        value = Math.floor(longValue);
                        diffMs -= value * divider;
                    } else {
                        value = 0;
                    }

                    result[name] = value;
                });

                $ctrl.timeUnits = result;
            }
        }
    }
})();