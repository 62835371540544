(function () {
	angular
		.module('alfaromeo.buildAndPrice.compile-html')
		.directive('alfaCompileHtml', $compile => ({
			restrict: 'A',
			link: ($scope, element, attrs) => {
				$scope.$watch(
					() => $scope.$eval(attrs.alfaCompileHtml),
					value => {
						element.html(value && value.toString());
						$compile(element.contents())($scope);
					}
				);
			}
		}))
})();
