(function () {
	angular
	.module('fca.userAccount.myGarage')
	.directive('fcaUserAccountMyGarageBanner', Banner);

	function Banner() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: '$fcaUserAccountMyGarageBannerCtrl',
			controller: BannerCtrl
		};

		function BannerCtrl($scope, $rootScope, userAccountService) {

			let $ctrl = this;

			$ctrl.isUserSignedIn = false;
			$ctrl.isEmailAlertEnabled = false;
			$ctrl.isUserUnsubscribing = false;
			$ctrl.successMessage = false;
			$ctrl.pageIsReady = false;
			$ctrl.bannerEmailStateIsHide = false;
			$ctrl.bannerNormalStateIsHide = false;
			$ctrl.unsubscribeSuccessMessage = false;
			$ctrl.userEmail = false;
			$ctrl.emailAlreadyExistsMessage = false;

			$ctrl.$onInit = () => {

				$rootScope.$on('ua:libraryInitialized', () => {
					$ctrl.isUserSignedIn = UA.isUserLoggedIn();
					if (UA.getUser()) {
						$ctrl.isEmailAlertEnabled = UA.getUser().emailAlertEnabled;
						$ctrl.userEmail = UA.getUser().email;
					}
				});

				$rootScope.$on('$viewContentLoaded', () => {
					$ctrl.pageIsReady = true;
				});

				if (localStorage.getItem("savedBanner") === 'normalState') {
					$ctrl.bannerNormalStateIsHide = true;
				}

				if (window.location.href.endsWith('unsubscribe')) {
					$ctrl.isUserUnsubscribing = true;
				}
			};

			$ctrl.loadSignInPage = () => {
				let language = window.FCA_SITES_CONFIG.language;
				window.location.href = window.FCA_SITES_CONFIG.urls.signIn[language];
			};

			$ctrl.signOut = () => {
				if (userAccountService.isLibraryAvailable()) {
					UA.logout(function () {
						window.location.reload();
					});
				}
			};

			$ctrl.subscribe = newEmail => {
				if(typeof newEmail === 'undefined' || newEmail === null) {
					newEmail = '';
				}

				if (userAccountService.isLibraryAvailable() && UA.isUserLoggedIn()) {
					UA.subscribe(newEmail).then(() => $scope.$apply(() => {
						if (UA.getUser()) {
							$ctrl.successMessage = UA.getUser().emailAlertEnabled;
						}
					})).catch(e => $scope.$apply(() => {
						let catchedMessage = e.message;
						let existingEmailError = 'email-already-exists';

						if (catchedMessage.indexOf(existingEmailError) !== -1) {
							$ctrl.emailAlreadyExistsMessage = true;
						}
					}));
				}
			};

			$ctrl.hideEmailStateBanner = () => {
				$ctrl.bannerEmailStateIsHide = true;
				localStorage.setItem("savedBanner", 'emailState');
			};

			$ctrl.hideNormalStateBanner = () => {
				$ctrl.bannerNormalStateIsHide = true;
				localStorage.setItem("savedBanner", 'normalState');
			};

			$ctrl.unsubscribe = (event) => {
				if (userAccountService.isLibraryAvailable()) {
					if (UA.isUserLoggedIn()) {
						UA.unsubscribe().finally(() => $scope.$apply(() => {
							if (UA.getUser()) {
								$ctrl.unsubscribeSuccessMessage = true;
							}
						}));
					}
				}
			};

			$scope.$on('ua:userSignInSuccess', (event, data) => {
				UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
					UA.unsubscribe().finally(() => $scope.$apply(() => {
						if (UA.getUser()) {
							$ctrl.unsubscribeSuccessMessage = true;
						}
					}));
				});
			});
		}
	}
})();
