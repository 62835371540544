/* eslint-disable indent */
(function() {
    angular
    .module('fca.components.responsiveJelly')
    .component('componentsResponsiveJelly',{
        controller: ComponentsResponsiveJellyController,
        controllerAs: '$ctrl',
        templateUrl: '/components/responsive-jelly/responsive-jelly.html',
        bindings: {
            jellies: '=',
            irisJellyUrl: '@',
            title: '@',
            alt: '@'
        }
    });

    function ComponentsResponsiveJellyController() {
        'ngInject';
        const $ctrl = this;

        $ctrl.legacyRootURl = FCA_SITES_CONFIG.jelliesUrl.replace('jellies/renditions/','');

        $ctrl.jelliesMediaQuery = {
            MOBILE: '(min-width: 1px)',
            MOBILE_RETINA: '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1px) and (min--moz-device-pixel-ratio: 2),(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1px) and (min-device-pixel-ratio: 2),(min-width: 1px) and (min-resolution: 192dpi),(min-width: 1px) and (min-resolution: 2dppx)',
            TABLET: '(min-width: 768px)',
            TABLET_RETINA: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 768px) and (min--moz-device-pixel-ratio: 2),(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 768px) and (min-device-pixel-ratio: 2),(min-width: 768px) and (min-resolution: 192dpi),(min-width: 768px) and (min-resolution: 2dppx)',
            DESKTOP: '(min-width: 1025px)',
            DESKTOP_RETINA: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1025px) and (min-device-pixel-ratio: 2),(min-width: 1025px) and (min-resolution: 192dpi),(min-width: 1025px) and (min-resolution: 2dppx)'
        };

        $ctrl.$onInit = () => {
            if ($ctrl.irisJellyUrl) {
                return;
            }
            $ctrl.jelliesSource = $ctrl.buildResponsiveSrc($ctrl.jellies);
        };

        $ctrl.buildResponsiveSrc = (jellies) => {
            let jelliesStringArray = "[";

            if(jellies) {
                jellies.forEach(function (jelly, index) {
                    jelliesStringArray += "[";
                    jelliesStringArray += "'" + $ctrl.jelliesMediaQuery[jelly.resolutionCode] + "', ";
                    jelliesStringArray += "'" + $ctrl.legacyRootURl + jelly.path + "'";
                    jelliesStringArray += "]";
                    if (index < jellies.length - 1) {
                        jelliesStringArray += ", ";
                    }
                });
            }

            jelliesStringArray += "]";

            return jelliesStringArray;
        };

    }
})();