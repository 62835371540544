/* eslint-disable indent */
(function() {
    angular
        .module('fca.chatMenuPopup')
        .directive('fcaChatButton', fcaChatButton);

    function fcaChatButton() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {},
            controllerAs: 'fcaChatButton',
            controller: FcaChatButtonController
        };

        function FcaChatButtonController($element, dealerChatService, $rootScope) {
            'ngInject';

            let chatProvider = $element.data('chatProvider');
            let chatProviderId = $element.data('chatProviderId');
            let dealerCode = $element.data('dealerCode');
            let originationUrl = encodeURIComponent(location.href);
            let chatUrl = dealerChatService.getDealerChatUrl(chatProvider, chatProviderId, dealerCode, originationUrl);
            if (chatUrl) {
                $element.attr('href', chatUrl);
                $rootScope.dealerHasChatUrl = true;
            } else {
                $element.hide();
                $rootScope.dealerHasChatUrl = false;
            }
        }
    }
})();
