(function() {
	/**
	 *  Wrapper directive around ngDialog
	 */
	angular
		.module('fca.brandApp')
		.directive('fcaDialog', fcaDialog());

	function fcaDialog() {
		return (ngDialog) => {
			'ngInject';

			return {
				restrict: 'A',
				scope: {
					template: '@',
					theme: '<',
					scope: '@?'
				},
				controller: FcaDialogController,
				link: FcaDialogLink
			};

			function FcaDialogController($scope, matchmedia, FCA_MQ_LAYOUT) {
				'ngInject';
				let unreg = matchmedia.on(FCA_MQ_LAYOUT.NOT_MOBILE, function(mediaQueryList) {
					if (mediaQueryList.matches) {
						ngDialog.close();
					}
				});

				$scope.$on('$destroy', function() {
					unreg();
				});
			}

			function FcaDialogLink(scope, element, attrs) {
				const elem = angular.element(element);
				elem.addClass('fca-dialog-trigger');

				elem.bind('click', function(event) {
					event.preventDefault();
					let dialogScope;

					if (attrs.scope) {
						dialogScope = scope.$new(true);
						let newscope = angular.fromJson(attrs.scope);

						for (let propertyName in newscope) {
							if (newscope.hasOwnProperty(propertyName)) {
								newscope[propertyName] = newscope[propertyName]
								.replace(new RegExp('&amp;', 'g'), '&')
								.replace(new RegExp('&lt;', 'g'), '<')
								.replace(new RegExp('&gt;', 'g'), '>')
								.replace(new RegExp('&#39;', 'g'), '\'')
								.replace(new RegExp('&quot;', 'g'), '"');
							}
						}
						angular.extend(dialogScope, newscope);
					}
					ngDialog.open(buildDialogOptions(attrs.template, attrs.theme, dialogScope));
				});

				scope.$on('$destroy', function() {
					elem.unbind('click');
				});
			}

			function buildDialogOptions(template, theme, scope) {
				let options = {};

				if (theme) {
					options.className = theme;

					if (theme === 'fca-pano-video') {
						options.showClose = false;

						// to be refactored, should not have specific dialog instances handled in this directive
						options.onOpenCallback = () => {
							let dialog = ngDialog.getOpenDialogs()[0];
							let $dialog = $(`#${dialog}`);

							let $videoElem = $dialog.find('video').eq(0)[0];

							if ($videoElem) {
								let started = false;

								if (!started) {
									$videoElem.play();
									started = true;
								};
							}
						};
					}
				}

				if (scope) {
					options.scope = scope;
				}

				options.template = template;

				return options;
			}
		};
	}
})();
