(function() {
	angular
		.module('alfaromeo.filter')
		.component('alfaFilter', {
			controller: alfaFilterController,
			controllerAs: '$alfaFilter',
			bindings: {
				options: '<'
			},
			templateUrl: '/brand-specific/alfaromeo/components/filter/alfaromeo-filter.html'
		});

	function alfaFilterController() {
		'ngInject';

		const $ctrl = this;
		$ctrl.initialOption = null;

		$ctrl.$onInit = () => {
			$ctrl.getCurrentOption();
		};

		$ctrl.getCurrentOption = () => {
			$ctrl.options.forEach(option => {
				Object.keys(option).forEach(key => {
					if (option.hasOwnProperty(key) && key === 'selected') {
						if (option[key] === 'true') {
							$ctrl.initialOption = option;
						}
					}
				});
			});
		};

		$ctrl.onSelectionChange = url => {
			if (url) {
				window.location.href = url;
			}
		}
	}
})();
