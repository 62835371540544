(function(ng, window) {
	'use strict';

	angular
		.module('fca.gtmAnalytics', [
			'angulartics'
		])
		.config((
			gtmAnalyticsProvider) => {
			'ngInject';

			/**
			 * Load GA config
			 */
			let options = {};
			// Clean-up options and remove null values
			for (let p in options) {
				if (options[p] === null || options[p] === 'null') {
					delete options[p];
				}
			}
			// Extend gtmAnalytics page options
			gtmAnalyticsProvider.options = ng.extend({}, gtmAnalyticsProvider.options, options);

			/**
			 * Disabled automatic tracking
			 */
			gtmAnalyticsProvider.disabledDefaultTracking();
		})
		.provider('gtmAnalytics', function(
			$analyticsProvider) {
			'ngInject';

			/**
			 * [options description]
			 * @type {Object}
			 */
			this.options = {};

			this.eventPrefix = 'eventtrigger';

			/**
			 * [description]
			 * @return {[type]} [description]
			 */
			this.$get = ($analytics, matchmedia, $location, $window) => {
				'ngInject';

				return {
					trackPage: function(pOptions = {}, disabledTracking = false) {
						// Additional options
						let opts = ng.extend({}, pOptions);
						// Device type
						let device = 'desktop';

						if (!matchmedia.isDesktop()) {
							opts.mobileorientation = 'portrait';
							if (matchmedia.isLandscape()) {
								opts.mobileorientation = 'landscape';
							}

							device = 'tablet';
							if (matchmedia.isPhone()) {
								device = 'mobile';
							}
						}

						// Set device type
						opts.device = device;

						if (!disabledTracking) {
							let url = `${$window.location.pathname}`;
							let hash = $window.location.hash;
							if (hash.substr(-1) !== '/') {
								url += `${hash}`;
							}

							$analytics.pageTrack(url, opts);
						}
					},
					trackEvent: function(event, pOptions = {}) {
						$analytics.eventTrack(event, pOptions);
					}
				};
			};

			/**
			 * [description]
			 * @return {[type]} [description]
			 */
			this.disabledDefaultTracking = () => {
				// Virtual page view
				$analyticsProvider.virtualPageviews(false);
				// Automatic first page tracking
				$analyticsProvider.firstPageview(false);
				return this;
			};

			$analyticsProvider.registerPageTrack((path, pOptions) => {
				let dataLayer = window.dataLayer = window.dataLayer || [];
				let gtmOptions = ng.extend({
					'event': 'content-view',
					'content-name': path
				}, this.options, pOptions);
				let adobeOptions = {};

				// Push in dataLayer
				dataLayer.push(ng.extend({}, gtmOptions, adobeOptions));
			});

			$analyticsProvider.registerEventTrack((action, properties = {}) => {
				let dataLayer = window.dataLayer = window.dataLayer || [];
				let prefix = this.eventPrefix;
				let params = {
					[prefix]: action
				};
				let gtmOptions = ng.extend(params, properties);
				let adobeOptions = {};
				dataLayer.push(ng.extend({}, gtmOptions, adobeOptions));
			});
		});
})(angular, window);
