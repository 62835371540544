(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('textTransformNoneSpan', textTransformNoneSpan);

    function textTransformNoneSpan() {
        'ngInject';

        return function(text) {
            let pattern = new RegExp('4xe', 'gi');
            return text ? String(text).replace(pattern, '<span class="text-transform-none">4xe</span>') : '';
        };
    }
})();
