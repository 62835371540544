(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator.cookie')
		.service('geolocatorRegionCookieService', GeolocatorRegionCookie);

	function GeolocatorRegionCookie(
		regionMappingService,
		urlRegionMapping,
		$location,
		GeolocatorCookie,
		geolocatorCookieExpire,
		externalConfigLoader) {
		'ngInject';

        let cookieName = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('regionCookieName');
		const DEFAULT_REGION = regionMappingService.getDefault().slug;
		const EXPIRES = geolocatorCookieExpire.getOneDayExpires();
		const COOKIE_CONNECTOR = new GeolocatorCookie(cookieName, EXPIRES);

		let url = $location.path();
		let urlRegion = urlRegionMapping.getRegionByUrl(url) || '';

		this.set = (cookieData) => {
			return COOKIE_CONNECTOR.set(cookieData);
		};

		this.get = () => {
			return COOKIE_CONNECTOR.get();
		};

		this.isCookieExists = () => {
			return COOKIE_CONNECTOR.isCookieExists();
		};

		// Set the cookie if not exists
		if (!this.isCookieExists()) {
			if (urlRegion === '') {
				urlRegion = DEFAULT_REGION;
			}

			let cookieData = {
				region: urlRegion
			};

			this.set(cookieData);
		} else if (this.get().region !== urlRegion && urlRegion.length !== 0) {
			let cookieData = {
				region: urlRegion
			};

			this.set(cookieData);
		}
	}
})(angular);
