(function() {
	angular
		.module('fca.brandApp')
		.directive('threesixty', ['$document', '$window', 'gtmAnalytics',

	function ($document, $window, gtmAnalytics) {
		return {
			templateUrl: '/panels/exterior-360/threesixty.html',
			restrict: 'E',
			replace: true,
			controllerAs: '$threeSixtyCtrl',
			controller: FcaThreeSixtyController,
			scope: {
				images: '=',
				color: '=',
				reverse: '=',
				analyticsIdToBind: '@',
				analyticsValueToBind: '@',
				analyticsCategoryToBind: '@',
				isLegacyMode: '<?',
				vehicleDescription: '@'
			},
		};
	}]);

	function FcaThreeSixtyController($scope, $document, $element, $window, gtmAnalytics) {
		'ngInject';

		const $ctrl = this;

		let img;
		let currentFrame = 31;
		let endFrame;
		let ticker = 0;
		let totalFrames;
		let loadedImages;
		let frames = [];
		let ready = false;
		let dragging;
		let pointerEndPosX;
		let pointerStartPosX;
		let pointerDistance;
		let monitorStartTime = 0;
		let monitorInt = 0;
		let speedMultiplier = 20;

		$ctrl.$onInit = () => {
			initImages();

			$scope.$on('threesixty.rotate', (event, direction) => {
				$ctrl.changeFrame(direction);
			});

			$scope.$on('$destroy', function () {
				$document.off('touchmove mousemove', mousemove);
				$document.off('touchend mouseup', mouseup);
				angular.element($window).off('resize', adjustHeight);

			});

			// Update images on model change
			// only if image list changes
			$scope.$watchCollection('images',
				function (newImageList, oldImageList) {

					if (newImageList.length != oldImageList.length) {
						initImages();
					} else {
						for (let i = 0; i < oldImageList.length; i++) {
							if (newImageList[i] !== oldImageList[i]) {
								initImages();
								break;
							}
						}
					}

				}
			);

			angular.element($window).on('resize', function () {
				if (totalFrames > 1) {
					adjustHeight();
				}
			});
		};

		$ctrl.changeFrame = (direction) => {
			endFrame = currentFrame + direction;
			refresh();
		};

		$ctrl.getAnalyticsId = () => {
			return $scope.analyticsIdToBind;
		};

		let adjustHeight = function () {

			if (loadedImages > 0) {
				let elementW;
				let imageW;
				let h;

				if (navigator.userAgent.indexOf('MSIE') !== -1
					|| navigator.appVersion.indexOf('Trident/') > -1) {
					if (frames.length === 1) {
						elementW = $element[0].offsetWidth;
						imageW = frames[0].width;
						h = frames[0].height * (elementW / imageW);
						$element.css('height', h + 'px');
					}
				} else {
					elementW = $element[0].offsetWidth;
					imageW = frames[0].width;
					h = frames[0].height * (elementW / imageW);
					$element.css('height', h + 'px');
				}

			}
		};

		let getImagesContainer = function () {
			return $element.find('.js-fca-threesixty-images-container');
		};


		let load360Images = function () {
			let alternateText = ($scope.vehicleDescription ? $scope.vehicleDescription : '');

			for (var i = 1; i < $scope.images.length; i++) {
				img = new Image();
				img.onload = imageReady;
				getImagesContainer().append(img);
				frames[i] = img;
				img.src = $scope.images[i];
				img.alt = alternateText;
			}

        };

		let showWaitingAnimation = () => {
			$('.E360_jelly-loading-animation').addClass('inload');
			$element.addClass('loading-images');
		}

		let hideWaitingAnimation = () => {
			$('.E360_jelly-loading-animation').removeClass('inload');
			$element.removeClass('loading-images');
		}

		let imageReady = function (event) {
			loadedImages++;
			if (loadedImages === totalFrames) {
				ready = true;
				// start
				endFrame = totalFrames;

				// Remove fca-loading
				hideWaitingAnimation();

				if (totalFrames > 1) {
					$element.css('padding-bottom', '0');
				}
			}
		};

		let firstImageReady = function () {

			// Remove previous images.
			$element.find('img').remove();
			loadedImages++;
			let firstImage = frames[0];

			adjustHeight();
			getImagesContainer().append(firstImage);
			$element.removeClass('loading-first');
			load360Images();

			if (getNormalizedCurrentFrame() !== 4) {
				hidePreviousFrame();
				showCurrentFrame();
				adjustHeight();
			} else {
				frames[4].className = 'current E360_ExteriorOptions-jellyImg -source';
			}
		};

		let onlyfirstImageReady = function () {

			// Remove previous images.
			$element.find('img').remove();
			loadedImages++;
			let firstImage = frames[0];

			// Add isLegacy class to adjust height + add current class
			firstImage.className = 'current E360_ExteriorOptions-jellyImg -source img-is-legacy';
			$element.css('height', 'auto');

			// adjustHeight();
			getImagesContainer().append(firstImage);
			$element.removeClass('loading-first');

			// Remove fca-loading
			hideWaitingAnimation();
		};

		let initImages = function () {
			let promptRef = $('.E360-slider-prompt');
			$element.addClass('loading-first');

			// Display fca-loading
			showWaitingAnimation();

			frames = [];
			totalFrames = $scope.images.length;
			loadedImages = 0;
            let alternateText = ($scope.vehicleDescription ? $scope.vehicleDescription : '');

			if (totalFrames > 1) {
				// Load first image
				img = new Image();
				img.onload = firstImageReady;
				img.src = $scope.images[0];
				img.alt = alternateText;
				frames.push(img);

			} else {

				if (promptRef && promptRef.css('display') !== 'none') {
					promptRef.css('display', 'none');
				}

				img = new Image();
				img.onload = onlyfirstImageReady;
				img.src = $scope.images[0];
				frames.push(img);
			}

			if (totalFrames > 1) {
				$element.css('padding-bottom', '38.5%');
			}
		};

		let refresh = function () {
			if (ticker === 0) {
				ticker = setInterval(render, Math.round(1000 / 30));
			}
		};

		let getNormalizedCurrentFrame = function () {
			let c = -Math.ceil(currentFrame % totalFrames);
			if (c < 0) {
				c += (totalFrames - 1);
			}
			return c;
		};

		let hidePreviousFrame = function () {
			frames[getNormalizedCurrentFrame()].className = '';
		};

		let showCurrentFrame = function () {
			frames[getNormalizedCurrentFrame()].className = 'current E360_ExteriorOptions-jellyImg -source';
		};

		let render = function () {
			if (frames.length > 0 && currentFrame !== endFrame) {
				let frameEasing = endFrame < currentFrame ?
					Math.floor((endFrame - currentFrame) * 0.1) :
					Math.ceil((endFrame - currentFrame) * 0.1);

				hidePreviousFrame();
				currentFrame += frameEasing;
				showCurrentFrame();

			} else {
				$window.clearInterval(ticker);
				ticker = 0;
			}
		};

		// Touch and Click events ->

		let getPointerEvent = function (event) {
			return event.targetTouches ? event.targetTouches[0] : event;
		};

		$element.on('touchstart mousedown', mousedown);

		function mousedown(event) {

			$('body').addClass('prevent-scroll-x');

			if ($(window).width() > 1024) {
				event.preventDefault();
			}

			pointerStartPosX = getPointerEvent(event).pageX;
			dragging = true;

			$document.on('touchmove mousemove', mousemove);
			$document.on('touchend mouseup', mouseup);
		};

		function trackPointer(event) {
			if (ready && dragging) {

				pointerEndPosX = getPointerEvent(event).pageX;

				let direction = $scope.reverse ? -1 : 1;

				if (monitorStartTime < new Date().getTime()
					- monitorInt) {
					pointerDistance = pointerEndPosX - pointerStartPosX;
					endFrame = currentFrame + Math.ceil(
						(totalFrames - 1) * direction * speedMultiplier
						* (pointerDistance / 600));
					refresh();
					monitorStartTime = new Date().getTime();
					pointerStartPosX = getPointerEvent(event).pageX;
				}
			}
		}

		function mouseup(event) {
			if ($(window).width() > 1024) {
				event.preventDefault();
			}
			dragging = false;
			$document.off('touchmove mousemove', mousemove);
			$document.off('touchend mouseup', mouseup);

			// Send Analytics Once Experience Done
			if ($scope.analyticsIdToBind && totalFrames > 1) {
				sendGtmTrackEvent();
			}

			$('body').removeClass('prevent-scroll-x');
		}

		function mousemove(event) {

			if ($(window).width() > 1024) {
				event.preventDefault();
			}
			trackPointer(event);
		}

		function sendGtmTrackEvent() {
			gtmAnalytics.trackEvent('event', {
				category: $scope.analyticsCategoryToBind,
				label: $scope.analyticsIdToBind
					+ $scope.analyticsValueToBind
			});
		}
	}

})();
