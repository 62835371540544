(function () {
	angular
		.module('fca.partial')
		.service('fcaPartialService',
			['$location', '$http', '$window', ($location, $http, $window) => {
				return new FcaPartialService($location, $http, $window);
			}]);

	class FcaPartialService {

		constructor($location, $http, $window) {
			this.$location = $location;
			this.$http = $http;
			this.$window = $window;
		}

		registerPartialController(zoneName, controller) {
			// We need to register the partial controllers so that they stay accessible
			// to the partial itself for ex close integration.
			let partialRepository = this.$window.partialRepository || {};
			partialRepository[zoneName] = controller;
			this.$window.partialRepository = partialRepository;
		}

		findPartialController(zoneName) {
			let partialRepository = this.$window.partialRepository || {};
			return partialRepository[zoneName];
		}

		getZoneContent(zoneName, success, error) {
			let path = this.$location.path();
			path += '/partial/zone/' + zoneName;

			this.getContent(path, success, error);
		}

		getBlockContent(blockId, success, error) {
			let path = this.$location.path();
			path += '/partial/block/' + blockId;

			this.getContent(path, success, error);
		}

		getContent(path, success, error) {
			this.$http({
				method: 'GET',
				url: path
			}).then((response) => {
				let content = response.data;
				let status = response.status;

				if (status === 200 && content !== null) {
					success(content);
				} else {
					error("Error retrieving Partial content with path " + path +
						" : " + (status || '') + " response " + (content || ''))
				}
			}).catch((data, status, headers, config) => {
				error('Error retrieving Partial ' + path);
			});
		}
	}

})();

