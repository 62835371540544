(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaPano', fcaPano);

	function fcaPano() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'fcaPano',
			controller: fcaPanoController
		};

		function fcaPanoController($element, $scope, $rootScope, $window, $timeout) {
			'ngInject';

			const $ctrl = this;
			$ctrl.isVideoDone = false;
			$ctrl.isVideoPristine = true;

			//TODO: Still need to display the correct video on resize. Currently it's only on init.
			$ctrl.isMobile = $window.matchMedia("(max-width: 767px)").matches;
			$ctrl.isTablet = $window.matchMedia('(min-width:768px) and (max-width: 1023px)').matches;
			$ctrl.isDesktop = $window.matchMedia('(min-width:1024px)').matches;

			let videoPlayedOnce = false;

			$ctrl.$onInit = () => {
				// Autoplay video when it's in view on page load
				$timeout(() => {
					$ctrl.playVideo();
				}, 500);
			};

			$rootScope.$on('onScrollingUpdate', () => {
				$ctrl.playVideo();
			});

			$ctrl.playVideo = () => {
				const parentElem = $($element[0]).parents('.vertical-scroll-block-container');
				const currentVideo = parentElem[0].querySelector('.AR-pano-video video');

				if (parentElem && currentVideo) {
					if ((parentElem[0].classList.contains('is-current')) && !videoPlayedOnce) {
						if (currentVideo.play) {
							const playPromise = currentVideo.play();

							if (playPromise !== undefined) {
								playPromise.then(function() {
									videoPlayedOnce = true;
									$ctrl.isVideoPristine = false;
									currentVideo.onended = () => {
										$ctrl.isVideoDone = true;
										$scope.$apply();
									};
								}).catch(function(error) {
									// Video playback failed.
									console.error('Error playing the video. Fallback to overlay CTA instead: ', error);
								});
							}
						}
					}
				}
			};

			$scope.$on("onVideoPlay", () => {
				$ctrl.isVideoDone = false;
				$scope.$apply();
			});

			$scope.$on("onVideoPause", () => {
				$ctrl.isVideoDone = true;
				$scope.$apply();
			});
		}
	}
})();
