/* eslint-disable indent */
(function () {

	angular
		.module('fca.buyOnline.calculator')
		.directive('fcaBuyOnlineCalculator', fcaBuyOnlineCalculator);

	function fcaBuyOnlineCalculator() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				calculatorData: '<',
				dealerPrice: '<',
				netAmount: '<',
				isActive: '@',
				paymentData: '@',
				vin: '@',
				sniOverviewController: '<'
			},
			controllerAs: '$buycalc',
			controller: FcaBuyOnlineCalculator
		};

		function FcaBuyOnlineCalculator($scope, $rootScope, fcaBuyOnlineService, configService, $filter, $timeout, $element) {
			'ngInject';

			let $buycalc = this;

			$buycalc.$onInit = () => {
				let data = angular.fromJson($buycalc.calculatorData);

				// make sure we are told when the buy object is updated
				fcaBuyOnlineService.registerListener($buycalc.buyObjectUpdated);
				// retrieve the trade-in
				$buycalc.checkout = fcaBuyOnlineService.getFromStorage($buycalc.vin);

				// make sure we keep the dealer price / net amount / msrp
				$buycalc.checkout.msrp = data.msrp;
				// dealer price
				delete $buycalc.checkout.dealerPrice;
				if ($buycalc.dealerPrice) {
					$buycalc.checkout.dealerPrice = $buycalc.dealerPrice;
				}
				// net amount
				delete $buycalc.checkout.netAmount;
				if ($buycalc.netAmount) {
					$buycalc.checkout.netAmount = $buycalc.netAmount;
				}
				// update the checkout object
				fcaBuyOnlineService.setInStorage($buycalc.vin, $buycalc.checkout);

				$buycalc.isActive = false;
				$buycalc.isVisibleOnpageLoad = false;
				$buycalc.basePrice = data.msrp;
				$buycalc.freight = data.freight;
				$buycalc.acTax = data.acTax;
				$buycalc.greenLevy = data.greenLevy;
				$buycalc.dealerAdminFee = data.dealerAdminFee;
				$buycalc.gkrp = data.gkrp;
				$buycalc.incentives = data.incentives;
				$buycalc.options = data.options;
				$buycalc.modelYearId = data.modelYearId;
				$buycalc.year = data.year;
				$buycalc.acode = data.acode;
				$buycalc.packageAndOptionsCode = data.packageAndOptionsCode;
				// default to best payment (use the value in checkout if there's one)
				$buycalc.useBestRate = $buycalc.checkout.useBestRate || false;

				$buycalc.dealerPrice = data.dealerPrice;
				// default to finance (use the value in checkout if there's one)
				$buycalc.financeMode = $buycalc.checkout.financeMode || 'finance';

				// set the down payment according to the finance mode
				if ($buycalc.financeMode === 'lease') {
					$buycalc.downPayment = data.leaseDownPayment;
				} else {
					// we don't keep the cash down payment separately
					$buycalc.downPayment = data.financeDownPayment;
				}

				$buycalc.paymentFrequency = $buycalc.checkout.frequency;

				// if we have a down-payment in-session, use it
				$buycalc.downPayment = $buycalc.checkout.downPayment || $buycalc.downPayment;

				// if we have lease or finance terms in the checkout object, use them
				$buycalc.leaseTerm = $buycalc.checkout.leaseTerm;
				$buycalc.financeTerm = $buycalc.checkout.financeTerm;

				// set it in the overview controller too
				if ($buycalc.sniOverviewController) {
					$buycalc.sniOverviewController.currentFinanceMode = $buycalc.financeMode;
				}

				// cash program
				$buycalc.cash = data.cash;

				// finance programs
				$buycalc.finance = data.finance;
				$buycalc.altFinance = data.altFinance;

				// lease programs
				$buycalc.lease = data.lease;
				$buycalc.altLease = data.altLease;

				// set the trade in value from the checkout object
				$buycalc.tradeInValue = $buycalc.checkout.tradeInValue || 0;
				$buycalc.tradeOwed = $buycalc.checkout.tradeOwed || 0;

				// now we're ready
				$buycalc.ready = true;

				// expose ourself to window.fca namespace (debug purpose mostly)
				window.fca = window.fca || {};
				window.fca.buycalc = $buycalc;

				$buycalc.checkCalculatorVisibilityOnPageLoad();

				$rootScope.$on('new-inventory:close-calculator', $buycalc.cancel);
			};

			/**
			 * Safe scope apply
			 */
			$buycalc.scopeApply = () => {
				$timeout(() => {
					$scope.$apply();
				});
			};

			$buycalc.cancel = () => {
				// Do cancel action
				$buycalc.hideOverlay();
				$buycalc.scopeApply();
			};

			/**
			 * payment data: {
			 *	'cashNetAmount'
			 *	'netAmount'
			 *	'currentRates': {
			 *		finance: {
			 *		apr: 2.49,
			 *			eir: 3.13,
			 *			duration: 96
			 *	    },
			 *	    lease: {
			 *		    apr: 5.49
			 *		    eir: 6.28
			 *		    duration: 60
			 *	    }
			 *  }
			 *	'financePayment'
			 *	'financeRate'
			 *	'financeTerm'
			 *	'financeNet'
			 *	'financeDiscounts'
			 *  'frequency'
			 *	'leasePayment'
			 *	'leaseResidualValue'
			 *	'leaseTerm'
			 *	'leaseInterestRate'
			 *	'leaseMileage'
			 *	'leaseNet'
			 *	'leaseDiscounts'
			 *  'discounts'
			 *  'hasHybridIncentives'
			 *  'disclaimer'
			 *  'totalDiscount'
			 *  'downPayment'
			 *  'tradeInValue'
			 *  'tradeOwed'
			 *  'useBestRate'
			 *  'hasAltFinance'
			 *  'hasAltLease'
			 *  'hasLease'
			 *  }
			 *
			 * @param paymentData
			 */
			$buycalc.paymentUpdated = (paymentData) => {
				$buycalc.paymentData = paymentData;

				let checkout = fcaBuyOnlineService.getFromStorage($buycalc.vin);

				// update check-out object if necessary
				let update = $buycalc.apply(paymentData, checkout,
					['tradeInValue',
						'tradeOwed',
						'frequency',
						'downPayment',
						'leaseTerm',
						'leasePayment',
						'leaseNet',
						'leaseResidualValue',
						'leaseMileage',
						'financeTerm',
						'financePayment',
						'financeNet',
						'useBestRate',
						'financeMode',
						'frequency',
						'totalDiscount']);

				// keep the finance rate
				if (paymentData.currentRates) {
					if (paymentData.currentRates.finance) {
						if (checkout.financeRate !== paymentData.currentRates.finance.apr) {
							checkout.financeRate = paymentData.currentRates.finance.apr;
							update = true;
						}
						if (checkout.financeRateEir !== paymentData.currentRates.finance.eir) {
							checkout.financeRateEir = paymentData.currentRates.finance.eir;
							update = true;
						}
					}
				}

				// keep the lease rate
				if (paymentData.currentRates) {
					if (paymentData.currentRates.lease) {
						if (checkout.leaseRate !== paymentData.currentRates.lease.apr) {
							checkout.leaseRate = paymentData.currentRates.lease.apr;
							update = true;
						}
						if (checkout.leaseRateEir !== paymentData.currentRates.lease.eir) {
							checkout.leaseRateEir = paymentData.currentRates.lease.eir;
							update = true;
						}
					}
				}

				if (paymentData.financeMode !== $buycalc.financeMode) {
					$buycalc.financeMode = paymentData.financeMode;
					// switch the sni controller
					$buycalc.sniOverviewController.currentFinanceMode = paymentData.financeMode;
				}

				// only update the object if necessary
				if (update) {
					$buycalc.checkout = checkout;
					fcaBuyOnlineService.setInStorage($buycalc.vin, $buycalc.checkout);
				}

				// if we have trade-in, down-payment or trade-owed, and we are showing lease/finance
				if (($buycalc.paymentData.tradeInValue || 0) > 0
					|| ($buycalc.paymentData.tradeOwed || 0) > 0
					|| ($buycalc.paymentData.downPayment || 0) > 0) {
					$buycalc.showPaymentInfo = $buycalc.financeMode === 'lease' || $buycalc.financeMode === 'finance';
				}
			};

			/**
			 * Apply properties from object1 to object2
			 *
			 * @param object1
			 * @param object2
			 * @param properties
			 * @returns {boolean}
			 */
			$buycalc.apply = (object1, object2, properties) => {
				let changed = false;
				if (properties && properties.length > 0) {
					properties.forEach(property => {
						if (object1[property] !== object2[property]) {
							object2[property] = object1[property];
							changed = true;
						}
					});
				}
				return changed;
			};

			$buycalc.showOverlay = () => {
				// Note: fixed elements will also need the margin adjustment (like a fixed header, if you have one).
				let scrollBarWidth = window.innerWidth - document.body.offsetWidth;
				$('html')
					.css('margin-right', scrollBarWidth)
					.addClass('C_NID_OL-showing-modal');

				$('body').addClass('noScroll');

				$buycalc.isActive = true;

				$timeout(() => {
					$rootScope.$broadcast('calculator:refresh-focus-trap');
				});
			};

			$buycalc.hideOverlay = () => {
				$buycalc.overlayVisible = false;

				$('html')
					.css('margin-right', '')
					.removeClass('C_NID_OL-showing-modal');

				$('body').removeClass('noScroll');
				$buycalc.isActive = false;
			};

			$buycalc.buyObjectUpdated = (checkout) => {
				if (checkout !== $buycalc.checkout) { // if it's the same object, we did the update
					// if the checkout contains an owe on trade, use it
					if (checkout.hasOwnProperty('tradeOwed')) {
						$buycalc.tradeOwed = checkout.tradeOwed;
					}

					// if the checkout contains a trade in value, use it
					if (checkout.tradeInValue != null) {
						$buycalc.tradeInValue = checkout.tradeInValue;
					}

					// now update our copy of the checkout object
					$buycalc.checkout = checkout;

					if ($buycalc.calculator) {
						$buycalc.calculator.tradeValue = $buycalc.tradeInValue;
						$buycalc.calculator.tradeOwed = $buycalc.tradeOwed;
						$buycalc.calculator.calculatePayments();
					}
				}
			};

			$buycalc.setFinanceMode = (financeMode) => {
				$buycalc.financeMode = financeMode;

				// switch the sni overview controller
				if ($buycalc.sniOverviewController) {
					$buycalc.sniOverviewController.currentFinanceMode = financeMode;
				}
				// switch the active tab in the calculator
				if ($buycalc.calculator) {
					$buycalc.calculator.changeActiveTab(financeMode);
				}
				// update the checkout object
				$buycalc.checkout = fcaBuyOnlineService.getFromStorage($buycalc.vin);
				$buycalc.checkout.financeMode = financeMode;
				fcaBuyOnlineService.setInStorage($buycalc.vin, $buycalc.checkout);
			};

			$buycalc.calculatorHook = (calculatorCtrl) => {
				$buycalc.calculator = calculatorCtrl;
			};

			$buycalc.checkCalculatorVisibilityOnPageLoad = () => {
				$timeout(() => {
					let isCalculatorVisible = $element.attr('data-calculator-visible');

					if (isCalculatorVisible && isCalculatorVisible === 'true') {
						$buycalc.isVisibleOnpageLoad = true;
						$buycalc.isActive = true;
					}
				}, 500);
			}
		}
	}
})();
