(function() {
	angular
		.module('fca.uiPanels')
		.component('dealerHours', {
			templateUrl: '/ui-panels/dealer-hours/dealer-hours.html',
			bindings: {
				openingHours: '<',
				isOpenNow: '<',
				analyticsCategory: '@',
				analyticsId: '@',
				analyticsValue: '@'
			},
			controller: dealerHoursController
		});

	function dealerHoursController() {
		'ngInject';

		this.hoursOpen = false;

		this.$onChanges = () => {
			if (this.openingHours) {
				for (let i = 0; i < this.openingHours.length; ++i) {
					if (this.openingHours[i].isToday) {
						this.todayIndex = i;
						break;
					}
				}

				for (let x = 0; x < this.openingHours.length; ++x) {
					if (this.openingHours[x].isToday) {
						this.todayHours = this.openingHours[x];
						break;
					}
				}
			}
		};

		this.openHours = ($event) => {
			$event.preventDefault();
			// Change open hours status
			this.hoursOpen = !this.hoursOpen;
		};


	}
})();
