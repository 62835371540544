// Directive to wrap slick carousel
// Allows for callbacks and other arguments need to be passed to slick to be defined in
// this directive's controller instead of higher up in application

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselStory', fcaSlickCarouselStory);

	function fcaSlickCarouselStory() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselStoryController,
			link: FcaSlickCarouselStoryLink
		};

		function FcaSlickCarouselStoryController() {}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselStoryLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
