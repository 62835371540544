(function () {
	angular
		.module("alfaromeo.videoPlayer")
		.component("alfaVideoPlayer", {
			controller: alfaVideoPlayer,
			controllerAs: "$ctrl",
			templateUrl: "/brand-specific/alfaromeo/components/video-player/alfaromeo-video-player.html",
			bindings: {
				url: "@",
				crossorigin: "@",
				poster: "@",
				autoplay: "<",
				controls: "<",
				loop: "<",
				muted: "<",
				preload: "@",
				getCtrl: "<",
				onEnd: "<",
				onPlay: "<",
				onPause: "<",
				onFullscreen: "<",
				onVolumechange: "<"
			}
		});

	function alfaVideoPlayer($sce, $element) {
		"ngInject";

		let video;
		const $ctrl = this;
		$ctrl.url = null;

		$ctrl.trustSrc = () => {
			return $sce.trustAsResourceUrl($ctrl.url);
		};

		$ctrl.$onInit = () => {
			// save video element instance
			video = $element.find('video')[0];

			// call parent binding to expose this controller
			if( $ctrl.getCtrl && typeof $ctrl.getCtrl === 'function' ) $ctrl.getCtrl($ctrl);
		};


		// public API
		$ctrl.play = () => {
		// if video element doesn't exist, return
			if( !video ) {
				return;
			}

			video.play();
		};
		$ctrl.pause = () => {
			// if video element doesn't exist, return
			if( !video ) {
				return;
			}

			video.pause();
		};


		$ctrl.handleEnd = (event) => {
			if( $ctrl.onEnd && typeof $ctrl.onEnd === 'function' ) $ctrl.onEnd($ctrl);
		};
		$ctrl.handlePlay = (event) => {
			if( $ctrl.onPlay && typeof $ctrl.onPlay === 'function' ) $ctrl.onPlay($ctrl);
		};
		$ctrl.handlePause = (event) => {
			if( $ctrl.onPause && typeof $ctrl.onPause === 'function' ) $ctrl.onPause($ctrl);
		};
		$ctrl.handleFullscreen = (event, isFullscreen) => {
			if( $ctrl.onFullscreen && typeof $ctrl.onFullscreen === 'function' ) $ctrl.onFullscreen($ctrl, isFullscreen);
		};
		$ctrl.handleVolumeChange = (event, volume, muted) => {
			if( $ctrl.onVolumechange && typeof $ctrl.onVolumechange === 'function' ) $ctrl.onVolumechange($ctrl, muted === true ? 0 : volume);
		};
	}
})();
