(function() {
	angular
		.module('fca.core.main')
		.component('fcaCampaignTracker', {
			bindings: {
				trackerPath: '@',
				domains: '<'
			},
			controller: FcaCampaignTracker,
			template: getTemplate()
		});

	function FcaCampaignTracker($location, CampaignTracker) {
		'ngInject';

		this.$onInit = () => {
			let urlParams = $location.search();

			this.timestamp = new Date().getTime();
			this.hasCampaign = urlParams.utm_campaign;
			this.currentCampaign = {};

			if (this.hasCampaign) {
				this.currentCampaign.utmCampaign = urlParams.utm_campaign || '';
				this.currentCampaign.utmSource = urlParams.utm_source || '';
				this.currentCampaign.utmMedium = urlParams.utm_medium || '';
				this.currentCampaign.utmContent = urlParams.utm_content || '';
				this.currentCampaign.utmAudience = urlParams.utm_audience || '';
				CampaignTracker.addCampaign(this.currentCampaign);
			}
		};

		this.test = () => {
			CampaignTracker.getCampaigns();
		};

		this.buildParams = () => {
			let params = '';

			// utm_campaign is required, all other params are optional
			if (this.hasCampaign) {
				params = `?utm_campaign=${this.currentCampaign.utmCampaign}`;
				params += `&utm_source=${this.currentCampaign.utmSource}`;
				params += `&utm_medium=${this.currentCampaign.utmMedium}`;
				params += `&utm_content=${this.currentCampaign.utmContent}`;
				params += `&utm_audience=${this.currentCampaign.utmAudience}`;
				params += `&ck=${this.timestamp}`;
			}

			return params;
		};
	}

	function getTemplate(context) {
		let template = `
		<div class="campaign-tracker-container"
		     style="display:none"
		     data-ng-if="$ctrl.hasCampaign">
			<img ng-repeat="domain in ::$ctrl.domains"
				 ng-src="{{domain}}{{::$ctrl.trackerPath}}{{$ctrl.buildParams()}}"
				 width="0"
				 height="0">
		</div>
		`;

		return template;
	}
})();
