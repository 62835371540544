(function () {
	angular
		.module('fca.miniBuildAndPrice.swatch')
		.component('rowMiniBp', {
			templateUrl: '/shared/fca-mini-build-and-price/row/row-mini-bp.html',
			bindings: {
				url: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				name: '@',
				price: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('swatchMiniBp', {
			templateUrl: '/shared/fca-mini-build-and-price/swatch/swatch-mini-bp.html',
			bindings: {
				colorCode: '@',
				label: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				url: '@',
				name: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('thumbnailMiniBp', {
			templateUrl: '/shared/fca-mini-build-and-price/thumbnail/thumbnail-mini-bp.html',
			bindings: {
				big: '<',
				label: '@',
				url: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				showAdd: '<',
				withBorder: '<',
				name: '@',
				alt: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('tileMiniBp', {
			templateUrl: '/shared/fca-mini-build-and-price/title/title-mini-bp.html',
			bindings: {
				big: '<',
				description: '@',
				features: '<',
				price: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				url: '@',
				name: '@',
				category: '@',
				placeholderImage: '@'
			}
		});
})();
