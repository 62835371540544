(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaScrollTo', alfaScrollTo);

	function alfaScrollTo($window, $rootScope, $timeout) {
		'ngInject';

		return {
			restrict: 'A',
			scope: true,
			link: function($scope, $element) {
				let target = $element.data('scrollTarget');
				let groupId = $element.data('miniNavGroupId') || null;
				let speed = $element.data('scrollSpeed') || 200;

				$element.on('click', function(event) {
					$('[data-alfa-mini-nav-item]').removeClass('is-active');

					event.preventDefault();

					$rootScope.$broadcast('alfaScrollTo.active', groupId);

					if ($(event.target.offsetParent).hasClass('legal-tooltip-label')) {
						return;
					}

					history.pushState({}, '', $element.attr('href'));

					// Animate the scroll to the destination
					$('html, body').animate(
						{
							scrollTop: $(`#${target}`).offset().top - getOffset()// Scroll to this location.
						}, {
							duration: speed,

							// At each animation step, check whether the target has moved.
							step: function( now, fx ) {

								// Where is the target now located on the page?
								let newOffset = $(`#${target}`).offset().top - getOffset();

								if(fx.end !== newOffset)
									fx.end = newOffset;
							}
						}
					);
				});

				function getOffset() {
					let $mainNavHeight = $('.main-nav').outerHeight();
					let $miniNavHeight = $('.AR_mini-nav').outerHeight();
					return $mainNavHeight + $miniNavHeight;
				}
			},
			controller: alfaScrollToController
		};

		function alfaScrollToController($location, $element, $anchorScroll) {
			'ngInject';

			let hashValue;

			this.$onInit = () => {
				hashValue = $location.hash();
				this.goToHashAnchor();
				this.updateGTA();
			};

			/**
			 * Update the Gtmanalytics to add en event when we load the page
			 * via a target hash link.
			 */
			this.updateGTA = () => {
				if($element.attr('data-scroll-target') == hashValue) {
					let hashLinkTxt = $element.attr('data-mini-nav-group-id').trim();
					let gtmOptions = angular.extend({'pagesubsection': hashLinkTxt}, {}, {});
					dataLayer.push(gtmOptions);
				}
			};

			this.goToHashAnchor = () => {
				if($element.attr('data-scroll-target') == hashValue) {
					$anchorScroll.yOffset = getOffset();

					$anchorScroll(hashValue);
				}
			};

			function getOffset() {
				let $mainNavHeight = $('.main-nav').outerHeight();
				let $miniNavHeight = $('.AR_mini-nav').outerHeight();
				return $mainNavHeight + $miniNavHeight;
			}
		}
	}
})();
