(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaVehiclesLineup', FcaVehiclesLineup);

	function FcaVehiclesLineup() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$vehiclesLineupCtrl',
			controller: FcaVehiclesLineupController,
			bindToController: {
				prevArrowLabel: '@',
				nextArrowLabel: '@',
				brandCode: '@',
				irisWidth: '@?',
				irisX: '@?',
				irisH: '@?'
			}
		};

		function FcaVehiclesLineupController(fcaPartialService, $element, $timeout, externalConfigLoader) {
			'ngInject';

			const $vehiclesLineupCtrl = this;
			const irisCodeForBrand = {
				'chrysler': 'C',
				'dodge': 'D',
				'jeep': 'J',
				'ramtruck': 'R',
				'fiat': 'X',
				'alfaromeo': 'Y'
			};

			let config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
			let irisRootURl = config.getConfig('irisJellyBaseUrl') + '/mediaserver/iris?client=FCAUS&market=U';
			let irisWidth;
			let irisH;
			let irisY;
			let irisBrandCode;

			$vehiclesLineupCtrl.currentZone = '';
			$vehiclesLineupCtrl.pageContent = '';

			$vehiclesLineupCtrl.mainSlider = $element.find('[data-vehicles-lineup-slider]');
			$vehiclesLineupCtrl.totalSlides = false;
			$vehiclesLineupCtrl.currentSlide = 0;
			$vehiclesLineupCtrl.slideNextOne = false;
			$vehiclesLineupCtrl.slideNextTwo = false;
			$vehiclesLineupCtrl.slideNextThree = false;
			$vehiclesLineupCtrl.slideNextFour = false;
			$vehiclesLineupCtrl.slidePrevOne = false;
			$vehiclesLineupCtrl.slidePrevTwo = false;
			$vehiclesLineupCtrl.slidePrevThree = false;
			$vehiclesLineupCtrl.slidePrevFour = false;

			$vehiclesLineupCtrl.$onInit = () => {
				irisWidth = $vehiclesLineupCtrl.irisWidth || "1920";
				irisH = $vehiclesLineupCtrl.irisH || "7000";
				irisY = $vehiclesLineupCtrl.irisY || "2000";
				irisBrandCode = $vehiclesLineupCtrl.getIrisBrandByBrandCode($vehiclesLineupCtrl.brandCode);

				$timeout(() => {
					$vehiclesLineupCtrl.initSlider();
				});
			}

			$vehiclesLineupCtrl.getIrisBrandByBrandCode = (brandCode) => {
				let result = "";

				if (brandCode) {
					result = irisCodeForBrand[brandCode];
				}
				return result;
			};

			$vehiclesLineupCtrl.initSlider = () => {

				if($vehiclesLineupCtrl.mainSlider) {
					$vehiclesLineupCtrl.mainSlider.slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						fade: true,
						centerMode: true,
						infinite: true,
						autoplay: false,
						prevArrow: $vehiclesLineupCtrl.buildArrow($vehiclesLineupCtrl.prevArrowLabel, 'fcaicon-chevron-left', '-prev'),
						nextArrow: $vehiclesLineupCtrl.buildArrow($vehiclesLineupCtrl.nextArrowLabel, 'fcaicon-chevron-right', '-next')
					});
				}
			}

			$vehiclesLineupCtrl.buildArrow = (ariaLabel, iconClass, btnClass) => {
				return `<button type="button" class="AS_VL-slider-btn ${btnClass} AS-btn-arrow" aria-label="${ariaLabel}">` +
					`<i class="fcaicon ${iconClass}" aria-hidden="true"></i></button>`;
			}

			$vehiclesLineupCtrl.goToSlide = (slideIndex) => {
				$vehiclesLineupCtrl.mainSlider.slick('slickGoTo', slideIndex, true);
			}

			$vehiclesLineupCtrl.defineSlidePosition = (currentSlide, slick) => {
				$timeout(() => {
					$vehiclesLineupCtrl.currentSlide = currentSlide;

					if ($vehiclesLineupCtrl.totalSlides > 1) {
						if (currentSlide + 1 < $vehiclesLineupCtrl.totalSlides) {
							$vehiclesLineupCtrl.slideNextOne = currentSlide + 1;

						} else {
							$vehiclesLineupCtrl.slideNextOne = currentSlide + 1 - $vehiclesLineupCtrl.totalSlides;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 2) {
						if (currentSlide - 1 < 0) {
							$vehiclesLineupCtrl.slidePrevOne = currentSlide - 1 + $vehiclesLineupCtrl.totalSlides;
						} else {
							$vehiclesLineupCtrl.slidePrevOne = currentSlide - 1;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 3) {
						if (currentSlide - 2 < 0) {
							$vehiclesLineupCtrl.slidePrevTwo = currentSlide - 2 + $vehiclesLineupCtrl.totalSlides;

						} else {
							$vehiclesLineupCtrl.slidePrevTwo = currentSlide - 2;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 4) {
						if (currentSlide + 2 < $vehiclesLineupCtrl.totalSlides) {
							$vehiclesLineupCtrl.slideNextTwo = currentSlide + 2;

						} else {
							$vehiclesLineupCtrl.slideNextTwo = currentSlide + 2 - $vehiclesLineupCtrl.totalSlides;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 5) {
						if(currentSlide - 3 < 0) {
							$vehiclesLineupCtrl.slidePrevThree = currentSlide - 3 + $vehiclesLineupCtrl.totalSlides;

						} else {
							$vehiclesLineupCtrl.slidePrevThree = currentSlide - 3;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 6) {
						if (currentSlide + 3 < $vehiclesLineupCtrl.totalSlides) {
							$vehiclesLineupCtrl.slideNextThree = currentSlide + 3;

						} else {
							$vehiclesLineupCtrl.slideNextThree = currentSlide + 3 - $vehiclesLineupCtrl.totalSlides;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 7) {
						if(currentSlide - 4 < 0) {
							$vehiclesLineupCtrl.slidePrevFour = currentSlide - 4 + $vehiclesLineupCtrl.totalSlides;

						} else {
							$vehiclesLineupCtrl.slidePrevFour = currentSlide - 4;
						}
					}

					if ($vehiclesLineupCtrl.totalSlides > 8) {
						if (currentSlide + 4 < $vehiclesLineupCtrl.totalSlides) {
							$vehiclesLineupCtrl.slideNextFour = currentSlide + 4;

						} else {
							$vehiclesLineupCtrl.slideNextFour = currentSlide + 4 - $vehiclesLineupCtrl.totalSlides;
						}
					}
				});
			};

			$vehiclesLineupCtrl.mainSlider.on('init reInit beforeChange', function(event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				let targetSlideIndex = nextSlide ? nextSlide : 0;
				let slideElement = $(slick.$slides[targetSlideIndex]);
				let slideZoneName = slideElement.data('zoneName');

				if(!$vehiclesLineupCtrl.totalSlides) {
					$vehiclesLineupCtrl.totalSlides = slick.slideCount;
				}

				$vehiclesLineupCtrl.defineSlidePosition(targetSlideIndex);
				$vehiclesLineupCtrl.getBodyContent(slideZoneName);
			});

			$vehiclesLineupCtrl.setCurrentZone = (zoneName) => {
				$vehiclesLineupCtrl.currentZone = zoneName;
			};

			$vehiclesLineupCtrl.getBodyContent = (zone) => {
				$vehiclesLineupCtrl.setCurrentZone(zone);
				let zoneName = $vehiclesLineupCtrl.currentZone;

				if (zoneName !== '') {

					fcaPartialService.getZoneContent(
						zoneName,
						(responseContent) => {
							$vehiclesLineupCtrl.pageContent = responseContent;
						},
						(errorMessage) => {
							console.log(errorMessage)
						});
				}
			};

			$vehiclesLineupCtrl.generateJellyUrl = (angle, irisVehicle, irisPaint, irisSa, irisFabric) => {
				// Construct the Iris url for the jelly.

				let url = irisRootURl +
					"&brand=" + irisBrandCode +
					"&vehicle=" + irisVehicle +
					"&paint=" + irisPaint +
					"&sa=" + irisSa +
					"&pov=" + angle +
					"&fabric=" + irisFabric +
					"&width=" + irisWidth +
					"&h=" + irisH +
					"&y=" + irisY;

				url += '&bkgnd=transparent&resp=png';

				return url;
			};
		}
	}
})();
