(function() {
	angular
		.module('fcaWaypoints')
		.directive('fcaSticky', fcaSticky);

	function fcaSticky() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				stickyId: '@',
				stickyClass: '@?',
				stickyGroup: '@?',
				stickyOffset: '@?',
				stickyOnStuckFn: '&?',
				stickyRefresh: '&?'
			},
			controller: FcaStickyController
		};

		function FcaStickyController($rootScope, $element, functionExecutionControl) {
			'ngInject';

			let activeScrollCheck = false;

			this.$onChanges = (changes) => {
				if (changes.stickyId.currentValue) {
					angular.element(document).ready(() => {
						// http://imakewebthings.com/waypoints/shortcuts/sticky-elements/
						new Waypoint.Sticky({
							element: $element[0],
							handler: (direction) => {
								if (this.stickyOnStuckFn) {
									this.stickyOnStuckFn(direction);
								}

								$rootScope.$emit('fcaSticky.something-stuck', {
									direction: direction,
									stickyId: this.stickyId
								});

								// Added this snippet to be able to self manage the height of a sticky container
								if($element.hasClass(this.stickyClass)) {
									$element.parent().addClass(this.stickyClass);

									activeScrollCheck = true;
								} else {
									$element.parent().removeClass(this.stickyClass);

									activeScrollCheck = false;
								}
							},
							stuckClass: this.stickyClass,	// defaults to 'stuck' in plugin
							group: this.stickyGroup,		// defaults to 'default' in plugin
							offset: this.stickyOffset		// defaults to '0' in plugin
						});
						
						if (this.stickyRefresh) {
							$(window).on('scroll', functionExecutionControl.throttle(() => {
								Waypoint.refreshAll();
							}, 500));
						}
					});
				}
			};
		}
	}
})();
