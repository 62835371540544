(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021Feature', vlp2021FeatureDirective);

	function vlp2021FeatureDirective() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				isExpandable: '<',
				analyticscategory: '@',
				analyticsid: '@',
				onTitle: '@'
			},
			controllerAs: 'vlpFeature',
			controller: vlp2021FeatureController,
		};

		function vlp2021FeatureController($element, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;
			$ctrl.index = 0;
			$ctrl.featureExpanded = false;

			$ctrl.$onInit = () => {
				if ($ctrl.isExpandable) {
					$element.find('.VLP2021-features-item__content-expandable').slideUp();
				} 
			};

			$ctrl.expandFeature = () => {
				$ctrl.featureExpanded = !$ctrl.featureExpanded;

				if ($ctrl.featureExpanded) {
					$element.find('.VLP2021-features-item__content-expandable').slideDown();
				} else {
					$element.find('.VLP2021-features-item__content-expandable').slideUp();
				}
			}
		}
	}
})();
