(function () {
	angular
	.module('fca.userAccount.myGarage')
	.directive('fcaUserAccountMyGarageListVehicles', ListVehicles);

	function ListVehicles() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				displayType: '@',
			},
			controllerAs: '$listVehiclesCtrl',
			controller: ListVehiclesCtrl
		};

		function ListVehiclesCtrl($scope, $rootScope, $window, $http) {

			const INVENTORY_MODE = 'inventory';
			const VIEWED_MODE = 'viewed';
			const CUSTOM_ORDER_MODE = 'custom_order';

			let $listVehiclesCtrl = this;

			$listVehiclesCtrl.vehicles = [];
			// This object contains all IDs to simplify how checkboxes work
			$listVehiclesCtrl.selectedIds = {};
			$listVehiclesCtrl.sliderActive = true;
			$listVehiclesCtrl.sliderDeactivated = false;

			$listVehiclesCtrl.$onInit = () => {
				$rootScope.$on('ua:libraryInitialized', () => {
					$listVehiclesCtrl.loadVehicles();
					if (!UA.isUserLoggedIn()) {
						$listVehiclesCtrl.sliderDeactivated = true;
					}
					$listVehiclesCtrl.checkVisibleSlides();
				});
			};

			$listVehiclesCtrl.loadVehicles = () => {
				let method = $listVehiclesCtrl.getVehiclesMethod();
				if (method) {
					method.then(vehicles => {
						Promise.all(
							vehicles.map(vehicle => $listVehiclesCtrl.getVehicleData(vehicle))
						).then(result => {
							$listVehiclesCtrl.vehicles = result.filter(vehicle => {
								if (vehicle === null) {
									return false;
								}

								$listVehiclesCtrl.selectedIds[vehicle.id] = false;

								return true;
							});

							$scope.$apply();

							$listVehiclesCtrl.checkVisibleSlides();
							$scope.$broadcast('vehicleListUpdated');
						});
					}).catch(err => {
						throw new Error('error fetching vehicles', err)
					});
				}
			};

			$listVehiclesCtrl.getVehiclesMethod = () => {
				let method;

				switch ($listVehiclesCtrl.displayType) {
					case INVENTORY_MODE:
						if (UA.isUserLoggedIn()) {
							method = UA.getInventoryVehicles();
						}
						break;
					case VIEWED_MODE:
						if (UA.isUserLoggedIn()) {
							method = UA.getViewedVehicles();
						} else {
							method = UA.getViewedVehiclesFromLocalStorage();
						}
						break;
					case CUSTOM_ORDER_MODE:
						if (UA.isUserLoggedIn()) {
							method = UA.getCustomOrderVehicles();
						}
						break;
					default:
						break;
				}

				return method;
			};

			$listVehiclesCtrl.removeVehiclesMethod = (vehicleIds) => {
				return $listVehiclesCtrl.removeVehiclesMethod(vehicleIds, false);
			};

			$listVehiclesCtrl.removeVehiclesMethod = (vehicleIds, sold) => {
				switch ($listVehiclesCtrl.displayType) {
					case CUSTOM_ORDER_MODE:
						if (UA.isUserLoggedIn()) {
							return UA.removeCustomOrderVehicles(vehicleIds);
						}
						break;
					case VIEWED_MODE:
						if (UA.isUserLoggedIn()) {
							return UA.removeViewedVehicles(vehicleIds);
						} else {
							return UA.removeViewedVehiclesFromLocalStorage(vehicleIds);
						}
					case INVENTORY_MODE:
					default:
						if (sold) {
							return UA.updateInventoryVehicle(vehicleIds[0], {sold: true});
						} else {
							return UA.removeInventoryVehicles(vehicleIds);
						}
				}
			};

			$listVehiclesCtrl.getVehicleData = vehicle => {
				const inventoryUrl = (
					`/data/inventories/${vehicle.brandCode}/` +
					`vehicle/${vehicle.vin.toUpperCase()}` +
					`?language=${FCA_SITES_CONFIG.language}`
				);

				return new Promise((resolve, _) => {
					if (!vehicle.vin) {
						return null;
					}

					$http.get(inventoryUrl).then(
						response => {
							vehicle.data = response.data;

							resolve(vehicle);
						},
						reason => {
							if (reason.status === 404) {
								//vehicle sold case
								$listVehiclesCtrl.removeVehiclesMethod([parseInt(vehicle.id)], true);
							}
							resolve(null);
						}
					)
				});
			};

			$listVehiclesCtrl.getSniDetailUrl = (vehicle, commonPath) => {
				return window.FCA_SITES_CONFIG.brandSiteUrls[vehicle.brandCode] + commonPath + vehicle.vin;
			};

			$listVehiclesCtrl.getCustomOrderVehicleUrl = (vehicle, commonPath) => {
				const subBrandOrBrandCode = vehicle.subBrandCode ? vehicle.subBrandCode : vehicle.brandCode;

				return window.FCA_SITES_CONFIG.brandSiteUrls[subBrandOrBrandCode]
					+ commonPath
					+ "/" + subBrandOrBrandCode
					+ "/" + vehicle.nameplateCode
					+ "/" + vehicle.year
					+ "/" + vehicle.modelYearId
					+ "/" + vehicle.acode
					+ "?customOrder=true"
					+ "#builtVehicleId=" + vehicle.id;
			};

			$listVehiclesCtrl.countSelected = () => {
				return Object.keys($listVehiclesCtrl.selectedIds).filter(key =>
					$listVehiclesCtrl.selectedIds[key] === true
				).length;
			};

			$listVehiclesCtrl.updateVehicle = (id, titleAndNotes) => {
				UA.updateInventoryVehicle(id, titleAndNotes).then((response) => {
					if (response) {
						$listVehiclesCtrl.vehicles.forEach(elem => {
							if (elem.id === id) {
								elem.title = response.title;
								elem.notes = response.notes;
								$scope.$apply();
							}
						});
					} else {
						// response is empty, that means this vehicle doesn't exist anymore
						$listVehiclesCtrl.loadVehicles();
					}
				})
				.catch(err => {
					throw new Error('error updating vehicle', err)
				});
			};

			$listVehiclesCtrl.removeVehicles = () => {
				let selectedVehicleIds = Object.keys($listVehiclesCtrl.selectedIds).filter(key => {
					return $listVehiclesCtrl.selectedIds[key];
				});

				selectedVehicleIds = selectedVehicleIds.map(elem => {
					return parseInt(elem);
				});

				$listVehiclesCtrl.removeVehiclesMethod(selectedVehicleIds).then(() => {
					$listVehiclesCtrl.selectedIds = {};

					$listVehiclesCtrl.loadVehicles();
				});
			};

			$listVehiclesCtrl.checkVisibleSlides = () => {
				let visibleSlides;
				let mobileBreakpoint = 667;
				let desktopBreakpoint = 1024;
				let windowWidth = $window.innerWidth;

				if (windowWidth < mobileBreakpoint) {
					visibleSlides = 1;
				} else if (windowWidth < desktopBreakpoint) {
					visibleSlides = 2;
				} else {
					visibleSlides = 3;
				}

				if ($listVehiclesCtrl.vehicles.length == 0 ||
					$listVehiclesCtrl.vehicles.length <= visibleSlides ||
					$listVehiclesCtrl.sliderDeactivated == true) {
					$listVehiclesCtrl.sliderActive = false;
				} else {
					$listVehiclesCtrl.sliderActive = true;
				}
			};

			angular.element($window).on('resize', function () {
				$listVehiclesCtrl.checkVisibleSlides();
			});

			$listVehiclesCtrl.showAll = () => {
				$listVehiclesCtrl.sliderDeactivated = true;
				$listVehiclesCtrl.checkVisibleSlides();
				$scope.$broadcast('destroySlider');
			}
		}
	}
})();
