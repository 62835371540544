/* eslint-disable indent */
(function () {
	angular
		.module('fca.kbb')
		.directive('fcaBuyOnlineKbb',
			() => ({
			restrict: 'A',
			scope: true,
			bindToController: {
				years: '@',
				year: '@',
				grades: '@',
				grade: '@',
				brands: '@',
				brand: '@',
				models: '@',
				model: '@',
				vehicles: '@',
				vehicle: '@',
				mileage: '@',
				tradeInValue: '@',
				haveTradeIn: '@',
				realMileage: '@',
				submitReady: '@',
				oweOnTrade: '@',
				owedApplied: '@',
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: [
				"$document",
				"$scope",
				"$rootScope",
				"externalConfigLoader",
				"userLocation",
				"fcaKbbService",
				"fcaBuyOnlineService",
				"fcaScrollToElementService",
				"$element",
				"fcaKeypress",
				($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaBuyOnlineService, fcaScrollToElementService, $element, fcaKeypress) => new FcaBuyOnlineKbbCtrl($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaBuyOnlineService, fcaScrollToElementService, $element, fcaKeypress)
			]
			})
		);

	angular
	.module('fca.kbb')
	.directive('fcaTradeInKbb',
		() => ({
			restrict: 'A',
			scope: true,
			bindToController: {
				years: '@',
				year: '@',
				grades: '@',
				grade: '@',
				brands: '@',
				brand: '@',
				models: '@',
				model: '@',
				vehicles: '@',
				vehicle: '@',
				mileage: '@',
				nextYear: '@',
				nextBrand: '@',
				nextModel: '@',
				firstname: '@',
				lastname: '@',
				email: '@',
				postalCode: '@',
				phoneNumber: '@',
				tradeInValue: '@',
				realMileage: '@',
				submitReady: '@',
				submitLeadReady: '@',
				vehicleInfo: '@'
			},
			controllerAs: '$ctrl',
			controller: [
				"$document",
				"$scope",
				"$rootScope",
				"externalConfigLoader",
				"userLocation",
				"fcaKbbService",
				"fcaScrollToElementService",
				"$element",
				"fcaKeypress",
				($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaScrollToElementService, $element, fcaKeypress) => new FcaTradeInKbbCtrl($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaScrollToElementService, $element, fcaKeypress)
			]
		})
	);

	angular
	.module('fca.kbb')
	.directive('fcaBuildAndPriceKbb',
		() => ({
			restrict: 'A',
			scope: true,
			bindToController: {
				years: '@',
				year: '@',
				grades: '@',
				grade: '@',
				brands: '@',
				brand: '@',
				models: '@',
				model: '@',
				vehicles: '@',
				vehicle: '@',
				mileage: '@',
				tradeInValue: '@',
				realMileage: '@',
				submitReady: '@'
			},
			controllerAs: '$ctrl',
			controller: [
				"$document",
				"$scope",
				"$rootScope",
				"externalConfigLoader",
				"userLocation",
				"fcaKbbService",
				"fcaScrollToElementService",
				"$element",
				"fcaKeypress",
				($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaScrollToElementService, $element, fcaKeypress) => new FcaBuildAndPriceKbbCtrl($document, $scope, $rootScope, externalConfigLoader, userLocation, fcaKbbService, fcaScrollToElementService, $element, fcaKeypress)
			]
		})
	);


		class FcaKbbCtrl {
			constructor($document,
						$scope,
						$rootScope,
						externalConfigLoader,
						userLocation,
						fcaKbbService,
						fcaScrollToElementService,
						$element,
						fcaKeypress) {
				this.$document = $document;
				this.$scope = $scope;
				this.$rootScope = $rootScope;
				this.externalConfigLoader = externalConfigLoader;
				this.userLocation = userLocation;
				this.fcaKbbService = fcaKbbService;
				this.fcaScrollToElementService = fcaScrollToElementService;
				this.$element = $element;
				this.fcaKeypress = fcaKeypress;
			}

			getYears() {
				this.fcaKbbService.getYears(this, this.gotYears, (error) => console.error(error));
			}

			getGrades() {
				this.fcaKbbService.getGrades(this, this.gotGrades, (error) => console.error(error));
			}

			getLocationStates() {
				this.fcaKbbService.getLocationStates(this.language, this, this.gotLocationStates, (error) => console.error(error));
			}

			getBrands() {
				this.brandsLoading = true; // show loader
				this.fcaKbbService.getBrands(this.year.Year, this, this.gotBrands, (error) => console.error(error));
			}

			getModels() {
				this.modelsLoading = true; // show loader
				this.fcaKbbService.getModels(this.year.Year, this.brand.ID, this, this.gotModels, (error) => console.error(error));
			}

			getVehicles() {
				this.vehiclesLoading = true; // show loader
				this.fcaKbbService.getVehicles(this.year.Year, this.model.ID, this.brand.ID, this, this.gotVehicles, (error) => console.error(error));
			}

			getOptions() {
				this.optionsLoading = true; // show loader
				this.fcaKbbService.getEquipment(this.vehicle.ID, this, this.gotOptions, (error) => console.error(error));
			}

			getPrices() {
				this.fcaKbbService.getPrices(this, this.gotPrices, (error) => console.error(error));
			}

			/**
			 * @param years list of years
			 */
			gotYears(years, fcaKbbCtrl) {
				fcaKbbCtrl.years = years;

				fcaKbbCtrl.checkIfReady();
			}

			/**
			 * @param grades list of grades
			 */
			gotGrades(grades, fcaKbbCtrl) {
				fcaKbbCtrl.grades = grades;

				fcaKbbCtrl.checkIfReady();
			}

			getSelectedOptions() {
				let selectedOptionIds = Object.entries(this.selectedOptions)
				.filter((entry) => entry[1] === true)
				.map((entry) => Number(entry[0]));
				return this.options
				.filter(option => selectedOptionIds.indexOf(option.ID) !== -1);
			}

			/**
			 * @param brands list of brands
			 */
			gotBrands(brands, fcaKbbCtrl) {
				fcaKbbCtrl.brands = brands;

				// if we already have a brand selected, retrieve the models
				if (fcaKbbCtrl.brand) {
					fcaKbbCtrl.getModels();
				}

				fcaKbbCtrl.brandsLoading = false;
			}

			/**
			 * @param transmissions list of transmissions
			 */
			gotTransmissions(transmissions, fcaKbbCtrl) {
				fcaKbbCtrl.transmissions = transmissions;

				fcaKbbCtrl.checkIfReady();
			}

			/**
			 *
			 * @param locations list of location states
			 */
			gotLocationStates(locations, fcaKbbCtrl) {
				fcaKbbCtrl.locations = locations;

				fcaKbbCtrl.checkIfReady();
			}

			checkTradeInValueAvailability() {
				let currentYear = new Date().getFullYear();
				this.tradeinUnavailable = this.tradeInValue <= 2000 || this.year.Year <= (currentYear - 15);
			}

			checkIfReady() {
				if (this.locations
					&& this.years) {
					this.ready = true;

					// if we already have a year selected, retrieve the brands
					if (this.year) {
						this.getBrands();
					}
				}
			}

			/**
			 *
			 * @param models list of models objects
			 */
			gotModels(models, fcaKbbCtrl) {
				fcaKbbCtrl.models = models;

				// if we already have a model selected, retrieve the vehicles
				if (fcaKbbCtrl.model) {
					fcaKbbCtrl.getVehicles();
				}

				fcaKbbCtrl.modelsLoading = false;
			}

			/**
			 *
			 * @param vehicles list of vehicles objects
			 */
			gotVehicles(vehicles, fcaKbbCtrl) {
				// reduce the trim list according to VehicleVersionNameLong + TransmissionTypeID
				// this will give us an associative array, with a key=>val structure
				let filteredVehicles = fcaKbbCtrl.mergeArray(
					vehicles,
					(vehicle) => vehicle.VehicleVersionNameLong + '#' + vehicle.TransmissionTypeID,
					(vehicle1, vehicle2) => fcaKbbCtrl.maxBy((vehicle) => Number(vehicle.TradeInPrice || 0), vehicle1, vehicle2));

				// if multiple vehicles have the same name, add the transmission (to both of them)
				let newVehicles = filteredVehicles.reduce((accumulator, currentValue, currentIndex, reducedArray) => {
					// look for vehicles with same VehicleVersionNameLong
					let sameVehicleVersionNameLong = reducedArray.filter((vehicle, currentIndex, filteredArray) =>
						vehicle.VehicleVersionNameLong === currentValue.VehicleVersionNameLong
						&& vehicle !== currentValue
					);

					if (sameVehicleVersionNameLong && sameVehicleVersionNameLong.length > 0) {
						// build the name for everybody, including the current element
						sameVehicleVersionNameLong.push(currentValue);
						// if we have different transmissions, use them
						let allTransmissionIds = sameVehicleVersionNameLong.reduce((accumulator, currentValue, currentIndex, reducedArray) => {
							if (accumulator.indexOf(currentValue.TransmissionTypeID) === -1) {
								accumulator.push(currentValue.TransmissionTypeID);
							}
							return accumulator;
						}, []);

						sameVehicleVersionNameLong.forEach((vehicle) => {
							// get the name of the transmission
							let transmission = null;
							if (fcaKbbCtrl.transmissions) {
								let matchedTrans = fcaKbbCtrl.transmissions
									.filter((transmission, currentIndex, filteredArray) => transmission.ID === vehicle.TransmissionTypeID);
								if (matchedTrans) {
									transmission = matchedTrans[0];
								}
							}
							vehicle.label = vehicle.VehicleVersionNameLong +
								(transmission
									? ' ' + transmission.Name
									: '');
						});
					} else {
						// we use the VehicleVersionNameLong as the label for now
						currentValue.label = currentValue.VehicleVersionNameLong;
					}

					// add current value
					accumulator.push(currentValue);

					return accumulator;
				}, []); // initial value of reduce is an empty array

				// sort the vehicles by their label
				newVehicles = newVehicles.sort((vehicle1, vehicle2) =>
					vehicle1.label.localeCompare(vehicle2.label)
				);

				fcaKbbCtrl.vehicles = newVehicles;

				// if we already have a vehicle, get the options
				if (fcaKbbCtrl.vehicle) {
					fcaKbbCtrl.getOptions();
				}

				fcaKbbCtrl.vehiclesLoading = false;
			}

			/**
			 * Called when the list of options is received
			 *
			 *	{
			 *		"ResponseStatus": 1,
			 *		"Response": [
			 *			{
			 *				"ID": 0,
			 *				"Name": "",
			 *				"Price": 0.0
			 *			},
			 *			{
			 *				"ID": 1,
			 *				"Name": "",
			 *				"Price": 0.0
			 *			}
			 *		]
			 *	}
			 *
			 * @param options list of options
			 **/
			gotOptions(equipmentResponse, fcaKbbCtrl) {
				let options = equipmentResponse.Response;
				if (options) {
					// not all cars have options
					if (options.length > 0) {
						fcaKbbCtrl.options = options;
						fcaKbbCtrl.optionsAvailable = true;
					} else {
						fcaKbbCtrl.options = null;
						fcaKbbCtrl.optionsAvailable = false;
					}
				}
				fcaKbbCtrl.optionsLoading = false;
				fcaKbbCtrl.source = equipmentResponse.source;

				// check if we're ready
				fcaKbbCtrl.checkIfSubmitReady();
			}

			/**
			 * Called whenever the year changes
			 */
			yearChanged() {

				// only retrieve the brands if we have a year
				if (this.year) {
					this.getBrands();
				}

			}

			/**
			 * Called whenever the grade changes
			 */
			gradeChanged() {
				// changing the grade invalidates the previous trade-in value
				this.tradeInValue = null;

				this.checkIfSubmitReady();
			}

			removeBrands() {
				// remove the models (they depend on the brand)
				this.removeModels();

				// remove the list of choices
				this.brands = null;
				// also remove the selected brand
				this.brand = null;
			}

			removeModels() {
				// remove the vehicles (they depend on the brand)
				this.removeVehicles();

				// remove the list of choices
				this.models = null;
				// also remove the selected model
				this.model = null;
			}

			removeVehicles() {
				// remove the options (they depend on the brand)
				this.removeOptions();

				// remove the trade-in value (it depends on the vehicle)
				this.tradeInValue = null;

				// remove the list of choices
				this.vehicles = null;
				// also remove the selected vehicle
				this.vehicle = null;

				this.checkIfSubmitReady(); // there's no way we should be able to submit now
			}

			removeOptions() {
				// remove the list of options
				this.options = null;
				this.optionsAvailable = false;
				this.selectedOptions = null;
				this.resetUpgradeLabel();
			}

			/**
			 * Called whenever the brand changes (including when the drop-down is removed)
			 */
			brandChanged() {
				// remove the models (they depend on the brand)
				this.removeModels();

				// this is also called when the brand is removed
				if (this.brand) {
					this.getModels();
				}
			}

			/**
			 * Called whenever the model changes (including when the drop-down is removed)
			 */
			modelChanged() {
				// remove the vehicles (they depend on the brand)
				this.removeVehicles();

				// this is also called when the model is removed
				if (this.model) {
					this.getVehicles();
				}
			}

			/**
			 * Called whenever the vehicle changes (including when the drop-down is removed)
			 */
			vehicleChanged() {
				// remove the options (it depends on the vehicle)
				this.removeOptions();

				// changing the vehicle invalidates the previous trade-in value
				this.tradeInValue = null;

				this.checkIfSubmitReady(); // it's possible the mileage has been input prior

				// this is also called when the model is removed
				if (this.vehicle) {
					this.getOptions();
				}
			}

			/**
			 * Called whenever the km value change
			 */
			mileageChanged() {
				// changing the mileage invalidates the previous trade-in value
				this.tradeInValue = null;

				this.checkMileage();

				this.checkIfSubmitReady();
			}

			/**
			 * Called whenever the km value change
			 */
			checkMileage() {
				// wipe any parsed mileage
				this.realMileage = null;

				// remove any non-digit characters
				let parsedMileage = Math.max(Number(String(this.mileage).replace(/\D/g, '')), 0);

				if (this.mileage != null) {
					if (parsedMileage > 350000) {
						this.mileageTooHigh = true;
					} else {
						this.realMileage = parsedMileage;
						this.mileageTooHigh = false;
					}
				}
			}

			/**
			 * Prevent non-number keypress on owe-on-trade
			 *
			 * @param $event
			 */
			oweOnTradeKeypress(event) {
				this.fcaKeypress.allowNumbers(event);
			}

			/**
			 * Prevent non-number keypress on mileage
			 *
			 * @param $event
			 */
			mileageKeypress(event) {
				this.fcaKeypress.allowNumbers(event);
			}

			/**
			 * For the controller to be ready to obtain a price,
			 * the mileage must be there, and we must have a vehicle.
			 */
			checkIfSubmitReady() {
				this.submitReady = this.realMileage && this.vehicle && this.grade;
			}

			/**
			 * Called whenever the user clicks on the get price button
			 */
			getPriceButton() {
				this.getPrices();
			}

			/**
			 * Get the biggest value between two, according to the value extractor.
			 *
			 * @param valueExtractor
			 * @param value1
			 * @param value2
			 * @returns {*}
			 */
			maxBy(valueExtractor, value1, value2) {
				return valueExtractor(value1) > valueExtractor(value2) ? value1 : value2;
			}

			/**
			 * Merge an array
			 * Use the keyMapper to extract key
			 * If two values have same key, use the merge function.
			 *
			 * @param array                 Array we want to merge
			 * @param keyExtractor          Key extractor
			 * @param mergeFunction         Binary function used when there are multiple items with same key
			 * @returns {*}                 A new merged array
			 */
			mergeArray(array, keyExtractor, mergeFunction) {
				let result = array;
				if (array
					&& keyExtractor
					&& mergeFunction) {
					// produce a map where the key is produced by the key extractor
					let groupedMap = array.reduce((accumulator, currentValue, currentIndex, reducedArray) => {
						let key = keyExtractor(currentValue);
						if (!accumulator.hasOwnProperty(key)) {
							accumulator[key] = currentValue;
						} else {
							accumulator[key] = mergeFunction(accumulator[key], currentValue);
						}
						return accumulator;
					}, []); // initial value of reduce is an empty array
					// only keep the values
					result = Object.values(groupedMap);
				}
				return result;
			}

			resetUpgradeLabel() {
				const multiSelectLabel = $('#multi-select-pluginmulti-select-label');
				multiSelectLabel.children('label').text('Select an upgrade');
			}
		}

	class FcaBuyOnlineKbbCtrl extends FcaKbbCtrl {
		constructor($document,
			$scope,
			$rootScope,
			externalConfigLoader,
			userLocation,
			fcaKbbService,
			fcaBuyOnlineService,
			fcaScrollToElementService,
			$element,
			fcaKeypress) {
			super($document,
				$scope,
				$rootScope,
				externalConfigLoader,
				userLocation,
				fcaKbbService,
				fcaScrollToElementService,
				$element,
				fcaKeypress)
			this.fcaBuyOnlineService = fcaBuyOnlineService;
		}

		$onInit() {


			// initialize configuration
			this.config = this.externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
			this.language = this.config.getConfig('language');
			this.submitReady = false;
			this.submitLeadReady = false;
			this.owedApplied = false;
			this.brandsLoading = false;
			this.modelsLoading = false;
			this.vehiclesLoading = false;
			this.vehicleInfo = "";

			this.appraised = false;
			this.tradeinUnavailable=false;
			this.source=1;

			this.optionsAvailable = false;

			// check if we already have a trade-in-value
			let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);

			if (checkout.kbb) {
				this.haveTradeIn = checkout.kbb.haveTradeIn || false;
				this.year = checkout.kbb.year;
				this.brand = checkout.kbb.brand;
				this.model = checkout.kbb.model;
				this.vehicle = checkout.kbb.vehicle;
				this.mileage = checkout.kbb.mileage;
				if (checkout.kbb.options) {
					this.selectedOptions = checkout.kbb.options.reduce((options, option) => {
						options[option.ID] = true;
						return options;
					}, []);
				}
				if (this.mileage) {
					this.checkMileage();
				}
				this.tradeInValue = checkout.kbb.tradeInValue;
				if (this.tradeInValue != null) {
					this.appraised = true;
				}
				this.kbb = checkout.kbb;
			}

			// retrieve the states
			this.getLocationStates();

			// retrieve the years
			this.getYears();

			// retrieve the grades
			this.getGrades();

			window.fca = (window.fca || {});
			window.fca.fcaKbbCtrl = this;
		}

		gotPrices(prices, fcaKbbCtrl) {

			let tradeInValue = prices.FPP;
			// round to two decimals
			fcaKbbCtrl.tradeInValue = Math.round(Number(tradeInValue) * 100) / 100;

			fcaKbbCtrl.fcaScrollToElementService.scrollToElement(fcaKbbCtrl.$element, 0);

			fcaKbbCtrl.updateCheckout();

			fcaKbbCtrl.appraised = true;

			fcaKbbCtrl.checkTradeInValueAvailability();

		}

		yearChanged() {
			super.yearChanged();
			// remember the selected year
			this.updateCheckout();
		}
		/**
		 * Called whenever the brand changes (including when the drop-down is removed)
		 */
		brandChanged() {
			super.brandChanged();
			this.updateCheckout(); // remember the selected brand
		}

		/**
		 * Called whenever the model changes (including when the drop-down is removed)
		 */
		modelChanged() {
			super.modelChanged();
			this.updateCheckout(); // remember the selected model
		}

		/**
		 * Called whenever the vehicle changes (including when the drop-down is removed)
		 */
		vehicleChanged() {
			super.vehicleChanged();
			this.updateCheckout(); // remember the selected vehicle
		}

		/**
		 * Called whenever the options change
		 */
		optionsChanged() {
			super.optionsChanged();
			this.updateCheckout(); // remember the options
		}

		/**
		 * Called whenever the km value change
		 */
		mileageChanged() {
			super.mileageChanged();
			this.updateCheckout(); // remember the mileage
		}

		/**
		 * Called whenever the user interacts with the "have trade in" radio buttons
		 */
		haveTradeInChanged() {
			// forget the year
			this.year = null;

			this.updateCheckout();
		}

		/**
		 * Called whenever the user clicks on apply owe on trade
		 */
		applyOweOnTrade() {
			this.owedApplied = false;

			if (this.oweOnTrade != null) {
				// remove any non-digit characters
				let oweOnTrade = Math.max(Number(String(this.oweOnTrade).replace(/\D/g, '')), 0);

				// make sure the owe on trade is not higher than the fpp
				this.owedTooHigh = false;
				this.oweOnTradeVal = null;

				if (oweOnTrade > this.tradeInValue) {
					this.owedTooHigh = true;
				} else {
					// update the session storage item
					this.owedApplied = true;
					this.oweOnTradeVal = oweOnTrade;
					this.updateCheckout();
				}
			}
		}

		/**
		 * Updates the checkout object
		 */
		updateCheckout() {
			let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);

			// remove any previously set values
			delete checkout.kbb;

			// only set the trade owed if the user input something
			if (this.oweOnTradeVal != null) {
				checkout.tradeOwed = this.oweOnTradeVal;
			}

			// create a kbb dedicated property in checkout
			checkout.kbb = {};
			checkout.kbb.haveTradeIn = this.haveTradeIn;
			checkout.kbb.year = this.year;
			if (this.brand) {
				checkout.kbb.brand = {Name: this.brand.Name, ID: this.brand.ID};
			}
			if (this.model) {
				checkout.kbb.model = {Name: this.model.Name, ID: this.model.ID};
			}
			if (this.vehicle) {
				checkout.kbb.vehicle = {label: this.vehicle.label, ID: this.vehicle.ID};
			}
			if (this.mileage) {
				checkout.kbb.mileage = this.mileage;
			}
			if (this.selectedOptions) {
				checkout.kbb.options = this.getSelectedOptions().map(
					(option) => ({ID: option.ID, Name: option.Name}));
			}
			if (this.grade) {
				checkout.kbb.grade = {Description: this.grade.Description, ID: this.grade.ID};
			}
			if (this.tradeInValue != null) {
				checkout.kbb.tradeInValue = this.tradeInValue;
				// also set it as the parameter
				checkout.tradeInValue = this.tradeInValue;
			} else {
				delete checkout.kbb.tradeInValue;
			}
			// keep the kbb reference here
			this.kbb = checkout.kbb;

			this.fcaBuyOnlineService.setInStorage(this.vin, checkout);
		}
	}


	class FcaTradeInKbbCtrl extends FcaKbbCtrl {
		constructor($document,
			$scope,
			$rootScope,
			externalConfigLoader,
			userLocation,
			fcaKbbService,
			fcaScrollToElementService,
			$element,
			fcaKeypress) {
			super($document,
				$scope,
				$rootScope,
				externalConfigLoader,
				userLocation,
				fcaKbbService,
				fcaScrollToElementService,
				$element,
				fcaKeypress)
		}

		$onInit() {
			this.phoneRegex = '^\\(?([2-9][0-9]{2})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
			this.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
			this.emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

			// initialize configuration
			this.config = this.externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
			this.language = this.config.getConfig('language');
			this.submitReady = false;
			this.submitLeadReady = false;
			this.owedApplied = false;
			this.brandsLoading = false;
			this.modelsLoading = false;
			this.vehiclesLoading = false;
			this.vehicleInfo = "";

			this.appraised = false;

			this.step = "1";

			this.buildAndPriceUrl = "";
			this.inventoryUrl = "";

			this.optionsAvailable = false;

			// retrieve the states
			this.getLocationStates();

			// retrieve the years
			this.getYears();

			// retrieve the grades
			this.getGrades();

			this.$rootScope.$on('contact-a-dealer.field-changed.and-ready', (event, args) => {
				this.submitLeadReady = args;
			});

			this.$rootScope.$on('contact-a-dealer.submit-to-dealer-succeed', (event, args) => {
				this.sendConfirmationEmail(args.selectedData);
				this.$rootScope.$applyAsync(() => {
					this.step = "3";
					this.buildAndPriceUrl = args.buildAndPrice;
					this.inventoryUrl = args.inventory;
					$('html, body').animate({scrollTop: 0});
				});
			});

			window.fca = (window.fca || {});
			window.fca.fcaKbbCtrl = this;
		}

		/**
		 * Called whenever the user clicks on the Continue to Step 2 button
		 */
		getToStep2() {
			this.step = "2";
			this.submitReady = false;
			this.getPrices();
		}

		getToStep3() {
			// Trigger the submit to dealer
			this.$rootScope.$broadcast('contact-a-dealer.trigger-submit-form', {tradeIn: true});
		}

		sendConfirmationEmail(data) {

			var emailData = {
				language: window.FCA_SITES_CONFIG.language,
				contactMethod: data.contactMethod,
				dealerId: data.dealerCode,
				brandCode: data.brandCode,
				firstname: data.firstname,
				lastname: data.lastname,
				postalCode: data.postalCode,
				email: data.email,
				year: data.year,
				nameplateCode: data.nameplateCode,
				trimDescription: data.trimDescription,
				trimGroupCode: data.trimGroupCode,
				modelYearId: data.modelYearId,
				irisExteriorImage: data.jellyPath,
				jellyPath: data.jellyPath
			};

			var tradeIn = {
				year: this.year.Year,
				brand: this.brand.Name,
				model: this.model.Name,
				trim: this.vehicle.label,
				grade: this.grade.Description,
				mileage: this.mileage,
				value: this.tradeInValue
			}

			if (this.selectedOptions && this.options) {
				tradeIn.options = this.getSelectedOptions().map(
					(option) => option.Name);
			}

			emailData.tradeIn = tradeIn;

			$.ajax({
				url: '/api/tradein/emails',
				type: 'post',
				data: JSON.stringify(emailData),
				dataType: 'json',
				contentType: 'application/json',
				success: function (data) {
					console.log("Success enails sent");
				}
			});
		}

		/**
		 *
		 * @param prices list of prices
		 */
		gotPrices(prices, fcaKbbCtrl) {

			let tradeInValue = prices.FPP;
			let priceHigh = prices.PriceHigh;
			let priceLow = prices.PriceLow;

			// round to two decimals
			fcaKbbCtrl.tradeInValue = Math.round(Number(tradeInValue) * 100) / 100;
			fcaKbbCtrl.priceHigh = Math.round(Number(priceHigh) * 100) / 100;
			fcaKbbCtrl.priceLow = tradeInValue === 0 ? 0 : Math.round(Number(priceLow) * 100) / 100;

			let result = "";

			result += `Trade-in:\n`
				+ `\tVehicle: ${fcaKbbCtrl.year.Year} `
				+ `${fcaKbbCtrl.brand.Name} `
				+ `${fcaKbbCtrl.model.Name} `
				+ `${fcaKbbCtrl.vehicle.label}\n`
				+ `\tgrade: ${fcaKbbCtrl.grade.Description}\n`
				+ `\tMileage: ${fcaKbbCtrl.mileage} km\n`
				+ `\tValue: ${fcaKbbCtrl.tradeInValue}`;

			fcaKbbCtrl.vehicleInfo = result;

			fcaKbbCtrl.appraised = true;
			fcaKbbCtrl.checkTradeInValueAvailability();

		}

		fieldChanged() {
			this.checkIfSubmitLeadReady();
		}

		/**
		 * For the controller to be ready to obtain a price,
		 * the name must be there
		 */
		checkIfSubmitLeadReady() {
			this.submitLeadReady = this.firstname && this.lastname;
		}
	}


	class FcaBuildAndPriceKbbCtrl extends FcaKbbCtrl {
		constructor($document,
			$scope,
			$rootScope,
			externalConfigLoader,
			userLocation,
			fcaKbbService,
			fcaScrollToElementService,
			$element,
			fcaKeypress) {
			super($document,
				$scope,
				$rootScope,
				externalConfigLoader,
				userLocation,
				fcaKbbService,
				fcaScrollToElementService,
				$element,
				fcaKeypress)
		}

		$onInit() {

			// initialize configuration
			this.config = this.externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
			this.language = this.config.getConfig('language');
			this.submitReady = false;
			this.brandsLoading = false;
			this.modelsLoading = false;
			this.vehiclesLoading = false;
			this.vehicleInfo = "";
			this.spanError = "";
			this.appraised = false;
			this.errorHeader = null;
			this.errorHeaderHidden = true;
			this.step = "1";

			this.optionsAvailable = false;

			// retrieve the states
			this.getLocationStates();

			// retrieve the years
			this.getYears();

			// retrieve the grades
			this.getGrades();

			window.fca = (window.fca || {});
			window.fca.fcaKbbCtrl = this;

			this.$rootScope.$on('trade-in-kbb:reset-trade-in-estimator',
				() => {
					this.step = "1";
					this.submitReady = false;
					this.appraised = false;
					this.tradeInValue = null;
					this.priceHigh = null;
					this.priceLow = null;
					this.mileage = null;
					this.year = null;
					this.grade = null;
					this.brand = null;
					this.model = null;
					this.vehicle = null;
					this.vehicles = null;
					this.brands = null;
					this.models = null;
					this.errorHeaderHidden = true;
					this.options = {};
				});

			this.$rootScope.$on('reset-multi-select-options', () => {
				super.resetUpgradeLabel();
			});
		}

		/**
		 * Called whenever the user clicks on the Get my Trade-in value button
		 * focus on error span if there is one.
		 */
		getToStep3() {
			this.checkIfSubmitReady();
			this.resetFormErrors();
			if(!this.submitReady) {
				this.validateYear();
				this.validateMake();
				this.validateModel();
				this.validateVehicle();
				this.validateGrade();
				this.validateMileage();
				this.errorHeaderHidden = false;
				this.errorHeader = document.getElementById('tradeFormError');
				setTimeout(() => {
					this.errorHeader.focus();
				}, 100);

			} else {
				this.step = "3";
				this.submitReady = true;
				this.errorHeaderHidden = true;
				this.getPrices();
			}
		}

		/**
		 *
		 * @param prices list of prices
		 */
		gotPrices(prices, fcaKbbCtrl) {

			let tradeInValue = prices.FPP;
			let priceHigh = prices.PriceHigh;
			let priceLow = prices.PriceLow;

			// round to two decimals
			fcaKbbCtrl.tradeInValue = Math.round(Number(tradeInValue) * 100) / 100;
			fcaKbbCtrl.priceHigh = Math.round(Number(priceHigh) * 100) / 100;
			fcaKbbCtrl.priceLow = tradeInValue === 0 ? 0 : Math.round(Number(priceLow) * 100) / 100;

			fcaKbbCtrl.appraised = true;

			fcaKbbCtrl.checkTradeInValueAvailability();

		}

		/**
		 * Called whenever the year changes (including when the drop-down is removed)
		 * In this implementation, each [step]Changed method checks the previous to
		 * know which error message to print
		 */
		yearChanged() {
			this.validateYear();
			super.yearChanged();
		}

		/**
		 * Called whenever the brand changes (including when the drop-down is removed)
		 */
		brandChanged() {
			/**
			 * previous step check
			 */
			this.validateYear();
			this.validateMake();

			super.brandChanged();
		}

		/**
		 * Called whenever the model changes (including when the drop-down is removed)
		 */
		modelChanged() {
			/**
			 * previous step check
			 */
			this.validateYear();
			this.validateMake();
			this.validateModel();

			super.modelChanged();
		}

		/**
		 * Called whenever the vehicle changes (including when the drop-down is removed)
		 */
		vehicleChanged() {
			/**
			 * previous step check
			 */
			this.validateYear();
			this.validateMake();
			this.validateModel();
			this.validateVehicle();

			super.vehicleChanged();
		}

		/**
		 * Called whenever the options change
		 * no validation needed on options
		 */
		optionsChanged() {
			super.optionsChanged();
		}

		/**
		 * Called whenever the grade changes
		 */
		gradeChanged() {
			this.validateGrade();
			// changing the grade invalidates the previous trade-in value
			this.checkIfSubmitReady();
		}

		/**
		 * Called whenever the km value change
		 */
		mileageChanged() {
			this.validateMileage();
			super.mileageChanged();
		}

		applyTradeInValueToCalculator() {
			this.$rootScope.$broadcast("calculator:apply-trade-in-value", {tradeInValue : this.tradeInValue});
			this.$rootScope.$broadcast("build-and-price:close-trade-in-estimator");
		}

		/**
		 * For the controller to be ready to obtain a price,
		 * the mileage must be there, and we must have a vehicle.
		 */
		checkIfSubmitReady() {
			this.submitReady = this.mileage && this.vehicle && this.grade;
		}

		/**
		 * Called whenever the user clicks on a empty field
		 */
		validateFormErrors(inputType, option) {
			this.spanError = document.getElementById(inputType);
			if(option === "hide") {
				this.spanError.classList.remove('C_BP_OL-form-error');
				this.spanError.classList.add('C_BP_OL-form-error-hidden');
			} else {
				this.spanError.classList.add('C_BP_OL-form-error');
				this.spanError.classList.remove('C_BP_OL-form-error-hidden');
			}
			const errors = document.querySelectorAll('.C_BP_OL-form-error');
			this.errorHeaderHidden = errors.length === 0;
		}

		/**
		 * The methods described belows validates the given field by checking
		 * the boolean conditions required.
		 */
		validateYear() {
			if(!this.year) {
				this.validateFormErrors("year");
			} else {
				this.validateFormErrors("year", "hide");
			}
		}

		validateMake() {
			if(!this.brand && this.year) {
				this.validateFormErrors("brand");
			} else {
				this.validateFormErrors("brand", "hide");
			}
		}

		validateModel() {
			if(!this.model && this.brand && this.year) {
				this.validateFormErrors("model");
			} else {
				this.validateFormErrors("model", "hide");
			}
		}

		validateVehicle() {
			if(this.model && this.brand && this.year && !this.vehicle) {
				this.validateFormErrors("trim");
			} else {
				this.validateFormErrors("trim", "hide");
			}
		}

		validateGrade() {
			if(!this.grade) {
				this.validateFormErrors("grade");
			} else {
				this.validateFormErrors("grade", "hide");
			}
		}

		validateMileage() {
			if(!this.mileage) {
				this.validateFormErrors("mileage");
			} else {
				this.validateFormErrors("mileage", "hide");
			}
		}

		resetFormErrors() {
			let errors = document.querySelectorAll('.C_BP_OL-form-error');
			errors.forEach(error => {
				error.classList.add('.C_BP_OL-form-error-hidden');
				error.classList.remove('.C_BP_OL-form-error');
			});
			this.errorHeaderHidden = true;
		}
	}
})();
