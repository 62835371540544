(function () {
    angular
        .module('fca.commonNameplate')
        .directive('fcaNameplate', fcaNameplate);

    function fcaNameplate() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                defaultTab: '@'
            },
            controllerAs: '$ctrlNameplate',
            controller: FcaNameplate
        };

        function FcaNameplate($element, $rootScope, $scope, $timeout) {
            'ngInject';

            const DATE_BUTTON_CLASSNAME = "commonNameplate-date";

            let currentElement = $element[0];
            let yearButtons = currentElement.querySelectorAll(`.${DATE_BUTTON_CLASSNAME}`);
            let dropdownButton = currentElement.querySelector('.js-commonNameplate-dropdownBtn');

            let $buttons = $element.find('.js-commonNameplate-accordeonBtn');

            let accordeonHeightsFeature = 0;
            let accordeonHeightsConfig = 0;

            let $ctrl = this;

            $ctrl.$onInit = () => {
                // parse the hash parameters
                $ctrl.hashParameters = getHashParameters();

                // default the active tab to cash
                $ctrl.activeTab = 'cash';

                // if we have a default tab, honor it
                $ctrl.activeTab = $ctrl.defaultTab || $ctrl.activeTab;

                // honor the hash parameter if any
                $ctrl.activeTab = $ctrl.hashParameters && $ctrl.hashParameters.activeTab ? $ctrl.hashParameters.activeTab : $ctrl.activeTab;

                // rebuild the url suffix
                $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);
            };

            $scope.$on('feature-accordeon-status-changed', newStatus => {
                $ctrl.isSlideDowntriggered = newStatus;
            });

            $ctrl.$postLink = () => {
                $ctrl.initNameplateContentSwitch();
                $ctrl.initNameplateDropdown();
                $ctrl.initNameplateAccordeon();
            };

            $scope.$on('pricing:pricing-object-is-ready', (_, data) => {
                $ctrl.initSelectedYear();
            });

            $ctrl.initNameplateContentSwitch = () => {
                if (!yearButtons) return;
                for (let yearButton of yearButtons) {
                    yearButton.addEventListener('click', $ctrl.onYearButtonClick);

                    if ($(yearButton).hasClass('-active')) {
                        $(yearButton).attr('aria-pressed', 'true');
                    }
                }
            };

            $ctrl.initSelectedYear = () => {
                const hash = location.hash.substr(1);
                if (hash) {
                    const hashParameters = getHashParameters();

                    if (hashParameters.year) {
                        $('[data-year-button]:contains("' + hashParameters.year + '")').trigger('click');
                    }
                }
            };

            $ctrl.initNameplateDropdown = () => {
                if (!dropdownButton) {
                    return;
                }

                dropdownButton.addEventListener('click', evt => {
                    $(evt.target).toggleClass('js-dropdown-open');
                });
            };

            $ctrl.onYearButtonClick = evt => {
                const { target } = evt;

                if (target.className.indexOf("-active") > -1) {
                    return;
                }

                $ctrl.desactivateAllYearButton();

                target.className = `${DATE_BUTTON_CLASSNAME} -active`;

                $element.find('[data-model-year]').hide().removeClass('active-year');
                $element.find(`[data-model-year='${target.dataset['yearButton']}']`).fadeIn(150).addClass('active-year');

                $element.find('[data-year-button]').attr('aria-pressed', 'false');
                $element.find(`[data-year-button='${target.dataset['yearButton']}']`).attr('aria-pressed', 'true');
            }

            $ctrl.desactivateAllYearButton = () => {
                if(!yearButtons) return;
                for (let i = 0; i < yearButtons.length; i++) {
                  yearButtons[i].className = DATE_BUTTON_CLASSNAME;
                }
            };

            let maxHeight = 0;
            $ctrl.setDropdownMinHeight = (accordeonContent) => {
                accordeonContent.each((_, elem) => {
                    if ($(elem).height() > maxHeight) {
                        maxHeight = $(elem).height();
                    }
                });

                accordeonContent.height(maxHeight);
            };

            $ctrl.initNameplateAccordeon = () => {
                $buttons.each((_, button) => {
                    const $element = $(button);
                    const $parentNameplate = $element.parents('.js-commonNameplate');
                    const $featureAccordeonContent = $('[data-accordeon-type="feature"]').next('.js-commonNameplate-accordeonContent');

                    let clickCounter = 0;
                    let isSlideDowntriggered = false;
                    button.addEventListener("click", () => {
                        const $clickedButtonGroup = $('.js-commonNameplate-accordeonBtn[data-accordeon-type="' + $element.data('accordeon-type') + '"]');
                        const $clickedButtonOtherGroup = $('.js-commonNameplate-accordeonBtn[data-accordeon-type!="' + $element.data('accordeon-type') + '"]');
                        const contentDisplayProperty = $element.next('.js-commonNameplate-accordeonContent').css('display');

                        if (contentDisplayProperty === 'none') {
                            $clickedButtonOtherGroup.next('.js-commonNameplate-accordeonContent').slideUp(200);
                            $clickedButtonOtherGroup.removeClass('-active');
                            $clickedButtonOtherGroup.attr('aria-expanded', 'false');
                            clickCounter++;
                            $clickedButtonGroup.next('.js-commonNameplate-accordeonContent').slideDown(200, () => {
                                if (clickCounter === 1 && $element.data('accordeon-type') === 'feature') {
                                    $ctrl.setDropdownMinHeight($featureAccordeonContent);
                                    isSlideDowntriggered = true;
                                    $rootScope.$broadcast('feature-accordeon-status-changed', true);
                                }
                            });
                            $clickedButtonGroup.addClass('-active');
                            $clickedButtonGroup.attr('aria-expanded', 'true');

                            $clickedButtonGroup.each(function( btnIndex, btnElement ) {
                                $(btnElement).attr('data-analyticsvalue',$(btnElement).attr('data-analyticsvalue').replace("open","closed"))
                                $(btnElement).find('.commonNameplate-btnLabel').attr('data-analyticsvalue', $(btnElement).find('.commonNameplate-btnLabel').attr('data-analyticsvalue').replace("open","closed"));
                                $(btnElement).find('.commonNameplate-btnLabel .fcaicon').attr('data-analyticsvalue', $(btnElement).find('.commonNameplate-btnLabel .fcaicon').attr('data-analyticsvalue').replace("open","closed"));
                            })

                        } else {
                            if ($ctrl.isSlideDowntriggered) {
                                $clickedButtonGroup.next('.js-commonNameplate-accordeonContent').slideUp(200);
                                $rootScope.$broadcast('feature-accordeon-status-changed', false);
                                $clickedButtonGroup.removeClass('-active');
                                $clickedButtonGroup.attr('aria-expanded', 'false');

                                $clickedButtonGroup.each(function( btnIndex, btnElement ) {
                                    $(btnElement).attr('data-analyticsvalue',$(btnElement).attr('data-analyticsvalue').replace("closed","open"))
                                    $(btnElement).find('.commonNameplate-btnLabel').attr('data-analyticsvalue', $(btnElement).find('.commonNameplate-btnLabel').attr('data-analyticsvalue').replace("closed","open"));
                                    $(btnElement).find('.commonNameplate-btnLabel .fcaicon').attr('data-analyticsvalue', $(btnElement).find('.commonNameplate-btnLabel .fcaicon').attr('data-analyticsvalue').replace("closed","open"));
                                })
                            }
                        }
                        $timeout(() => {
                            $('html, body').animate(
                                { scrollTop: $parentNameplate.offset().top }, 100);
                        }, 300);
                    });
                });
            }

            $scope.$on('pricing:set-active-tab-and-build-url', (event, data) => {
                $ctrl.defaultFirstSelected = false;
                const pageType = data.pageType;
                let thisTab, hasLease, hasFinance, hasAltLease, hasAltFinance;

                if (pageType === 'vehicleSelector') {
                    thisTab = ($element.find('.active-year [data-tab="' + data.activeTab + '"]').length > 0);
                    hasLease = ($element.find('.active-year [data-tab="lease"]').length > 0);
                    hasFinance = ($element.find('.active-year [data-tab="finance"]').length > 0);
                    hasAltLease = ($element.find('.active-year [data-tab="altLease"]').length > 0);
                    hasAltFinance = ($element.find('.active-year [data-tab="altFinance"]').length > 0);
                } else {
                    thisTab = ($element.find('[data-tab="' + data.activeTab + '"]').length > 0);
                    hasLease = ($element.find('[data-tab="lease"]').length > 0);
                    hasFinance = ($element.find('[data-tab="finance"]').length > 0);
                    hasAltLease = ($element.find('[data-tab="altLease"]').length > 0);
                    hasAltFinance = ($element.find('[data-tab="altFinance"]').length > 0);
                }

                if (thisTab) {
                    $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, data.activeTab);
                    $ctrl.activeTab = data.activeTab;
                } else if (data.activeTab === 'lease') {
                    $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, 'finance');
                    $ctrl.activeTab = 'finance';
                } else if (data.activeTab === 'altLease') {
                    if (!hasAltLease && hasLease) {
                        $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, 'lease');
                        $ctrl.activeTab = 'lease';
                    } else if (!hasAltLease && !hasLease) {
                        $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, 'finance');
                        $ctrl.activeTab = 'finance';
                    }
                } else if (data.activeTab === 'altFinance') {
                    if (!hasAltFinance && hasFinance) {
                        $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, 'finance');
                        $ctrl.activeTab = 'finance';
                    } else {
                        $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, 'cash');
                        $ctrl.activeTab = 'cash';
                    }
                }

            });
        }
    }
})();
