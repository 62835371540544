(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaLazyloadPlaceholder', fcaLazyloadPlaceholder);

    function fcaLazyloadPlaceholder() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: 'lazyloadPlaceholder',
            controller: FcaLazyloadPlaceholderController,
        };

        function FcaLazyloadPlaceholderController($element) {
            'ngInject';
            const $ctrl = this;
            let loopActive = false;

            $ctrl.$onInit = () => {
                $ctrl.removeLoadingLazyload();
            };

            $ctrl.$postLink = () => {
                angular.element(document).ready(function () {
                    if ($element.find('img').hasClass('lazyloading')) {
                        $element.find('.fca-loading').removeClass('lazyload-spinner-active').addClass('lazyload-spinner');
                    }
                });
            };
            /**
             * @ngdoc method
             * @name removeLoadingLazyload
             * @description Remove the loading icon once image is loaded
             */
            $ctrl.removeLoadingLazyload = () => {
                if ($element.find('img').hasClass('lazyloaded')) {
                    $element.find('.fca-loading').removeClass('lazyload-spinner-active').addClass('lazyload-spinner');
                    loopActive = true;
                } else {
                    if (!loopActive) {
                        setTimeout(this.removeLoadingLazyload, 1000);
                    }
                }
            };
        }
    }
})();
