/* eslint-disable indent */
(function() {
    let $affiliateLogos = $('.js-affiliate-logo');
	function init() {
		if ($affiliateLogos.length > 0) {
			initAffiliateChecker();
		}
	}

	function initAffiliateChecker() {
        storeAffiliateId();
        let isAffiliate = isAffiliateMode();
        
        if (isAffiliate) {
            $affiliateLogos.addClass('visible');
        } 
	}

	init();
})(); 