(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaMoparCarousel', fcaMoparCarousel);

	function fcaMoparCarousel() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'moparCarousel',
			controller: FcaMoparCarouselController,
		};

		function FcaMoparCarouselController($element, $timeout) {
			'ngInject';

			const $ctrl = this;
			let lang = FCA_SITES_CONFIG.language;

			$ctrl.$onInit = () => {
				$(window).on('load resize orientationchange', function() {
					/* Initializes a slick carousel only on mobile screens */
					if ($(window).width() > 1024) {
						if ($element.hasClass('slick-initialized')) {
							$element.slick('unslick');
						}
					} else {
						if (!$element.hasClass('slick-initialized')) {
							$element.slick({
								slidesToShow: 1.2,
								slidesToScroll: 1,
								mobileFirst: true,
								dots: true,
								arrows: true,
								infinite: false,
								nextArrow:`<button class="slick-next slick-arrow" 
								 aria-label="${ lang==='en' ? 'Next' : 'Suivant' }" 
								 type="button"
								 aria-disabled="false"
								 data-analyticsid="jeep-dealer-near-you"
								 data-analyticsvalue="next"></button>`,
								prevArrow:`<button class="slick-prev slick-arrow" 
								 aria-label="${ lang==='en' ? 'Prev' : 'Précédent' }" 
								 type="button"
								 aria-disabled="false"
								 data-analyticsid="jeep-dealer-near-you"
								 data-analyticsvalue="prev"></button>`,
								responsive: [
									{
										breakpoint: 667,
										settings: {
											slidesToShow: 2.5,
										}
									}
								]
							});
						}
						$timeout(() => {
							$element.slick('refresh');
						});
					}
				});
			};
		}
	}
})();
