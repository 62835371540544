(function() {
	angular
		.module('fca.commonAjaxLegalTooltip')
		.component('fcaAjaxLegalTooltip', {
			bindings: {
				label: '@',
				type: '@',
				value: '@?',
				key: '@',
				disclaimerName: '=',
				options: '<?'
			},
			template: getTemplate(),
			controller: FcaAjaxLegalTooltipController,
			controllerAs: '$ctrl'
		});

	function FcaAjaxLegalTooltipController($element, $window, mentionService) {
		'ngInject';
		
		const $ctrl = this;
		let closeBtnLabel;

		if(window.FCA_SITES_CONFIG.language == 'fr') {
			closeBtnLabel = 'Fermer';
		} else {
			closeBtnLabel = 'Close disclaimer';
		}

		$ctrl.$postLink = () => {
			if($window.tippyInstances) {
				$window.tippyInstances.forEach(instance => {
					if(instance.settings && instance.settings.html) {
						let mentionToDisplay = mentionService.getMention(this.value);
						instance.settings.html.innerHTML = mentionToDisplay;
					}
				});
			}

			let contentNode = '';
			if (this.value) {
				contentNode = $element[0].querySelectorAll('.legal-tooltip-content')[0];
				let mentionToDisplay = mentionService.getMention(this.value);
				let element = angular.element($element[0]).find('.content');
				element.html(mentionToDisplay);
			} else {
				console.error("attenpt to load a disclaimer without value")
			}

			let $parentDiv = $($element[0]).parents('.layout-module');
			let tooltipToggle = $element[0].querySelectorAll('[data-legal-tooltip-toggle]')[0];
			let tooltipCloseBtn = $element[0].querySelectorAll('[data-legal-tooltip-close]')[0];

			if ($parentDiv.length <= 0) {
				$parentDiv = $('body');
			}

			tooltipCloseBtn.setAttribute('aria-label', closeBtnLabel);

			const options = angular.extend({
				placement: 'top',
				trigger: 'click',
				content: contentNode,
				interactive: true,
				theme: 'fcatooltips',
				arrow: true,
				appendTo: $parentDiv[0],
				onShow(instance) {
					tippy.hideAll({ exclude: instance });
				},
				onShown(instance) {
					instance.popper.querySelector('[data-legal-tooltip-content]').focus();
					instance.popper.querySelector('[data-legal-tooltip-close]').addEventListener('click', () => {
						instance.hide();
						tooltipToggle.focus();
					});

					instance.popper.querySelector('.legal-tooltip-content').addEventListener('keydown', () => {
						$ctrl.focusTrap(event, instance.popper.querySelector('.legal-tooltip-content'));
					});
				},
				onHide(instance) {
					instance.popper.querySelector('[data-legal-tooltip-close]').removeEventListener('click', () => {
						instance.hide();
						tooltipToggle.focus();
					});

					instance.popper.querySelector('.legal-tooltip-content').removeEventListener('keydown', () => {
						$ctrl.focusTrap(event, instance.popper.querySelector('.legal-tooltip-content'));
					});
				}
				// more options: https://atomiks.github.io/tippyjs/#all-settings
			}, this.options);

			const tippyInst = tippy($element[0], options); // eslint-disable-line new-cap


			let tippyInstances = $window.tippyInstances || [];
			$window.tippyInstances = tippyInstances.concat(tippyInst);
		}

		$ctrl.focusTrap = (e, element) => {
			const focusableElements = $(element).find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			const firstFocusableElement = focusableElements[0];
			const lastFocusableElement = focusableElements[focusableElements.length - 1];
			const mainContainer = $(element).find('[data-legal-tooltip-content]');

			if (e.key === 'Tab' || e.code === 'Tab') {
				if ( e.shiftKey ) {
					if (document.activeElement === firstFocusableElement) {
						mainContainer.focus();
						e.preventDefault();
					} else if (document.activeElement.hasAttribute('data-legal-tooltip-content')) {
						lastFocusableElement.focus();
						e.preventDefault();
					}

				} else {
					if (document.activeElement === lastFocusableElement) {
						mainContainer.focus();
						e.preventDefault();
					}
				}
			} else {
				return;
			}
		}
	};

	function getTemplate() {
		return `<span class="legal-tooltip">
					<button class="legal-tooltip-label is-{{::$ctrl.type}}" 
						data-legal-tooltip-toggle
						data-analytics-on 
						data-analytics-event="legalnote" 
						data-analytics-legalnote="legal note"
						ng-click="$ctrl.onClick()">
						<span>{{::$ctrl.label}}</span>
					</button>

					<data class="legal-tooltip-data" style="display: none;" aria-hidden="true">
						<div class="legal-tooltip-content">
							<div class="content" data-legal-tooltip-content tabindex="-1">
							</div>
							
							<button class="legal-tooltip-close"
									data-legal-tooltip-close>
								<i class="fcaicon fcaicon-close-thin" aria-hidden="true"></i>
							</button>
						</div>
					</data>
				</span>`;
	};
})();

