(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaConfigPipeline', AlfaRomeoConfigPipeline);

	function AlfaRomeoConfigPipeline(alfaBp, irisResponsive, gtmAnalytics, $rootScope) {
		const $service = this;

		const flatMap = (arr, func) => {
			let elems = [];
			arr.forEach(member => elems = elems.concat(func(member)))
			return elems;
		}

		$service.selectorTypes = Object.freeze({
			swatch: 'swatch',
			thumbnail: 'thumbnail',
			thumbnailBig: 'thumbnail-big',
			row: 'row',
			tileBig: 'tile-big',
			tileSmall: 'tile-small',
		});

		const sectionNames = Object.freeze({
			exteriorColor: 'exteriorColor',
			exteriorFront: 'exteriorFrontSection',
			exteriorRear: 'exteriorRearSection',
			exteriorSide: 'exteriorSideSection',
			interiorColor: 'interiorColor',
			interior: 'interiorSection',
			group: 'group',
			options: 'options',
		});

		const sectionSelectorTypes = Object.freeze({
			[sectionNames.exteriorColor]: $service.selectorTypes.swatch,
			[sectionNames.interiorColor]: $service.selectorTypes.swatch,
			[sectionNames.group]: $service.selectorTypes.tileBig,
			[sectionNames.options]: $service.selectorTypes.tileSmall,
		});

		// Tuples of [ratio, width]
		const selectorImageDimensions = Object.freeze({
			[$service.selectorTypes.swatch]: [1, 40],
			[$service.selectorTypes.thumbnail]: [1, 70],
			[$service.selectorTypes.thumbnailBig]: [1, 100],
			[$service.selectorTypes.row]: [1, 70],
		});

		const defaultImageBackground = 'white';
		const selectorImageBackgrounds = Object.freeze({
			[$service.selectorTypes.tileSmall]: 'black',
			[$service.selectorTypes.tileBig]: 'black',
		})

		// These are all the options categories which can be included
		// in the iris jelly URL to customize the rendered image.
		const paintEcc = '0082';
		const fabricEcc = '0083';
		$service.irisOptionsEccs = Object.freeze({
			wheels: '0037',
			seats: '0065',
			tires: '0038',
			brakes: '0008',
			mirrors: '0070',
			headlamps: '0081',
			bumpers: '0074',
			spoilersAndExhaust: '0071',
			exteriorAdditional: '0073',
			groups: '0006',
			interiorAdditional: '0152',
			soundSystems: '0058',
			speakerSystem: '0057',
			battery: '0054',
			cruiseControl: '0045',
			leatherSteeringWheel: '0067',
			rearHeatedSeat: '0062',
			interiorColours: '0083',
			exteriorPrimaryColours: '0082'
		});

		let optionImageBaseUrl = '';

		//this broadcast comes from alfaromeo-pricing-summary.component.js
		$rootScope.$on("Alfa-Pricing-Summary-Loaded", () => {
			function checkYOffset() {
				//wait for blocks to be loaded in page
					let exterior = document.getElementById('interior');
					let powerTrain = document.getElementById('powertrain');
					let NavGroups = document.getElementById('groups');
					let NavSummary = document.querySelector('alfa-pricing-summary');
					//then set an interval to check how far did the user reach
					let throttleCounter = 0;
					let checkYInterval = () => {
						let exteriorLimit = window.pageYOffset > exterior.offsetTop;
						let powertrainLimit = window.pageYOffset > powerTrain.offsetTop;
						let navgroupsLimit = window.pageYOffset > NavGroups.offsetTop;
						let navsummaryLimit = window.pageYOffset > NavSummary.offsetTop;
						if(exteriorLimit && throttleCounter === 0) {
							triggerInteriorEvent();
							throttleCounter++;
						}
						if(powertrainLimit && throttleCounter === 1) {
							triggerPowerTrainEvent();
							throttleCounter++;
						}
						if(navgroupsLimit && throttleCounter === 2) {
							triggerNavGroupsEvent();
							throttleCounter++;
						}
						if(navsummaryLimit && throttleCounter === 3) {
							triggerNavSummaryEvent();
							throttleCounter++;
						}
					};
					window.addEventListener('scroll', checkYInterval);
			}
			checkYOffset();

			function triggerInteriorEvent() {
				addTracking('build-price-scroll-nav-interior','App-Suite-buildandprice');
			}
			function triggerPowerTrainEvent() {
				addTracking('build-price-scroll-nav-powertrain','App-Suite-buildandprice');
			}
			function triggerNavGroupsEvent() {
				addTracking('build-price-scroll-nav-groups','App-Suite-buildandprice');
			}
			function triggerNavSummaryEvent() {
				addTracking('build-price-scroll-nav-summary','App-Suite-buildandprice');
			}

			function addTracking(trackingID, category) {
				gtmAnalytics.trackEvent('event', {
					label: `${trackingID}`,
					category: `${category}`
				});
			}
		});

		$service.makeViewConfig = (config, optionBaseUrl, swatchesData, onlyShowEccCodes = []) => {
			optionImageBaseUrl = optionBaseUrl;

			const selectorSections = {}
			let exclusiveSelectorSections = {}
			for (const [key, name] of Object.entries(sectionNames)) {
				if (!config[name]) continue;
				if (!config[name].categories) continue;

				selectorSections[key] = config[name];
			}

			const allSelectedOptions = $service.getAllSelectedOptions(config);
			$service.pruneUselessCategories(selectorSections);
			const allCategories = flatMap(
				Object.values(selectorSections), section => section.categories
			);

			$service.addCategorySelectorTypes(selectorSections);
			$service.addOptionsProperties(allCategories, swatchesData);

			angular.copy(selectorSections, exclusiveSelectorSections);
			$service.pruneUselessCategories(exclusiveSelectorSections, onlyShowEccCodes, false);

			const groupedSections = $service.groupSections(selectorSections);
			const groupedExclusiveSections = $service.groupSections(exclusiveSelectorSections);
			const jellyCategories = $service.groupJellyCategories(allCategories, allSelectedOptions);

			return {
				allSelectedOptions,
				sections: groupedSections,
				exclusiveSections: groupedExclusiveSections,
				jellyCategories,
			};
		}

		$service.pruneUselessCategories = (selectorSections, onlyShowEccCodes = [], spliceExteriorColor = true) => {
			if (spliceExteriorColor) {
				selectorSections.exteriorColor.categories.splice(0, 1);
			}

			for (const section of Object.values(selectorSections)) {
				section.categories.forEach((category) => {
					category.onlyShowEccCodes = onlyShowEccCodes;
				});
				section.categories =
					section.categories.filter($service.categoryIsUseful);
			}
		}

		$service.categoryIsUseful = category => (
			category.options &&
			category.options.length &&
			(
				(category.options.length > 1 || !category.options[0].mutuallyExclusive) &&
				(category.onlyShowEccCodes.length == 0 || category.onlyShowEccCodes.includes(category.options[0].ecc) )
			)
		)

		$service.addCategorySelectorTypes = selectorSections => {
			for (const [sectionName, selectorType] of Object.entries(sectionSelectorTypes)) {
				if (!selectorSections[sectionName]) continue;

				$service.setTypeForSection(
					selectorSections[sectionName], selectorType
				)
			}
		}

		$service.setTypeForSection = (selectorSection, selectorType) => {
			selectorSection.categories.forEach(category =>
				$service.optimisticallySetCategoryType(category, selectorType)
			);
		}

		// Set the category's selector type to the given value *if* it's not
		// already set.
		$service.optimisticallySetCategoryType = (category, selectorType) => {
			if (!category.view) {
				category.view = selectorType;
			}
		}

		$service.addOptionsProperties = (allCategories, swatches) => {
			allCategories.forEach(category => {
				category.options.forEach(option => {
					$service.setIsDeselectable(option, category);
					$service.addTrimmedCode(option);
					$service.addFeatures(option);
					$service.addSwatchUrl(option, swatches);
					$service.addImageUrl(option, category);
				});
			});
		}

		$service.setIsDeselectable = (option, category) => {
			option.isDeselectable = (!category.mutuallyExclusive && category.view !== $service.selectorTypes.swatch);
		}

		$service.addTrimmedCode = option => {
			// Most option codes have a trailing underscore and numbers. For
			// almost everything that we do inside BP we just want the part
			// *before* the underscore, so we add a `trimmedCode` property to
			// avoid duplicating this replace call everywhere.
			option.trimmedCode = option.code.replace(/_.*$/, '');
		}

		$service.addFeatures = option => {
			// An option's features (if it has any) comes in a
			// caret-and-white-space-delimited string. We would prefer to work
			// with a list, so we'll go ahead and split the `content` property
			// and put the result in `features`.
			if (option.content) {
				option.features = option.content.split(/\^\s+/).filter(Boolean);
			}
		}

		$service.addImageUrl = (option, category) => {
			if (option.mediaPath) {
				option.imageUrl = option.mediaPath;
				return;
			}
			if (option.swatchUrl) {
				option.imageUrl = option.swatchUrl;
				return;
			}
			let queryOptions = {
				view: 'static',
				bkgnd: selectorImageBackgrounds[category.view] ||
					defaultImageBackground,
			}

			const ratioWidth = selectorImageDimensions[category.view];

			if (ratioWidth) {
				const [ratio, width] = ratioWidth;

				const dimensions =
					irisResponsive.calcDimensions({
						ratio,
						maxWidth: width
					});

				queryOptions = Object.assign(dimensions, queryOptions);
			}

			const queryString = alfaBp.encodeQueryParameters(queryOptions);

			option.imageUrl =
				`${optionImageBaseUrl}${option.trimmedCode}____&${queryString}`;
		}

		$service.addSwatchUrl = (option, swatches) => {
			for (let i = 0; i < swatches.data.length; i++) {
				const swatchCategory = swatches.data[i];
				if (swatchCategory.eccCode !== option.ecc) {
					continue;
				}
				for (let j = 0; j < swatchCategory.options.length; j++) {
					const swatch = swatchCategory.options[j];
					if (swatch.code !== option.trimmedCode) {
						continue;
					}
					if (!swatch.path) {
						return;
					}
					option.swatchUrl = swatches.baseUrl + swatch.path;
					return;
				}
			}
		}

		$service.groupSections = sections => {
			const exteriorColor = sections.exteriorColor;
			const exteriorFront = sections.exteriorFront;

			// For purposes of layout and animation, exterior colors and exterior
			// front are the same section; we'll go ahead and combine them here.
			const combinedFrontCategories =
				exteriorColor.categories.concat(exteriorFront.categories);

			const combinedFrontSection = {
				categories: combinedFrontCategories,
				description: exteriorFront.description,
			}

			return {
				exterior: new Map([
					["front", combinedFrontSection],
					["side", sections.exteriorSide],
					["rear", sections.exteriorRear],
				]),
				interior: {
					colors: sections.interiorColor,
					sections: sections.interior,
				},
				groups: sections.group,
			}
		};

		$service.groupJellyCategories = (allCategories, allSelectedOptions) => {
			const categoriesByEcc = {};
			const result = {
				options: []
			};

			allCategories.forEach(category => {
				if (category.options && category.options.length) {
					categoriesByEcc[category.options[0].ecc] = category;
				}
			});

			result.paint = $service.selectedOptionCode(categoriesByEcc[paintEcc]);
			result.fabric = $service.selectedOptionCode(categoriesByEcc[fabricEcc]);

			allCategories.forEach(category => {
				const codes = $service.getAllSelectedOptionsCodeForCategory(category);
				result.options = result.options.concat(codes);
			});

			allSelectedOptions.forEach(option => {
				result.options = result.options.concat(option.code);
			});

			return result;
		}

		$service.getAllSelectedOptionsCodeForCategory = category => {
			return category.options
				.filter(option => option.selected)
				.map(option => option.trimmedCode);
		}

		$service.selectedOptionCode = category => {
			const selectedOption = category.options.find(
				option => option.selected
			);

			return selectedOption ? selectedOption.trimmedCode : null;
		};

		$service.allOptionsInConfig = config => {
			let options = [];

			for (const sectionName of Object.values(sectionNames)) {
				if (!config[sectionName]) continue;
				if (!config[sectionName].categories) continue;

				for (const category of config[sectionName].categories) {
					options = options.concat(category.options);
				}
			}

			return options;
		}

		$service.getAllSelectedOptions = config => {
			let result = [];
			Object.values(config).forEach(section => {
				if (section.categories) {
					section.categories.forEach(category => {
						result = result.concat(selectedOptions(category));
					})
				}
			})
			return result;
		}

		const selectedOptions = category => {
			return category.options.filter(option => option.selected)
		}

		$service.categoriesByEcc = (config) => {
			const categoriesByEcc = {};

			Object.values(config).forEach(section => {
				if (!section.categories) return;

				section.categories.forEach(category => {
					if (!category.options || !category.options.length) return;

					const tentativeEcc = category.options[0].ecc;

					// Some categories (such as groups) have mixed ecc codes. We
					// want to ignore these categories.
					if (category.options.every(o => o.ecc = tentativeEcc)) {
						categoriesByEcc[tentativeEcc] = category;
					}
				});
			});

			return categoriesByEcc;
		}

		$service.getSelectedOptionsForEccCode = (config, ecc) => {
			const categoriesByEcc = $service.categoriesByEcc(config);

			if (!categoriesByEcc[ecc]) return [];

			return categoriesByEcc[ecc].options
				.filter(option => option.selected)
				.map(option => option.trimmedCode);
		}
	}
})();
