(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculatorWidget')
		.service('daaCalculator', Calculator);

	/**
	 * @ngdoc service
	 * @name fca.daaCalculatorWidget.service:daaCalculator
	 * @description Calculate payment for cash, leasing, finance options
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function Calculator() {
		'ngInject';

		/**
		 * @ngdoc method
		 * @name preCalculateLeasing
		 * @methodOf fca.daaCalculatorWidget.service:daaCalculator
		 * @description Calculate leasing payments cf. {@link http://www.leaseguide.com/lease08/ formula}
		 * @param  {Object} opts List of options for calculate payment
		 * @return {Object} List of options with the property toPay
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.preCalculateLeasing = (opts) => {
			/* Destructuration opts properties */
			let {
				dp,
				tiv,
				rp,
				frequency,
				term,
				residual,
				leaseKilometer,
				financedAmount,
				nonResidualizedAmount,
				provincialSalesTaxList,
				location
			} = opts;
			/* Less kilometers to increse resisual percent to 2% */
			if (leaseKilometer === 18000) {
				let percent = 2;
				if (term >= 72 || term === 12) {
					percent = 0;
				}

				residual += percent;
			}

			// dp : down payment
			// tiv : trade in value
			// rp : interest rate
			// frequency : nb of payment per year

			/* Nb total payment */
			let nbPayment = (term / 12) * frequency;
			/* Base price to finance */
			let p = financedAmount - (dp + tiv);
			p += nonResidualizedAmount;
			/* Deprecated rate factor for residual vehicle value */
			let drf = residual / 100;
			/* Residual value */
			let rv = financedAmount * drf;
			/* Payment */
			let toPay = calc(rp / frequency, nbPayment, p, -rv, 1);

			let leasePaymentWithTax = calcLeasePaymentWithTax(toPay, drf, tiv,
				dp, rp, frequency,
				term, financedAmount, location,
				provincialSalesTaxList, nonResidualizedAmount);

			if (toPay < 0) {
				toPay = 0;
			}

			if (leasePaymentWithTax < 0) {
				leasePaymentWithTax = 0;
			}

			let finance = ng.extend({}, opts, {
				toPay: Math.round(toPay).toFixed(0),
				leasePaymentWithTax: Math.round(leasePaymentWithTax).toFixed(0)
			});

			return finance;
		};

		/**
		 * @ngdoc method
		 * @name preCalculateCash
		 * @methodOf fca.daaCalculatorWidget.service:daaCalculator
		 * @description Calculate payment by cash
		 * @param  {Object} opts List of options for calculate payment
		 * @return {Object} List of options with the property toPay
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.preCalculateCash = (opts) => {
			/* Destructuration opts properties */
			let {
				dp,
				tiv,
				offerPrice
			} = opts;

			let c = offerPrice - (dp + tiv);
			let finance = ng.extend({}, opts, {
				toPay: Math.round(c).toFixed(0)
			});

			return finance;
		};

		/**
		 * @ngdoc method
		 * @name preCalculateFinance
		 * @methodOf fca.daaCalculatorWidget.service:daaCalculator
		 * @param  {Object} opts List of options for calculate payment
		 * @return {Object}      List of options with the property toPay
		 * @description
		 * Calculate payment with a set of options cf. {@link https://www.ilemaths.net/calcul-credit.php#tauxfixe formula}
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.preCalculateFinance = (opts) => {
			/* Destructuration opts properties */
			let {
				financedAmount,
				dp,
				tiv,
				rp,
				frequency,
				term,
				provincialSalesTaxList,
				location,
				nonResidualizedAmount,
				beforeTaxDiscount,
				afterTaxDiscount
			} = opts;

			let nbPayment = (term / 12) * frequency;
			let toFinance = financedAmount - (dp + tiv);
			let toPay = calc(rp / frequency, nbPayment, toFinance);
			let checkedProvince = checkIfProvinceExists(location);
			let provincialTaxes = getSaleTaxesForProvince(
				checkedProvince.toLowerCase(), provincialSalesTaxList, toFinance);
			let taxAmount = 0;
			if (provincialTaxes.taxes.length > 1) {
				taxAmount = parseFloat(provincialTaxes.taxes[0].tax) +
					parseFloat(provincialTaxes.taxes[1].tax);
			} else {
				taxAmount = parseFloat(provincialTaxes.taxes[0].tax);
			}
			let financePaymentWithTax = calcFinancePaymentWithTax(financedAmount, dp, tiv, rp,
				frequency, term, taxAmount, nonResidualizedAmount, afterTaxDiscount);
			let finance = ng.extend({}, opts, {
				toPay: Math.round(toPay).toFixed(0),
				realValueTopay : toPay,
				financePaymentWithTax: Math.round(financePaymentWithTax).toFixed(0)
			});

			return finance;
		};

		/**
		 * @description Calculate finance with interest
		 * @param  {Number} toFinance Amount to finance
		 * @param  {Float}  rp Interest rate in percent (div. by payment per year)
		 * @param  {Number} np Number total of payment (term * frequency)
		 * @param  {Number} fv To finance value
		 * @param  {Number} rvv Residual vehicle value
		 * @param  {Interger} type If payment is at start or end month (0 -> start, 1 -> end)
		 * @return {Float}  Amount to pay per term payment
		 */
		function calc(rp, np, fv, rvv = 0, type = 0) {
			rp /= 100;
			let vpm;
			if (rp === 0) {
				vpm = (fv + rvv) / np;
			}

			if (vpm === undefined) {
				let rate = Math.pow(1 + rp, -1 * np);
				vpm = ((fv + rvv * rate) * rp / (1 - rate)) / (1 + rp * type);
			}

			return vpm;
		}

		function calcLeasePaymentWithTax(leasePaymentWithoutTax, drf,
										tradeInValue, dp, rp, frequency, term,
								financedAmount, location, provincialSalesTaxList,
										nonResidualizedAmount) {
			// Frequency corresponds to number of payments per year
			let checkedProvince = checkIfProvinceExists(location);

			let provincialTaxes = getSaleTaxesForProvince(
				checkedProvince.toLowerCase(), provincialSalesTaxList,
				financedAmount);

			let gstHstTax = 0;
			let pstTax = 0;

			for (var i = 0; i < provincialTaxes.taxes.length; i++) {
				if (provincialTaxes.taxes[i].taxType === 'gstHst') {
					gstHstTax = parseFloat(provincialTaxes.taxes[i].tax);
				}
				if (provincialTaxes.taxes[i].taxType === 'pst') {
					pstTax = parseFloat(provincialTaxes.taxes[i].tax);
				}
			}

			let otherNonResidualizedAmountsWithoutTradeIn = nonResidualizedAmount - dp;

			let pstLeasePayment = leasePaymentWithoutTax;
			let nbPayment = (term / 12) * frequency;
			let pstAmortizedAmount = 0.00;
			if ((checkedProvince === 'BC' || checkedProvince === 'SK') && tradeInValue > 0) {
				let pstAmortizedAmountWithTradeIn = financedAmount +
					otherNonResidualizedAmountsWithoutTradeIn;
				if (pstAmortizedAmountWithTradeIn > 0.00) {
					pstAmortizedAmount = pstAmortizedAmountWithTradeIn;
				}
				let pstResidualValue = financedAmount * drf;
				pstLeasePayment = calc(rp / frequency, nbPayment,
					pstAmortizedAmount, -pstResidualValue, 1);
			}

			let gstHstTaxAmountPerPayment = 0;
			if (gstHstTax > 0) {
				gstHstTaxAmountPerPayment = leasePaymentWithoutTax * (gstHstTax / 100);
			}

			let pstTaxAmountPerPayment = 0;
			if (pstTax > 0) {
				pstTaxAmountPerPayment = pstLeasePayment * (pstTax / 100);
			}

			let leasePaymentWithTax = leasePaymentWithoutTax + gstHstTaxAmountPerPayment +
				pstTaxAmountPerPayment;

			return Math.round(leasePaymentWithTax).toFixed(0);
		}

		function calcFinancePaymentWithTax(financedAmount, downpayment,
										tradeInValue, rp, frequency, term,
										totalTax, nonResidualizedAmount,
										afterTaxDiscount) {
			let tax = (totalTax + 100) / 100;

			// add after taxes discount (we'll subtract it after taxes are calculated)
			financedAmount += afterTaxDiscount;
			// subtract trade-in (it's calculated before taxes)
			financedAmount -= tradeInValue;
			financedAmount *= tax;
			// subtract the after tax discount
			financedAmount -= afterTaxDiscount;
			// subtract the down payment
			financedAmount -= downpayment;
			let nbPayment = (term / 12) * frequency;
			let financePaymentWithTax = calc(rp / frequency, nbPayment, financedAmount);
			// round that amount
			return Math.round(financePaymentWithTax).toFixed(0);
		}

		function checkIfProvinceExists(location) {
			let provinceChecked = '';
			if (location === null || location === 'undefined') {
				provinceChecked = 'ON';
			} else {
				provinceChecked = location.province;
			}
			return provinceChecked;
		}

		function getSaleTaxesForProvince(province, saleTaxes, amount) {
			var saleTaxesObject = saleTaxes[0];
			for (var i = 0; i < saleTaxes.length; i++) {
				if (province === saleTaxes[i].provinceCode) {
					saleTaxesObject = saleTaxes[i];
				}
			}
			if (saleTaxesObject.hasTaxBracket) {
				var bracketsSaleTaxesObject = saleTaxesObject;
				if (bracketsSaleTaxesObject.taxBrackets !== undefined) {
					for (var y = 0; y < bracketsSaleTaxesObject.taxBrackets.length; y++) {
						var lesserThan = Number.MAX_VALUE;
						var higherThan = Number.MIN_VALUE;
						if (bracketsSaleTaxesObject.taxBrackets[y].lesserThan !== null) {
							lesserThan = bracketsSaleTaxesObject.taxBrackets[y].lesserThan;
						}
						if (bracketsSaleTaxesObject.taxBrackets[y].higherThan !== null) {
							higherThan = bracketsSaleTaxesObject.taxBrackets[y].higherThan;
						}
						if (lesserThan >= amount && higherThan <= amount) {
							saleTaxesObject.taxes = bracketsSaleTaxesObject.taxBrackets[y].taxes;
						}
					}
				}
			}
			return saleTaxesObject;
		}
	}
})(angular);
