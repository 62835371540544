
(function() {
	angular
		.module('fca.commonDirectives')
		.directive('featureCompare', featureCompare);

	function featureCompare() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsCategory: '@?',
				appendArrows: '@?',
				appendDots: '@?',
				appendDotsMobile: '@?',
				mobileFullCtaWidth: '@?',
				staticSlide: '@?',
				slidesToShowMobile: '@?',
				mobileArrows: '=?',
				noDrag: '@?',
				isOnlyram: '@?'
			},
			controllerAs: 'featureCompare',
			controller: featureCompareController
		};

		function featureCompareController($timeout, $element, $window, gtmAnalytics, cleanCarousel) { // eslint-disable-line max-len
			'ngInject';

			let $ctrl = this;

			$ctrl.mainSlider = $element.find('[data-feature-compare-main-slider]');
			$ctrl.featureList = $element.find('[data-feature-compare-feature-list]');
			$ctrl.sliderNav = $element.find('[data-feature-compare-slider-nav]');
			$ctrl.currentSlide = false;
			$ctrl.appendArrowsTarget = $ctrl.sliderNav;
			$ctrl.appendDotsTarget = $ctrl.sliderNav;

			let isChanging = false;
			let isFiltered = false;
			let doubleWidthBreakpoint = 667;
			let smallBreakpoint = 667;
			let mediumBreakpoint = 960;
			let largeBreakpoint = 1200;
			let defaultSlidesToShow = 4;
			let largeBreakpointSlidesToShow = 3;
			let mediumBreakpointSlidesToShow = 2;
			let smallBreakpointSlidesToShow = 1;
			let appendDotsMobile = true;
			let drag = true;

			$ctrl.$postLink = () => {
				$ctrl.mobileArrows = $ctrl.mobileArrows || false;

				$timeout(() => {
					$ctrl.initMasterCarousel();
				});
			};

			$ctrl.initMasterCarousel = () => {
				if($ctrl.staticSlide === "true") {
					defaultSlidesToShow = 3;
					largeBreakpointSlidesToShow = 2;
					mediumBreakpointSlidesToShow = 1;
				}

				if($ctrl.noDrag === "true") {
					drag = false;
				}

				if($ctrl.slidesToShowMobile) {
					smallBreakpointSlidesToShow = $ctrl.slidesToShowMobile;
				}

				if($ctrl.appendDotsMobile === "false") {
					appendDotsMobile = false
				}

				if($ctrl.appendArrows === "false") {
					$ctrl.appendArrowsTarget = $ctrl.mainSlider;
				}

				if($ctrl.appendDots === "false") {
					$ctrl.appendDotsTarget = $ctrl.mainSlider;
				}

				$ctrl.mainSlider.on('init breakpoint', function(event, slick) {
					$ctrl.totalTrims = slick.slideCount;

					if (!isChanging) {
						// Prevent infinite loop
						isChanging = true;
						// The highest breakpoint is null
						if (!slick.activeBreakpoint || (slick.activeBreakpoint && slick.activeBreakpoint > doubleWidthBreakpoint)) {
							if (!isFiltered) {
								slick.slickFilter(':not(.mobile-only)');
								isFiltered = true;
							}
						} else {
							if (isFiltered) {
								slick.slickUnfilter();
								isFiltered = false;
							}
						}
						isChanging = false;
					}
					
					cleanCarousel.removeAriaHidden($element);
					
				}).slick({
					appendArrows: $ctrl.appendArrowsTarget,
					appendDots: $ctrl.appendDotsTarget,
					arrows: true,
					dots: true,
					slidesToShow: defaultSlidesToShow,
					slidesToScroll: 1,
					infinite: false,
					draggable: drag,
					swipe: drag,
					swipeToSlide: drag,
					touchMove: drag,
					accessibility: drag,
					responsive: [
						{
							breakpoint: largeBreakpoint,
							settings: {
								slidesToShow: largeBreakpointSlidesToShow
							}
						},
						{
							breakpoint: mediumBreakpoint,
							settings: {
								slidesToShow: mediumBreakpointSlidesToShow
							}
						},
						{
							breakpoint: smallBreakpoint,
							settings: {
								arrows: $ctrl.mobileArrows,
								slidesToShow: smallBreakpointSlidesToShow,
								centerMode: true,
								centerPadding: '18px'
							}
						}
					]
				});
				$ctrl.calculateTrimSizes();
				$ctrl.windowResizeWatcher();
				$ctrl.beforeSlideChange();
				$ctrl.afterSlideChange();
			};

			$ctrl.setTotalSlides = () => {
				$ctrl.mainSlider.on('init', (event, slick) => {
					$ctrl.totalTrims = slick.slideCount;
				});
			};


			$ctrl.nextSlide = (event, slick, currentSlide, nextSlide) => {
				$element.find('.slick-slider').slick('slickNext');
			};
			$ctrl.prevSlide = (event, slick, currentSlide, nextSlide) => {
				$element.find('.slick-slider').slick('slickPrev');
			};


			$ctrl.beforeSlideChange = () => {
				$ctrl.mainSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					let nextSlideNumber = nextSlide + 1;
					let trackingMessage = 'go to slide ' + nextSlideNumber;

					if(currentSlide !== nextSlide) {
						gtmAnalytics.trackEvent('event', {
							category: $ctrl.analyticsCategory,
							label: trackingMessage
						});
					}

					$ctrl.animateCarousel(nextSlide);
					cleanCarousel.removeAriaHidden($element);
					angular.element('[data-feature-compare-main-slider]').slick('slickGoTo', nextSlide);
				});
			};
			
			$ctrl.afterSlideChange = () => {
				$ctrl.mainSlider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
					cleanCarousel.removeAriaHidden($element);
				});
			};

			$ctrl.calculateSlidesToShow = () => {
				let visibleSlides;
				let windowWidth = $window.innerWidth;

				if (windowWidth < smallBreakpoint) {
					visibleSlides = smallBreakpointSlidesToShow;
				} else if (windowWidth >= smallBreakpoint && windowWidth < mediumBreakpoint) {
					visibleSlides = mediumBreakpointSlidesToShow;
				} else if (windowWidth >= mediumBreakpoint && windowWidth < largeBreakpoint) {
					visibleSlides = largeBreakpointSlidesToShow;
				} else {
					visibleSlides = defaultSlidesToShow;
				}

				return visibleSlides;
			};

			$ctrl.animateCarousel = (slide) => {
				let slidesTotal = $ctrl.mainSlider.slick('getSlick').slideCount;
				let slidesToShow = $ctrl.calculateSlidesToShow();
				let carouselWidth = Math.ceil($ctrl.mainSlider.width());
				let trimSize = Math.ceil(carouselWidth / slidesToShow);
				let ctaSize = trimSize;
				let featureListWidth = Math.ceil($ctrl.featureList.width());
				let windowWidth = $window.innerWidth;

				if(windowWidth < doubleWidthBreakpoint) {
					ctaSize = Math.ceil(featureListWidth / slidesToShow);

					if($ctrl.mobileFullCtaWidth === "true") {
						trimSize = Math.ceil(featureListWidth / slidesToShow);
						if ($ctrl.isOnlyram === 'true') {
							trimSize -= 8;
						}
					} else {
						trimSize = Math.ceil(featureListWidth / slidesToShow / 2);
					}
				}

				let scrollDistance = (trimSize * slide) * -1;
				let ctaScrollDistance = (ctaSize * slide) * -1;

				$ctrl.currentSlide = slide;

				if(slidesTotal > slidesToShow) {
					$element.find('[data-feature-compare-feature-slider]').css('transform', `translate3d(${scrollDistance}px, 0, 0)`);
					$element.find('[data-feature-compare-cta-slider]').css('transform', `translate3d(${ctaScrollDistance}px, 0, 0)`);
				} else {
					$element.find('[data-feature-compare-feature-slider]').css('transform', 'none');
					$element.find('[data-feature-compare-cta-slider]').css('transform', 'none');
				}
			};

			$ctrl.calculateTrimSizes = () => {
				let slidesToShow = $ctrl.calculateSlidesToShow();
				let carouselWidth = Math.ceil($ctrl.mainSlider.width());
				let featureListWidth = Math.ceil($ctrl.featureList.width());
				let trimSize = Math.ceil(carouselWidth / slidesToShow);
				let ctaSize = trimSize;
				let windowWidth = $window.innerWidth;

				if(windowWidth < doubleWidthBreakpoint) {
					ctaSize = Math.ceil(featureListWidth / slidesToShow);

					if($ctrl.mobileFullCtaWidth === "true") {
						trimSize = Math.ceil(featureListWidth / slidesToShow);
						if ($ctrl.isOnlyram === 'true') {
							trimSize -= 8;
						}
					} else {
						trimSize = Math.ceil(featureListWidth / slidesToShow / 2);
					}
				}

				let trimCount = $element.find('[data-feature-compare-feature-slider-cell]').length;
				let carouselSize = trimCount * trimSize;
				let trainStops = $element.find('.C_FC-slider-nav');

				// adjust static slide on mobile
				if ($element.find('.C_FC-static-slide-mobile') && windowWidth < doubleWidthBreakpoint && $ctrl.mobileFullCtaWidth === "true") {
					$element.find('.C_FC-static-slide-mobile').css('width', `${ctaSize}px`);
				} else {
					if ($element.find('.C_FC-static-slide-mobile').length) {
						$element.find('.C_FC-static-slide-mobile')[0].style.removeProperty('width');
					}
				}

				$element.find('[data-feature-compare-feature-cell]').css('width', `${trimSize}px`);
				$element.find('[data-feature-compare-cta-cell]').css('width', `${ctaSize}px`);
				$element.find('[data-feature-compare-feature-row]').css('width', `${carouselSize}px`);
				$element.find('[data-feature-compare-cta-row]').css('width', `${carouselSize}px`);

				if ($ctrl.isOnlyram === 'true' && $element.find('.C_FC-static-slide-mobile') && windowWidth < doubleWidthBreakpoint && $ctrl.mobileFullCtaWidth === "true") {
					trainStops.css('width', `${trimSize}px`);
				}
			};

			$ctrl.windowResizeWatcher = () => {
				$ctrl.realTime;
				$ctrl.timeoutActive = false;
				$ctrl.resizeDelay = 300;

				angular.element($window).on('resize', () => {
					$ctrl.realTime = new Date();

					if ($ctrl.timeoutActive == false) {
						$ctrl.timeoutActive = true;
						$timeout($ctrl.windowWasResized, $ctrl.resizeDelay);
					}
				});
			};

			$ctrl.windowWasResized = () => {
				cleanCarousel.removeAriaHidden($element);
				
				if (new Date() - $ctrl.realTime < $ctrl.resizeDelay) {
					$timeout($ctrl.windowWasResized, $ctrl.resizeDelay);
				} else {
					$ctrl.timeoutActive = false;
					$ctrl.calculateTrimSizes();
					$ctrl.animateCarousel($ctrl.currentSlide);
				}
			};
		}
	}
})();
