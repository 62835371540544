(function () {
    'use strict';

    angular
        .module('fca.commonServices')
        .service('dealerChatService', dealerChatService);

    function dealerChatService (externalConfigLoader) {
        'ngInject';

        const $service = this;

        // chat providers
        const GUBAGOO = 'Gubagoo';
        const DMT = 'Keyloop';
        const threeSixtyAgency = '360 Agency';
        const contactAtOnce = 'Live Person';
        const optimy = 'Optimy';

        // DMT configuration
        const DMT_SOURCE_FCA = '7a5b459f-914c-4f06-a524-cf6248b21715';
        const DMT_OEM_FCA = 'fca';

        // DMT has specific configuration for alfa romeo
        const DMT_OEM_ALFA = 'fcaar';
        const DMT_SOURCE_ALFA = 'f69f77a7-60b5-49bb-b81d-5655f5b1f24f';
        const BRAND_ALFA = 'alfaromeo';

        let sitesConfig = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
        const LANG = sitesConfig.getConfig('language');
        const BRAND = sitesConfig.getConfig('name');

        /**
         * determine the chat url
         * chat provider is gubagoo|dmt|360 Agency|contact_at_ounce
         * chat provider id is the id from that provider (used only for contact at ounce)
         */
        $service.getDealerChatUrl = (chatProvider, chatProviderId, dealerCode, originationUrl) => {
            let result = false;
            if(chatProvider === GUBAGOO) {
                result = `https://gubagoo.io/live-chat/fca_toolbar/${dealerCode}`;
            } else if (chatProvider === DMT) {
                let oemcode = DMT_OEM_FCA;
                let source = DMT_SOURCE_FCA;
                if (BRAND === BRAND_ALFA) {
                    oemcode = DMT_OEM_ALFA;
                    source = DMT_SOURCE_ALFA;
                }
                result = `https://dispatch-canada.digital-interview.com/${LANG}-ca/directlaunch?source=${source}&oemcode=${oemcode}&dealercode=${dealerCode}`;
            } else if (chatProvider === threeSixtyAgency) {
                result = `https://direct.cchat.io/${LANG}/${dealerCode}`;
            } else if (chatProvider === contactAtOnce) {
                result = `http://chryslerca.contactatonce.com/caoclientcontainer.aspx?ProviderId=${chatProviderId}&MerchantId=${dealerCode}`;
                // origination url is only used for contact at ounce
                if (originationUrl) {
                    result = result + '&OriginationUrl=' + originationUrl;
                }
            } else if (chatProvider === optimy) {
                result = `https://optimy.app/chat/${dealerCode}?lang=${LANG}`;
            }
            return result;
        }
    }
})();