(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaBuyOnlineCollapse', fcaBuyOnlineCollapse);

	function fcaBuyOnlineCollapse() {
		return {
			restrict: 'A',
			controller: fcaBuyOnlineCollapseController,
			controllerAs: 'fcaBuyOnlineCollapse',
			scope: true
		};

		function fcaBuyOnlineCollapseController() {
			'ngInject';

			const $ctrl = this;
			$ctrl.isOpen = false;

			this.toggleCollapse = () => {
				$ctrl.isOpen = !$ctrl.isOpen;
			};
		}
	}
})();
