(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaChangeDealer', {
			templateUrl: '/components/contact-dealer/change-dealer/change-dealer.html',
			transclude: true,
			bindings: {
				isActive: '<',
				selectedDealerCodes: '<',
				selectedRegion: '<',
				selectFn: '&',
				cancelFn: '&',
				brandCode: '<',
				analyticsCategory: '@',
				analyticsId: '@',
				analyticsValue: '@'
			},
			controller: daaChangeDealerController
		});

	function daaChangeDealerController($rootScope, ContactDealer, externalConfigLoader) {
		'ngInject';

		/* Set identifier flag for the instance */
		let identifier = Math.random();

		let deregisterDealerChange;

		this.visibleDealersList = [];

		this.$onChanges = (changes) => {
			if (changes.isActive && changes.isActive.currentValue === true) {
				$rootScope.$emit('contact-dealer.dealer-change', identifier);
			}
		};

		this.$onInit = () => {
			// Bind listener to close other instance of the component
			deregisterDealerChange = $rootScope.$on('contact-dealer.dealer-change', (event, source) => { // eslint-disable-line max-len
				// if event not from me close
				if (this.isActive && source !== identifier) {
					this.cancel();
				}
			});

			let loc = this.selectedRegion;

			let brand = externalConfigLoader.loadConfig('DAA', 'BRAND').getConfig('name');
			// Override brand to load nameplates
			if (this.brandCode !== undefined) {
				brand = this.brandCode;
			}
			ContactDealer
				.getOtherDealers(brand, this.selectedDealerCodes, loc.latitude, loc.longitude)
				.then((dealersArr) => {
					let maxLengthBeforeShowMore = 7;
					let listCutoffLength = 5;

					this.dealersFullList = dealersArr;

					this.dealersSubsetList = [];

					if (dealersArr.length > maxLengthBeforeShowMore) {
						let i = 0;

						// Build subset list
						while (i < listCutoffLength) {
							this.dealersSubsetList.push(dealersArr[i]);
							i++;
						}

						this.visibleDealersList = this.dealersSubsetList;
						this.showAllResults = false;
					} else {
						this.showAllDealers();
					}
				});
		};

		this.$onDestroy = () => {
			deregisterDealerChange();
		};

		this.showAllDealers = (event) => {
			if (event) {
				event.preventDefault();
			}

			if (this.dealersFullList.length) {
				this.visibleDealersList = this.dealersFullList;
			} else {
				this.loadError = 'No results';
			}

			this.showAllResults = true;
		};

		this.cancel = (event) => {
			if (event) {
				event.preventDefault();
			}

			this.cancelFn()(event);
		};

		this.select = (dealer, event) => {
			if (event) {
				event.preventDefault();
			}

			this.selectFn()(dealer, event);
			this.cancelFn()(event);
		};
	}
})();
