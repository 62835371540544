(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaInstagramFeed', fcaInstagramFeed);

	function fcaInstagramFeed() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				token: '@',
				userId: '@',
				numPhotos: '@',
				analyticsId: '@'
			},
			controller: FcaInstagramFeedController
		};
	}

	function FcaInstagramFeedController($element) {
		'ngInject';

		this.$onInit = () => {
			let token = String(this.token);
			let userid = Number(this.userId);
			let numPhotos = this.numPhotos ? Number(this.numPhotos) : 3;
			let analyticsId = "";

			if (this.analyticsId) {
				analyticsId = `data-analyticsid=${this.analyticsId}`;
			}

			$.ajax({
				url: `https://api.instagram.com/v1/users/${userid}/media/recent`,
				dataType: 'jsonp',
				type: 'GET',
				data: {access_token: token, count: numPhotos},
				success: function(data) {
					let wordsArray;
					let newMessage;
					let captionText;

					for (let x = 0; x < data.data.length; x++ ) {
						if(data.data[x].caption) {
							captionText = cleanCaption(data.data[x].caption.text);
							wordsArray = deconstructPost(captionText);
							newMessage = reconstructPost(wordsArray);
						} else {
							//fallback for missing caption
							newMessage = "";
						}

						$('[fca-instagram-feed-container]').append(`
							<div class="instagram-feed-post">
								<a target="blank" href="${data.data[x].link}" ${analyticsId} ${constructAnalyticsValue(x, analyticsId)} tabindex="0">
									<img src="${data.data[x].images.standard_resolution.url}">
								</a>
								<div class="instagram-feed-post-content">
									<p>${newMessage}</p>
									<a target="blank" href="https://instagram.com/${data.data[x].user.username}/" class="instagram-feed-post-footer">
										<div class="instagram-feed-post-avatar">
											<img src="${data.data[x].user.profile_picture}">
										</div>
										<div class="instagram-feed-post-author">
											<span class="fullname">${data.data[x].user.full_name}</span>
											<small class="username">@${data.data[x].user.username}</small>
										</div>
									</a>
								</div>
							</div>`
						);
					}
					initSlick();
				},
				error: function(data) {
					$element.hide();
				}
			});
		};
	}

	function deconstructPost(post) {
		let wordsArray = post.split(' ');
		let wordOnly = '';

		for (let y = 0; y < wordsArray.length; y++) {
			wordOnly = wordsArray[y].substr(1);

			if (wordsArray[y].charAt(0) === '#') {
				wordsArray[y] = `<a target="blank" href="https://www.instagram.com/explore/tags/${wordOnly}">${wordsArray[y]}</a>`;
			} else if (wordsArray[y].charAt(0) === '@') {
				wordsArray[y] = `<a target="blank" href="https://www.instagram.com/${wordOnly}">${wordsArray[y]}</a>`;
			}
		}

		return wordsArray;
	}

	function reconstructPost(post) {
		let reconstructedMessage = '';

		for (let y = 0; y < post.length; y++) {
			reconstructedMessage += post[y] + ' ';
		}

		return reconstructedMessage;
	}

	function constructAnalyticsValue(id, analyticsId) {
		if (analyticsId) {
			let slideNumber = id + 1;
			return `data-analyticsvalue="${"#"+slideNumber}"`;
		} else {
			return "";
		}
	}

	function cleanCaption(post) {
		let cleanedCaption = post;

		cleanedCaption = cleanedCaption.replace('#', ' #');
		cleanedCaption = cleanedCaption.replace('@', ' @');

		return cleanedCaption;
	}

	function equalizeHeight() {
		let slides = document.getElementsByClassName('instagram-feed-post');
		let slidesArr = Array.from(slides);
		let heightArray = [];

		for(let i = 0; i < slidesArr.length; i++) {
			heightArray.push(slidesArr[i].clientHeight);
		}

		let highestValue = Math.max(...heightArray) + 30;
		let strConversion = highestValue.toString() + "px";

		for(let i = 0; i < slidesArr.length; i++) {
			slides[i].style.height = strConversion;
		}
	}

	function removeTabindex() {
		let slickInstagramSlider = $('[fca-instagram-feed-container]');
		let slickTabs = slickInstagramSlider.find('[tabindex=-1]');
		slickTabs.removeAttr('tabindex');
	}

	function initSlick() {
		let slickInstagramSlider = $('[fca-instagram-feed-container]');

		const settings = {
			infinite: false,
			arrows: false,
			dots: false,
			centerPadding: '102px',
			centerMode: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 620,
					settings: {
						centerPadding: "350px",
						centerMode: true,
					}
				},
				{
					breakpoint: 767,
					settings: "unslick",
				}
			]
		};
		slickInstagramSlider.slick(settings);

		$(window).on('resize', () => {
			if( $(window).width() <= 767 && !slickInstagramSlider.hasClass('slick-initialized')) {
				slickInstagramSlider.slick(settings);
			}
		});
		setTimeout(()=>{
			// The tabindex was set at -1 by default, this removes it, so it becomes more accessible
			removeTabindex();

			if(window.innerWidth <= 767) {
				equalizeHeight();
			}
		},350);
	}
})();
