(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaEngine', fcaEngine);

	function fcaEngine() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'engine',
			controller: fcaEngineController
		};

		function fcaEngineController($scope, $window, $element, $rootScope, gtmAnalytics, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;
			const audioElementId = "audioEngine";
			const scroller = scrollama();

			let statElementsArray;
			let animationDone = false;
			let pixelDuration = 1000;
			const animationDuration = 2000;

			$ctrl.audioIsPlaying = false;

			$ctrl.$onInit = () => {
				$(window).on('resize', functionExecutionControl.debounce($ctrl.scrollerInit, 250) );
			}

			$ctrl.$postLink = () => {
				statElementsArray = $($element[0]).find('.specs-block__stat-number');

				statElementsArray.each((i, element) => {
					$(element).text('0');
				});

				if ($element[0].classList.contains('specs-block--engine')) {
					$ctrl.scrollerInit();
					window.addEventListener('scroll', $ctrl.rotateEngine);
				}
			};

			$ctrl.scrollerInit = () => {
				const turntableImagesList = $($element[0]).find('.specs-block__turntable-item');
				const flexWrapper = $element.find('.specs-block__flex-wrapper');
				const progressRange = 1 / turntableImagesList.length;

				$ctrl.scrollerSetup();
				$ctrl.rotateEngine();

				if ($window.innerWidth >= 768) {
					Stickyfill.addOne(flexWrapper);
				}

				turntableImagesList.each((i, element) => {
					element.dataset.minVal = progressRange * i;
					element.dataset.maxVal = progressRange * i + progressRange;
				});
			};

			$ctrl.rotateEngine = () => {
				const turntableImagesList = $($element[0]).find('.specs-block__turntable-item');
				let offsetTop = $element.find('[data-fca-engine-scroll-zone]')[0].getBoundingClientRect().top;
				let stickyTop = $element.find('[data-flex-wrapper]').css('top');
				let scrollProgress;
				stickyTop = parseInt(stickyTop, 10);
				offsetTop = offsetTop - stickyTop;

				if(offsetTop <= 0 && offsetTop >= (pixelDuration * -1)) {
					scrollProgress = Math.abs(offsetTop) / pixelDuration;

					turntableImagesList.each((i, element) => {
						if ($window.innerWidth >= 768) {
							if (scrollProgress >= element.dataset.minVal && scrollProgress < element.dataset.maxVal) {
								element.classList.add('is-visible');
							} else if (element.classList.contains('is-visible')) {
								if (scrollProgress !== 1) {
									element.classList.remove('is-visible');
								}
							}
						}
					});
				}
			}

			$ctrl.scrollerSetup = () => {
				const spacer = $element.find('.specs-block__spacer');
				$ctrl.elementHeight = $element.find('[data-flex-wrapper]').innerHeight();

				if ($window.innerWidth >= 768) {
					spacer.css('height', $ctrl.elementHeight + pixelDuration);
				} else {
					spacer.css('height', 'auto');
				}
			}

			$ctrl.startNumberIncrementation = index => {
				const currentElement = statElementsArray[index];
				const currentElementNumber = currentElement.dataset.number;

				$(currentElement).prop('Counter',0).animate({
					Counter: currentElementNumber
				}, {
					duration: animationDuration,
					easing: 'swing',
					step: now => {
						$(currentElement).text(Math.ceil(now));
					}
				});
			};

			$ctrl.manageAudio = () => {
				const element = $element[0].querySelector(`#${audioElementId}`);
				let audioAction;

				if ($ctrl.audioIsPlaying) {
					audioAction = 'stop';
					element.pause();
					$ctrl.audioIsPlaying = false;
				} else {
					try {
						audioAction = 'listen';
						element.play();
						$ctrl.audioIsPlaying = true;
					} catch(e) {
						console.log(e);
					}
				}

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label: 'vlp-specs-engine-sounds-' + audioAction
				});
			};

			$ctrl.addEventAfterContentIsLoaded = () => {
				document.getElementById(audioElementId)
						.addEventListener('ended', $ctrl.isEnded, false);
			};

			$ctrl.isEnded = () => {
				$ctrl.audioIsPlaying = false;
				$scope.$apply();
			};

			$rootScope.$on('onScrollingUpdate', () => {
				const parentElem = $($element[0]).parents('.vertical-scroll-block-container');

				if (parentElem[0].classList.contains('is-current') && !animationDone) {
					animationDone = true;

					statElementsArray.each(i => {
						this.startNumberIncrementation(i);
					});
				}
			});
		}
	}
})();
