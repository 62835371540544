(function(ng) {
	'use strict';

	angular
		.module('fca.dealersPageList')
		.directive('dealerLocatorClickItem', ClickItem);

	/**
	 * @ngdoc directive
	 * @name fca.dealersPageList.directive:dealerLocatorClickItem
	 * @restrict A
	 * @description Set all the box of a dealer item clickable
	 * @scope
	 */
	function ClickItem($window) {
		'ngInject';

		return {
			link: link,
			restrict: 'A',
			scope: {
				clickDisabled: '<?'
			}
		};

		function link($scope, $elt) {
			// Event triggered after the ng-repeat in dealers list component is finished
			$scope.$parent.$on('EVENT.dealersListReady', () => {
				let $li = ng.element('li', $elt);
				for (let i = 0, l = $li.length; i < l; i++) {
					let $element = ng.element($li[i]);
					$element.on('click', ($evt) => {
						if (!$scope.clickDisabled) {
							$evt.preventDefault();
							let $link = ng.element('a', $element);
							let href = $link.attr('href');
							$window.location.href = href;
						}
					});
				}
			});
		}
	}
})(angular);
