(function () {
	angular
		.module('fca.commonDirectives')
		.directive('fcaSniTrim', FcaSniTrim);

	function FcaSniTrim() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				irisWidth: '@',
				brandCode: '@',
				nameplateCode: '@',
				year: '@',
				modelYearId: '@',
				trimGroupName: '@',
				acode: '@',
				packageAndOptions: '<'
			},
			controllerAs: '$fcaSniTrimCtrl',
			controller: FcaSniTrimCtrl
		};

		function FcaSniTrimCtrl($rootScope, fcaSniTrimService) {
			const $ctrl = this;
			$ctrl.isPageReady = false;
			$ctrl.jellyUrl = '';

			$ctrl.$onInit = () => {
				$('.sub-nav-previous-link').hide();
				if ($ctrl.brandCode && $ctrl.nameplateCode &&
					$ctrl.year && $ctrl.modelYearId && $ctrl.acode) {
					$ctrl.setMfgCodeForSingleVehicle();
				}
			};

			$ctrl.setMfgCodeForSingleVehicle = () => {
				fcaSniTrimService.getSniTrimData($ctrl.brandCode, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId).then(value => {
						const trimGroupArray = value.data.trimGroups;
						// const mfgCode = fcaSniTrimService.findTrim($ctrl.trimGroupName, trimGroupArray, $ctrl.acode).mfgCode;
						// const fallbackPackageCode = fcaSniTrimService.findTrim($ctrl.trimGroupName, trimGroupArray, $ctrl.acode).packages[0].code;
						// const fallbackPaintCode = fcaSniTrimService.findTrim($ctrl.trimGroupName, trimGroupArray, $ctrl.acode).primaryColourCode;
						// $ctrl.jellyUrl = fcaSniTrimService.getIrisJellyData($ctrl.year, mfgCode, $ctrl.packageAndOptions, fallbackPackageCode, fallbackPaintCode, 'fronthero', $ctrl.irisWidth);
						// $ctrl.interiorJellyUrl = fcaSniTrimService.getIrisJellyData($ctrl.year, mfgCode, $ctrl.packageAndOptions, fallbackPackageCode, fallbackPaintCode, 'I01', $ctrl.irisWidth);
						$ctrl.isPageReady = true;
					},
					value => {
						console.error('api call for trims return 404, return value:', value);
					}).catch(e => {
				});
			};

		}
	}
})();