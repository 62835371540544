(function() {
  'use strict';

  /**
   * @ngdoc overview
   * @name fca.hoursStatus
   * @description Module to manage mediaqueries constant
   */
  angular
    .module('fca.mqConstant', ['matchmedia-ng'])
    .run(function($rootScope, FCA_MQ_LAYOUT, FCA_MQ_IMAGES, FCA_MQ_PICTURE, MEDIAQUERIES) {
      $rootScope.FCA_MQ_LAYOUT = FCA_MQ_LAYOUT;
      $rootScope.FCA_MQ_IMAGES = FCA_MQ_IMAGES;
      $rootScope.FCA_MQ_PICTURE = FCA_MQ_PICTURE;

      // Deprecated: these are used in DAA
      $rootScope.FCA_MQ_JELLY = FCA_MQ_IMAGES;
      $rootScope.FCA_MEDIAQUERIES = MEDIAQUERIES;
    });
})(angular);
