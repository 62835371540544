(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAnimatedStatistics', fcaAnimatedStatistics);

	function fcaAnimatedStatistics() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaAnimatedStatistics',
			controller: fcaAnimatedStatisticsController
		};

		function fcaAnimatedStatisticsController($element, $window, $filter) {
			'ngInject';

			const $ctrl = this;
			const animationDuration = 2000;
			$ctrl.isAnimating = false;


			$ctrl.$postLink = () => {
				angular.element($window).bind('scroll', $ctrl.onScrollEvent);

				$ctrl.svgCircle = $element.find('.circular-chart .circle-progress');
				$ctrl.svgBlurCircle = $element.find('.circular-chart .circle-blur');
				$ctrl.statElement = $element.find('.animated-statistics__single-value');

				$ctrl.onScrollEvent(); // In case the user lands directly on the block without scrolling

				$ctrl.statElement.text('0');
			};

			$ctrl.onScrollEvent = () => {
				const rect = $element[0].getBoundingClientRect();

				if (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= ($window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= ($window.innerWidth || document.documentElement.clientWidth) &&
					!$ctrl.isAnimating
				) {
					$ctrl.startStatsAnimation();
				}
			};

			$ctrl.startStatsAnimation = () => {
				if (!$ctrl.isAnimating) {
					$ctrl.isAnimating = true;
					$ctrl.startNumberIncrementation($ctrl.statElement[0]);
				}
			};

			$ctrl.startNumberIncrementation = elem => {
				const currentElement = elem;
				const currentElementNumber = currentElement.dataset.number.replace(/[a-zA-Z "']/g, '');
				const currentElementNumberText = currentElement.dataset.number.replace(/[0-9.,]/g, '');
				const isFloat = currentElementNumber.includes('.');

				$(currentElement).prop('Counter', 0).animate({
					Counter: currentElementNumber
				}, {
					duration: animationDuration,
					easing: 'swing',
					step: now => {
						if (isFloat) {
							$(currentElement).text($filter('fcaRate')(now.toFixed(1) + currentElementNumberText));
						} else {
							$(currentElement).text($filter('fcaRate')(Math.ceil(now) + currentElementNumberText));
						}
					}
				});
			};
		}
	}
})();
