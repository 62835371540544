(function(ng) {
	'use strict';

	angular
		.module('fca.bodyBuilder.dialog')
		.controller('bodyBuilderVideoController', DialogController);

	function DialogController($scope, $rootScope, ngDialog, $translate, $sce) {
		'ngInject';

		let dialogData = $scope.ngDialogData;

		$scope.dialogCloseLabel = $translate.instant(dialogData.dialogCloseLabel);
		$scope.youtubeUrl = $sce.trustAsResourceUrl(dialogData.youtubeUrl);

		this.close = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			ngDialog.close();
		};
	}
})(angular);
