(function () {
    angular
    .module('fca.userAccount')
    .directive('fcaUserAccountMenuHandler', UserAccountMenuHandler);

    function UserAccountMenuHandler() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: true,
            controllerAs: '$fcaUserAccountMenuCtrl',
            controller: UserAccountMenuHandlerCtrl
        };

        function UserAccountMenuHandlerCtrl($rootScope, $window, fcaUserIdPersistentService) {

            let $ctrl = this;
            $ctrl.language = $('html').attr('lang');
            $ctrl.label = "";
            $ctrl.menuLabel = "";

            $ctrl.$onInit = () => {
                handleDisplay();

                $rootScope.$on('ua:libraryInitialized', () => {

                    handleDisplay();

                    //we need to refresh user account menu entry when we change the size of window cause it's may be
                    // a new menu that will be displayed (desktop / tablet / mobile)
                    angular.element($window).bind('resize', () => {
                        handleDisplay();
                    });

                });
            };

            let handleDisplay = () => {
                const navigationGroupItemUaAnonymous = $('.navigation-group-item-ua-anonymous');
                const navigationGroupItemUaSignedIn = $('.navigation-group-item-ua-signed-in');
                const optionsLabel = {
                    'en': 'Press enter to access options.',
                    'fr': 'Appuyez sur Entrée pour les options.'
                };

                const connexionStatusLabel = {
                    'loggedIn': {
                        'en': 'You are currently signed into My Saved vehicles.',
                        'fr': 'Vous êtes connecté à vos véhicules favoris.'
                    },
                    'loggedOut': {
                        'en': 'You are currently signed out of My Saved vehicles.',
                        'fr': 'Vous n\'êtes pas connecté à vos véhicules favoris.'
                    }
                };

                //hide / show right item menu depending if a user is signed in or not;
                if(typeof UA !== 'undefined') {
                    if (UA.isUserLoggedIn()) {
                        $ctrl.label = connexionStatusLabel['loggedIn'][$ctrl.language];
                        $ctrl.menuLabel = connexionStatusLabel['loggedIn'][$ctrl.language] + " " + optionsLabel[$ctrl.language];
                        navigationGroupItemUaAnonymous.hide();
                        navigationGroupItemUaSignedIn.show();

                    } else {
                        $ctrl.label = connexionStatusLabel['loggedOut'][$ctrl.language];
                        $ctrl.menuLabel = connexionStatusLabel['loggedOut'][$ctrl.language] + " " + optionsLabel[$ctrl.language];
                        navigationGroupItemUaAnonymous.show();
                        navigationGroupItemUaSignedIn.hide();
                    }

                    //attach an action on sign-out item menu to log out the user and refresh the current page
                    $('.navigation-item-ua-sign-out').on('click', function (e) {

                        // restore userId cookie
                        let restored_uid = fcaUserIdPersistentService.create_UUID();
                        fcaUserIdPersistentService.setCookie('userId', restored_uid, 365);

                        //remove the storage of the id on logout
                        sessionStorage.removeItem("persistentID");

                        e.preventDefault();
                        UA.logout(function () {
                            window.location.reload();
                        });
                    });
                }
            }
        }
    }

})();
