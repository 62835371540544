(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAnimatedProgressBar', fcaAnimatedProgressBar);

	function fcaAnimatedProgressBar() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaAnimatedProgressBar',
			controller: fcaAnimatedProgressBarController
		};

		function fcaAnimatedProgressBarController($element, $window) {
			'ngInject';

			const $ctrl = this;
			let isAnimating = false;

			$ctrl.$postLink = () => {
				angular.element($window).bind('scroll', $ctrl.onScrollEvent);
				$ctrl.progressBarElementsArray = $element.find('.animated-stats__stat-progressBar');
				$ctrl.onScrollEvent(); // In case the user lands directly on the block without scrolling
			};

			$ctrl.onScrollEvent = () => {
				const rect = $element[0].getBoundingClientRect();

				if (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= ($window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= ($window.innerWidth || document.documentElement.clientWidth) &&
					!isAnimating
				) {
					if ($ctrl.progressBarElementsArray) {
						$ctrl.progressBarElementsArray.each((i => {
							$ctrl.startProgressBarAnimation(i);
						}));
					}
				}
			};

			$ctrl.startProgressBarAnimation = (index) => {
				isAnimating = true;
				const currentElement = $ctrl.progressBarElementsArray[index];
				const currentValue = currentElement.dataset.progressBarValue;
				const currentMaxValue = currentElement.dataset.progressBarMaxValue;
				const percentValue = currentValue * 100 / currentMaxValue;

				$(currentElement).css({"width": percentValue + '%'});
			};
		}
	}
})();
