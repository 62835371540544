(function() {
	'use strict';

	/**
	 * @ngdoc component
	 * @name fca.daaForm.inputRange.component:daaInputRange
	 * @description Input range element form component
	 * @param {Number} min        Mininum input value
	 * @param {Number} max        Maximum input value
	 * @param {Number} step       Step input value
	 * @param {Number} value      Current (initial) input value
	 * @param {String} id         Html id attribute
	 * @param {String} name       Html name attribute
	 * @param {Number} unit       Size/2 of the cursor
	 * @param {Function} onChange Function reference (event listener) on change event
	 */
	angular
		.module('fca.daaForm.inputRange')
		.component('daaInputRange', inputRange());

	function inputRange() {
		return {
			bindings: {
				min: '@',
				max: '@',
				step: '@',
				value: '<',
				id: '@?',
				name: '@',
				unit: '@?',
				invert: '<?',
				onChange: '&?',
				analyticsCategory: '@',
				analyticsId: '@',
				analyticsValue: '@'
			},
			templateUrl: '/components/input-range/input-range.html',
			controller: InputRangeController
		};
	}

	function InputRangeController($log) {
		'ngInject';

		/* Graduations steps */
		this.steps = [];
		/* Input model */
		this.model = 0;

		/* Build graduations steps array */
		this.$onInit = () => {
			/* Set numerics value */
			this.unit = parseInt(this.unit, 10) || 17;
			this.model = parseInt(this.value, 10);
		};

		this.$onChanges = (obj) => {
			if (obj.hasOwnProperty('value') && obj.value.previousValue !== 'UNINITIALIZED_VALUE') {
				this.value = parseInt(obj.value.currentValue, 10);
				if (isNaN(this.value)) {
					this.value = 0;
				}

				this.model = parseInt(this.value, 10);
			}

			if (obj.hasOwnProperty('min') || obj.hasOwnProperty('max')) {
				this.initializeSteps();
			}
		};

		/* Update value and pass throw outside component */
		this.onRangeChange = (value) => {
			let prop = (this.id) ? this.id.replace('range-', '') : null;
			if (angular.isFunction(this.onChange) && value !== undefined) {
				this.onChange({
					prop: prop,
					value: value
				});
			}
		};

		this.initializeSteps = () => {
			if (this.step > 1) {
				this.steps = [];
				let min = parseInt(this.min, 10);
				let max = parseInt(this.max, 10);
				let step = parseInt(this.step, 10);

				for (var i = min; i < (max + 1); i += step) {
					this.steps.push(i);
				}
			}
		};
	}
})();
