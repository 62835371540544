(function () {
    angular
        .module('fca.gmap', ['ngMessages', 'ngTouch'])
        .component('fcaDealerCardLocation',
           { templateUrl: ['$element', '$attrs', function ($element, $attrs) {
                return $attrs.templateUrl || '/dealer-card-location/dealer-card-location.html';
            }],
            controller: FcaDealerCardLocationController,
            controllerAs: '$dealerLocatorCtrl',
            bindings: {
                location: "<",
                dealerCode: '@',
            }
        });

        function FcaDealerCardLocationController($scope, $rootScope, $http) {
            'ngInject';

            const $dealerLocatorCtrl = this;

            $dealerLocatorCtrl.brandCode = FCA_SITES_CONFIG.name;
            $dealerLocatorCtrl.language = FCA_SITES_CONFIG.language;
            $dealerLocatorCtrl.dealerObject = null;

            $dealerLocatorCtrl.$onInit = () => {
                $dealerLocatorCtrl.getDealerInfo();
            };

            $dealerLocatorCtrl.getDealerInfo = () => {
                if($dealerLocatorCtrl.dealerCode && $dealerLocatorCtrl.brandCode) {
                    $http.get(`/data/dealers/${$dealerLocatorCtrl.brandCode}/dealer/${ $dealerLocatorCtrl.dealerCode}`).then((result) => {
                        if (result.data && result.status == 200) {
                            $dealerLocatorCtrl.dealerObject = result.data;
                        } else {
                            console.error(" Error while retrieving dealer object in dealer card location component.");
                        }
                    });
                }
            };
        }
})();
