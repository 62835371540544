(function () {
	angular
	.module('fca.brandApp')
	.directive('vlpExterior360', vlpExterior360);

	function vlpExterior360() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				brandCode: '@',
				irisJellyParameters: '@',
				irisFabricCode: '@',
				irisJellyParametersMobile: '@',
				irisCposVehicleType: '@',
				tabs: '<',
				show360only: '<',
				trimParams: '<',
				irisTransparency: '@?'
			},
			controllerAs: '$ctrl',
			controller: vlpExterior360Controller
		};

		function vlpExterior360Controller($scope, $rootScope, $element, $window, $timeout, irisService, povHelper, matchmedia, FCA_MQ_LAYOUT, AR_IRIS_PRESETS, externalConfigLoader) {
			'ngInject';

			const COLORS_TAB = 'colors';
			const DEFAULT_POVS = [
				povHelper.format(3),
				povHelper.format(5),
				povHelper.format(7),
				povHelper.format(9),
				povHelper.format(11),
				povHelper.format(13),
				povHelper.format(15),
				povHelper.format(17),
				povHelper.format(19),
				povHelper.format(21),
				povHelper.format(23),
				povHelper.format(25),
				povHelper.format(27),
				povHelper.format(29),
				povHelper.format(31),
				povHelper.format(33),
				povHelper.format(35),
				povHelper.format(1),
			];

			// from experimenting various values from Iris
			const DEFAULT_IRIS_CAPTURE = {
				width: 11500,
				height: 3500,
				x: -750,
				y: 3200,
			};
			const DEFAULT_IRIS_CAPTURE_MOBILE = {
				width: 7800,
				height: 3000,
				x: 1100,
				y: 3000,
			};

			const $ctrl = this;
			const irisUrlBuilder = irisService.getUrlBuilder().preset(AR_IRIS_PRESETS.EXTERIOR);
			const config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

			let currentPOV = 0;
			let jellyIsLoading = true;
			let waitForTransition = false;
			let transitionPromise = null;

			$ctrl.selectedTabID = null;
			$ctrl.jellyCopyUrl = null;
			$ctrl.transitionState = 0;
			$ctrl.tabsSettings = {};
			$ctrl.showPaintChips;
			$ctrl.wasLegacy = false;

			$ctrl.$onInit = () => {

				if ($ctrl.tabs && !$ctrl.show360only) {
					$ctrl.tabs.forEach((tab) => {
						// select first tab who's not empty
						if ($ctrl.selectedTabID === null && tab.options && tab.options.length > 0) {
							$ctrl.selectedTabID = tab.id;
						}

						// select first option of each tabs
						if (tab.options && tab.options.length > 0) {
							const option = tab.options[0];
							$ctrl.selectOption(null, tab.id, option.contentPath, option.code, option.desc, option.std_desc, option.mfgCode, option.pkgCode, option.vehCode, option.isLegacy, option.jellySrc);
						}

					});
				} else {
					//show 360 only without using tabs. Jelly parameters are specified for only 1 trim
					if ($ctrl.trimParams) {
						$ctrl.selectedTabID = COLORS_TAB;
						$ctrl.selectOption(null, COLORS_TAB, '', $ctrl.trimParams.colorCode, $ctrl.trimParams.desc, '', $ctrl.trimParams.mfgCode, $ctrl.trimParams.pkgCode, $ctrl.trimParams.vehCode, 'false', '');
					}

				}

				if ($ctrl.selectedTabID === 'colors') {
					$ctrl.pushJelliesUrlsTo360Directive($ctrl.isLegacy);
				}
			};

			$ctrl.pushJelliesUrlsTo360Directive = (isLegacy = false) => {
				//*  Push images to the 360 slider  *//
				var body_ref = $(window);
				var temp_pov = currentPOV;

				$scope.imageList = [];

				currentPOV = 0;

				if (isLegacy === true) {
					// Size Management For Legacy
					if (body_ref.width() <= 767) {
						$scope.imageList.push($ctrl.getLegacyJelly('xsmall'));
					} else if (body_ref.width() >= 768) {
						if (body_ref.width() >= 1024) {
							if (body_ref.width() >= 1200) {
								$scope.imageList.push($ctrl.getLegacyJelly('large'));
							} else {
								$scope.imageList.push($ctrl.getLegacyJelly('medium'));
							}
						} else {
							$scope.imageList.push($ctrl.getLegacyJelly('small'));
						}
					}

				} else {
					for (let i = 1; i < povHelper.getPovMax() + 1; i++) {

						// Send Color to Analytics only if Iris's used
						// $scope.currentColor = $ctrl.getSelectedDesc();

						// Size Management For IRIS
						if (body_ref.width() <= 767) {
							$scope.imageList.push($ctrl.getJellyUrl('xsmall'));
						} else if (body_ref.width() >= 768) {
							if (body_ref.width() >= 1024) {
								if (body_ref.width() >= 1200) {
									$scope.imageList.push($ctrl.getJellyUrl('large'));
								} else {
									$scope.imageList.push($ctrl.getJellyUrl('medium'));
								}
							} else {
								$scope.imageList.push($ctrl.getJellyUrl('small'));
							}
						}
						currentPOV = i;
					}
				}

				currentPOV = temp_pov; // Restore POV to wheels management
				//* ----------------------- *//
			};

			$ctrl.prevColor = () => {
				$ctrl.triggerSelectOption('prev');
			};

			$ctrl.nextColor = () => {
				$ctrl.triggerSelectOption('next');
			};

			$ctrl.triggerSelectOption = (direction) => {
				const tabSetting = getTabSettings($ctrl.selectedTabID);
				const selectedTab = $ctrl.tabs.find(tab => tab.id === $ctrl.selectedTabID);
				const index = selectedTab.options.findIndex(option => option.code === tabSetting.selectedOptionCode);
				const modifier = direction == 'next' ? 1 : -1;
				const max = selectedTab.options.length - 1;
				const newIndex = index != 0 ? (index + modifier > max ? 0 : index + modifier) : (modifier < 0 ? max : modifier);
				const option = selectedTab.options[newIndex];
				$ctrl.selectOption(null, $ctrl.selectedTabID, option.contentPath, option.code, option.desc, option.std_desc, option.mfgCode, option.pkgCode, option.vehCode, option.isLegacy, option.jellySrc);
			};

			const getTabSettings = (tabID) => {
				if (!$ctrl.tabsSettings[tabID]) {
					$ctrl.tabsSettings[tabID] = {
						selectedOptionContentPath: null,
						selectedOptionCode: null,
						selectedOptionDescription: null,
						hoveringOptionDescription: null,
					};
				}
				;

				return $ctrl.tabsSettings[tabID];
			};
			const getPOV = () => {
				return DEFAULT_POVS[currentPOV];
			};
			const getBackground = () => {
				if ($ctrl.irisTransparency != undefined && $ctrl.irisTransparency) {
					return 'transparent';
				} else {
					return 'white';
				}
			};
			const getSelectedPaint = () => {
				return getTabSettings(COLORS_TAB).selectedOptionCode || null;
			};
			const getSelectedOptionsCode = () => {
				// join selectedOptionCode from all tabs except for paint
				return Object.entries($ctrl.tabsSettings).filter(tab => tab[0] !== COLORS_TAB).map(tab => tab[1].selectedOptionCode);
			};
			const getSelectedMfgCode = () => {
				return getTabSettings($ctrl.selectedTabID).selectedOptionMfgCode || null;
			};
			const getSelectedPkgCode = () => {
				return getTabSettings($ctrl.selectedTabID).selectedOptionPkg || null;
			};
			const getSelectedVehicle = () => {
				return getTabSettings($ctrl.selectedTabID).selectedOptionVehicle || null;
			};

			$ctrl.getSelectedDesc = () => {
				return getTabSettings($ctrl.selectedTabID).selectedOptionDescription || null;
			};

			$ctrl.getSelectedStdDesc = () => {
				return getTabSettings($ctrl.selectedTabID).selectedOptionStdDescription || null;
			};

			$ctrl.isLegacyMode = () => {
				return getTabSettings($ctrl.selectedTabID).isLegacy || null;
			};

			const getJellySrc = () => {
				return getTabSettings($ctrl.selectedTabID).jellySrc || null;
			};

			const getIrisOutput = (size) => {
				const ratio = 1800 / 700;

				if (size === 'xsmall') return irisService.calculateImageSize(768, 1536 / 900);
				else if (size === 'small') return irisService.calculateImageSize(1024, ratio);
				else if (size === 'medium') return irisService.calculateImageSize(1200, ratio);
				else if (size === 'large') return irisService.calculateImageSize(1800, ratio);
				else return null;
			};
			const getIrisCapture = (size) => {
				if (size === 'xsmall') {
					if ($ctrl.irisJellyParametersMobile) return parseIrisJellyParams($ctrl.irisJellyParametersMobile);
					return DEFAULT_IRIS_CAPTURE_MOBILE;
				} else {
					if ($ctrl.irisJellyParameters) return parseIrisJellyParams($ctrl.irisJellyParameters);
					return DEFAULT_IRIS_CAPTURE;
				}
			};
			const parseIrisJellyParams = (jellyParams) => {
				const values = jellyParams.split('&');
				const params = {};

				values.forEach((param) => {
					const entry = param.split('=');
					let key = entry[0];

					if (key === "w") key = "width";
					else if (key === "h") key = "height";

					params[key] = parseInt(entry[1]);
				});

				return params;
			};

			const updateJellyWithSmoothTransition = () => {
				jellyIsLoading = true;
				waitForTransition = true;

				// get currentSrc of source image
				let url = $element.find('.E360_ExteriorOptions-jellyImg.-source')[0].currentSrc;

				// if browser does not support currentSrc, use image.src
				if (!url) url = $element.find('.E360_ExteriorOptions-jellyImg.-source')[0].src;

				// clear running transition if it exists
				if (transitionPromise) $timeout.cancel(transitionPromise);
				transitionPromise = null;

				if ($ctrl.jellyCopyUrl !== url) {
					$ctrl.jellyCopyUrl = url;
				}

				$ctrl.onJellyCopyLoadCompleted();

			};

			$ctrl.getJellyUrl = (size) => {
				let url;
				let currPov;
				if (waitForTransition === true) return;

				const paint = getSelectedPaint();
				if (!paint) return null;

				// calculate proportional image sizes for each viewports
				const output = getIrisOutput(size);
				const capture = getIrisCapture(size);

				// if output is invalid, return null
				if (!output) return null;

				if ($ctrl.selectedTabID === 'wheels') {
					currPov = getPOV();
				} else {
					currPov = povHelper.format(currentPOV);
				}

				// build jelly URL
				irisUrlBuilder
				.brand(config.getConfig('irisCodeForBrand')[$ctrl.brandCode])
				.vehicule(getSelectedVehicle())
				.mfgCode(getSelectedMfgCode())
				.packageCode(getSelectedPkgCode())
				.cposVehicleType($ctrl.irisCposVehicleType)
				.pov(currPov)
				.background(getBackground())
				.paint(paint)
				.fabric($ctrl.irisFabricCode)
				.options(getSelectedOptionsCode())
				.capture(capture.width, capture.height, capture.x, capture.y)
				.output(output.width, output.height);


				url = irisUrlBuilder.getURL();

				if ($ctrl.irisTransparency != undefined && $ctrl.irisTransparency) {
					url += '&resp=png';
				}

				return url;
			};

			$ctrl.getLegacyJelly = (size) => {
				if (waitForTransition === true) return;

				const paint = getSelectedPaint();
				if (!paint) return null;

				const output = getIrisOutput(size);

				// if legacy mode we return jelly from Sling
				const jellySrc = getJellySrc();

				if (jellySrc) {
					let jellyUrl = `${jellySrc}`;
					const index = jellySrc.indexOf('.png');
					if (index !== -1) {
						jellyUrl = `${jellySrc.slice(0, index)}-${output.height}x${output.width}${jellySrc.slice(index)}`;
					}

					return jellyUrl;
				}

			};

			$ctrl.onJellyLoadCompleted = (event) => {
				jellyIsLoading = false;
				$ctrl.transitionState = 2;

				transitionPromise = $timeout(() => {
					waitForTransition = false;
					$ctrl.transitionState = 0;
				}, 1000);
			};

			$ctrl.onJellyCopyLoadCompleted = (event) => {
				$ctrl.transitionState = 1;
				waitForTransition = false;
			};

			$ctrl.isJellyLoadCompleted = () => {
				return !jellyIsLoading;
			};

			$ctrl.nextPOV = (event) => {
				currentPOV--;
				if (currentPOV < 0) currentPOV = DEFAULT_POVS.length - 1;

				updateJellyWithSmoothTransition();
			};

			$ctrl.previousPOV = (event) => {
				currentPOV++;
				if (currentPOV >= DEFAULT_POVS.length) currentPOV = 0;

				updateJellyWithSmoothTransition();
			};

			$ctrl.selectTab = (event, tabID) => {
				$ctrl.selectedTabID = tabID;

				// only for Edge
				if (window.navigator.userAgent.indexOf("Edge") > -1) {
					// force repaint after 100ms (https://issues.nurun.com/browse/CAQPRR-268)
					$timeout(() => {
						$element.find('.E360_ExteriorOptions-panel.-active .E360_ExteriorOptions-optionItem').each((index, el) => {
							el.style.display = 'none';
							el.offsetHeight;
							el.style.display = 'block';
						});
					}, 100);
				}
			};

			$ctrl.swipeRight = () => {
				if ($ctrl.selectedTabID === 'wheels') {
					$ctrl.nextColor();
				} else {
					$ctrl.nextPOV();
				}
			};

			$ctrl.swipeLeft = () => {
				if ($ctrl.selectedTabID === 'wheels') {
					$ctrl.prevColor();
				} else {
					$ctrl.previousPOV();
				}
			};

			$ctrl.selectOption = (event, tabID, optionContentPath, optionCode, optionDescription, optionStdDescription, optionMfgCode, optionPkgCode, optionVehicle, optionSelectedMode, optionJellySrc) => {

				// if option is already selected, do nothing
				if (optionContentPath === $ctrl.getTabSelectedOptionContentPath(tabID)) return;

				const tab = getTabSettings(tabID);

				if ($ctrl.isLegacy === true) {
					$ctrl.wasLegacy = true;
				} else {
					$ctrl.wasLegacy = false;
				}

				tab.selectedOptionContentPath = optionContentPath;
				tab.selectedOptionCode = optionCode;
				tab.selectedOptionDescription = optionDescription;
				tab.selectedOptionStdDescription = optionStdDescription;
				tab.selectedOptionMfgCode = optionMfgCode;
				tab.selectedOptionPkg = optionPkgCode;
				tab.selectedOptionVehicle = optionVehicle;
				tab.isLegacy = optionSelectedMode === 'true' ? true : false;
				tab.jellySrc = optionJellySrc;
				jellyIsLoading = true;
				waitForTransition = false;

				if (tab.isLegacy === true) {
					$ctrl.isLegacy = true;
				} else {
					$ctrl.isLegacy = false;
				}

				if (event) {
					if (tabID === 'wheels') {
						updateJellyWithSmoothTransition();
					} else if (tabID === 'colors') {
						const paintChipsExpanded = document.querySelectorAll('.VLP2021-E360.-expanded');
						const paintChipsContainer = document.querySelectorAll('.paint-chips-container');
						$ctrl.pushJelliesUrlsTo360Directive(tab.isLegacy);
					}
				}
			};
			$ctrl.rolloverOption = (event, tabID, optionDescription) => {
				const tab = getTabSettings(tabID);
				tab.hoveringOptionDescription = optionDescription;
			};
			$ctrl.rolloutOption = (event, tabID) => {
				const tab = getTabSettings(tabID);
				tab.hoveringOptionDescription = null;
			};
			$ctrl.getTabSelectedOptionContentPath = (tabID) => {
				return getTabSettings(tabID).selectedOptionContentPath;
			};
			$ctrl.getTabSelectedOptionCode = (tabID) => {
				return getTabSettings(tabID).selectedOptionCode;
			};
			$ctrl.getTabSelectedOptionDescription = (tabID) => {
				const tab = getTabSettings(tabID);
				return tab.hoveringOptionDescription || tab.selectedOptionDescription || null;
			};

			$ctrl.colorsLoaded = () => {
				let mobileBreakpoint = 667;
				let windowWidth = $window.innerWidth;
				let colorList = $('.E360_ExteriorOptions-optionList')[0];
				let colorItem = $('.E360_ExteriorOptions-optionItem');
				let colorBtn = $('.E360_ExteriorOptions-optionItemBtn');
				let colorListContainer = $('.E360_ExteriorOptions-optionList-container')[0];

				if (windowWidth < mobileBreakpoint && colorList) {
					$(colorList).css('width', 'max-content');
					let colorsNumber = $(colorList).find('li').length;
					let eachBoxWidth = $(colorList).find('li')[0].clientWidth;
					let allBoxesWidth = colorsNumber * eachBoxWidth;
					let widthCalculated = 0;

					if (colorsNumber === 8) {
						let offset = 20;
						widthCalculated = allBoxesWidth / 2 + offset;
					} else {
						if (colorsNumber % 2 === 0 ) {
							let offset = 40;
							widthCalculated = allBoxesWidth / 2 + offset;
						} else {
							let offset = 70;
							widthCalculated = allBoxesWidth / 2 + offset;
						}
					}

					$(colorList).css('width', widthCalculated + 'px');
				} else {
					if (colorBtn) {
						$(colorBtn).attr('tabindex', 0);
					}
					if (colorList) {
						$(colorList).css('width', '');
					}
				}

				if (colorListContainer && colorList) {
					if (colorListContainer.scrollWidth <= colorListContainer.clientWidth) {
						$('.arrow-color-list').css('display', 'none');
						$(colorList).css('width', '');
						$(colorList).css('justify-content', 'center');
					} else {
						$('.arrow-color-list').css('display', 'block');
						$(colorList).css('justify-content', '');
					}
				}

				colorItem.removeAttr('aria-hidden');
			};

			$ctrl.arrowClicked = (direction) => {
				let mobileBreakpoint = 667;
				let windowWidth = $window.innerWidth;

				if (windowWidth < mobileBreakpoint) {
					let colorList = $('.E360_ExteriorOptions-optionList-container');
					let scrollableWidth = colorList[0].scrollWidth
						- colorList[0].clientWidth;

					if (direction === 'left') {
						colorList.animate({scrollLeft: 0}, 500);
					} else {
						colorList.animate({scrollLeft: scrollableWidth}, 500);
					}
				}
			};

			$ctrl.rotateNegative = () => {
				var direction = -1;
				$scope.$broadcast('threesixty.rotate', direction);
			};

			$ctrl.rotatePositive = () => {
				var direction = 1;
				$scope.$broadcast('threesixty.rotate', direction);
			};

		};
	};

})();
