(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaFrequency', FcaFrequency);

    function FcaFrequency($filter) {
        'ngInject';

        // Filter frequency

        return function(frequencyCode) {
            let frequency = "";

            switch(frequencyCode) {
                case "monthly":
                case 0:
                case "0": {
                    frequency = 'calculator.monthly';
                    break;
                }
                case "bimonthly":
                case 1:
                case "1": {
                    frequency = 'calculator.semi-monthly';
                    break;
                }
                case "weekly":
                case 2:
                case "2": {
                    frequency = 'calculator.weekly';
                    break;
                }
                case "biweekly":
                case 3:
                case "3": {
                    frequency = 'calculator.bi-weekly';
                    break;
                }
            }
            return frequency;
        };
    }
})();
