(function(ng) {
	'use strict';

	angular
		.module('fca.uiPanels')
		.filter('businessWeekDay', WeekDayFilter);

	function WeekDayFilter($translate) {
		'ngInject';

		// Index week days for Data
		let weekDays = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];

		return function(indexDay, data = false) {
			let ref = weekDays[indexDay];
			if (data) {
				ref = weekDays[indexDay];
			}

			let translateKey = `global.day.abbr.${ref}`;
			return $translate.instant(translateKey);
		};
	}
})(angular);
