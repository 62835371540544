// Directive to wrap slick carousel
// Allows for callbacks and other arguments need to be passed to slick to be defined in
// this directive's controller instead of higher up in application

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselPano', fcaSlickCarouselPano);

	function fcaSlickCarouselPano() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				isAlfa: '<',
				slickTheme: '@',
				speed: '@',
				easing: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselPanoController,
			link: FcaSlickCarouselPanoLink
		};

		function FcaSlickCarouselPanoController($rootScope, $scope, $element, $timeout, gtmAnalytics) {
			'ngInject';

			this.totalSlides = 0;
			this.activeSlide = 0;
			this.currentThemeColorClass = null;
			this.viewedSlides = [];

			this.onInit = (event, slick) => {
				$timeout(this.animateTextAnimation);
				$timeout(this.applyThemeColor);

				$rootScope.$emit('slickCarousel.panoActive', true);

				// Tracking of first slide
				let firstPano = angular.element('.slick-track .pano').eq(0);

				this.viewedSlides.push(0);
				this.trackPano(firstPano, 0);
				this.totalSlides = $element.find('.slick-slide').length;

				$timeout(() => {
					$element.find('.slick-arrow').removeAttr('style');
				})
			};

			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let tooltips = $(slick.$slides[currentSlide]).find('fca-legal-tooltip');

				if (this.isAlfa) {
					$(slick.$nextArrow).click(e => {
						this.sendTracking(`hero-click-next`, 'click');
					});

					$(slick.$prevArrow).click(e => {
						this.sendTracking(`hero-click-prev`, 'click');
					});
				}

				let popper;
				tooltips.each((index, $tooltip) => {
					popper = $(`#${$tooltip.dataset.tippyPopper}`);

					// Check if tooltip opened
					if (popper.length) {
						tippy.hideAll();

						// Note: Closing disclaimer is needed to solve 2 bugs
						// 1) Disclaimer tooltips follow the pano when it slides to the right
						// 2) Disclaimer tooltips remain hovered over the stacking animation (different stacking context so no easy z-index fix)
					}
				});

				if (currentSlide != nextSlide) {
					$timeout(this.applyThemeColor);
					this.clearTextAnimation();
					$timeout(this.animateTextAnimation);
				}

				if (this.viewedSlides.indexOf(nextSlide) < 0) {
					this.viewedSlides.push(nextSlide);

					let slide = slick.$slides.eq(nextSlide);
					this.trackPano(slide, nextSlide);
				}
			};

			this.afterChange = (event, slick, currentSlide, nextSlide) => {};

			this.applyThemeColor = () => {
				if (this.currentThemeColorClass != null) {
					$element.removeClass(this.currentThemeColorClass);
				}
				let $currentPanoElem = $element.find('.pano.slick-current');
				let currentThemeColor = $currentPanoElem.data('theme-color');
				if (currentThemeColor) {
					this.currentThemeColorClass = 'theme-color-' + currentThemeColor;
					$element.addClass(this.currentThemeColorClass);
				}
			};

			this.activateTextAnimation = ($currentPanoElem) => {
				let isActivated = $currentPanoElem.data('is-activated');
				if (!isActivated) {
					$currentPanoElem.data('is-activated', true);
					let animationType = $currentPanoElem.data('animationType');
					if (animationType && animationType != '') {
						$currentPanoElem.find('.pano-content').addClass(animationType);
					} else {
						$currentPanoElem.find('.pano-content').addClass('no-animation');
					}
				}
			};

			this.animateTextAnimation = () => {
				let $currentPanoElem = $element.find('.pano.slick-current');
				this.activateTextAnimation($currentPanoElem);
				$currentPanoElem.find('.pano-content').addClass('animate');
			};

			this.clearTextAnimation = () => {
				$element.find('.pano .pano-content').removeClass('animate');
			};

			this.sendTracking = (pCategory, pLabel) => {
				gtmAnalytics.trackEvent('event', {
					category: pCategory,
					label: pLabel
				});
			};

			this.trackPano = (slide, nextSlide) => {
				let dataAttr = slide.data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					this.sendTracking(category, label);
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselPanoLink(scope, element, attrs, $ctrl, gtmAnalytics) {
			let $panoButtons = element.find('.btn');

			element.addClass($ctrl.slickTheme);

			for (let panoButton of $panoButtons) {
				if (!panoButton.hasAttribute('no-default-analytics')) {
					panoButton.addEventListener('click', (e) => {
						$ctrl.sendTracking('home pano', $(e.currentTarget).text());
					});
				}
			}
		}
	}
})();
