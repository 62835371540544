(function() {
	angular
		.module('fca.layout.regionalOffers')
		.directive('fcaManageProvinces', fcaManageProvinces);

	function fcaManageProvinces() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				listProvinces: '@'
			},
			controllerAs: 'fcaManageProvincesCtrl',
			controller: FcaManageProvincesController
		};

		function FcaManageProvincesController($rootScope, userLocation) {
			'ngInject';


			this.$onInit = () => {
				this.checkIfExistsIncentiveInCurrentProvince();
			};

			this.checkIfExistsIncentiveInCurrentProvince = () => {
				let existsInCurrentProvince = false;
				if (this.listProvinces != undefined && this.listProvinces != null) {
					let array = this.listProvinces.split(',');
					if (array.includes(userLocation.getLocationProvince())) {
						existsInCurrentProvince = true;
					}
				}
				return existsInCurrentProvince;
			};
		}
	}
})();
