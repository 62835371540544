/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.closestDealer')
        .controller('closestDealerController', Controller)
        .component('closestDealer', {
            controller: 'closestDealerController',
            controllerAs: '$closestDealer',
            templateUrl: '/closest-dealer/closest-dealer.html',
            bindings: {
                apiUrl: '@',
                location: '<',
                viewAllUrl: '@?',
                urlFindDealer: '@',
                selectFavoriteDealerList: '<',
                analyticsCategory: '@',
                analyticsId: '@'
            }
        });

    /**
     * @ngdoc controller
     * @name  fca.closestDealer.controller:closestDealerController
     * @requires $scope
     * @requires $http
     * @requires $timeout
     * @requires $location
     * @requires fca.closestDealer.service:closestDealer
     * @requires matchmedia
     * @requires MEDIAQUERIES
     * @description [TODO]
     * @example
     * <pre>[TODO]</pre>
     */
    function Controller($scope,
                        $rootScope,
                        $location,
                        closestDealer,
                        fcaGeolocator,
                        preferredDealerProvider,
                        translateConfigurator,
                        $http,
                        $element,
                        $timeout,
                        dealerChatService) {
        'ngInject';

        /**
         * @const {String}
         * @name EVENT_DATA_RESET
         * @description Event name when data is reset
         */
        const EVENT_DATA_RESET = 'closestDealer.event.onResetData';

        const RADIUS = 50;

        const BRAND = FCA_SITES_CONFIG.name;

        const MAX_DISPLAYED_DEALERS = 3;

        const MAX_DISPLAYED_FAVORITE_DEALERS = 1;

        /**
         * @description
         * Url params selected code
         */
        let urlSelectedCode;
        let preferredDealerCode;
        let closestDealerInitialized = false;

        /**
         * @ngdoc property
         * @name location
         * @description Ref. location of the center of the map
         * @type {Object}
         */
        let defaultLocation = {};

        /**
         * @ngdoc property
         * @name dealers
         * @propertyOf fca.closestDealer.controller:closestDealerController
         * @type {Array}
         * @description List of dealers ($onInit call backend API)
         */
        this.dealers = [];

        /**
         * @ngdoc property
         * @name isInitialized
         * @propertyOf fca.closestDealer.controller:closestDealerController
         * @type {Boolean}
         * @description Set to true when the dealers load is done
         */
        this.isInitialized = false;

        this.dataLoaded = false;

        /**
         * @ngdoc property
         * @name noResults
         * @propertyOf fca.closestDealer.controller:closestDealerController
         * @type {Boolean}
         * @description Set to true when component is initialized and the dealer list is empty
         */
        this.noResults = false;

        /**
         * @ngdoc property
         * @name isVisible
         * @propertyOf fca.closestDealer.controller:closestDealerController
         * @type {Boolean}
         * @description Set to false to hide dealer address
         */

        this.isClientSideLocation = false;

        /**
         * @ngdoc property
         * @name isCarouselDisabled
         * @propertyOf fca.closestDealer.controller:closestDealerController
         * @type {Boolean}
         * @description Set to true to disable slick carousel
         */

        this.isCarouselDisabled = false;

        /**
         * @description
         * Model for locator dialog
         * @type {Object}
         */
        this.dialogModel = {};

        this.selectFavoriteDealerList = false;

        const $ctrl = this;

        let eventNameChangedLocation = fcaGeolocator.getLocationChangedEvent();

        /**
         * @ngdoc method
         * @name  $onInit
         * @methodOf fca.closestDealer.controller:closestDealerController
         * @description Do initial call API server to get dealders list
         */
        this.$onInit = () => {
            $timeout(() => {
                if ($element[0].dataset.carouseldisable && $element[0].dataset.carouseldisable === "true") {
                    this.isCarouselDisabled = true;
                }

                $scope.$broadcast("setCarouselState", this.isCarouselDisabled);
            },300);

            $scope.$on(eventNameChangedLocation, (evt, args) => {
                // Change display with new location
                let [newLocation] = args;
                this.newSetLocation = true;
                this.choosePreferedDealerOrClosestDealer(newLocation);
            });

            $scope.$on('resetPreferredDealer', () => {
                this.selectFavoriteDealerList = false;
                if (this.newSetLocation) {
                    this.newSetLocation = false;
                    this.findDealers();
                }
            });

            this.dialogModel = ng.extend({}, this.dialogModel, {
                title: 'geolocation.user.location'
            });

            this.choosePreferedDealerOrClosestDealer();
        };

        /**
         * @ngdoc method
         * @name  $onChanges
         * @methodOf fca.closestDealer.controller:closestDealerController
         * @description Catch location property changes to refresh data
         */
        this.$onChanges = o => {
            // Get from url the selected dealer
            let {code, latitude, longitude} = $location.search();
            if (ng.isString(code)) {
                urlSelectedCode = code;
            }

            if (latitude && longitude) {
                latitude = parseFloat(latitude);
                longitude = parseFloat(longitude);
                if (ng.isNumber(latitude) && ng.isNumber(longitude)) {
                    defaultLocation = ng.extend({}, defaultLocation, {
                        latitude: parseFloat(latitude),
                        longitude: parseFloat(longitude)
                    });
                }
            }

            let {location} = o;
            if (location !== undefined) {
                let currentLocation = location.currentValue;
                if (ng.isObject(currentLocation)) {
                    // Set default value with url params
                    this.location = ng.copy(defaultLocation);
                    // Set defaultLocation to empty value
                    defaultLocation = {};
                    if (ng.equals(this.location, {})) {
                        // Set current user location if urls params not exists
                        this.location = currentLocation;
                    }

                    preferredDealerCode = preferredDealerProvider.getPreferredDealer();
                    this.choosePreferedDealerOrClosestDealer();
                }
            }

            this.isClientSideLocation = fcaGeolocator.isLocationBaseOnClientSide(this.location);
        };

        /**
         * @ngdoc method
         * @name findDealers
         * @methodOf fca.closestDealer.controller:closestDealerController
         * @description
         * Retrieve dealers near a location with a specific radius.
         * Broadcast event data reset after data are updated.
         * @param {Object} location Object (longitude/latitude) center reference
         * @param {Number} radius Distance radius around the reference point
         * @return {Object} AngularJS promise
         */
        this.findDealers = (location = null, radius = RADIUS, setCenter = false) => {

            this.dataLoaded = false;

            this.unSlick();

            if (location === null) {
                /* use current location */
                location = this.location;
            }

            return closestDealer.findDealers(this.apiUrl, location, BRAND, false, radius).then(r => {

                if (r.manualPostalCode !== undefined) {
                    r.userZipCode = r.manualPostalCode;
                }

                // Don't display all dealers.
                if(this.selectFavoriteDealerList) {
                    this.dealers = r.dealers.slice(0, MAX_DISPLAYED_FAVORITE_DEALERS);
                } else {
                    this.dealers = r.dealers.slice(0, MAX_DISPLAYED_DEALERS);
                }

                // Add chat url for each dealer
                this.dealers.forEach((dealer, index) => {
                    if (dealer.isSubscribedToChat) {
                        let originationUrl = encodeURIComponent(window.location.href);
                        dealer.chatUrl = dealerChatService.getDealerChatUrl(dealer.chatProvider, dealer.chatProviderIdEn, dealer.code, originationUrl);
                    }
                    // dealer code is not unique, we create a dealer id that is unique
                    dealer.dealerId = dealer.code + '-' + index;
                });
                /* Get postal code and update location with */
                this.location = ng.extend({}, this.location, location, {
                    cp: r.userZipCode.replace(' ', '')
                });

                this.location.fitBounds = setCenter;

                this.selected = this.dealers[0];

                this.noResults = this.isInitialized && this.dealers.length === 0;

                /* Event to scroll list on top */
                $scope.$broadcast(EVENT_DATA_RESET, this.selected);

                // Set flag to true for tracking once
                this.isClientSideLocation = fcaGeolocator.isLocationBaseOnClientSide(this.location);

                this.dataLoaded = true;
                $timeout(function() {
                    $scope.$emit('component:loaded');

                    const slickRef = $('.closest-slick');
                    if (slickRef && slickRef.hasClass('slick-initialized') && !$ctrl.isCarouselDisabled) {
                        slickRef.slick('refresh')
                    }
                });
            });
        };

        this.unSlick = () => {
            let carouselRef = $('.closest-slick');
            if (carouselRef) {
                $('.closest-slick')
                    .slick('slickRemove')
                    .slick('unslick');
            }
        };


        this.initPreferredDealer = () => {
            let url = `/data/dealers/${BRAND}/dealer/${preferredDealerCode}/`;
            this.dataLoaded = false;
            this.unSlick();

            $http.get(url).then((r) => {
                closestDealerInitialized = true;
                let preferredDealer = r.data;
                let salesHours = [];

                if (preferredDealer.chatProvider !== undefined) {
                    preferredDealer = addChatUrl(preferredDealer, translateConfigurator);
                }

                this.dealers = [preferredDealer];

                $scope.$broadcast(EVENT_DATA_RESET, this.dealers);

                this.dataLoaded = true;
                $timeout(function() {
                    const slickRef = $('.closest-slick');
                    if (slickRef && slickRef.hasClass('slick-initialized') && !$ctrl.isCarouselDisabled) {
                        slickRef.slick('refresh')
                    }
                });
            }).catch(function(e) {
                console.error('error retrieving dealer data for preferred dealer code', e);
            });
        };

        function addChatUrl(dealer, translateConfigurator) {
            let dealerWithChatUrl = dealer;
            let originationUrl = encodeURIComponent(location.href);
            dealerWithChatUrl.chatUrl = dealerChatService.getDealerChatUrl(dealerWithChatUrl.chatProvider, dealerWithChatUrl.chatProviderIdEn, dealerWithChatUrl.code, originationUrl);
            return dealerWithChatUrl;
        }

        this.choosePreferedDealerOrClosestDealer = (location = this.location) => {
            preferredDealerCode = preferredDealerProvider.getPreferredDealer();

            if (preferredDealerCode !== null) {
                this.initPreferredDealer();
                this.selectFavoriteDealerList = false;
            } else if (location) {
                this.findDealers(location);
                this.isInitialized = true;
            }

        }

    }
})(angular);
