(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaGalleryPage', fcaGalleryPage);

	function fcaGalleryPage() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				isSlideshow: '<'
			},
			controllerAs: 'galleryPage',
			controller: fcaGalleryPageController
		};

		function fcaGalleryPageController($scope, $element, $timeout, $location, gtmAnalytics, matchmedia, FCA_MQ_LAYOUT) {
			'ngInject';
			/* eslint-disable no-invalid-this */
			/* eslint max-len: ["error", 130] */

			let vm = this;
			this.$element = $element;
			this.isInit = false;
			this.activeItemId = -1; // linked to slider index
			this.activeRowId = -1;
			this.$content = $('.gallery-page-grid-content', $element);
			this.$items = $('.gallery-page-grid-item', $element);
			this.$rows = $('.gallery-page-grid-row', $element);

			this.goto = function(itemId) {
				if (itemId != this.activeItemId) {
					// set current slide index to itemId
					this.activeItemId = itemId;
					$timeout(this.triggerActiveSlideContent);
				}else if (this.activeRowId == -1) {
					// expand content
					this.updateActiveItem();
					$timeout(this.triggerActiveSlideContent);
				}else{
					this.collapse();
				}
			};

			this.onInit = function() {
				if ($location.hash() && $location.hash() != '') {
					let uid = $location.hash();
					// get item corresponding to uid
					let $item = $element.find('.gallery-page-grid-item[data-item-uid="'+ uid +'"]');
					if ($item.length > 0) {
						// show item
						$timeout(() => {
							this.goto($item.data('item-id'));
						}, 500);
					}
				}
			};

			this.onBeforeChange = function(event, slick, currentSlide, nextSlide) {
				if (!this.isInit) {
					this.isInit = true;
				}else{
					if ($(slick.$slides[nextSlide]).hasClass('gallery-page-display-item-vr360')) {
						$('.gallery-slider-component').slick("slickSetOption", "draggable", false, false);
					} else {
						$('.gallery-slider-component').slick("slickSetOption", "draggable", true, true);
					}
					this.updateActiveItem(nextSlide);
				}
			};

			this.updateActiveItem = function(itemId = -1) {
				if (itemId == -1) itemId = this.activeItemId;
				if (itemId == -1) {
					this.collapse();
				} else {
					let $item = $('.gallery-page-grid-item[data-item-id="'+itemId+'"]', $element);
					let $row = $item.parent();
					let rowId = $row.data('row-id');
					let delay = 0;

					this.clearItems();
					if (rowId != this.activeRowId && this.activeRowId != -1) {
						this.collapse();
						if (this.isSlideshow) delay = 500;
					}

					this.activeRowId = rowId;
					$timeout(function() {
						vm.$rows.removeClass('active');
						vm.$content.addClass('expanded');
						$item.addClass('active');
						$row.addClass('active');
						vm.scrollToContent();
					}, delay);

					gtmAnalytics.trackEvent('event', {
						category: 'gallery',
						label: `image - ${$($item).data('analyticsLabel')}`
					});
				}
			};

			this.collapse = function() {
				this.scrollToRow(this.activeRowId);
				this.activeRowId = -1;
				this.$content.removeClass('expanded');
				this.clearItems();
				$scope.$broadcast('galleryPage.collapse');
			};

			this.clearItems = function() {
				this.$items.removeClass('active');
			};

			this.scrollToRow = function(rowId) {
				if (!this.isPhone()) {
					let top = $(`[data-row-id="${rowId}"]`).offset().top;

					$('html, body').stop().animate({
						scrollTop: top
					}, 500);
				}
			};

			this.scrollToContent = function() {
				if (this.isSlideshow) {
					let top = this.$content.offset().top - parseInt(this.$content.css('margin-top'));
					$('html, body').stop().animate({scrollTop: top}, 500);
				}
			};

			this.triggerActiveSlideContent = function() {
				// trigger slide content (play video, start 360, etc.)
				let $currentSlideElem = vm.$element.find('.slick-current');
				let galleryItemId = $currentSlideElem.data('gallery-item-id');
				$scope.$broadcast('galleryPage.triggerActiveSlideContent', galleryItemId);
			};

			this.isPhone = () => {
				let isMatching = false;
				matchmedia.on(FCA_MQ_LAYOUT.SMALL_PLUS, function(mediaQueryList) {
					if (mediaQueryList.matches) {
						isMatching = false;
					} else {
						isMatching = true;
					}
				});
				return isMatching;
			};
		}
	}
})();
