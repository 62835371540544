(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.translateConfigurator
	 * @description Configure translation module pascalprecht.translate and run it
	 * @example
	 * <pre>
	 * ng
	 *   .module('MyApp', ['fca.translateConfigurator'])
	 *   .config(function(translateConfiguratorProvider) {
	 *     translateConfiguratorProvider.configure({
	 *       url: '/api/url',
	 *       sanitizeStrategy: 'sanitize',
	 *       preferredLanguage: 'fr_CA',
	 *       loader: 'translateLoader'
	 *     }).use();
	 *   });
	 * </pre>
	 */
	angular
		.module('fca.translateConfigurator', [
			'pascalprecht.translate'
		]);
})();
