(function() {
	'use strict';

	angular
		.module('fca.daaCalculatorWidget')
		.service('daaFrequency', Frequency);

	/**
	 * @ngdoc service
	 * @name fca.daaCalculatorWidget.service:daaFrequency
	 * @requires $translate
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function Frequency($translate) {
		'ngInject';

		const WEEKLY = $translate.instant('codes.frequency.weekly');
		const BI_WEEKLY = $translate.instant('codes.frequency.biweekly');
		const BI_MONTHLY = $translate.instant('codes.frequency.bimonthly');
		const MONTHLY = $translate.instant('codes.frequency.monthly');

		const WEEKLY_TRACK = 'weekly';
		const BI_WEEKLY_TRACK = 'bi-weekly';
		const BI_MONTHLY_TRACK = 'semi-monthly';
		const MONTHLY_TRACK = 'monthly';

		/**
		 * @ngdoc method
		 * @name getFrequencyPerYearFromInput
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {NUmber} fParam Numeric value for range input
		 * @return {Number}        Return Numeric value of payment frequency per year
		 */
		this.getFrequencyPerYearFromInput = (fParam) => {
			let f;
			switch (fParam) {
			case 0:
				f = 52;
				break;
			case 2:
				f = 26;
				break;
			case 4:
				f = 24;
				break;
			default:
				f = 12;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequencyInputFormYear
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {NUmber} fParam Numeric value of payment frequency per year
		 * @return {Number}        Return numeric value for range input
		 */
		this.getFrequencyInputFormYear = (fParam) => {
			let f;
			switch (fParam) {
			case 52:
				f = 0;
				break;
			case 26:
				f = 2;
				break;
			case 24:
				f = 4;
				break;
			default:
				f = 6;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequencyInputFromString
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {String} fParam String value of payment frequency per month
		 * @return {Number}        Return numeric value for range input
		 */
		this.getFrequencyInputFromString = (fParam) => {
			let f;
			switch (fParam) {
			case WEEKLY:
				f = 0;
				break;
			case BI_WEEKLY:
				f = 2;
				break;
			case BI_MONTHLY:
				f = 4;
				break;
			default:
				f = 6;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequencyStrFromInput
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {Number} fParam Number value in range input
		 * @return {Number}        Return string of payment frequency per month
		 */
		this.getFrequencyStrFromInput = (fParam, toTrack = false) => {
			let f;
			switch (fParam) {
			case 0:
				f = toTrack ? WEEKLY_TRACK : WEEKLY;
				break;
			case 2:
				f = toTrack ? BI_WEEKLY_TRACK : BI_WEEKLY;
				break;
			case 4:
				f = toTrack ? BI_MONTHLY_TRACK : BI_MONTHLY;
				break;
			default:
				f = toTrack ? MONTHLY_TRACK : MONTHLY;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequencyMappingValue
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {Number} fParam Number value in range input
		 * @return {Number}        Return string of payment frequency per month
		 */
		this.getFrequencyMappingValue = (fParam) => {
			let f;
			switch (fParam) {
			case 1:
				f = 4;
				break;
			case 2:
				f = 0;
				break;
			case 3:
				f = 2;
				break;
			default:
				f = 6;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequencyMappingValueForRequest
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @param  {Number} fParam Number value in range input
		 * @return {Number}        Return string of payment frequency per month
		 */
		this.getFrequencyMappingValueForRequest = (fParam) => {
			let f;
			switch (fParam) {
			case 4:
				f = 1;
				break;
			case 0:
				f = 2;
				break;
			case 2:
				f = 3;
				break;
			default:
				f = 0;
			}

			return f;
		};

		/**
		 * @ngdoc method
		 * @name getFrequenciesLbls
		 * @methodOf fca.daaCalculator.service:daaFrequency
		 * @description [TODO]
		 * @return {Object} List of string frequencies per month
		 */
		this.getFrequenciesLbls = () => {
			return {
				weekly: WEEKLY,
				biWeekly: BI_WEEKLY,
				biMonthly: BI_MONTHLY,
				monthly: MONTHLY
			};
		};
	}
})();
