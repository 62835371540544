(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaBackToTop', fcaBackToTop);

	function fcaBackToTop() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'backToTop',
			controller: fcaBackToTopController
		};

		function fcaBackToTopController($element) {
			'ngInject';

			this.$onInit = () => {
				this.backToTop();
			};

			function headerPositionScrollTop() {
				let scrollHeight = document.body.scrollTop || document.documentElement.scrollTop;

				$element.addClass('show');

				if(scrollHeight <= 0) {
					$element.removeClass('show');
				}
			}

			function headerPositionScrollBottom() {
				$element.removeClass('show');
			}

			this.backToTop = () => {
				let lastScrollTop = 0;

				$(window).scroll(function(event) {
					let st = $(window).scrollTop();

					if (st > lastScrollTop) {
						// downscroll code
						headerPositionScrollBottom();
					} else {
						// upscroll code
						headerPositionScrollTop();
					}

					lastScrollTop = st;
				});

				$element.on('click', function (e) {
					e.preventDefault();
					$('html,body').animate({
						scrollTop: 0
					}, 700);
				});
			}
		}
	}
})();
