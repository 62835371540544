(function () {
	angular
		.module('alfaromeo.buildAndPrice.sticky')
		.directive('alfaSticky', () => ({
			link: (_, element) => alfaSticky(element[0])
		}));

	function alfaSticky(element) {
		const isIE = /MSIE|Trident/.test(navigator.userAgent);

		if (isIE) {
			window.addEventListener('scroll', () => stickyScroll(element));
		} else {
			element.classList.add('-sticky');
		}
	}

	const stickyScroll = element => {
		const containerRect = element.parentNode.getBoundingClientRect();
		const elementHeight = element.offsetHeight;

		const stuckBottom = containerRect.bottom < elementHeight;
		const stuckTop = containerRect.top < 0 && !stuckBottom;

		requestAnimationFrame(() => {
			$(element).toggleClass('-stuckBottom', stuckBottom);
			$(element).toggleClass('-stuckTop', stuckTop);
		});
	}
})();
