(function() {
    'use strict';

    angular
        .module('fca.exceptionHandler', [])
        .factory('$exceptionHandler', function () {
            return function exceptionHandler(exception) {
                const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
                const msie = ua.indexOf('MSIE '); // IE 10 or older
                const trident = ua.indexOf('Trident/'); //IE 11

                if ((msie > 0 || trident > 0)) { // IE doesn't support nice console.trace
                    console.error('unhandled exception!: ' + exception.message);

                    // ie truncates stack traces, so we need to print the stack trace line by line
                    if (exception.stack) {
                        const stackSplitted = exception.stack.split("\n");
                        if (stackSplitted) {
                            stackSplitted.forEach((line) => {
                                console.error(line);
                            });
                        }
                    }

                } else {
                    console.trace('%cunhandled exception!', 'color:red;', exception);
                }
            };
        });
})();
