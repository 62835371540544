(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaNameplateOffersAccordion', fcaNameplateOffersAccordion);

	function fcaNameplateOffersAccordion() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'nameplateOffersAccordion',
			controller: FcaNameplateOffersAccordionController,
		};

		function FcaNameplateOffersAccordionController($element, $rootScope, $timeout) {
			'ngInject';

			this.$onInit = () => {
				this.isOpen = false;
				$element.find('.nameplateOffers-accordionContent').slideUp();

				if($element.find('div[data-accordion-offer-inventory]').length > 0) {
					this.isOpen = !this.isOpen;
					$timeout( () => {
						$rootScope.$broadcast('nameplateOffersAccordion.accordionOpen');
						$element.find('div[data-accordion-offer-inventory]').slideDown();
					});
				}
			};

			this.toggleAccordionItem = (getOfferInventory) => {
				this.isOpen = !this.isOpen;
				if (this.isOpen) {
					$element.find('.nameplateOffers-accordionContent').slideDown();
					if (getOfferInventory) {
						$rootScope.$broadcast('nameplateOffersAccordion.accordionOpen');
					}
				} else {
					$element.find('.nameplateOffers-accordionContent').slideUp('fast', function() {
						if (getOfferInventory) {
							$rootScope.$broadcast('nameplateOffersAccordion.accordionClose');
						}
					});
				}
			}
		}

	}
})();
