(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('apr', AprLocalized);

    function AprLocalized($filter) {
        'ngInject';

        const language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');

        return function(rate) {
            if (!rate) {
                return '';
            }
            let filteredRate = $filter('number')(rate, 2);
            filteredRate = filteredRate ? filteredRate : '0';

            if (language === 'fr') {
                return `(taux de&nbsp;<strong>${filteredRate}%</strong>)&nbsp;`;
            } else {
                return `(<strong>${filteredRate}%</strong>&nbsp;<span class="apr-text">APR</span>)&nbsp;`;
            }
        };
    }
})();

