(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.daaCalculatorWidget
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.daaCalculatorWidget', ['fca.geolocator.cookie'])
		.run(function($log) {
			'ngInject';
			$log.debug('fca.daaCalculatorWidget run !');
		});
})();
