(function() {
	angular
		.module('fcaModelSpecs')
		.directive('fcaModelSpecsCategory', fcaModelSpecsCategory);

	function fcaModelSpecsCategory($timeout) {
		return {
			restrict: 'A',
			scope: true,
			bindToController: true,
			controller: FcaModelSpecsCategoryController,
			controllerAs: 'specsCategory'
		};

		function FcaModelSpecsCategoryController($rootScope, $scope, $timeout, $element, modelSpecsGrid, matchmedia, FCA_MQ_LAYOUT, functionExecutionControl) { // eslint-disable-line max-len
			'ngInject';

			this.hashParameters = null;
			this.categoryHidden = false;
			this.toggleIsActive = false;
			this.numberOfSlaves = 0;
			this.numberOfHiddenSlaves = 0;
			this.activeSlide = 0;
			this.activeTab = 'cash';
			this.toggleSpecIsOpen = false;

			const maxContainerWidth = 1325;

			this.addToCategory = () => {
				this.numberOfSlaves++;
			};

			this.hideCategory = () => {
				this.numberOfHiddenSlaves++;

				if (this.numberOfSlaves == this.numberOfHiddenSlaves) {
					this.categoryHidden = true;
				}
			};

			this.showCategory = () => {
				this.numberOfHiddenSlaves = 0;
				this.categoryHidden = false;
			};

			this.isDesktop = () => {
				let matchesMedia = false;

				matchmedia.on(FCA_MQ_LAYOUT.LARGE_PLUS, function(mediaQueryList) {
					if (mediaQueryList.matches) {
						matchesMedia = true;
					} else {
						matchesMedia = false;
					}
				});

				return matchesMedia;
			};

			this.animateCarousel = (slide) => {
				let slidesToShow = 1;

				if (this.isDesktop()) {
					slidesToShow = 3;
				}

				let trimSize = Math.ceil($('.master-carousel').width() / slidesToShow);
				let scrollDistance = (trimSize * slide) * -1;

				this.activeSlide = slide;
				this.carouselElement.css('transform', `translate3d(${scrollDistance}px, 0, 0)`);

				let reverseScrollDistance = -scrollDistance;
				$('.fixed-over-title').css("transform", `translate3d(${reverseScrollDistance}px, 0, 0)`);
			};

			this.tablesBackground = () => {
				this.toggleIsActive = true;

				$timeout(() => {
					$element.find('.slave-carousel:not(.is-hidden):odd').addClass('is-odd');
					$element.find('.slave-carousel:not(.is-hidden):even').addClass('is-even');
				});
			};

			this.removeTablesBackground = () => {
				$('.slave-carousel').removeClass('is-odd');
				$('.slave-carousel').removeClass('is-even');
			};

			this.hideTrim = (event, slide, hiddenTrims) => {
				let $slideToFade = $element.find(`[data-trim="${slide}"]`);

				$slideToFade.addClass('fade-away');

				$timeout(() => {
					$slideToFade.css('display', 'none');

					if (modelSpecsGrid.totalVisibleTrims <= 3 && this.isDesktop()) {
						this.carouselElement.css('transform', 'translate3d(0, 0, 0)');
					}
				}, 500);
			};

			this.resetCarousels = (event, slide) => {
				$('[data-trim]').removeClass('fade-away');
				$('[data-trim]').css('display', 'inline-block');
				this.activeSlide = 0;
			};

			this.serviceWatchers = () => {
				$rootScope.$on('modelSpecsGrid.hideIndenticalTrims', () => {
					this.tablesBackground();
				});

				$rootScope.$on('modelSpecsGrid.showIdenticalTrims', () => {
					this.toggleIsActive = false;
					this.removeTablesBackground();
				});

				$rootScope.$on('modelSpecsGrid.masterCarouselChange', (event, slide) => {
					this.animateCarousel(slide);
				});

				$rootScope.$on('modelSpecsGrid.windowWasResized', () => {
					this.animateCarousel(this.activeSlide);
				});

				$rootScope.$on('modelSpecsGrid.hideTrim', (event, slide, hiddenTrims) => {
					this.hideTrim(event, slide, hiddenTrims);
				});

				$rootScope.$on('modelSpecsGrid.resetCarousels', (event) => {
					this.resetCarousels();
				});

				$rootScope.$on('modelSpecsGrid.toggleTriggers', (event) => {
					// hideCategoryIfEmpty 
					this.hideCategoryIfEmpty();
				});
			};

			this.setActiveTab = (tab) => {
				this.activeTab = tab;
				this.urlSuffix = postProcessUrl(this.hashParameters, this.activeTab);
			};

			this.$onInit = () => {
				$timeout(() => {
					this.carouselElement = $element.find('.specs-module-carousel');
					this.hideCategoryIfEmpty();
				});

				this.urlSuffix = postProcessUrl(this.hashParameters, this.activeTab);

				this.serviceWatchers();
				
			};

			$rootScope.$on('modelSpecsGrid.toggleTriggers', (e, showOnlyHighlights) => {
				$timeout(() => {
					this.hideCategoryIfEmpty();

					this.manageDropdown(showOnlyHighlights);
				});
			});

			this.hideCategoryIfEmpty = () => {
				this.categoryHidden = this.carouselElement.find('.slave-carousel:not(".ng-hide")').length === 0;
			};

			this.manageDropdown = showOnlyHighlights => {
					this.toggleSpecIsOpen = false;
					const modelSpecFeatures = $element.find('.VLP2021-model-specs-table .feature-desc');
					let featuresCount = 0;

					modelSpecFeatures.each(function (index) {
						this.classList.remove('is-collapsed', 'is-collapsible');
					});


					modelSpecFeatures.each(function (index) {
						// increment features (excluding titles)
						if (!this.classList.contains('fixed-over-title') && !this.classList.contains('featured-title') && !this.classList.contains('ng-hide')) {
							featuresCount++;
						} else if (featuresCount === 5 && (this.classList.contains('fixed-over-title') || this.classList.contains('featured-title'))) {
							// exclude titles as last element
							this.classList.add('is-collapsed', 'is-collapsible');
						}

						if (featuresCount > 5) {
							// hide remaining features
							this.classList.add('is-collapsed', 'is-collapsible');
						}
					});

				let slidesToShow = this.isDesktop() ? 3 : 1;
				let trimSize = Math.ceil($('.master-carousel').width() / slidesToShow);
				let reverseScrollDistance = trimSize * this.activeSlide;

				$('.fixed-over-title').css("transform", `translate3d(${reverseScrollDistance}px, 0, 0)`);
			};

			this.toggleSpecs = e => {
				this.toggleSpecIsOpen = !this.toggleSpecIsOpen;
				const currentSection = $(e.currentTarget).closest('.feature-section');

				if (this.toggleSpecIsOpen) {
					currentSection.find('.feature-desc.is-collapsed').removeClass('is-collapsed');
				} else {
					currentSection.find('.feature-desc.is-collapsible').addClass('is-collapsed');
				}
			};

			this.resizeSpecsBackgrounds = () => {
				const containerWidth = window.innerWidth <= maxContainerWidth ? window.innerWidth : maxContainerWidth;
				const backgroundsWidth = (containerWidth - 100) / 3; // 100 is margin for desktop resolution

				$('.VLP2021-model-specs-table-background').css('width', `${backgroundsWidth}px`);
			};

			$(window).on('resize', functionExecutionControl.debounce(this.resizeSpecsBackgrounds, 400));

			$scope.$on("$destroy", () => {
				$(window).off('resize');
			});
		}
	}
})();
