(function () {
    angular
        .module('fca.programsBanner')
        .directive('fcaProgramsBanner', FcaProgramsBanner);

    function FcaProgramsBanner() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                trimName: '@',
                baseSniBuildUrl: '@'
            },
            controllerAs: '$programsCtrl',
            controller: FcaProgramsBannerController
        };

        function FcaProgramsBannerController($scope, $rootScope, $location, $filter, $http, trimService, configService, userLocation,
                                             fcaGeolocator) {
            'ngInject';

            const $programsCtrl = this;

            $programsCtrl.selectedTrim = {};
            $programsCtrl.selectedTrimGroup = {};
            $programsCtrl.selectedPackage = {};
            $programsCtrl.location = "";
            $programsCtrl.locationLabel = "";
            $programsCtrl.queryParams = "";
            $programsCtrl.language = FCA_SITES_CONFIG.language;

            $programsCtrl.$onInit = () => {

                $scope.$on(fcaGeolocator.getLocationUpdatedEvent(), (_, args) => {
                    const location = args[0];
                    $programsCtrl.locationLabel = window.FCA_SITES_CONFIG.location[location.province].desc;
                });

                $programsCtrl.queryParams = addQueryParametersToUrl($programsCtrl.queryParams);

                // Once a trim has been determined, parse the data to show detailed information
                // rather than just codes
                $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                    $programsCtrl.modelYearId = configService.modelYearId;
                    $programsCtrl.nameplateCode = configService.nameplateCode;
                    $programsCtrl.year = configService.year;
                    $programsCtrl.brandCode = configService.brandCode;
                    $programsCtrl.acode = configService.acode;
                    $programsCtrl.provinceCode = configService.provinceCode;

                    // Update both the trim and the package to change the displayed information
                    $programsCtrl.selectedTrim = data.selectedTrim;
                    $programsCtrl.selectedTrimGroup = data.selectedTrimGroup;
                    $programsCtrl.mfgCode = $programsCtrl.selectedTrim.mfgCode;
                    $programsCtrl.trimName = $programsCtrl.selectedTrimGroup.code;
                    $programsCtrl.selectedPackage = data.selectedTrim.packages[0];

                    $programsCtrl.setCTAUrls();
                });

                $scope.$on('scratch-save-updated:scratch-save', (_, data) => {
                    $programsCtrl.setCTAUrls();
                });

                $rootScope.$on('options-selector:paint-updated', (_, data) => {
                    $programsCtrl.paint = data.paint.substr(0, 3);
                    $programsCtrl.setCTAUrls();
                    configService.setPaintCode(data.paint);
                });
            }

            $programsCtrl.setCTAUrls = () => {
                // check if we're in iframe mode
                let dealerFromStorage = JSON.parse(sessionStorage.getItem('dealer-lock'));
                if (dealerFromStorage) {
                    // check if the vendor configuration is overrided by the iframe
                    let vendor = sessionStorage.getItem('vendor');

                    // if there's no vendor in session storage, use the vendor from the dealer object
                    if (!vendor || vendor === '') {
                        vendor = dealerFromStorage.vendor;
                    }

                    // use the vendor url specified by iframe parameters
                    let dealerUrl = sessionStorage.getItem('dealer-url');

                    // if there's no dealer url in session storage, use the url from the dealer object
                    if (!dealerUrl || dealerUrl === '') {
                        dealerUrl = dealerFromStorage.url;
                    }

                    // if the dealer url does not start with a protocol add https
                    if (!dealerUrl.startsWith('http://') && !dealerUrl.startsWith('https://')) {
                        dealerUrl = "https://" + dealerUrl;
                    }

                    // retrieve the vendor mapping
                    $http.get(`/data/dealer-website/mapping?vendor=${vendor}`)
                        .then(response => {
                            if (response.data) {
                                let vendorMapping = response.data;
                                if (vendorMapping) {
                                    // get the make mapping
                                    let makeMapping = (vendorMapping.makes || []).find(item => {
                                        return item.code === $programsCtrl.brandCode;
                                    });
                                    // get the model mapping
                                    let modelMapping = (vendorMapping.models || []).find(item => {
                                        return item.id === $programsCtrl.modelYearId;
                                    });
                                    let srp = '';
                                    if ($programsCtrl.language == 'fr') {
                                        srp = vendorMapping.srp.fr || '';
                                    } else {
                                        srp = vendorMapping.srp.en || '';
                                    }
                                    srp = srp.split('{{url}}').join(dealerUrl);
                                    srp = srp.split('{{trim}}').join($programsCtrl.trimName);
                                    srp = srp.split('{{year}}').join($programsCtrl.year);
                                    if (makeMapping) {
                                        srp = srp.split('{{make}}').join(makeMapping.name);
                                    }
                                    if (modelMapping) {
                                        srp = srp.split('{{model}}').join(modelMapping.name);
                                    }
                                    $programsCtrl.inventoryCTA = srp;
                                }
                            }
                        }).catch(function (e) {
                        throw new Error('error retrieving vendor mapping: ' + e);
                    });
                } else {
					const queryParams =$programsCtrl.queryParams !== "" ? $programsCtrl.queryParams : "?";

                    $programsCtrl.inventoryCTA =
                        `${$programsCtrl.baseSniBuildUrl}` +
                        `${queryParams}` +
                        `paymentMode=cash&year=${$programsCtrl.year}&trim=${$programsCtrl.trimName}` +
                        `&modelYearId=${$programsCtrl.modelYearId}` +
                        `&acode=${$programsCtrl.acode}` +
                        `&trimPkg=${$programsCtrl.selectedPackage.code}` +
                        `&trimOpts=${$programsCtrl.paint}`;
                }
            };

            $programsCtrl.initLocation = () => {
                $programsCtrl.location = userLocation.getLocation();
                $programsCtrl.locationLabel = userLocation.getLocationLabel();
            };
        }
    }
})();
