(function() {
    angular
        .module('fca.personalizationDaa')
        .provider('personalizationDaaScenario', PersonalizationDaaScenarioProvider)

    // Pass the enable value from directive to config
    function PersonalizationDaaScenarioProvider() {
        this.enable = false;
        this.localStorageName = 'fcaPersonalizationScenario_5_1';
        this.propertiesList = ['status', 'trigger', 'acode', 'offerid', 'trimgroup', 'vin', 'year', 'modelyearid', 'nameplatecode'];
        this.preQuietDelayInMinutes = 1440;
        this.fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(this.localStorageName));

        this.$get = function() {
            return {
                enable: this.enable,
                localStorageName: this.localStorageName,
                activate: (value) => this.activate(value),
                createWith: (properties) => this.createWith(properties),
                updateStatusWith: (vin, status) => this.updateStatusWith(vin, status),
                addPreQuietThreshold: () => this.addPreQuietThreshold(),
                destroy: () => this.destroy(),
                destroyWithVin: (vin) => this.destroyWithVin(vin)
            }
        };

        this.activate = value => this.enable = value;

        // Create
        this.createWith = properties => {
            if (!this.fcaPersonalizationObject) this.fcaPersonalizationObject = {};
            this.propertiesList.forEach(item => this.fcaPersonalizationObject[item] = properties[item]);
            this.fcaPersonalizationObject['trigger'] = true;

            let configPreQuietDelayInMinutes = window.FCA_SITES_CONFIG.personalizationPreQuietDelay;
            this.setPreQuietThreshold(this.fcaPersonalizationObject, configPreQuietDelayInMinutes);

            this.setItemWith(this.fcaPersonalizationObject)
        }

        // Update
        this.addPreQuietThreshold = () => {
            if (this.fcaPersonalizationObject) {
                this.setPreQuietThreshold(this.fcaPersonalizationObject, this.preQuietDelayInMinutes);
                this.setItemWith(this.fcaPersonalizationObject)
            }
        }

        this.updateStatusWith = (vin, status) => {
            if (this.fcaPersonalizationObject && this.fcaPersonalizationObject.vin == vin) {
                this.fcaPersonalizationObject['status'] = status;
                this.setItemWith(this.fcaPersonalizationObject)
            }
        }

        // Destroy
        this.destroy = () => {
            if (this.fcaPersonalizationObject) window.localStorage.removeItem(this.localStorageName);
        }

        this.destroyWithVin = (vin) => {
            if (this.fcaPersonalizationObject && this.fcaPersonalizationObject.vin == vin) {
                window.localStorage.removeItem(this.localStorageName);
            }
        }

        // Utils
        this.setItemWith = object => window.localStorage.setItem(this.localStorageName, JSON.stringify(object));

        this.addMinutes = (date, minutes) => new Date(date.getTime() + minutes*60000);

        this.setPreQuietThreshold = (fcaPersonalizationObject, addMinutes) => {
            let nowDate = new Date();
            let preQuietThreshold = this.addMinutes(nowDate, addMinutes);
            fcaPersonalizationObject.preQuietThresholdScenario = preQuietThreshold;
            console.debug('Added pre threshold:', preQuietThreshold);
        }
    }
})();

