(function () {
	angular
		.module('fca.brandApp')
		.service('povHelper', PovHelper);

	function PovHelper() {
		const $service = this;

		$service.povMin = 1;
		$service.povMax = 36;

		// Shortcuts for internal use
		const povMin = $service.povMin;
		const povMax = $service.povMax;

		$service.format = pov => {
			pov = pov || 1;
			return 'E' + pov.toString().padStart(2, '0');
		}

		$service.getPovMax = () => {
			return povMax;
		}

		$service.wrap = pov => {
			pov %= povMax;

			if (pov < povMin) {
				return pov + povMax;
			}

			return pov;
		}

		$service.pathBetween = (startPov, endPov) => {
			const clockwise = directionToMove(startPov, endPov);
			const increment = clockwise ? 1 : -1;

			const path = [];
			for (let pov = startPov; pov !== endPov; pov += increment) {
				pov = $service.wrap(pov);
				path.push(pov);
			}

			path.push(endPov);
			return path;
		}

		// True => clockwise
		const directionToMove = (a, b) => {
			const clockwiseDistance = $service.wrap(b - a);

			return (clockwiseDistance - povMin) < (povMax - povMin) / 2;
		}

		$service.distanceBetween = (a, b) => {
			const difference = Math.abs(b - a);

			if (difference < povMax / 2) {
				return difference;
			}

			return povMax - difference;
		}
	}
})();
