(function() {
	angular
		.module('fca.provincializeBlock')
		.directive('fcaProvincializeBlock', provincializeBlock);

	/**
	 * Directive to show/hide an element based on the location of the user
	 *
	 * @param {String} whitelistProvinceCodes The list of whitelisted province codes
	 * @param {String} blacklistProvinceCodes The list of blacklisted province codes
	 * @param {String} divStyle The style of the element applied to hide the block. If displayNone, the block is hidden using display: none. By default, the block is hidden using visibility: hidden.
	 *
	 * @return {Object} The directive definition object.
	 */
	function provincializeBlock() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				whitelistProvinceCodes: '=',
				blacklistProvinceCodes: '=',
				divStyle: '@'
			},
			controller: ProvincialBlockController,
			controllerAs: '$blockCtrl',
		};

		function ProvincialBlockController($rootScope, $scope, $element, userLocation, fcaGeolocator) {
			'ngInject';

			const TRANSITION_TIME = 1000;
			const ctrl = this;

			ctrl.$onInit = () => {
				const whitelistedProvinceCodes = ctrl.toLowerCaseArray(ctrl.whitelistProvinceCodes);
				const blacklistedProvinceCodes = ctrl.toLowerCaseArray(ctrl.blacklistProvinceCodes);
				const divStyle = ctrl.divStyle;

				ctrl.manageBlockState(whitelistedProvinceCodes, blacklistedProvinceCodes);

				$rootScope.$on(fcaGeolocator.getLocationChangedEvent(), (evt, args) => {
					ctrl.manageBlockState(whitelistedProvinceCodes, blacklistedProvinceCodes);
				});
			};

			/**
			 * we need an array
			 * we only want lowercase values
			 */
			ctrl.toLowerCaseArray = (value) => {
				return value instanceof Array
					? value.filter(value => value && value.length > 0)
						.map(value => value.toLowerCase())
					: [];
			};

			ctrl.manageBlockState = (whitelistedProvinceCodes, blacklistedProvinceCodes) => {
				if(whitelistedProvinceCodes.length === 0 && blacklistedProvinceCodes.length === 0) {
					// given whitelist and blacklist are empty,
					//		- then block is displayed
					$element.css('visibility', "visible");
					$element.fadeIn(TRANSITION_TIME);
				} else {
					// force it to determine location (or else the call to getLocationProvince won't work correctly)
					userLocation.getLocation();
					const province = (userLocation.getLocationProvince()||'').toLowerCase();
					const isWhitelisted = whitelistedProvinceCodes.includes(province);
					const isBlacklisted = blacklistedProvinceCodes.includes(province);

					// - given the whitelist is not empty,
					// 		then show the block if the province is in the list,
					// 		otherwise the block is not displayed
					// - given the blacklist is not empty,
					//		then hide the block if the province is in the list,
					// 		otherwise the block is displayed
					// - given whitelist and blacklist are not empty,
					//		then only whitelist is considered
					if(whitelistedProvinceCodes.length !== 0) {
						if(isWhitelisted) {
							$element.addClass('fca-provincialize-block');
							if (ctrl.divStyle === 'displayNone') {
								$element.css('display', "block");
								$element.removeClass('fca-provincialize-block-none');
							} else {
								$element.removeClass('fca-provincialize-block-hidden');
								$element.css('visibility', "visible");
							}
                            $element.css('padding', '');
						} else {
							$element.removeClass('fca-provincialize-block');
							if (ctrl.divStyle === 'displayNone') {
								$element.addClass('fca-provincialize-block-none');
								$element.css('display', "none");
								$element.css('padding', '0');
							} else {
								$element.addClass('fca-provincialize-block-hidden');
								$element.css('visibility', "hidden");
								$element.css('padding', '0');
							}
						}
					} else if(blacklistedProvinceCodes.length !== 0) {
						if(isBlacklisted) {
							$element.removeClass('fca-provincialize-block');
							if (ctrl.divStyle === 'displayNone') {
								$element.addClass('fca-provincialize-block-none');
								$element.css('display', "none");
								$element.css('padding', '0');
							} else {
								$element.addClass('fca-provincialize-block-hidden');
								$element.css('visibility', "hidden");
								$element.css('padding', '0');
							}
						} else {
							$element.addClass('fca-provincialize-block');
							if (ctrl.divStyle === 'displayNone') {
								$element.css('display', "block");
								$element.removeClass('fca-provincialize-block-none');
							} else {
								$element.removeClass('fca-provincialize-block-hidden');
								$element.css('visibility', "visible");
							}
                            $element.css('padding', '');
						}
					}
				}
			};
		}
	}
})();
