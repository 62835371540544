(function() {
    angular
        .module('fca.miniNav')
        .component('fcaMiniNavSideScroll', {
            controller: FcaMiniNavSideScrollController,
            templateUrl: '/components/mini-nav/fca-mini-nav-side-scroll/fca-mini-nav-side-scroll.html',
            transclude: true
        });

    function FcaMiniNavSideScrollController($scope, $window, $element, matchmedia, FCA_MQ_LAYOUT) {

        let $ctrl = this;

        $scope.$on('event:resetMiniNavPosition', function(event, navClass) {
            let $listElem = $element.find('ul').eq(0);
            $listElem.css('width', 'auto');
            let naturalWidth = $listElem.width();
            let listItemsWidths = getListItemLengths($listElem);
            let totalWidth = Math.ceil(listItemsWidths.reduce((a, b) => a + b, 0));
            if (totalWidth > naturalWidth) {
                $listElem.css('width', totalWidth);
                $ctrl.showScrollPrev = false;
                $ctrl.showScrollNext = true;

                $ctrl.offsets = getListItemPositions($listElem);
                $element.currentOffset = 0;

                $element.scrollLeft = 0;
                $ctrl.totalScroll = totalWidth - naturalWidth;
                $ctrl.elementOffset = $element.offset().left;

                let listItems = $listElem.find('li > *');
                let currentActivePosition = listItems.index($('.is-active'));
                for (let x=0; x < currentActivePosition; x++) {
                    $ctrl.scrollToOffset('next');
                }
            }
            $scope.$digest();
            $('body').trigger('fcaMiniNavSideScrollEvent');
        });

        this.$postLink = () => {
            matchmedia.on(FCA_MQ_LAYOUT.MOBILE, (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    init.call(this);
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.MOBILE_LANDSCAPE, (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    init.call(this);
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.TABLET, (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    init.call(this);
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_SMALL, (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    init.call(this);
                }
            });

            matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_LARGE, (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    init.call(this);
                }
            });

            this.dragging = false;

            this.onTouchStart = (event) => {
                $element.bind('touchmove', this.onTouchMove);
            };
            this.onTouchMove = (event) => {
                this.onMove(event);
            };
            this.onTouchEnd = (event) => {
                $element.unbind('touchmove', this.onTouchMove);
            };
            $element.bind('touchstart', this.onTouchStart);
            $element.bind('touchend', this.onTouchEnd);

            this.onMove = (event) => {
                this.dragScroll();
                $scope.$apply();
            };

            $scope.getWindowOrientation = function() {
                return $window.orientation;
            };

            $scope.$watch($scope.getWindowOrientation, function(newValue, oldValue) {
                $scope.degrees = newValue;
            }, true);

            angular.element($window).bind('orientationchange', function(mediaQueryList) {
                /* eslint-disable no-invalid-this */
                init.call(this);
            });

            function init() {
                /* eslint-disable no-invalid-this */
                let $listElem = $element.find('ul').eq(0);
                $listElem.css('width', 'auto');

                // calculate widths
                let naturalWidth = $listElem.width();
                let listItemsWidths = getListItemLengths($listElem);
                let listItemsPositions = getListItemPositions($listElem);
                let totalWidth = Math.ceil(listItemsWidths.reduce((a, b) => a + b, 0));

                if (totalWidth > naturalWidth) {
                    $listElem.css('width', totalWidth);

                    this.offsets = listItemsPositions;
                    $element.currentOffset = 0;

                    $element.scrollLeft = 0;
                    this.totalScroll = totalWidth - naturalWidth;
                    this.elementOffset = $element.offset().left;
                }

                $ctrl.showScrollPrev = false;
                $ctrl.showScrollNext = totalWidth > naturalWidth;
                $('body').trigger('fcaMiniNavSideScrollEvent');
            }
        };

        this.scrollToOffset = (direction) => {
            if (!this.offsets) {
                let $listElem = $element.find('ul').eq(0);
                let listItemsPositions = getListItemPositions($listElem);
                this.offsets = listItemsPositions;
            }
            let $mainNavSubmenu = $element.find('.mini-nav-sub-menu');

            if (direction === 'prev') {
                $element.scrollLeft = this.offsets[$element.currentOffset - 1];
                $mainNavSubmenu.scrollLeft($element.scrollLeft);

                $element.currentOffset--;
            } else {
                // default to 'next'
                $element.scrollLeft = this.offsets[$element.currentOffset + 1];
                $mainNavSubmenu.scrollLeft($element.scrollLeft); // move by width of one "li"

                $element.currentOffset++;
            }

            // manage button visibility
            if (($element.scrollLeft >= this.totalScroll) ||
                ($element.currentOffset >= this.offsets.length)) {
                this.showScrollNext = false;
            } else {
                this.showScrollNext = true;
            }

            $ctrl.showScrollPrev = $element.currentOffset !== 0;
        };

        this.dragScroll = (direction) => {
            let $mainNavSubmenu = $element.find('.mini-nav-sub-menu');
            let mainOffset = $mainNavSubmenu.find('ul').position();
            let $menuElements = $mainNavSubmenu.find('ul > li');

            $menuElements.each( function() {
                let $this = $(this);
                let offset = $this.offset().left;
                if(offset <= 0 && offset >= $this.outerWidth() * -1) {
                    $element.currentOffset = $this.index() + 1;
                    $element.scrollLeft = $this.index();
                }
            });

            this.positionLeft = (mainOffset.left * -1);

            $ctrl.showScrollNext = this.positionLeft < this.totalScroll;
            $ctrl.showScrollPrev = this.positionLeft > 0;
        };

        function getListItemLengths($listElem) {
            let listItemsWidths = [];

            $listElem.find($('li')).each((index, elem) => {
                listItemsWidths.push($(elem).width());
            });

            return listItemsWidths;
        }

        function getListItemPositions($listElem) {
            let listItemsPositions = [];
            let initialWidth = 0;

            $listElem.find($('li')).each((index, elem) => {
                listItemsPositions.push(initialWidth);
                $(elem).attr('offset-left', initialWidth);
                initialWidth = initialWidth + $(elem).width();
            });

            return listItemsPositions;
        }
    }
})();

