(function() {
	'use strict';

	angular
		.module('fca.daaContactDealer')
		.filter('telephone', Telephone);

	function Telephone() {
		return function(tel) {
			if (!tel) {
				return '';
			}

			let value = tel.toString()
				.trim()
				.replace(/^\+/, '')
				.replace(/\s/, '')
				.replace(/\-/, '')
				.replace(/\(/, '')
				.replace(/\)/, '');

			return value;
		};
	}
})();
