
(function() {
	angular
		.module('fca.bodyBuilder')
		.directive('bodyBuilderGuideDocuments', bodyBuilderGuideDocuments);

	function bodyBuilderGuideDocuments() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@',
				modelCode: '@',
				modelYear: '@',
				locale: '@'
			},
			controllerAs: '$guideDocuments',
			controller: bodyBuilderGuideDocumentsController
		};

		function bodyBuilderGuideDocumentsController($timeout, $element, $window, $http, $translate, gtmAnalytics) { // eslint-disable-line max-len
			'ngInject';

			let windowWidth = $(window).width();
			let $ctrl = this;
			let $html = $('html');
			let mobileBreakpoint = 768;

			$ctrl.activeCategory = 1;
			$ctrl.mobileOpen = false;
			$ctrl.searchResults = false;
			$ctrl.submittedString = false;

			$ctrl.toggleCategory = (category) => {
				$ctrl.activeCategory = category;
				$ctrl.resetSearch();

				if($(window).width() < mobileBreakpoint) {
                    $ctrl.openMobileOverlay();
				}
			}

			$ctrl.openMobileOverlay = () => {
                let lastScroll = window.scrollY || window.pageYOffset;
                let heightValue = 'calc(100% + ' + lastScroll + 'px)';

                $html.addClass('main-nav-active');
                document.body.style.position = 'static';
                document.body.style.top = `-${lastScroll}px`;
                $ctrl.mobileOpen = true;
			}

			$ctrl.backToCategories = () => {
				$ctrl.closeMobileOverlay();
				$ctrl.resetSearch();
			}

			$ctrl.closeMobileOverlay = () => {
                let scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
                $html.removeClass('main-nav-active');
                $ctrl.mobileOpen = false;
			}

			$ctrl.resetSearch = () => {
				$ctrl.searchString = null;
				$ctrl.searchResults = false;
				$ctrl.submittedString = false;
			}

			$ctrl.searchDocuments = ($event) => {
				$event.preventDefault();

				if ($ctrl.searchString !== undefined && $ctrl.searchString !== '') {
					let ajaxUrl = (
						$ctrl.baseUrl +
						'/data/bodybuilder/guide/search/' +
						$ctrl.searchString + '/' +
						$ctrl.modelCode + '/' +
						$ctrl.modelYear + '/' +
						$ctrl.locale
					);

					$http.get(ajaxUrl).then(response => {
						let results = response.data;

						$ctrl.submittedString = $ctrl.searchString;

						if(results) {
							results.forEach((result, key) => {
								result.fileList = [];

								if(result.enUS) {
									let enUsFile = {
										fileLabel: $translate.instant('body-builder.documents.original-us'),
										fileType: 'en_us',
										filePath: result.filePathEnUS,
										fileExtension: result.fileExtensionEnUS
									};

									result.fileList.push(enUsFile);
								}

								if(result.enCA) {
                                    let enCAFile = {
										fileLabel: $translate.instant('body-builder.documents.canadian-en'),
										fileType: 'en_ca',
										filePath: result.filePathEnCA,
										fileExtension: result.fileExtensionEnCA
									};

                                    result.fileList.push(enCAFile);
								}

								if(result.frCA) {
                                    let frCAFile = {
										fileLabel: $translate.instant('body-builder.documents.canadian-fr'),
										fileType: 'fr_ca',
										filePath: result.filePathFrCA,
										fileExtension: result.fileExtensionFrCA
									};

                                    result.fileList.push(frCAFile);
								}
							});
						}

						$ctrl.searchResults = results;

						if($(window).width() < mobileBreakpoint) {
                            $ctrl.openMobileOverlay();
						}
					}, error => {
						console.error(error);
					});
				} else {
					$ctrl.resetSearch();
				}
			};

			// Close mobile overlay when window width is greater or equal to mobileBreakpoint (mobile portrait to landscape)
			angular.element($window).on('resize', () => {
				// Check window width has actually changed and it's not just iOS triggering a resize event on scroll
				if($(window).width() != windowWidth) {
					windowWidth = $(window).width();

					if(windowWidth >= mobileBreakpoint) {
						$ctrl.closeMobileOverlay();
					}
				}
			});

			const input = document.querySelector('.C_BB-guide-results-header-search>form>input');
			const inputButton = document.querySelector('.C_BB-guide-results-header-search>form>button');

			$timeout(() => {
				const nameplateName = document.querySelector('.C_BB-guide-selector>div.slick-list>.slick-track>.is-active>a>.C_BB-guide-selector-cell-title');
				inputButton.addEventListener('click', ()=>{
					if(input.value !== "") {
						addTracking(input.value, `rbb-guide-search-${nameplateName.innerText}`);
					}
				});
			},300);


			/**
			 * @param elementValueToTrack the value of the tracked element (innerText, value, etc)
			 * @param trackingID the analytics-id provided by the analytics team
			 */
			function addTracking(elementValueToTrack, trackingID) {
				gtmAnalytics.trackEvent('event', {
					category:  `${window.FCA_SITES_CONFIG.pageCode} bodybuilder`,
					label: `${trackingID}-${elementValueToTrack}`
				});
			}
		}
	}
})();
