(function() {
	angular
		.module('fca.layout.regionalOffers')
		.directive('fcaOffersCta', fcaOffersCta);

	function fcaOffersCta() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				currentRegion: '@'
			},
			controllerAs: 'fcaOffersCtaOverlay',
			controller: FcaOffersCtaController
		};

		function FcaOffersCtaController($rootScope, userLocation) {
			'ngInject';

			this.isVisible = false;

			this.$onInit = () => {
				this.previousLocation = userLocation.getLocationRegion();

				this.setRegion(userLocation.getLocationRegion());
				this.checkRegionMatch();

				$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
					this.setRegion(region);
					this.checkRegionMatch();
				});
			};

			this.setRegion = (newRegion) => {
				this.region = newRegion;
				let $changedLocation = this.previousLocation;

				// TODO: REFACTOR FOR MULTIPLE CALL
				let provinceMapFr = {
					pacific: 'pacifique',
					alberta: 'alberta',
					prairies: 'prairies',
					ontario: 'ontario',
					quebec: 'quebec',
					atlantic: 'atlantique'
				};
				let regionForLinks = newRegion;
				if (window.FCA_SITES_CONFIG.preferredLanguage === 'fr_CA') {
					regionForLinks = provinceMapFr[newRegion];
				}

				angular.element('a.js-offers-location').each(function() {
					/* eslint-disable no-invalid-this */
					let $this = $(this);
					let $href = $this.attr('href');

					if($href.indexOf(regionForLinks) === -1 && $href.indexOf($changedLocation) === -1) {
						$href = $href.replace('/offers', '/offers/' + regionForLinks);
						$href = $href.replace('/offres', '/offres/' + regionForLinks);
						$this.attr('href', $href);
					} else if ($href.indexOf($changedLocation !== regionForLinks)) {
						$href = $href.replace('/offers/' + $changedLocation, '/offers/' + regionForLinks);
						$href = $href.replace('/offres/' + $changedLocation, '/offres/' + regionForLinks);
						$this.attr('href', $href);
					}
				});

				this.previousLocation = regionForLinks;
			};

			this.checkRegionMatch = () => {
				if (this.currentRegion == this.region) {
					this.isVisible = true;
				} else {
					this.isVisible = false;
				}
			};
		}
	}
})();
