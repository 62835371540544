(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaCustomizerSelector', fcaCustomizerSelector);

	function fcaCustomizerSelector() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'customizerSeclector',
			controller: fcaCustomizerSelectorController,
			link: fcaSlickCarouselCustomizerLink
		};

		function fcaCustomizerSelectorController($scope) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			$scope.index = 0;

			this.onSwatchKeyPressed = ($event, currentIndex) => {
				if ($event && $event.originalEvent && $event.originalEvent.keyCode == 13) {
					$scope.index = currentIndex;
				}
			};

			this.onSwatchClicked = ($event, trimIndex) => {
				$scope.index = trimIndex;
				$event.preventDefault();
			}
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function fcaSlickCarouselCustomizerLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
