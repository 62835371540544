/* eslint-disable indent */
(function() {
    angular
        .module('fca.modal')
        .directive('fcaModalContent', fcaModalContent);

    function fcaModalContent() {
        return {
            restrict: 'E',
            scope: true,
            transclude: true,
            require: '^fcaModalWindow',
            templateUrl: '/modal-window/fca-modal-content.html',
            controllerAs: '$fcaModalContentCtrl',
            controller: FcaModalContentController
        };
        function FcaModalContentController($scope) {
            'ngInject';
            let $ctrl = this;
            $ctrl.modalId = $scope.$parent.modalWindow.modalId;
        }
    }
})();
