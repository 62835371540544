(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaVideoReel', fcaAlfaVideoReel);

	function fcaAlfaVideoReel() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'alfaVideoReel',
			controller: fcaAlfaVideoReelController
		};


		function fcaAlfaVideoReelController($scope, $element, $document, $window, gtmAnalytics, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;

			let $el = $element[0];
			$ctrl.videoIsVisible = false;
			$ctrl.isVideoPlaying = true;
			$ctrl.isTranscriptShown = false;
			$ctrl.blockVideo = null

			this.onVideoClick = (videoId) => {
				$ctrl.isVideoPlaying = !$ctrl.isVideoPlaying

				if($ctrl.isVideoPlaying) {
					$ctrl.blockVideo.play();
				} else {
					$ctrl.blockVideo.pause();
				}
			}
	
			this.onTranscriptClick = () => {
				$ctrl.isTranscriptShown = !$ctrl.isTranscriptShown;
			}

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					functionExecutionControl.debounce($ctrl.checkIfVisible(), 200);
				});
			};

			this.checkIfVisible = () => {
				let elScrollTop = $el.getBoundingClientRect().top;
				let elScrollBottom = $el.getBoundingClientRect().bottom;
				let windowHeight = $window.innerHeight;

				if(elScrollTop <= windowHeight && elScrollBottom >= 0 && !$ctrl.videoIsVisible) {
					if($ctrl.blockVideo == null) {
						$ctrl.blockVideo = $element.find('video')[0];
					}
					let videoSource = $element.attr('data-video-url');

					$ctrl.blockVideo.src = videoSource;
					$ctrl.blockVideo.load();
					$ctrl.blockVideo.play();

					$ctrl.isVideoPlaying = true
					$ctrl.videoIsVisible = true;
				}
			};

			this.$onInit = () => {
				this.bindScrolling();
				this.checkIfVisible();
			};
		}
	}
})();
