(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name fca.dialog.theme.alfaromeowhite
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dialog.theme.alfaromeowhite')
		.provider('fcaDialogThemeAlfaromeowhite', ThemeProvider);

	function ThemeProvider() {
		'ngInject';

		/* Dialog configurations */
		let cfg = this.cfg = {
			template: '/dialogs/themes/transparency/alfaromeowhite.html',
			appendClassName: 'fca-dialog-overlay fca-dialog-alfaromeowhite-overlay',
			closeByDocument: false
		};

		/* Theme name */
		this.name = 'alfaromeowhite';

		/**
		 * @description Public service
		 * @return {Object} Provider instance
		 */
		this.$get = () => {
			return cfg;
		};
	}
})();
