(function() {
	angular
        .module('fcaModelSpecs')
        .directive('fcaModelSpecsHighlights', fcaModelSpecsHighlights);

	function fcaModelSpecsHighlights() {
		return {
			restrict: 'A',
            scope: true,
            bindToController: true,
			controllerAs: '$specsHighlights',
			controller: FcaModelSpecsHighlights
		};

		function FcaModelSpecsHighlights($rootScope, $timeout) {
            'ngInject';
            
			/**
			 * @description Determines if we show only Highlights or no
			 * @type Boolean
			 */
            this.showOnlyHighlights = true;

            this.toggleOnlyHighlights = () => {
				$timeout(() => {
					$rootScope.$broadcast('modelSpecsGrid.toggleTriggers', this.showOnlyHighlights);
				});
            };
		}
	}
})();
