(function () {
	angular
		.module('alfaromeo.buildAndPrice.pricing-summary')
		.component('alfaPricingSummary', {
			controller: alfaPricingSummary,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/pricing-summary/alfaromeo-pricing-summary.html',
			bindings: {
				vehicleName: '@',
				trimGroup: '@',
				paymentsAndRates: '<',
				netAmount: '<',
				disclaimers: '<',
				contactDealerUrl: '@',
				paymentType: '<',
				programLogoPath: '@',
				programDescription: '@',
				programLogoAltText: '@',
				programShowShopOnlineLink: '@',
				programShowBanner: '@',
				programBaseSniBuildUrl: '@',
				customOrderActivated: '@',
				hasCustomOrderSNI: '@',
				hasCustomOrderParam: '@'
			}
		});

	function alfaPricingSummary($scope, alfaPricing, $rootScope) {
		'ngInject';

		const $ctrl = this;

		const cash = 'cash';
		const finance = 'finance';
		const lease = 'lease';

		$ctrl.currentPaymentType = cash;

		$scope.$on('navigation: payment-type-changed', (_, data) => {
			if ($ctrl.currentPaymentType === data.type) return;
			alfaPricing.changeActiveTab(
				data.type,
				$ctrl.changeToCashMode.bind(this),
				$ctrl.changeToFinanceMode.bind(this),
				$ctrl.changeToLeaseMode.bind(this));
		});

		$ctrl.$onInit = () => {
			alfaPricing.changeActiveTab(
				$ctrl.paymentType,
				$ctrl.changeToCashMode.bind(this),
				$ctrl.changeToFinanceMode.bind(this),
				$ctrl.changeToLeaseMode.bind(this));
				$rootScope.$broadcast("Alfa-Pricing-Summary-Loaded");
		}

		$ctrl.changeToCashMode = () => {
			$ctrl.currentPaymentType = cash;
			alfaPricing.onModeChanged($ctrl.currentPaymentType);
		}

		$ctrl.changeToFinanceMode = () => {
			$ctrl.currentPaymentType = finance;
			alfaPricing.onModeChanged($ctrl.currentPaymentType);
		}

		$ctrl.changeToLeaseMode = () => {
			$ctrl.currentPaymentType = lease;
			alfaPricing.onModeChanged($ctrl.currentPaymentType);
		}

		$ctrl.isInCashMode = () => {
			return $ctrl.currentPaymentType === cash;
		}

		$ctrl.hasLease = () => {
			return alfaPricing.hasLease($ctrl.paymentsAndRates);
		}

		$ctrl.getCurrentPaymentLabel = () => {
			return alfaPricing.getCurrentPaymentLabel($ctrl.currentPaymentType);
		}

		$ctrl.getCurrentPayment = () => {
			return alfaPricing.getCurrentPayment($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getCurrentPaymentOccurence = () => {
			return alfaPricing.getCurrentPaymentOccurence($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getCurrentEffectiveRate = () => {
			return alfaPricing.getCurrentEffectiveRate($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getCurrentApplicableRate = () => {
			return alfaPricing.getCurrentApplicableRate($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getNumberPayments = () => {
			return alfaPricing.getNumberPayments($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getCurrentDiscounts = () => {
			return alfaPricing.getCurrentDiscounts($ctrl.paymentsAndRates, $ctrl.currentPaymentType);
		}

		$ctrl.getCurrentDownPayment = () => {
			if (!$ctrl.paymentsAndRates) return;
			return $ctrl.paymentsAndRates.downPayment;
		}

		$ctrl.getCurrentModeDisclaimer = () => {
			if (!$ctrl.disclaimers) return '';
			switch ($ctrl.currentPaymentType) {
				case cash:
					return $ctrl.disclaimers.netAmount;
				case finance:
					return $ctrl.disclaimers.financing;
				case lease:
					return $ctrl.disclaimers.leasing;
				default:
					return '';
			}
		}
	}
})();
