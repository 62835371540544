(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamModelTray', FcaOnlyRamModelTray);
	
	function FcaOnlyRamModelTray() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsPrefix: '@?'
			},
			controllerAs: '$fcaOnlyRamModelTrayCtrl',
			controller: FcaOnlyRamModelTrayCtrl
		};
		
		function FcaOnlyRamModelTrayCtrl($element, gtmAnalytics) {

			const $ctrl = this;
			const $slickSlider = $element.find('slick');
			let analyticsPrefix;
			let slideCount;

			$slickSlider.on('init', function(event, slick) {
				var $items = slick.$dots.find('li');
				let slide = slick.currentSlide;
				slideCount = slick.slideCount;

				$items.on('click',function () {
					let currentSlide = $slickSlider.slick('slickCurrentSlide');
					let dotIndex = $(slick.$slides[currentSlide]).index() + 1;
					$ctrl.sendGtmTrackEvent(analyticsPrefix + 'trimtray--dots-', 'dot-' + dotIndex);
				});

				// manage arrow in slick filter prev
				$element.find('.slick-prev').on('click',function () {
					let currentSlide = $slickSlider.slick('slickCurrentSlide');
					let slideIndex = $(slick.$slides[currentSlide]).index() + 1;
					let currentElementTitle = $element.find('.model-tray-item .model-tray-item-title')[currentSlide].innerText.toLowerCase();

					$ctrl.sendGtmTrackEvent('trimtray-backward', currentElementTitle);
				});

				// manage arrow in slick filter next
				$element.find('.slick-next').on('click',function () {
					let currentSlide = $slickSlider.slick('slickCurrentSlide');
					let slideIndex = $(slick.$slides[currentSlide]).index() + 1;
					let currentElementTitle = $element.find('.model-tray-item .model-tray-item-title')[slideIndex + 1].innerText.toLowerCase();

					$ctrl.sendGtmTrackEvent('trimtray-forward', currentElementTitle);
				});

				$ctrl.updateAnalytics(slide);
			});

			$slickSlider.on('afterChange', function(event, slick, currentSlide) {
				let slide = slick.currentSlide;
				$ctrl.updateAnalytics(slide);
			});

			$slickSlider.on('swipe', function(event, slick, direction) {
				//Add analytics on the slick swipe
				let slideNumber = slick.currentSlide;
				
				if(slideNumber === 0) {
					previousSlideNumber = slideCount;
				} else {
					previousSlideNumber = slideNumber;
				}

				if(slideNumber === (slideCount - 1)) {
					nextSlideNumber = 1;
				} else {
					nextSlideNumber = slideNumber + 2;
				}
				
				if(direction === "left") {
					$ctrl.sendGtmTrackEvent(analyticsPrefix + '-slide-next', 'slide-' + nextSlideNumber);
				} else {
					$ctrl.sendGtmTrackEvent(analyticsPrefix + '-slide-prev', 'slide-' + previousSlideNumber);
				}
			});

			$ctrl.updateAnalytics = (slideNumber) => {
				let previousSlideNumber;
				let nextSlideNumber;

				if(slideNumber === 0) {
					previousSlideNumber = slideCount;
				} else {
					previousSlideNumber = slideNumber;
				}

				if(slideNumber === (slideCount - 1)) {
					nextSlideNumber = 1;
				} else {
					nextSlideNumber = slideNumber + 2;
				}

				$element.find('.slick-prev').attr('data-analyticsvalue', 'slide-' + previousSlideNumber);
				$element.find('.slick-next').attr('data-analyticsvalue', 'slide-' + nextSlideNumber);
			};

			$ctrl.sendGtmTrackEvent = (id, value) => {
				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label: `${id}-${value}`
				});
			};

			$ctrl.$onInit = () => {
				analyticsPrefix = $ctrl.analyticsPrefix || 'model-tray';
			}
		}
	}
})();