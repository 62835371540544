(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.autocomplete
	 * @description [TODO]
	 * @example
	 * <pre></pre>
	 */
	angular
		.module('fca.autocomplete', [
			'angucomplete-alt'
		]);
})();
