/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.dealersPageList')
        .component('dealersPageList', {
            controller: DealerPageListController,
            controllerAs: '$pageList',
            template: '<ng-include src="getTemplateUrl()" style="width:100%"/>',
            bindings: {
                dealersList: '<',
                noResults: '<',
                itemSelected: '<',
                isPaginated: '<',
                eventPrefix: '@?',
                location: '<',
                showDealerAppointmentSection: '<',
                customPerPage: '<',
                eshopUrl: "@",
                customOrderActivated: "@",
            }
        });

    /**
     * @ngdoc controller
     * @name fca.dealersPageList.controller:DealerPageListController
     * @description Component delearsPageList controller
     * @requires $scope
     */
    function DealerPageListController($scope, $http, fcaUserIdPersistentService) {
        'ngInject';

        /**
         * @ngdoc property
         * @name page
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Number}
         * @description Current active page (by default 1)
         */
        this.page = 1;

        /**
         * @ngdoc property
         * @name hasNextPage
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Boolean}
         * @description True if at the current page the list has a next page
         */
        this.hasNextPage = null;

        /**
         * @ngdoc property
         * @name totalPage
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Number}
         * @description Total page number with the current list of dealers
         */
        this.totalPage = 1;

        /**
         * @ngdoc property
         * @name perPage
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Number}
         * @description Number of item per page (10)
         */
        this.perPage = 10;

        /**
         * @ngdoc property
         * @name isPaginated
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Boolean}
         * @description True if the list is paginated (could be change by the parent component)
         */
        this.isPaginated = false;

        /**
         * @ngdoc property
         * @name noResults
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Boolean}
         * @description True if there's not results in dealers list
         */
        this.noResults = false;

        /**
         * @ngdoc property
         * @name listItems
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Array}
         * @description List of dealers for current page
         */
        this.listItems = [];

        /**
         * @ngdoc property
         * @name itemSelected
         * @propertyOf fca.dealersPageList.controller:DealerPageListController
         * @type {Object}
         * @description Selected item in list
         */
        this.itemSelected = null;

        this.isPostLoading = false;

        $scope.getTemplateUrl = function() {
            let path = window.location.pathname;
            if (path.indexOf('build-and-price/find-a-dealer') > 0) {
                return '/dealer-locator/dealers-page-list/custom-order-list.html';
            } else {
                return '/dealer-locator/dealers-page-list/list.html';
            }
        };

        /**
         * @ngdoc method
         * @name $onChanges
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Object} o Binding changed object
         * @description Set noResults property and paginate the list when the dealers list change
         */
        this.$onChanges = (o) => {
            let {dealersList, noResults, isPaginated} = o;
            let page = (this.isPaginated) ? 1 : null;

            if (dealersList !== undefined || isPaginated !== undefined) {
                this.paginate(page);
            }

            if (noResults !== undefined) {
                this.noResults = noResults.currentValue;
            }
        };

        this.getBuildAndPriceCustomOrderUrl = (dealerCode) =>{

			let url = '/'+window.FCA_SITES_CONFIG.language+'/custom-order';
            let urlParams = new URLSearchParams(window.location.search);

            url = url + '/'
                + urlParams.get('brandCode')+ '/'
                + urlParams.get('namePlate') + '/'
                + urlParams.get('modelYear') + '/'
                + urlParams.get('modelYearId') + '/'
                + dealerCode;

            url = url + '#step=models';

            return encodeURI(url);
        };

        /**
         * @ngdoc method
         * @name paginate
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Number} page Current page
         * @description Set le current page recordset of dealers
         */
        this.paginate = (page) => {
            if (this.isPaginated && !this.noResults) {
                if (ng.isNumber(page)) {
                    this.page = page;
                }

                let currentPerPage = this.customPerPage ? this.customPerPage : this.perPage;

                let length = this.dealersList.length;
                // Set total page
                this.totalPage = Math.ceil(length / currentPerPage);
                // Check if list is on the last page
                if (this.page <= this.totalPage) {
                    let last = (currentPerPage * this.page) - 1;
                    let start = 0;
                    let list = this.dealersList.slice(start, last);
                    let page = this.page++;
                    let hasNextPage = false;
                    if (page < this.totalPage) {
                        hasNextPage = true;
                    }

                    this.listItems = list;
                    this.page = page;
                    this.hasNextPage = hasNextPage;
                }
            } else {
                this.listItems = this.dealersList;
            }
        };

        /**
         * @ngdoc method
         * @name next
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Object} $evt DOM event object
         * @description Load the next page in list
         */
        this.next = ($evt) => {
            if ($evt) {
                $evt.preventDefault();
            }

            this.paginate(this.page + 1);
        };

        /**
         * @ngdoc method
         * @name itemLeave
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Object} $evt DOM event object
         * @description $emit event when the mouse leave a list item
         */
        this.itemLeave = ($evt) => {
            if (this.eventPrefix !== undefined) {
                let event = this.eventPrefix + '.leave';
                $scope.$emit(event, [null]);
            }
        };

        /**
         * @ngdoc method
         * @name itemHover
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Object} $evt DOM event object
         * @param  {String} code Hovered item list dealer code
         * @description $emit event when the mouse enter in a list item
         */
        this.itemHover = ($evt, dealerId) => {
            if (this.eventPrefix !== undefined) {
                let event = this.eventPrefix + '.hover';
                $scope.$emit(event, [dealerId]);
            }
        };

        /**
         * @ngdoc method
         * @name itemClick
         * @methodOf fca.dealersPageList.controller:DealerPageListController
         * @param  {Object} $evt DOM event object
         * @param  {Object} clocked item list dealer
         * @description $emit event when the mouse enter in a list item
         */
        this.itemClicked = ($evt, dealer) => {
            if (this.eventPrefix !== undefined) {
                let eventClicked = this.eventPrefix + '.clicked';

                $scope.$emit(eventClicked, dealer);
            }
        };

        this.itemKeyPressed = ($evt, dealer) => {
            if ($evt && $evt.originalEvent.key == "Enter") {
                this.itemClicked($evt, dealer);
            }
        };

        this.redirectToEshop = (dealerCode) => {
            const urlParams = new URLSearchParams(window.location.search);
            const buildId = urlParams.get('buildId');

            const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;
            const pid = persistentUid && `&pid=${persistentUid}`;

            const apiUrl = `/data/custom-order/${FCA_SITES_CONFIG.language}/${buildId}/dealer?dealerCode=${dealerCode}${pid}`;

            this.isPostLoading = true;

            $http.post(apiUrl).then(response => {
                const redirectToEshop = `${this.eshopUrl}?buildid=${buildId}${pid}`
                window.location.href = redirectToEshop;
            }).catch(function (e) {
                this.isPostLoading = false;
                throw new Error('error in saving custom order', e);
            });
        };
    }
})(angular);
