(function () {
    angular
        .module('fca.uconnect')
        .component('fcaHotspotButton', {
            controller: fcaHotspotButton,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/hotspot-button/hotspot-button.html',
            bindings: {
                hotspotsObject: '<',
                updateFeatureCodeSelected: '<',
                featureOverlayIsVisible: '=',
                analyticsId: '@'
            }
        });

    function fcaHotspotButton() {
        'ngInject';

        const $ctrl = this;

        //Show or Hide feature-overlay component
        $ctrl.showFeatureOverlay = featureCode => {
            $ctrl.featureOverlayIsVisible = !$ctrl.featureOverlayIsVisible;
            $ctrl.updateFeatureCodeSelected(featureCode);
        };

    }
})();
