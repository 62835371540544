(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021Video', vlp2021VideoDirective);

	function vlp2021VideoDirective() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				singleVideo: '<'
			},
			controllerAs: 'vlpVideo',
			controller: vlp2021VideoController,
		};

		function vlp2021VideoController($element, $timeout) {
			'ngInject';

			const $ctrl = this;
			$ctrl.selectedVideo = 0;
			$ctrl.isMobile = false;

			$ctrl.$postLink = () => {
				if (!$ctrl.singleVideo) {
					$(window).on('load resize orientationchange', function() {
						/* Initializes a slick carousel only on mobile screens */
						if ($(window).width() > 767) {
							if ($($element.find('.VLP2021-video-player')).hasClass('slick-initialized')) {
								$($element.find('.VLP2021-video-player')).slick('unslick');
							}
							$ctrl.isMobile = false;
						} else {
							if (!$($element.find('.VLP2021-video-player')).hasClass('slick-initialized')) {
								$($element.find('.VLP2021-video-player')).slick({
									slidesToShow: 1.2,
									slidesToScroll: 1,
									mobileFirst: true,
									dots: false,
									arrows: false,
									infinite: false,
								});
							}
							$timeout(() => {
								$($element.find('.VLP2021-video-player')).slick('refresh');
							});
							$ctrl.isMobile = true;
						}
					});
				}
			};

			$ctrl.showVideo = index => {
				$ctrl.selectedVideo = index;
				$timeout(() => {
					$element.find('.VLP2021-video .video-thumb').click();
				});
			}
		}
	}
})();
