(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021TriggerPersonalization', vlp2021TriggerPersonalizationDirective);

	function vlp2021TriggerPersonalizationDirective() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'vlpTriggerPersonalization',
			controller: vlp2021TriggerPersonalizationController,
		};

		function vlp2021TriggerPersonalizationController($element, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			$ctrl.triggerPersonalization = (value) => {
				let triggerValue = value;

				// used for personalisation scenario 1.1
				gtmAnalytics.trackEvent('event', {
					category: 'models and specs',
					label: triggerValue,
					trigger: triggerValue
				});
			}
		}
	}
})();
