(function () {
    'use strict';

    angular
        .module('fca.commonDirectives')
        .directive('onImageError', onImageError);

    function onImageError () {
        return {
            controller: function ($element, $attrs) {
                $element.bind('error', onImageSourceError);
            
                function onImageSourceError() {
                    if ($attrs.src != $attrs.onImageError) {
                        $attrs.$set('src', $attrs.onImageError);
                    }
                };
            }
        };
   }
})();