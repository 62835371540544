(function () {
	angular
		.module('fca.buyOnlineDeliveryOptions')
		.directive('fcaBuyOnlineDeliveryOptions', FcaBuyOnlineDeliveryOptions);

	function FcaBuyOnlineDeliveryOptions() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineDeliveryOptionsCtrl
		};

		function FcaBuyOnlineDeliveryOptionsCtrl($scope, $rootScope, externalConfigLoader, userLocation, fcaBuyOnlineService) {
			'ngInject';
			let $ctrl = this;

			$ctrl.$onInit = () => {
				// initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
				$ctrl.language = $ctrl.config.getConfig('language');
				// check if we already have a trade-in-value
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				if (checkout.learnMoreAboutDeliveryOptions) {
					$ctrl.learnMoreAboutDeliveryOptions = checkout.learnMoreAboutDeliveryOptions;
				}
			};

			$ctrl.changeOption = () => {
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				checkout.learnMoreAboutDeliveryOptions = $ctrl.learnMoreAboutDeliveryOptions;
				fcaBuyOnlineService.setInStorage($ctrl.vin, checkout);
			};
		}
	}
})();
