(function () {
    angular
        .module('alfaromeo.buildAndPrice.jelly')
        .component('alfaJelly', {
            controller: alfaJelly,
            controllerAs: '$ctrl',
            templateUrl: '/brand-specific/alfaromeo/components/jelly/alfaromeo-jelly.html',
            bindings: {
                animationStartState: '<',
                animationZones: '<',
                animationEnabled: '<',
                imageCache: '<',
                isDesktop: '<',
                jellyData: '<',
                requestPov: '<',
                onUserPovSelect: '<',
                generateAltText: '@',
                hidePovButtons: '<'
            }
        });

    function alfaJelly($scope, povHelper, alfaAnimation) {
        'ngInject';

        const $ctrl = this;


        $ctrl.$onInit = () => {
            $ctrl.animationScale = $ctrl.animationStartState.scale;
            $ctrl.alText = $ctrl.altText;
            $ctrl.animationTranslateX = $ctrl.animationStartState.translateX;

            $ctrl.animationTranslateY = $ctrl.animationStartState.translateY;

            $ctrl.pov = $ctrl.animationStartState.pov;

            $ctrl.moveToPov($ctrl.pov, { cancelAnimations: false });
            if (!$ctrl.hidePovButtons) {
                angular.element(document).bind(
                    'keydown',
                    event => $scope.$apply($ctrl.onKeyDown(event))
                );
            }

        };

        $ctrl.$onChanges = changes => {
            if (!changes.imageCache || !changes.imageCache.currentValue) {
                return;
            }

            const imageCache = changes.imageCache.currentValue;

			$ctrl.pov = $ctrl.animationStartState.pov;

			console.log($ctrl.pov);

            if ($ctrl.pov in imageCache) {
                $ctrl.jellyUrl = imageCache[$ctrl.pov].url;
            } else {
                $ctrl.requestPov($ctrl.pov);
            }
        }

        $ctrl.onKeyDown = keyEvent => {
            switch (keyEvent.keyCode) {
                case 39 /* arrow right */ :
                case 68 /* d */ :
                    $ctrl.decrementPov();
                    break;
                case 37 /* arrow left */ :
                case 65 /* a */ :
                    $ctrl.incrementPov();
                    break;
            }
        };

        let lastScrollY = -1;
        $ctrl.$postLink = () => {
            if (!$ctrl.animationZones) {
                return;
            }

            window.addEventListener(
                'scroll', () => $scope.$apply(() => {
                    $ctrl.jellyAnimationListener();
                    lastScrollY = pageYOffset;
                })
            );
        }

        $ctrl.animationCanceled = false;
        let currentAnimationZone;
        $ctrl.jellyAnimationListener = () => {
            if (!$ctrl.animationEnabled) return;
            // Look for the active animation zone
            const animationZone = alfaAnimation.findActiveAnimationZone($ctrl.animationZones, lastScrollY);

            // If we are not in an animation zone...
            if (!animationZone) {
                // ... and we previously were, finish the animation.
                if (currentAnimationZone) {
                    finishAnimation();
                }

                $ctrl.animationCanceled = false;
                // No other work to do -> return early!
                return;
            }

            if ($ctrl.animationCanceled) return;

            if (!currentAnimationZone) {
                currentAnimationZone = animationZone;
                let animationForwards = pageYOffset > lastScrollY;

                currentAnimationZone.setStartState(animationForwards, {
                    scale: $ctrl.animationScale,
                    translateX: $ctrl.animationTranslateX,
                    translateY: $ctrl.animationTranslateY,
                    pov: $ctrl.pov,
                });

                if (!currentAnimationZone.povPath.every(
                    pov => pov in $ctrl.imageCache
                )) {
                    $ctrl.cancelInFlightAnimations();
                    return;
                }
            }

            $ctrl.animateJelly();
        }

        const finishAnimation = () => {
            if (!$ctrl.animationCanceled) {
                $ctrl.animateJelly();
            }

            currentAnimationZone = null;
        }

        $ctrl.animateJelly = () => {
            $ctrl.animationScale = currentAnimationZone
                .interpolateProperty('scale');

            $ctrl.animationTranslateX = currentAnimationZone
                .interpolateProperty('translateX');

            if ($ctrl.isDesktop) {
                $ctrl.animationTranslateY = currentAnimationZone
                    .interpolateProperty('translateY');
            }

            const newPov = currentAnimationZone.interpolatePov();

            if (newPov !== $ctrl.pov) {
                $ctrl.moveToPov(newPov, { cancelAnimations: false});
            }
        }

        $ctrl.moveToPov = (
            pov, { cancelAnimations = true, userInitiated = false }
        ) => {
            if (cancelAnimations) {
                $ctrl.cancelInFlightAnimations();
            }

            pov = povHelper.wrap(pov);
            $ctrl.pov = pov;

            if (pov in $ctrl.imageCache) {
                $ctrl.jellyUrl = $ctrl.imageCache[pov].url;
            } else {
                $ctrl.requestPov(pov);
            }

            if (userInitiated && $ctrl.onUserPovSelect) {
                $ctrl.onUserPovSelect();
            }
        }

        $ctrl.cancelInFlightAnimations = () => {
            if (currentAnimationZone) {
                $ctrl.animationCanceled = true;
            }

            $ctrl.animationScale = 1;
            $ctrl.animationTranslateX = 0;
            $ctrl.animationTranslateY = 0;
        }

        $ctrl.incrementPov = () =>
            $ctrl.moveToPov($ctrl.pov + 1, { userInitiated: true });

        $ctrl.decrementPov = () =>
            $ctrl.moveToPov($ctrl.pov - 1, { userInitiated: true });

        $ctrl.jellyIsLoaded = () => $ctrl.pov in $ctrl.imageCache;
    }
})()
