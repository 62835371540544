(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.component('regionSelector', {
			controller: RegionSelectorComponent,
			controllerAs: '$ctrl',
			bindings: {
				onChanged: '&',
				regionList: '<?',
				analyticsid: '@',
				analyticsvalue: '@'
			},
			templateUrl: '/geolocator/geolocator-region-selector/geolocator-region.selector.html',
		});

	function RegionSelectorComponent(
		$scope,
		$element,
		$timeout,
		$rootScope,
		urlRegionMapping,
		regionMappingService,
    defaultProvinceService,
		$window) {
		'ngInject';

		const REGION = urlRegionMapping.getRegionByUrl($window.location.href);
		const OPEN_CLASSNAME = 'is-opened';

		// List classname open status
		this.listOpened = '';
		// List of regions
		this.regionsList = regionMappingService.getRegions();

		this.$onInit = () => {
			this.bindClickOutSide();
			for (let i = 0, l = this.regionsList.length; i < l; i++) {
				let region = this.regionsList[i];
				region.currentRegion = false;
				if (region.slug === REGION) {
					region.currentRegion = true;
				}

				this.regionsList[i] = {
					region: region.slug,
					label: region.region,
					currentRegion: region.currentRegion
				};
			}
		};

		this.toggleDropdown = ($evt = null, $apply = false) => {
			if ($evt && ng.isObject($evt)) {
				$evt.preventDefault();
			}

			if (this.listOpened) {
				this.listOpened = '';
			} else {
				this.listOpened = OPEN_CLASSNAME;
			}

			if ($apply) {
				$scope.$apply();
			}
		};

		this.setRegion = (region) => {
			if (this.onChanged) {
        let regionName = region.region;
        defaultProvinceService.updateBasedOnRegion(regionName);
				this.onChanged({
					data: region
				});
			}
		};

		this.bindClickOutSide = () => {
			ng.element('body').on('click', ($evt) => {
				let selector = $element[0].tagName.toLowerCase();
				let parent = ng.element($evt.target).parents(selector);
				if (parent.length === 0 && this.listOpened.length) {
					this.toggleDropdown(null, true);
				}
			});
		};
	}
})(angular);
