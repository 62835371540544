(function() {
	angular
		.module('fca.preferredDealer')
		.directive('fcaPreferredDealer', PreferredDealer)
		.controller('fcaPreferredDealerController', PreferredDealerController);

	function PreferredDealer() {
		return {
			restrict: 'A',
			controller: PreferredDealerController,
			controllerAs: 'preferredDealerCtrl',
			scope: true,
			bindToController: {
				code: '@',
				analyticsCategory: '@',
				analyticsId: '@',
				analyticsValue: '@'
			}
		};
	}

	function PreferredDealerController($rootScope, preferredDealerProvider, gtmAnalytics) {
		'ngInject';

		this.isChecked = false;

		this.$onInit = () => {
			this.currentPreferredDealer = preferredDealerProvider.getPreferredDealer();

			if (this.currentPreferredDealer !== null) {
				this.isChecked = this.currentPreferredDealer === this.code;
			}

			$rootScope.$on('preferredDealer', (event, newDealer) => {
				if (this.code !== newDealer) {
					this.isChecked = false;
				}
			});
		};

		this.selectDealer = () => {
			gtmAnalytics.trackEvent('event', {
				category: `${this.analyticsCategory}`,
				label: `${this.analyticsId}-${this.analyticsValue}`
			});
			if (this.isChecked) {
				preferredDealerProvider.setPreferredDealer(this.code);
			} else {
				preferredDealerProvider.resetPreferredDealer();
			}
		};


		this.showHideAlert = ($event) => {
			let popUpRef = $($event.currentTarget.nextElementSibling);
			let preferredDealerInput = $($event.currentTarget.parentElement.firstElementChild);

			if(preferredDealerInput.hasClass('ng-empty')) {
				addAnimationPopUp($event, popUpRef, preferredDealerInput, preferredDealerInput);
				removeAnimationPopUp(popUpRef, preferredDealerInput);
			} else {
				popUpRef.removeClass('is-active removing-animation');
			}
		};

		function addAnimationPopUp($event, popUpRef) {

			popUpRef.addClass('is-active');

			setTimeout((function() {
				popUpRef.addClass('removing-animation');
			}), 4000);

		}

		function removeAnimationPopUp(popUpRef) {

			setTimeout((function() {
				popUpRef.removeClass('is-active removing-animation');
			}), 4500);
		}
	}
})();
