/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.directives')
        .directive('buildAndPriceTracker', buildAndPriceTracker);

    function buildAndPriceTracker(configService, buildAndPriceStepsService) {
        return {
            restrict: 'E',
            scope: {},
            controllerAs: '$ctrl',
            bindToController: {
                vehicleNameFr: '@',
                vehicleNameEn: '@',
                scenario2DelayInMinutes: '@',
                scenario2PreDelayInMinutes: '@',
                scenario2Enabled: '@'
            },
            controller: buildAndPriceTrackerController
        };

        function buildAndPriceTrackerController($window, configService, buildAndPriceStepsService) {
            'ngInject';

            const $ctrl = this;
            const TRANSPARENT_BG = 'transparent';
            const POV = 'E05';// this is the point of view we will use for iris jellies
            const RESP = 'png';
            const WIDTH = 1600;
            const HEIGHT = 1020;
            const W = 6200;
            const H = 5000;
            const X = 2000;
            const Y = 2000;

            this.irisJellyUrl = null;
            this.legacyJellyUrl = null;

            this.$onInit = () => {
                if ($ctrl.scenario2Enabled === 'true') {
                    // register ourself to the configuration service
                    configService.registerConfigurationListener(this.configurationUpdated);

                    // we need to know when the step changes
                    buildAndPriceStepsService.registerStepListener(this.buildAndPriceStepChanged);

                    // we need to know about iris jelly updates
                    configService.registerIrisJellyListener(this.irisJellyUpdated);

                    // we need to know about legacy jelly updates
                    configService.registerLegacyJellyListener(this.legacyJellyUpdated);

                    configService.registerColorCodeListeners(this.colorCodeUpdated);

                    configService.registerTrimGroupListeners(this.trimGroupUpdated);
                }
            };

            this.configurationUpdated = (newConfiguration) => {
                // do not manipulate the object reference
                let configurationData = angular.extend({}, newConfiguration);

                // get the current data
                let scenarioData = $ctrl.getLocalStorageItem();

                // if the configuration is updated, that configuration is no longer complete (until the person reaches the summary tab)
                scenarioData.complete = false;

                // compress the scratch save data
                if (configurationData.scratchSave) {
                    configurationData.scratchSave = LZString.compressToEncodedURIComponent(configurationData.scratchSave);
                }
                scenarioData.configuration = configurationData;

                scenarioData.vehicleName = { 'en': $ctrl.vehicleNameEn, 'fr': $ctrl.vehicleNameFr};


                let nowDate = new Date();
                let expiryDate = addMinutes(nowDate, $ctrl.scenario2DelayInMinutes);
                scenarioData.expiryDate = expiryDate;

                let preDelay = addMinutes(nowDate, $ctrl.scenario2PreDelayInMinutes);
                scenarioData.preDelayDate = preDelay;

                // save in local storage
                $ctrl.saveLocalStorageItem(scenarioData);
            };

            this.irisJellyUpdated = (jellyUrl) => {
                // that gets called for all point of views
                // we don't want to spam the local storage, so make sure it's different
                if (jellyUrl
                    && jellyUrl.length > 0) {
                    // must validate the jelly url, sometimes they are broken
                    let splitJellyUrl = jellyUrl.split('?');
                    let jellyBaseUrl = splitJellyUrl[0];
                    let jellyQueryString = splitJellyUrl[1];
                    let parsedQueryString = parseQueryString(jellyQueryString);
                    // we need those for an iris jelly to work
                    if (parsedQueryString
                        && parsedQueryString.client
                        && parsedQueryString.brand
                        && parsedQueryString.vehicle
                        && parsedQueryString.sa
                        && parsedQueryString.market
                        && parsedQueryString.paint) {
                        parsedQueryString.width = WIDTH;
                        parsedQueryString.height = HEIGHT;
                        parsedQueryString.w = W;
                        parsedQueryString.h = H;
                        parsedQueryString.x = X;
                        parsedQueryString.y = Y;
                        // replace the background with a transparent background
                        parsedQueryString.bkgnd = TRANSPARENT_BG;
                        // replace the pov
                        parsedQueryString.pov = POV;
                        parsedQueryString.resp = RESP;

                        // rebuild the jelly url with the updated parameters
                        let rebuiltJellyUrl = jellyBaseUrl + '?' + toQueryString(parsedQueryString);

                        // no need to update if that's already what we have
                        if (this.irisJellyUrl !== rebuiltJellyUrl) {
                            let scenarioData = $ctrl.getLocalStorageItem();

                            scenarioData.irisJellyUrl = rebuiltJellyUrl;

                            // remove any left-over legacy jelly url
                            scenarioData.legacyJellyUrl = null;

                            // save in local storage
                            $ctrl.saveLocalStorageItem(scenarioData);

                            // remember what the last jelly url was
                            this.irisJellyUrl = rebuiltJellyUrl;
                        }
                    }
                }
            };

            this.legacyJellyUpdated = (jellyUrl) => {
                // that gets called for all point of views
                // we don't want to spam the local storage, so make sure it's different
                if (this.legacyJellyUrl !== jellyUrl) {
                    let scenarioData = $ctrl.getLocalStorageItem();
                    scenarioData.legacyJellyUrl = jellyUrl;

                    // remove any left-over legacy jelly url
                    scenarioData.irisJellyUrl = null;

                    // save in local storage
                    $ctrl.saveLocalStorageItem(scenarioData);

                    // remember what the last jelly url was
                    this.legacyJellyUrl = jellyUrl;
                }
            };

            this.colorCodeUpdated = (colorCode) => {
                if (this.colorCode !== colorCode) {
                    let scenarioData = $ctrl.getLocalStorageItem();

                    let trimmedColorCode = colorCode.substring(0, 3);
                    scenarioData.colorCode = trimmedColorCode;

                    // save in local storage
                    $ctrl.saveLocalStorageItem(scenarioData);

                    // remember what the last colorCode
                    this.colorCode = colorCode;
                }
            };

            this.trimGroupUpdated = (trimGroup) => {
                if (this.trimGroup !== trimGroup) {
                    let scenarioData = $ctrl.getLocalStorageItem();

                    scenarioData.trimGroup = trimGroup;

                    // save in local storage
                    $ctrl.saveLocalStorageItem(scenarioData);

                    // remember what the last colorCode
                    this.trimGroup = trimGroup;
                }
            };

            this.buildAndPriceStepChanged = (stepChanged) => {
                // we only care when the step is the summary
                if (stepChanged && stepChanged.step === 'summary') {
                    // if we have an unfinished configuration remove it and put it under complete
                    let scenarioData = $ctrl.getLocalStorageItem();
                    if (scenarioData.configuration) {
                        scenarioData.complete = true;
                        $ctrl.saveLocalStorageItem(scenarioData);
                    }
                }
            };

            this.getLocalStorageItem = () => {
                let result = {};
                let storageItem = $window.localStorage.getItem(this.getLocalStorageKey());
                if (storageItem) {
                    result = JSON.parse(storageItem);
                }
                return result;
            };

            this.saveLocalStorageItem = (data) => {
                $window.localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(data));
            };

            this.getLocalStorageKey = () => {
                return 'fcaPersonalizationScenario_2';
            }

            function addMinutes(dt, minutes) {
                return new Date(dt.getTime() + minutes*60000);
            }

        };
    };

})();