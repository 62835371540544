(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaEngineBlock', fcaEngineBlock);

	function fcaEngineBlock() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'engineBlock',
			controller: fcaEngineBlockController,
			link: fcaSlickCarouselEngineBlockLink
		};

		function fcaEngineBlockController($scope) {
			'ngInject';
			/* eslint-disable no-invalid-this */
			const $ctrl = this;

			$scope.index = 0;

			$ctrl.currentSlide = 0;
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function fcaSlickCarouselEngineBlockLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
