(function(ng) {
	'use strict';

	/**
	 * @ngdoc directive
	 * @name inputRangeDecorator
	 * @description Directive to customize input range form element
	 */
	angular
		.module('fca.daaForm.inputRange')
		.directive('inputRangeDecorator', Decorator);

	function Decorator($log, $window, $timeout) {
		'ngInject';

		return {
			restrict: 'AC',
			scope: {
				min: '@',
				max: '@'
			},
			link: function($scope, $element, $attr) {
				// Parent DOM element wrapper (use to calculate the size of the input range)
				var $parent = $element.parent();
				// Brand colored element over gray background
				var $indicator = ng.element('> span', $parent);
				// Value in percent of the $indicator width
				var value = null;
				// Width/2 of the thumb range input (to set the right position of the $indicator)
				var unit = parseInt($attr.unit, 10) / 50;

				/* Update outside change */
				$attr.$observe('value', function(value) {
					if ($element[0].value !== value) {
						change(null, value);
					}
				});

				$attr.$observe('min', () => {
					change(null, $attr.value);
				});

				$attr.$observe('max', () => {
					change(null, $attr.value);
				});

				/* Watch the change thumb position */
				$element
					.on('input', change)
					.on('change', change);

				/* Initialize $indicator position */
				change(null, $attr.value);

				/* Change $indicator size on the $window resize event */
				angular.element($window).on('resize', (evt) => {
					change(null, $element[0].value);
				});

				function change(evt, v) {
					/* Override initial value on change */
					v = parseInt((v !== undefined) ? v : $element[0].value); // eslint-disable-line
					if (isNaN(v)) {
						v = 0;
					}

					let isInvert = false;
					if ($element.hasClass('slider-invert')) {
						isInvert = true;
					}
					
					$timeout(() => {
						/* Min/Max values */
						let min = parseInt($scope.min, 10);
						let max = parseInt($scope.max, 10) - min;
						/* $indicator position in percent */
						let percent = Math.round(((v - min) / max) * 100);
						let variantPx = unit * (50 - percent);
						let variantPercent = (variantPx / $parent.width()) * 100;

						percent += variantPercent;
						if (percent >= 100) {
							percent = 100;
						}

						if (isInvert) {
							percent = 100 - percent;
						}

						/* Change position only for new value */
						if (value !== percent) {
							value = percent;
							$indicator.css('width', percent + '%');
						}
					}, 0);
				}
			}
		};
	}
})(angular);
