/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.optionSelector')
        .component('buildAndPriceOptionSelector', {
            controller: BuildAndPriceOptionSelector,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/option-selector/option-selector.html',
            bindings: {
                optionType: '@',
                hasJelly: '<',
                centered: '<',
                jellyPov: '@',
                jellyParameters: '@',
                modelYearId: '@',
                nameplateCode: '@',
                year: '@',
                brandCode: '@',
                provinceCode: '@',
                location: '<'
            }
        });

    function BuildAndPriceOptionSelector($scope, $rootScope, configService, $element, $timeout) {
        'ngInject';

        const TONE_ECC_CODE = "0098";

        const EXTERIOR_COLOUR_DESCRIPTION = "Exterior Colours";

        const $ctrl = this;

        $ctrl.options = {};

        $ctrl.optionContent = {};

        $ctrl.groupOptionsContent = {};

        $ctrl.popularOptions = [];

        $ctrl.language = $('html').attr('lang');

        $ctrl.showAlerts = true;

        $ctrl.scratchSave = "";

        $ctrl.currentOption = false;

        $ctrl.currentTrim = {};

        $ctrl.isLoading = false;

        $ctrl.$onInit = () => {
            $ctrl.showAlerts = configService.getShowAlerts();

            $ctrl.centered = $ctrl.optionType === 'roof' || $ctrl.optionType === 'seatColor';

            $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                $ctrl.currentTrim = data;
            });

            // When the trim selector receives new options,
            // if the type matches the type of the module, update the options for that type
            $scope.$on('trim-selector:options-' + $ctrl.optionType + '-updated', (event, data) => {
                $ctrl.options = data.options;

                if (configService.popularOptions) {
                    $ctrl.popularOptions = configService.popularOptions;
                }

                $ctrl.options.popularOptions = [];

                $ctrl.options.categories.forEach(category => {
                    if (category.description !== EXTERIOR_COLOUR_DESCRIPTION) {
                        category.options.forEach(option => {
                            if (option.content) {
                                $ctrl.formatGroupContent(option);
                            }
                            if ($ctrl.popularOptions.includes(option.code) && !category.mutuallyExclusive && $ctrl.showPriceSign(option)) {
                                $ctrl.options.popularOptions.push(option);
                                option.popular = true;
                            }
                        });
                        if (category.options.every(elem => elem.hasOwnProperty('popular'))) {
                            category.hide = true;
                        }
                    }
                });
            });

            $scope.$on('jelly:requesting-trim', (event, data) => {
                if ($ctrl.optionType === 'interiorColor' && $ctrl.options.categories) {
                    for (let i = 0; i < $ctrl.options.categories.length; i++) {
                        let category = $ctrl.options.categories[i];
                        for (let c = 0; c < category.options.length; c++) {
                            let option = category.options[c];
                            if (option.selected) {
                                $rootScope.$broadcast('options-selector:fabric-updated', { fabric: option.code });
                            }
                        }
                    }
                }
            });

            $scope.$on('alert-window:select-options-proceed', (event, data) => {
                $ctrl.showAlerts = configService.getShowAlerts();
                if ($ctrl.options.categories) {
                    $ctrl.manageSelectedOptions(data.options);
                }
            });

            $scope.$on('alert-window:alert-hidden', (event, data) => {
                $ctrl.isLoading = false;
                if($ctrl.currentOption && data.focusOnOption) {
                    $ctrl.focusOnCurrentOption();
                }
            });

            $scope.$on('alert-window:focus-on-target', (event) => {
                if($ctrl.currentOption) {
                    $ctrl.focusOnCurrentOption();
                }
            });
        };

        $ctrl.focusOnCurrentOption = () => {
            if($ctrl.currentOption) {
                $timeout(() => {
                    let currentOptionElement = $element.find(`[data-option-code="${$ctrl.currentOption}"]`);

                    if(currentOptionElement.length > 0) {
                        currentOptionElement[0].focus();
                    }

                    $ctrl.currentOption = false;
                });
            }
        };

        $ctrl.selectOption = (option) => {
            $ctrl.currentOption = option.code;

            const category = $ctrl.options.categories.find(category => {
                return category.options.some(o => {
                    return o.code === option.code;
                });
            })
            if (!category || ((category.mutuallyExclusive && category.options.length > 1) && $ctrl.checkIfOptionShouldBeSelected(option))) {
                return;
            }
            $ctrl.isLoading = true;
            configService.selectOption(option);
        };

        $ctrl.manageSelectedOptions = (options) => {
            //Loop through the options received
            for (let dataIndex = 0; dataIndex < options.length; dataIndex++) {
                //Our controller options have categories, loop through those
                for (let categoriesIndex = 0; categoriesIndex < $ctrl.options.categories.length; categoriesIndex++) {
                    // Keep the current category
                    let currentCategory = $ctrl.options.categories[categoriesIndex];
                    //Loop through every option in each category
                    for (let optionIndex = 0; optionIndex < currentCategory.options.length; optionIndex++) {
                        let currentOption = currentCategory.options[optionIndex];
                        //If one of our controller option matches with one in the list, set
                        //the attibute selected to what was sent in the selected argument.
                        if (currentOption.code === options[dataIndex].code) {
                            let currentDataOption = options[dataIndex];
                            currentOption.selected = $ctrl.checkIfOptionShouldBeSelected(currentDataOption);
                            currentOption.msrp = currentDataOption.msrp;
                            currentOption.missedAlert = currentDataOption.missedAlert;

                            //Add a flag if the msrp contains a number
                            currentOption.showPriceSign = $ctrl.showPriceSign(currentOption);

                            //If the option was an exterior color, update the jelly
                            if (currentOption.selected === true && $ctrl.optionType === 'exteriorColor' && currentOption.ecc !== TONE_ECC_CODE) {
                                $rootScope.$broadcast('options-selector:paint-updated', { paint: currentOption.code, hexCode: currentOption.color });
                            }
                            if (currentOption.selected === true ) {
                                $rootScope.$broadcast('option-selector:sa-updated', { code: currentOption.code});
                            }
                        }
                    }
                }
            }
        };

        $ctrl.formatGroupContent = (groupOption) => {
            // Content is a ^ separated string. Here we parse it to get the values separated into an array.
            let includedOptions = groupOption.content.split('^');

            // The content string often starts with a ^ and a space. In that case the first element is a whitespace.
            // If that is the case we remove it and set the rest of the array as the content.
            // We test to see if any characters are not whitespace.
            if (!(/\S/.test(includedOptions[0]))) {
                includedOptions = includedOptions.slice(1);
            }
            $ctrl.groupOptionsContent[groupOption.code] = includedOptions;
        }

        $ctrl.optionHasContent = (option) => {
            if (!$ctrl.groupOptionsContent) {
                return false;
            }
            return !!$ctrl.groupOptionsContent[option.code];
        }

        $ctrl.toggleRowContent = ($event, option) => {
            if (option.code === $ctrl.expandedCode) {
                $ctrl.expandedCode = undefined;
            } else {
                $ctrl.expandedCode = option.code;
            }
            $event.stopPropagation();
            $event.preventDefault();
        }

        $ctrl.showLastAlert = ($event) => {
            $rootScope.$broadcast('options-selector:show-missed-alert', {});

            $event.stopPropagation();
            $event.preventDefault();
        }

        $ctrl.checkIfOptionShouldBeSelected = (option) => {
            return option.state === "S" || option.state === "G"
        };

        $ctrl.showPriceSign = (option) => {
            return option.msrp.match(/\d+/g);
        };
    }
})();
