(function() {
	angular
		.module('fca.chatMenuPopup')
		.component('fcaChatMenuPopup', {
			controller: FcaChatMenuPopupController,
			controllerAs: 'fcaChatMenuPopupCtrl',
			templateUrl: '/chat-popup/fca-chat-menu-popup.content.html',
			bindings: {
				skin: '@'
			}
		});

	function FcaChatMenuPopupController($scope, $rootScope) {
		'ngInject';

		this.isDisplayed = false;

		this.$onInit = () => {
			$scope.$on('nearestDealersWithChatObtained', (evt, args) => {
				this.isDisplayed = true;
			});
		};

		this.showPopup = () => {
			$rootScope.$broadcast('requestDealersChatPopup');
		};
	}
})();
