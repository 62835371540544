(function () {
	angular
	.module('fca.commonDirectives')
	.directive('featurePricingToggle', featurePricingToggle);

	function featurePricingToggle() {

		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controller: featurePricingToggleController,
			controllerAs: 'featurePricingToggle',
		};

		function featurePricingToggleController ($element, $scope, gtmAnalytics) {
			'ngInject';

			const vm = this;
			vm.activeType = 'finance';
			vm.isLease = false;
			vm.calculatorMode = 'finance';
			vm.activeTab = 'finance';

			vm.switchPricing = type => {
				switch (type) {
					case "finance" :
						vm.activeType = 'finance';
						vm.calculatorMode = 'finance';
						break;
					case "lease" :
						vm.activeType = 'lease';
						vm.calculatorMode = 'leasing';
						break;
				}
			};

			vm.togglePricing = () => {
				if (vm.activeType === 'finance') {
					vm.activeType = 'lease';
					vm.calculatorMode = 'leasing';
				} else if (vm.activeType === 'lease') {
					vm.activeType = 'finance';
					vm.calculatorMode = 'finance';
				}
			};

			vm.tabSelected = type => {
				vm.activeTab = type;
			}
		}
	}
})();
