(function () {
	angular
		.module('fca.kbb')
		.service('fcaKbbService', FcaKbbService);

	function FcaKbbService($http) {
		'ngInject';

		let $service = this;

		$service.baseUrl = '/data/trade-in';

		/**
		 * Get years from the KBB API
		 *
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getYears = (fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/years';
			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1
						&& response.data.Response) {
						successCallback(response.data.Response, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving years status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''));
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving years');
				});
		};

		/**
		 * Get grades from the KBB API
		 *
		 * Grade format is: {"ID":2,"Description":"FAIR"}
		 *
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getGrades = (fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/grades';
			$http.get(url)
			.then((response) => {
				if (response.data
					&& response.data.ResponseStatus === 1
					&& response.data.Response) {
					successCallback(response.data.Response, fcaKbbCtrl);
				} else {
					errorCallback("error retrieving grades status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''));
				}
			})
			.catch((data, status, headers, config) => {
				errorCallback('error retrieving grades');
			});
		};

		/**
		 * Get the location states from KBB
		 *
		 * 	Location format is:
		 *	{
		 *			"ID": 6,
		 *			"LocationID": 6,
		 *			"Name": "Ontario",
		 *			"IsDefault": true,
		 *			"TwoLetterCode": "ON",
		 *			"IPVA": 0,
		 *			"LicenseFee": 0
		 *	}
		 *
		 * @param language
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getLocationStates = (language, fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/locations?language=' + language;
			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1) {
						successCallback(response.data.Response, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving location states status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving location states');
				});
		};

		/**
		 * Get brands from KBB
		 *
		 * @param year
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getBrands = (year, fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/brands?year=' + year;

			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1) {
						successCallback(response.data.Response, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving brands status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving brands');
				});
		};

		/**
		 * Get models from KBB
		 *
		 * @param year
		 * @param brandId
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getModels = (year, brandId, fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/models?year=' + year + '&brandId=' + brandId;

			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1) {
						successCallback(response.data.Response, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving models status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving models');
				});
		};

		/**
		 * Get Vehicles from KBB
		 * @param language
		 * @param year
		 * @param modelId
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getVehicles = (year, modelId, brand, fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/vehicles?year=' + year + '&model=' + encodeURIComponent(modelId) + "&brand=" + encodeURIComponent(brand);

			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1) {
						successCallback(response.data.Response.VehicleList, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving vehicles status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving vehicles');
				});
		};

		/**
		 * Get equipment from KBB
		 *
		 * @param vehicleId
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getEquipment = (guid, fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/equipment?guid=' + guid;
			$http.get(url)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1) {
						successCallback(response.data, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving equipment status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
						fcaKbbCtrl.optionsLoading = false;
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving equipment');
					fcaKbbCtrl.optionsLoading = false;
				});
		};

		/**
		 * Get prices from KBB
		 * @param fcaKbbCtrl
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getPrices = (fcaKbbCtrl, successCallback, errorCallback) => {
			let url = $service.baseUrl + '/vehicle-prices';
			let postalCode = fcaKbbCtrl.postalCode;
			if (!postalCode) {
				postalCode = fcaKbbCtrl.userLocation.getDefaultPostalCodeForProvince(
					fcaKbbCtrl.userLocation.getLocationProvince()
				);
			}
			let consumer = {
				postal_code: postalCode
			}
			let payload = {
				source: fcaKbbCtrl.source,
				sourceId: fcaKbbCtrl.vehicle.ID,
				mileage: fcaKbbCtrl.realMileage,
				approximateCondition: fcaKbbCtrl.grade.ID,
				make: fcaKbbCtrl.brand.ID,
				model: fcaKbbCtrl.model.ID,
				year: fcaKbbCtrl.year.Year,
				style: fcaKbbCtrl.vehicle.label,
				equipments: fcaKbbCtrl.selectedOptions?fcaKbbCtrl.getSelectedOptions():null,
				consumer: consumer
			}
			$http.post(url, payload)
				.then((response) => {
					if (response.data
						&& response.data.ResponseStatus === 1
						&& response.data.Response) {
						successCallback(response.data.Response, fcaKbbCtrl);
					} else {
						errorCallback("error retrieving vehicle prices status: " + (response.data.ResponseStatus || '') + " response " + (response.data.Response || ''))
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving vehicle prices');
				});

		};

		/**
		 * Get the location state that corresponds to the province code
		 * @param locationStates
		 * @param provinceCode
		 * @returns {*}
		 */
		$service.getLocationState = (locationStates, provinceCode) => {
			let result = null;
			if (locationStates) {
				let matchingLocations = locationStates.filter((location, currentIndex, filteredArray) =>
					location.TwoLetterCode === provinceCode
				);
				if (matchingLocations
					&& matchingLocations.length > 0) {
					result = matchingLocations[0];
				} else {
					// can't match the province, use the first location state
					result = locationStates[0];
				}
			} else {
				console.error("can't determine location state for province code: " + provinceCode);
			}
			return result;
		};
	}
})();
