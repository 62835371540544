 (function() {
	angular
		.module('fca.brandApp')
		.directive('fcaArticleList', fcaArticleList);

		function fcaArticleList() { // eslint-disable-line max-len
			return {
				restrict: 'A',
				scope: true,
				controllerAs: '$fcaArticleListCtrl',
				controller: FcaArticleListController
			};

			function FcaArticleListController($scope, $rootScope, $http, $location) {
				'ngInject';

				let $ctrl = this;
				$ctrl.hasMoreArticles = true;
				$ctrl.articleList;
				$ctrl.articleArray = [];

				let browserCacheAvoider = 0;

				$ctrl.$onInit = () => {
					$ctrl.fetchNextPage();
				}

				$ctrl.fetchNextPage = () => {
					$http.get($location.$$path + '/fetch/' + browserCacheAvoider)
						.then(function (page) {

							$ctrl.articleList = page.data;

							$ctrl.articleList.articles.forEach((article) => {
								$ctrl.articleArray.push(article);
							});

							$ctrl.hasMoreArticles = $ctrl.articleList.moreArticles;
						})
						.catch((error) => {
							console.error('Error fetching new articles: ' + error.statusText);
						});

					browserCacheAvoider ++;

				};
			}
		}
})();
