(function() {
    angular
        .module('fca.brandApp')
        .directive('fcaDealerRedirect', fcaDealerRedirect);

    function fcaDealerRedirect() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                slickTheme: '@'
            },
            controllerAs: 'fcaDealerRedirectController',
            controller: fcaDealerRedirectController
        };

        function fcaDealerRedirectController($scope, $window, $http, cookieLocation, fcaGeolocator) {
            'ngInject';
            /* eslint-disable no-invalid-this */

            const BRAND = FCA_SITES_CONFIG.name;
            const CLOSEST_DEALERS_ENDPOINT = "/data/dealers/closest";
            const ctrl = this;

            ctrl.$onInit = () => {
                // we already have a cookie, use it to determine the correct dealer to redirect
                if (cookieLocation.isCookieExists()) {
                    ctrl.redirectToClosestDealer(cookieLocation.getLocation());
                } else {
                    // make sure we receive the update if the location changes based on something else
                    $scope.$on(fcaGeolocator.getLocationUpdatedEvent(), (evt, args) => {
                        ctrl.redirectToClosestDealer( args[0]); // the location is the very first argument
                    });

                    // trigger browser-based geo-location
                    fcaGeolocator.detectCurrentPosition().then((position) => {
                        if (angular.isObject(position)) {
                            ctrl.redirectToClosestDealer(position);
                        } else {
                            // fall-back to ip-based geo-location
                            fcaGeolocator.getCurrentLocation().then((ipLocation) => {
                                ctrl.redirectToClosestDealer(ipLocation);
                            })
                            .catch((reason) => {
                                console.error("error attempting to determine user's geo-location based on ip address", reason);
                            });
                        }
                    });
                }
            };

            /**
             * Redirect to the closest dealer to the location argumentation.
             * The object must have both latitude and longitude
             */
            ctrl.redirectToClosestDealer = (position) => {
                if (position
                    && position.latitude
                    && position.longitude) {

                    const {latitude, longitude} = position;

                    ctrl.findDealers(
                        latitude,
                        longitude,
                        BRAND,
                        (data) => {
                            if (data
                                && data.dealers
                                && data.dealers.length) {
                                // take the absolute closest dealer (by distance)
                                let closestDealer = data.dealers.reduce((prev, curr) => prev.distance < curr.distance ? prev : curr);
                                ctrl.redirectToUrl(closestDealer.url);
                            } else {
                                console.error(`dealer redirect: no dealers found close to lat: ${latitude} long: ${longitude}`);
                            }
                        },
                        (error) => {
                            console.error("error retrieving closest dealers", error);
                        });
                } else {
                    console.error("can't find closest dealer to position (no latitude/longitude)", position)
                }
            };

            /**
             * find dealers with the closest dealers api endpoint
             */
            ctrl.findDealers = (latitude, longitude, brand, successCallback, errorCallback) => {
                const url = `${CLOSEST_DEALERS_ENDPOINT}?`
                    + 'isDigital=true&'
                    + (brand ? `brand=${brand}` : '')
                    + (longitude ? `&longitude=${longitude}` : '')
                    + (latitude ? `&latitude=${latitude}` : '');

                return $http.get(url).then((r) => {
                    successCallback(r.data);
                }).catch(function(e) {
                    if (errorCallback) {
                        errorCallback(e);
                    }
                });
            };

            /**
             * Redirect to this url, but make sure we keep any querystring
             */
            ctrl.redirectToUrl = (url) => {
                let doRedirect = true;
                // take current query string
                const querystring = location.search || '';
                if (querystring) {
                    const parsed = parseQueryString(querystring);
                    if (parsed && parsed.no_redirect) {
                        doRedirect = false;
                    }
                }
                // add the current query string to the destination url
                const redirectLocation = url + querystring;
                if (doRedirect) {
                    // redirect the user
                    $window.location.replace(redirectLocation);
                } else {
                    console.error(`would redirect to ${redirectLocation}`);
                }
            };
        }
    }
})(angular);
