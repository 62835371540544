// Allows the usage of the following attributes:
//     * ng-touchend
//     * ng-touchstart
//     * ng-touchmove
//     * ng-touchcancel
(function() {
  'use strict';

  angular
    .module('fca.commonDirectives')
    .directive('ngTouchend', onTouchEnd)
    .directive('ngTouchstart', onTouchStart)
    .directive('ngTouchmove', onTouchMove)
    .directive('ngTouchcancel', onTouchCancel);

  function onTouchEnd() {
    return {
      controller: function($scope, $element) {
        $element.bind('touchend', onTouchEnd);

        function onTouchEnd(event) {
          var method = $element.attr('ng-touchend');
          $scope.$event = event;
          $scope.$apply(method);
        };
      }
    };
  }

  function onTouchStart() {
    return {
      controller: function($scope, $element) {
        $element.bind('touchstart', onTouchStart);

        function onTouchStart(event) {
          var method = $element.attr('ng-touchstart');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  }

  function onTouchMove() {
    return {
      controller: function($scope, $element) {
        $element.bind('touchmove', onTouchMove);

        function onTouchMove(event) {
          var method = $element.attr('ng-touchmove');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  }

  function onTouchCancel() {
    return {
      controller: function($scope, $element) {
        $element.bind('touchcancel', onTouchCancel);

        function onTouchCancel(event) {
          var method = $element.attr('ng-touchcancel');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  }

})();
