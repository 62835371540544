/* eslint-disable indent */
(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaVoiceSearch', fcaVoiceSearch);

	function fcaVoiceSearch() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: '$fcaVoiceSearch',
			controller: fcaVoiceSearch
		};

		function fcaVoiceSearch($anchorScroll) {
			'ngInject';
			let $fcaVoiceSearch = this;

			$fcaVoiceSearch.$postLink = () => {
				let currentUrl = window.location.href;
				let urlAnchor = currentUrl.substring(currentUrl.indexOf("#") + 1);
				let navHeight = 80;
				$fcaVoiceSearch.questionId = urlAnchor;
				$fcaVoiceSearch.analyticsValue = '-toggle-open';

				if(urlAnchor !== '' && document.getElementById(urlAnchor)) {

					let nav = document.querySelector('.VLP2021-navigation__list');
					if(nav !== null && nav.length) {
						navHeight = nav.offsetHeight < navHeight ? navHeight : nav.offsetHeight;
					}
					$anchorScroll.yOffset = navHeight + 15;
					$anchorScroll(urlAnchor);
				}
			};

			$fcaVoiceSearch.resetUrlAnchor = (id) => {
				if($fcaVoiceSearch.questionId === id) {
					$fcaVoiceSearch.questionId = '';
					$fcaVoiceSearch.analyticsValue = '-toggle-open';
				} else {
					$fcaVoiceSearch.questionId = id;
					$fcaVoiceSearch.analyticsValue = '-toggle-close';
				}
			};
		}
	}
})();
