(function() {
	angular
		.module('alfaromeo.imgDifference')
		.directive('alfaImgDifference', alfaImgDifference);

	function alfaImgDifference() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				angle: '@',
				animation: '<',
				direction: '@',
				enabled: '@',
				percentage: '@',
				onMove: '<',
				splitImgLabel : '@'
			},
			controllerAs: '$alfaImgDiffCtrl',
			controller: AlfaImgDifferenceController,
			templateUrl: '/brand-specific/alfaromeo/components/img-difference/alfaromeo-img-difference.html',
			transclude: true,
		};

		function AlfaImgDifferenceController($scope, $window, $element) {
			'ngInject';

			const DEFAULT_ANGLE = 0;
			const DEFAULT_DIRECTION = 'horizontal';
			const DEFAULT_PERCENTAGE = 0.5;

			const $ctrl = this;

			const getSkew = (angle) => {
				return $ctrl.direction === 'vertical' ? `skew(0, ${angle}deg)` : `skew(${angle}deg)`;
			};
			const getRotation = (angle) => {
				return $ctrl.direction === 'vertical' ? `rotate(${angle}deg)` : `rotate(${-angle}deg)`;
			};
			const getPercentage = (value) => {
				return `${value * 100}%`;
			};

			const limitAngle = () => {
				// make sure data is a floating number
				$ctrl.angle = parseFloat($ctrl.angle);

				// if angle is not a number, set it to default value
				if( isNaN($ctrl.angle) ) $ctrl.angle = DEFAULT_ANGLE;
			};
			const limitDirection = () => {
				// only valid value is vertical, otherwise direction equal horizontal
				if( $ctrl.direction !== 'vertical' ) $ctrl.direction = DEFAULT_DIRECTION;
			};
			const limitPercentage = () => {
				// make sure data is a floating number
				$ctrl.percentage = parseFloat($ctrl.percentage);

				// if percentage is not a number, set it to default value
				if( isNaN($ctrl.percentage) ) $ctrl.percentage = DEFAULT_PERCENTAGE;

				// limit range from 0 to 1
				if( $ctrl.percentage < 0.0 ) $ctrl.percentage = 0.0;
				else if( $ctrl.percentage > 1.0 ) $ctrl.percentage = 1.0;
			};

			const onWindowResize = (event) => {
				if( $ctrl.enabled === true ) {
					width = $element[0].clientWidth;
					height = $element[0].clientHeight;
				}
			};
			const onImageLoadCompleted = (event) => {
				onWindowResize();
				$scope.$apply();
				//if img completely loaded, set a
				//scoped var to the middle of the width
				if(width !== 0 ) {
					$ctrl.middle = width / 2;
				}
			};
			$ctrl.onDragMove = (event) => {
				let x = $ctrl.middle,
					y,
					point;
				if(event.originalEvent instanceof KeyboardEvent) {
					//move left
					if(event.which === 37) {
						if(x < 0) {
							x = 0;
						} else {
							x = ($ctrl.middle = $ctrl.middle - 10);
						}
					}
					//move right
					if(event.which === 39) {
						if(x > width) {
							x = width;
						} else {
							x = ($ctrl.middle = $ctrl.middle + 10);
						}
					}

				} else {
					point = event.touches ? event.touches[0] : event;
					x = point.clientX - rect.left;
					y = point.clientY - rect.top;
				}

				let percentage = $ctrl.direction === 'vertical' ? y / height : x / width;

				if( percentage < 0.0 ) percentage = 0.0;
				else if( percentage > 1.0 ) percentage = 1.0;

				$ctrl.percentage = percentage;
			};
			const onDragEnd = (event) => {
				$window.removeEventListener('mousemove', $ctrl.onDragMove);
				$window.removeEventListener('mouseup', onDragEnd);
				$window.removeEventListener('touchmove', $ctrl.onDragMove, {passive: false});
				$window.removeEventListener('touchend', onDragEnd, {passive: false});

				isRunning = false;

				if( raqID ) cancelAnimationFrame(raqID);
				raqID = null;
			};

			const animate = (settings) => {
				if( $ctrl.enabled === false ) return;

				$ctrl.percentage = settings.from;
				animationTarget = settings.to;

				updateAnimation();
			};
			const updateAnimation = () => {
				$ctrl.percentage += (animationTarget - $ctrl.percentage) * 0.1;

				if( $ctrl.percentage > animationTarget - 0.001 && $ctrl.percentage < animationTarget + 0.001 ) {
					$ctrl.percentage = animationTarget;
					updateScope();

					if( raqID ) cancelAnimationFrame(raqID);
					raqID = null;
				} else {
					updateScope();
					raqID = requestAnimationFrame( updateAnimation );
				}
			};
			const updateScope = () => {
				$scope.safeApply(() => {
					if( $ctrl.onMove && typeof $ctrl.onMove === 'function' ) $ctrl.onMove( $ctrl.percentage );
				});

				if( isRunning === true ) raqID = requestAnimationFrame( updateScope );
			};


			// https://coderwall.com/p/ngisma/safe-apply-in-angular-js
			$scope.safeApply = function(fn) {
				var phase = this.$root.$$phase;

				if(phase == '$apply' || phase == '$digest') {
					if(fn && (typeof(fn) === 'function')) {
						fn();
					}
				} else {
					this.$apply(fn);
				}
			};


			let width = 0;
			let height = 0;
			let isRunning = false;
			let rect;
			let animationTarget = null;
			let raqID = null;


			// default values
			$ctrl.angle = 0;
			$ctrl.direction = DEFAULT_DIRECTION;
			$ctrl.enabled = true;
			$ctrl.percentage = DEFAULT_PERCENTAGE;

			$ctrl.$onInit = () => {
				onWindowResize();
				$window.addEventListener('resize', onWindowResize);

				if( $ctrl.onMove && typeof $ctrl.onMove === 'function' ) $ctrl.onMove( $ctrl.percentage );
			};
			$ctrl.$postLink = () => {
				$element.find('img').each((index, img) => {
					img.onload = onImageLoadCompleted;
				});
			};
			$ctrl.$onChanges = (changes) => {
				if( changes.angle ) limitAngle();
				if( changes.direction ) limitDirection();
				if( changes.enabled ) {
					if( changes.enabled.currentValue === "true" ) {
						$ctrl.enabled = true;
						onWindowResize();
					} else $ctrl.enabled = false;
				}
				if( changes.percentage ) limitPercentage();
				if( changes.animation && changes.animation.currentValue ) animate(changes.animation.currentValue);
			};
			$ctrl.$onDestroy = () => {
				$window.removeEventListener('resize', onWindowResize);

				if( raqID ) cancelAnimationFrame(raqID);
				raqID = null;
			};


			$ctrl.onDragStart = (event) => {
				if( event ) {
					event.preventDefault();
					event.stopPropagation();
				}

				// prevent right click
				if( event.which === 3 ) {
					return;
				}

				// prevent multitouch
				if( event.touches && event.touches.length > 1 ) {
					return;
				}

				// if component is not enabled
				if( $ctrl.enabled !== true ) return;

				isRunning = true;
				rect = $element[0].getBoundingClientRect();

				$window.addEventListener('mousemove', $ctrl.onDragMove);
				$window.addEventListener('mouseup', onDragEnd);
				$window.addEventListener('touchmove', $ctrl.onDragMove, {passive: false});
				$window.addEventListener('touchend', onDragEnd, {passive: false});

				raqID = requestAnimationFrame( updateScope );
			};


			$ctrl.getImgStyles = () => {
				const percentage = parseFloat($ctrl.percentage);
				let x = 0,
					y = 0;

				if( $ctrl.direction === 'vertical' ) y = height * percentage;
				else x = width * percentage;

				return {
					transform: `translate(${x}px, ${y}px) ${getSkew($ctrl.angle)}`,
				};
			};
			$ctrl.getImgInvertedStyles = () => {
				const percentage = parseFloat($ctrl.percentage);
				let x = 0,
					y = 0;

				if( $ctrl.direction === 'vertical' ) y = height * percentage;
				else x = width * percentage;

				return {
					transform: `translate(${-x}px, ${-y}px) ${getSkew(-$ctrl.angle)}`,
				};
			};
			$ctrl.getSeparatorStyles = () => {
				const percentage = parseFloat($ctrl.percentage);
				let x = 0,
					y = 0;

				if( $ctrl.direction === 'vertical' ) y = height * percentage;
				else x = width * percentage;

				return {
					transform: `translate(${x}px, ${y}px) ${getSkew($ctrl.angle)}`,
				};
			};
			$ctrl.getSeparatorHandleStyles = () => {
				const angle = $ctrl.direction === 'vertical' ? $ctrl.angle + 90 : $ctrl.angle;
				return {
					transform: `translate(-50%, -50%) ${getSkew(-$ctrl.angle)} ${getRotation(angle)}`,
				};
			};
		}
	}
})();
