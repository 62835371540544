// Allows the usage of the following attributes:
//     * ng-dragend
//     * ng-dragenter
//     * ng-dragleave
//     * ng-dragover
//     * ng-dragstart
//     * ng-drop
(function () {
    'use strict';

    angular
        .module('fca.commonDirectives')
        .directive('ngDragend', onDragEnd)
        .directive('ngDragenter', onDragEnter)
        .directive('ngDragleave', onDragLeave)
        .directive('ngDragover', onDragOver)
        .directive('ngDragstart', onDragStart)
        .directive('ngDrop', onDrop);

   function onDragEnd () {
     return {
       controller: function ($scope, $element) {
         $element.bind('dragend', onDragEnd);

         function onDragEnd(event) {
           var method = $element.attr('ng-dragend');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

   function onDragEnter () {
     return {
       controller: function ($scope, $element) {
         $element.bind('dragenter', onDragEnter);

         function onDragEnter(event) {
           var method = $element.attr('ng-dragenter');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

   function onDragLeave () {
     return {
       controller: function ($scope, $element) {
         $element.bind('dragleave', onDragLeave);

         function onDragLeave(event) {
           var method = $element.attr('ng-dragleave');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

   function onDragOver () {
     return {
       controller: function ($scope, $element) {
         $element.bind('dragover', onDragOver);

         function onDragOver(event) {
           var method = $element.attr('ng-dragover');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

   function onDragStart () {
     return {
       controller: function ($scope, $element) {
         $element.bind('dragstart', onDragStart);

         function onDragStart(event) {
           var method = $element.attr('ng-dragstart');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

   function onDrop () {
     return {
       controller: function ($scope, $element) {
         $element.bind('drop', onDrop);

         function onDrop(event) {
           var method = $element.attr('ng-drop');
           $scope.$event = event;
           $scope.$apply(method);
         }
       }
     };
   };

})();
