// Directive to generate and handle Tabs carousel

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaMultipleNameplatesTabs', fcaMultipleNameplatesTabs);

	function fcaMultipleNameplatesTabs() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				defaultNameplate: '@',
			},
			controllerAs: '$fcaMultipleNameplatesTabs',
			controller: FcaMultipleNameplatesTabsController,
		};

		function FcaMultipleNameplatesTabsController($rootScope) {
			'ngInject';

			let $ctrl = this;

			$ctrl.$onInit = () => {
				let hash = window.location.hash;
				let subNameplateHashIndex = hash.indexOf('#subNameplate=');

				if (hash && subNameplateHashIndex > -1) {
					let subNameplateHash = hash.replace('#subNameplate=', '');
					$ctrl.setTab(subNameplateHash);
				} else {
					$ctrl.setTab($ctrl.defaultNameplate);
				}
			}

			$ctrl.setTab = (nameplate) => {
				$ctrl.activeNameplate = nameplate;
			}

			$ctrl.broadcastNewNameplate = (nameplate) => {
				$rootScope.$broadcast('subnameplate-change', nameplate);
			}

		}
	}
})();
