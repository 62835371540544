/*
*******************************************
* Functions for size manipulations
* *****************************************

1. equalHeight : Give the higher height to each selected element for perfect alignment
2. ...
___________________________________________

note: don't forget to re-trigger it on resize if needed
example:
window.addEventListener('resize', (event) => {
    functionExecutionControl.debounce(functionDynamicSizing.equalHeight(mySelector), 400);
});
*/

(function() {
    angular
        .module('fca.commonServices')
        .service('functionDynamicSizing', functionDynamicSizing);

    function functionDynamicSizing () {
        'ngInject';

        let $service = this;

        $service.equalHeight = (elements) => {
            if (elements && elements.length > 1) {
                // reset to auto
                elements.css('height', 'auto');

                let largerHeight = 0;

                elements.each(function() {
                    if (this.offsetHeight > largerHeight) {
                        largerHeight = this.offsetHeight;
                    }
                });

                if (largerHeight > 0) {
                    // output larger height to all elements
                    elements.css('height', largerHeight + 'px');
                }
            }
        };
    }
})();