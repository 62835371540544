(function () {
	'use strict';

	angular
		.module('fca.commonServices')
		.service('socialShare', socialShare);

	function socialShare() {
		const $service = this;

		/**
		 * Returns an URL which will open a facebook share prompt.
		 * The post's link will be the current location + provided hash parameters.
		 * This means hash parameters in the current URL will be ignored.
		 * @param {String} text - Text that will be displayed as a quote in the facebook post.
		 * @param {String} hashtag - A string which will be added to the post as a hashtag.
		 * 		- The string should start with #
		 * @param {Object} hashParameters - Object which will result in: {key}={value} url formatted hash parameters.
		 */
		$service.facebookShare = (text = '', hashtag = '', hashParameters = {}) => {
			const sharingUrl = encodeURIComponent($service.getSharingUrl() + $service.formatHashParameters(hashParameters));

			return `https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}&hashtag=${hashtag}&quote=${text}`;
		}

		/**
		 * Returns an URL which will open a twitter share prompt.
		 * The post's link will be the current location + provided hash parameters.
		 * This means hash parameters in the current URL will be ignored.
		 * @param {String} text - Text that will be added in the twitter post.
		 * @param {List<String>} hashtags - A list of strings which will be added to the post as hashtags.
		 * 		- These strings will be comma seperated and should not include #.
		 * @param {Object} hashParameters - Object which will result in: {key}={value} url formatted hash parameters.
		 */
		$service.twitterShare = (text = '', hashtags = [], hashParameters = {}) => {
			const sharingUrl = encodeURIComponent($service.getSharingUrl() + $service.formatHashParameters(hashParameters));

			return `https://twitter.com/intent/tweet?hashtags=${formatTwitterHashtags(hashtags)}&text=${text}&url=${sharingUrl}`;
		}

		/**
		 * Returns an URL which will open an email with populated subject and body.
		 * @param {String} subject - The email's subject
		 * @param {String} body - The email's body
		 */
		$service.emailShare = (subject, body, hashParameters) => {
			const unescapeBody = unescapeHtmlEntities(body);
            const sharingUrl = encodeURIComponent($service.getSharingUrl() + $service.formatHashParameters(hashParameters));

			return `mailto:?subject=${encodeURI(subject)}&body=${encodeURIComponent(unescapeBody)} ${sharingUrl}`;
		};

		/**
		 * Formats an object into hash parameters to be added to an URL.
		 * @param {Object} hashParameters - Object which will be formatted in: #/&{key}={value}
		 * @param {String} arraySeparator - Character which will be used to join array parameters with.
		 */
		$service.formatHashParameters = (hashParameters, arraySeparator = ',') => {
			if (!hashParameters) return '';

			return '#' + Object.entries(hashParameters)
				.map(([key, val]) => {
					if (Array.isArray(val)) {
						return `${key}=${val.join(arraySeparator)}`;
					}
					return `${key}=${val}`;
				})
				.join('&');
		}

		$service.getSharingUrl = () => {
			return location.origin + location.pathname;
		}

		// Takes a list of strings, sanitizes it for twitter then joins them with commas
		const formatTwitterHashtags = hashtags => {
			return hashtags.map(value => value.replace('#', "")).join(',');
		}

		// Unescapes html entities (such as &amps;) to text (&).
		const unescapeHtmlEntities = escapedHtml =>
			$('<textarea />').html(escapedHtml).text();
	}
})();