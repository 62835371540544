(function() {
	angular
		.module('fca.signupPopup')
		.directive('fcaSignupPopup', fcaSignupPopup);

	function fcaSignupPopup() {
		return {
			restrict: 'A',
			controller: FcaSignupPopupController,
			controllerAs: 'fcaSignupPopupCtrl',
			bindToController: true,
			scope: true
		};
	}

	function FcaSignupPopupController($scope, $element, $timeout, $window, gtmAnalytics, $cookies) {
		'ngInject';

		this.isDisplayed = false;
		this.isMinified = false;
		this.timerEnded = false;
		this.isRemoved = false;

		const cookieName = 'signupPopup';
		const cookieValue = 'hide-signup-popup';
		const cookieLength = 7;
		const inactiveTimerLength = 10000;

		this.$onInit = () => {
			this.readCookie();

			$timeout(() => {
				this.timerEnded = true;
			}, 5000);

			$(document).on('scroll', () => {
				if (this.timerEnded && $(window).scrollTop() > 500) {
					this.showPopup();
					$(document).unbind('scroll');
				}
			});
		};

		this.sendGtmTrackEvent = (label) => {
			gtmAnalytics.trackEvent('event', {
				category: 'vehicle signup',
				label: label
			});
		};

		this.showPopup = () => {
			this.isDisplayed = true;
			this.startInactiveTimer();
			this.sendGtmTrackEvent('sign up now - load');
		};

		this.minifyPopup = () => {
			this.isMinified = true;
			this.setCookie();
			this.sendGtmTrackEvent('sign up now - close');
		};

		this.unMinifyPopup = () => {
			this.isMinified = false;
			this.resetInactiveTimer();
			this.removeCookie();
			this.sendGtmTrackEvent('sign up now - open');
		};

		this.removePopup = () => {
			if ($(window).scrollTop() > 2000) {
				this.isRemoved = true;
			} else {
				this.resetInactiveTimer(2000);
			}
		};

		this.startInactiveTimer = () => {
			this.timerUnused = $timeout(() => {
				this.removePopup();
			}, inactiveTimerLength);
		};

		this.resetInactiveTimer = (timerLength = inactiveTimerLength) => {
			$timeout.cancel(this.timerUnused);

			this.timerUnused = $timeout(() => {
				this.removePopup();
			}, timerLength);
		};

		this.readCookie = () => {
			let cookie = $cookies.get(cookieName);

			if (cookie !== undefined) {
				this.isMinified = true;
			}
		};

		this.removeCookie = () => {
			$cookies.remove(cookieName);
		};

		this.setCookie = () => {
			let currDate = new Date();
			let cookieEndTime = new Date(currDate.getTime() - ((cookieLength * -1) * 24 * 60 * 60 * 1000));

			$cookies.put(cookieName, cookieValue, {
				expires: cookieEndTime,
				path: '/'
			});
		};
	}
})();
