(function(ng) {
	'use strict';

	angular
		.module('fca.bodyBuilder.dialog')
		.controller('bodyBuilderFileController', DialogController);

	function DialogController($scope, $element, $rootScope, ngDialog, $translate, $sce, $timeout, $window, $http) {
		'ngInject';

		let dialogData = $scope.ngDialogData;
		let fileExtension = dialogData.fileList[0].fileExtension;
		let baseUrl = window.FCA_SITES_CONFIG.mediaBaseUrl.slice(0, -1);
		let baseIframePath = baseUrl + dialogData.fileList[0].filePath;
		let sceIframePath = $sce.trustAsResourceUrl(baseIframePath);
		let category = dialogData.fileList[0].fileType;
		let minZoomLevel = 100;
		let maxZoomLevel = 500;
		let zoomIncrement = 50;
		let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

		$scope.dialogCloseLabel = $translate.instant(dialogData.dialogCloseLabel);
		$scope.fileList = dialogData.fileList;
		$scope.zoomLevel = minZoomLevel;
		$scope.minZoomLevel = minZoomLevel;
		$scope.maxZoomLevel = maxZoomLevel;
		$scope.embedSrc = false;
		$scope.isIOS = isIOS;

		this.getContent = (path, extension, category) => {
			$scope.isLoading = true;
			$scope.iframePath = path;
			$scope.currentCategory = category;
			$scope.zoomLevel = minZoomLevel;
			$scope.hasEmbed = false;
			$scope.embedSrc = false;
			$scope.isImagePath = false;
			$scope.isHtmlPath = false;
			$scope.isIframePath = false;

			if( extension.includes('jpg') ||
				extension.includes('jpeg') ||
				extension.includes('gif') ||
				extension.includes('png')) {
				$scope.isImagePath = true;
			} else {
				$scope.isImagePath = false;

				if(extension.includes('htm')) {
					$scope.isHtmlPath = true;

					$http.get(path).then(response => {
						let results = response.data;

						if(results) {
							let SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
							const hasEmbed = results.includes('<embed');

							results = results.replace(SCRIPT_REGEX, "");

							if(isIOS) {
								let SIZE_REGEX = (/(width='|height='|width="|height=").+?('[\s]?|"[\s]?|$)/gi);
								results = results.replace(SIZE_REGEX, "");
							}

							if(hasEmbed) {
								let parser = new DOMParser();
								let xmlDoc = parser.parseFromString(results,"text/xml");
								let resultsEmbedSrc = $(xmlDoc).find('embed').attr('src');

								if(resultsEmbedSrc) {
									$scope.hasEmbed = true;
									$scope.embedSrc = resultsEmbedSrc;
								}
							}

							$element.find('[data-html-clone]').html(results);
							$scope.isLoading = false;
						}
					}, error => {
						console.error(error);
					})
				} else {
					$scope.isIframePath = true;
				}
			}
		}

		this.zoomContent = (action) => {
			if(action === 'zoomin' && $scope.zoomLevel < maxZoomLevel) {
				$scope.zoomLevel = $scope.zoomLevel + zoomIncrement;
			} else if (action === 'zoomout' && $scope.zoomLevel > minZoomLevel) {
				$scope.zoomLevel = $scope.zoomLevel - zoomIncrement;
			}
		}

		this.close = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			ngDialog.close();
		};

		$window.isLoaded = () => {
			$timeout(function() {
				$scope.isLoading = false;
			});
		};

		this.changeFile = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			let newPath = baseUrl + $evt.currentTarget.getAttribute('ng-href');
			let sceNewPath = $sce.trustAsResourceUrl(newPath);
			let newExtension = $evt.currentTarget.getAttribute('data-file-extension');
			let newCategory = $evt.currentTarget.getAttribute('data-file-type');

			this.getContent(sceNewPath, newExtension, newCategory);
		};

		this.getContent(sceIframePath, fileExtension, category);
	}
})(angular);
