/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.summaryTooltips')
        .component('buildAndPriceSummaryTooltips', {
            controller: SummaryTooltips,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/summary-tooltips/summary-tooltips.html',
            bindings: {
                selectedPackage: '<',
                selectedTrim: '<',
                step: '<',
                drivetrainDescription: '@',
                engineDescription: '@',
                transmissionDescription: '@',
                selectedOptions: '<'
            }
        });

    function SummaryTooltips($scope, $location, $rootScope, $element, trimService, configService, $timeout, $translate) {
        'ngInject';

        let $ctrl = this;

        $ctrl.$postLink = () => {
            angular.element(document).ready(() => {
                /* Harvest content from DOM */
                if (this.value) {
                    angular.element('ng-transclude', $element[0]).html(this.value);
                }
            });
        };

        $ctrl.trimGroupSelectorUrl = $location.url().substr(0, $location.url().lastIndexOf('/'));

        $ctrl.optionsLabel = [];

        $ctrl.$onInit = () => {
            $ctrl.generateOptionsLabel(configService.getCategoriesObjects());

            $scope.$on('packages-selector:finished-sending-options', (_, data) => {
                $ctrl.generateOptionsLabel(data);
            });

            $scope.$on('alert-window:select-options-proceed', () => {
                $ctrl.generateOptionsLabel(configService.getCategoriesObjects());
            });
        };

        $ctrl.$onChanges = changes => {
            if (changes.selectedOptions && changes.selectedOptions.currentValue) {
                $ctrl.generateOptionsLabel(changes.selectedOptions.currentValue);
            }
        }

        $ctrl.getDrivetrainDescription = code => {
            if ($ctrl.drivetrainDescription) {
                return $ctrl.drivetrainDescription;
            }
            return trimService.getDrivetrainDescription(code);
        };

        $ctrl.getBoxLengthDescription = code => {
            return trimService.getBoxLengthDescription(code);
        };

        $ctrl.getCabTypeDescription = code => {
            return trimService.getCabTypeDescription(code);
        };

        // This function is needed because in english,
        // the box lengths have unescaped double quotes for the inches
        // This breaks when translating in the HTML template
        $ctrl.translateBoxLength = code => {
            let translated = $ctrl.getBoxLengthDescription(code);
            return $translate.instant(
                'build-and-price.director.box-length',
                { 'boxLength' : translated }
            )
        };

        $ctrl.alfaRomeoSelectedOptions = () => {
            return $ctrl.selectedOptions.map(option => option.description);
        }

        $ctrl.generateOptionsLabel = options => {
            if ($ctrl.selectedOptions) {
                $ctrl.optionsLabel = $ctrl.alfaRomeoSelectedOptions();
                return;
            }

            if (!options || !options.enginePackage) {
                return;
            }
            $ctrl.optionsLabel = [];
            const selectedPackage = options.enginePackage.find(pkg => pkg.selected);
            if (!selectedPackage) {
                return;
            }

            let allOptions = selectedPackage.content.split(/\^\s+/).filter(Boolean);
            let engineOption = null;
            let transmissionOption = null;
            let toRemoveOption = null;

            // We need to find the option in the list and split the transmission

            if (allOptions) {
              let index = 0;
              allOptions.forEach(function(option) {
                  let optionText = option.trim();
                  let isEngine = (optionText.indexOf('Engine') != -1) || (optionText.indexOf('Moteur') != -1);
                  let indexOfTransmission = optionText.indexOf('Transmission');

                  if (isEngine) {
                      toRemoveOption = index;

                      if (indexOfTransmission != -1) {
                          engineOption = optionText.substring(0, indexOfTransmission);
                          transmissionOption = optionText.substring(indexOfTransmission);
                      } else {
                          engineOption = optionText;
                      }
                  }
                  index++;
              });

              // Remove the Engine from the complete list

              $ctrl.optionsLabel = [];

              if (engineOption) {
                  $ctrl.optionsLabel.push(engineOption);
              }

              if (transmissionOption) {
                  $ctrl.optionsLabel.push(transmissionOption);
              }

              index = 0;
              allOptions.forEach(function(option) {
                  if (index != toRemoveOption) {
                    $ctrl.optionsLabel.push(option);
                  }
                  index++;
              });
            }
        }

        $ctrl.showSummaryButton = () => {
            return $ctrl.step !== "summary";
        }

        $ctrl.showPackagesButton = () => {
            return $ctrl.step !== "packages"
            && $ctrl.selectedTrim.packages
            && $ctrl.selectedTrim.packages.length > 1;
        }

        $ctrl.onChangeStepClick = step => {
            $rootScope.$broadcast('navigation: active-step-change', {
                'step': step,
            });
        }

        $ctrl.onSummaryClick = () => {
            $timeout(() => {
                $ctrl.onChangeStepClick("summary");
            });
        }

        $ctrl.onPackageClick = () => {
            $ctrl.onChangeStepClick("packages");
        }

        $ctrl.getEngineDescription = () => {
            return $ctrl.engineDescription ? $ctrl.engineDescription : $ctrl.selectedPackage.engine;
        }

        $ctrl.getTransmissionDescription = () => {
            return $ctrl.transmissionDescription ? $ctrl.transmissionDescription : $ctrl.selectedPackage.transmission;
        }
    }
})()
