(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaDaaPaymentMode', fcaDaaPaymentMode);

	function fcaDaaPaymentMode() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				defaultPayment: '@',
				nameplate: '@',
			},
			controllerAs: '$daaPaymentMode',
			controller: FcaDaaPaymentModeController,
		};

		function FcaDaaPaymentModeController($element, $scope, $rootScope) {
			'ngInject';

			const $ctrl = this;
			$ctrl.paymentOption = ''
			$ctrl.hasFinance = false;
			$ctrl.hasLease = false;
			$ctrl.hasCash = false;

			$ctrl.$onInit = () => {
				$ctrl.paymentOption = $ctrl.defaultPayment;

				$scope.$on('daa-offer:switch-pill', (_, data) => {
					if(data === 'all') {
						$ctrl.paymentOption = $ctrl.defaultPayment;
					} else {
						$ctrl.paymentOption = data;
					}

					$('.viewOfferInventory-tabsContainer .C_SFC-nameplate-tabs-content').addClass('ng-hide');
					$('.'+$ctrl.paymentOption+'-tab').removeClass('ng-hide');
					$rootScope.$broadcast('daa-offer:set-payment-option', $ctrl.paymentOption);

				});
			}

			$ctrl.setPaymentOption = (value,np) => {
				$ctrl.paymentOption = value;
				$('.'+np+'-payment-method').addClass('ng-hide');
				$('.'+value+'-'+np).removeClass('ng-hide');

				$rootScope.$broadcast('daa-offer:set-payment-option', value);
			}

			$ctrl.normalizeLeaseString = (value) => {
				return value === 'leasing' ? 'lease' : value
			};
		}
	}
})();
