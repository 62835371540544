(function () {
	angular
		.module("alfaromeo.vlp")
		.component("alfaromeoVlpOverlay", {
			controller: AlfaVlpOverlay,
			controllerAs: "$ctrl",
			templateUrl: "/brand-specific/alfaromeo/vlp/overlay/alfaromeo-overlay.html",
			transclude: true,
			bindings: {
				id: "@",
				closeButtonTheme: "@",
				closeButtonValue: "@",
				backgroundTheme: "@?",
				debug: "@",
				globalCssClass: "@",
				autoplayVideo: "@",
			}
		});

	function AlfaVlpOverlay($scope, $rootScope, $timeout, $window, $element, AR_VLP_OVERLAY) {
		"ngInject";

		const $ctrl = this;
		$ctrl.isSafari = false;
		$ctrl.scrollTopOffset = 0;
		const disableBodyScroll = bodyScrollLock.disableBodyScroll;
		const enableBodyScroll = bodyScrollLock.enableBodyScroll;

		let openUnregister = null;
		let closeUnregister = null;
		let timeoutCancel = null;

		let isOpened = false;
		let isVisible = false;
		let isEnabled = false;
		let isAnimated = false;
		let animationCompleted = false;

		$ctrl.showSpan = $('body').hasClass('qv-page');

		const onKeyDown = (event) => {
			// ESCAPE key pressed
			if( event.keyCode == 27 ) {
				$window.document.removeEventListener('keydown', onKeyDown);
				$rootScope.$emit(AR_VLP_OVERLAY.CLOSE, $ctrl.id);
			}
		};

		const onOverlayOpenRequested = (event, id) => {
			// if requested overlay is not myself, do nothing
			if( id !== $ctrl.id ) return;

			// if overlay is already opened, do nothing
			if( isOpened === true ) return;

			isOpened = true;


			// broadcast event to all children that opening has started
			$scope.$broadcast(AR_VLP_OVERLAY.OPEN_START, $ctrl.id);

			// disable body scrolling
			angular.element('html').css('overflow', 'hidden');
			angular.element('html').addClass('overlay-is-open');
			$ctrl.scrollTopOffset = $(window).scrollTop();


			window.scroll(0, -1)
			// listen to ESC keypress
			$window.document.addEventListener('keydown', onKeyDown);

			$scope.safeApply(() => {
				isVisible = true;
				isEnabled = true;
				isAnimated = true;
			});

			if ($ctrl.autoplayVideo == 'true' && document.querySelector('.AR_VLP_Overlay.-visible.-enabled video') != undefined) {
				document.querySelector('.AR_VLP_Overlay.-visible.-enabled video').play();
			}

			if( timeoutCancel ) $timeout.cancel(timeoutCancel);
			timeoutCancel = $timeout(() => {
				animationCompleted = true;

				// broadcast event to all children that opening has ended
				$scope.$broadcast(AR_VLP_OVERLAY.OPEN_END, $ctrl.id);
			}, 650);

			$ctrl.focusTrapActive();
		};

		const onOverlayCloseRequested = (event, id) => {
			// if requested overlay is not myself, do nothing
			if( id !== $ctrl.id ) return;

			// if overlay is not opened, do nothing
			if( isOpened === false ) return;
			isOpened = false;

			// broadcast event to all children that closing has started
			$scope.$broadcast(AR_VLP_OVERLAY.CLOSE_START, $ctrl.id);

			if ($ctrl.autoplayVideo == 'true' && document.querySelector('.AR_VLP_Overlay.-visible.-enabled video') != undefined) {
				document.querySelector('.AR_VLP_Overlay.-visible.-enabled video').pause();
				document.querySelector('.AR_VLP_Overlay.-visible.-enabled video').currentTime = 0;
			}

			// enable body scrolling
			angular.element('html').css('overflow', '');
			angular.element('html').removeClass('overlay-is-open');
			if (enableBodyScroll && bodyScrollLock.enableBodyScroll) {
				enableBodyScroll($ctrl.targetElement);
				$('html, body').scrollTop($ctrl.scrollTopOffset);
			}

			// unlisten to ESC keypress
			$window.document.removeEventListener('keydown', onKeyDown);

			$scope.safeApply(() => {
				animationCompleted = false;
				isAnimated = false;
			});

			if( timeoutCancel ) $timeout.cancel(timeoutCancel);
			$timeout(() => {
				isVisible = false;
				isEnabled = false;

				// broadcast event to all children that closing has ended
				$scope.$broadcast(AR_VLP_OVERLAY.CLOSE_END, $ctrl.id);
			}, 450);
		};

		// https://coderwall.com/p/ngisma/safe-apply-in-angular-js
		$scope.safeApply = function(fn) {
			var phase = this.$root.$$phase;

			if(phase == '$apply' || phase == '$digest') {
				if(fn && (typeof(fn) === 'function')) {
					fn();
				}
			} else {
				this.$apply(fn);
			}
		};

		$ctrl.$onInit = () => {
			openUnregister = $rootScope.$on(AR_VLP_OVERLAY.OPEN, onOverlayOpenRequested);
			closeUnregister = $rootScope.$on(AR_VLP_OVERLAY.CLOSE, onOverlayCloseRequested);
			$ctrl.targetElement = $element[0].querySelector(".AR_VLP_Overlay");

			// if is in debug mode, automatically open overlay
			if( $ctrl.debug === 'true' ) {
				$timeout(() => {
					$rootScope.$emit(AR_VLP_OVERLAY.OPEN, $ctrl.id);
				}, 2000);
			}
			if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 && !$ctrl.isSafari) {
				$ctrl.isSafari = true;
			}
		};

		$ctrl.$onDestroy = () => {
			if( openUnregister ) openUnregister();
			if( closeUnregister ) closeUnregister();
			if( timeoutCancel ) $timeout.cancel(timeoutCancel);

			openUnregister = null;
			closeUnregister = null;
			timeoutCancel = null;
		};

		$ctrl.close = (event) => {
			if( event ) {
				event.stopPropagation();
				event.preventDefault();
			}
			const tippyPopper = $element.find('.tippy-popper');
			if (tippyPopper.length > 0 ) {
				tippyPopper.css('visibility', 'hidden');
			}

			$rootScope.$emit(AR_VLP_OVERLAY.CLOSE, $ctrl.id);
		};

		$ctrl.isVisible = () => {
			return isVisible;
		};

		$ctrl.isEnabled = () => {
			return isEnabled;
		};

		$ctrl.isAnimating = () => {
			return isAnimated;
		};

		$ctrl.isAnimationCompleted = () => {
			return animationCompleted;
		};

		$ctrl.focusTrapActive = () => {
			let navDialogEl = document.querySelector('.AR_VLP_Overlay.-visible .AR_VLP_Overlay-main');
			let dialogOverlay = document.querySelector('.AR_VLP_Overlay.-visible');
			let dialogRef = new Dialog(navDialogEl, dialogOverlay);
			dialogRef.addEventListeners(
				'.AR_VLP_Overlay .slick-next.slick-arrow',
				'.AR_VLP_Overlay-close'
			);
			dialogRef.open();
		};
	}
})();
