/* eslint-disable indent */
(function() {
    angular
        .module('fca.buildAndPrice.director')
        .directive('fcaBuildAndPriceNavItem', fcaBuildAndPriceNavItem);

    function fcaBuildAndPriceNavItem() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {},
            controllerAs: '$ctrl',
            controller: FcaBuildAndPriceNavItem
        };

        function FcaBuildAndPriceNavItem($element, $rootScope, $location) {
            'ngInject';

            let $ctrl = this;

            $ctrl.trimGroupSelectorUrl = $location.url().substr(0, $location.url().lastIndexOf('/'));
            $ctrl.trimGroupSelectorUrl = addQueryParametersToUrl($ctrl.trimGroupSelectorUrl);
            $ctrl.trimGroupSelectorUrl = addHashparamsToUrl($ctrl.trimGroupSelectorUrl, ['scratchsave']);

            $ctrl.changeActiveStep = (step) => {
                if(!$element.hasClass('is-active')) {
                    $rootScope.$broadcast('navigation: active-step-change', {step: step});
                }
            };

            $ctrl.removeBuildId = (url) => {
                const newUrl = url.split('?');
                const param = new URLSearchParams(newUrl[1]).get('buildId');
                return url.replace(`buildId=${param}`, '');
            }
        }
    }
})();
