(function() {
	angular
		.module("fca.miniBuildAndPrice.stats")
		.component("statsMiniBp", {
			controller: statsMiniBp,
			controllerAs: "$ctrl",
			templateUrl:
				'/shared/fca-mini-build-and-price/stats/stats-mini-bp.html',
			bindings: {
				statsData: '<',
				isDesktop: '<',
				iconPowerDesktopUrl: '@',
				iconPowerMobileUrl: '@',
				iconSpeedDesktopUrl: '@',
				iconSpeedMobileUrl: '@',
				iconTorqueDesktopUrl: '@',
				iconTorqueMobileUrl: '@',
				statsFound: '<',
				statsTorque: '@',
				statsPower: '@',
				statsAcceleration: '@',
				stats: '<',
				statisticStyle: '@'
			}
		});

	function statsMiniBp(
		$scope
	) {
		"ngInject";

		const $ctrl = this;

		$ctrl.statsVisible = false;

		$ctrl.$onInit = () => {

		}

		$ctrl.toggleStatsVisible = () => {
			$ctrl.statsVisible = !$ctrl.statsVisible;
		}
	}
})();
