/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.directives')
        .directive('fcaTrimGroupPricing', fcaTrimGroupPricing);

    function fcaTrimGroupPricing() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                location: '<',
                acode: '@',
                modelYearId: '@',
                brand: '@',
                nameplate: '@',
                modelYear: '@',
                dealerCode: '@?',
                initialAcode: '@',
                campaignCode: '@?'
            },
            controllerAs: '$ctrl',
            controller: FcaTrimGroupPricing
        };

        function FcaTrimGroupPricing($http, $rootScope, $scope, $element, $location, fcaGeolocator, trimService, $filter, calculatorService, externalConfigLoader) {
            'ngInject';

            let $ctrl = this;

            $ctrl.apiUrl = '/api/buildandprice/trims/prices';

            $ctrl.activeTab = "cash";

            $ctrl.pricingObject = null;

            $ctrl.hashParameters = null;

            $ctrl.affiliateMode = false;

            $ctrl.dealerCode = null;

            $ctrl.pricingContainer = $element.find('.pricing-container');

            $ctrl.priceObject = {};

            $ctrl.calculatorService = calculatorService;

            /* Service Config */
            let configService = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

            const maxLeaseEir = configService.getConfig("maxLeaseEir");

            $ctrl.$onInit = () => {

                // Init affiliateMode
                storeAffiliateId();
                $ctrl.affiliateMode = isAffiliateMode();

                //Parse hash parameters
                $ctrl.hashParameters = getHashParameters();

                if ($ctrl.hashParameters != {}) {
                    $ctrl.activeTab = $ctrl.hashParameters.activeTab || 'cash';
                    $ctrl.restoreSession = $ctrl.hashParameters.restoreSession;
                }

                if ($ctrl.campaignCode && $ctrl.campaignCode !== 'null'
                    && $ctrl.campaignCode !== '') {
                    sessionStorage.setItem('campaignCode', $ctrl.campaignCode);
                } else {
                    sessionStorage.removeItem('campaignCode');
                }


                $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);

                /* Listen if the location is changed outside this component */
                let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
                $scope.$on(eventUpdateName, (evt, args) => {
                    $ctrl.getModelYearPricing(args[0].province);
                });

                if ($ctrl.location !== null) {
                    $ctrl.getModelYearPricing($ctrl.location.province);
                }

                $scope.$on('trim-group-pricing:set-active-tab', (event, data) => {
                    $ctrl.activeTab = data.activeTab;
                });

                $scope.$on('trim-service:trim-pricing-update', (event, data) => {
                    if (data.pricingData === "") {
                        return;
                    }
                    if ($ctrl.initialAcode) {
                        updateAcode()
                    }
                    const filteredPricingData = data.pricingData.filter(e => e.modelYearId === $ctrl.modelYearId);
                    $ctrl.pricingObject = filteredPricingData.length > 0 ? filteredPricingData[0].data : data.pricingData;

                    $ctrl.pricingContainer.removeClass('hidden');
                    updatePricingObject();
                });

                $rootScope.$on(
                    'alfa-trim-group:update-drivetrain',
                    updatePricingObject
                );
            };

            function updateAcode() {
                const tileSelector = '.js-trimgroup-acode-' + $ctrl.initialAcode;
                $ctrl.acode = $(tileSelector).attr('data-acode');
            }

            /**
             * TODO: refactor this method.
             * The pricingObject should not be updated on top of itself.
             * There should be one object that's untouched from the api and another that's "filtered" for display purpose.
             */
            function updatePricingObject() {
                updateAcode();
                if ($ctrl.pricingObject != null) {
                    for (let i = 0; i < $ctrl.pricingObject.length; i++) {
                        if ($ctrl.pricingObject[i].acode) {
                            if ($ctrl.pricingObject[i].acode === $ctrl.acode) {
                                let cashObject = $ctrl.pricingObject[i].cash;
                                let financeObject = $ctrl.pricingObject[i].finance;
                                let altFinanceObject = $ctrl.pricingObject[i].altFinance;
                                // copy both lease objects, remove them, from the pricing object
                                let leaseObject = $ctrl.pricingObject[i].lease;
                                let altLeaseObject = $ctrl.pricingObject[i].altLease;
                                $ctrl.pricingObject[i].lease = null;
                                $ctrl.pricingObject[i].altLease = null;


                                // assign cash tab variable
                                if (cashObject) {
                                    $ctrl.pricingObject[i].startingAtPrice = Math.ceil(cashObject.price);
                                    $ctrl.pricingObject[i].incentAmount = Math.round(cashObject.incentivesAmount);
                                }

                                if (financeObject) {
                                    // assign finance main program
                                    $ctrl.pricingObject[i].finance = {
                                        frequency: financeObject.frequencyCode,
                                        frequencyCode: financeObject.frequencyCode,
                                        payment: financeObject.payment,
                                        rate: financeObject.rate,
                                        eir: financeObject.eir,
                                        term: financeObject.term,
                                        incentive: Math.round(financeObject.incentivesAmount),
                                        incentivesAmount: financeObject.incentivesAmount
                                    };

                                    // assign alternative finance program if available
                                    // compare rate to exclude same rate program
                                    if (altFinanceObject && Number(financeObject.rate) > Number(altFinanceObject.rate)) {
                                        $ctrl.pricingObject[i].altFinance = {
                                            frequency: altFinanceObject.frequencyCode,
                                            frequencyCode: altFinanceObject.frequencyCode,
                                            payment: altFinanceObject.payment,
                                            rate: altFinanceObject.rate,
                                            eir: altFinanceObject.eir,
                                            term: altFinanceObject.term,
                                            incentive: Math.round(altFinanceObject.incentivesAmount),
                                            incentivesAmount: altFinanceObject.incentivesAmount
                                        };
                                    } else {
                                        // remove assignation cause the rate are not better than main program
                                        $ctrl.pricingObject[i].altFinance = null;
                                    }
                                } else if(altFinanceObject) {
                                    // assign alternative finance program as main finance program
                                    $ctrl.pricingObject[i].finance = {
                                        frequency: altFinanceObject.frequencyCode,
                                        frequencyCode: altFinanceObject.frequencyCode,
                                        payment: altFinanceObject.payment,
                                        rate: altFinanceObject.rate,
                                        eir: altFinanceObject.eir,
                                        term: altFinanceObject.term,
                                        incentive: Math.round(altFinanceObject.incentivesAmount),
                                        incentivesAmount: altFinanceObject.incentivesAmount
                                    };
                                }

                                let selectedLease = null;
                                if (leaseObject && altLeaseObject) {
                                    // if the effective rate is lower than the lease rate
                                    // AND the alternative payment is lower
                                    // AND the EIR is lower than the lease rate
                                    // use the alternative lease
                                    if (Number(leaseObject.payment) > Number(altLeaseObject.payment)
                                        && Number(altLeaseObject.eir||0) < Number(leaseObject.rate)) {
                                        selectedLease = altLeaseObject;
                                    } else {
                                        selectedLease = leaseObject;
                                    }
                                } else if (leaseObject) {
                                    // there's only a lease, use it
                                    selectedLease = leaseObject;
                                } else if (altLeaseObject) {
                                    // there's only an alternative lease, use it
                                    selectedLease = altLeaseObject;
                                }

                                // do not use the lease if the EIR is over the max
                                if (selectedLease && Number(selectedLease.eir||0) > Number(maxLeaseEir)) {
                                    selectedLease = null;
                                }

                                // if we have a selected lease, use it!
                                if (selectedLease) {
                                    $ctrl.pricingObject[i].lease = {
                                        frequency: selectedLease.frequencyCode,
                                        frequencyCode: selectedLease.frequencyCode,
                                        payment: selectedLease.payment,
                                        rate: selectedLease.rate,
                                        eir: selectedLease.eir,
                                        term: selectedLease.term,
                                        incentive: Math.round(selectedLease.incentivesAmount),
                                        incentivesAmount: selectedLease.incentivesAmount
                                    };
                                }

                                // manage incentives and discounts
                                if ($ctrl.pricingObject[i].provincialIncentive) {
                                    console.log($ctrl.pricingObject[i].provincialIncentive);
                                    $ctrl.pricingObject[i].incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].incentAmount,
                                        $ctrl.pricingObject[i].provincialIncentive, "cash");


                                    $ctrl.pricingObject[i].finance.incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].finance.incentive,
                                        $ctrl.pricingObject[i].provincialIncentive, "finance");

                                    if (leaseObject || altLeaseObject) {
                                        $ctrl.pricingObject[i].lease.incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].lease.incentive,
                                            $ctrl.pricingObject[i].provincialIncentive, "lease");
                                    }

                                    // manage cash disclaimer
                                    if($ctrl.pricingObject[i].provincialIncentive.disclaimer) {
                                        $ctrl.pricingObject[i].disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                                    }
                                    // manage finance disclaimer
                                    if($ctrl.pricingObject[i].provincialIncentive.disclaimer
                                            || $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance) {

                                        $ctrl.pricingObject[i].finance.disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance
                                                ? $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance
                                                : $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                                    }
                                    // manage lease disclaimer
                                    if($ctrl.pricingObject[i].provincialIncentive.disclaimer
                                        || $ctrl.pricingObject[i].provincialIncentive.disclaimerLease) {

                                        if (leaseObject || altLeaseObject) {
                                            $ctrl.pricingObject[i].lease.disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimerLease
                                                ? $ctrl.pricingObject[i].provincialIncentive.disclaimerLease
                                                : $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                                        }
                                    }
                                }

                                $ctrl.priceObject = $ctrl.pricingObject[i];
                            }
                        }
                    }

                    $rootScope.$broadcast('custom-order:first-dealer-price', $ctrl.pricingObject[0]);

                    $scope.$$postDigest(function () {
                        $rootScope.$broadcast('pricing:pricing-object-is-ready', {pageType: 'trimGroupSelector'});
                    });
                }
            }

            $ctrl.getModelYearPricing = provinceCode => {
                trimService.getTrimPricing(provinceCode, $ctrl.modelYearId, $ctrl.brand, $ctrl.nameplate, $ctrl.modelYear, $ctrl.dealerCode);
            };

            /**
             * This is executed when someone actually clicks on the active tab
             *
             * TODO: call the parent or use a two way binding instead of broadcasting
             */
            $ctrl.setActiveTab = (tab) => {
                let urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);
                $rootScope.$broadcast('pricing:set-active-tab-and-build-url', { activeTab: tab, urlSuffix: urlSuffix, pageType: 'trimGroupSelector'});
            };

            /**
             * change the active tab for the current trim group if it's changed by another
             */
            $scope.$on('pricing:set-active-tab-and-build-url', (event, data) => {
                $ctrl.updateActiveTab(data.activeTab);
            });

            /**
             * update the active tab for the current trim group
             */
            $ctrl.updateActiveTab = (tab) => {
                // only accept the new tab if we have a price object for it
                if ($ctrl.priceObject[tab]) {
                    $ctrl.activeTab = tab;
                    let newParams = $.extend($ctrl.hashParameters, {});
                    newParams.activeTab = tab;
                    // make sure we don't reuse the term (or else we'd have lease or finance terms on cash)
                    delete newParams.leaseTerm;
                    delete newParams.financeTerm;
                    switch (tab) {
                        case 'lease':
                        case 'altLease':
                            newParams.leaseTerm = $ctrl.priceObject[tab].term;
                            break;
                        case 'finance':
                        case 'altFinance':
                            newParams.financeTerm = $ctrl.priceObject[tab].term;
                            break;

                    }
                    $ctrl.urlSuffix = postProcessUrl(newParams);
                }
            };

            $ctrl.calculateIncentivesAndDiscount = (discount, provincialIncentiveAmount, federalIncentiveAmount) => {
                let total = 0;
                if (discount && parseFloat(discount) > 0) {
                    total += parseFloat(discount);
                }
                if (provincialIncentiveAmount && parseFloat(provincialIncentiveAmount) > 0) {
                    total += parseFloat(provincialIncentiveAmount);
                }
                if (federalIncentiveAmount && parseFloat(federalIncentiveAmount) > 0) {
                    total += parseFloat(federalIncentiveAmount);
                }

                return Math.round(total);
            }

            $ctrl.calculateSumIncentives = (discount,provincialIncentive,paymentType) => {
                let total = 0;
                let federalDiscount = 0;
                let provincialDiscount = 0;

                switch (paymentType) {
                    case 'cash':
                        provincialDiscount = provincialIncentive.disableCashProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableCashFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;

                    case 'finance':
                        provincialDiscount = provincialIncentive.disableFinanceProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableFinanceFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;

                    case 'lease':
                        provincialDiscount = provincialIncentive.disableLeaseProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableLeaseFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;
                }
                if (discount && parseFloat(discount) > 0) {
                    total += parseFloat(discount);
                }
                if (provincialDiscount && parseFloat(provincialDiscount) > 0) {
                    total += parseFloat(provincialDiscount);
                }
                if (federalDiscount && parseFloat(federalDiscount) > 0) {
                    total += parseFloat(federalDiscount);
                }
                return Math.round(total);
            }
        }
    }
})();
