/* eslint-disable indent */
(function() {
    angular
        .module('fca.slider')
        .component('slider', {
            controller: Slider,
            controllerAs: '$sliderCtrl',
            templateUrl: '/slider/slider.html',
            bindings: {
                min: '@',
                max: '@',
                step: '@',
                ngModel: '=',
                ngChange: '&'
            }
        });

    function Slider() {
        'ngInject';

        let $sliderCtrl = this;
        $sliderCtrl.$onInit = () => {
            // console.log('slider initiated')
        }
    }
})();
