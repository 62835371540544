(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaShareButtons', fcaAlfaShareButtons);

	function fcaAlfaShareButtons() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$alfaShareButtons',
			controller: fcaAlfaShareButtonsController
		};

		function fcaAlfaShareButtonsController($element, $window, $translate) {
			'ngInject';

			const $alfaShareButtons = this;
			const getSharingUrl = () => {
				return $window.location.href;
			};

			$alfaShareButtons.showLinks = false;

			$alfaShareButtons.getFacebookUrl = () => {
				const url = encodeURIComponent( getSharingUrl() );
				return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
			};

			$alfaShareButtons.getTwitterUrl = () => {
				const url = encodeURIComponent( getSharingUrl() );
				return `https://twitter.com/intent/tweet?url=${url}`;
			};

			$alfaShareButtons.getGooglePlusUrl = () => {
				const url = encodeURIComponent( getSharingUrl() );
				return `https://plus.google.com/share?url=${url}`;
			};

			$alfaShareButtons.getPinterestUrl = () => {
				let url = encodeURIComponent( getSharingUrl() );
				let shareUrl = `http://pinterest.com/pin/create/button/?url=${url}`;

				if(document.querySelectorAll("meta[property='og:image']").length > 0) {
					let media = document.querySelectorAll("meta[property='og:image']")[0].content;
					shareUrl += `&media=${media}`;
				}

				if(document.querySelectorAll("meta[property='og:description']").length > 0) {
					let description = encodeURIComponent(document.querySelectorAll("meta[property='og:description']")[0].content);
					shareUrl += `&description=${description}`;
				}

				return shareUrl;
			};

			this.toggleLinks = () => {
				console.log('Show buttons');

				if($alfaShareButtons.showLinks) {
					$alfaShareButtons.showLinks = false;
				} else {
					$alfaShareButtons.showLinks = true;
				}
			};

			this.openShareWindow = ($event, shareType) => {
				$event.preventDefault();
				let shareLink = false;

				if(shareType === 'facebook') {
					shareLink = $alfaShareButtons.getFacebookUrl();
				}

				if(shareType === 'twitter') {
					shareLink = $alfaShareButtons.getTwitterUrl();
				}

				if(shareType === 'pinterest') {
					shareLink = $alfaShareButtons.getPinterestUrl();
				}

				if(shareType === 'googleplus') {
					shareLink = $alfaShareButtons.getGooglePlusUrl();
				}

				if(shareLink) {
					$window.open(shareLink,'_blank',"height=300,width=495");
				}
			};
		}
	}
})();
