(function () {
    angular
    .module('fca.commonDirectives')
    .directive('fcaSimpleToggle', SimpleToggle);

    function SimpleToggle() {
        return {
            controllerAs: '$fcaSimpleToggle',
            controller: SimpleToggleCtrl
        };

        function SimpleToggleCtrl($element, $scope) {
            'ngInject';

            let $ctrl = this;

            $ctrl.menuOpened = false;

            $ctrl.paymentTypeDropdownToggle = (event) => {
                if (event) {
                    event.preventDefault();
                }

                if ($ctrl.menuOpened) {
                    $ctrl.menuOpened = false;
                } else {
                    $ctrl.menuOpened = true;
                    $ctrl.hideOnClickOutside($element);
                }
            };

            $ctrl.hideOnClickOutside = element => {
                const outsideClickListener = event => {
                    if (!element[0].contains(event.target)) {
                        if ($ctrl.menuOpened) {
                            $scope.$apply(() => {
                                $ctrl.menuOpened = false;
                            });
                            document.removeEventListener('click', outsideClickListener)
                        }
                    }
                };
                document.addEventListener('click', outsideClickListener)
            };


        }
    }
})();
