(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaRate', FcaRate);

    function FcaRate() {
        'ngInject';

        // Filter frequency
        // Current language
        const language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');

        return function(rate) {
            let result = rate;
            if (result) {
                // we don't want to use numbers (for some reason)
                if (typeof(result) === 'number') {
                    result = result.toString();
                }

                // we don't want to print 0.0
                if (parseFloat(result) == 0) {
                    result = '0';
                }

                if (language === "fr") {
                    result = result.replace('.', ',')
                }
            }
            return result;
        };
    }
})();
