(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFleetVehiclesTile', fcaFleetVehiclesTile);

	function fcaFleetVehiclesTile() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'fcaFleetVehiclesTile',
			controller: fcaFleetVehiclesTile,
		};

		function fcaFleetVehiclesTile($scope, $element, $timeout) {
			const $ctrl = this;

			$ctrl.descriptionExpanded = false;

			$ctrl.toggleDescriptions = () => {
				$scope.$emit("toggleDescriptions", $element);
			};
		}
	}
})();
