(function() {
	angular
		.module('alfaromeo.imgDifference')
		.directive('alfaImgDifferenceItem', alfaImgDifferenceItem);

	function alfaImgDifferenceItem() {
		return {
			restrict: 'A',
			scope: false,
			templateUrl: '/brand-specific/alfaromeo/components/img-difference/alfaromeo-img-difference-item.html',
			transclude: true,
		};
	}
})();
