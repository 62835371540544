/* eslint-disable indent */
(function() {
    'use strict';

    angular
        .module('fca.dealerItem')
        .controller('dearlerItemController', Controller)
        .component('dealerItem', {
            controller: 'dearlerItemController',
            controllerAs: '$dealerItem',
            bindings: {
                selected: '<?',
                dealerData: '<?',
                dealerPosition: '@?',
                location: '<',
                showDealerAppointmentSection: '<'
            },
            templateUrl: '/dealer-locator/dealer-item/item.html'
        });

    /**
     * @ngdoc controller
     * @name fca.dealerItem.controller:dearlerItemController
     * @description dealerItem Component controller
     * @example
     * <pre>[TODO]</pre>
     */
    function Controller($scope) {
        'ngInject';

        /**
         * @ngdoc property
         * @name isSelected
         * @propertyOf fca.dealerItem.controller:dearlerItemController
         * @type {Boolean}
         * @description
         * True if the item is selected in list
         */
        this.isSelected = false;
        this.language = FCA_SITES_CONFIG.language;
        // Prevents any duplicate ID for preferred dealers
        this.checkboxId = '0';

        this.siteGroupCode = FCA_SITES_CONFIG.siteGroupCode;
        this.brandCode = FCA_SITES_CONFIG.name;

        // Should Map be hidden on mobile
        $scope.hideMapMobile = false;

        /**
         * @ngdoc method
         * @name $onInit
         * @methodOf fca.dealerItem.controller:dearlerItemController
         * @description
         * Transform dealer object on component initialisation
         */
        this.$onInit = () => {
            this.setDistance();
            this.setDisplayPostalCode();
        };

        /**
         * @ngdoc method
         * @name $onChanges
         * @methodOf fca.dealerItem.controller:dearlerItemController
         * @description
         * Observe changes on isSelected and dealerData compoenent property
         */
        this.$onChanges = (o) => {
            this.isSelected = false;
            if (o.hasOwnProperty('selected') && o.selected.currentValue) {
                let current = o.selected.currentValue;
                if (current.dealerId === this.dealerData.dealerId) {
                    this.isSelected = true;
                    $scope.$emit('EVENT.dealerItemSelected', [current]);
                }
            }

            if (o.hasOwnProperty('dealerData') && o.dealerData.currentValue) {
                this.setDistance();
            }

            this.checkboxId = this.isSelected ? '1' : '0';
        };

        /**
         * @ngdoc method
         * @name setDistance
         * @methodOf fca.dealerItem.controller:dearlerItemController
         * @description
         * Round the property distance of dealer object
         */
        this.setDistance = () => {
            if (this.dealerData !== null) {
                this.dealerData.distance = Math.round(this.dealerData.distance || 0);
            }
        };

        this.setDisplayPostalCode = () => {
            if(this.dealerData !== null) {
                let postalCode = this.dealerData.zipPostal;
                if(postalCode !== null) {
                    let firstDigits = postalCode.substr(0, 3);
                    let lastDigits = postalCode.substr(3, 5);
                    this.dealerData.displayPostalCode = firstDigits + " " + lastDigits;
                }
            }
        }

        this.contactDealerUrl = () => {
            return `/${this.language}/${this.language === 'en' ? 'contact-a-dealer' : 'contacter-un-concessionnaire'}#dealer=${this.dealerData.code}`
        }
    }
})();
