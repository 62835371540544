(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaHeroBlockOverlay', fcaHeroBlockOverlay);

	function fcaHeroBlockOverlay() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsId: '@'
			},
			controllerAs: 'heroBlockOverlay',
			controller: fcaHeroBlockOverlayController
		};
	}

	function fcaHeroBlockOverlayController($scope, $element, $rootScope, gtmAnalytics) {


		const $ctrl = this;
		const body = document.body;
		const directiveElement = $element[0];
		const videoCloseButton = document.createElement('button');
		const videoThumbnail = directiveElement.querySelector('div.video-thumb');
		const videoContent = directiveElement.querySelector('div.video-content');
		const container = directiveElement.querySelector('div.video-content-container');
		const btnClose = directiveElement.querySelector('div.btn-close');
		const analyticsId = $element.attr('data-analytics-id');

		$ctrl.$onInit = () => {
			btnClose.parentNode.removeChild(btnClose);
			videoCloseButton.innerHTML=`<i class="fcaicon fcaicon-close" data-analyticsid=""></i>`;
			addEvents();
		};

		function addEvents() {
			videoThumbnail.addEventListener("click", () => {
				if(analyticsId) {
					sendGtmTrackEvent(analyticsId,'play');
				}
				changeClass(body, "noScroll", "add");
				changeClass(container, "HP_heroBlock-content-veil", "add");
				changeClass(videoContent, "HP_heroBlock-content-veil-video", "add");
				changeClass(container, "HP_heroBlock-content-hidden", "remove");
				body.addEventListener('keyup', onEscape);

				$rootScope.$on('createClosingButton', () => {
					videoCloseButton.setAttribute("data-ng-click", "video.destroyVideo()");
					container.appendChild(videoCloseButton);
					changeClass(videoCloseButton, "HP_heroBlock-content-closeButton", "add");
					changeClass(videoCloseButton, "HP_heroBlock-content-hidden", "remove");
					videoCloseButton.addEventListener('click', removeVideo);
				});

			});
			container.addEventListener('click', () => {
				if(analyticsId) {
					sendGtmTrackEvent(analyticsId,'exit');
				}
				removeVideo();
			});
		}

		function removeVideo() {
			$scope.$broadcast("hero-block-lightbox:destroyVideo");
			videoCloseButton.parentNode.removeChild(videoCloseButton);
			changeClass(body, "noScroll", "remove");
			changeClass(container, "HP_heroBlock-content-veil", "remove");
			changeClass(videoContent, "HP_heroBlock-content-veil-video", "remove");
			changeClass(videoCloseButton,"HP_heroBlock-content-hidden", "add");
			changeClass(container,"HP_heroBlock-content-hidden","add");
			body.removeEventListener('keyup', onEscape);
		}

		function changeClass(element, elementClass, addOrRemove) {
			if(addOrRemove==="add") {
				if(!element.classList.contains(elementClass)) {
					element.classList.add(elementClass);
				}
			}
			if(addOrRemove==="remove") {
				if(element.classList.contains(elementClass)) {
					element.classList.remove(elementClass);
				}
			}
		}
		function onEscape(event) {
			if(event.key==="Escape") {
				removeVideo();
			}
		}

		function sendGtmTrackEvent(id, label) {
			console.log("sendGtmTrackEvent");
			gtmAnalytics.trackEvent('event', {
				category: window.FCA_SITES_CONFIG.pageCode,
				label: id + label
			});
		}
	}
})();
