(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpExteriorOptions', alfaromeoVlpExteriorOptions);

	function alfaromeoVlpExteriorOptions() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				irisJellyParameters: '@',
				irisJellyParametersMobile: '@',
				irisCposVehicleType: '@',
				irisVehicule: '@',
				irisMfgCode: '@',
				irisPackageCode: '@',
				tabs: '<',
			},
			controllerAs: '$ctrl',
			controller: alfaromeoVlpExteriorOptionsController
		};

		function alfaromeoVlpExteriorOptionsController($element, $timeout, alfaIrisService, povHelper, matchmedia, FCA_MQ_LAYOUT, AR_IRIS_PRESETS) {
			'ngInject';

			const COLORS_TAB = 'colors';
			const DEFAULT_POVS = [
				povHelper.format(28),
				povHelper.format(19),
				povHelper.format(5),
				povHelper.format(1),
				povHelper.format(32)
			];
			const MOBILE_POV = povHelper.format(32);

			// from experimenting various values from Iris
			const DEFAULT_IRIS_CAPTURE = {
				width: 11500,
				height: 3500,
				x: -700,
				y: 4150,
			};
			const DEFAULT_IRIS_CAPTURE_MOBILE = {
				width: 6100,
				height: 4500,
				x: 2200,
				y: 3500,
			};


			const $ctrl = this;
			const irisUrlBuilder = alfaIrisService.getUrlBuilder().preset(AR_IRIS_PRESETS.EXTERIOR);

			let currentPOV = 0;
			let jellyIsLoading = true;
			let waitForTransition = false;
			let transitionPromise = null;

			$ctrl.selectedTabID = null;
			$ctrl.jellyCopyUrl = null;
			$ctrl.transitionState = 0;
			$ctrl.tabsSettings = {};

			$ctrl.$onInit = () => {
				if( $ctrl.tabs ) {
					$ctrl.tabs.forEach((tab) => {
						// select first tab who's not empty
						if( $ctrl.selectedTabID === null && tab.options && tab.options.length > 0 ) {
							$ctrl.selectedTabID = tab.id;
						}

						// select first option of each tabs
						if( tab.options && tab.options.length > 0 ) {
							const option = tab.options[0];
							$ctrl.selectOption(null, tab.id, option.contentPath, option.code, option.desc);
						}
					});
				}
			};

			const getTabSettings = (tabID) => {
				if( !$ctrl.tabsSettings[tabID] ) {
					$ctrl.tabsSettings[tabID] = {
						selectedOptionContentPath: null,
						selectedOptionCode: null,
						selectedOptionDescription: null,
						hoveringOptionDescription: null,
					};
				};

				return $ctrl.tabsSettings[tabID];
			};
			const getPOV = () => {
				if( matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS) ) {
					return DEFAULT_POVS[currentPOV];
				} else {
					// set currentPOV index to be equal to MOBILE_POV
					// if MOBILE_POV does not exist in DEFAULT_POVS, do nothing
					const mobilePovIndex = DEFAULT_POVS.indexOf( MOBILE_POV );
					if( mobilePovIndex > -1 ) currentPOV = mobilePovIndex;

					return MOBILE_POV;
				}
			};
			const getSelectedPaint = () => {
				return getTabSettings(COLORS_TAB).selectedOptionCode || null;
			};
			const getSelectedOptionsCode = () => {
				// join selectedOptionCode from all tabs except for paint
				return Object.entries($ctrl.tabsSettings).filter(tab => tab[0] !== COLORS_TAB).map(tab => tab[1].selectedOptionCode);
			};
			const getIrisOutput = (size) => {
				const ratio = 1800 / 700;

				if( size === 'xsmall' ) return alfaIrisService.calculateImageSize(768, 1536 / 900);
				else if( size === 'small' ) return alfaIrisService.calculateImageSize(1024, ratio);
				else if( size === 'medium' ) return alfaIrisService.calculateImageSize(1200, ratio);
				else if( size === 'large' ) return alfaIrisService.calculateImageSize(1800, ratio);
				else return null;
			};
			const getIrisCapture = (size) => {
				if( size === 'xsmall' ) {
					if( $ctrl.irisJellyParametersMobile ) return parseIrisJellyParams($ctrl.irisJellyParametersMobile);
					return DEFAULT_IRIS_CAPTURE_MOBILE;
				} else {
					if( $ctrl.irisJellyParameters ) return parseIrisJellyParams($ctrl.irisJellyParameters);
					return DEFAULT_IRIS_CAPTURE;
				}
			};
			const parseIrisJellyParams = (jellyParams) => {
				const values = jellyParams.split('&');
				const params = {};

				values.forEach((param) => {
					const entry = param.split('=');
					let key = entry[0];

					if( key === "w" ) key = "width";
					else if ( key === "h" ) key = "height";

					params[key] = parseInt(entry[1]);
				});

				return params;
			};
			const updateJellyWithSmoothTransition = () => {
				jellyIsLoading = true;
				waitForTransition = true;

				// get currentSrc of source image
				let url = $element.find('.AR_ExteriorOptions-jellyImg.-source')[0].currentSrc;

				// if browser does not support currentSrc, use image.src
				if( !url ) url = $element.find('.AR_ExteriorOptions-jellyImg.-source')[0].src;

				// clear running transition if it exists
				if( transitionPromise ) $timeout.cancel( transitionPromise );
				transitionPromise = null;

				if( $ctrl.jellyCopyUrl !== url ) $ctrl.jellyCopyUrl = url;
				else $ctrl.onJellyCopyLoadCompleted();
			};


			$ctrl.getJellyUrl = ( size ) => {
				if( waitForTransition === true ) return;

				const paint = getSelectedPaint();
				if( !paint ) return null;

				// calculate proportional image sizes for each viewports
				const output = getIrisOutput(size);
				const capture = getIrisCapture(size);

				// if output is invalid, return null
				if( !output ) return null;

				// build jelly URL
				irisUrlBuilder
					.vehicule($ctrl.irisVehicule)
					.mfgCode($ctrl.irisMfgCode)
					.packageCode($ctrl.irisPackageCode)
					.cposVehicleType($ctrl.irisCposVehicleType)
					.pov(getPOV())
					.paint(paint)
					.options(getSelectedOptionsCode())
					.capture(capture.width, capture.height, capture.x, capture.y)
					.output(output.width, output.height);

				return irisUrlBuilder.getURL();
			};

			$ctrl.onJellyLoadCompleted = (event) => {
				jellyIsLoading = false;
				$ctrl.transitionState = 2;

				transitionPromise = $timeout(() => {
					waitForTransition = false;
					$ctrl.transitionState = 0;
				}, 1100);
			};
			$ctrl.onJellyCopyLoadCompleted = (event) => {
				$ctrl.transitionState = 1;
				waitForTransition = false;
			};
			$ctrl.isJellyLoadCompleted = () => {
				return !jellyIsLoading;
			};
			$ctrl.nextPOV = (event) => {
				currentPOV++;
				if( currentPOV >= DEFAULT_POVS.length ) currentPOV = 0;

				updateJellyWithSmoothTransition();
			};
			$ctrl.previousPOV = (event) => {
				currentPOV--;
				if( currentPOV < 0 ) currentPOV = DEFAULT_POVS.length - 1;

				updateJellyWithSmoothTransition();
			};

			$ctrl.selectTab = (event, tabID) => {
				$ctrl.selectedTabID = tabID;

				// only for Edge
				if( window.navigator.userAgent.indexOf("Edge") > -1 ) {
					// force repaint after 100ms (https://issues.nurun.com/browse/CAQPRR-268)
					$timeout(() => {
						$element.find('.AR_ExteriorOptions-panel.-active .AR_ExteriorOptions-optionItem').each((index, el) => {
							el.style.display = 'none';
							el.offsetHeight;
							el.style.display = 'block';
						});
					}, 100);
				}
			};
			$ctrl.selectOption = (event, tabID, optionContentPath, optionCode, optionDescription) => {
				// if option is already selected, do nothing
				if( optionContentPath === $ctrl.getTabSelectedOptionContentPath(tabID) ) return;

				const tab = getTabSettings(tabID);

				tab.selectedOptionContentPath = optionContentPath;
				tab.selectedOptionCode = optionCode;
				tab.selectedOptionDescription = optionDescription;

				jellyIsLoading = true;
				waitForTransition = false;

				if( event ) updateJellyWithSmoothTransition();
			};
			$ctrl.rolloverOption = (event, tabID, optionDescription) => {
				const tab = getTabSettings(tabID);
				tab.hoveringOptionDescription = optionDescription;
			};
			$ctrl.rolloutOption = (event, tabID) => {
				const tab = getTabSettings(tabID);
				tab.hoveringOptionDescription = null;
			};
			$ctrl.getTabSelectedOptionContentPath = (tabID) => {
				return getTabSettings(tabID).selectedOptionContentPath;
			};
			$ctrl.getTabSelectedOptionCode = (tabID) => {
				return getTabSettings(tabID).selectedOptionCode;
			};
			$ctrl.getTabSelectedOptionDescription = (tabID) => {
				const tab = getTabSettings(tabID);
				return tab.hoveringOptionDescription || tab.selectedOptionDescription || null;
			};

			$ctrl.getAltText = (yearBrandNameplate) => {
				let altText;
				let currentOptions = '';
				$ctrl.tabs.forEach((tab) => {
					currentOptions += $ctrl.getTabSelectedOptionDescription(tab.id).replace('<sup>', '').replace('</sup>', '') + ' ';
				});

				altText = yearBrandNameplate + ' ' + currentOptions;
				return altText;
			};

		};
	};

})();
