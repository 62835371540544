(function() {
	// The glossary block itself
	let $glossaryBlock = $('.glossary-block');

	// The show all button
	let $glossaryShowAll = $('.glossary-show-all a');

	// List of words inside the glossary block
	let $glossaryItem = $glossaryBlock.find('.glossary-item');

	// Current groupe code
	let $selectedGroupCode = '';

	// Maximum amount of words to show before requiring a show all button
	let maxWordReveal = $glossaryBlock.data('max-words-count');

	// Reveal all or a specific amount of words depending on which group code is selected
	function revealGlossaryWords() {
		$glossaryShowAll.hide();
		if($selectedGroupCode !== '') {
			$('.glossary-item[data-group-code="' + $selectedGroupCode + '"]').slice(maxWordReveal).show();
		} else {
			$('.glossary-item').slice(maxWordReveal).show();
		}
		let columnOne = $('dl.glossary-column-1');
		let columnTwo = $('dl.glossary-column-2');
		columnTwo.find('.glossary-item').prependTo('.glossary-accordion');
		columnOne.find('.glossary-item').prependTo('.glossary-accordion');
		columnTwo.remove();
		columnOne.remove();
		glossaryTwoColumn();
	}

	// Trim the listed words to only those of the selected group code
	function revealGlossaryTerm(groupCode) {
		/* eslint-disable no-invalid-this */
		$glossaryItem.hide();
		$glossaryShowAll.hide();
		$('.glossary-key').each(function() {
			if ($(this).data('group-code') === groupCode) {
				$selectedGroupCode = groupCode;
				let columnOne = $('dl.glossary-column-1');
				let columnTwo = $('dl.glossary-column-2');
				let $currentGroupCode = $('.glossary-item[data-group-code="' + groupCode + '"]');
				$currentGroupCode.show();
				columnOne.find('.glossary-item').appendTo('.glossary-accordion');
				columnTwo.find('.glossary-item').appendTo('.glossary-accordion');
				columnOne.remove();
				columnTwo.remove();
				$currentGroupCode.slice(maxWordReveal).hide();
				glossaryTwoColumn();

				if($currentGroupCode.length > maxWordReveal) {
					$glossaryShowAll.fadeIn('fast');
				}
			}
		});
	}

	// Bind the words to their respective group code
	function initGroupCodes() {
		/* eslint-disable no-invalid-this */
		if($glossaryBlock.length > 0) {
			$glossaryBlock.find('.glossary-key').click(function() {
				let $this = $(this);
				$glossaryBlock.find('.glossary-key').not($this).removeClass('is-active');
				$this.addClass('is-active');
				let groupCode = $(this).data('group-code');
				revealGlossaryTerm(groupCode);
			});
		}
	}

	// Expand / collapse
	function glossaryAccordion() {
		/* eslint-disable no-invalid-this */
		$('.glossary-desc, .faq-desc').hide();
		$('.glossary-title, .faq-title').on('click', function() {
			expandCollapseAccordion($(this));
		});

		$('.glossary-title, .faq-title').on('keypress', function(event) {
			if (event.keyCode === 13) {
				expandCollapseAccordion($(this));
			}
		});
	}

	function expandCollapseAccordion(accordionElement) {
		if(accordionElement.next().is(':visible')) {
			accordionElement.next().slideUp();
			$('.glossary-title, .faq-title').removeClass('open');
			accordionElement.attr('data-analyticsvalue', 'click text-open');
			$('.glossary-titleText').attr('data-analyticsvalue', 'click text-open');
		}
		if(accordionElement.next().is(':hidden')) {
			$('.glossary-title, .faq-title').next().slideUp();
			$('.glossary-title, .faq-title').removeClass('open');
			$('.glossary-titleText').attr('data-analyticsvalue', 'click text-close');
			accordionElement.attr('data-analyticsvalue', 'click text-close');
			accordionElement.next().slideDown();
			accordionElement.addClass('open');
		}
	}

	// Setup the list of words in two columns
	function glossaryTwoColumn() {
		$('.glossary-accordion').each(function() {
			let $items = $('.glossary-item:visible');
			$items.slice(0, ($items.length + 1) / 2)
				.wrapAll('<dl class="glossary-column-1">');
			$items.slice(($items.length + 1) / 2)
				.wrapAll('<dl class="glossary-column-2">');
		});
	}

	function init() {
		// Hide any words after maxWordReveal
		$glossaryItem.slice(maxWordReveal).hide();

		// If there's too many words, show the Show All button
		if ($('.glossary-item:visible').eq(maxWordReveal)) {
			$glossaryShowAll.css({'display': 'inline-block'});

			// Add the reveal event on the click of showAll
			$glossaryShowAll.click(function() {
				revealGlossaryWords();
			});
		}

		glossaryAccordion();

		if($glossaryBlock.length > 0 && !$glossaryBlock[0].hasAttribute('data-single-column')) {
			glossaryTwoColumn();
		}

	}

	$('body').on('fcaMiniNavSideScrollEvent', function() {
		initGroupCodes();
	});

	init();
})();
