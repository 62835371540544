(function () {

	angular
	.module('fca.kbb')
	.directive('fcaBuyOnlineKbbCopyright', () => ({
		restrict: 'A',
		scope: true,
		bindToController: {
			vin: '@',
			tradeInValue: '@'
		},
		controllerAs: '$ctrl',
		controller: [
			"$scope",
			"userLocation",
			"fcaBuyOnlineService",
			"externalConfigLoader",
			"fcaGeolocator",
			($scope, userLocation, fcaBuyOnlineService, externalConfigLoader,
				fcaGeolocator) => new FcaBuyOnlineKbbCopyrightCtrl($scope, userLocation,
				fcaBuyOnlineService, externalConfigLoader, fcaGeolocator)
		]
	}));

	angular
	.module('fca.kbb')
	.directive('fcaTradeInKbbCopyright', () => ({
		restrict: 'A',
		scope: true,
		bindToController: {
		},
		controllerAs: '$ctrl',
		controller: [
			"$scope",
			"userLocation",
			"externalConfigLoader",
			"fcaGeolocator",
			($scope, userLocation, externalConfigLoader,
				fcaGeolocator) => new FcaTradeInKbbCopyrightCtrl($scope, userLocation, externalConfigLoader, fcaGeolocator)
		]
	}));

	class FcaKbbCopyrightCtrl {
		constructor($scope, userLocation, externalConfigLoader, fcaGeolocator) {
			this.$scope = $scope;
			this.userLocation = userLocation;
			this.externalConfigLoader = externalConfigLoader;
			this.fcaGeolocator = fcaGeolocator;
		}

		initPostalCode() {
			this.province = this.userLocation.getLocationProvince();
			this.fcaGeolocator.getCurrentLocation().then(location => {
					this.$scope.postalCode = location.postalCode;
					if (!this.$scope.postalCode) {
						this.$scope.postalCode = this.userLocation.getDefaultPostalCodeForProvince(
							this.province);
					}
				}
			);
		}

		initFormattedDate() {
			this.config = this.externalConfigLoader.loadConfig(
				'FCA_SITES_CONFIG');
			this.language = this.config.getConfig('language');

			let now = new Date();
			let format = {
				day: 'numeric',
				month: this.language === "en" ? 'numeric' : 'long',
				year: 'numeric'
			};
			this.$scope.formattedDate = now.toLocaleDateString(this.language,
				format);
		}


	}

	class FcaBuyOnlineKbbCopyrightCtrl extends FcaKbbCopyrightCtrl {
		constructor($scope, userLocation, fcaBuyOnlineService,
			externalConfigLoader, fcaGeolocator) {
			super($scope, userLocation, externalConfigLoader, fcaGeolocator)
			this.fcaBuyOnlineService = fcaBuyOnlineService;
		}

		$onInit() {

			this.initPostalCode();
			this.initFormattedDate();

			let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);
			this.updateTradeInState(checkout);
			this.initTradeInListener(this);
		};

		updateTradeInState(checkout) {
			this.haveTradeIn = false;

			if (checkout.kbb && checkout.kbb.haveTradeIn) {
				this.haveTradeIn = (checkout.kbb.haveTradeIn === "yes");
			}
		}

		initTradeInListener(ctrl) {
			this.fcaBuyOnlineService.registerListener(function (checkout) {
				ctrl.updateTradeInState(checkout);
			});
		}
	}


	class FcaTradeInKbbCopyrightCtrl extends FcaKbbCopyrightCtrl {

		$onInit() {
			this.initPostalCode();
			this.initFormattedDate();
		};

	}

})();
