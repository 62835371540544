(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaWarranty', fcaWarranty);

	function fcaWarranty() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsValue: "@",
			},
			controllerAs: 'warranty',
			controller: fcaWarrantyController
		};

		function fcaWarrantyController($scope, $element, $timeout, gtmAnalytics) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			const $ctrl = this;
			$ctrl.flipped = false;

			$ctrl.flip = (event) => {
				if (event) {
					event.stopPropagation();
				}

				let side = $ctrl.flipped ? 'panel-return' : 'panel-flip';

				gtmAnalytics.trackEvent('event', {
					category: `warranty & coverage` ,
					label: `${side}-${$ctrl.analyticsValue}`
				});

				$timeout(() => {
					$ctrl.flipped = !$ctrl.flipped;
					$ctrl.manageTabIndex();
					$scope.$apply();
				});
			};

			$ctrl.manageTabIndex = () => {
				let frontContainer = $element.find('.warranty-side-front .warranty-side-content');
				let backContainer = $element.find('.warranty-side-back .warranty-side-content');

				let activeContainer = $ctrl.flipped ? backContainer : frontContainer;
				let inactiveContainer = $ctrl.flipped ? frontContainer : backContainer;

				let elements = activeContainer.find('a[href]:not(.ng-hide), area[href]:not(.ng-hide), input:not([disabled]):not(.ng-hide), select:not([disabled]):not(.ng-hide), textarea:not([disabled]):not(.ng-hide), button:not([disabled]):not(.ng-hide), video:not(.ng-hide), [tabindex="0"]:not(.ng-hide)').filter(':visible');
				if (elements.length > 0) {
					elements.attr('tabindex', null);
				}

				elements = inactiveContainer.find('a[href]:not(.ng-hide), area[href]:not(.ng-hide), input:not([disabled]):not(.ng-hide), select:not([disabled]):not(.ng-hide), textarea:not([disabled]):not(.ng-hide), button:not([disabled]):not(.ng-hide), video:not(.ng-hide), [tabindex="0"]:not(.ng-hide)').filter(':visible');
				if (elements.length > 0) {
					elements.attr('tabindex', -1);
				}
			};

			$ctrl.sendGtmTrackEvent = () => {
				let side = $ctrl.flipped ? 'panel-return' : 'panel-flip';

				gtmAnalytics.trackEvent('event', {
					category: `warranty & coverage` ,
					label: `${side}-${$ctrl.analyticsValue}`
				});
			};
		}
	}
})();
