(function () {
	angular
		.module('fca.buyOnlineLead')
		.service('fcaBuyOnlineLeadService', FcaBuyOnlineLeadService);

	function FcaBuyOnlineLeadService($http, fcaBuyOnlineService, fcaSalesAppointmentService, $filter) {
		'ngInject';

		let $service = this;

		let fcaCurrencyWithDollar = $filter('fcaCurrencyWithDollar');

		/**
		 * Build the comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.buildComments = (checkout) => {
			let comments = "Start Buying Process Lead\n"
				+ $service.getPriceComment(checkout)
				+ $service.getAdjustmentsComment(checkout)
				+ $service.getOfferComment(checkout)
				+ $service.getTradeInComment(checkout)
				+ $service.getServicesProtectionComment(checkout)
				+ $service.getAccessoriesComment(checkout)
				+ $service.getCreditApplicationComment(checkout)
				+ $service.getDeliveryOptionComment(checkout)
				+ $service.getAppointmentComment();

			return comments;
		};

		/**
		 * Vehicle price comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getPriceComment = (checkout) => {
			let result = "Vehicle Price:\n\t";
			let formatted = {
				msrp: fcaCurrencyWithDollar(checkout.msrp),
				dealerPrice: fcaCurrencyWithDollar(checkout.dealerPrice),
				netAmount: fcaCurrencyWithDollar(checkout.netAmount)
			};
			if (checkout.msrp) {
				result += `MSRP: ${formatted.msrp} /`;
			}
			if (checkout.dealerPrice) {
				result += ` Dealer price: ${formatted.dealerPrice}`;
			}
			if (checkout.netAmount) {
				result += ` Net amount: ${formatted.netAmount}`;
			}
			return result;
		};

		/**
		 * Finance adjustments comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getAdjustmentsComment = (checkout) => {
			let result = "";
			if (checkout.downPayment
				|| checkout.tradeInValue) {
				let formatted = {
					downPayment: fcaCurrencyWithDollar(checkout.downPayment),
					tradeInValue: fcaCurrencyWithDollar(checkout.tradeInValue),
					tradeOwed: fcaCurrencyWithDollar(checkout.tradeOwed)
				};

				result += `\nAdjustments:`;
				if (checkout.downPayment) {
					result += `\n\tDown payment: ${formatted.downPayment}`;
				}
				if (checkout.tradeInValue) {
					result += `\n\tTrade in value: ${formatted.tradeInValue}`;
				}
				if (checkout.tradeOwed) {
					result += `\n\tTrade owed: ${formatted.tradeOwed}`;
				}
			}
			return result;
		};

		/**
		 * Selected Offer (one of the three based on final selected payment tab)
						  Cash: Dealer Price $28,000 with $9,000 in Cash Discounts
						  Finance: $115 Weekly @ 3.99% for 96 Months with $9,000 in Cash Discounts
						  Lease: $112 Weekly @ 7.29% for 60 Months with $9,000 in Cash Discounts
		 * @param checkout
		 * @returns {string}
		 */
		$service.getOfferComment = (checkout) => {
			let result = `\nSelected Offer:\n\t`;
			let formatted = {
				dealerPrice: fcaCurrencyWithDollar(checkout.dealerPrice),
				netAmount: fcaCurrencyWithDollar(checkout.netAmount),
				financePayment: fcaCurrencyWithDollar(checkout.financePayment),
				leasePayment: fcaCurrencyWithDollar(checkout.leasePayment),
				totalDiscount: fcaCurrencyWithDollar(checkout.totalDiscount)
			};

			if (checkout.financeMode === 'cash') {
				result += `Cash: ${checkout.financeMode}`;
				if (checkout.dealerPrice) {
					result += ` Dealer price: ${formatted.dealerPrice}`;
				}
				if (checkout.netAmount) {
					result += ` Net amount: ${formatted.netAmount}`;
				}
			} else if (checkout.financeMode === 'finance') {
				result += `Finance: ${formatted.financePayment} ${checkout.frequency} @ ${checkout.financeRate}% for ${checkout.financeTerm} Months`;
			} else if (checkout.financeMode === 'lease') {
				result += `Lease: ${formatted.leasePayment} ${checkout.frequency} @ ${checkout.leaseRate}% for ${checkout.leaseTerm} Months`;
			}
			if (checkout.totalDiscount) {
				result += ` with ${formatted.totalDiscount} in Cash Discounts`;
			}
			return result;
		};

		/**
		 * Trade-in: Vehicle Information: (Year, Make, Model, Trim, Condition, Mileage and KBB value returned)
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getTradeInComment = (checkout) => {
			let result = "";
			if (checkout.kbb
				&& checkout.kbb.tradeInValue) {
				let formatted = {
					tradeInValue: fcaCurrencyWithDollar(checkout.kbb.tradeInValue)
				};

				result += `\nTrade-in:\n`
					+ `\tVehicle: ${checkout.kbb.year.Year} `
					+ `${checkout.kbb.brand.Name} `
					+ `${checkout.kbb.model.Name} `
					+ `${checkout.kbb.vehicle.label}\n`
					+ `\tGrade: ${checkout.kbb.grade.Description}\n`
					+ `\tMileage: ${checkout.kbb.mileage} km\n`;

				if (checkout.kbb.options) {
					result += `\tOptions:\n`
					+ checkout.kbb.options.reduce((buffer, option) => buffer + `\t\t${option.Name}\n`, "");
				}
				result += `\tValue: ${formatted.tradeInValue}`;
			}
			return result;
		};

		/**
		 * Services and protection comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getServicesProtectionComment = (checkout) => {
			let result = "";
			let selectedServices = checkout.selectedServices;
			if (selectedServices) {
				for (let [key, value] of Object.entries(selectedServices)) {
					if (value) {
						if (result.length > 0) {
							result += ', ';
						}
						result += key;
					}
				}
				if (result.length > 0) {
					result = "\nService Protection:\n\t" + result;
				}
			}
			return result;
		};

		/**
		 * Accessories comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getAccessoriesComment = (checkout) => {
			let result = "";
			let selectedAccessories = checkout.selectedAccessories;
			if (selectedAccessories) {
				for (let [key, value] of Object.entries(selectedAccessories)) {
					if (value) {
						if (result.length > 0) {
							result += ', ';
						}
						result += key;
					}
				}
				if (result.length > 0) {
					result = "\nMopar Accessories:\n\t" + result;
				}
			}
			return result;
		};

		$service.getCreditApplicationComment = (checkout) => {
			let result = "\nCredit Application:\n\t";
			let leadReferenceNumber = checkout.leadReferenceNumber;

			if (!leadReferenceNumber) {
				result += "Not Submitted";
			} else {
				result += "Submitted (" + leadReferenceNumber + ")";
			}

			return result;
		}

		/**
		 * Delivery option comment block
		 *
		 * @param checkout
		 * @returns {string}
		 */
		$service.getDeliveryOptionComment = (checkout) => {
			let result = "\nInterested in Delivery Options:\n\t";
			let deliveryOptions = checkout.learnMoreAboutDeliveryOptions;

			if (!deliveryOptions) {
				deliveryOptions = false;
			}
			result += deliveryOptions? 'Yes' : 'No';
			return result;
		};

		/**
		 * Appointment object comment block
		 *
		 * @returns {string}
		 */
		$service.getAppointmentComment = () => {
			let result = "\nSales appointment :\n";
			let appointmentObject = fcaSalesAppointmentService.getFromStorage();
			let date = appointmentObject.date;
			let time = appointmentObject.time;
			let testDrive = appointmentObject.testDrive;

			if(date!="" && time!="") {
				if(testDrive != "") {
					testDrive = appointmentObject.testDrive ==="yes"
						? "\tThe customer has requested a test drive."
						: "\tThe customer is not interested in a test drive.";
					result +=
						`${testDrive}\n`;
				}
				result +=
					`\tThe customer has requested an appointment for ${date}  ${time}.\n`;
			} else {
				result +=
					`\tNot submitted\n`;
			}
			return result;
		};

		$service.submitLead = (brandCode, language, vin, lead, callback) => {
			let appointmentObject = fcaSalesAppointmentService.getFromStorage();
			let checkout = fcaBuyOnlineService.getFromStorage(vin);

			let comments = $service.buildComments(checkout);
			lead.push({
				'name' : 'customerComments',
				'value' : comments
			});

			$(lead).each(function (i, field) {
				if (field.name === "postType") {
					if (appointmentObject.date != "" && appointmentObject.time != "" && appointmentObject.testDrive === "yes") {
						field.value = "book_a_test_drive";
					}
				}
			});

			let endpoint = $service.buildApiUri(brandCode, language);

			let queryString = $.param(lead);
			$.post(endpoint, queryString, (response) => {
				callback(response);
			});
		};

		$service.registerSuccessListener = (listener) => {
			$service.successListener = listener;
		};

		$service.triggerSuccess = () => {
			if ($service.successListener) {
				$service.successListener();
			}
		};

		$service.buildApiUri = (brandCode, language) => {
			let uri = "/api/lead/" + language + "/brand/" + brandCode;
			return uri;
		};
	}
})();
