(function() {
	angular
		.module('fca.layout.regionalOffers')
		.component('fcaOffersPreview', {
			controller: FcaOffersPreviewController,
			templateUrl: '/fca-regional-offers/fca-offers-preview/fca-offers-preview.html'
		});

	function FcaOffersPreviewController($rootScope, $scope, FCA_MQ_IMAGES) {
		'ngInject';

		this.FCA_MQ_IMAGES = FCA_MQ_IMAGES;

		this.$onInit = () => {
		};
	}
})();
