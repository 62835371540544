(function () {
	angular
		.module('fca.brandApp')
		.directive('autoshowOverlay', AutoshowOverlay);

	function AutoshowOverlay() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$autoshowOverlayCtrl',
			controller: AutoshowOverlayController
		};

		function AutoshowOverlayController($element, $timeout, $window, $scope, $rootScope) {
			'ngInject';

			let $autoshowOverlayCtrl = this;
			let scrollingIsPaused = false;
			let dialogRef;

			const KEY_ESC = 27;

			const onKeyDown = (event) => {
				// ESCAPE key pressed
				if( event.keyCode === KEY_ESC && $autoshowOverlayCtrl.closeOnEscape) {
					$window.document.removeEventListener('keydown', onKeyDown);
					$autoshowOverlayCtrl.closeOverlay();
				}
			};

			$autoshowOverlayCtrl.pageContent = null;
			$autoshowOverlayCtrl.isLoading = false;
			$autoshowOverlayCtrl.focusTarget = false;
			$autoshowOverlayCtrl.hasContent = false;
			$autoshowOverlayCtrl.closeOnEscape = true;

			$autoshowOverlayCtrl.$onInit = () => {
				$autoshowOverlayCtrl.showDrawer = false;
			}

			$autoshowOverlayCtrl.manageOverlayOpen = (eventTarget) => {

				$autoshowOverlayCtrl.toggleDrawer();
				$autoshowOverlayCtrl.hasContent = true;
				$autoshowOverlayCtrl.setScrolling(false);
				$autoshowOverlayCtrl.setLoader(false);
				$autoshowOverlayCtrl.focusTrapActive();

				if(eventTarget.closest('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')) {
					$autoshowOverlayCtrl.focusTarget = eventTarget.closest('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
				} else {
					$autoshowOverlayCtrl.focusTarget = eventTarget;
				}

				$window.document.addEventListener('keydown', onKeyDown);
			}

			$autoshowOverlayCtrl.toggleDrawer = () => {
				$autoshowOverlayCtrl.showDrawer = !$autoshowOverlayCtrl.showDrawer;
			}

			$autoshowOverlayCtrl.setLoader = isLoading => {
				$autoshowOverlayCtrl.isLoading = isLoading;
			}

			$autoshowOverlayCtrl.setScrolling = (mustScroll) => {
				if(mustScroll && scrollingIsPaused) {
					//resumeScrolling
					scrollingIsPaused = false;
					$('body').removeClass('overflow-hidden');
					$('html').removeClass('overflow-hidden');
				} else if(!mustScroll && !scrollingIsPaused) {
					//pauseScrolling
					scrollingIsPaused = true;
					$('body').addClass('overflow-hidden');
					$('html').addClass('overflow-hidden');
				}
			}

			$autoshowOverlayCtrl.closeOverlay = () => {
				const overlayElement = $element.find('.js-overlay');

				if( $autoshowOverlayCtrl.focusTarget ) {
					let focusTarget = $autoshowOverlayCtrl.focusTarget;
					$autoshowOverlayCtrl.focusTarget = false;
					focusTarget.focus();
				}

				if($autoshowOverlayCtrl.showDrawer) {
					$autoshowOverlayCtrl.showDrawer = false;
					$autoshowOverlayCtrl.setScrolling(true);

					if(overlayElement) {
						$(overlayElement).bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
							$timeout(()=> {
								$autoshowOverlayCtrl.hasContent = false;
								$rootScope.$emit('autoshow-overlay:closed', null);
							});

							$(overlayElement).unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd');
						});
					}

					$window.document.removeEventListener('keydown', onKeyDown);

					$timeout(() => {
						$scope.$apply();
					});
				}
			}

			$autoshowOverlayCtrl.focusTrapActive = () => {
				const wrapperElement = $element.find('.js-overlay-wrapper');
				const dialogElement = $element.find('.js-overlay')[0];
				const overlayElement = $element.find('.js-overlay-background')[0];

				if(dialogElement && overlayElement) {
					wrapperElement.bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
						if (!dialogRef) {
							if (dialogElement && overlayElement) {
								dialogRef = new Dialog(dialogElement, overlayElement);

								//refresh the focus trap by calling Dialog.open again
								dialogRef.setRefreshOnTab(true);
								dialogRef.open();
							}
						} else {
							//reset focusable elements and focus on first element
							dialogRef.refresh();
							dialogRef.open();
						}
						wrapperElement.unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd');
					});
				}
			};

			$scope.$on('vr360.destroyed', () => {
				$autoshowOverlayCtrl.closeOverlay();
			});

			$rootScope.$on('tooltip-opened', () => {

				$timeout(() => {
					$autoshowOverlayCtrl.closeOnEscape = false;
				});
			});

			$rootScope.$on('tooltip-closed', () => {

				$timeout(() => {
					$autoshowOverlayCtrl.closeOnEscape = true;
				});
			});
		}
	}
})();
