(function () {
	angular
		.module('fca.brandApp')
		.service('brandPreloadStrategy', BrandPreloadStrategy);

	function BrandPreloadStrategy(povHelper) {
		const $service = this;

		$service.preloadStrategies = Object.freeze({
			// Download all of the povs
			all: 'all',
			// Download the most important povs
			ofInterest: 'ofInterest',
			// Download the povs needed for the jelly animations
			animation: 'animation',
			// Download specific main angles
			mainAngle: 'mainAngle'
		});

		// These are the povs needed for the jelly animation
		const povsOfInterest = [33, 28, 23];

		// This is the pov needed when there is no exterior 360, main angle images
		const mainAnglePov = [4];

		$service.generateViewpoints = (strategy, currentPov) => {
			switch (strategy) {
				case $service.preloadStrategies.all:
					return allPovsSorted(currentPov);
				case $service.preloadStrategies.ofInterest:
					return povsOfInterest;
				case $service.preloadStrategies.animation:
					return povPaths();
				case $service.preloadStrategies.mainAngle:
					return mainAnglePov;
				default:
					throw Error('Unrecognized strategy', strategy);
			}
		}

		// Returns all povs, sorted by their distance to the current pov
		const allPovsSorted = currentPov => {
			const allOtherViewpoints = [];

			for (let pov = povHelper.povMin; pov <= povHelper.povMax; pov++) {
				if (pov !== currentPov) {
					allOtherViewpoints.push(pov);
				}
			}

			allOtherViewpoints.sort((a, b) =>
				povHelper.distanceBetween(a, currentPov) -
				povHelper.distanceBetween(b, currentPov)
			);

			return allOtherViewpoints;
		}

		const povPaths = () => {
			const [front, side, rear] = povsOfInterest;
			const path1 = povHelper.pathBetween(front, side);
			const path2 = povHelper.pathBetween(side, rear);

			// Combine the two paths and remove duplicates
			return Array.from(new Set([...path1, ...path2]));
		}
	}
})();
