(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaOpenCollapse', fcaOpenCollapse);

	function fcaOpenCollapse() {
		return {
			restrict: 'E',
			scope: true,
			bindToController: {
				openedclass: '@'
			},
			controllerAs: 'openCollapse',
			controller: FcaOpenCollapseController,
		};

		function FcaOpenCollapseController($element, $timeout, $scope) {
			this.stateCloseLabel = 'close';

			this.$onInit = () => {
				this.isExpanded = false;
			};

			this.$postLink = () => {
				$element.addClass('open-collapse-initiated');
				this.toggleContentFromState($element[0].attributes.state.nodeValue);

				this.observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === "attributes" && mutation.attributeName === "state") {
							this.toggleContentFromState(mutation.target.attributes['state'].nodeValue);
						}
					});
				});

				this.observer.observe($element[0], {
					attributes: true
				});
			};

			this.$onDestroy = () => {
				this.observer.disconnect();
			};

			this.toggleContentFromState = (state) => {
				if (state === this.stateCloseLabel) {
					$element.removeClass(this.openedclass);
					this.isExpanded = false;
				} else {
					$element.addClass(this.openedclass);
					this.isExpanded = true;	
				}
			};

			this.toggleContent = () => {
				this.isExpanded = !this.isExpanded;
				$element.toggleClass(this.openedclass);
			};
		}
	}
})();
