/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.stepsManager')
        .service('buildAndPriceStepsService', BuildAndPriceStepsService);

    function BuildAndPriceStepsService($rootScope) {
        'ngInject';
        let $service = this;

        // those will be called every time the step changes
        $service.stepListeners = [];

        $service.stepChanged = (stepName) => {
            let message = {
                'step': stepName,
            };
            // broadcast, for those that don't use the callback
            $rootScope.$broadcast('navigation: active-step-changed', message);
            // call all listeners
            $service.notifyStepListeners(message);
        };

        $service.registerStepListener = (stepListener) => {
            $service.stepListeners.push(stepListener);
        };

        $service.notifyStepListeners = (message) => {
            if ($service.stepListeners && $service.stepListeners.length > 0) {

                // call all listeners
                $service.stepListeners.forEach(function(stepListener) {
                    if (stepListener) {
                        stepListener(message);
                    }
                });
            }
        };

    }
})(angular);
