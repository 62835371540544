(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaHideOptionCategory', FcaHideOptionCategory);

    function FcaHideOptionCategory($filter) {
        'ngInject';

        return function(category) {
            let newArray = [];
            for (let i = 0; i < category.length; i++) {
                if (!category[i].hasOwnProperty('hide')) {
                    newArray.push(category[i]);
                }
            }

            return newArray;
        };
    }
})();
