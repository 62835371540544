(function() {
	angular.module('fca.layout.regionalOffers', [
		'ngCookies',
		'base64',
		'pascalprecht.translate',
		'slick',
		'fca.geolocator'
	]).run((userLocation) => {
		// initialize the location
		userLocation.initLocation();
	});
})();
