(function () {
    angular
        .module('fca.miniBuildAndPrice.selector')
        .component('miniBpSelector', {
            controller: miniBpSelector,
            controllerAs: '$ctrl',
            templateUrl: '/shared/fca-mini-build-and-price/selector/selector-mini-bp.html',
            bindings: {
                onSelect: '<',
                options: '<',
                type: '@',
                withBorder: '<',
                withTitle: '<',
                withoutSwatchLabels: '<',
                categoryName: '@',
                placeholderImage: '@'
            }
        });

    function miniBpSelector($rootScope, alfaConfigPipeline, $filter) {
        'ngInject';

        const $ctrl = this;

        $ctrl.validTypes = Object.values(alfaConfigPipeline.selectorTypes);

        $ctrl.$onInit = () => {
            if ($ctrl.validTypes.indexOf($ctrl.type) === -1) {
                console.error(
                    `Invalid type in selector, expected one of ` +
                    `${JSON.stringify($ctrl.validTypes)}, got ${$ctrl.type}`
                );
            }
        }

        $ctrl.selectedOption = () => $ctrl.options.find(option => option.selected);

        $ctrl.onOptionClick = (option) => {
            $ctrl.onSelect(option);
        }

        $ctrl.onOptionMouseEnter = (option) => {

            $ctrl.currentlyHoveredOption = option;
        }

        $ctrl.onOptionMouseLeave = () => $ctrl.currentlyHoveredOption = null;

        $ctrl.selectorTitle = () => {
			let msrp = $ctrl.selectedOptionPrice();
			if (isNaN(msrp)) {
				msrp = 'Incl';
			} else {
				msrp = $filter('fcaCurrencyWithDollarSign')(msrp, 0);
			}

            if ($ctrl.currentlyHoveredOption) {
                return $ctrl.currentlyHoveredOption.description + " (" + msrp + ")";
            } else if ($ctrl.options) {
                return $ctrl.selectedOption().description + " (" + msrp + ")";
            }
        }

        $ctrl.selectedOptionPrice = () => {
            if ($ctrl.currentlyHoveredOption) {
                return $ctrl.currentlyHoveredOption.msrp;
            } else if ($ctrl.options) {
                return $ctrl.selectedOption().msrp;
            }
        }

        $ctrl.onMissedAlertClick = event => {
            event.stopPropagation();
            $rootScope.$broadcast('missedAlertClick')
        }
    }
})();
