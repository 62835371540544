// Directive to generate and handle Tabs carousel

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaTabsArrows', fcaTabsArrows);

	function fcaTabsArrows() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaTabs',
			controller: FcaTabsArrowsController,
		};

		function FcaTabsArrowsController($rootScope, $scope, $element, $timeout, functionExecutionControl, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			$ctrl.tabsContainer = $element.find('.fca-tabs-toggle-btn-container');
			$ctrl.tabsContainerOffset;
			$ctrl.tabsContainerWidth;
			$ctrl.showLeftArrow = false;
			$ctrl.showRightArrow = false;
			$ctrl.currentOffset = 0;
			$ctrl.scrollLeft = 0;

			$ctrl.$onInit = () => {
				$ctrl.getWidths();
				$(window).on('resize', functionExecutionControl.debounce($ctrl.getWidths, 400) );
				$element.find('.fca-tabs-toggle-scrollable').bind('touchend', $ctrl.onTouchEnd);
			}

			$ctrl.getWidths = () => {

				$ctrl.leftArrow = $element.find('fca-tabs-arrow-left');
				$ctrl.rightArrow = $element.find('fca-tabs-arrow-right');

				$timeout(() => {
					$ctrl.tabsButtons = $element.find($ctrl.tabsContainer).find('.fca-tabs-toggle-btn:visible');
					$ctrl.getTabsWidth();
					$ctrl.tabsContainerWidth = $ctrl.tabsContainer.width();
					$ctrl.tabsContainerOffset = $ctrl.tabsContainer.offset().left;
					let totalWidth = Math.floor($ctrl.tabsWidthsArray.reduce((a, b) => a + b, 0));

					if (totalWidth > $ctrl.tabsContainerWidth) {
						$ctrl.tabsContainer.addClass('arrows-shown');
						$ctrl.showRightArrow = true;
						$ctrl.totalScroll = totalWidth - $ctrl.tabsContainerWidth;
						$ctrl.currentOffset = 0;
						$ctrl.scrollLeft = 0;
					} else {
						$ctrl.tabsContainer.removeClass('arrows-shown');
						$ctrl.showRightArrow = false;
						$ctrl.showLeftArrow = false;
					}

					$scope.$apply();
					$ctrl.setAccessibility();
				})
			}

			$ctrl.moveTabs = (dir) => {

				if (!$ctrl.tabsOffsetArray) {
					$ctrl.getTabsOffset();
				}

				if (dir === 'left') {
					$ctrl.scrollLeft = $ctrl.tabsOffsetArray[$ctrl.currentOffset - 1];
					$ctrl.tabsContainer.scrollLeft($ctrl.scrollLeft);

					$ctrl.currentOffset--;
				} else {
					$ctrl.scrollLeft = $ctrl.tabsOffsetArray[$ctrl.currentOffset + 1];
					$ctrl.tabsContainer.scrollLeft($ctrl.scrollLeft);

					$ctrl.currentOffset++;
				}

				if (($ctrl.scrollLeft >= $ctrl.totalScroll) || ($ctrl.currentOffset >= $ctrl.tabsOffsetArray.length)) {
					$ctrl.showRightArrow = false;
				} else {
					$ctrl.showRightArrow = true;
				}

				$ctrl.showLeftArrow = $ctrl.currentOffset !== 0;

				$ctrl.setAccessibility();
			}

			this.dragScroll = () => {
				let position = $ctrl.tabsButtons.first().position().left;

				$ctrl.tabsButtons.each( function() {
					let $this = $(this);
					let offset = $this.offset().left;
					if(offset <= 0 && offset >= $this.outerWidth() * -1) {
						$ctrl.currentOffset = $this.index() + 1;
						$ctrl.scrollLeft = $this.index();
					}
				});

				$ctrl.positionLeft = (position * -1);

				$ctrl.showRightArrow = $ctrl.positionLeft < $ctrl.totalScroll;
				$ctrl.showLeftArrow = $ctrl.positionLeft > 0;

				$ctrl.setAccessibility();
			};

			$ctrl.setAccessibility = () => {
				$ctrl.leftArrowWidth = $ctrl.leftArrow.outerWidth();
				$ctrl.rightArrowWidth = $ctrl.rightArrow.outerWidth();

				$ctrl.tabsButtons.each((index, tabButton) => {

					if ($ctrl.leftArrowWidth === "undefined" || index === 0) {
						$ctrl.leftArrowWidth = 0;
					}

					if ($ctrl.rightArrowWidth === "undefined" || index === $ctrl.tabsButtons.length - 1) {
						$ctrl.rightArrowWidth = 0;
					}

					let tabSpanOffset = $(tabButton).find('.fca-tabs-toggle-btn-content').offset().left;
					let tabSpanWidth = $(tabButton).find('.fca-tabs-toggle-btn-content').outerWidth();

					if (((tabSpanOffset + tabSpanWidth) > ($ctrl.tabsContainerOffset + ($ctrl.tabsContainerWidth - $ctrl.rightArrowWidth))) || tabSpanOffset < ($ctrl.tabsContainerOffset + $ctrl.leftArrowWidth)) {
						$(tabButton).attr('tabindex', -1);
					} else {
						$(tabButton).attr('tabindex', 0);
					}
				})
			}

			$ctrl.onTouchEnd = (event) => {
				$ctrl.dragScroll();
				$scope.$apply();
			}

			$ctrl.getTabsOffset = () => {

				$ctrl.tabsOffsetArray = [];
				let initialWidth = 0;

				$ctrl.tabsButtons.each((index, tabButton) => {
					$ctrl.tabsOffsetArray.push(initialWidth);
					initialWidth = initialWidth + $(tabButton).outerWidth();
				})
			}

			$ctrl.getTabsWidth = () => {

				$ctrl.tabsWidthsArray = [];

				$ctrl.tabsButtons.each((index, tabButton) => {
					let tabWidth = $(tabButton).outerWidth();

					$ctrl.tabsWidthsArray.push(tabWidth);
				})
			}

			$ctrl.sendAnalytics = (category, id) => {
				gtmAnalytics.trackEvent('event', {
					category: category,
					label: id
				})
			}
		}
	}
})();
