(function () {
    angular
        .module('alfaromeo.miniNav')
        .component('alfaromeoMiniNavSideShowMore', {
            controller: AlfaromeoMiniNavSideShowMore,
            controllerAs: '$ctrl',
            templateUrl: '/brand-specific/alfaromeo/components/mini-nav/mini-nav-side-show-more/alfaromeo-mini-nav-side-show-more.html',
			transclude: true
        });

    function AlfaromeoMiniNavSideShowMore($rootScope, $interval, $scope, $window, $element, $timeout, miniNavService, matchmedia, FCA_MQ_LAYOUT) {
        'ngInject';

        const $ctrl = this;
		const NAV_ITEMS_HIDDEN_CLASSNAME = 'is-hidden';

		let initialized = false;
		let resizeTimeout;
		let navItemsOffsets;
		let scrollerRect;
		let subNavMenu;
		let subNavListItems;
		let miniNaviItems;
		let showMoreMenuNavItems;
		let navWidthTimer;

		$ctrl.showMoreBtnRequired = false;
		$ctrl.showMoreMenuVisible = false;
		$ctrl.btnLabel = '';

		$ctrl.$onInit = () => {

			// cache DOM nodes
			subNavMenu = $element.find('.mini-nav-sub-menu')[0];

			// initialize or destroy component when switching from mobile to tablet viewport (>= 768px)
			matchmedia.on(FCA_MQ_LAYOUT.MEDIUM_PLUS, onMatchMedia);

			// Listen for each section enetering the page (note: backend returns multiple divs with same id)
			$rootScope.$on('fcaWaypoint.reached', onWaypointReached);

			// when viewport is resized, refresh toggling behavior
			$window.addEventListener('resize', onResizeAndOrientationChange);
			$window.addEventListener('orientationchange', onResizeAndOrientationChange);

			$timeout(() => {
				$ctrl.btnLabel = $element[0].getAttribute("data-label");
			});
		};

		$ctrl.$postLink = () => {

			// cache DOM nodes included via ng-transclude
			subNavListItems = $element.find('.mini-nav-sub-menu ul:first>li').toArray();
			showMoreMenuNavItems = $element.find('.mini-nav-show-more-menu ul:first>li').toArray();
			miniNaviItems = $element.find('.mini-nav-sub-menu [data-alfa-mini-nav-item]').toArray();

			// initialize component if we already are in correct viewport size
			if( matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS) ) init();
			else refresh();
		};

		$ctrl.$onDestroy = () => {
			$window.removeEventListener('resize', onResizeAndOrientationChange);
			$window.removeEventListener('orientationchange', onResizeAndOrientationChange);
			matchmedia.off(FCA_MQ_LAYOUT.MEDIUM_PLUS, onMatchMedia);

			destroy();

			resizeTimeout = null;
			navItemsOffsets = null;
			scrollerRect = null;
			subNavMenu = null;
			subNavListItems = null;
			showMoreMenuNavItems = null;
		};

		$ctrl.checkNavWidth = () => {
			let nav = getScrollerRect();
			let navMinVidth = 250;

			if(nav.width > navMinVidth) {
				refresh();
				$interval.cancel(navWidthTimer);
				navWidthTimer = undefined;
			}
		};

		$ctrl.toggleShowMoreMenu = (event) => {
			if( event ) {
				event.preventDefault();
				event.stopPropagation();
			}

			$ctrl.showMoreMenuVisible = !$ctrl.showMoreMenuVisible;

			// close menu when clicking outside of show more menu
			$window.document[$ctrl.showMoreMenuVisible ? 'addEventListener' : 'removeEventListener']('click', forceCloseMenu);
		};


		const onMatchMedia = (mediaQueryList) => {
			if (mediaQueryList.matches) init();
			else destroy();
		};
		const onResizeAndOrientationChange = (event) => {
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(refresh, 300);
		};
		const forceCloseMenu = (event) => {
			// make sure click event listener is removed
			$window.document.removeEventListener('click', forceCloseMenu);

			// close show more menu at next digest cycle
			$scope.$apply(() => {
				$ctrl.toggleShowMoreMenu();
			});
		};
		const onWaypointReached = (event, data) => {
			// do nothing if viewport is >= 768px
			if( matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS, onMatchMedia) ) return;

			// if waypoint event is related to our mini nav items
			const eventFromValidGroup = miniNavService.itemCollection.indexOf(data.groupId) >= 0;
			if( !eventFromValidGroup ) return;

			// find miniNavItem related to this waypoint event
			const miniNavItem = (miniNaviItems || []).find((el, index) => {
				return el.dataset.miniNavGroupId === data.groupId;
			});


			// if no miniNavItem is related to this waypoint event, do nothing
			if( !miniNavItem ) return;

			// if current miniNavItem is active, don't reset the scrollLeft
			if (miniNavItem.classList.contains('is-active')) {
				return;
			}

			// get miniNavItem rectangle
			const rect = miniNavItem.getBoundingClientRect();

			// center miniNavItem in scroller
			const offset = subNavMenu.scrollLeft + rect.x - (($element[0].offsetWidth - rect.width) / 2);

			subNavMenu.scrollLeft = offset;
		};

		const init = () => {
			// do nothing if component is already initialized
			// do nothing if navigation is empty
			if( initialized === true || !subNavListItems || subNavListItems.length === 0 ) return;

			// reset scrolling position
			subNavMenu.scrollLeft = 0;

			refresh();

			navWidthTimer = $interval(this.checkNavWidth, 500);

			// set component as initialized
			initialized = true;
		};

		const destroy = () => {
			// do nothing if component has not been initialized
			if( initialized !== true ) return;

			// clear resize timeout
			clearTimeout( resizeTimeout );

			// clear navigation items state
			subNavListItems.forEach((el, index) => {
				const showMoreMenuItemEl = showMoreMenuNavItems[index];

				el.classList.remove( NAV_ITEMS_HIDDEN_CLASSNAME );
				showMoreMenuItemEl.classList.remove( NAV_ITEMS_HIDDEN_CLASSNAME );
			});

			// hide show more menu
			$ctrl.showMoreMenuVisible = false;
			$window.document.removeEventListener('click', forceCloseMenu);

			// reset initialization state
			initialized = false;
		};

		const refresh = () => {
			scrollerRect = getScrollerRect();
			navItemsOffsets = getNavItemsOffsets();

			const itemsToHide = (subNavListItems || []).filter((el, index) => {
				el.classList.remove( NAV_ITEMS_HIDDEN_CLASSNAME );

				const rect = navItemsOffsets[index];
				const showMoreMenuItemEl = showMoreMenuNavItems[index];
				const fitInScrollingViewport = (rect.left + rect.width - scrollerRect.left <= scrollerRect.width) &&
					(rect.top === scrollerRect.top);

				if( fitInScrollingViewport ) {
					showMoreMenuItemEl.classList.add( NAV_ITEMS_HIDDEN_CLASSNAME );
				} else {
					el.classList.add( NAV_ITEMS_HIDDEN_CLASSNAME );
					showMoreMenuItemEl.classList.remove( NAV_ITEMS_HIDDEN_CLASSNAME );
				}

				return !fitInScrollingViewport;
			});

			// show show-more button if we are hiding at least one button
			$timeout(() => $ctrl.showMoreBtnRequired = itemsToHide.length > 0);
		};

		// calculate $element's (alfaromeo-mini-nav-side-toggle) width
		const getScrollerRect = () => {
			const rect = subNavMenu.getBoundingClientRect();
			return {
				left: rect.left,
				top: rect.top,
				width: rect.width
			};
		};

		// calculate each items width and left offset
		const getNavItemsOffsets = () => {
			// if there is no items in navigation
			if( !subNavListItems || subNavListItems.length === 0 ) return null;

			return subNavListItems.map((el) => {
				const rect = el.getBoundingClientRect();
				return {
					left: rect.left,
					top: rect.top,
					width: rect.width
				};
			});
		};
    }
})();
