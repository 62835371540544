(function () {
	angular
		.module('fca.buyOnlineApplyCredit')
		.directive('fcaBuyOnlineApplyCredit', FcaBuyOnlineApplyCredit);

	function FcaBuyOnlineApplyCredit() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				leadRefNumber: '@',
				vin: '@',
				year: '@',
				model: '@',
				trim: '@',
				lang: '@',
				brandCode: '@',
				pdid: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineApplyCreditCtrl
		};

		function FcaBuyOnlineApplyCreditCtrl($scope,
			$rootScope,
			$document,
			$timeout,
			$element,
			$sce,
			$http,
			externalConfigLoader,
			fcaBuyOnlineApplyCreditService,
			fcaBuyOnlineService,
			fcaScrollToElementService,
			cookieLocation,
			gtmAnalytics) {
			'ngInject';

			let $ctrl = this;
			$ctrl.leadRefNumber = null;
			$ctrl.showIframe = false;
			$ctrl.iFrameUrl = null;
			$ctrl.iframeHeight = 0;

			$ctrl.location = {
				province: 'ON'
			};

			$scope.primaryApplicantFirstName = '';
			$ctrl.submitConfirmation = false;

			$ctrl.$onInit = () => {

				// Initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

				let vin = $ctrl.vin;

				let leadRefNumber = fcaBuyOnlineApplyCreditService.getLeadReferenceNumber(vin);
				if (leadRefNumber != null) {
					$ctrl.leadRefNumber = leadRefNumber;
				}

				if ($ctrl.brandCode == "undefined" || $ctrl.brandCode == null) {
					$ctrl.brandCode = FCA_SITES_CONFIG.name;
				}

			};

			$ctrl.$postLink = () => {
				if (window.addEventListener) {
					window.addEventListener('message', $ctrl.gotPDMessage);
				} else {
					window.attachEvent("onmessage", $ctrl.gotPDMessage);
				}
			}

			$ctrl.gotPDMessage = (evt) => {
				/* convert message string to JSON */
				let messageString = evt.data;
				let messageJSON;
				let page_code_active = window.BRANDS_GA.pagetype;

				try {
					messageJSON = JSON.parse(messageString);
				} catch (e) {
					// We do nothing here, this method is meant to be used for data coming from Dealertrack, but catches more event than only those from DealerTrack
				}
				/* set iframe height based on height received in message */
				if (messageJSON) {
					let DRSWidgetHeight = messageJSON.DRSWidgetHeight;
					let creditIframe = $element.find('iframe')[0];
					if (DRSWidgetHeight !== undefined && DRSWidgetHeight !== null) {
						$timeout(() => {
							$ctrl.iframeHeight = DRSWidgetHeight;
						});
					}
					/* scroll page to top of iframe based on trigger received in message */
					if (messageJSON.messageType == 'scroll') {
						fcaScrollToElementService.scrollToElement($element, 0);
					}
					/* Get lead reference number, and set it into the local storage */
					let leadReferenceNumber = messageJSON.LeadReferenceNumber;

					if (leadReferenceNumber !== undefined && leadReferenceNumber !== null) {
						fcaBuyOnlineApplyCreditService.setLeadReferenceNumber($ctrl.vin, leadReferenceNumber);

						$ctrl.submitConfirmation = true;
						let leadData;
						// try to get firstName from sessionStorage
						if (sessionStorage.getItem("Lead")) {
							leadData = JSON.parse(sessionStorage.getItem("Lead"));

							if (leadData.PrimaryApplicant.FirstName) {
								$ctrl.primaryApplicantFirstName = leadData.PrimaryApplicant.FirstName;
							}
						} else {
							// fallback to firstname input value if it exist
							let firstnameInput = document.querySelector('#firstname');
							if (firstnameInput && firstnameInput.value) {
								$ctrl.primaryApplicantFirstName = document.querySelector('#firstname').value;
							}
						}

						gtmAnalytics.trackEvent('event', {
							category: 'App-Suite-' + page_code_active,
							label: 'apply_credit_completed'
						});
					}
				}
			};

			$ctrl.setiFrameUrl = () => {
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				let provinceCode = $ctrl.location.province;
				let prod;
				let term;
				let residualValue;
				let leaseMileage;
				let salePrice;
				let tradein;
				let firstname;
				let lastname;
				let phonenumber;
				let email;
				let zipcode;

				$ctrl.location = cookieLocation.getLocation() || $ctrl.location;

				if (checkout.financeMode === 'finance') {
					prod = "LOAN";
					term = checkout.financeTerm;
					salePrice = checkout.financeNet;
				} else if (checkout.financeMode === 'lease') {
					prod = "LEASE";
					term = checkout.leaseTerm;
					residualValue = checkout.leaseResidualValue;
					leaseMileage = checkout.leaseMileage;
					salePrice = checkout.leaseNet;
				}

				if (checkout.tradeInValue) {
					tradein = checkout.tradeInValue - (checkout.tradeOwed || 0);
				}

				if (checkout.personalInfo) {
					firstname = checkout.personalInfo.firstname;
					lastname = checkout.personalInfo.lastname;
					zipcode = checkout.personalInfo.postalCode;

					if (checkout.contactMethod === 'EMAIL') {
						email = checkout.personalInfo.email;
					} else if (checkout.contactMethod === 'PHONE') {
						phonenumber = checkout.personalInfo.phone;
					}
				}

				if ($ctrl.brandCode === undefined) {
					$ctrl.brandCode = FCA_SITES_CONFIG.name;
				}

				var postData = {
					"vin": $ctrl.vin,
					"firstname": firstname,
					"lastname": lastname,
					"phone": phonenumber,
					"email": email,
					"confirmationemail": email,
					"province": provinceCode,
					"zip": zipcode,
					"cond": "N",
					"cpo": "N",
					"modelnum": "",
					"yr": $ctrl.year,
					"model": $ctrl.model,
					"trim": $ctrl.trim,
					"msrp": checkout.msrp,
					"prod": prod,
					"term": term,
					"lang": $ctrl.lang,
					"cashdown": checkout.downPayment,
					"tradein": tradein,
					"excltax": "Y",
					"yearlykms": leaseMileage,
					"pdid": $ctrl.pdid,
					"cp": salePrice,
					"residualval": residualValue,
					"brandCode": $ctrl.brandCode
				};

				$http({
					url: "/api/financedriver/getUrl",
					method: "POST",
					data: JSON.stringify(postData),
					contentType : "application/json"
				})
				.then( response => {
					$ctrl.iframeUrl = $sce.trustAsResourceUrl(response.data);
				}).catch(function(errorResponse) {});

				$ctrl.showIframe = true;
				$ctrl.showLoader = true;
			}
		}
	}
})();
