// TBD if this is better as a component
(function() {
	angular
		.module('fca.commonDirectives')
		.directive('fcaProvinceSelector', fcaProvinceSelector);

	function fcaProvinceSelector() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				buttonLabel: '@'
			},
			controllerAs: 'provinceSelector',
			controller: FcaProvinceSelectorController
		};

		function FcaProvinceSelectorController(
			$window,
			$scope,
			$timeout,
			$rootScope,
			userLocation,
			gtmAnalytics,
			cookieLocation,
			defaultProvinceService,
			urlRegionMapping,
			geolocatorRegionCookieService,
			$location) {
			'ngInject';


			this.$onInit = () => {
				// Watching location change in fca-userLocation.service
				$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
					this.buttonLabel = label;
				});

				// Timeout is necessary
				$timeout(() => {
					let locationFromCookie = cookieLocation.getLocation();
					let provinceCode = locationFromCookie ? locationFromCookie.province : 'ON'; // use ontario if there's no location cookie
					if (window.FCA_SITES_CONFIG.location[provinceCode]) {
						let locationLabel = window.FCA_SITES_CONFIG.location[provinceCode].desc;
						this.buttonLabel = locationLabel;
					}
				});
			};

			/**
			 * @ngdoc method
			 * @name changeLocation
			 * @propertyOf fca.layout.regionalOffers:FcaRegionSelectorController
			 * @description Changes the location by calling fca-userLocation.service
			 */
			this.changeLocation = ($event, changeLocation = true) => {
				$event.preventDefault();

				let newRegion = $event.currentTarget.getAttribute('data-region');
				let newProvince = $event.currentTarget.getAttribute('data-province');
				let newRegionObject = {};

				let category = $event.currentTarget.getAttribute('data-analytics-category');
				let label = $event.currentTarget.getAttribute('data-analytics-label');

				if (newRegion != undefined || newRegion != '') {
					// newRegionObject = {'region': newRegion.toLowerCase()};

					if(changeLocation) {
						newRegionObject = {'region': `${newProvince},${newRegion.toLowerCase()}`};
						userLocation.changeLocation(newRegionObject);
					}

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label
					});
				}
				this.changeOffersLocation(newProvince);
			};
			
			this.changeOffersLocation = (province) => {
				if (window.location.href.indexOf('offers') > -1) {
					if (province && province !== cookieLocation.getLocation().province) {
						defaultProvinceService.updateBasedOnProvince(province);
						let defaultProvince = defaultProvinceService.getProvince(province);
						let urlRegionReplace = urlRegionMapping.getUrlByRegion(defaultProvince.region);
						let currentRegionUrl = urlRegionMapping.getUrlByRegion(geolocatorRegionCookieService.get().region);
						//if (window.location.href.indexOf("/offres/") != -1 || window.location.href.indexOf("/offers/") != -1 ) return;
						let href = $window.location.href.replace(currentRegionUrl, urlRegionReplace);
						$window.location.href = href;
						if (currentRegionUrl === urlRegionReplace) {
							window.location.reload();
						}
					}
				}
			}
		}
	}
})();
