(function() {
	angular
		.module('fca.uiPanels')
		.filter('businessHour', function($filter) {
			'ngInject';
			// Application language
			let language = window.FCA_SITES_CONFIG.language;
			
			return function(date, local) {
				let l = (local === undefined) ? language : local;
				let format = (l === 'fr') ? 'H\'h\' mm' : 'h:mm a';
				return $filter('date')(new Date(date), format);
			};
		});
})();
