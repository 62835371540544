/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.packageSelector')
        .component('buildAndPricePackageSelector', {
            controller: BuildAndPricePackageSelector,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/package-selector/package-selector.html',
            bindings: {
                outputType: '@',
                selectedModelYearId: '@',
                initialAcode: '@',
                selectedProvince: '@',
                selectedBrand: '@',
                selectedSubBrand: '@',
                selectedNameplate: '@',
                initialYear: '@',
                nameplateName: '@',
                trimName: '@',
                location: '<',
                sectionTitle: '@',
                hasClassStrategy: '@'
            }
        });

    function BuildAndPricePackageSelector($http, $scope, $rootScope, $location, $element, $filter, $translate, trimService, configService, fcaGeolocator, userAccountService, fcaUserIdPersistentService, gtmAnalytics) {
        'ngInject';

        const OPTIONS_TYPES = 5;

        let $ctrl = this;

        $ctrl.language = $('html').attr('lang');

        $ctrl.jellyUrl = "";

        $ctrl.availableTrims = [];

        $ctrl.selectedTrim = null;

        $ctrl.navigatedFromMiniBP = false;

        $ctrl.affiliateMode = false;

        $ctrl.initialLoadCalled = false;

        //Need to keep the previous trim to be able to maintain a user previous package selector
        $ctrl.previousSelectedTrimAcode = "";

        $ctrl.selectedTrimGroup = null;

        $ctrl.trimData = {};

        $ctrl.drivetrain = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.boxLength = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.cabType = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.rearWheel = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.wheelbase = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.engine = {
            isActive: false,
            currentValue: null,
            list: []
        };

        $ctrl.packages = [];

        $ctrl.selectedPackage = {};

        $ctrl.viewableTrimGroupOptions = false;

        $ctrl.tempColor = '';

        $ctrl.initialTrim = true;

        $ctrl.hashParameters = {};

        $ctrl.provinceCode = null;

        $ctrl.previousProvinceCode = null;

        $ctrl.isOneClassStrategy = false;

        $ctrl.$onInit = () => {

            $ctrl.isOneClassStrategy = $ctrl.hasClassStrategy === 'true';

            // Init affiliateId & affiliateMode
            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();
            $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

            $ctrl.hashParameters = getHashParameters();
            // Are we in iframe mode and Embedded inside a dealer site?
            $ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';

            $ctrl.nameplateAndTrimName = $ctrl.nameplateName + ' ' + ($ctrl.trimName ? $ctrl.trimName.toUpperCase() : '');

            $ctrl.checkNavigationFrom();

            $ctrl.applyHashParameters();

            if (userAccountService.isLibraryAvailable()) {
                $rootScope.$on('ua:libraryInitialized', () => {
                    //first of all, load details saved configuration if a builtVahicleId is given in hash parameters
                    if ($ctrl.hashParameters.builtVehicleId && UA.isUserLoggedIn()) {
                        //get details for the saved configuration
                        UA.getBuiltVehicles()
                            .then((builtVehicleList) => {
                                configService.builtVehicleSaved =
                                    builtVehicleList.find((vehicle) => vehicle.id.toString() === $ctrl.hashParameters.builtVehicleId);
                            })
                            .finally(() => $ctrl.initializeBuildAndPrice($ctrl.initialAcode));
                    } else {
                        $ctrl.initializeBuildAndPrice($ctrl.initialAcode);
                    }
                });
            } else {
                $ctrl.initializeBuildAndPrice($ctrl.initialAcode);
            }

            /* Listen if the location is changed outside this component */
            let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
            $scope.$on(eventUpdateName, $ctrl.locationUpdated);

            // When the trim service has completed the request, setup the possible packages and the default selected trim.
            $scope.$on('trim-service:trim-data-updated', (event, data) => {
                let trimGroups = data.trimData.trimGroups;

                // Make a copy to ensure we don't keep the same data across multiple package selectors
                // such as in the trim group page
                $ctrl.trimData = angular.copy(data.trimData);

                $ctrl.setInitialTrimGroupOptions($ctrl.trimData);
                $ctrl.setTrimAndTrimGroupObject($ctrl.trimData);
            });

            // Listen for requests made by the jellies to sent them the selectedTrim
            $scope.$on('jelly:requesting-trim', () => {
                $rootScope.$broadcast('packages-selector:sending-jelly-trim-data',
                    { selectedTrim: $ctrl.selectedTrim, selectedTrimGroup: $ctrl.selectedTrimGroup, initialAcode: $ctrl.initialAcode });
            });

            $ctrl.previousProvinceCode = $ctrl.selectedProvince;
        };

        /**
         * Called whenever the location changes
         * Both geolocator.status.component and geolocator.component emit the same location change, this means we can (and will) receive it twice.
         */
        $ctrl.locationUpdated = (event, data) => {
            if (data
                && data.length > 0
                && data[0].province
                && data[0].province !== $ctrl.selectedProvince) {
                $ctrl.selectedProvince = data[0].province;  // if we really get a new province, update it here
                $ctrl.provinceCodeUpdated();
            }
        };

        /**
         * This is called whenever a model is changed (from the outside).
         *
         * The $ctrl.selectedProvince is bound to $mainCtrl.location.province (which means it will get changed if the main controller updates itself)
         */
        $ctrl.$onChanges = data => {
            // retrieve popular options whenever both location and selectedNameplate are changed (this includes the initialization)
            if ($ctrl.location && $ctrl.selectedNameplate) {
                configService.getPopularOptions($ctrl.location.longitude, $ctrl.location.latitude, $ctrl.selectedNameplate);
            }
            // make sure we only trigger on actual changes (not on the initialization)
            if (data
                && data.selectedProvince
                && !data.selectedProvince.isFirstChange()) {
                $ctrl.provinceCodeUpdated();
            }
        };

        /**
         * Handle province code update
         * Trigger a re-initialization of build and price if the province changes.
         */
        $ctrl.provinceCodeUpdated = () => {
            // make sure we don't do anything if it's not required
            if ($ctrl.previousProvinceCode !== $ctrl.selectedProvince) {
                $ctrl.previousProvinceCode = $ctrl.selectedProvince;

                if ($ctrl.selectedTrim) {
                    $ctrl.previousSelectedTrimAcode = angular.copy($ctrl.selectedTrim.acode);
                    trimService.setTrimData("");
                    $ctrl.initializeBuildAndPrice($ctrl.selectedTrim.acode);
                }
            }
        };

        $ctrl.initializeBuildAndPrice = (acode) => {
            let longitude, latitude;

            if ($ctrl.location) {
                longitude = $ctrl.location.longitude;
                latitude = $ctrl.location.latitude;
            }
            if ($ctrl.outputType === 'getConfig') {
                configService.initializeConfig(acode, $ctrl.language, $ctrl.selectedModelYearId, $ctrl.selectedNameplate,
                    $ctrl.initialYear, $ctrl.selectedBrand, $ctrl.selectedSubBrand, $ctrl.selectedProvince, longitude, latitude);
            }

            // Ask the trimService to obtain the list of trims for the selected TrimGroup
            trimService.getTrimData($ctrl.language, $ctrl.selectedBrand, $ctrl.selectedNameplate, $ctrl.initialYear, $ctrl.selectedModelYearId, $ctrl.provinceCode);
        };

        // Setting initial trimGroupOptions and packages information
        // upon initial call completion
        $ctrl.setInitialTrimGroupOptions = (data) => {
            $ctrl.drivetrain.list = data.drivetrains;
            $ctrl.boxLength.list = data.boxLengths;
            $ctrl.cabType.list = data.cabTypes;
            $ctrl.rearWheel.list = data.rearWheels;
            $ctrl.wheelbase.list = data.wheelbases;
        };

        $ctrl.formatPrice = (unformattedPrice) => {
            const price = `+ ${$filter('fcaCurrencyWithDollarSign')(Math.abs(unformattedPrice))}`;
            return (unformattedPrice > 0 ?
                $translate.instant('build-and-price.package-selector.upgrade-price', { price }) :
                $translate.instant('build-and-price.package-selector.discount-price', { price }));
        }

        // Since the page only receives an acode, we need to find to which trim group it belongs to.
        // This will let us know what options are available to a user such as drivetrain, box length etc.
        // We also store the first trim as a base to know which options and packages to display initially.
        $ctrl.setTrimAndTrimGroupObject = (data) => {
            let selectedTrimGroupCode = "";

            let trimGroups = data.trimGroups;

            // Use either the initial aCode or the config aCode depending on
            // If we are in the trim group page or the build and price page.
            let acode = $ctrl.initialAcode;
            if ($ctrl.outputType === 'getConfig') {
                acode = configService.getAcode();
                //in case of a saved configuration, we use the acode of the saved configuration
                if (configService.builtVehicleSaved) {
                    acode = configService.builtVehicleSaved.acode;
                }
            }

            for (let trimGroupIndex = 0; trimGroupIndex < trimGroups.length; trimGroupIndex++) {
                let trimGroup = trimGroups[trimGroupIndex];
                for (let trimIndex = 0; trimIndex < trimGroup.trims.length; trimIndex++) {
                    if (trimGroup.trims[trimIndex].acode === acode) {
                        $ctrl.selectedTrimGroup = trimGroup;
                        $ctrl.availableTrims = trimGroup.trims;
                        $ctrl.selectedTrim = $ctrl.availableTrims[trimIndex];
                        configService.updateSelectedTrim($ctrl.selectedTrim);
                    }
                }
            }

            if ($ctrl.availableTrims.length > 0) {
                $ctrl.setPossibleTrimGroupOptions($ctrl.availableTrims);
                $ctrl.getPackagesInfoFromDictionary();
            } else {
                console.warn('The acode ' + $ctrl.initialAcode + ' hasn\'t matched with any of the following trimGroups');
                console.warn(trimGroups);
            }
        };

        // With a list of availabletrims in hand, obtain the possible trimGroup options a user could make
        // for drivetrain, boxlength etc. Only display them if more than one choice is available for a category.
        $ctrl.setPossibleTrimGroupOptions = () => {
            let selectableDrivetrainList = [];
            let selectableBoxLengthList = [];
            let selectableCabTypeList = [];
            let selectableRearWheelList = [];
            let selectableWheelbaseList = [];

            $ctrl.availableTrims.forEach(function (trim) {
                if (selectableDrivetrainList.indexOf(trim.drivetrain) === -1) {
                    selectableDrivetrainList.push(trim.drivetrain);
                }
                if (selectableBoxLengthList.indexOf(trim.boxLength) === -1) {
                    selectableBoxLengthList.push(trim.boxLength);
                }
                if (selectableCabTypeList.indexOf(trim.cabType) === -1) {
                    selectableCabTypeList.push(trim.cabType);
                }
                if (selectableRearWheelList.indexOf(trim.rearWheel) === -1) {
                    selectableRearWheelList.push(trim.rearWheel);
                }
                if (selectableWheelbaseList.indexOf(trim.wheelbase) === -1) {
                    selectableWheelbaseList.push(trim.wheelbase);
                }
            });

            $ctrl.drivetrain.isActive = $ctrl.activateTrimGroupListAndOptions($ctrl.drivetrain.list, selectableDrivetrainList);
            $ctrl.boxLength.isActive = $ctrl.activateTrimGroupListAndOptions($ctrl.boxLength.list, selectableBoxLengthList);
            $ctrl.cabType.isActive = $ctrl.activateTrimGroupListAndOptions($ctrl.cabType.list, selectableCabTypeList);
            $ctrl.rearWheel.isActive = $ctrl.activateTrimGroupListAndOptions($ctrl.rearWheel.list, selectableRearWheelList);
            $ctrl.wheelbase.isActive = $ctrl.activateTrimGroupListAndOptions($ctrl.wheelbase.list, selectableWheelbaseList);

            if ($ctrl.drivetrain.isActive || $ctrl.boxLength.isActive || $ctrl.cabType.isActive || $ctrl.rearWheel.isActive || $ctrl.wheelbase.isActive) {
                $ctrl.viewableTrimGroupOptions = true;
            }

            // Broadcast that a new trim was selected
            $ctrl.broadcastUpdatedTrim($ctrl.selectedTrim);
        };

        // Activate the trimGroupOtions that a user can see on the interface.
        // Returns if the list should be present on the interface.
        $ctrl.activateTrimGroupListAndOptions = (optionList, selectableList) => {
            if (selectableList.length > 0) {
                selectableList.forEach(function (selectable) {
                    optionList.forEach(function (option, optionIndex) {
                        if (optionList[optionIndex]['id'] === selectable) {
                            optionList[optionIndex].isActive = true;
                        }
                    });
                });
            }
            // Make the list viewable only if more than one item is selectable.
            return selectableList.length > 1;
        };

        // Since the packages only have a code,
        // enhance them with their engine and transmission information.
        $ctrl.getPackagesInfoFromDictionary = () => {
            $ctrl.packages = [];
            let tPackages = $ctrl.selectedTrim.packages;
            let dPackages = trimService.getDictionary().packages;

            for (let index = 0; index < tPackages.length; index++) {
                for (let dIndex = 0; dIndex < dPackages.length; dIndex++) {
                    if (tPackages[index].code === dPackages[dIndex].id) {
                        let packageObject = tPackages[index];
                        packageObject.engineShortDesc = dPackages[dIndex].engineShortDesc;

                        //Special language correction since french data come with added 'Moteur' and 'Transimission' prefixes
                        //TODO: Backend will implement it on their end, remove this conditionnal once they are ready.
                        if ($ctrl.language === 'en') {
                            packageObject.engine = dPackages[dIndex].engine;
                            packageObject.transmission = dPackages[dIndex].transmission;
                            // Transmission type is necessary to display the automatic or manual icon
                            packageObject.transmissionType = $ctrl.getTransmissionType(packageObject.transmission, 'manual');
                        } else {
                            packageObject.engine = dPackages[dIndex].engine.replace('Moteur : ', '');
                            packageObject.transmission = dPackages[dIndex].transmission.replace('Transmission : ', '');
                            packageObject.transmissionType = $ctrl.getTransmissionType(packageObject.transmission, 'manuelle');
                        }
                        $ctrl.packages.push(packageObject);
                        break;
                    }
                }
            }

            // Only update the engine filter if the current acode is different than the previously selected one
            // Use case is when the location gets changed on the user side
            if ($ctrl.previousSelectedTrimAcode !== $ctrl.selectedTrim.acode) {
                $ctrl.updateEngineList();
            }
        };

        $ctrl.getTransmissionType = (transmission, manual) => {
            // Sometimes manual can be Manual so we lowercase all the text
            transmission = transmission.toLowerCase();
            if (transmission.includes(manual)) {
                return 'manual';
            } else {
                return 'automatic';
            }
        };

        $ctrl.updateEngineList = () => {
            let engineList = [];
            let packages = $ctrl.selectedTrim.packages;
            for (let i = 0; i < packages.length; i++) {
                let currentEngine = packages[i].engineShortDesc;
                if (engineList.indexOf(currentEngine) === -1) {
                    engineList.push(currentEngine);
                }
            }

            $ctrl.engine.list = engineList;
            $ctrl.engine.currentValue = 'all';
            $ctrl.engine.isActive = $ctrl.engine.list.length > 1;
        };

        $ctrl.updateSelectedEngine = (engine) => {
            $ctrl.engine.currentValue = engine;
        };

        // Update the selected trim when a user changes a selected option
        // Ensure that his choice is always considered to locate a trim.
        $ctrl.updateSelectedTrim = (optionType, optionValue) => {
            if ($ctrl.availableTrims.length > 0) {
                switch (optionType) {
                    case 'drivetrain': {
                        $ctrl.locateTrimBasedOnOptionChoice(
                            'drivetrain',
                            optionValue,
                            $ctrl.selectedTrim.boxLength,
                            $ctrl.selectedTrim.cabType,
                            $ctrl.selectedTrim.rearWheel,
                            $ctrl.selectedTrim.wheelbase
                        );
                        break;
                    }
                    case 'boxLength': {
                        $ctrl.locateTrimBasedOnOptionChoice(
                            'boxLength',
                            $ctrl.selectedTrim.drivetrain,
                            optionValue,
                            $ctrl.selectedTrim.cabType,
                            $ctrl.selectedTrim.rearWheel,
                            $ctrl.selectedTrim.wheelbase
                        );
                        break;
                    }
                    case 'cabType': {
                        $ctrl.locateTrimBasedOnOptionChoice(
                            'cabType',
                            $ctrl.selectedTrim.drivetrain,
                            $ctrl.selectedTrim.boxLength,
                            optionValue,
                            $ctrl.selectedTrim.rearWheel,
                            $ctrl.selectedTrim.wheelbase
                        );
                        break;
                    }
                    case 'rearWheel': {
                        $ctrl.locateTrimBasedOnOptionChoice(
                            'rearWheel',
                            $ctrl.selectedTrim.drivetrain,
                            $ctrl.selectedTrim.boxLength,
                            $ctrl.selectedTrim.cabType,
                            optionValue,
                            $ctrl.selectedTrim.wheelbase
                        );
                        break;
                    }
                    case 'wheelbase': {
                        $ctrl.locateTrimBasedOnOptionChoice(
                            'wheelbase',
                            $ctrl.selectedTrim.drivetrain,
                            $ctrl.selectedTrim.boxLength,
                            $ctrl.selectedTrim.cabType,
                            $ctrl.selectedTrim.rearWheel,
                            optionValue
                        );
                        break;
                    }
                }

                const drivetrain = $ctrl.drivetrain.list.find(d => d.id === optionValue);
                const cabtype = $ctrl.cabType.list.find(d => d.id === optionValue);
                const boxlength = $ctrl.boxLength.list.find(d => d.id === optionValue);

                if (drivetrain) {
                    gtmAnalytics.trackEvent('event', {
                        category: `App-Suite-${window.FCA_SITES_CONFIG.pageCode}`,
                        label:  `trim-vs-drop-choose config-${optionType}-${drivetrain.description}`
                    });
                }else if (cabtype) {
                    gtmAnalytics.trackEvent('event', {
                        category: `App-Suite-${window.FCA_SITES_CONFIG.pageCode}`,
                        label:  `trim-vs-drop-choose config-${optionType}-${cabtype.description}`
                    });
                }else if (boxlength) {
                    gtmAnalytics.trackEvent('event', {
                        category: `App-Suite-${window.FCA_SITES_CONFIG.pageCode}`,
                        label:  `trim-vs-drop-choose config-${optionType}-${boxlength.description}`
                    });
                }
            }
        };

        // Find a matching trim based on a set of options. Ensure that the user choice is always maintained in the search.
        $ctrl.locateTrimBasedOnOptionChoice = (importantOption, drivetrain, boxLength, cabType, rearWheel, wheelbase) => {

            let matchFound = false;

            for (let acceptableOptionsTypes = OPTIONS_TYPES; acceptableOptionsTypes > 0; acceptableOptionsTypes--) {
                for (let trimIndex = 0; trimIndex < $ctrl.availableTrims.length; trimIndex++) {
                    let currentTrim = $ctrl.availableTrims[trimIndex];
                    let matchingOptionsNumber = 0;
                    if (drivetrain === undefined || currentTrim.drivetrain === drivetrain) {
                        matchingOptionsNumber++;
                    }
                    if (boxLength === undefined || currentTrim.boxLength === boxLength) {
                        matchingOptionsNumber++;
                    }
                    if (cabType === undefined || currentTrim.cabType === cabType) {
                        matchingOptionsNumber++;
                    }
                    if (rearWheel === undefined || currentTrim.rearWheel === rearWheel) {
                        matchingOptionsNumber++;
                    }
                    if (wheelbase === undefined || currentTrim.wheelbase === wheelbase) {
                        matchingOptionsNumber++;
                    }
                    if (matchingOptionsNumber === acceptableOptionsTypes) {
                        switch (importantOption) {
                            case 'drivetrain': {
                                if (drivetrain === currentTrim.drivetrain) {
                                    matchFound = true;
                                    $ctrl.selectedTrim = currentTrim;
                                }
                                break;
                            }
                            case 'boxLength': {
                                if (boxLength === currentTrim.boxLength) {
                                    matchFound = true;
                                    $ctrl.selectedTrim = currentTrim;
                                }
                                break;
                            }
                            case 'cabType': {
                                if (cabType === currentTrim.cabType) {
                                    matchFound = true;
                                    $ctrl.selectedTrim = currentTrim;
                                }
                                break;
                            }
                            case 'rearWheel': {
                                if (rearWheel === currentTrim.rearWheel) {
                                    matchFound = true;
                                    $ctrl.selectedTrim = currentTrim;
                                }
                                break;
                            }
                            case 'wheelbase': {
                                if (wheelbase === currentTrim.wheelbase) {
                                    matchFound = true;
                                    $ctrl.selectedTrim = currentTrim;
                                }
                                break;
                            }
                        }
                        if (matchFound) {
                            break;
                        }

                    }
                }

                if (matchFound) {
                    // With a matching trim found, 'fetch' the packages information from the dictionary
                    $ctrl.getPackagesInfoFromDictionary();
                    $ctrl.broadcastUpdatedTrim($ctrl.selectedTrim);
                    break;
                }
            }
        };

        // Get and set configuration in case we restore a session stored vehicle.
        $ctrl.getSessionStoredConfiguration = (savedConfigs) => {
            let params = {
                acode: savedConfigs.acode,
                language: $ctrl.language,
                packageCode: savedConfigs.package || savedConfigs.packageCode,
                provinceCode: $ctrl.selectedProvince,
                modelYearId: savedConfigs.modelYearId,
                nameplateCode: savedConfigs.nameplateCode || savedConfigs.nameplate,
                year: savedConfigs.year,
                brand: savedConfigs.brand || savedConfigs.brandCode,
                scratchSave: savedConfigs.scratchSave
            };
            $location.hash('');

            if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                params[affiliateIdName] = $ctrl.affiliateId;
            }

            const campaignCode = sessionStorage.getItem('campaignCode');
            if (campaignCode && campaignCode !== null) {
                params['campaignCode'] = campaignCode;
            }

            if ($ctrl.inIframeMode && !!$ctrl.hashParameters.dealerId) {
                // Dealer code parameter added to request if we are in iframe mode, it gives us the admin fee attribute on the config call
                params['dealerCode'] = $ctrl.hashParameters.dealerId;
            }

            $http.post('/api/buildandprice/config', params).then((r) => {
                this.setConfigData(r.data);
            });

        }

        // Get and set configuration in case of a saved configuration is displayed
        $ctrl.postCarConfiguration = (packageCode, scratchsave) => {
            let params = {
                acode: configService.getAcode(),
                language: $ctrl.language,
                packageCode: packageCode,
                provinceCode: $ctrl.selectedProvince,
                modelYearId: $ctrl.selectedModelYearId,
                nameplateCode: $ctrl.selectedNameplate,
                year: $ctrl.initialYear,
                brand: $ctrl.selectedBrand,
                scratchSave: scratchsave
            };

            if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                params[affiliateIdName] = $ctrl.affiliateId;
            }

            const campaignCode = sessionStorage.getItem('campaignCode');
            if (campaignCode && campaignCode !== null) {
                params['campaignCode'] = campaignCode;
            }

            if ($ctrl.inIframeMode && !!$ctrl.hashParameters.dealerId) {
                // Dealer code parameter added to request if we are in iframe mode, it gives us the admin fee attribute on the config call
                params['dealerCode'] = $ctrl.hashParameters.dealerId;
            }

            $http.post('/api/buildandprice/config', params).then((r) => {
                this.setConfigData(r.data);
            });
        };

        // Upon a new package selection, ask for all details related to the package/car chosen
        $ctrl.getCarConfiguration = (pkg) => {

            // Use either the initial aCode or the config aCode depending on
            // If we are in the trim group page or the build and price page.
            let acode = $ctrl.initialAcode;
            if ($ctrl.outputType === 'getConfig') {
                acode = configService.getAcode();
            }
            let apiUrl = '/api/buildandprice/config' +
                '?acode=' + acode +
                '&language=' + $ctrl.language +
                '&packageCode=' + pkg.code +
                '&provinceCode=' + $ctrl.selectedProvince +
                '&modelYearId=' + $ctrl.selectedModelYearId +
                '&nameplateCode=' + $ctrl.selectedNameplate +
                '&year=' + $ctrl.initialYear +
                '&brand=' + $ctrl.selectedBrand;


            if ($ctrl.affiliateMode) {
                apiUrl += getAffiliateIdUrlParam();
            }

            const campaignCode = sessionStorage.getItem('campaignCode');
            if (campaignCode && campaignCode !== null) {
                apiUrl += `&campaignCode=${campaignCode}`;
            }

            if ($ctrl.inIframeMode && !!$ctrl.hashParameters.dealerId) {
                // Dealer code parameter added to request if we are in iframe mode, it gives us the admin fee attribute on the config call
                apiUrl += `&dealerCode=${$ctrl.hashParameters.dealerId}`;
            }

            $http.get(apiUrl).then((r) => {
                this.setConfigData(r.data);
            });
        };

        /**
         * This is called once we get the configuration data (after get config has happened)
         */
        this.setConfigData = (configData) => {

            // Colors & wheels goes first
            $ctrl.sendOptionsToSelector('exteriorColor', configData.exteriorColor);
            $ctrl.sendOptionsToSelector('wheel', configData.wheel);
            $ctrl.sendOptionsToSelector('interiorColor', configData.interiorColor);
            $ctrl.sendOptionsToSelector('roof', configData.roofColor);
            $ctrl.sendOptionsToSelector('seat', configData.seatColor);

            // Temporarily setting the paint to the first color
            $ctrl.tempColor = configData.exteriorColor.categories[1].options[0].code.substr(0, 3);

            // Other options
            $ctrl.sendOptionsToSelector('entertainmentTechnology', configData.entertainmentTechnology);
            $ctrl.sendOptionsToSelector('exterior', configData.exterior);
            $ctrl.sendOptionsToSelector('group', configData.group);
            $ctrl.sendOptionsToSelector('interior', configData.interior);
            $ctrl.sendOptionsToSelector('mechanical', configData.mechanical);
            $ctrl.sendOptionsToSelector('safetySecurity', configData.safetySecurity);

            // Set the scratch save in the config service.
            // This string must be sent with every call to select option.
            configService.setScratchSave(configData.scratchSave);
            $rootScope.$broadcast('scratch-save-updated:scratch-save', { scratch: configService.getScratchSave() });

            // Confirm the package selector and broadcast it to others
            // GetConfig call always returns a single package which is the selected one
            const configPackage = getSelectedPackage(configData);
            $ctrl.selectedPackage = $ctrl.selectedTrim.packages.find(pkg => {
                return pkg.code === configPackage.code;
            });

            $ctrl.isFlipped = false;
            configService.updateSelectedPackage($ctrl.selectedPackage);
            // update the selected trim
            configService.updateSelectedTrim($ctrl.selectedTrim);

            // Send config to be parsed
            configService.parseConfiguration(configData);

            $rootScope.$broadcast('packages-selector:selected-package-updated', { package: $ctrl.selectedPackage });

            //Pricing details
            $rootScope.$broadcast('packages-selector:pricing', { pricing: configData.pricing });

            // Done sending options
            $rootScope.$broadcast('packages-selector:finished-sending-options', configService.getCategoriesObjects());
        };

        // return the package flag as selected (state S or G) in config data;
        // if none is found, return the first package
        let getSelectedPackage = (configData) => {
            let configPackage;
            if(configData.enginePackage.categories[0].options.length > 1) {
                configPackage = configData.enginePackage.categories[0].options.find(option => {
                    return configService.checkIfOptionShouldBeSelected(option);
                });
            }

            if(!configPackage || !configPackage.code) {
                configPackage = configData.enginePackage.categories[0].options[0];
            }
            return configPackage;
        };

        // Broadcast the initConfig information to the options blocks.
        // Ensure that the scratch save is sent.
        $ctrl.sendOptionsToSelector = (type, options) => {
            if (options.categories != null && options.categories.length > 0) {
                $rootScope.$broadcast('trim-selector:options-' + type + '-updated', { options: options });
            }
        };

        // Returns the list of options available for a package.
        // Based on UX, add the engine and transmission at the top of the list.
        $ctrl.showIncludedOptions = (pkg) => {
            $ctrl.loadIncludedOptions(pkg);

            $ctrl.setFlipped(pkg, true);

            this.manageFaceFocus(pkg);
        };

        $ctrl.hideIncludedOptions = (pkg) => {
            $ctrl.setFlipped(pkg, false);

            this.manageFaceFocus(pkg);
        };

        $ctrl.setFlipped = (pkg, flipped) => {
            pkg.flipped = flipped;
            $ctrl.isFlipped = flipped;
        }

        $ctrl.loadIncludedOptions = (pkg) => {
            if (!pkg.includedOptions) {
                let apiUrl = '/api/buildandprice/' + $ctrl.language +
                    '/brand/' + $ctrl.selectedBrand + '/nameplate/' + $ctrl.selectedNameplate +
                    '/year/' + $ctrl.initialYear + '/modelyear/' + $ctrl.selectedModelYearId +
                    '/trims/' + configService.getAcode() + '/packages/' + pkg.code + '/content';
                $http.get(apiUrl).then((r) => {
                    let data = r.data;
                    pkg.includedOptions = [];
                    for (let index = 0; index < data.length; index++) {
                        pkg.includedOptions.push(data[index]);
                    }
                });
            }
        }

        $ctrl.broadcastUpdatedTrim = (selectedTrim) => {
            $ctrl.drivetrain.currentValue = selectedTrim.drivetrain;
            $ctrl.boxLength.currentValue = selectedTrim.boxLength;
            $ctrl.cabType.currentValue = selectedTrim.cabType;
            $ctrl.rearWheel.currentValue = selectedTrim.rearWheel;
            $ctrl.wheelbase.currentValue = selectedTrim.wheelbase;

            //If the component is located in build and price / else we are in the trimGroup page.
            if ($ctrl.outputType === 'getConfig') {
                configService.setDriveTrain($ctrl.drivetrain);
                this.loadCarConfiguration();
            } else {
                // Reveal the trimGroup accordion if one of the list is active.
                // Update it's build and price link with an new acode
                // Update the jelly if the selectedTrim differs from the base acode
                // Update the starting at price (MSRP)
                if ($ctrl.viewableTrimGroupOptions) {
                    let $trimGroupArticle = $element.parents('.js-commonNameplate');
                    let $accordeonItem = $trimGroupArticle.find('.commonNameplate-accordeonItem-is-hidden');
                    let $buildBtn = $trimGroupArticle.find('.commonNameplate-buildBtn');
                    let $buildLink = $trimGroupArticle.find('.trim-group-jelly-link');

                    $accordeonItem.removeClass('commonNameplate-accordeonItem-is-hidden');
                    const newHref = $ctrl.updateUrlsWithAcode($ctrl.selectedTrim.acode);
                    $buildLink.attr('href', newHref);
                    $buildBtn.attr('href', newHref);
                }
            }
        };

        $ctrl.updateUrlsWithAcode = (acode) => {
            let result = $location.path() + `/${acode}`;

            const queryParameters = $location.search();
            if (queryParameters) {
                result += $ctrl.addListPropertiesToUrl("", queryParameters, '?');
            }

            if ($ctrl.hashParameters) {
                result += $ctrl.addListPropertiesToUrl("", $ctrl.hashParameters, '#');
            }

            return result;
        };

        $ctrl.addListPropertiesToUrl = (URL, list, prefixCharacter = '') => {
            const keys = Object.keys(list).filter(value => {
                return list.hasOwnProperty(value);
            });
            keys.forEach((key, index) => {
                URL += `${index === 0 ? prefixCharacter : '&'}${key}=${list[key]}`;
            });
            return URL;
        };

        // Broadcast that the user has changed his package
        // Only change it and call gitConfig if the package code is different than the previous one.
        $ctrl.selectPackage = (pkg) => {
            $ctrl.getCarConfiguration(pkg);
            configService.resetSavedConfiguration();

            gtmAnalytics.trackEvent('event', {
                category: `App-Suite-${window.FCA_SITES_CONFIG.pageCode}`,
                label:  `build-price-package-select-${pkg.description}`
            });
        };

        $ctrl.updateUrls = () => {
            let acode = $ctrl.selectedTrim.acode;
            let $desktopMainNav = $('.main-nav-lang-toggle');
            let $mobileMainNav = $('.mobile-nav-lang-toggle a');
            let languageTogglePath = $desktopMainNav.attr('href');
            let languageTogglePathSliced = null;
            if (languageTogglePath) {
                languageTogglePathSliced = languageTogglePath.slice(0, languageTogglePath.lastIndexOf("/"));
            }

            let path = $location.path();
            let pathSliced = path.slice(0, path.lastIndexOf("/"));

            //Update the path in the url
            $location.path(pathSliced + "/" + acode);

            if ($desktopMainNav) {
                //Update the desktop language toggle url
                $desktopMainNav.attr("href", languageTogglePathSliced + "/" + acode);
            }
            if ($mobileMainNav) {
                //Update the mobile language toggle url
                $mobileMainNav.attr("href", languageTogglePathSliced + "/" + acode);
            }
        };

        $ctrl.applyHashParameters = () => {
            $ctrl.hashParameters = getHashParameters();
        };

        $ctrl.checkNavigationFrom = () => {
            // Check if user navigate from mini build-and-price
            let result = {};
            $location.hash().split('&').forEach(item => {
                result[item.split('=')[0]] = decodeURIComponent(item.split('=')[1]);
                $ctrl.navigatedFromMiniBP = result['mini-bp-config'] == "true";
            });
        }

        this.loadCarConfiguration = () => {
            //If the output is for build and price, set the config acode to the new acode.
            configService.setAcode($ctrl.selectedTrim.acode);
            const savedConfigs = configService.getSessionStoredConfiguration();

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const buildId = urlParams.get('buildId') || null;

            if ($ctrl.navigatedFromMiniBP && savedConfigs) {
                // we know the user come from mini build-and-price
                $ctrl.postCarConfiguration('', savedConfigs.scratchSave);
            } else if (configService.builtVehicleSaved) {
                // load configuration from a previous saved configuration
                //retrieve the right package
                let pkg = $ctrl.selectedTrim.packages.find(
                    (element) => element.code === configService.builtVehicleSaved.packageCode);
                //get by post the car configuration for the corresponding scratch save
                $ctrl.postCarConfiguration(pkg.code, configService.builtVehicleSaved.scratchsave);

            } else if (configService.hashParameters.scratchsave) {
                //get by post the car configuration corresponding to the scratchsave in hash parameters
                $ctrl.postCarConfiguration('', configService.getScratchSave());

            } else if (savedConfigs && !$ctrl.initialLoadCalled && !buildId) {
                $ctrl.getSessionStoredConfiguration(savedConfigs);

            } else if (buildId) {
                const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;
                const pid = persistentUid && `?pid=${persistentUid}`;

                const apiUrl = `/data/custom-order/${$ctrl.language}/${buildId}${pid}`;
                $http.get(apiUrl).then(response => {
                    $ctrl.getSessionStoredConfiguration(response.data)
                })
                .catch(function (e) {
                    window.location.replace(window.location.pathname);
                });

            } else {
                //default behavior, we load base configuration for the selected package

                // Only update the selected package if the current acode is different than the previously selected one
                // Use case is when the location gets changed on the user side
                if ($ctrl.previousSelectedTrimAcode !== $ctrl.selectedTrim.acode) {
                    $ctrl.selectedPackage = $ctrl.selectedTrim.packages[0];
                }
                $ctrl.getCarConfiguration($ctrl.selectedPackage);
            }
            $ctrl.initialLoadCalled = true;

            //With a new trim selected, update the necessary urls
            $ctrl.updateUrls();
            $rootScope.$broadcast('packages-selector:selected-trim-updated',
                { selectedTrim: $ctrl.selectedTrim, selectedTrimGroup: $ctrl.selectedTrimGroup, initialAcode: $ctrl.initialAcode, trimHasOptions: $ctrl.viewableTrimGroupOptions });
        };

        this.manageFaceFocus = (pkg) => {
            const buttonClicked = $(event.target);
            let focussedFace;

            if (pkg.flipped) {
                focussedFace = buttonClicked.parents('.commonTile-frontface').siblings('.commonTile-backface');
            } else {
                focussedFace = buttonClicked.parents('.commonTile-backface').siblings('.commonTile-frontface');
            }

            focussedFace.focus();
        }

    }
})();
