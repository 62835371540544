(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaOfferboxSlider', fcaOfferboxSlider);

	function fcaOfferboxSlider() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$offerboxSlider',
			controller: FcaOfferboxSlickCtrl
		};

		function FcaOfferboxSlickCtrl($rootScope, $element, $scope, $timeout) {
			'ngInject';

			const $ctrl = this;

			$ctrl.slickSlider = $element.find('#fca-offerbox-slick');
			$ctrl.sliderInitilaized = false;
			$ctrl.activeSlide = 1;
			$ctrl.activeIndex = 0;

			this.initSlickCarousel = () => {
				$ctrl.slickSlider.slick({
					autoplay: false,
					dots: false,
					infinite: false,
					arrows: false,
					mobileFirst: true,
					centerMode: true,
					slidesToShow: 1,
					adaptiveHeight: false,
				});
				$('#bentoBox-slide-title-0').addClass('active');
			};

			$ctrl.$onInit = () => {
				$(window).on('load resize orientationchange', function() {
					if ($(window).width() > 768) {
						if ($ctrl.slickSlider.hasClass('slick-initialized')) {
							$ctrl.slickSlider.slick('unslick');
						}
						$('.bentoBox-offer-tabs-header .bentoBox-offertabs-slide-title').removeClass('active');

					} else {
						if (!$ctrl.slickSlider.hasClass('slick-initialized')) {
							$ctrl.initSlickCarousel();
						}
					}
				});
			};

			this.updateSlideTitle = () => {
				$('.bentoBox-offertabs-slide-title').removeClass('active');
				$('#bentoBox-slide-title-'+$ctrl.activeIndex).addClass('active');
			}

			this.updateSliderCount = (currentSlide, nextSlide) => {
				$timeout(() => {
					$ctrl.activeSlide = nextSlide + 1;
					$ctrl.activeIndex = nextSlide;
					$ctrl.updateSlideTitle();
				});
			};

			$ctrl.slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				$ctrl.updateSliderCount(currentSlide, nextSlide);
			});

			$ctrl.getSliderCount = () => {
				let count = $ctrl.slickSlider.find('.tab-item:not(.slick-cloned)').length;
				return count;
			};
    }
	}
})();