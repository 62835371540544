/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.gmap')
        .provider('fcaGmapIcon', Provider);

    /**
     * @ngdoc service
     * @module fca.gmap
     * @name fcaGmapIconProvider
     * @description
     * Service fcaGmapIcon provider.
     * Provide colors icons for Google map.
     * @requires fca.externalConfigLoader.externalConfigLoaderProvider
     */
    function Provider() {
        'ngInject';

        /**
         * List of colors
         * @type {Object}
         */
        const COLORS = {
            alfaromeo: '#BA0816',
            dodge: '#d50000',
            ramtruck: '#880d00',
            jeep: '#000000',
            chrysler: '#436fb0',
            fiat: '#B11E36'
        };

        const markerScale = {
            small: .45,
            default: .65,
            large: .8,
        }

        // Get brand name from external config.
        let brand = window.FCA_SITES_CONFIG.name;

        // Set color for active/hover icon
        let defaultActiveColor = '#FF1907';
        if (brand && Object.keys(COLORS).indexOf(brand) !== -1) {
            defaultActiveColor = COLORS[brand];
        }

        /* eslint-disable max-len */
        // Set the path for SVG Icon
        let defaults = {
            path: 'M21.3,0.3C9.8,0.3,0.6,9.5,0.6,21c0,9.4,13.9,27,18.9,33.1c1,1.2,2.6,1.2,3.6,0C28.1,48,42,30.3,42,21 C42,9.5,32.7,0.3,21.3,0.3z',
            singlePinPath: 'M21.282,0.254C9.84,0.254,0.565,9.529,0.565,20.972 c0,9.364,13.875,27.026,18.915,33.134c0.984,1.193,2.62,1.193,3.605,0C28.125,47.998,42,30.336,42,20.972 C42,9.529,32.724,0.254,21.282,0.254z M21.282,28.811c-4.639,0-8.399-3.76-8.399-8.399c0-4.639,3.76-8.399,8.399-8.399 c4.639,0,8.399,3.76,8.399,8.399C29.681,25.051,25.921,28.811,21.282,28.811z',
            fillColor: '#666666',
            fillOpacity: 1,
            anchor: null,
            strokeWeight: 0,
            scale: markerScale.default
        };
        /* eslint-enable max-len */


        /* Config */
        this.cfg = {
            fillColor: '#666666',
            fillColorActive: defaultActiveColor
        };

        /**
         * @ngdoc service
         * @module fca.gmap
         * @name fcaGmapIcon
         * @description
         * Service fcaGmapIcon to get icons shape/colors for google map icons.
         */
        this.$get = () => {
            return {
                /**
                 * @ngdoc method
                 * @name getIcon
                 * @methodOf fca.gmap.service:fcaGmapIcon
                 * @param  {Object} google Google map namespace
                 * @param  {boolean} singlePin Use a variant of the marker icon
                 * @param  {string} [markerSize='default'] The marker icon size
                 * @return {Object} Properties for icon marker SVG
                 */
                getIcon: (google, singlePin, markerSize = 'default') => {
                    if(singlePin) {
                        defaults.path = defaults.singlePinPath;
                    }
                    return ng.extend({}, defaults, {
                        fillColor: this.cfg.fillColor,
                        anchor: new google.maps.Point(21, 55),
                        labelOrigin: new google.maps.Point(21.5, 19),
                        scale: markerScale[markerSize]
                    });
                },
                /**
                 * @ngdoc method
                 * @name getIconHover
                 * @methodOf fca.gmap.service:fcaGmapIcon
                 * @param  {Object} google Google map namespace
                 * @param  {boolean} singlePin Use a variant of the marker icon
                 * @param  {string} [markerSize='large'] The hover marker icon size
                 * @return {Object} Properties for icon marker SVG hover state
                 */
                getIconHover: (google, singlePin, markerSize = 'large') => {
                    if(singlePin) {
                        defaults.path = defaults.singlePinPath;
                    }
                    return ng.extend({}, defaults, {
                        fillColor: this.cfg.fillColorActive,
                        anchor: new google.maps.Point(21, 55),
                        labelOrigin: new google.maps.Point(21.5, 19),
                        scale: markerScale[markerSize]
                    });
                },
                /**
                 * @ngdoc method
                 * @name getIconActive
                 * @methodOf fca.gmap.service:fcaGmapIcon
                 * @param  {Object} google Google map namespace
                 * @param  {boolean} singlePin Use a variant of the marker icon
                 * @param  {string} [markerSize='large'] The active marker icon size
                 * @return {Object} Properties for icon marker SVG active/selected state
                 */
                getIconActive: (google, singlePin, markerSize = 'large') => {
                    if(singlePin) {
                        defaults.path = defaults.singlePinPath;
                    }
                    return ng.extend({}, defaults, {
                        fillColor: this.cfg.fillColorActive,
                        anchor: new google.maps.Point(21, 55),
                        labelOrigin: new google.maps.Point(21.5, 19),
                        scale: markerScale[markerSize]
                    });
                }
            };
        };
    }
})(angular);
