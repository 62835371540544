/* eslint-disable indent */
(function () {
    angular
    .module('fca.buildAndPrice.directives')
    .directive('fcaOptionPricing', fcaOptionPricing);

    function fcaOptionPricing(calculatorService, configService) {
        return {
            restrict: 'E',
            templateUrl: '/build-and-price/option-pricing/option-pricing.html',
            scope: {
                optionPrice: '=',
                optionSelected: '=',
                className: '='
            },
            controllerAs: '$ctrl',
            controller: fcaOptionPricingController
        };

        function fcaOptionPricingController() {
            'ngInject';

            const $ctrl = this;
            const paymentFrequencies = [
                    {
                        key: 'shop-by-payments.weekly'
                    },
                    {
                        key: 'shop-by-payments.bi-weekly'
                    },
                    {
                        key: 'shop-by-payments.semi-monthly'
                    },
                    {
                        key: 'shop-by-payments.monthly'
                    },
                ];

            $ctrl.hasFrequencyLabel = false;
            $ctrl.frequencyLabel = '';

            $ctrl.calculatePreferredPrice = optionNetAmount => {
                let preferredPrice = optionNetAmount;

                storeAffiliateId();
                const affiliateMode = isAffiliateMode();

                if (hasDecimalFormat(optionNetAmount)
                    && configService.paymentType !== 'net'
                    && configService.paymentType !== 'cash'
                    && !affiliateMode) {

                    if ((configService.paymentType === 'finance' || configService.paymentType === 'altFinance')
                        && configService.defaultFinancePayment) {

                        preferredPrice = calculateOptionPayment(optionNetAmount, configService.defaultFinancePayment, configService.defaultNetAmount);
                        $ctrl.hasFrequencyLabel = true;

                    } else if ((configService.paymentType === 'lease' || configService.paymentType === 'altLease')
                        && configService.defaultLeasePayment) {

                        preferredPrice = calculateOptionPayment(optionNetAmount, configService.defaultLeasePayment, configService.defaultNetAmount);
                        $ctrl.hasFrequencyLabel = true;
                    } else {
                        $ctrl.hasFrequencyLabel = false;
                    }

                    if ($ctrl.hasFrequencyLabel) {
                        const optionPricingElem = document.querySelectorAll('.C_BP-alert-window-content-option .option-pricing-item');
                        $ctrl.frequencyLabel = paymentFrequencies[configService.paymentFrequency].key;
                        for (let i = 0; i < optionPricingElem.length; i++) {
                            optionPricingElem[i].classList.add('-has-frequency-label');
                        }
                    }

                } else {
                    $ctrl.hasFrequencyLabel = false;
                }

                return preferredPrice;
            };
        }

        function hasDecimalFormat(netAmount) {
            return netAmount.match(/\d+(\.\d{1,2})?/);
        }

        function calculateOptionPayment(optionNetAmount, payment, netAmount) {
            let optionPayment = Math.ceil((Math.abs(optionNetAmount) * payment) / netAmount);
            if(optionNetAmount < 0) {
                optionPayment *= -1;
            }
            return optionPayment;
        }
    }

})();