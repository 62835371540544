// Directive to wrap slick carousel
// Allows for callbacks and other arguments need to be passed to slick to be defined in
// this directive's controller instead of higher up in application

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselSlidingNav', fcaSlickCarouselSlidingNav);

	function fcaSlickCarouselSlidingNav() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				autoplayDuration: '@'
			},
			controllerAs: 'carouselSlidingNav',
			controller: FcaSlickCarouselSlidingNavController,
			require: {
				carouselPanoCtrl: '^fcaSlickCarouselPano'
			}
		};

		function FcaSlickCarouselSlidingNavController($scope, $element, $window, $timeout, $rootScope) {
			'ngInject';
			/* eslint max-len: ["error", 130] */

			$element.addClass('slick-theme-sliding-nav');
			this.slider = $element;

			this.activeIndicatorEle = angular.element('<div class="active-underline"></div>');
			this.activeIndicatorEleWidth = 0;
			this.nbSlides;
			this.playing = false;
			this.tooltipOpen = false;
			this.currentTooltip = false;
			this.videoCurrentlyPlaying = false;
			this.usingPauseButton = false;
			this.videoPausedByButton = false;
			this.progressStart = null;
			this.progressCurrentTimestamp = null;
			this.progressElapsed = 0;
			this.progressDelay = 100; // compensate for css transition
			this.animationFrameReference;

			this.onInit = () => {
				// init active indicator
				this.nbSlides = $element.find('.slick-dots > *').length;
				$element.find('.slick-dots').append(this.activeIndicatorEle);
				this.activeIndicatorEleWidth = 1/this.nbSlides;

				// init progress
				this.displayProgress();
				this.play();

				this.addPauseEventListener($element);

				let _this = this;
				$scope.$on('carousel.pause', (event, args) => {
					_this.videoCurrentlyPlaying = true;
					_this.pause();
				});

				$scope.$on('carousel.resume', (event, args) => {
					if (!this.videoPausedByButton) {
						_this.videoCurrentlyPlaying = false;
						_this.play();
					}
				});

				$scope.$on('carousel.pauseWithRestartedProgress', (event, args) => {
					this.updateProgress(0);
					_this.videoCurrentlyPlaying = true;
					_this.pause();
				});

				$scope.$on('carousel.resumeWithRestartedProgress', (event, args) => {
					this.updateProgress(0);
					_this.videoCurrentlyPlaying = false;
					_this.play();
				});

				angular.element('.slick-dots > li').on('click', $element, () => {
					$scope.$emit('carousel.resume');
				});

				angular.element(document).on('click', $element, (e) => {
					let $legalParent = angular.element(e.target).parents('.legal-tooltip').parents('.slick-slide');

					if($legalParent.length > 0) {
						let clickedTooltip = angular.element(e.target).parents('fca-legal-tooltip').data('tippy-popper');

						if(this.tooltipOpen == true) {
							if(this.currentTooltip !== clickedTooltip) {
								this.currentTooltip = clickedTooltip;
							} else {
								this.tooltipOpen = false;
								this.currentTooltip = false;
								$scope.$emit('carousel.resume');
							}
						} else {
							this.tooltipOpen = true;
							this.currentTooltip = clickedTooltip;
							$scope.$emit('carousel.pause');
						}
					} else {
						if(this.tooltipOpen == true) {
							this.tooltipOpen = false;
							this.currentTooltip = false;
							$scope.$emit('carousel.resume');
						}
					}
				});

				// Stop slider if not in viewport
				if (window.BRANDS_GA && window.BRANDS_GA.pagetype && window.BRANDS_GA.pagetype === 'vlp') {
					$rootScope.$on('stackedIsInitialized', () => {
						this.isInViewport($element.parents('.stacked-module'));
					});
				} else {
					this.isInViewport($element);
				}
			};

			this.addPauseEventListener = (slickElement) => {
				const slickPauseButton = slickElement.find('.pano-pause-button');
				if (slickPauseButton) {
					this.usingPauseButton = true;
					slickPauseButton.on('click', this.pauseResumeCarousel);
				}
			};

			this.pauseResumeCarousel = () => {
				this.videoPausedByButton = !this.videoPausedByButton;

				if (!this.videoPausedByButton) {
					this.resetProgress();
					this.play();
				} else {
					this.pause();
					this.resetProgress();
				}

				$scope.$apply(function() {});
			};

			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				this.setActiveIndicator(nextSlide);
				this.resetProgress();
				if (!this.playing) {
					this.displayProgress(0);
				}
			};

			this.afterChange = (event, slick, currentSlide, nextSlide) => {

			};

			this.setActiveIndicator = (index) => {
				// move active indicator to slide index position
				this.activeIndicatorEle.css('left', (index/this.nbSlides * 100) + '%');
			};

			this.play = () => {
				if(!this.videoCurrentlyPlaying && (!this.usingPauseButton || !this.videoPausedByButton)) {
					this.progressStart = null;
					this.playing = true;
					this.animationFrameReference = $window.requestAnimationFrame(this.updateProgress);
				}
			};

			this.pause = () => {
				this.playing = false;
				this.progressElapsed = this.progressCurrentTimestamp - this.progressStart;
				$window.cancelAnimationFrame(this.animationFrameReference);
			};

			this.updateProgress = (timestamp) => {
				if (!this.progressStart) {
					if (this.progressCurrentTimestamp) {
						this.progressStart = timestamp - this.progressElapsed;
					}
				}

				this.progressCurrentTimestamp = timestamp;
				let step = this.progressCurrentTimestamp - this.progressStart;

				if (this.playing) {
					if (step < this.progressDelay) step = 0;
					let progress = step / (this.autoplayDuration - this.progressDelay);

					if (progress > 1) progress = 1;

					this.displayProgress(progress);

					if (progress >= 1) {
						// reached end of slide duration
						this.gotoNextSlide();
					}
					this.animationFrameReference = $window.requestAnimationFrame(this.updateProgress);
				}
			};

			this.displayProgress = (progress = 0) => {
				if(progress === 0) {
					this.activeIndicatorEle.removeClass('active');
				} else {
					this.activeIndicatorEle.addClass('active');
				}
				this.activeIndicatorEle.css('transform', 'scaleX('+(progress*this.activeIndicatorEleWidth)+')');
			};

			this.resetProgress = () => {
				this.displayProgress(0);
				this.progressStart = null;
				this.progressElapsed = 0;
			};

			this.gotoNextSlide = () => {
				if (this.carouselPanoCtrl) {
					$timeout(() => {
						this.carouselPanoCtrl.activeSlide++;
						if (this.carouselPanoCtrl.activeSlide >= $element.find('.slick-slide').length) {
							this.carouselPanoCtrl.activeSlide = 0;
						}
					});
				}
			};

			this.isInViewport = (el) => {
				new Waypoint.Inview({
					element: el[0],
					enter: function() {
						if(this.videoCurrentlyPlaying) return;
						$scope.$emit('carousel.resume');
					},
					exited: function() {
						$scope.$emit('carousel.pause');
					}
				});
			};
		}
	}
})();
