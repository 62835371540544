(function() {
	'use strict';

	angular
		.module('fca.uiPanels')
		.directive('hoursFormat', HoursFormat);
	/**
	 * @ngdoc directive
	 * @name fca.uiPanels.directive:hoursFormat
	 * @requires $filter
	 * @description Format/concat business openAt/closeAt hours in a string
	 * @example
	 * <pre>
	 * <div hours-format data-open-at="2017-04-19 11:30:00.0" data-close-at="2017-04-19 21:00:00.0"></div>
	 * // Output markup EN
	 * <div>11:30 AM - 09:00 PM</div>
	 * // Output markup FR
	 * <div>11:30 - 21:00</div>
	 * </pre>
	 */
	function HoursFormat($filter) {
		'ngInject';

		let filter = $filter('businessHour');

		return {
			restrict: 'A',
			scope: {
				openAt: '@',
				closeAt: '@'
			},
			link: function($scope, elt, attrs) {
				let openAt = filter($scope.openAt);
				let closeAt = filter($scope.closeAt);
				let hours = `${openAt} - ${closeAt}`;
				elt.html(hours);
			}
		};
	}
})();
