(function(ng) {
	angular
		.module('fca.daaContactDealer')
		.component('daaContactDealer', {
			templateUrl: '/components/contact-dealer/contact-dealer.html',
			bindings: {
				selectedDealerCode: '<?',
				selectedVehicle: '<?',
				selectedRegion: '<',
				contactReason: '<?',
				selectedBrandCode: '<?',
				showFullDealerSelectFlow: '<',
				showDealerExtendedInfo: '<',
				allowChangeDealer: '<',
				allowChangeNameplate: '<',
				allowChangeNameplateModel: '<',
				allowShotgunQuoteFeature: '<?',
				cancelFn: '&?',
				submitFn: '&?',
				analyticsCategory: '@',
				analyticsId: '@'
			},
			controller: daaContactDealerController
		});

	function daaContactDealerController(
		$rootScope,
		ContactDealer,
		externalConfigLoader,
		gtmAnalytics) {
		'ngInject';

		let brand = externalConfigLoader.loadConfig('DAA', 'BRAND').getConfig('name');

		// let deregisterSelectReason;
		let deregisterSelectDealer;
		let deregisterSelectNameplate;
		let deregisterAddShotgun;
		let deregisterRemoveShotgun;

		this.formSubmitDone = false;
		this.isLoading = false;
		this.allowShotgunQuote = true;

		this.$onInit = () => {
			let loc = this.selectedRegion;
			let dealerRequest;

			// Override brand to load nameplates
			if (this.selectedBrandCode !== undefined) {
				brand = this.selectedBrandCode;
			}

			/* Hack to get the dealer distance when on the Dealer Details page */
			if (this.selectedDealerCode) {
				// if there's a dealer code get the dealer object
				dealerRequest = ContactDealer.getDealerObject(brand, this.selectedDealerCode);
			} else {
				// otherwise get the closest dealer to the user
				dealerRequest = ContactDealer.getOtherDealers(brand, [], loc.latitude, loc.longitude);
			}

			if (this.allowShotgunQuoteFeature !== undefined) {
				if (!this.allowShotgunQuoteFeature) {
					this.allowShotgunQuote = false;
				}
			}

			// Load the Brand's nameplate definitions
			let undefinedVin = angular.isUndefined(this.selectedVehicle);

			if (!undefinedVin && ng.isString(this.selectedVehicle)) {
				ContactDealer.getVehicle(brand, this.selectedVehicle).then((vehicle) => {
					this.nameplates = [];
					this.selectedVehicle = ng.extend(this.selectedVehicle, vehicle);
				});
			} else {
				ContactDealer.getNameplates(brand).then((nameplatesArr) => {
					let numberOfNameplatesToEnrich = nameplatesArr.length;

					nameplatesArr.forEach((nameplate) => {
						/*
						 * Enrich the raw data to what UI needs
						 * retrieve and add modelYears to nameplate object
						 */
						if (nameplate.jellies.length === 0) {
							numberOfNameplatesToEnrich--;

							if (numberOfNameplatesToEnrich === 0) {
								this.nameplates = nameplatesArr;
							}
						} else {
							ContactDealer.getNameplateTrims(brand, nameplate.code)
								.then((modelYears) => {
									nameplate.modelYears = modelYears;

									numberOfNameplatesToEnrich--;
									// very loose check to see if all the nameplates have had their trimgroups (i.e. modelYears) added
									if (numberOfNameplatesToEnrich === 0) {
										/*
										 * At this point we have the full definition of every nameplate and its trimgroups for the brand
										 */
										this.nameplates = nameplatesArr;
									}
								});
						}
					});
				});
			}

			/* Initialize the component */
			dealerRequest.then((dealerObj) => {
				/* Hack to get the dealer distance when initializing form on the Dealer Details page */
				if (this.selectedDealerCode) {
					this.selectedDealer = dealerObj;
				} else {
					this.selectedDealer = dealerObj[0];
				}

				/*
				 * Mapping of form models compared to their appsuite name that gets submitted
				 *
				 * selectedDealer.code -> retailerId (dealer code)
				 * shotgunLeads -> retailerIds (dealer codes when multiple)
				 * brand -> vehBrands (chrysler | dodge| jeep | ram)
				 * leadType (ie: daa)
				 * contactReason -> postType (ie: get_a_quote (when shotgun) | contact_a_dealer (when not shotgun))
				 * firstName -> formFirstName
				 * lastName -> formLastName
				 * email -> formEmail
				 * phone -> formPhone
				 * sms -> formPhone
				 * contactMethod -> formPrefContact ('Day Phone' | 'Home Email' | 'Work Email' | 'SMS' | 'N/A')
				 * comments -> customerComments
				 *
				 * # selected vehicle info
				 * namePlateSelected (Promaster 3500 Cutaway)
				 * modelSelected (Low Roof 159. WB)
				 * vin
				*/

				// this.formData.postType = this.contactReason;
				// deregisterSelectReason = $rootScope.$on('contact-dealer.select-contact-reason', (event, newReason) => { // eslint-disable-line max-len
				// 	this.formData.postType = newReason;
				// });

				this.selectedDealersList = this.selectedDealer.code;
				this.selectedDealerCodes = buildSelectedDealersArr.apply(this);
				deregisterSelectDealer = $rootScope.$on('contact-dealer.select-dealer', (event, newDealer) => { // eslint-disable-line max-len
					this.selectedDealersList = newDealer.code;
					this.selectedDealerCodes = buildSelectedDealersArr.apply(this);
					this.selectedDealer = newDealer;
				});

				this.shotgunDealersList = [];
				deregisterAddShotgun = $rootScope.$on('contact-dealer.add-shotgun-dealer', (event, newDealer) => { // eslint-disable-line max-len
					this.shotgunDealersList.push(newDealer.code);
					this.selectedDealerCodes = buildSelectedDealersArr.apply(this);
				});

				deregisterRemoveShotgun = $rootScope.$on('contact-dealer.remove-shotgun-dealer', (event, newDealer) => { // eslint-disable-line max-len
					let shotgunLeads = this.shotgunDealersList;
					shotgunLeads.splice(shotgunLeads.indexOf(newDealer.code), 1);
					this.selectedDealerCodes = buildSelectedDealersArr.apply(this);
				});

				addSelectedVehicleFormData(this.selectedVehicle, this.formData);
				deregisterSelectNameplate = $rootScope.$on('contact-dealer.select-nameplate', (event, newNameplate) => { // eslint-disable-line max-len
					addSelectedVehicleFormData(newNameplate, this.formData);
				});
			});
		};

		this.$onDestroy = () => {
			// deregisterSelectReason();
			deregisterSelectDealer();
			deregisterSelectNameplate();
			deregisterAddShotgun();
			deregisterRemoveShotgun();
		};

		/* Setup object to hold field ngModels for submitting form */
		this.formData = {
			formPrefContact: 'email'
		};

		this.submitForm = (event) => {
			if (event) {
				event.preventDefault();
			}

			this.formSubmitted = true;
			this.isLoading = true;

			if (!this.contactForm.$valid) {
				this.isLoading = false;
				return;
			}

			/* Normalize nameplate name */
			if (this.formData.namePlateSelected) {
				let {namePlateSelected} = this.formData;
				this.formData.namePlateSelected = namePlateSelected;
			}

			/* Add form data that always gets submitted */
			this.formData.leadType = 'daa';
			this.formData.brandSelected = brand;
			this.formData.vehBrands = 'ALL';
			// Set this information empty to send
			this.formData.formPostalCode = '';

			let numOfSelectedDealers = this.selectedDealerCodes.length;

			/* Set retailerId or retailerIds */
			this.formData.retailerId = null;
			this.formData.retailerIds = null;

			if (numOfSelectedDealers === 1) {
				this.formData.retailerId = this.selectedDealerCodes[0];
			} else if (numOfSelectedDealers > 1) {
				this.formData.retailerIds = this.selectedDealerCodes;
			}

			/* Set postType */
			this.formData.postType = '';

			if (numOfSelectedDealers === 1) {
				this.formData.postType = 'contact_a_dealer';
			} else if (numOfSelectedDealers > 1) {
				this.formData.postType = 'get_a_quote';
			}

			if (this.contactReason) {
				let {
					customerComments
				} = this.formData;
				this.formData.comments = `${this.contactReason}: ${customerComments}`;
			}

			ContactDealer.sendContact(this.formData).then((data) => {
				this.isLoading = false;

				if (data.errors) {
					// handle error?
					return;
				}

				if (data.success) {
					// add extra stuff for campaign tracker and send
					this.formData.isDAA = 1;
					ContactDealer.sendToCampaignTracker(this.formData);
					// switch to confirmation screen
					this.formSubmitDone = true;
					// update dialog title
					if (angular.isFunction(this.submitFn)) {
						this.submitFn();
					}
					// Tracking analytics
					this.analyticsTrackSubmit();
				}
			});
		};

		this.analyticsTrackSubmit = () => {
			let {
				formPrefContact,
				modelSelected,
				postType
			} = this.formData;

			let {
				year,
				code
			} = this.selectedVehicle;

			let opts = {
				formleadtype: 'request info',
				formcontactmethod: formPrefContact,
				formservicetype: this.contactReason,
				formspecificvehicle: 'no',
				formsubmit: 'send my request'
			};

			if (postType === 'get_a_quote') {
				angular.extend(opts, {
					formgaq: 'add dealer'
				});
			}

			if (year && year !== '') {
				angular.extend(opts, {
					formselectavehicle: code,
					pagemodelyear: year,
					pagemodelname: modelSelected
				});
			}

			gtmAnalytics.trackEvent('form', opts);
		};

		this.setContactReason = (reason) => {
			this.contactReason = reason;
		};

		this.cancel = (event) => {
			if (event) {
				event.preventDefault();
			}

			if (this.cancelFn) {
				this.cancelFn({
					$event: event
				});
			}
		};
	}

	function addSelectedVehicleFormData(nameplateObj, formData) {
		formData.namePlateSelected = '';
		formData.modelSelected = '';
		formData.vin = '';
		formData.modelYear = '';

		if (!nameplateObj) {
			return;
		}

		formData.namePlateSelected = nameplateObj.code;

		if (nameplateObj.modelYears && nameplateObj.modelYears.length > 0) {
			const trimgroup = nameplateObj.modelYears.find(modelYear => modelYear.year === nameplateObj.year);

			if (trimgroup) {
				const model = trimgroup.trims.find(trim => trim.name.en === nameplateObj.name.en);
				if (model) {
					formData.modelSelected = model.trimDesc.en;
				}
			}
		}

		if (nameplateObj.vin) {
			formData.vin = nameplateObj.vin;
		}

		if (nameplateObj.year) {
			formData.modelYear = nameplateObj.year;
		}
	}

	// Returns a string of comma-separated dealer codes. Example C1560,C3110
	function buildSelectedDealersArr() {
		let shotgunArr = this.shotgunDealersList ? this.shotgunDealersList : [];
		let arr = [this.selectedDealersList].concat(shotgunArr);

		return arr;
	}
})(angular);
