(function() {
	'use strict';

	angular
		.module('fca.hoursStatus')
		.service('hoursStatus', HoursStatusService);

	/**
	 * @ngdoc service
	 * @name fca.hoursStatus.service:hoursStatus
	 * @description Extract informations/status from dealer's business hours list
	 * @example
	 * <pre>
	 * ng
	 * 	.module('MyModule', ['fca.hoursStatus'])
	 * 	.controller('MyCtrl', (hoursStatus) => {
	 * 		let dealersHours = [
	 * 			{
	 * 				openAt: '@date',
	 * 				closeAt: '@date'
	 * 			},
	 * 			etc...
	 * 		];
	 *
	 * 		this.isOpenedNow = false;
	 * 		for(let i = 0, l dealersHours.length; i < l; i++) {
	 * 			let day = dealersHours[i];
	 * 			let isOpenedNow = hoursStatus.isOpenedNow(day.openAt, day.closeAt);
	 * 			if (isOpenedNow) {
	 * 				this.isOpenedNow = isOpenedNow;
	 * 				break;
	 * 			}
	 * 		}
	 * 	});
	 * </pre>
	 */
	function HoursStatusService() {
		'ngInject';

		/**
		 * @ngdoc method
		 * @name isOpenNow
		 * @methodOf fca.hoursStatus.service:hoursStatus
		 * @param  {String} pOpenAt  Open at value in date string with hours
		 * @param  {String} pCloseAt Close at value in date string with hours
		 * @return {Boolean}         True if the current date with time is between open/close values
		 */
		this.isOpenNow = (pOpenAt, pCloseAt) => {
			let isOpen = false;
			if (pOpenAt && pCloseAt) {
				let openAt = strToLocalDate(pOpenAt);
				let closeAt = strToLocalDate(pCloseAt);
				let now = new Date();
				isOpen = openAt < now && now < closeAt;
			}

			return isOpen;
		};

		/**
		 * @ngdoc method
		 * @name isToday
		 * @methodOf fca.hoursStatus.service:hoursStatus
		 * @param  {String} pStartHour [description]
		 * @return {Boolean}           True if the current date with time is in startDate/endDate same day
		 */
		this.isToday = (pStartHour) => {
			let isToday = false;
			if (pStartHour) {
				let startHourDate = strToLocalDate(pStartHour);
				let now = new Date();
				let days = [startHourDate.getDate()];
				let months = [startHourDate.getMonth()];
				let years = [startHourDate.getFullYear()];
				let day = now.getDate();
				let month = now.getMonth();
				let year = now.getFullYear();

				if (days.indexOf(day) !== -1 &&
					months.indexOf(month) !== -1 &&
					years.indexOf(year) !== -1) {
					isToday = true;
				}
			}

			return isToday;
		};

		this.strToLocalDate = strToLocalDate;

		function strToLocalDate(str) {
			let dateStr = str.replace(/\-[\d]{2}:[\d]{2}/, '');
			return new Date(dateStr);
		}
	}
})();
