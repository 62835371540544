(function() {
    'use strict';

    angular
        .module('fca.dealersPageList')
        .directive('scrollToSelected', Directive);

    /**
     * @ngdoc directive
     * @name fca.dealersPageList.directive:scrollToSelected
     * @restrict A
     * @description Listen changes to scroll root element to a specific child
     */
    function Directive() {
        'ngInject';

        return {
            link: linkFunction,
            restrict: 'A'
        };

        function linkFunction($scope, $element, $attrs) {
            if ($attrs.listEventReadyName) {
                $scope.$on($attrs.listEventReadyName, ($evt) => {
                    let $selected = $('.item-active-wrapper', $element);
                    scrollToElement($element, $('.active', $selected));
                });
            }

            $scope.$on('dealerLocator.event.onSelect', ($evt, selected) => {
                setTimeout(function() {
                    if (selected) {
                        let $selected = $('#list-item-' + selected.dealerId, 0);
                        scrollToElement($element, $selected);
                    }
                }, 300);
            });

            $scope.$on('dealerLocator.event.onResetData', ($evt, selected) => {
                scrollToElement($element, 0);
            });
        }

        function scrollToElement($element, $target) {
            $element.scrollTo($target, 300, {
                offset: {
                    left: 0,
                    top: 0
                }
            });
        }
    }
})();
