(function(ng) {
	'use strict';

	let tpl = '/geolocator/geolocator-region-status/geolocator-region-status.html';

	angular
		.module('fca.geolocator')
		.component('fcaGeolocatorRegionStatus', {
			templateUrl: tpl,
			controller: GeolocatorRegionStatus,
			controllerAs: '$ctrl',
			bindings: {
				brand: '@',
				skin: '@'
			}
		});

	function GeolocatorRegionStatus(
		geolocatorRegionCookieService,
		urlRegionMapping,
		regionMappingService,
		$location,
		$window) {
		'ngInject';

		// const _$ = $translate.instant;
		const REGION = geolocatorRegionCookieService.get().region;

		this.regionsList = [];

		/**
		 * @ngdoc property
		 * @name dialogModel
		 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
		 * @description Dialog model data to inject into modal
		 * @type {Object}
		 */
		this.dialogModel = {
			viewAllUrl: null,
			title: 'geolocation.set.location.title'
		};

		this.$onInit = () => {
			// let brand = _$(`brand.label.${this.brand}`);
			let brand = `brand.label.${this.brand}`;
			let url = $location.path();
			// this.label = _$(`geolocation.offers.invite.${REGION}`, {brand: brand});
			this.label = `geolocation.offers.invite.${REGION}`, {brand: brand};

			this.currentRegion = urlRegionMapping.getRegionByUrl(url);
			this.createRegionsArray();
		};

		this.setRegion = (data) => {
			if (data && ng.isString(data.region) && data.region !== REGION) {
				let urlRegionReplace = urlRegionMapping.getUrlByRegion(data.region);
				let currentRegionUrl = urlRegionMapping.getUrlByRegion(REGION);
				let href = $window.location.href.replace(currentRegionUrl, urlRegionReplace);
				$window.location.href = href;
			}
		};

		this.createRegionsArray = () => {
			let regions = regionMappingService.getRegions();
			let isCurrent;

			for (let i = 0; i < regions.length; i++) {
				isCurrent = this.currentRegion === regions[i].slug;

				this.regionsList.push({
					region: regions[i].slug,
					label: regions[i].region,
					currentRegion: isCurrent
				});
			}
		};
	}
})(angular);
