(function () {
	angular
		.module('fca.keypress')
		.service('fcaKeypress', FcaKeypressService);

	function FcaKeypressService() {
		'ngInject';

		let $service = this;

		const NUMERIC_KEY_MIN = 48;
		const NUMERIC_KEY_MAX = 57;
		const SPACE_KEY = 'Spacebar';

		/**
		 * Allow any numeric characters.
		 *
		 * @param event
		 */
		$service.allowNumbers = (event) => {
			if (event) {
				if (!$service.isNumeric(event)) {
					event.preventDefault();
				}
			}
		};

		$service.isNumeric = (event) => {
			let keyCode = (event.keyCode ? event.keyCode : 0);
			let result = !(keyCode < NUMERIC_KEY_MIN || keyCode > NUMERIC_KEY_MAX);
			return result;
		};

		$service.isSpace = (event) => {
			let result = event.key === " " || event.key === SPACE_KEY;
			return result;
		};
	}
})();
