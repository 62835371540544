(function () {
    angular
        .module('fca.uconnect')
        .directive('fcaUconnectNameplateSelector', FcaUconnectNameplateSelector);

    function FcaUconnectNameplateSelector() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                brandCode: '<?'
            },
            controller: FcaUconnectNameplateSelectorController,
            controllerAs: '$ctrl'
        };


        function FcaUconnectNameplateSelectorController($scope, $element, gtmAnalytics, $http) {
            'ngInject';

            const $ctrl = this;

            $ctrl.activeYear = '';
            $ctrl.nameplateCode = '';
            $ctrl.nameplates= [];
            $ctrl.versionTitle = '';
            $ctrl.versionCode = '';
            $ctrl.brandCode = '';
            $ctrl.functionalities = [];
            $ctrl.upgradeOptions = [];
            $ctrl.brandCode = '';
            $ctrl.language = window.FCA_SITES_CONFIG.language;


            $ctrl.$onInit = () => {

            };

            $ctrl.updateActiveYear = year => {
                $ctrl.activeYear = year;
                $ctrl.nameplateCode = '';
            };

            $ctrl.updateActiveNameplate = () => {
                $ctrl.nameplateCode = document.getElementById('nameplateSelector').value;
                gtmAnalytics.trackEvent('event', {
                    category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
                    label: 'body-nameplate-selector-model select - ' + $ctrl.nameplateCode
                });

            };

            $ctrl.updateVersion = (title, code, brandCode, selectedAppetizers) => {
                $ctrl.versionTitle = title;
                $ctrl.versionCode = code;
                $ctrl.brandCode = brandCode;

                $ctrl.getFunctionalitiesByVersion();
                $ctrl.getUpdateVersion();
            };

            $ctrl.getUpdateVersion = () => {                
                let apiUrl = `/api/uconnect/brand/${$ctrl.brandCode}/${$ctrl.activeYear}/${$ctrl.nameplateCode}/${$ctrl.versionCode}`;
                $http.get(apiUrl).then(response => {
                    $ctrl.upgradeOptions = response.data;
                }, error => {
                    console.error(error);
                })
            };

            $ctrl.getFunctionalitiesByVersion = () => {
                let apiUrl = `/api/uconnect/brand/${$ctrl.brandCode}/${$ctrl.versionCode}`;
                $http.get(apiUrl).then(response => {
                    $ctrl.functionalities = response.data;
                }, error => {
                    console.error(error);
                })
            }

            $ctrl.getDisclaimerCodeFromString = (disclaimer) => {
                let code = disclaimer.substring(
                    disclaimer.indexOf(":") + 1, 
                    disclaimer.lastIndexOf("}")
                );
                return code;
            }

            $ctrl.removeDisclaimerFromString = (disclaimer) => {
                let removeDisclaimer = disclaimer.substring(
                    disclaimer.indexOf("$"), 
                    disclaimer.lastIndexOf("}") + 1
                );                
                return disclaimer.replace(removeDisclaimer, '');
            }

            $ctrl.isUpgradeEmpty = () => {
                return Object.keys($ctrl.upgradeOptions).length === 0;
            }

            $ctrl.removeBreakline = (string) => {
                return string.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,'');
            }
        }
    }
})();
