(function() {
  'use strict';

  angular
    .module('fca.commonFilters')
    .filter('fcaRegisteredTrademark', function($sce) {
      return function(val) {
        let newVal = val.replace('MD', '&#x1D39;&#x1D30;').replace('TM', '&#x1D40;&#x1D39;');
        return $sce.trustAsHtml(newVal);
      }
    });
})();
