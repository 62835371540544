/* DEBOUNCE - This function allows the function to be launched only when operation is complete
* THROTTLE - Allows function to be called once in a determined amount of time */

(function() {
    angular
        .module('fca.commonServices')
        .service('functionExecutionControl', functionExecutionControl);

    function functionExecutionControl () {
        'ngInject';

        let $service = this;

        $service.debounce = (func, wait, immediate) => {
            let timeout;
            return function () {
                let context = this, args = arguments;
                let later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        $service.throttle = (fn, wait) => {
            let time = Date.now();

            return function () {
                let newTime = Date.now();

                if ((time + wait - newTime) < 0) {
                    fn();
                    time = Date.now();
                }
            };
        };
    }
})();