(function () {
	angular
		.module('alfaromeo.buildAndPrice.alertWindow')
		.component('alfaAlertWindow', {
			controller: alfaAlertWindow,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/alert-window/alfaromeo-alert-window.html',
			bindings: {
				actionable: '<',
				mainOption: '<',
				includedOptions: '<',
				removedOptions: '<',
				musthaveChoices: '<',
				status: '@',
				onCancel: '<',
				onConfirm: '<',
				onMusthaveSelection: '<',
			}
		});

	function alfaAlertWindow($document, $element, $scope, $timeout, alfaAlertState) {
		'ngInject';

		const $ctrl = this;

		$ctrl.$onChanges = changes => {
			if (!changes.mainOption) return;

			if (changes.mainOption.currentValue) {
				$timeout(() => {
					let navDialogEl = $element.find(".container")[0];
					let dialogOverlay = $element[0];
					$ctrl.dialogRef = new Dialog(navDialogEl, dialogOverlay);
					$ctrl.dialogRef.addEventListeners(
						null,
						'.alfa-common-button.-grey',
						$ctrl.onCancel
					);
					$ctrl.dialogRef.open();
				});

				$document.on('click', function (e) {
					if ($element[0] === e.target) {
						$scope.$apply(() => {
							$ctrl.onCancel();
						});
					}
				});
			} else {
				$document.off('click');
			}
		};

		$ctrl.$onInit = () => {
			$ctrl.alertsDisabled = alfaAlertState.alertsDisabled();
		};

		$ctrl.disableAlerts = () => {
			alfaAlertState.disableAlerts();
			$ctrl.alertsDisabled = true;
			$ctrl.refreshDialogFocus();
		};

		$ctrl.enableAlerts = () => {
			alfaAlertState.enableAlerts();
			$ctrl.alertsDisabled = false;
			$ctrl.refreshDialogFocus();
		};

		$ctrl.refreshDialogFocus = () => {
			$timeout(() => {
				$ctrl.dialogRef.refresh();
				$ctrl.dialogRef.firstFocusableEl.focus();
			});
		};
	}
})()
