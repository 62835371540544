(function() {
	'use strict';

	angular
		.module('fca.hoursStatus')
		.directive('hoursStatusOpenClosed', OpenCloseStatus);

	/**
	 * @ngdoc directive
	 * @name hoursStatusOpenClosed
	 * @description Display Open/closed status markup
	 * @requires hoursStatus
	 * @example
	 * <pre>
	 * <div hours-status-open-closed data-open-at="2017-04-19 11:30:00.0" data-close-at="2017-04-19 21:00:00.0"></div>
	 * </pre>
	 */
	function OpenCloseStatus() {
		'ngInject';

		return {
			controller: OpenCloseStatusController,
			controllerAs: '$ctrl',
			bindToController: {
				openAt: '<?',
				closeAt: '<?'
			},
			restrict: 'A'
		};
	}

	/**
	 * @ngdoc controller
	 * @name fca.hoursStatus.controller:OpenCloseStatusController
	 * @description hoursStatusOpenClosed directive controller
	 */
	function OpenCloseStatusController(hoursStatus) {
		'ngInject';

		/**
		 * @ngdoc property
		 * @type {Boolean}
		 * @name isOpened
		 * @propertyOf fca.hoursStatus.controller:OpenCloseStatusController
		 * @description
		 * Open/close status
		 */
		this.isOpened = false;

		/**
		 * @description
		 * Set initial open/close status
		 */
		this.$onInit = () => {
			this.setOpenStatus();
		};

		/**
		 * @description
		 * On change property openAt/closeAt set new open/close status
		 */
		this.$onChanges = (o) => {
			if (o.hasOwnProperty('closeAt') && o.closeAt.currentValue !== undefined) {
				this.setOpenStatus();
			}
		};

		/**
		 * @ngdoc method
		 * @methodOf fca.hoursStatus.controller:OpenCloseStatusController
		 * @name setOpenStatus
		 * @description Change open/close status with openAt/CloseAt parameters
		 */
		this.setOpenStatus = () => {
			this.isOpened = hoursStatus.isOpenNow(this.openAt, this.closeAt);
		};
	}
})();
