(function() {
	angular
		.module('fca.brandApp')
		.directive('vehicleLineUp', vehicleLineUp);

	function vehicleLineUp() {
		return {
			restrict: 'A',
			controller: VehicleLineUpController,
			controllerAs: 'vehicleLineUp',
			scope: true
		};
	}
	function VehicleLineUpController($scope, $element, $rootScope, userLocation) {
		'ngInject';

		/**
		 * @ngdoc property
		 * @name currentRegion
		 * @propertyOf fca.layout.regionalOffers.controller:FcaOffersCarouselController
		 * @description Defines the current region
		 * @type Boolean
		 */
		this.currentRegion = userLocation.getLocationRegion();

		this.currentTab = 'all';
		
		this.paymentTab = 'cash';

		/**
		 * @ngdoc method
		 * @name onInit
		 * @propertyOf ca.layout.regionalOffers.controller:FcaOffersCarouselController
		 * @description Watching location change in fca-userLocation.service
		 */
		this.$onInit = () => {
			$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
				this.currentRegion = region;
			});
		};
	}
})();
