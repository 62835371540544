
(function() {
    angular
        .module('fca.bodyBuilder')
        .directive('bodyBuilderVideoGallery', bodyBuilderVideoGallery);

    function bodyBuilderVideoGallery() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                defaultCategory: '@',
                defaultCategoryLabel: '@'
            },
            controllerAs: '$bodyBuilderVideoGallery',
            controller: bodyBuilderVideoGalleryController
        };

        function bodyBuilderVideoGalleryController($element) {
            'ngInject';

            let $ctrl = this;

            $ctrl.toggleCategory = (event) => {
                let newCategory = event.currentTarget.getAttribute('data-category');
                let newCategoryLabel = event.currentTarget.getAttribute('data-category-label');
                $ctrl.currentCategory = newCategory;
                $ctrl.currentCategoryLabel = newCategoryLabel;
            }

            $ctrl.$onInit = () => {
                $ctrl.currentCategory = this.defaultCategory;
                $ctrl.currentCategoryLabel = this.defaultCategoryLabel;
            }
        }
    }
})();
