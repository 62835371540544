(function(ng) {
    'use strict';
    angular
        .module('fca.preferredDealer')
        .service('preferredDealerProvider', PreferredDealerProvider);

    function PreferredDealerProvider($rootScope, $cookies,externalConfigLoader) {
        'ngInject';

        let now = new Date();
        let {expires} = new Date(now.getFullYear()+1, now.getMonth(), now.getDate());
		let cookieName = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('preferredDealerCookieName');
        let preferredDealer;

        // Get preferred dealer stored in cookie
        this.getPreferredDealer = () => {
            let dealer = null;

            if (this.isCookieExists()) {
                dealer = ng.copy(preferredDealer);
            }

            return dealer;
        };

        // Set preferred dealer in cookie
        this.setPreferredDealer = (locParam) => {
            let value = locParam;

            $cookies.put(cookieName, value, {expires: expires, path: '/'});
            preferredDealer = ng.copy(locParam);
            this.broadcastNewPreferredDealer();

            return preferredDealer;
        };

        // Check if preferred dealer cookie exists
        this.isCookieExists = () => {
            let cookie = $cookies.get(cookieName);
            let isExists = false;

            if (cookie !== undefined) {
                if (!preferredDealer) {
                    preferredDealer = cookie;
                }

                isExists = true;
            }

            return isExists;
        };

        this.resetPreferredDealer = () => {
            $cookies.remove(cookieName, {path: '/'});
            $rootScope.$broadcast('resetPreferredDealer');
        };

        this.broadcastNewPreferredDealer = () => {
            $rootScope.$broadcast('preferredDealer', preferredDealer);
        };
    }
})(angular);
