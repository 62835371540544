(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaVr360Hotspot', fcaVr360Hotspot);

	function fcaVr360Hotspot() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				id: '@',
				lang: '@',
				hotspotImage: '@',
				hotspotTitle: '@',
				hotspotCopy: '@',
				hotspotIndex: '@', // TODO change to <
				hotspotAlt: '@',
				hotspotClose: '@',
				prevLabel: '@',
				prevIndex: '@',
				nextLabel: '@',
				nextIndex: '@'
			},
			controllerAs: 'vr360Hotspot',
			controller: FcaVr360HotspotController,
			templateUrl: '/panels/vr360/fca-vr360-hotspot.html'
		};

		function FcaVr360HotspotController($scope) {
			'ngInject';

			$scope.index = 0; // TODO remove $scope and use this instead
		}
	}
})();
