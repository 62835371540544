/* eslint-disable indent */
(function () {
    angular.module('fca.buildAndPrice.nameplatePricingOr').component('nameplatePricingOr', {
        templateUrl: '/build-and-price/nameplate-pricing-or/nameplate-pricing-or.html',
        controller: NameplatePricingOr,
        controllerAs: '$ctrl',
        bindings: {
            setActiveTab: '&',
            priceObjectType: '@',
            priceObjectAltType: '@',
            activeTab: '@',
            priceObject: '<',
            disclaimerFinance: '@',
            disclaimerLease: '@',
            uniqueId: '@'
        }
    });

    function NameplatePricingOr() {
      'ngInject';

      const $ctrl = this;

      $ctrl.language = window.FCA_SITES_CONFIG.language;
      storeAffiliateId();
      $ctrl.affiliateMode = isAffiliateMode();

      $ctrl.$onInit = () => {
        if ($ctrl.priceObjectType === 'lease' || $ctrl.priceObjectType === 'altLease' ) {
          $ctrl.disclaimer = $ctrl.disclaimerLease;
        } else {
          $ctrl.disclaimer = $ctrl.disclaimerFinance;
        }
      };

      $ctrl.$postLink = () => {
        document.addEventListener('keypress', function logKey(e) {
          if ( e.code === 'Enter' && $(":focus").hasClass('price-display-or-element') ) {
            $(":focus").click();
          }
        });
      }
    }
})();