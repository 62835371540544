(function() {
  'use strict';

  angular
    .module('fca.commonFilters')
    .filter('trustAsHtml', function($sce) {
        return function(text) {
            return $sce.trustAsHtml(text);
        };
    });
})();
