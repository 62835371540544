(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaNameplateOffers', fcaNameplateOffers);

	function fcaNameplateOffers() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'nameplateOffers',
			controller: FcaNameplateOffersController,
		};

		function FcaNameplateOffersController($element) {
			'ngInject';

			this.current = "cash";

			this.$onInit = () => {
				if ($element.find( '[data-default-tab]' )) {
					this.current = $element.find( '[data-default-tab]' ).attr( 'data-default-tab' );
				}
			};

			this.setActiveTab = (tab) => {
				this.current = tab;
			};
		}

	}
})();
