/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.directives')
        .directive('fcaTrimGroupPreviousLink', fcaTrimGroupPreviousLink);

    function fcaTrimGroupPreviousLink() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
            },
            controllerAs: '$ctrl',
            controller: FcaTrimGroupPreviousLink
        };

        function FcaTrimGroupPreviousLink($scope, $location) {
            'ngInject';

            let $ctrl = this;

            $ctrl.activeTab = "cash";

            $ctrl.$onInit = () => {
                $scope.$on('trim-group-pricing:set-active-tab', (event, data) => {
                    $ctrl.activeTab = data.activeTab;
                });

                $ctrl.hashParameters = getHashParameters();
                $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);
            };
        }
    }
})();
