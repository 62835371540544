/* eslint-disable indent */
(function() {
    angular
    .module('fca.sni.compareVehicleWrapper')
    .component('sniCompareVehicleWrapper',{
        controller: SniComparedVehicleWrapper,
        controllerAs: '$ctrl',
        templateUrl: '/sni/compare-vehicle-wrapper/compare-vehicle-wrapper.html',
        bindings: {
            brandCode: '@',
            disclaimers: '<',
            neutralColors: '<',
            sniDetailsUrl: '@',
            userLocation: "<",
            affiliateLogoSource: '@',
            maxIrisWidth: '@',
            sniMode: "@",
            hasNameplate: "=",
            sniEshopUrl: '@',
            sniEshopActivated: '@',

            disclaimerPrice: "@",
            disclaimerDealerPrice: "@",
            disclaimerEmployeePrice: "@",
            disclaimerPreferredPrice: "@",
            disclaimerAffiliatePrice: "@",
            disclaimerLease: "@",
            disclaimerFinance: "@",
            disclaimerNetAmountSni: "@",
            disclaimerMsrp: "@",
        }
    });

    function SniComparedVehicleWrapper($scope, $interval) {
        'ngInject';

        const $ctrl = this;
        $ctrl.hashData = {};
        $ctrl.filterManager = new SniInventoryFilter();
        $ctrl.vinsToCompare = [];
        $ctrl.pageIsReady = false;
        $ctrl.compareMode = false;
        $ctrl.compareBarInitialized = false;

        $ctrl.pageHeaderElement = $('#zones_body_blocks_page-title');
        $ctrl.dealerDetailsElement = $('#zones_body_blocks_dealer-info');
        $ctrl.compareBarZone = $('.C_VIL-compareBarZone');
        $ctrl.ctaHolder = $('.C_VIL-ctaHolder');


        $ctrl.getUrlHashData = () => {
            $ctrl.hashData =  $ctrl.filterManager.parseUrlSearch();
        };

        $ctrl.neutralColors = $ctrl.sniMode === 'dealer' && $ctrl.hasNameplate === false;

        $ctrl.goToCompare = () => {
            if(window.fcaCompare) {
                $('.compare-overlay-target').toggleClass('hidden')
            }
            $scope.$applyAsync(function () {
                $ctrl.pageIsReady = true;
                $ctrl.compareMode = true
            });

            $('html, body').animate({
                scrollTop: 0
            }, 400);
        };

        // Close compare
        function closeCompare() {
            if (window.location.hash === '#' || window.location.hash === '' || window.location.hash === undefined || window.location.hash === null) {
                if(window.fcaCompare) {
                    $('.compare-overlay-target').toggleClass('hidden')
                }
                $scope.$applyAsync(function () {
                    $ctrl.pageIsReady = false;
                    $ctrl.compareMode = false;
                });
            }
        }

        window.addEventListener('hashchange', closeCompare, false);

        // FCAPODS-591 : wait for VueJs to be loaded and to define window.fcaCompare
        const intervalPromise = $interval(() => {
            if(window.fcaCompare) {
                window.fcaCompare.vins.registerListener(vins => {
                    $scope.$applyAsync(function () {
                        $ctrl.vinsToCompare = vins;
                    });
                });
                $interval.cancel(intervalPromise);
            }
        }, 100, 50);

        $scope.$on("$destroy", function() {
            window.removeEventListener('hashchange', closeCompare, false);
            if(window.fcaCompare) {
                window.fcaCompare.vins.clearListener();
            }
        });

        $ctrl.$onInit = () => {
            $ctrl.disclaimers = {
                dealer: $ctrl.disclaimerDealerPrice,
                regular: $ctrl.disclaimerPrice,
                finance: $ctrl.disclaimerFinance,
                lease: $ctrl.disclaimerLease,
                legAmountSni: $ctrl.disclaimerNetAmountSni,
                msrp: $ctrl.disclaimerMsrp
            };

            $ctrl.getUrlHashData();
        }

    }
})();
