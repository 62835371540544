/* eslint-disable indent */
(function () {
	angular
	.module('fca.vlp.overlay')
	.directive('fcaVlpOverlay', fcaVlpOverlay);

	function fcaVlpOverlay() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: '$vlpOverlayCtrl',
			controller: FcaVlpOverlay
		};

		function FcaVlpOverlay($scope, $rootScope, configService, $element) {
			'ngInject';
			let $vlpOverlayCtrl = this;
			let lastScroll;
			$vlpOverlayCtrl.scrollPaused = false;

			$vlpOverlayCtrl.language = "";
			$vlpOverlayCtrl.isActive = false;
			$vlpOverlayCtrl.formErrors = false;
			$vlpOverlayCtrl.errors = $('.form-error > span');

			$vlpOverlayCtrl.$onInit = () => {
				window.addEventListener('scroll', event => {
					if (!$vlpOverlayCtrl.scrollPaused) {
						lastScroll = window.scrollY || window.pageYOffset;
					}
				});
				$vlpOverlayCtrl.language = configService.getLanguage();
				// When the button is clicked,
				$scope.$on('vlp:initialize-hr-form-overlay',
					(event, data) => {
						// previously focused element AKA the element that called this overlay
						$vlpOverlayCtrl.previousFocusedElement = data.previousFocusedElement;
						$vlpOverlayCtrl.email = data.email;

						$vlpOverlayCtrl.showOverlay();
					});

				document.addEventListener('keydown', event => {
					if(event.key === "Escape") {
						event.preventDefault();
						$vlpOverlayCtrl.cancel();
					}
				});
			};

			$vlpOverlayCtrl.cancel = () => {
				// set focus to the button that called this overlay
				if ($vlpOverlayCtrl.previousFocusedElement) {
					$vlpOverlayCtrl.previousFocusedElement.focus();
				}
				// Do cancel action
				$vlpOverlayCtrl.hideOverlay();
			};

			$vlpOverlayCtrl.showOverlay = () => {
				$vlpOverlayCtrl.pauseScrolling();
				//old vlp hide main nav and mini nav
				$element.toggleClass('C_HR_OL-hidden');
				$('.layout-main-nav').hide();
				$('#zones_body_blocks_mini-nav').hide();
				// Note: fixed elements will also need the margin adjustment (like a fixed header, if you have one).
				let scrollBarWidth = window.innerWidth
					- document.body.offsetWidth;
				$('html')
				.css('margin-right', scrollBarWidth)
				.addClass('C_NID_OL-showing-modal');

				$('body').addClass('noScroll');
				$('body').css('overflow','hidden');

				$vlpOverlayCtrl.isActive = true;
			};

			$vlpOverlayCtrl.hideOverlay = () => {
				$vlpOverlayCtrl.resumeScrolling();
				$element.toggleClass('C_HR_OL-hidden');
				$('.layout-main-nav').show();
				$('#zones_body_blocks_mini-nav').show();
				$('html')
				.css('margin-right', '')
				.removeClass('C_NID_OL-showing-modal');

				$('body').removeClass('noScroll');
				$('body').css('overflow','visible');
				$vlpOverlayCtrl.isActive = false;
			};
			$vlpOverlayCtrl.pauseScrolling = () => {
				$vlpOverlayCtrl.scrollPaused = true;
				let heightValue = 'calc(100% + ' + lastScroll + 'px)';
				$('body').addClass('noScroll')
				.css({
							'top': -lastScroll,
						'height': heightValue,
							'overflow': 'hidden'
					});
			};

			$vlpOverlayCtrl.resumeScrolling = () => {
				$vlpOverlayCtrl.scrollPaused = false;
				$('body').removeClass('noScroll')
					.removeAttr('style');

					if(lastScroll !== undefined) {
						window.scrollTo(0, lastScroll);
					}
			};

			$rootScope.$on('sign-up-submit-failed', () => {
				if($vlpOverlayCtrl.errors.is(':visible')) {
					$vlpOverlayCtrl.formErrors = true;
					$('#vlp-hr-overlay-error > span').focus();
				} else {
					$vlpOverlayCtrl.formErrors = false;
				}
			});
		}
	}
})();
