(function () {
    angular
        .module('fca.userSignInForm')
        .service('fcaUserIdPersistentService', FcaUserIdPersistentService);

    function FcaUserIdPersistentService($http) {
        'ngInject';

        let $service = this;

        $service.baseUrl = '/data/persistentId';

        /**
         * Get Uid
         *
         * @param email
         */
        $service.getUserId = (email,successCallback = null, errorCallback = null,postUrl = "", postObject = null) => {
            let responseUid = "";
            let url = $service.baseUrl + `/email/${email}`;
            let days = 365;

            $http.get(url)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        responseUid = response.data.uid;

                        // Always set the cookie
                        if (responseUid !=null && responseUid != "") {
                            $service.setCookie('userId', responseUid, days);

                            if (successCallback != null) {
                                successCallback(responseUid, postUrl, postObject);
                            }
                        }
                    } else {
                        if (errorCallback != null) {
                            errorCallback("Error while getting user id for :" + email);
                        } else {
                            console.log("Error while getting user id for :" + email);
                        }
                    }
                })
                .catch((data) => {
                    console.log("Error while getting user id for :" + email);
                });
        };

        $service.getCookie = (name) => {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        };

        $service.create_UUID = () => {
            let dt = new Date().getTime();
            let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = (dt + Math.random()*16)%16 | 0;
                dt = Math.floor(dt/16);
                return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
            return uuid;
        };

        $service.setCookie = (name, value, days) => {
            let expires = "";
            if (days) {
                let date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        };
    }
})(angular);