(function() {
	angular
		.module('fcaModelSpecs')
		.directive('fcaModelSpecsColorPicker', fcaModelSpecsColorPicker);

	function fcaModelSpecsColorPicker() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				colorName: '@'
			},
			controller: FcaModelSpecsColorPickerController,
			controllerAs: 'colorPicker'
		};

		function FcaModelSpecsColorPickerController($scope, $element) {
			'ngInject';

			this.colorSelected = (colorName) => {
				if (colorName != undefined) {
					this.currentColor = colorName;
				}
			};

			this.$onInit = () => {
				let firstColorElement = $element.find('.color-selector')[0];
				let firstColorName = angular.element(firstColorElement).attr('data-color-name');

				this.currentColor = firstColorName;
			};
		}
	}
})();
