(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaDescriptionBlock', fcaDescriptionBlock);

	function fcaDescriptionBlock() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'fcaDescriptionBlock',
			controller: fcaDescriptionBlockController
		};

		function fcaDescriptionBlockController($element) {
			'ngInject';

			const $ctrl = this;
			const scroller = scrollama();
			const mediaContainer = $element[0].querySelector('.AR_desc-block__media-wrapper');

			const mediaElements = $element.find('.AR_desc-block__media');
			const hotspotElements = $element.find('.AR-hotspot');
			const packageLinks = $element.find('.AR_desc-block__package');
			const packageContents = $element.find('.AR_desc-block__content-media');
			const instanceId = $element[0].id;

			$ctrl.packageToggleMobileIsOpen = false;

			$ctrl.scrollerInit = () => {
				scroller
					.setup({
						step: `.AR_desc-block--no-packages .step-desc-${instanceId}-0`,
						offset: 0.5,
						//debug: true
					})
					.onStepEnter(response => {
						$ctrl.onScrollerStepEnter(response);
					})
					.onStepExit(() => {
						Stickyfill.removeOne(mediaContainer);
					});

				window.addEventListener('resize', scroller.resize);
			};

			$ctrl.packagesScrollerInit = () => {
				packageContents.each(function(index) {
					let scrollerPackage = scrollama();

					scrollerPackage
						.setup({
							step: `.AR_desc-block--has-packages .step-desc-${instanceId}-${index}`,
							offset: 0.5,
							//debug: true
						})
						.onStepEnter(response => {
							$ctrl.onScrollerStepEnter(response);
						})
						.onStepExit(() => {
							Stickyfill.removeOne(mediaContainer);
						});

					window.addEventListener('resize', scroller.resize);
				});
			};

			$ctrl.selectPackage = ($event) => {
				$event.preventDefault();
				const currentPackage = $event.currentTarget.parentElement;
				const selectedPackageIndex = $event.currentTarget.getAttribute('data-index');

				if (!currentPackage.classList.contains('is-active')) {
					packageLinks.each(function() {
						if(this.classList.contains('is-active')) {
							this.classList.remove('is-active');
						}
					});

					$element.find('.AR_desc-block__content-media').removeClass('is-active');
					$element.find(`.AR_desc-block__content-media[data-index="${selectedPackageIndex}"]`).addClass('is-active');
					$element.find('.AR_desc-block__content-text').removeClass('is-active');
					$element.find(`.AR_desc-block__content-text[data-index="${selectedPackageIndex}"]`).addClass('is-active');
					$element.find('.AR_desc-block__package-title').removeClass('is-active');
					$element.find(`.AR_desc-block__package-title[data-index="${selectedPackageIndex}"]`).addClass('is-active');
					currentPackage.classList.add('is-active');
					window.addEventListener('resize', scroller.resize);
				}
			};

			$ctrl.onScrollerStepEnter = response => {
				Stickyfill.addOne(mediaContainer);
				const currentDataStep = response.element.getAttribute('data-step');

				mediaElements.each(function() {
					$ctrl.toggleVisibility(this, currentDataStep);
				});

				if (hotspotElements.length) {
					hotspotElements.each(function() {
						$ctrl.toggleVisibility(this, currentDataStep);
					});
				}
			};

			$ctrl.toggleVisibility = (currentElement, currentDataStep) => {
				currentElement.classList.remove('is-visible');
				if (currentElement.getAttribute('data-step-index') === currentDataStep) {
					if (currentElement.classList.contains('has-video')) {
						const videoElement = currentElement.querySelector('video');

						if (videoElement && !currentElement.classList.contains('video-has-played')) {
							videoElement.muted = true;
							videoElement.autoplay = false;
							videoElement.controls = false;
							const playPromise = videoElement.play();

							if (playPromise !== undefined) {
								playPromise.then(function() {
									videoElement.onended = () => {
										currentElement.classList.add('video-has-played');
									};
								}).catch(function(error) {
									console.error('Error playing the video: ', error);
								});
							}
						}
					}

					currentElement.classList.add('is-visible');
				}
			};

			$ctrl.$onInit = () => {
				if ($element[0].classList.contains('AR_desc-block--has-packages')) {
					this.packagesScrollerInit();
				} else {
					this.scrollerInit();
				}
			};
		}
	}
})();
