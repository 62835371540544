(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaKeepUrlHash', fcaKeepUrlHash);

	function fcaKeepUrlHash() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'keepUrlHash',
			controller: fcaKeepUrlHashController
		};

		function fcaKeepUrlHashController($location) {
			'ngInject';

			const $ctrl = this;
			const hashParameters = $location.$$hash;

			$ctrl.setNewUrl = (originalUrl) => {
				return !!hashParameters ? `${originalUrl}#${hashParameters}` : originalUrl;
			}
		}
	}
})();
