(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.externalConfigLoader
	 * @description Load configurations from $window object. Different way to inject/use data in application
	 * @example
	 * <pre>
	 * // In HTML template script tag
	 * window.CONFIG = { webserviceUrl: '/api' };
	 * // In application
	 * ng
	 *   .module('MyApp', ['fca.externalConfigLoader'])
	 *   .config(function(externalConfigLoaderProvider){
	 *     let config = externalConfigLoaderProvider.loadConfig('CONFIG');
	 *     // config == { webserviceUrl: '/api' }
	 *   });
	 * </pre>
	 */
	angular
		.module('fca.externalConfigLoader', []);
})();
