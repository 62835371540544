(function () {
    angular
    .module('fca.userAccount')
    .directive('fcaUserAccountInitializer', UserAccountInitializer);

    function UserAccountInitializer() {
        return {
            restrict: 'E',
            scope: true,
            bindToController: {
                facebookAppId: '@',
                googleClientId: '@',
                apiBaseUrl: '@',
                debug: '@',
                siteBaseUrl: '@'
            },
            controllerAs: '$fcaUserAccountInitializerCtrl',
            controller: UserAccountInitializerCtrl
        };

        function UserAccountInitializerCtrl($scope, $rootScope, userAccountService, fcaGeolocator) {
            'ngInject';

            let $ctrl = this;

            // Public functions
            //------------------

            $ctrl.$onInit = () => {
                if (userAccountService.isLibraryAvailable()) {

                    let uaClientOptions = {
                        facebookAppId: $ctrl.facebookAppId,
                        googleClientId: $ctrl.googleClientId,
                        apiBaseUrl: $ctrl.apiBaseUrl,
                        debug: $ctrl.debug,
                        onMessageFromIFrame: onMessageFromIFrame,
                    };

                    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (isSafari) {

                        //MFBR-2938 special handling for safari: user account calls will be proxied by brand site it-self
                        //cause since safari 11, 3rd party cookies are prohibited by default.
                        uaClientOptions['apiBaseUrl'] = '';
                        //We deactivate iframe to share data between brand and daa sites cause this doesn't work in safari
                        //Error in safari: The source list for Content Security Policy directive ‘script-src’
                        //contains an invalid source: ‘’strict-dynamic’’. It will be ignored.
                        uaClientOptions['iframeDivId'] = '';
                    }

                    UA.init(uaClientOptions, doAfterLogin);

                    //if iframe is present, we wait she's completely loaded before load user account data
                    let uaIframeObject = $('#fca-ua-iframe');
                    if (uaIframeObject.length > 0) {
                        //nothing to do cause ua iframe will send a message when she will be loaded
                        // (see UA.options.onMessageFromIFrame)
                    } else {
                        loadUser();
                    }

                    $rootScope.$on(fcaGeolocator.getLocationChangedEvent(), (evt, args) => {
                        const location = args[0];
                        //may be we have to update the postal code cause in case of authenticationProvider is GOOGLE or FACEBOOK,
                        //we didn't have the postal code
                        if (location
                            && UA.isUserLoggedIn()
                            && UA.getUser().authenticationProvider) {

                            updateUserLocation(location);
                        }
                    });

                } else {
                    console.warn('UA object is not defined! User account will be deactivated.');
                }
            };

            let doAfterLogin = (response) => {

                if (response.status === 'FAIL') {
                    $rootScope.$broadcast('user-account: login-fail', {
                        message: response.message
                    });
                } else {
                    let language = window.FCA_SITES_CONFIG.language;

                    //are we on sign in page
                    if (window.location.href.endsWith(window.FCA_SITES_CONFIG.urls.signIn[language])) {
                        //we redirect to my-garage page
                        window.location.replace(window.FCA_SITES_CONFIG.urls.myGarage[language]);
                    }
                }
            };

            let loadUser = () => {

                UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                    $rootScope.$broadcast('ua:libraryInitialized');

                    if (UA.isUserLoggedIn()) {
                        let language = window.FCA_SITES_CONFIG.language;

                        // we update language in user account if needed
                        if (language !== UA.getUser().language && UA.updateLanguage) {
                            UA.updateLanguage(language);
                        }
                    }
                });
            };

            let onMessageFromIFrame = (data) => {

                if (data && data.eventId) {

                    switch (data.eventId) {

                        case 'ua_iframe_last_updated_date_sent':

                            if (UA.resetUserData) {

                                let needToRefreshData = false;

                                //get the max date from the map we received from ua iframe to know which site has made
                                //the last modification:
                                // - if the iste is the same than the current site, no need to reset user data
                                let maxDate = null;
                                let urlSite = null;
                                if (data.data !== null) {
                                    for (let entry of data.data) {
                                        const lastUpdatedDate = new Date(entry[1]);
                                        if (maxDate == null || maxDate < lastUpdatedDate) {
                                            maxDate = lastUpdatedDate;
                                            urlSite = entry[0];
                                        }
                                    }
                                }

                                needToRefreshData = urlSite !== null && urlSite !== $ctrl.siteBaseUrl;

                                // if needed (when user account data has been updated from another domains,
                                // we reset all data in session storage
                                if (needToRefreshData) {
                                    console.debug('UA -> reset user account data');
                                    UA.resetUserData();
                                }
                            }
                            break;
                    }
                }

                loadUser();
            };

            let updateUserLocation = (location) => {

                const provinceCode = location.province;
                const latitude = location.latitude;
                const longitude = location.longitude;
                const postalCode = userAccountService.unformatPostalCode(location.postalCode);

                if (UA.isUserLoggedIn() &&
                    (UA.getUser().provinceCode !== provinceCode
                        || UA.getUser().latitude !== latitude
                        || UA.getUser().longitude !== longitude
                        || UA.getUser().postalCode !== postalCode)) {

                    UA.updateLocation(provinceCode, latitude, longitude, postalCode);
                }
            }
        }
    }
})();
