(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaScrollTo', fcaScrollTo);

	function fcaScrollTo($window, $rootScope) {
		'ngInject';

		return {
			restrict: 'A',
			scope: true,
			link: function($scope, $element) {
				let target = $element.data('scrollTarget');
				let speed = $element.data('scrollSpeed') || 200;
				let stickyPanelHeight;

				$element.on('click', function(event) {
					event.preventDefault();

					$rootScope.$emit('fcaScrollTo.active');

					if ($(event.target.offsetParent).hasClass('legal-tooltip-label')) {
						return;
					}

					history.pushState({}, '', $element.attr('href'));
					$('html, body').animate({
						scrollTop: $(`#${target}`).offset().top - getOffset()
					}, speed);

					let buttonMatching = $($element.get(0).hash);
					if (buttonMatching.length > 0) {
						buttonMatching.click();
					}
				});

				function getOffset() {
					let panel = $('.sticky-panel.is-mini-nav');

					// Set offset to 0 if panel does not exist
					if(panel.length > 0) {
						if (!stickyPanelHeight) {
							// Clone panel to measure height of mini-nav in its sticky state
							const panelClone = panel
											.clone()
											.addClass('is-stuck')
											.css('visibility', 'hidden')
											.appendTo('body');

							// Store value to not have to repeat expensive operation
							stickyPanelHeight = panelClone.height();
							panelClone.remove();
						}

						const offset = panel.scrollTop() + stickyPanelHeight;

						return offset - 5; // -5 needed to avoid bug on initial load
					} else {
						return 0;
					}
				}
			},
			controller: FcaScrollToController
		};

		function FcaScrollToController($location, $element, $anchorScroll) {
			'ngInject';

			let hashValue;
			let stickyPanelHeight;

			this.$onInit = () => {
				hashValue = $location.hash();
				this.goToHashAnchor();
				this.updateGTA();
			};

			/**
			 * Update the Gtmanalytics to add en event when we load the page
			 * via a target hash link.
			 */
			this.updateGTA = () => {
				if($element.attr('data-scroll-target') == hashValue) {
					let hashLinkTxt = $element.attr('data-mini-nav-group-id').trim();
					let gtmOptions = angular.extend({'pagesubsection': hashLinkTxt}, {}, {});
					dataLayer.push(gtmOptions);
				}
			};

			this.goToHashAnchor = () => {
				if($element.attr('data-scroll-target') == hashValue) {
					$anchorScroll.yOffset = getOffset();

					$anchorScroll(hashValue);
				}
			};

			function getOffset() {
				let panel = $('.sticky-panel.is-mini-nav');

				// Set offset to 0 if panel does not exist
				if(panel.length > 0) {
					if (!stickyPanelHeight) {
						// Clone panel to measure height of mini-nav in its sticky state
						const panelClone = panel
							.clone()
							.addClass('is-stuck')
							.css('visibility', 'hidden')
							.appendTo('body');

						// Store value to not have to repeat expensive operation
						stickyPanelHeight = panelClone.height();
						panelClone.remove();
					}

					const offset = panel.scrollTop() + stickyPanelHeight;
					return offset - 5; // -5 needed to avoid bug on initial load
				} else {
					return 0;
				}
			}
		}
	}
})();
