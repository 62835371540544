(function() {
	angular
		.module('fca.overlay')
		.directive('fcaOverlayTrigger', fcaOverlayTrigger);

	function fcaOverlayTrigger() {
		return {
			restrict: 'A',
			controller: FcaOverlayTriggerController,
			controllerAs: 'fcaOverlayTrigger',
			scope: true,
			bindToController: {
				contentType: '@',
				offerId: '@?'
			}
		};
	}

	function FcaOverlayTriggerController($element, $rootScope) {
		'ngInject';

		this.showOverlay = () => {
			if (this.contentType == 'global-legal') {
				$rootScope.$broadcast('fcaOverlay.openLegal');
			}
			if (this.contentType == 'global-offers') {
				$rootScope.$broadcast('fcaOverlay.openGlobalOffers');
			}
			if (this.contentType == 'vehicle-offer') {
				if (this.offerId != undefined && this.offerId != '') {
					$rootScope.$broadcast(`fcaOverlay.${this.offerId}`);
				}
			}
		};
	}
})();
