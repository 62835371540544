(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.service('geolocatorAutoDetection', GeolocatorAutoDetection);

	function GeolocatorAutoDetection(
		GeolocatorCookie,
		geolocatorCookieExpire,
		cookieLocation,
		fcaGeolocator,
		$rootScope,
		$timeout,
		externalConfigLoader,
		userLocation) {
		'ngInject';

		let config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
		const EXPIRES = geolocatorCookieExpire.getOneDayExpires();
		const DETECTION = new GeolocatorCookie(config.getConfig('autoDetectionCookieName'), EXPIRES);
		const LOCATION_BASED_ON_BROWSER = fcaGeolocator.getLocationBasedOnBrowser();
		const LOCATION_BASE_FIELD = fcaGeolocator.getLocationBaseField();
		const LOCATION_CHANGED_EVENT = fcaGeolocator.getLocationChangedEvent();
		const RADIUS = 20;
		const DEFAULT_LOCATION = 'toronto';

		const locationIsAccurate = (loc) => {
			return (
				location &&
				(loc.locationBaseOn === "userInput" ||
					(loc.locationBaseOn === "browser" &&
						(loc.forceByIp === false || loc.forceByIp === "false")))
			);
		};

		this.detect = (cb) => {
			if (this.isAutoDetectionMandatory()) {
				console.debug('Auto Detect Mandatory is true');
				fcaGeolocator.detectCurrentPosition().then((position) => {
					console.debug('Current Position Detected:', position);
					if (ng.isObject(position)) {
						console.debug('Position detected, ng.isObject');
						fcaGeolocator.reverseGeocode(position).then((location) => {
							let broadcast = true;

							console.debug('Location from reverseGeoCode :', location);

							userLocation.verifyUserOutsideCanada(location);

							if (location.name.toLowerCase() === DEFAULT_LOCATION) {
								// Broadcast new detect location only
								// If location server match with browser detection
								// Case : user outside canada or error server IP detection
								broadcast = fcaGeolocator.isInRadius(position, location, RADIUS);
							}

							if (broadcast) {
								ng.extend(location, {
									[LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
									"forceByIp": false
								});

								$rootScope.$broadcast(LOCATION_CHANGED_EVENT, [location]);
							}

							if( typeof(cb) === typeof(Function)) {
								cb(location);
							}
						});
					} else {

						function forceIPDetect() {
							console.debug('Position not detected, IP detection used instead');
							fcaGeolocator.getCurrentLocation().then((location) => {

								console.debug('Location from IP:', location);

								userLocation.verifyUserOutsideCanada(location);

								ng.extend(location, {
									[LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
									"forceByIp": true
								});

								$rootScope.$broadcast(LOCATION_CHANGED_EVENT, [location]);

								if( typeof(cb) === typeof(Function)) {
									cb(location);
								}
							});
						}

						// this happens mostly when the user refuses geo-location, fall back to ip, but maybe thing has changed in between
						if (cookieLocation.isCookieExists()) {
							const location = cookieLocation.getLocation();

							if(locationIsAccurate(location)) {
								forceIPDetect();
							}
						} else {
							forceIPDetect()
						}
					}

					DETECTION.set({
						detection: true
					});
				});
			}
		};

		this.isAutoDetectionMandatory = () => {
			let isMandatory = true;

			// Location exists
			if (cookieLocation.isCookieExists()) {
				isMandatory = false;
				userLocation.verifyUserOutsideCanada(cookieLocation.getLocation());
				console.debug('Location from cookies', cookieLocation.getLocation());
			}

			return isMandatory;
		};
	}
})(angular);
