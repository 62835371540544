(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaContestInfo', fcaContestInfo);

	function fcaContestInfo() {
		return {
			restrict: 'A',
			controller: FcaContestInfoController,
			controllerAs: 'contestinfo',
			scope: true,
			bindToController: {
				getDealerDropdownOptions: '=',
				selectedDealerOption: '='
			}
		};

		// eslint-disable-next-line max-len
		function FcaContestInfoController($scope, $rootScope) {
			'ngInject';
			this.showDealerInfo = false;
			this.dealerName = null;
			this.dealerAddress = null;
			this.dealerCity = null;
			this.dealerZipCode = null;
			this.dealerCode = null;
			this.contestPin = null;
			this.dealerPhone = null;

			this.$onInit = () => {
				/* Listen if the chat popup must be opened */
				$scope.$on('defineSelectedDealer', () => {
					let selectedDealer = $rootScope.selectedDealerOption;

					if(selectedDealer != undefined) {
						this.showDealerInfo = true;
						this.dealerName = selectedDealer.name;
						this.dealerAddress = selectedDealer.address;
						this.dealerCity = selectedDealer.city;
						this.dealerZipCode = selectedDealer.zipPostal;
						this.dealerPhone = selectedDealer.contactNumber;
						this.dealerCode = selectedDealer.code;
					} else {
						this.showDealerInfo = false;
					}
				});

				/* Clear selected dealer on null dropdown value */
				$scope.$on('clearSelectedDealer', () => {
					this.showDealerInfo = false;
					this.dealerName = null;
					this.dealerAddress = null;
					this.dealerCity = null;
					this.dealerZipCode = null;
					this.dealerCode = null;
					this.contestPin = null;
					this.dealerPhone = null;
				});

				$scope.$on('setContestPin', () => {
					let returnedContestPin = $rootScope.contestPin;

					if (returnedContestPin != undefined) {
						this.contestPin = returnedContestPin;
					}
				});
			};
		}
	}
})();

