(function () {
	angular
		.module('alfaromeo.buildAndPrice.presentational-components')
		.component('alfaRow', {
			templateUrl: '/brand-specific/alfaromeo/components/row/alfaromeo-row.html',
			bindings: {
				url: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				name: '@',
				price: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('alfaSwatch', {
			templateUrl: '/brand-specific/alfaromeo/components/swatch/alfaromeo-swatch.html',
			bindings: {
				colorCode: '@',
				label: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				url: '@',
				name: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('alfaThumbnail', {
			templateUrl: '/brand-specific/alfaromeo/components/thumbnail/alfaromeo-thumbnail.html',
			bindings: {
				big: '<',
				label: '@',
				url: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				showAdd: '<',
				withBorder: '<',
				name: '@',
				alt: '@',
				category: '@',
				placeholderImage: '@'
			}
		})
		.component('alfaTile', {
			templateUrl: '/brand-specific/alfaromeo/components/tile/alfaromeo-tile.html',
			bindings: {
				big: '<',
				description: '@',
				features: '<',
				price: '@',
				selected: '<',
				missedAlert: '<',
				onMissedAlertClick: '<',
				withoutLabel: '<',
				url: '@',
				name: '@',
				category: '@',
				placeholderImage: '@'
			}
		});
})();
