/**
 * This utility service takes in a slick carousel object
 * and removes unnecessary attributes for accessibility.
 *
 * @param carouselObject - should be a slick object
 */
(function() {
    angular
        .module('fca.commonServices')
        .service('cleanCarousel', cleanCarousel);

    function cleanCarousel () {
        'ngInject';

        let $service = this;
        $service.makeAccessible = carouselObject => {
            if(carouselObject instanceof jQuery && carouselObject.length > 0) {
                const carousel = carouselObject[0];

                const slickObjectCarousel = carousel.querySelectorAll('.slick-track');
                const slickObjectSlide = carousel.querySelectorAll('.slick-slide');

                const carouselArray = Array.from(slickObjectCarousel);
                const slideArray = Array.from(slickObjectSlide);

                //failsafe
                if (carouselArray && slideArray) {

                    carouselArray.forEach(carouselSlides => {
                        carouselSlides.removeAttribute('role');
                    });

                    slideArray.forEach(slides => {
                        slides.removeAttribute('aria-describedby');
                        slides.removeAttribute('role');
                    });
                }
            }
        };
        
        $service.removeAriaHidden = carouselObject => {
            if(carouselObject instanceof jQuery && carouselObject.length > 0) {
                const carousel = carouselObject[0];
    
                const slickObjectSlide = carousel.querySelectorAll('.slick-slide');
    
                const slideArray = Array.from(slickObjectSlide);
    
                if (slideArray) {
                    slideArray.forEach(slides => {
                        slides.removeAttribute('aria-hidden');
                    });
                }
            }
        };
    }
})();