(function () {
	angular
		.module('alfaromeo.buildAndPrice.alertWindow')
		.service('alfaAlertState', AlfaAlertState);

	function AlfaAlertState(alfaConfigPipeline) {
		const $service = this;

		const localStorageKey = 'fca-alfaromeo-disableAlerts';
		const alertsDisabledDefault = false;

		let _alertsDisabled = null;

		const fetchState = () => {
			const storedPreference = localStorage.getItem(localStorageKey);

			if (storedPreference == null) {
				_alertsDisabled = alertsDisabledDefault;
			} else {
				_alertsDisabled = JSON.parse(storedPreference);
			}
		};

		const persistState = () => {
			localStorage.setItem(localStorageKey, _alertsDisabled);
		};

		$service.alertsDisabled = () => {
			if (_alertsDisabled == null) {
				fetchState();
			}

			return _alertsDisabled;
		};

		$service.disableAlerts = () => {
			_alertsDisabled = true;
			persistState();
		};

		$service.enableAlerts = () => {
			_alertsDisabled = false;
			persistState();
		};

		$service.setMissedOption = (config, optionCode = null) => {
			const options = alfaConfigPipeline.allOptionsInConfig(config);

			const oldMissedOption = options.find(option => option.missedAlert);

			if (oldMissedOption) {
				delete oldMissedOption.missedAlert;
			}

			const newMissedOption = options.find(
				option => option.code === optionCode
			);

			if (newMissedOption) {
				newMissedOption.missedAlert = true;
			}
		}
	}
})();
