(function(ng) {
    angular
        .module('fca.calculator')
        .service('pricingDataService', pricingDataService);

    function pricingDataService() {

        let $service = this;

        $service.sumCashIncentives = (incentives, isAfterTaxes = false) => {
            let result = 0;
            if (incentives && incentives.length > 0) {
                if (isAfterTaxes) {
                    result = sumValueInArrayOfObjects(getAfterTaxesIncentives(incentives), "cash");
                } else {
                    result = sumValueInArrayOfObjects(getBeforeTaxesIncentives(incentives), "cash");
                }
            }
            return result;
        };

        $service.getBasePrice = (pricingData) => {
            return parseFloat(pricingData.base.msrp);
        };

        $service.getFreight = (pricingData) => {
            return parseFloat(pricingData.freight.msrp);
        };

        $service.getAcTax = (pricingData) => {
            return parseFloat(pricingData.acTax.msrp);
        };

        $service.getOptions = (pricingData) => {
            return parseFloat(pricingData.options.msrp);
        };

        $service.getGreenLevy = (pricingData) => {
            return parseFloat(pricingData.greenLevy.msrp);
        };

        $service.getAdminFee = (pricingData) => {
            return parseFloat(pricingData.adminFee);
        };

        // Sum one of the numerical values in each object in an array of objects
        let sumValueInArrayOfObjects = (objects, dataKey) => {
            return objects.reduce((total, object) => {
                return total + parseFloat(object[dataKey])
            }, 0);
        };

        let getBeforeTaxesIncentives = (incentives) => {
            return incentives.filter(incentive => incentive.beforeTax);
        };

        let getAfterTaxesIncentives = (incentives) => {
            return incentives.filter(incentive => !incentive.beforeTax);
        };

    }
})(angular);