// Directive to wrap slick carousel
// Allows for callbacks and other arguments need to be passed to slick to be defined in
// this directive's controller instead of higher up in application

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselFixedContent', fcaSlickCarouselFixedContent);

	function fcaSlickCarouselFixedContent() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				speed: '@',
				easing: '@'
			},
			controllerAs: 'carouselFixedContent',
			controller: FcaSlickCarouselFixedContentController,
		};

		function FcaSlickCarouselFixedContentController($scope, $element, $timeout, $window) {
			'ngInject';

			this.dataAttrSelector = 'carousel-fixed-content-selector';
			this.dragging = false;
			this.$slicktrack = null;

			this.onTouchStart = (event) => {
				this.onStartDrag(event);
				$element.bind('touchmove', this.onTouchMove);
			};
			this.onTouchMove = (event) => {
				this.onMove(event);
			};
			this.onTouchEnd = (event) => {
				this.onStopDrag(event);
				$element.unbind('touchmove', this.onTouchMove);
			};
			$element.bind('touchstart', this.onTouchStart);
			$element.bind('touchend', this.onTouchEnd);

			this.onStartDrag = (event) => {
				this.dragging = true;
				$element.addClass('dragging');
			};
			this.onStopDrag = (event) => {
				this.dragging = false;
				$element.removeClass('dragging');
				// $timeout(() => {
				this.updatePos(true);
				// });
			};
			this.onMove = (event) => {
				if (this.dragging) {
					this.updatePos();
				}
			};
			this.onInit = (event, slick, currentSlide, nextSlide) => {
				this.$slicktrack = $element.find('.slick-track');
				let $els = $element.find('*[data-'+this.dataAttrSelector+']');
				$els.each((i) => {
					let $ele = angular.element($els[i]);
					let $fixedElements = $ele.find($ele.data(this.dataAttrSelector));
					$fixedElements.css({
						'transition-duration': (this.speed/1000) + 's',
						'transition-timing-function': this.easing
					}).addClass('carousel-fixed-content');
				});

				$element.parents('slick').slick('refresh');
			};
			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				// $timeout(() => {
				this.updatePos(true, nextSlide);
				// });
			};
			this.updatePos = (updateToActive = false, activeIndex = null) => {
				let offset = 0;
				if (!updateToActive) {
					offset = this.getComputedTranslateX(this.$slicktrack.get(0));
				}else{
					if (activeIndex == null) activeIndex = this.getActiveSlide();
					offset = activeIndex * -$element.width();
				}
				let w = $element.width();
				let $slides = $element.find('.slick-slide');
				$slides.each((i) => {
					let value = -(offset + i*w);
					let $ele = angular.element($slides[i]);
					let $fixedElements = $ele.find($ele.data(this.dataAttrSelector));
					$fixedElements.css('transform', 'translate3d('+value+'px, 0, 0)');
				});
			};
			this.getActiveSlide = () => {
				return $element.find('.slick-current').data('slick-index');
			};
			this.getFixedElements = (container) => {
				let fixedContentElements = [];
				let $els = angular.element(container).find('*[data-'+this.dataAttrSelector+']');
				$els.each((i) => {
					let $ele = angular.element($els[i]);
					let $fixedContentEle = $ele.find($ele.data(this.dataAttrSelector));
					fixedContentElements.push($fixedContentEle);
				});
				return fixedContentElements;
			};
			this.getComputedTranslateX = (obj) => {
				if (!window.getComputedStyle) return;
				let style = getComputedStyle(obj);
				let transform = style.transform || style.webkitTransform || style.mozTransform;
				let mat = transform.match(/^matrix3d\((.+)\)$/);
				if (mat) return parseFloat(mat[1].split(', ')[13]);
				mat = transform.match(/^matrix\((.+)\)$/);
				return mat ? parseFloat(mat[1].split(', ')[4]) : 0;
			};
		}
	}
})();
