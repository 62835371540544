(function() {
	angular
		.module('fca.brand.minivan')
		.directive('fcaMinivanScrollTo', fcaMinivanScrollTo);

	function fcaMinivanScrollTo($window, $rootScope) {
		'ngInject';

		return {
			restrict: 'A',
			scope: true,
			link: function($scope, $element) {
				let target = $element.data('scrollTarget');
				let speed = $element.data('scrollSpeed') || 200;
				let stickyPanelHeight;

				$element.on('click', function(event) {
					event.preventDefault();
					console.log(target);
					if (target === 'zones_body_blocks_features') {
						$rootScope.$broadcast('open-first-accordion');
					}

					if ($(event.target.offsetParent).hasClass('legal-tooltip-label')) {
						return;
					}

					$('html, body').animate({
						scrollTop: $(`#${target}`).offset().top
					}, speed);

					let buttonMatching = $($element.get(0).hash);
					if (buttonMatching.length > 0) {
						buttonMatching.click();
					}
				});
			},
			controller: fcaMinivanScrollToController
		};

		function fcaMinivanScrollToController($location, $element, $anchorScroll) {
			'ngInject';

			let hashValue;

			this.$onInit = () => {
				hashValue = $location.hash();
				this.goToHashAnchor();
			};

			this.goToHashAnchor = () => {
				if($element.attr('data-scroll-target') == hashValue) {
					$anchorScroll.yOffset = getOffset();

					$anchorScroll(hashValue);
				}
			};
		}
	}
})();
