(function () {
    angular
        .module('fca.buildAndPrice.optionsSummary')
        .component('buildAndPriceOptionsSummary', {
            controller: BuildAndPriceOptionsSummary,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/options-summary/options-summary.html',
            bindings: {
                hasClassStrategy: '@'
            }
        });

    function BuildAndPriceOptionsSummary($scope, $rootScope, $translate, configService, trimService, optionsSummaryService) {
        'ngInject';

        let $ctrl = this;

        $ctrl.options = {};


        $ctrl.viewSelectedPackageAndTrim = [];

        $ctrl.$onInit = () => {
            $ctrl.isOneClassStrategy = $ctrl.hasClassStrategy === 'true';
            optionsSummaryService.setOneClassStrategy($ctrl.isOneClassStrategy);

            $ctrl.viewCategoriesObjects = {};
            $ctrl.groupOptionsContent = [];
            $ctrl.selectedTrim = {};
            $ctrl.selectedPackage = {};

            $ctrl.viewCategoriesObjects = optionsSummaryService.formatSelectedOptions(configService.getCategoriesObjects(),
                configService.getSelectedTrim(),
                configService.getSelectedPackage());

            $scope.$on('packages-selector:finished-sending-options', (event, data) => {
                $ctrl.viewCategoriesObjects = optionsSummaryService.formatSelectedOptions(data,
                    configService.getSelectedTrim(),
                    configService.getSelectedPackage());
            });

            $scope.$on('alert-window:select-options-proceed', (event, data) => {
                $ctrl.viewCategoriesObjects = optionsSummaryService.formatSelectedOptions(
                    configService.getCategoriesObjects(),
                    configService.getSelectedTrim(),
                    configService.getSelectedPackage()
                );
            });

            $scope.$on('packages-selector:selected-trim-updated', (event, data) => {
                $ctrl.viewCategoriesObjects = optionsSummaryService.formatPackageAndTrimInformation(data.selectedTrim.packages[0],
                    data.selectedTrim,
                    $ctrl.viewCategoriesObjects,
                    configService.getCategoriesObjects());
            });

            $scope.$on('packages-selector:selected-package-updated', (event, data) => {
                $ctrl.viewCategoriesObjects = optionsSummaryService.formatPackageAndTrimInformation(
                    data.package,
                    null,
                    $ctrl.viewCategoriesObjects,
                    configService.getCategoriesObjects());
            });
        };

        $ctrl.optionHasContent = (option) => {
            if (!$ctrl.groupOptionsContent) {
                return false;
            }

            if (option.content) {
                $ctrl.formatGroupContent(option);
            }

            return !!$ctrl.groupOptionsContent[option.code];
        }

        $ctrl.goToTab = (category) => {
            $rootScope.$broadcast('navigation: active-step-change', { step: optionsSummaryService.categoriesMapForNav[category] });
        }

        $ctrl.toggleConfigSection = key => {
            if (key === $ctrl.openCategoryKey) {
                $ctrl.openCategoryKey = undefined;
            } else {
                $ctrl.openCategoryKey = key;
            }

            // Reset open row
            $ctrl.expandedOptionCode = undefined;
        }
        
        $ctrl.toggleRowContent = ($event, option) => {
            if (option.code === $ctrl.expandedOptionCode) {
                $ctrl.expandedOptionCode = undefined;
            } else {
                $ctrl.expandedOptionCode = option.code;
            }
            $event.stopPropagation();
            $event.preventDefault();
        }

        $ctrl.formatGroupContent = (groupOption) => {
            // Content is a ^ separated string. Here we parse it to get the values separated into an array.
            let includedOptions = groupOption.content.split('^');

            // The content string often starts with a ^ and a space. In that case the first element is a whitespace.
            // If that is the case we remove it and set the rest of the array as the content.
            // We test to see if any characters are not whitespace.
            if (!(/\S/.test(includedOptions[0]))) {
                includedOptions = includedOptions.slice(1);
            }
            $ctrl.groupOptionsContent[groupOption.code] = includedOptions;
        }
    }
})();
