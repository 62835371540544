/* eslint-disable indent */
(function() {
    'use strict';

    angular
        .module('fca.closestDealerItem')
        .controller('closestDearlerItemController', Controller)
        .component('closestDealerItem', {
            controller: 'closestDearlerItemController',
            controllerAs: '$dealerItem',
            bindings: {
                selected: '<?',
                dealerData: '<?',
                dealerPosition: '@?',
                location: '<',
                isVisible: '<',
                dealerHours: '<',
                selectFavoriteDealerList: '<',
                analyticsCategory: '@',
                analyticsId: '@'
            },
            templateUrl: '/closest-dealer/closest-dealer-item/closest-dealer-item.html'
        });

    /**
     * @ngdoc controller
     * @name fca.closestDealerItem.controller:closestDearlerItemController
     * @description dealerItem Component controller
     * @example
     * <pre>[TODO]</pre>
     */
    function Controller($scope, $rootScope, externalConfigLoader) {
        'ngInject';

        /**
         * @ngdoc property
         * @name isSelected
         * @propertyOf fca.closestDealerItem.controller:closestDearlerItemController
         * @type {Boolean}
         * @description
         * True if the item is selected in list
         */
        this.isSelected = false;
        this.language = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('language');
        this.contactADealerUrl = (this.language == 'fr') ? "contacter-un-concessionnaire" : "contact-a-dealer";
        this.brandCode = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('name');
        // Prevents any duplicate ID for preferred dealers
        this.checkboxId = '0';

        this.siteGroupCode = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('siteGroupCode');

        // Should Map be hidden on mobile
        $scope.hideMapMobile = false;

        this.isVisible = false;

        /**
         * @ngdoc method
         * @name $onInit
         * @methodOf fca.closestDealerItem.controller:closestDearlerItemController
         * @description
         * Transform dealer object on component initialisation
         */
        this.$onInit = () => {
            this.setDistance();
            this.setDisplayPostalCode();
            this.isVisible = true;

            $scope.$on('showHideAddress', () => {
                this.isVisible = !this.isVisible;
            });
        };

        /**
         * @ngdoc method
         * @name $onChanges
         * @methodOf fca.closestDealerItem.controller:closestDearlerItemController
         * @description
         * Observe changes on isSelected and dealerData compoenent property
         */
        this.$onChanges = (o) => {
            this.isSelected = false;
            if (o.hasOwnProperty('selected') && o.selected.currentValue) {
                let current = o.selected.currentValue;
                if (current.dealerId === this.dealerData.dealerId) {
                    this.isSelected = true;
                    $scope.$emit('EVENT.dealerItemSelected', [current]);
                }
            }

            if (o.hasOwnProperty('dealerData') && o.dealerData.currentValue) {
                this.setDistance();
            }

            this.checkboxId = this.isSelected ? '1' : '0';
        };

        /**
         * @ngdoc method
         * @name setDistance
         * @methodOf fca.closestDealerItem.controller:closestDearlerItemController
         * @description
         * Round the property distance of dealer object
         */
        this.setDistance = () => {
            if (this.dealerData !== null) {
                this.dealerData.distance = Math.round(this.dealerData.distance || 0);
            }
        };

        this.setDisplayPostalCode = () => {
            if(this.dealerData !== null) {
                let postalCode = this.dealerData.zipPostal;
                if(postalCode !== null) {
                    let firstDigits = postalCode.substr(0, 3);
                    let lastDigits = postalCode.substr(3, 5);
                    this.dealerData.displayPostalCode = firstDigits + " " + lastDigits;
                }
            }
        };

        this.showHide = () => {
            $rootScope.$broadcast('showHideAddress');
        };
    }
})();
