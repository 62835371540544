(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselFuelEfficiency', fcaSlickCarouselFuelEfficiency);

	function fcaSlickCarouselFuelEfficiency() {
		return {
			restrict: 'A',
			bindToController: {},
			controllerAs: 'carouselFuelEfficiency',
			controller: FcaSlickCarouselFuelEfficiencyController
		};

		function FcaSlickCarouselFuelEfficiencyController($element, gtmAnalytics) {
			'ngInject';

			this.slider = $element;
			this.activeSlide;
			this.currentIndex = 0;

			this.$onInit = () => {
				this.slider.on('init', this.onInit);
			};

			this.onInit = (event, slick) => {

			};

			this.onAfterChange = (event, slick, currentSlide) => {
				this.currentIndex = currentSlide;
				this.activeSlide = slick.$slides.eq(currentSlide);
				this.trackPano(this.activeSlide);
			};

			this.trackPano = (slide) => {
				if(slide) {
					let dataAttr = slide.data();

					function removeTags(txt) {
						let rex = /(<([^>]+)>)/ig;
						return txt.replace(rex, '');
					}

					if (dataAttr) {
						let category = dataAttr.analyticsCategory;
						let label = removeTags(dataAttr.analyticsLabel);

						gtmAnalytics.trackEvent('event', {
							category: category,
							label: label
						});
					}
				}
			};
		};
	}
})();
