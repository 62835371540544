((angular) => {
	'use strict';

	angular
        .module('fca.dealerLocator')
        .service('contactADealerService', ContactADealerService);

	function ContactADealerService($http) {
		this.getElectricVehicleState = (brandCode, modelYearId) => {
			return $http.get(`/data/nameplates/${brandCode}/model-years/model-year-id/${modelYearId}`).then((result) => result.data.isElectricVehicle);
		}
	}
})(angular);
