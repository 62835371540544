(function () {
    angular
        .module('fca.brandApp')
        .directive('fcaFeaturedArticles', FcaFeaturedArticles);

    function FcaFeaturedArticles() {
        return {
            restrict: 'A',
			require: {
				autoshowOverlayCtrl: '?^autoshowOverlay'
			},
			scope: true,
            bindToController: {
            },
            controllerAs: '$featuredArticlesCtrl',
            controller: FcaFeaturedArticlesController
        };

        function FcaFeaturedArticlesController($sce, $location, $timeout, $rootScope) {
            'ngInject';

            const $featuredArticlesCtrl = this;

			$featuredArticlesCtrl.language = FCA_SITES_CONFIG.language;
			$featuredArticlesCtrl.pageContent = null;

			$featuredArticlesCtrl.getContent = (articleName, event) => {
				let baseHref = $location.protocol() + '://' + $location.host();
				let eventTarget = event.target;

				let ajaxUrl = baseHref + '/'
					+ $featuredArticlesCtrl.language + '/'
					+ articleName + '/partial/zone/body';

				if($featuredArticlesCtrl.autoshowOverlayCtrl) {
					$featuredArticlesCtrl.autoshowOverlayCtrl.setLoader(true);
				}

				$.ajax({
					url: $featuredArticlesCtrl.trustSrc(ajaxUrl),
					type: 'GET',
					success: function(data) {
						$timeout(() => {
							$featuredArticlesCtrl.pageContent = data;

							if($featuredArticlesCtrl.autoshowOverlayCtrl) {
								$featuredArticlesCtrl.autoshowOverlayCtrl.manageOverlayOpen(eventTarget);
							}
						});
					}
				});
			};

			$rootScope.$on('autoshow-overlay:closed', () => {
				$timeout(() => {
					$featuredArticlesCtrl.pageContent = null;
				});
			});

			$featuredArticlesCtrl.trustSrc = (url) => {
				return $sce.trustAsResourceUrl(url);
			};
        }
    }
})();
