(function() {
	angular
		.module('fca.commonLegalNotes')
		.component('fcaDisclaimersList', {
			transclude: true,
			template: '<div ng-if="$ctrl.showDisclaimers"><ng-transclude/></div>',
			controller: FcaDisclaimersListController
		});

	function FcaDisclaimersListController($location) {
		'ngInject';

		this.$onInit = () => {
			let urlParams = $location.search();

			this.showDisclaimers = (urlParams.source) && (urlParams.source === 'ad');
		};
	}
})();
