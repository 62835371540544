(function () {
	angular
		.module('fca.commonDirectives')
		.directive('fcaPaintChips', FcaPaintChips);

	function FcaPaintChips() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@',
				paintCode: '@',
				paintImagePath: '@',
				paintVideoPath: '@',
			},
			controllerAs: '$fcaPaintChipsCtrl',
			controller: FcaPaintChipsCtrl
		};

		function FcaPaintChipsCtrl($element,$timeout) {
			const $ctrl = this;
			$ctrl.failedPaintChipsVideos = [];
			$ctrl.showPaintChips = false;
			$ctrl.paintChipsVideoSrc = '';

			$ctrl.$onInit = () => {
				$ctrl.paintChipsVideoSrc = `${$ctrl.baseUrl}/videos/common/paint/${$ctrl.paintVideoPath}`;
				$ctrl.showPaintChips = false;
				// On escape keydown
				document.onkeydown = event => {
					if (event.keyCode === 27) {
						$ctrl.closePaintChips();
					}
				};
				forceFirstPaintChipsToPlay();
			};

			const forceFirstPaintChipsToPlay = () => {
				$timeout(function() {
					$element.find('video')[0].play();
				}, 0);
			};

			$ctrl.openPaintChips = () => {
				const paintChipsContainerParent = document.querySelectorAll('.VLP2021-E360');
				const paintChipsContainer = document.querySelectorAll('.paint-chips-container');
				for (let i = 0; i < paintChipsContainerParent.length; i++) {
					paintChipsContainerParent[i].classList.add('-expanded');
				}
				if (paintChipsContainerParent.length == 0) {
					for (let i = 0; i < paintChipsContainer.length; i++) {
						paintChipsContainer[i].classList.add('-expanded');
					}
				}
			};

			$ctrl.closePaintChips = () => {
				const paintChipsContainerParent = document.querySelectorAll('.VLP2021-E360');
				const paintChipsContainer = document.querySelectorAll('.paint-chips-container');
				const paintChipsExpanded = document.querySelectorAll('.VLP2021-E360.-expanded');
                for (let i = 0; i < paintChipsContainerParent.length; i++) {
                    paintChipsContainerParent[i].classList.remove('-expanded');
                }
				if (paintChipsExpanded.length == 0) {
					for (let i = 0; i < paintChipsContainer.length; i++) {
						paintChipsContainer[i].classList.remove('-expanded');
					}
				}
			};

		}
	}
})();