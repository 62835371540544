(function () {
    'use strict';

    angular
        .module('fca.commonDirectives')
        .directive('sniInventoryOverview', sniInventoryOverview);

    function sniInventoryOverview() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$sniInventoryOverviewCtrl',
            bindToController: {
                brandName: '@',
                incentAmount: '@',
                sniSearchBaseUrl: "@",
                nameplate: "@",
                year: "@",
                brand: "@",
                vin: "@",
                arrowAnalyticsLabel: "@?",
                verticalCarousel: "=?",
                previousArrowLabel: "@",
                nextArrowLabel: "@",
                daaPersonalization: "@"
            },
            controller: SniInventoryOverviewCtrl
        };

        function SniInventoryOverviewCtrl($element, $timeout, cleanCarousel, $http, $window, personalizationDaaScenario, fcaUserIdPersistentService, dealerDigitalRetailingService) {
            'ngInject';
            const $ctrl = this;
            let prevArrow;
            let nextArrow;

            $ctrl.$onInit = () => {
                $ctrl.currentFinanceMode = 'finance';

                let hashParameters = getHashParameters();

                $ctrl.currentFinanceMode = hashParameters && hashParameters.activeTab
                    ? hashParameters && hashParameters.activeTab
                    : $ctrl.currentFinanceMode;

                // try to get the previous url
                $ctrl.changeVehicleUrl = sessionStorage.getItem('C_NID-vehicle-inventory-list-url');

                // build a back url, if we can't find it from session storage
                if (!$ctrl.changeVehicleUrl) {
                    $ctrl.changeVehicleUrl = `${$ctrl.sniSearchBaseUrl}${$ctrl.brand}/${$ctrl.nameplate}#year=${$ctrl.year}`
                }

                if ($ctrl.daaPersonalization) personalizationDaaScenario.updateStatusWith($ctrl.vin, 'buy')

                $ctrl.inventoryIcon = {
                    'IN_STOCK': 'map',
                    'IN_TRANSIT': 'truck',
                    'ON_ORDER': 'paper'
                };

                $ctrl.inventoryStatus = {
                    'IN_STOCK': 'sni.list.nameplate.on-dealer-lot',
                    'IN_TRANSIT': 'sni.list.nameplate.in-transit',
                    'ON_ORDER': 'sni.list.nameplate.on-order'
                };
            };

            $ctrl.$postLink = () => {
                if($ctrl.arrowAnalyticsLabel) {
                    prevArrow = `<button role="button" class="left-arrow" aria-label="${$ctrl.previousArrowLabel}"><i class="fcaicon fcaicon-chevron-left" data-analyticsid="${$ctrl.arrowAnalyticsLabel}" data-analyticsvalue="previous"></i></button>`;
                    nextArrow = `<button role="button" class="right-arrow" aria-label="${$ctrl.nextArrowLabel}"><i class="fcaicon fcaicon-chevron-right" data-analyticsid="${$ctrl.arrowAnalyticsLabel}" data-analyticsvalue="forward"></i></button>`;
                } else {
                    prevArrow = `<button role="button" class="left-arrow" aria-label="${$ctrl.previousArrowLabel}"><i class="fcaicon fcaicon-chevron-left" data-analyticsid="invent-detail-previous"></i></button>`;
                    nextArrow = `<button role="button" class="right-arrow" aria-label="${$ctrl.nextArrowLabel}"><i class="fcaicon fcaicon-chevron-right" data-analyticsid="invent-detail-next"></i></button>`;
                }

                $('.C_NID-carousel-large').slick({
                    asNavFor: '.C_NID-carousel-small',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'ondemand',
                    arrows: false,
                    accessibility: true
                });

                const numImages = $('.C_NID-carousel-small').data('num-slides');
                const numThumbnails = Math.min(numImages, 5);
                const numThumbnailsVertical = Math.min(numImages, 4);

                const defaultSmallSliderSettings = {
                    lazyLoad: 'ondemand',
                    slidesToShow: numThumbnails,
                    slidesToScroll: 1,
                    asNavFor: '.C_NID-carousel-large',
                    // Allows clicking on a thumbnail to advance both the main and
                    // thumbnail carousels
                    focusOnSelect: true,
                    accessibility: true,
                    nextArrow,
                    prevArrow
                };

                const verticalSmallSliderSettings = {
                    lazyLoad: 'ondemand',
                    slidesToShow: numThumbnailsVertical,
                    slidesToScroll: 1,
                    asNavFor: '.C_NID-carousel-large',
                    // Allows clicking on a thumbnail to advance both the main and
                    // thumbnail carousels
                    focusOnSelect: true,
                    vertical: true,
                    nextArrow,
                    prevArrow,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: numThumbnails,
                                vertical: false
                            }
                        }
                    ]
                };

                $('.C_NID-carousel-small').slick($ctrl.verticalCarousel ? verticalSmallSliderSettings : defaultSmallSliderSettings);

                $ctrl.makeVisibleSlidesFocusable = () => {
                    $element.find('.C_NID-carousel-small .slick-track>.slick-active').attr('tabindex', '0');
                };

                $ctrl.makeVisibleSlidesFocusable();

                $element.on('afterChange', function(slick, currentSlide) {
                    $timeout(() => {
                        $ctrl.makeVisibleSlidesFocusable();
                    })
                });

                $('.C_NID-smooth-scroll').click(function () {
                    $('html, body').animate({
                        scrollTop: $($(this).attr('href')).offset().top
                    }, 500);

                    return false;
                });

                cleanCarousel.makeAccessible($('.C_NID-carousel-small'));
                cleanCarousel.makeAccessible($('.C_NID-carousel-large'));

                // Manage Affiliate mode
                storeAffiliateId();
                $ctrl.affiliateId = isAffiliateMode() ? getStoredAffiliateId() : null;
                if(isAffiliateMode()) $('#vehicle-selector-affiliate-logo').addClass('active');

                // TODO: stop using that mechanism
                angular.element(document).ready(function () {
                    $('.C_NID-change-vehicle-hook').attr('href', $ctrl.changeVehicleUrl);
                });
            };

            $ctrl.slickArrowsAnalytics = () => {
                console.log('slickArrowsAnalytics');
            }

            $ctrl.getWindowSticker = (brandBaseUrl, vin, lang, campaign, affiliateId) => {
                var path = brandBaseUrl + '/' + lang + '/windowsticker/' + vin;
                var params = "?campaign=" + campaign + "&affiliate=" + affiliateId;

                $window.open(path + params);
            }

            $ctrl.getUserId = () => {
                return fcaUserIdPersistentService.getCookie('userId');
            };

            $ctrl.getDealerDigitalRetailingUrl = (matrixUrl, dealerUrl, vin) => {
                return dealerDigitalRetailingService.getDealerDigitalRetailingUrl(matrixUrl, dealerUrl, vin);
            }

            $ctrl.onStartBuyingProcessClick = (event, sniBuyUrl) => {
                return dealerDigitalRetailingService.onStartBuyingProcessClick(event, sniBuyUrl);
            }
        };
    }
})();
