(function() {
	angular
		.module('fcaModelSpecs')
		.service('modelSpecsGrid', ModelSpecsGrid);

	function ModelSpecsGrid($rootScope, $timeout, gtmAnalytics) {
		this.currentSlideIndex = 0;

		this.hiddenTrims = null;

		this.totalTrims = 0;

		this.totalVisibleTrims = 0;

		this.showDifferencesOnly = false;

		$timeout(()=> {
			$('body').addClass('model-specs-loaded');
		}, 500);

		this.switchChange = (value) => {
			if (!this.showDifferencesOnly && value == true) {
				this.hideIndenticalTrims();
			} else {
				this.showIdenticalTrims();
			}

			this.showDifferencesOnly = value;
		};

		this.hideIndenticalTrims = () => {
			$rootScope.$emit('modelSpecsGrid.hideIndenticalTrims');

			this.triggerTracking('show differences');
		};

		this.showIdenticalTrims = () => {
			$rootScope.$emit('modelSpecsGrid.showIdenticalTrims');

			this.triggerTracking('show differences');
		};

		this.hideTrim = (indexNum) => {
			if (!this.hiddenTrims) {
				this.hiddenTrims = [];
			}

			this.hiddenTrims.push(Number(indexNum));

			this.totalVisibleTrims--;
			this.visibleTrimChange();

			this.showResetButton();

			$rootScope.$emit('modelSpecsGrid.hideTrim', indexNum, this.hiddenTrims);
		};

		this.visibleTrimChange = (trimTotal = this.totalVisibleTrims) => {
			$rootScope.$emit('modelSpecsGrid.totalVisibleTrims', trimTotal);
		};

		this.resetHiddenTrims = () => {
			this.hiddenTrims = null;
			this.totalVisibleTrims = this.totalTrims;
			this.visibleTrimChange();
			$rootScope.$emit('modelSpecsGrid.resetCarousels');
		};

		this.setTotalTrims = (trimCount) => {
			this.totalTrims = trimCount;
			this.totalVisibleTrims = trimCount;
			this.visibleTrimChange(trimCount);
			$rootScope.$emit('modelSpecsGrid.totalTrims', trimCount);
		};

		this.masterCarouselChange = (slide) => {
			this.currentSlideIndex = slide;

			$rootScope.$emit('modelSpecsGrid.masterCarouselChange', slide);
		};

		this.showResetButton = () => {
			$rootScope.$emit('modelSpecsGrid.showResetButton');
		};

		this.windowWasResized = () => {
			$rootScope.$emit('modelSpecsGrid.windowWasResized');
		};

		this.triggerTracking = (label) => {
			gtmAnalytics.trackEvent('event', {
				category: 'models and specs',
				label: label
			});
		};
	}
})();
