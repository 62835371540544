(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaCurrency', Currency);

    function Currency(
        $filter) {
        'ngInject';

        // Filter currency
        // Current language
        let language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');

        return function(pPrice) {
            if (!pPrice) {
                pPrice = 0;
            }

            let price = $filter('number')(pPrice, 0);
            if (language === 'fr') {
                price = price.replace(/[,]/ig, ' ').replace(',', ' ');
            }

            return price.replace(/\.\d+/ig, '');
        };
    }
})();
