(function(ng) {
	'use strict';

	angular
		.module('fca.scenarioOrchestratorService')
		.provider('scenarioOrchestrator', ScenarioOrchestratorProvider);

	function ScenarioOrchestratorProvider() {
		let $service = this;

		$service.personalizationPriorities = window.FCA_SITES_CONFIG.personalizationPriorities;
		$service.eligibleScenarios = [];
		$service.modalIsOpen = false;
		$service.personalizationDaaScenarioData = {}

		$service.$get = function() {
			return {
				registerScenario: (scenario) => $service.registerScenario(scenario),
				canShowModal: (scenario) => $service.canShowModal(scenario),
				daaScenarioData: () => $service.daaScenarioData(),
				modalIsOpen: $service.modalIsOpen
			}
		}

		// 1. Register eligible Scenarios for the page
		$service.registerScenario = scenario => $service.eligibleScenarios.push(scenario)

		// 2. Loop through the eligible scenarios and get the ones that respect their conditions and add priority
		$service.listOfScenario = () => $service.eligibleScenarios.reduce((reducer, item) => {
			if ($service.scenarioCondition[item](item)) reducer.push({scenarioName: item, priority: $service.personalizationPriorities[item]});
			return reducer
		}, []);

		// 3. Scenarios conditions
		$service.scenarioCondition = {
			fcaPersonalizationScenario_1_1: (scenarioName) => {
				let localStorageName = $service.getLocalStorageName(scenarioName);
				let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
				if (fcaPersonalizationObject) {
					let afterPreQuietThreshold = $service.isAfterPreQuietThreshold(fcaPersonalizationObject.preQuietThresholdScenario_1_1);
					console.debug("scenario 1.1 triggered", fcaPersonalizationObject.triggerScenario_1_1);
					console.debug("after pre quiet threshold", afterPreQuietThreshold);
					return (fcaPersonalizationObject.triggerScenario_1_1 && afterPreQuietThreshold)
				}
				return false
			},
			fcaPersonalizationScenario_1_2: (scenarioName) => {
				let localStorageName = $service.getLocalStorageName(scenarioName);
				let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
				if(fcaPersonalizationObject) {
					let afterPreQuietThreshold = $service.isAfterPreQuietThreshold(fcaPersonalizationObject.preQuietThresholdScenario_1_2);
					console.debug("scenario 1.2 triggered", fcaPersonalizationObject.triggerScenario_1_2);
					console.debug("after scenario 1.2 pre quiet threshold", afterPreQuietThreshold);
					return (fcaPersonalizationObject.triggerScenario_1_2 && afterPreQuietThreshold)
				}
				return false
			},
			fcaPersonalizationScenario_2_1: (scenarioName) => {
				let data = JSON.parse(window.localStorage.getItem('fcaPersonalizationScenario_2'));
				if(data) {
					let beforeExpiryDate = "expiryDate" in data ? new Date(data.expiryDate) >= new Date() : false;
					let afterPreDelayDate = "preDelayDate" in data ? new Date(data.preDelayDate) <= new Date() : false;
					return "complete" in data && data.complete === false && beforeExpiryDate && afterPreDelayDate;
				}
				return false
			},
			fcaPersonalizationScenario_2_2: (scenarioName) => {
				let data = JSON.parse(window.localStorage.getItem('fcaPersonalizationScenario_2'));
				if(data) {
					let beforeExpiryDate = "expiryDate" in data ? new Date(data.expiryDate) >= new Date() : false;
					let afterPreDelayDate = "preDelayDate" in data ? new Date(data.preDelayDate) <= new Date() : false;
					let notFound = "notFound" in data ? data.notFound : false;
					return "complete" in data && data.complete === true && beforeExpiryDate && afterPreDelayDate && !notFound;
				}
				return false
			},
			fcaPersonalizationScenario_5_1: (scenarioName) => {
				let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(scenarioName));
				if (fcaPersonalizationObject) {
					$service.personalizationDaaScenarioData = {
						vin: fcaPersonalizationObject.vin,
						nameplatecode: fcaPersonalizationObject.nameplatecode,
						modelyearid: fcaPersonalizationObject.modelyearid,
						offerid: fcaPersonalizationObject.offerid,
						acode: fcaPersonalizationObject.acode,
						status: fcaPersonalizationObject.status,
						trimgroup: fcaPersonalizationObject.trimgroup,
						year: fcaPersonalizationObject.year
					}

					return $service.isAfterPreQuietThreshold(fcaPersonalizationObject.preQuietThresholdScenario) && $service.otherModalNotPresent()
				}
				return false
			},
			fillTheFunnelVlpTrigger: () => {
				return $service.otherModalNotPresent()
			}
		}
		// 4. Ordered scenario per priorities
		$service.orderedScenario = () => $service.listOfScenario().sort((lhs, rhs) => lhs.priority - rhs.priority);

		// 5. Verify we can show scenario - Match the #1 priority with the scenario
		$service.canShowModal = scenario => $service.orderedScenario().length > 0 && $service.orderedScenario()[0].scenarioName == scenario;

		$service.daaScenarioData = () => $service.personalizationDaaScenarioData;

		const modelYearId =  (window.FCA_SITES_CONFIG.modelYearIds && window.FCA_SITES_CONFIG.modelYearIds.length > 0) ? window.FCA_SITES_CONFIG.modelYearIds[0] : "";

		// Utils for conditions
		$service.getLocalStorageName = scenarioName => scenarioName + '_' + modelYearId;

		$service.isAfterPreQuietThreshold = preQuietThreshold => {
			let nowDate = new Date();
			let preQuietThresholdDate = new Date(preQuietThreshold ? preQuietThreshold : null);
			let afterPreQuietThreshold = nowDate >= preQuietThresholdDate;
			console.debug('After scenario 1.2 pre quiet threshold', afterPreQuietThreshold);
			return afterPreQuietThreshold;
		}

		$service.otherModalNotPresent = () => !document.querySelector('html').classList.contains("ngdialog-open") && !$service.modalIsOpen;
	}
})(angular);
