(function() {
    angular
        .module('fca.cookieBanner')
        .directive('fcaCookieBannerOverlay', fcaCookieBannerOverlay);

    function fcaCookieBannerOverlay() {
        return {
            restrict: 'A',
            controller: fcaCookieBannerOverlayController,
            controllerAs: '$ctrlOverlay',
            scope: true
        };
    }
    function fcaCookieBannerOverlayController($scope, $element) {
        'ngInject';

        const $ctrl = this;

        $ctrl.initialLink = null;

        $ctrl.$postLink = () => {

            let focusableElements;
            let firstFocusableElement;
            let lastFocusableElement;

            $scope.$on('cookie-banner-overlay', function (event, el) {
                el.on('click', function (event) {
                    event.preventDefault();
                    $element.addClass('is-active');
                    $('html').addClass('overlay-active');

                    focusableElements = $element.find('i[tabindex=0], a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
                    firstFocusableElement = focusableElements[0];
                    lastFocusableElement = focusableElements[focusableElements.length - 1];

                    firstFocusableElement.focus();

                    $ctrl.initialLink = el;
                });
            });

            $element.find('.fcaicon-close').on('click', () => {
                $ctrl.closeOverlay();
            });

            //a11y close modal on keypress Enter
            $element.find('.fcaicon-close').on('keydown', keyLog => {
                if (keyLog.key === "Enter") {
                    $ctrl.closeOverlay();
                }
            });

            $element.on('click', () => {
                $ctrl.closeOverlay();
            });

            document.addEventListener('keydown', keyLog => {
                if (keyLog.key === "Escape") {
                    $ctrl.closeOverlay();
                } else if (keyLog.key === "Tab") {
                    if (keyLog.shiftKey) {
                        // shift + tab (backward <-)
                        if (document.activeElement === firstFocusableElement) {
                            lastFocusableElement.focus();
                            keyLog.preventDefault();
                        }
                    } else {
                        // tab (forward ->)
                        if (document.activeElement === lastFocusableElement) {
                            firstFocusableElement.focus();
                            keyLog.preventDefault();
                        }
                    }
                }
            });

            $element.find('.cookieBannerOverlay-wrapper').on('click', (e) => {
                e.stopPropagation();
            });
        };

        $ctrl.closeOverlay = () => {
            $element.removeClass('is-active');
            $('html').removeClass('overlay-active');

            if ($ctrl.initialLink) {
                $ctrl.initialLink.focus();
            }
        };

    }

})();
