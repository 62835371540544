(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselAwards', FcaSlickCarouselAwards);

	function FcaSlickCarouselAwards() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselAwardsController,
			link: FcaSlickCarouselAwardsLink
		};

		function FcaSlickCarouselAwardsController(gtmAnalytics) {
			'ngInject';

			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let dataAttr = slick.$slides.eq(nextSlide).data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label
					});
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselAwardsLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
