(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaHandraiserTrigger', fcaHandraiserTrigger);

	function fcaHandraiserTrigger() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'handraiserTrigger',
			controller: FcaHandraiserTriggerController
		};

		function FcaHandraiserTriggerController($element, $window, $rootScope, $location) {
			'ngInject';

			this.$onInit = () => {
				this.triggerFromUrl();
				$element.bind('click', this.onClick);
			};

			this.onClick = (event) => {
				event.preventDefault();
				$rootScope.$emit('handraiser.open');
			};

			this.triggerFromUrl = () => {
				angular.element(document).ready(() => {
					let urlParams = $location.search();

					if (urlParams.hrForm && $('[data-fca-handraiser]').length) {
						$rootScope.$emit('handraiser.open');
					}
				});
			};
		}
	}
})();
