(function() {
	angular
		.module('fcaWaypoints')
		.directive('fcaInview', fcaInview);

	function fcaInview() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				inviewId: '@',
				inviewClass: '@?',
				inviewOnEnterFn: '&?',
				inviewOnEnteredFn: '&?',
				inviewOnExitFn: '&?',
				inviewOnExitedFn: '&?'
			},
			controller: FcaInviewController
		};

		function FcaInviewController($rootScope, $element) {
			'ngInject';

			this.inviewClass = this.inviewClass || 'is-inview';

			new Waypoint.Inview({
				element: $element[0],
				enter: (direction) => {
					$element.addClass(this.inviewClass);
					// console.log('Enter triggered with direction ' + direction, this.inviewId);

					$rootScope.$emit(`fcaInview.enter.${this.inviewId}`, direction);

					if (this.inviewOnEnterFn) {
						this.inviewOnEnterFn(direction);
					}
				},
				entered: (direction) => {
					$element.addClass(this.inviewClass);
					// console.log('Entered triggered with direction ' + direction, this.inviewId);

					$rootScope.$emit(`fcaInview.entered.${this.inviewId}`, direction);

					if (this.inviewOnEnteredFn) {
						this.inviewOnEnteredFn(direction);
					}
				},
				exit: (direction) => {
					$element.removeClass(this.inviewClass);
					// console.log('Exit triggered with direction ' + direction, this.inviewId);

					$rootScope.$emit(`fcaInview.exit.${this.inviewId}`, direction);

					if (this.inviewOnExitFn) {
						this.inviewOnExitFn(direction);
					}
				},
				exited: (direction) => {
					$element.removeClass(this.inviewClass);
					// console.log('Exited triggered with direction ' + direction, this.inviewId);

					$rootScope.$emit(`fcaInview.exited.${this.inviewId}`, direction);

					if (this.inviewOnExitedFn) {
						this.inviewOnExitedFn(direction);
					}
				}
			});
		}
	}
})();
