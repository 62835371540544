(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaromeoVlpGallerySlider', alfaromeoVlpGallerySlider);

	function alfaromeoVlpGallerySlider() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				previousArrowLabel: '@',
				nextArrowLabel: '@'
			},
			controllerAs: 'vlpGallerySlider',
			controller: alfaromeoVlpGallerySliderController
		};

		function alfaromeoVlpGallerySliderController($scope, $element, $timeout, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			let prevArrow;
			let nextArrow;

			$ctrl.slickSlider = $element.find('.js-vlp-gallery-container');
			$ctrl.activeSlide = 0;

			this.initSlickCarousel = () => {
				$ctrl.slickSlider.slick({
					autoplay: false,
					dots: false,
					speed: 500,
					variableWidth: true,
					infinite: true,
					prevArrow,
					nextArrow,
					responsive: [
						{
							breakpoint: 667,
							settings: {
								arrows: false
							}
						}
					]
				});
			};

			this.updateSliderCount = (currentSlide, nextSlide) => {
				$timeout(() => {
					$ctrl.activeSlide = nextSlide + 1;
				});
			};

			this.trackPano = (slide, nextSlide) => {
				let dataAttr = slide.data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					$ctrl.sendTracking(category, label);
				}
			};

			this.sendTracking = (pCategory, pLabel) => {
				gtmAnalytics.trackEvent('event', {
					category: pCategory,
					label: pLabel
				});
			};

			$ctrl.slickSlider.on('afterChange', function(event, slick, currentSlide, nextSlide) {

				const selectedSlide = $(slick.$slides.get(currentSlide));

				if (selectedSlide.prev().find('.video').length || selectedSlide.next().find('.video').length) {
					$scope.$broadcast('vlpGalleryOutOfVideo');
				}
				if (selectedSlide.find('.video').length) {
					const getVideoUrl = angular.element($(slick.$slides.get(currentSlide))).find('.video').attr('data-video-path');
					$scope.$broadcast('vlpGalleryOnVideo', getVideoUrl);
				}

				$timeout(() => {
					if ($ctrl.nextPressed) {
						$('.slick-slider .next').focus();
						$ctrl.nextPressed = false;
					}

					if ($ctrl.prevPressed) {
						$('.slick-slider .prev').focus();
						$ctrl.prevPressed = false;
					}
				})
			});

			$ctrl.slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				$ctrl.updateSliderCount(currentSlide, nextSlide);

				let slide = slick.$slides.eq(nextSlide);
				$ctrl.trackPano(slide, nextSlide);
			});

			$scope.$on('vlp-gallery-slide-change', function (event, data) {
				let newSlide = data.slide;
				$ctrl.slickSlider.slick('slickGoTo', newSlide, true);
			});

			$ctrl.$onInit = () => {
				prevArrow = `<button role="button" class="prev" aria-label="${$ctrl.previousArrowLabel}"></button>`;
				nextArrow = `<button role="button" class="next" aria-label="${$ctrl.nextArrowLabel}"></button>`;

				$timeout(() => {
					this.initSlickCarousel();

					const prevArrowButton = $element.find('.prev');
					const nextArrowButton = $element.find('.next');

					nextArrowButton.on('click', function() {
						$ctrl.nextPressed = true;
					});

					prevArrowButton.on('click', function() {
						$ctrl.prevPressed = true;
					});
				});
			};
		}
	}
})();
