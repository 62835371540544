/*
 *
 *
 *	new IrisUrlBuilder()
 * 		.preset(IrisPresets.EXTERIOR)
 *
 *		.vehicule('2018_GU')
 *		.mfgCode('CODE')
 *		.packageCode('CODE')
 *      .cposVehicleType('D')
 *		.pov('E32')
 *
 *		.paint('PAINT')
 *		.fabric('FABRIC')
 *		.options(['WHEEL', 'MIRROIR'])
 *
 *		.background('white')
 *		.capture(1920, 800, 0, 0)
 *		.output(800, 600)
 *		.shadow(true)
 *		.getUrl();
 */
(function () {
	'use strict';

	angular
		.module('alfaromeo.iris')
		.service('alfaIrisService', AlfaIrisService);

	function AlfaIrisService(alfaBp, alfaJellyBuilder, externalConfigLoader) {

		const config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
		const baseIrisPath = config.getConfig('irisJellyBaseUrl') + '/mediaserver/iris?';
		const $service = this;

		$service.getUrlBuilder = () => {
			return new IrisUrlBuilder();
		};
		$service.calculateImageSize = (width, ratio) => {
			const w = Math.round(width * window.devicePixelRatio);
			return {
				width: w,
				height: Math.round(w / ratio),
			};
		};

		const IrisUrlBuilder = () => {

			const me = {};
			const values = {
				vehicule: null,
				mfgCode: null,
				packageCode: null,
				cposVehicleType: null,
				pov: null,

				paint: null,
				fabric: null,
				options: null,

				background: null,
				width: null,
				height: null,
				w: null,
				h: null,
				x: null,
				y: null,
				shadow: null,
			};

			const getValue = ($property) => {
				return values[$property];
			};
			const setValue = ($property, $value) => {
				values[$property] = $value;
			};
			const handleResponse = ($property, $value) => {
				if( $value !== undefined ) {
					setValue($property, $value);
					return me;
				} else {
					return getValue($property);
				}
			};

			const replaceChar = ($text, $index, $character) => {
				return $text.substr(0, $index) + $character + $text.substr($index + 1);
			};
			const createCposPackage = ($code) => {
				return replaceChar($code, 1, values.cposVehicleType || 'D');
			};
			const removeNullDataFromObject = ($object) => {
				const entries = Object.entries($object);
				for( const [key, value] of entries ) {
					if( value === null ) {
						$object[key] = '';
						delete $object[key];
					};
				};
			};


			me.preset = ($preset) => {
				// copy all non-null values from preset
				const entries = Object.entries($preset);
				for( const [key, value] of entries ) {
					if( value !== null ) {
						values[key] = value;
					};
				};

				// if preset has a defined shadow value
				// we need to pass it through or internal shadow method
				// to ensure data is handled correctly
				if( $preset.hasOwnProperty('shadow') ) {
					me.shadow($preset.shadow);
				}

				return me;
			};
			me.getURL = () => {

				const irisParams = {
					client: 'FCAUS',
					market: 'U',
					brand: 'Y',

					vehicle: getValue('vehicule'),
					pov: getValue('pov'),

					paint: getValue('paint'),
					fabric: getValue('fabric'),

					bkgnd: getValue('background'),
					width: getValue('width'),
					height: getValue('height'),
					w: getValue('w'),
					h: getValue('h'),
					x: getValue('x'),
					y: getValue('y'),
					noshadow: getValue('shadow'),
				};

				removeNullDataFromObject(irisParams);

				const queryString = alfaBp.encodeQueryParameters(irisParams);
				const additionalOptions = alfaJellyBuilder.makeSaParameter([
					getValue('mfgCode'),
					getValue('packageCode'),
					createCposPackage( getValue('packageCode') ),
					getValue('options')
				]);

				return baseIrisPath + queryString + additionalOptions;
			};

			me.vehicule = ($value) => {
				return handleResponse('vehicule', $value);
			};
			me.mfgCode = ($value) => {
				return handleResponse('mfgCode', $value);
			};
			me.packageCode = ($value) => {
				return handleResponse('packageCode', $value);
			};
			me.cposVehicleType = ($value) => {
				return handleResponse('cposVehicleType', $value);
			};
			me.pov = ($value) => {
				return handleResponse('pov', $value);
			};

			me.paint = ($value) => {
				return handleResponse('paint', $value);
			};
			me.fabric = ($value) => {
				return handleResponse('fabric', $value);
			};
			me.options = ($value) => {
				return handleResponse('options', $value);
			};

			me.background = ($value) => {
				return handleResponse('background', $value);
			};
			me.capture = ($width, $height, $x, $y) => {

				handleResponse('w', $width);
				handleResponse('h', $height);
				handleResponse('x', $x);
				handleResponse('y', $y);

				return me;
			};
			me.output = ($width, $height) => {

				handleResponse('width', $width);
				handleResponse('height', $height);

				return me;
			};
			me.shadow = ($value) => {
				// since our API use shadow (true|false) and Iris use noShadow (true|false)
				// we need to invert our value before return it to the user
				if( $value !== undefined ) return setValue($property, !$value);
				else return !getValue($property);
			};

			return me;
		};
	}
})();
