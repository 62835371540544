(function() {
	angular
		.module('fcaModelSpecs')
		.component('fcaModelSpecsControls', {
			controller: ModelSpecsControlsController,
			templateUrl: '/components/model-specs/fca-model-specs-controls/fca-model-specs-controls.html'
		});

	function ModelSpecsControlsController($rootScope, modelSpecsGrid) {
		'ngInject';

		this.totalTrims = 0;

		this.totalVisibleTrims = 0;

		this.showResetButton = false;

		this.showOnlyDiffs = (show) => {
			modelSpecsGrid.showOnlyDiffs(show);
		};

		this.resetCarousels = () => {
			modelSpecsGrid.resetHiddenTrims();
			this.showResetButton = false;
		};

		this.switchChange = ($event) => {
			modelSpecsGrid.switchChange($event.currentTarget.checked);
		};

		this.$onInit = () => {
			$rootScope.$on('modelSpecsGrid.totalVisibleTrims', (event, visibleSlidesCount) => {
				this.totalVisibleTrims = visibleSlidesCount;
			});

			$rootScope.$on('modelSpecsGrid.totalTrims', (event, totalTrimsCount) => {
				this.totalTrims = totalTrimsCount;
			});

			$rootScope.$on('modelSpecsGrid.showResetButton', () => {
				this.showResetButton = true;
			});
		};
	}
})();
