/* eslint-disable indent */
(function () {
  try {
    function init() {
		let urlPath = window.location.pathname;

		const affiliateEncryptedParam = getAffiliateParam('encpar');
		const affiliateEaProgram = getAffiliateParam('eaProgram');

		if (affiliateEncryptedParam) {
			sessionStorage.setItem('affiliateEncryptedParam', affiliateEncryptedParam)
		}
		if (affiliateEaProgram) {
			sessionStorage.setItem('affiliateEaProgram', affiliateEaProgram)
		}
    }

    function initializeMiniNav () {
      let currentStepName = 'models';
      let stepNumber = 1;

      $('.C_BP-main-navigation-item').removeClass('is-active');
      $('.C_BP-main-navigation-item').attr('aria-selected', 'false');
      $('.C_BP-main-navigation-item').attr('disabled', 'disabled');

      $('.C_BP-main-navigation-' + currentStepName).addClass('is-active');
      $('.C_BP-main-navigation-' + currentStepName).attr('aria-selected', 'true');
      $('.C_BP-main-navigation-' + currentStepName).removeAttr('disabled');
      $('.C_BP-main-navigation-' + currentStepName).focus();

      $('.C_BP-main-navigation a').each(function() {
          let navItem = $(this);
          if(navItem.is(':visible')) {
              navItem.find('span').html(stepNumber);
              stepNumber ++;
          }
      });
    }


    init();

  } catch(err) {
      console.error("error in trim-group-selector", err);
  }
})();
