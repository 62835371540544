(function () {
	angular
		.module('fca.buyOnlineServices')
		.directive('fcaBuyOnlineServices', FcaBuyOnlineServices);

	function FcaBuyOnlineServices() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				selectedServices: '@',
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineServicesCtrl
		};

		function FcaBuyOnlineServicesCtrl($scope, $rootScope, externalConfigLoader, fcaBuyOnlineServicesService) {
			'ngInject';

			let $ctrl = this;

			$ctrl.$onInit = () => {

				// Initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

				// Load existing services selected

				let vin = $ctrl.vin;

				let services = fcaBuyOnlineServicesService.getServiceSelection(vin);
				if (services != null) {
					$ctrl.selectedServices = services;
				}
			};

			$ctrl.haveSelectedServicesChanged = () => {
				let selected = $ctrl.selectedServices;
				let vin = $ctrl.vin;
				fcaBuyOnlineServicesService.updateServiceSelection(vin, selected);

				$rootScope.$broadcast('fca-custom-order:custom-order-services-review-update');
			}
		}
	}
})();
