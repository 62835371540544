(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaVehiclesLink', fcaVehiclesLink);

	function fcaVehiclesLink() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				touchRotation: '=?'
			},
			controllerAs: 'vehiclesLink',
			controller: fcaVehiclesLinkController
		};

		function fcaVehiclesLinkController($element, $rootScope, $interval, $window, gtmAnalytics) {
			'ngInject';

			this.currentStep = 0;
			this.canVideoPlay = true;
			this.videoLoopEnabled = false;
			this.videoIsVisible = false;
			let videoLoopTimer;

			this.onMouseOver = index => {
				this.currentStep = index;
				const currentVideoDiv = $element[0].querySelector(`.vehicles-links-video-${index}`);

				if (currentVideoDiv && this.canVideoPlay) {
					const currentVideo = currentVideoDiv.querySelector('video');
					if (currentVideo && currentVideo.pause) {
						currentVideo.play();
					}
				}

				if (angular.isDefined(videoLoopTimer)) {
					$interval.cancel(videoLoopTimer);
					videoLoopTimer = undefined;
				}
			}

			this.videoLoop = () => {
				let nextIndex = this.currentStep + 1;
				let currentVideoDiv = $element[0].querySelector(`.vehicles-links-video-${nextIndex}`);

				if (!currentVideoDiv) {
					nextIndex = 1;
				}

				this.videoLoopPlay(nextIndex);
			}

			this.videoLoopPlay = index => {
				this.currentStep = index;
				const currentVideoDiv = $element[0].querySelector(`.vehicles-links-video-${index}`);
				if (currentVideoDiv && this.canVideoPlay) {
					const currentVideo = currentVideoDiv.querySelector('video');
					if (currentVideo && currentVideo.pause) {
						currentVideo.play();
					}
				}
			}

			this.goToVideo = index => {
				this.videoLoopPlay(index);

				if (angular.isDefined(videoLoopTimer)) {
					$interval.cancel(videoLoopTimer);
					videoLoopTimer = $interval(this.videoLoop, 5000);
				}
			}

			this.$onInit = () => {
				if(this.touchRotation && 'ontouchstart' in document.documentElement) {
					this.currentStep = 1;
					this.videoLoopEnabled = true;
					this.goToVideo(1);
				}

				if ($element.find('[data-track-position]').length) {
					this.bindScrolling();
					this.bindResize();
					this.detectIfVisible();
				}
			}

			this.detectIfVisible = () => {
				let elementToTrack = $element.find('[data-track-position]');
				let windowHeight = $window.innerHeight;
				let elScrollTop = elementToTrack[0].getBoundingClientRect().top;
				let elScrollBottom = elementToTrack[0].getBoundingClientRect().bottom;
				let currentIndex = this.currentStep;
				let currentVideoDiv = $element[0].querySelector(`.vehicles-links-video-${currentIndex}`);

				if(elScrollTop <= windowHeight && elScrollBottom >= 0) {

					if(!this.videoIsVisible) {

						if(this.videoLoopEnabled && !angular.isDefined(videoLoopTimer)) {
							this.videoLoopPlay(currentIndex);
							videoLoopTimer = $interval(this.videoLoop, 5000);
						} else {

							if (currentVideoDiv && this.canVideoPlay) {
								let currentVideo = currentVideoDiv.querySelector('video');

								if (currentVideo && currentVideo.pause) {
									currentVideo.play();
								}
							}
						}

						this.videoIsVisible = true;
					}
				} else {
					if(this.videoIsVisible) {
						this.videoIsVisible = false;

						if (currentVideoDiv) {
							let currentVideo = currentVideoDiv.querySelector('video');

							if (currentVideo) {
								currentVideo.pause();
							}
						}

						if (angular.isDefined(videoLoopTimer)) {
							$interval.cancel(videoLoopTimer);
							videoLoopTimer = undefined;
						}
					}
				}
			}

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					this.detectIfVisible();
				});
			};

			this.bindResize = () => {
				angular.element($window).on('resize', () => {
					this.detectIfVisible();
				});
			};

			$rootScope.$on('onScrollingUpdate', () => {
				const parentElem = $($element[0]).parents('.vertical-scroll-block-container');
				if (parentElem && parentElem[0].querySelector('.AR-video-block-container video')) {
					const currentVideo = parentElem[0].querySelector('.AR-video-block-container video');
					if (currentVideo && (parentElem[0].classList.contains('is-current')) || parentElem[0].classList.contains('half-seen')) {
						currentVideo.play();
					} else if (currentVideo) {
						currentVideo.pause();
					}
				}
			});

			this.onVideoClick = () => {
				let analyticsId;

				if (this.canVideoPlay) {
					analyticsId = 'alfa-videos-pause-';

					$element.find('video').each((index, video) => {
						video.pause();
					});

					if (angular.isDefined(videoLoopTimer)) {
						$interval.cancel(videoLoopTimer);
						videoLoopTimer = undefined;
					}

					this.canVideoPlay = false;
				} else {
					let index = this.currentStep;
					let currentVideoDiv = $element[0].querySelector(`.vehicles-links-video-${index}`);

					analyticsId = 'alfa-videos-play-';

					if (currentVideoDiv) {
						let currentVideo = currentVideoDiv.querySelector('video');
						if (currentVideo && currentVideo.pause) {
							currentVideo.play();
						}
					}

					this.canVideoPlay = true;
				}

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label:  analyticsId
				});
			}
		}
	}
})();
