(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaShowcase', fcaShowcase);

	function fcaShowcase() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slideTotal: '=',
				hideHeaderArrows: '@?'
			},
			controllerAs: 'showcase',
			controller: fcaShowcaseController
		};

		function fcaShowcaseController($rootScope, $element, $timeout, gtmAnalytics) {
			'ngInject';

			this.activeSlide = 0;

			let showcase = this;
			let headerSlider = $element.find('.js-showcase-header');
			let primarySlider = $element.find('.js-showcase-primary');
			let secondarySlider = $element.find('.js-showcase-secondary');
			let textSlider = $element.find('.js-showcase-text');
			let logoSlider = $element.find('.js-showcase-logo');

			this.onInit = (event, slick) => {
				// Tracking of first slide
				let firstPano = angular.element('.slick-track .pano').eq(0);

				this.trackPano(firstPano, 0);
			};

			this.$onInit = () => {
				this.currentSlideNumber = this.activeSlide + 1;
				this.slideCount = this.slideTotal - 1;

				$timeout(() => {
					this.initHeaderSlider();
					this.initPrimarySlider();
					this.initSecondarySlider();
					this.initTextSlider();
					this.initLogoSlider();
				});
			};

			this.initHeaderSlider = () => {
				var arrowToggle = this.hideHeaderArrows?false:true;

				headerSlider.slick({
					infinite: true,
					autoplay: false,
					arrows: arrowToggle,
					fade: true,
					speed: 800,
				});
			}

			this.initPrimarySlider = () => {
				primarySlider.slick({
					infinite: true,
					autoplay: false,
					arrows: false,
					fade: true,
					speed: 800
				});
			}

			this.initSecondarySlider = () => {
				secondarySlider.slick({
					infinite: true,
					autoplay: false,
					arrows: false,
					fade: true,
					speed: 800
				});
			}

			this.initTextSlider = () => {
				textSlider.slick({
					infinite: true,
					adaptiveHeight: true,
					autoplay: false,
					arrows: false,
					fade: true,
					speed: 800
				});
			}

			this.initLogoSlider = () => {
				logoSlider.slick({
					infinite: true,
					autoplay: false,
					arrows: false,
					fade: true,
					speed: 800
				});
			}

			this.setShowcaseLocation = (currentSlide, nextSlide) => {
				let slideTotal = showcase.slideCount;

				if(currentSlide !== nextSlide) {
					$element.find('.slick-last-slide').removeClass('slick-last-slide');
					$element.find('.slick-active').addClass('slick-last-slide');
					$element.removeClass('slide-left slide-right');

					if(slideTotal > 1) {
						if((currentSlide < nextSlide && nextSlide - currentSlide === 1) || (currentSlide === slideTotal && nextSlide === 0)) {
							$element.addClass('slide-left');
						}

						if((currentSlide > nextSlide && (currentSlide - nextSlide === 1)) || (currentSlide === 0 && nextSlide === slideTotal)) {
							$element.addClass('slide-right');
						}
					} else {
						$element.addClass('slide-left');
					}

					headerSlider.slick('slickGoTo', nextSlide);
					primarySlider.slick('slickGoTo', nextSlide);
					secondarySlider.slick('slickGoTo', nextSlide);
					textSlider.slick('slickGoTo', nextSlide);
					logoSlider.slick('slickGoTo', nextSlide);

					$timeout(() => {
						showcase.activeSlide = nextSlide;
						showcase.currentSlideNumber = nextSlide + 1;
					});
				}
			}

			headerSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				showcase.setShowcaseLocation(currentSlide, nextSlide);
			});

			primarySlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				showcase.setShowcaseLocation(currentSlide, nextSlide);
			});

			secondarySlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				showcase.setShowcaseLocation(currentSlide, nextSlide);
			});

			textSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				showcase.setShowcaseLocation(currentSlide, nextSlide);

				let slide = slick.$slides.eq(nextSlide);
				showcase.trackPano(slide, nextSlide);
			});

			logoSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				showcase.setShowcaseLocation(currentSlide, nextSlide);
			});

			this.changeSlide = (direction) => {
				if(direction === 'previous') {
					headerSlider.slick('slickPrev');
				}

				if(direction === 'next') {
					headerSlider.slick('slickNext');
				}
			};

			this.sendTracking = (pCategory, pLabel) => {
				gtmAnalytics.trackEvent('event', {
					category: pCategory,
					label: pLabel
				});
			};

			this.trackPano = (slide, nextSlide) => {
				let dataAttr = slide.data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					this.sendTracking(category, label);
				}
			};
		}
	}
})();
