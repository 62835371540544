(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaDaaModelSelectorCarousel', fcaDaaModelSelectorCarousel);

	function fcaDaaModelSelectorCarousel() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				currentModel: '@',
			},
			controllerAs: '$daaModelCarousel',
			controller: FcaDaaModelSelectorCarouselController,
		};

		function FcaDaaModelSelectorCarouselController($element, $rootScope) {
			'ngInject';

			const $ctrl = this;

			const urlKeyParam = 'nameplate';

			$ctrl.$onInit = () => {
				if ($ctrl.currentModel === undefined) {
					$ctrl.currentModel = 'all';
				}
				$ctrl.setBodyClass($ctrl.currentModel);
				$ctrl.getModelByUrl();
			}

			$ctrl.switchModel = (value) => {
				this.currentModel = value;
				$ctrl.setBodyClass(value);
				$ctrl.setUrlParameter(value);
			}

			$ctrl.setUrlParameter = (value) => {
				const searchParams = new URLSearchParams(window.location.search);
				searchParams.set(urlKeyParam, value);

				const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
				history.pushState(null, '', newRelativePathQuery);

				$rootScope.$broadcast('daa-offer:set-url-nameplate', value);
			}

			$ctrl.getModelByUrl = () => {
				const urlParams = new URLSearchParams(window.location.search);

				if (urlParams.has(urlKeyParam)) {
					$ctrl.currentModel = urlParams.get(urlKeyParam);
				}

				$ctrl.setBodyClass($ctrl.currentModel)
			}

			$ctrl.setBodyClass = (value) => {
				let bodyClasses = document.querySelector('body').classList;

				const offersToReinitiate = document.querySelectorAll('.daa-offers-list-offer-item');

				offersToReinitiate.forEach(offerToReinitiate => {
					offerToReinitiate.classList.add('daa-offers-list-offer-item-hidden');
					let openCollapse = $(offerToReinitiate).find('fca-open-collapse');
					if (openCollapse.length > 0) {
						openCollapse.attr('state', 'close');
					}
				});

				bodyClasses.forEach((bodyClass) => {
					if (bodyClass.indexOf('-model-offers') > -1) {
						bodyClasses.remove(bodyClass);
					}
				})

				bodyClasses.add(`-model-offers-${value}`);

				let offersToShow;

				if (value === 'all') {
					offersToShow = document.querySelectorAll('.daa-offers-list-offer-item');
				} else {
					offersToShow = document.querySelectorAll(`.-model-offers-${value} .-offer-${value}`);
				}

				offersToShow.forEach((offerToShow, index) => {
					offerToShow.classList.remove('daa-offers-list-offer-item-hidden');
					if (index === 0) {
						let openCollapse = $(offerToShow).find('fca-open-collapse');
						if (openCollapse.length > 0) {
							openCollapse.attr('state', 'open');
						}
					}
				})
			}

		}

	}
})();
