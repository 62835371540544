(function() {
	angular
		.module('fca.layout.regionalOffers')
		.directive('fcaOffersRegionFilter', fcaOffersRegionFilterDirective);

	function fcaOffersRegionFilterDirective() {
		return {
			restrict: 'A',
			controller: FcaOffersRegionFilterController,
			controllerAs: 'offersRegionFilter',
			scope: true
		};
	}

	function FcaOffersRegionFilterController($scope, $element, $rootScope, $timeout, userLocation) {
		'ngInject';


		this.vehiclesAll = $element;

		/**
		 * @ngdoc property
		 * @name slides
		 * @propertyOf fca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Selecting all slides in the offers menu
		 * @type jQuery element
		 */
		this.slides = $element.find('div[data-region]');

		/**
		 * @ngdoc property
		 * @name slideFiltered
		 * @propertyOf fca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Defines if the offers menu has been filtered yet
		 * @type Boolean
		 */
		this.slideFiltered = false;

		/**
		 * @ngdoc property
		 * @name currentRegion
		 * @propertyOf fca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Defines the current region
		 * @type Boolean
		 */
		this.currentRegion = userLocation.getLocationRegion();

		/**
		 * @ngdoc method
		 * @name onInit
		 * @propertyOf ca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Watching location change in fca-userLocation.service
		 */
		this.$onInit = () => {
			$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => {
				try {
					this.currentRegion = region;
					this.filterRegion();
				} catch(exception) {
					console.log('error applying location change:', exception);
				}
			});

			$timeout(() => {
				this.filterRegion();
			});
		};

		/**
		 * @ngdoc method
		 * @name filterRegion
		 * @propertyOf ca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Filtering some slides out of the offers menu by region
		 */
		this.filterRegion = () => {

			if(this.slides) {

				this.slides.hide();
				$element.find(`div[data-region="${this.currentRegion}"]`).show();
				this.animateRegionChange();

			}else {
				console.warn('jQuery slick is undefined');

			}
		};

		/**
		 * @ngdoc method
		 * @name animateRegionChange
		 * @propertyOf ca.layout.regionalOffers.controller:FcaOffersRegionFilterController
		 * @description Animating the regional offers update.
		 */
		this.animateRegionChange = () => {
			let $menu = $element.parents('[data-subnav]');
			let $menuContainer = $menu.find('.offers-subnav');

			$menuContainer.addClass('is-loading');

			if ($menu.height() != 0) {
				if($(".main-nav-second-subnav").hasClass("main-nav-subnav-offers is-active")) {

				} else {
					$menu.css('height', 'auto');
				}
			}

			$timeout(() => {
				$menuContainer.removeClass('is-loading');
			}, 800);
		};
	}
})();
