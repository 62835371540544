(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaYearToggle', fcaYearToggle);

	function fcaYearToggle() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'yearToggle',
			controller: FcaYearToggleController,
		};

		function FcaYearToggleController() {
			'ngInject';

			this.activeYear = 'all';

			this.setActiveYear = (year) => {
				this.activeYear = year;
			};

		}
	}
})();
