/* eslint-disable indent */
(function() {
    angular
        .module('fca.newInventory.calculatorButton')
        .component('fcaNewInventoryCalculatorButton', {
            controller: CalculatorButton,
            controllerAs: '$calBtnCtrl',
            templateUrl: '/new-inventory/calculator-button/calculator-button.html',
            bindings: {
                nameplateCode: '@',
                trimFr: '@',
                trimEn: '@',
                overlayTitle: '@',
                calculatorData: '@',
                vin: '@',
                affiliateLogo: '@',
                affiliateMode: '@',
                dealerCode: '@',
                dealerPrice: '@',
                incentivizedPrice: '@',
                brandCode: '@'
            }
        });

    function CalculatorButton($rootScope) {
        'ngInject';

        let $calBtnCtrl = this;

        const paymentFrequencies =
            FCA_SITES_CONFIG.calculatorPaymentFrequencies;

        // This value set the default frequency for the calculator sni mode
        // Is now harcoded to weekly
        const maxPaymentFrequency = 0;

        $calBtnCtrl.callCalculator = () => {
            let calculatorDataObject = angular.fromJson($calBtnCtrl.calculatorData);
            // packageAndOptionsCode is a string that we split to make an array out of it.
            $rootScope.$broadcast('new-inventory:initialize-calculator', {
                'nameplateCode' : $calBtnCtrl.nameplateCode,
                'trimFr' : $calBtnCtrl.trimFr,
                'trimEn' : $calBtnCtrl.trimEn,
                'overlayTitle' : $calBtnCtrl.overlayTitle,
                'basePrice' : calculatorDataObject.msrp,
                'gkrp': calculatorDataObject.gkrp,
                'dealerPrice' : $calBtnCtrl.dealerPrice,
                'incentivizedPrice' : $calBtnCtrl.incentivizedPrice,
                'freight' : calculatorDataObject.freight,
                'acTax' : calculatorDataObject.acTax,
                'greenLevy' : calculatorDataObject.greenLevy,
                'incentives' : calculatorDataObject.incentives,
                'options' : calculatorDataObject.options,
                'modelYearId' : calculatorDataObject.modelYearId,
                'year' : calculatorDataObject.year,
                'acode': calculatorDataObject.acode,
                'packageAndOptionsCode' : calculatorDataObject.packageAndOptionsCode,
                'dealerAdminFee': calculatorDataObject.dealerAdminFee,
                'financeDownPayment' : calculatorDataObject.financeDownPayment,
                'leaseDownPayment' : calculatorDataObject.leaseDownPayment,
                'vin' : $calBtnCtrl.vin,
                'affiliateLogo' : $calBtnCtrl.affiliateLogo,
                'affiliateMode' : $calBtnCtrl.affiliateMode,
                'frequencyOverride' : maxPaymentFrequency,
                'dealerCode': $calBtnCtrl.dealerCode,
                'brandCode': $calBtnCtrl.brandCode,
                'cash': calculatorDataObject.cash,
                'finance': calculatorDataObject.finance,
                'altFinance': calculatorDataObject.altFinance,
                'lease': calculatorDataObject.lease,
                'altLease': calculatorDataObject.altLease,
                'previousFocusedElement': $('#js-open-sni-calculator-overlay')
            });
        };
    }
})();
