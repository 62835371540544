(function(ng) {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.daaCalculator
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.daaCalculator', [
			'fca.daaCalculatorWidget',
			'fca.daaCalculatorDialog',
			'fca.externalConfigLoader',
			'fca.gtmAnalytics',
			'fca.geolocator.cookie'
		])
		.config((externalConfigLoaderProvider) => {
			'ngInject';

			/* Load external config for service API */
			externalConfigLoaderProvider.loadConfig('DAA', 'CONFIG');
		})
		.run(($log) => {
			'ngInject';
			$log.debug('daaCalculator !');
		});
})(angular);
