(function () {
	angular
		.module("alfaromeo.buildAndPrice.navigation")
		.component("alfaNavigation", {
			controller: alfaNavigation,
			controllerAs: "$ctrl",
			templateUrl: "/brand-specific/alfaromeo/components/director/navigation/alfaromeo-navigation.html",
			bindings: {
				disclaimers: '<',
				nameplate: '@',
				navSections: '<',
				netPrice: '<',
				hasCustomOrderParam: '@'
			}
		});

	function alfaNavigation($element, $scope, $window) {
		"ngInject";

		const $ctrl = this;

		const navSectionThreshold = 300;

		$ctrl.language = FCA_SITES_CONFIG.language;

		$scope.$on('$locationChangeSuccess', () => {
			const sectionHash = location.hash.substr(1);
			if ($ctrl.navSections.includes(sectionHash)) {
				$ctrl.activeSection = sectionHash;
				$ctrl.onSummary = sectionHash === 'summary';

				if (!$ctrl.onSummary && sectionHash.indexOf('step=') === -1) {
					$ctrl.setLocationHash(sectionHash);
				}
			}
		});

		$ctrl.$postLink = () => {
			angular.element($window).on('scroll',
				() => $scope.$apply($ctrl.setActiveSection)
			);

			$ctrl.setActiveSection();
		}

		$ctrl.trimGroupUrl = () => {
			const baseUrl = FCA_SITES_CONFIG.urls.buildAndPrice[$ctrl.language];
			return `${baseUrl}/alfaromeo/${$ctrl.nameplate}${$ctrl.hasCustomOrderParam === 'true' ? '#customOrder=true': ''}`;
		}

		$ctrl.setActiveSection = () => {
			if ($ctrl.onSummary) return;
			const numNavSections = $ctrl.navSections.length;

			for (let i = numNavSections - 1; i >= 0; i--) {
				const section = $ctrl.navSections[i];
				const sectionContainer = $ctrl.elementForSection(section);

				if (!sectionContainer) continue;

				if (sectionContainer.getBoundingClientRect().top <
					navSectionThreshold) {
					if ($ctrl.activeSection !== section) {
						const $mainNavSubmenu = $element.find('.mini-nav-sub-menu');
						$mainNavSubmenu.scrollLeft(i > 2 ? 10000 : 0);
					}
					$ctrl.activeSection = section;
					break;
				}
			}

			$ctrl.activeSection = $ctrl.activeSection || $ctrl.navSections[0];
		}

		$ctrl.elementForSection = sectionName => {
			return document.getElementById(sectionName);
		}

		$ctrl.setLocationHash = sectionName => {
			history.replaceState(undefined, undefined, `#step=${sectionName}`)
		}
	}
})();
