/* eslint-disable indent */
(function() {
    angular
        .module('fca.countdown')
        .component('fcaCountdown', {
            controller: FcaCountdownController,
            controllerAs: 'fcaCountdownCtrl',
            templateUrl: '/countdown/fca-countdown.content.html',
            bindings: {
                countdownDate: '@',
                bentoPath: '@',
            }
        });

    function FcaCountdownController($scope, $interval, $timeout, $http, $sce, matchmedia, FCA_MQ_LAYOUT, functionExecutionControl) {
        'ngInject';

        const $this = this;

        let TIME_UNITS = [
            ['seconds', 1000, 60],
            ['minutes', 60, 60],
            ['hours', 60, 24],
            ['days', 24, 30],
            ['months', 30, 30],
            ['years', 12, 12]
        ];

        $this.diffMs = 0;
        $this.lang = FCA_SITES_CONFIG.language;
        let countingDown;
        let intervalPromise;
        let intervalMs = 1000;

        $this.countdownContentTitle;
        $this.countdownContentSubtitle;
        $this.countdownContentVideoOverlay;

        // Cleaning up when directive is destroyed.
        $scope.$on('$destroy', function () {
            // Stopping the loop implicitly when directive is destroyed.
            this.clearCounter();
        });

        $scope.$watch('countdownDate', this.onWatchedPropertyChanged);
        $scope.$watch('direction', this.onWatchedPropertyChanged);

        $this.onWatchedPropertyChanged = (newValue, oldValue) => {
            if (newValue !== oldValue) {
                $this.syncProperties();
            }
        }

        /**
         * Making sure our counter reflects the correct date.
         */
        $this.syncProperties = () => {

            // Setting new value for direction.
            switch ($scope.direction) {
                case 'up':
                    countingDown = false;
                    break;
                case 'down':
                    countingDown = true;
                    break;
                default:
                    countingDown = undefined;
                    break;
            }

            // Updating internal time difference.
            if ($this.countdownDate) {
                let finalCountdownDate = new Date($this.countdownDate);
                finalCountdownDate = finalCountdownDate.getTime();

                // Auto-detecting direction if not set explicitly.
                $http.post('/api/countdowntimer/time').then((r) => {
                    let now = r.data;
                    $this.diffMs = (now - finalCountdownDate);

                    if ('undefined' === typeof countingDown) {
                        countingDown = ($this.diffMs < 0);
                    }

                    // Making sure difference is adjusted correctly if timer should be stopped.
                    if ($this.isCounterShouldBeStopped()) {
                        $this.diffMs = 0;
                    }

                    // Updating the view with new value.
                    $this.renderCountdown();

                    // Making sure counter is ticking.
                    if (!$this.isCounterShouldBeStopped()) {
                        $this.startCounter();
                    }
                })
            } else {
                // Setting difference to zero if date is not provided.
                $this.diffMs = 0;
                $this.renderCountdown();
            }
        }

        /**
         * Updating the view with the actual data.
         */
        $this.renderCountdown = () => {
            var valueToRender = $this.diffMs;
            if (countingDown == false && $this.diffMs < 0) {
                // Rendering counter as zero when counting up and
                // target date is in the future.
                valueToRender = 0;
            }

            if ($this.diffMs >= 0) {
                $this.getCountdownInfo();
            }
            
            angular.extend($scope, $this.diffMsToTimeUnits(valueToRender));
        }

        /**
         * Starts or re-starts the counter loop.
         */
        $this.startCounter = () => {
            if (intervalPromise) {
                this.clearCounter();
            }
            intervalPromise = $interval(this.counterTick, intervalMs);
        }

        /**
         * Stops the counter loop.
         */
        $this.clearCounter = () => {
            $interval.cancel(intervalPromise);
            intervalPromise = null;
        }

        /**
         * Counter tick handler.
         */
        $this.counterTick = () => {

            // Updating internal difference value.
            $this.diffMs += intervalMs;

            // Checking if counter should be stopped.
            if ($this.isCounterShouldBeStopped() || $this.diffMs >= 0) {
                $this.diffMs = 0;
                $this.clearCounter();
            }

            // Updating the view.
            $this.renderCountdown();
        }

        /**
         * Indicates if counter should be stopped.
         *
         * @returns {boolean}
         */
        $this.isCounterShouldBeStopped = () => {
            // No need to run counter when counting down and
            // target date is already reached.
            return (countingDown == true && $this.diffMs >= 0);
        }

        /**
         * Calculating absolute millisecond value for each
         * time unit.
         */
        $this.initTimeUnits = () => {
            var divider = 1;
            TIME_UNITS.forEach(function (unit) {
                divider = divider * unit[1];
                unit[1] = divider;
            });
            TIME_UNITS.reverse();
        }

        /**
         * Returns difference as separate time units.
         *
         * @param {int} $this.diffMs
         *
         * @returns {object}
         */

        $this.diffMsToTimeUnits = (diffMs) => {
            let result = {};
            diffMs = Math.abs(diffMs);
            angular.forEach(TIME_UNITS, function (unit) {

                let strokeBasis;

                if (matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS)) {
                    strokeBasis = 339;
                } else {
                    strokeBasis = 251;
                }

                let strokeValue = 0;
                let name = unit[0];
                let divider = unit[1];
                let strokeDivider = unit[2];
                let value;

                if (diffMs > 0) {
                    let longValue = diffMs / divider;
                    value = Math.floor(longValue);
                    diffMs -= value * divider;
                    strokeValue = strokeBasis - (longValue / strokeDivider * strokeBasis);

                    if(value > strokeDivider) {
                        value = 0;
                    }

                    if(strokeValue > strokeBasis) {
                        strokeValue = strokeBasis;
                    }

                    if(strokeValue < 0) {
                        strokeValue = strokeBasis;
                    }
                } else {
                    value = 0;
                    strokeValue = 0;
                }

                result[name] = value;
                result[name + 'Stroke'] = strokeValue;
            });

            result.milliseconds = diffMs;
            return result;
        }

        $this.getCountdownInfo = () => {
            const params = { contentPath: $this.bentoPath }
            $http.post('/api/countdowntimer/content', params).then((r) => {
                if (r.data && r.data.isCountdownZero === true) {
                    if (r.data.countdownSubTitle[$this.lang]) {
                        $this.countdownContentSubtitle = r.data.countdownSubTitle[$this.lang];
                    }
                    if (r.data.countdownTitle[$this.lang]) {
                        $this.countdownContentTitle = r.data.countdownTitle[$this.lang];
                    }
                    if (r.data.countdownDesc[$this.lang]) {
                        $this.countdownContentDesc = r.data.countdownDesc[$this.lang];
                    }
                    if (r.data.countdownLink[$this.lang]) {
                        $this.countdownContentLink = r.data.countdownLink[$this.lang];
                    }
                    if (r.data.countdownLinkLabel[$this.lang]) {
                        $this.countdownContentLinkLabel = r.data.countdownLinkLabel[$this.lang];
                    }
                    if (r.data.countdownLink2[$this.lang]) {
                        $this.countdownContentLink2 = r.data.countdownLink2[$this.lang];
                    }
                    if (r.data.countdownLinkLabel2[$this.lang]) {
                        $this.countdownContentLinkLabel2 = r.data.countdownLinkLabel2[$this.lang];
                    }

                    $this.countdownContentVideoOverlay = (r.data.videoOverlayCTA === 'true');

                    if ($this.countdownContentVideoOverlay) {
                        if (r.data.localizedCountdownVideoPath[$this.lang] !== undefined) {
                            $this.countdownVideoPath = r.data.localizedCountdownVideoPath[$this.lang];
                        } else {
                            $this.countdownVideoPath = r.data.countdownVideoPath;
                        }

                        this.countdownAutoplay = '&autoplay=1&mute=1';

                        if (r.data.autoplay !== '') {
                            if (r.data.autoplay === 'true') {
                                this.countdownAutoplay = '&autoplay=1&mute=1'
                            } else {
                                this.countdownAutoplay = '&autoplay=0&mute=0'
                            }
                        }

                        $this.countdownVideoStart = '&start=0';
                        if (!angular.equals(r.data.countdownVideoStart, {})) {
                            if (r.data.countdownVideoStart[$this.lang] !== undefined) {
                                if (r.data.countdownVideoStart[$this.lang] !== '') {
                                    $this.countdownVideoStart = '&start=' + r.data.countdownVideoStart[$this.lang];
                                }
                            } else {
                                if (r.data.countdownVideoStart !== '') {
                                    $this.countdownVideoStart = '&start=' + r.data.countdownVideoStart;
                                }
                            }
                        }

                        $this.countdownVideoPath = 'https://www.youtube.com/embed/' + $this.countdownVideoPath + '?enablejsapi=1' + this.countdownAutoplay + $this.countdownVideoStart;

                        if (r.data.featureTitle[$this.lang]) {
                            $this.countdownVideoTitle = r.data.featureTitle[$this.lang];
                        }
                        if (r.data.subStoryDescription[$this.lang]) {
                            $this.countdownVideoDesc = r.data.subStoryDescription[$this.lang];
                        }
                    }
                }
            });
        }

        const getWidth = () => {
            if( matchmedia.is(FCA_MQ_LAYOUT.MEDIUM_PLUS) ) $this.isMobile = false;
            else $this.isMobile = true;
        };

        $this.trustSrc = () => {
            return $sce.trustAsResourceUrl($this.countdownVideoPath);
        };

        $this.$onInit = () => {
            $this.diffMs = 0;
            // Syncing properties initially.
            $this.syncProperties();
            $this.initTimeUnits();

            $(window).on('resize', functionExecutionControl.debounce(getWidth, 100));
            matchmedia.on(FCA_MQ_LAYOUT.MOBILE_PLUS, getWidth);

        };
    }
})();


