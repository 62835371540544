(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpOverlayOpen', alfaromeoVlpOverlayOpen);

	function alfaromeoVlpOverlayOpen() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				overlayId: '@',
			},
			controllerAs: '$ctrl',
			controller: alfaromeoVlpOverlayOpenController
		};

		function alfaromeoVlpOverlayOpenController($rootScope, $element, AR_VLP_OVERLAY) {
			'ngInject';

			const $ctrl = this;
			const onClick = (event) => {
				if( event ) {
					event.stopPropagation();
					event.preventDefault();
				};

				if ($(event.target.offsetParent).hasClass('legal-tooltip-label')) {
					return;
				}

				if( $ctrl.overlayId ) $rootScope.$emit(AR_VLP_OVERLAY.OPEN, $ctrl.overlayId);
				else throw new Error('alfaromeo-vlp-overlay-open: data-overlay-id attribute is required for this directive to work.');
			};


			$ctrl.$onInit = () => {
				$element.on('click', onClick);
			};
			$ctrl.$onDestroy = () => {
				$element.off('click', onClick);
			};

		};
	};

})();
