(function () {
	angular
		.module('alfaromeo.buildAndPrice.summary')
		.component('alfaOptionsRecap', {
			controller: alfaOptionsRecap,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/summary/alfaromeo-summary-options-recap/alfaromeo-summary-options-recap.html',
			bindings: {
				options: '<',
				drivetrain: '@'
			}
		});

	function alfaOptionsRecap(alfaSummary, $translate) {
		'ngInject';

		const $ctrl = this;
		const wheelsEcc = '0037';

		$ctrl.sectionIsExpanded = section => {
			return $ctrl.expandedSection === section.description;
		}

		$ctrl.expandSection = section => {
			if ($ctrl.expandedSection === section.description) {
				$ctrl.expandedSection = '';
			} else {
				$ctrl.expandedSection = section.description;
			}
		}

		$ctrl.optionIsExpanded = option => {
			return $ctrl.expandedOption === option.code;
		}

		$ctrl.expandOption = option => {
			if ($ctrl.expandedOption === option.code) {
				$ctrl.expandedOption = '';
			} else {
				$ctrl.expandedOption = option.code;
			}
		}

		$ctrl.optionHasContent = option => {
			return option ? option.content !== undefined : false;
		}

		$ctrl.getOptionContent = option => {
			if (option.content) {
				return option.content.split(/\^\s+/).filter(Boolean)[0].split('  ');
			}
		}

		$ctrl.$onChanges = changes => {
			$ctrl.viewSections = $ctrl.sections();
		}

		$ctrl.sections = () => {
			if (!$ctrl.options) return;

			const powertrain = {
				description: `${$translate.instant('build-and-price.summary.powertrain')}`,
				options: [
					alfaSummary.getOptionsForSection($ctrl.options.enginePackage)[0],
					{
						description: $ctrl.drivetrain
					}
				]
			};
			const mechanical = {
				description: $ctrl.options.mechanical.description,
				options: alfaSummary.getOptionsForSection($ctrl.options.mechanical)
			};
			const colourAndWheels = {
				description: `${$translate.instant('build-and-price.summary.colours-wheels')}`,
				options: getColourAndWheelsOptions()
			};
			const groups = {
				description: $ctrl.options.group.description,
				options: alfaSummary.getOptionsForSection($ctrl.options.group)
			};
			const exterior = {
				description: $ctrl.options.exterior.description,
				options: getExteriorOptions()
			};
			const interior = {
				description: $ctrl.options.interior.description,
				options: alfaSummary.getOptionsForSection($ctrl.options.interiorSection)
			};
			return [
				powertrain,
				mechanical,
				colourAndWheels,
				groups,
				exterior,
				interior
			];
		}

		const getColourAndWheelsOptions = () => {
			const {
				exteriorColor,
				exteriorSideSection,
				interiorColor
			} = $ctrl.options;
			let result = alfaSummary.getOptionsForSections([exteriorColor, interiorColor]);
			result = result.concat(alfaSummary.getOptionsForSection(exteriorSideSection, [], [wheelsEcc]));
			return result;
		}

		const getExteriorOptions = () => {
			const {
				exteriorFrontSection,
				exteriorSideSection,
				exteriorRearSection
			} = $ctrl.options;

			return alfaSummary.getOptionsForSections([
				exteriorFrontSection,
				exteriorSideSection,
				exteriorRearSection
			], [wheelsEcc]);
		}
	}
})()
