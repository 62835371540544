(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaHandraiser', fcaHandraiser);

	function fcaHandraiser() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticsCategory: '@',
				analyticsId: '@'
			},
			controllerAs: 'handraiser',
			controller: FcaHandraiserController,
			require: {
				layoutTransformerCtrl: '^fcaLayoutTransformer'
			}
		};

		function FcaHandraiserController($element, $window, $rootScope, $timeout, $location, gtmAnalytics) {
			'ngInject';
			this.completed = false;
			this.$onInit = () => {
				angular.element($window).bind('resize', this.arrange);
				$rootScope.$on('handraiser.open', (event, data) => {
					this.open();
				});
			};

			this.arrange = (event = null) => {
				let h = 'auto';
				if (this.layoutTransformerCtrl.isLargePlus) {
					let th = $element.find('.handraiser-title').height();
					h = $element.find('.handraiser-wrapper').height() - th;
				}
				$element.find('.hr-pano .handraiser-content, .hr-pano .handraiser-img').height(h);
			};

			this.open = () => {
				$element.addClass('opened');
				$timeout(this.arrange, 500);
			};

			this.close = () => {
				$element.removeClass('opened');
			};

			this.showResult = () => {
				this.completed = true;
				let offset = this.getOffset();

				$('html, body').animate({
					scrollTop: $($element).offset().top - offset
				}, 300);
			};

			this.showExpectedPurchase = (isChecked) => {
				$rootScope.$broadcast('contest:showExpectedPurchase', [isChecked]);
			};

			this.sendGAevent = (GAlabel, GAcategory = 'handraiser', GAaction = $location.url()) => {
				let categoryToBind = this.analyticsCategory ? this.analyticsCategory : GAcategory;
				let labelToBind = this.analyticsId ? this.analyticsId + '-' + GAlabel : GAlabel;
				gtmAnalytics.trackEvent('event', {
					category: categoryToBind,
					label: labelToBind
				});
			};

			this.whenPurchaseAnalyticsValue = (value) => {
				return $('select[name=whenPurchase] option[value="' + value + '"]').html();
			};

			this.getOffset = () => {
				let panel = $('.sticky-panel.is-mini-nav');

				// Set offset to 0 if panel does not exist
				if(panel.length > 0) {
					// Clone panel to measure height of mini-nav in its sticky state
					const panelClone = panel
						.clone()
						.addClass('is-stuck')
						.css('visibility', 'hidden')
						.appendTo('body');

					// Store value to not have to repeat expensive operation
					let stickyPanelHeight = panelClone.height();
					panelClone.remove();

					const offset = panel.scrollTop() + stickyPanelHeight;

					return offset - 5; // -5 needed to avoid bug on initial load
				} else {
					return 0;
				}
			}
		}
	}
})();
