/* eslint-disable indent */
(function() {
    angular
        .module('fca.buildAndPrice.stepsManager')
        .component('buildAndPriceStepsManager', {
            controller: BuildAndPriceStepsManager,
            controllerAs: '$ctrl',
            template: '<ng-include src="getTemplateUrl()"/>',
            bindings: {
                nameplateName: '@',
                hideSelector: '@',
                trimName: '@',
                hasClassStrategy: '@'
            }
        });

    function BuildAndPriceStepsManager($scope, $rootScope, $location, $window, $timeout, buildAndPriceStepsService, gtmAnalytics) {
        'ngInject';

        let $ctrl = this;
        let path = window.location.pathname;

        $ctrl.isCustomOrder = path.indexOf('custom-order') > 0? true: false;

        $ctrl.trimGroupSelectorUrl = $location.url().substr(0, $location.url().lastIndexOf('/'));

        $ctrl.showPackagesStep = false;

        $ctrl.hashParameters = {};

        $ctrl.initialLoad = true;

        $ctrl.isHideSelector = false;

        $ctrl.isOneClassStrategy = false;

        $ctrl.showModelsStep = false;

        $scope.getTemplateUrl = function() {
			return '/build-and-price/steps-manager/steps-manager.html';
        };

        $ctrl.$onInit = () => {
            $ctrl.isOneClassStrategy = $ctrl.hasClassStrategy === 'true';

            $ctrl.showModelsStep = !$ctrl.isOneClassStrategy;

            $ctrl.isHideSelector = $ctrl.hideSelector === 'true';

            $ctrl.applyHashParameters();
            $ctrl.trimGroupSelectorUrl = addQueryParametersToUrl($ctrl.trimGroupSelectorUrl);
            $ctrl.trimGroupSelectorUrl = addHashparamsToUrl($ctrl.trimGroupSelectorUrl);

            $('.C_BP-main-navigation-item').removeAttr('disabled');
            $('.C_SB-car-calculator button').show();

            // Once a trim has been determined, parse the data to show detailed information
            // rather than just codes
            $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                const { selectedTrim: { packages }, trimHasOptions } = data;

                $ctrl.showPackagesStep = packages.length > 1 || trimHasOptions || $ctrl.isOneClassStrategy;
                $ctrl.togglePackagesStep();
                $ctrl.toggleModelsStep();

                if ($ctrl.hashParameters.step && $ctrl.initialLoad) {
                    if ($ctrl.hashParameters.section) {
                        $ctrl.changeActiveStep($ctrl.hashParameters.step, $ctrl.hashParameters.section);
                    } else {
                        $ctrl.changeActiveStep($ctrl.hashParameters.step);
                    }
                } else if ($ctrl.initialLoad) {
					$ctrl.hashParameters.step = $ctrl.showPackagesStep ? 'packages' : 'colours';
					$ctrl.changeActiveStep($ctrl.hashParameters.step);
                }

                $ctrl.initialLoad = false;
                $ctrl.updateNavStepsNumber();
            });

            $scope.$on('navigation: active-step-change', (_, data) => {
                // Update the package to change the displayed information
                $ctrl.changeActiveStep(data.step, data.section);
            });
        };

        $ctrl.applyHashParameters = () => {
            $ctrl.hashParameters = getHashParameters();
        };

		$ctrl.updateUrl = () => {
			let newHash = buildHashParametersFoUrl($ctrl.hashParameters)

			$timeout(() => {
				history.replaceState(undefined, undefined, newHash);
			});

			let newUrl = $('.toggle-link-data').data('language-link') + newHash;
			$('.main-nav-lang-toggle, .footer-province-switch-lang').attr('href', newUrl);
		}

        $ctrl.changeActiveStep = (stepName, sectionName) => {
			$ctrl.hashParameters.step = stepName;

            let $miniNav = $('.is-mini-nav');

            $('.C_BP-zone').hide();
            $('.C_BP-stepsManager-step').removeClass('-active');
            $('.C_BP-main-navigation-item').removeClass('is-active');
            $('.C_BP-main-navigation-item').attr('aria-selected', 'false');
            $('.C_BP-zone-' + stepName).show();
            $('.C_BP-zone-director').show();
            $('.C_BP-stepsManager-step[data-step="'+ stepName +'"]').addClass('-active');


			$('.C_BP-main-navigation-' + stepName).addClass('is-active');
			$('.C_BP-main-navigation-' + stepName).attr('aria-selected', 'true');
			$('.C_BP-main-navigation-' + stepName).focus();

            if ((stepName == 'summary') && (sectionName == 'calculator')) {
                let sectionPos = $('#zones_summary_blocks_summary-header').offset();
                let mainNavOffset = $('.layout-main-nav').height();
                let miniNavOffset = $('#zones_body_blocks_mini-nav').height();

                // The height of the miniNav changes to 4 em when the nav becomes sticky. This becomes 40px currently
                window.scrollTo(0, sectionPos.top + mainNavOffset + miniNavOffset + 375);
            } else if($miniNav.hasClass('is-stuck')) {
                window.scrollTo(0, 85);
            } else {
                window.scrollTo(0, 0);
            }

			$ctrl.updateNavStepsNumber();

			this.updateUrl();


            buildAndPriceStepsService.stepChanged(stepName);

            $ctrl.moveFocusableElement('.C_BP-zone-' + stepName);
            $ctrl.moveFocusableElement('.C_BP-zone-director .C_BP-stepsManager');
            $ctrl.moveFocusableElement('.is-mini-nav');

            $ctrl.sendAnalytics(stepName);

            //Required for the nav to be properly sized
            setTimeout(function() {
                $(window).trigger('orientationchange');
                $window.dispatchEvent(new Event('resize'));
            }, 100);
        };

        $ctrl.updateNavStepsNumber = () => {
            let stepNumber = 1;
            $('.C_BP-main-navigation a').each(function() {
                let $navItem = $(this);
                if($navItem.is(':visible')) {
                    $navItem.find('span').html(stepNumber);
                    stepNumber ++;
                }
            })
        };

        $ctrl.sendAnalytics = (stepName) => {
            gtmAnalytics.trackEvent('event', {
                category: 'B&P Steps Nav',
                label: stepName.charAt(0).toUpperCase() + stepName.slice(1)
            });
        };

        $ctrl.togglePackagesStep = () => {
            if($ctrl.showPackagesStep) {
                $('.C_BP-main-navigation-packages').css({'display': 'flex', "align-items": "center"});
            } else {
                $('.C_BP-main-navigation-packages').css('display', 'none');
            }
        };

        $ctrl.toggleModelsStep = () => {
            if($ctrl.showModelsStep) {
                $('.C_BP-main-navigation-models').css({'display': 'flex', "align-items": "center"});
            } else {
                $('.C_BP-main-navigation-models').css('display', 'none');
            }
        };

        /* This function moves elements up if hidden behind director */
        $ctrl.moveFocusableElement = section => {

            $timeout(() => {

                const focusableElements = $(section).find('input, button, a, select, textarea');

                focusableElements.focus(function() {

                    const ele = $(this);
                    const elePositionTop = ele.offset().top;
                    const elePositionBottom = elePositionTop + ele.outerHeight();
                    const visibleContentTop = $(window).scrollTop();
                    let eleNewPositionTop;

                    if ($('.C_BP-zone-director .stuck').length) {

                        const wrapperHeight = $('.C_BP-zone-director .stuck').outerHeight();
                        const wrapperPositionTop = $('.C_BP-zone-director .stuck').offset().top;

                        if (wrapperHeight > 300) {
                            eleNewPositionTop = elePositionTop - 40;
                        } else {
                            eleNewPositionTop = elePositionTop - wrapperHeight;
                        }

                        if (elePositionTop < visibleContentTop || elePositionBottom > wrapperPositionTop) {
                            $('html, body').animate({
                                scrollTop: eleNewPositionTop
                            }, 400);
                        }
                    }
                });
            }, 400);
        }
    }
})();
