(function() {
	angular
		.module('alfaromeo.vlp')
		.directive('alfaVlpImgDifferenceShot', alfaVlpImgDifferenceShot);

	function alfaVlpImgDifferenceShot() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$alfaVlpImgDiffShotCtrl',
			controller: AlfaVlpImgDifferenceShotController,
		};

		function AlfaVlpImgDifferenceShotController() {
			'ngInject';

			const $ctrl = this;
			let imgDiffPercentage;

			$ctrl.onImgDiffMove = (percentage) => {
				imgDiffPercentage = percentage;
			};
			$ctrl.getShotContentStyles = (position) => {
				const distance = Math.min(0.5, (0.5 - imgDiffPercentage) * 1.2);

				if( position === 'top' ) return { opacity: imgDiffPercentage >= 0.5 ? 1.0 : 1.0 - distance * 2 };
				else if( position === 'bottom' ) return { opacity: imgDiffPercentage <= 0.5 ? 1.0 : 1.0 - distance * -2 };
			};
		}
	}
})();
