/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.colourWheelsSelector')
        .component('buildAndPriceColourWheelsSelector', {
            controller: BuildAndPriceColourWheelsSelector,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/colour-wheels-selector/colour-wheels-selector.html',
            bindings: {
                modelYearId: '@',
                nameplateCode: '@',
                nameplateName: '@',
                nameplateShort: '@',
                year: '@',
                brandCode: '@',
                provinceCode: '@',
                jellyParameters: '@',
                jellyHasWheels: '<',
                irisExternalJellyDisclaimer: '@?'
            }
        });

    function BuildAndPriceColourWheelsSelector($scope, $rootScope, $window, $element, $http, configService, colourService, $timeout) {
        'ngInject';

        const $ctrl = this;

        $ctrl.baseUrl = FCA_SITES_CONFIG.apiUrl;

        $ctrl.language = $('html').attr('lang');

        $ctrl.exteriorColourOptions = {};

        $ctrl.wheelsOptions = {};

        $ctrl.interiorColourOptions = {};

        $ctrl.currentTrim = {};

        $ctrl.paintChipsList = [];

        $ctrl.exteriorSelectedLabel = "";

        $ctrl.exteriorSelectedCode = "";

        $ctrl.paintChipsVideoSrc = "";

        $ctrl.wheelsSelectedLabel = "";

        $ctrl.scratchSave = "";

        $ctrl.mode = 'colours';

        $ctrl.showAlerts = true;

        $ctrl.showExteriorColourBlock = true;

        $ctrl.showPaintChips = false;

        $ctrl.isReady = false;

        $ctrl.isLegacy = false;

        $ctrl.isLoading = false;

        $ctrl.affiliateMode = false;

        $ctrl.currentOption = false;

        // Constants
        const toneEccCode = "0098";
        const primaryColourEccCode = "0082";
        const secondaryColourEccCode = "0160";
        const wheelEccCode = "0037";
        const interiorColorEccCode = "0083";
        const selectedState = "S";
        const notSelectedState = "C";
        const exteriorCategory = 'exteriorColor';
        const interiorCategory = 'interiorColor';
        const wheelCategory = 'wheel';
        let dialogRef;
        $ctrl.coloursEccCode = {
            primaryColour: primaryColourEccCode,
            secondaryColour: secondaryColourEccCode,
            toneColour: toneEccCode
        };

        /// Handles getting the valid options for exteriorColor, wheel and interiorColor categories.
        /// Parses the received options to determine which should show as selected.
        /// Also used to give initial value to the jellies concerning the colours and wheels.
        $ctrl.$onInit = () => {
            $ctrl.jellyHasWheels = true;
            $ctrl.showAlerts = configService.getShowAlerts();

            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();

            $ctrl.initExteriorColourListener();
            $ctrl.initInteriorColourListener();
            $ctrl.initWheelListener();

            $rootScope.$on('paint-chips:swatches-data-updated', (_, data) => {
                $ctrl.swatchesData = data.swatchesData;
                $ctrl.getPaintChipsList();
                $scope.$broadcast('paint-chips-ready-to-go');
            });

            $rootScope.$on('colours-wheels:paint-chips-updated', (_, data) => {
                $ctrl.mode = data.mode;
                if($ctrl.mode === 'wheels') {
                    $ctrl.showPaintChips = false;
                } else if ($ctrl.mode === 'colours' && $ctrl.paintChipsVideoSrc !== '') {
                    $ctrl.showPaintChips = true;
                }
            });

            $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                $ctrl.updateTrimRelatedInfos(data);
            });

            $scope.$on('alert-window:select-options-proceed', (event, data) => {
                $ctrl.showAlerts = configService.getShowAlerts();
                const validOption = data.options.find(option => {
                    return option.ecc === primaryColourEccCode
                        || option.ecc === wheelEccCode
                        || option.ecc === interiorColorEccCode;
                });
                if (!validOption) {
                    return;
                }
                switch (validOption.ecc) {
                    case primaryColourEccCode:
                        $ctrl.manageAlertStatus($ctrl.exteriorColourOptions.categories[1].options, data.options);
                        break;
                    case wheelEccCode:
                        $ctrl.manageAlertStatus($ctrl.wheelsOptions.categories[0].options, data.options);
                        break;
                    case interiorColorEccCode:
                        $ctrl.manageAlertStatus($ctrl.interiorColourOptions.categories[0].options, data.options);
                        break;
                    default:
                        break;
                }
                $ctrl.updateActive();
                $ctrl.focusOnCurrentOption();
            });

            $scope.$on('alert-window:alert-hidden', (event) => {
                $ctrl.focusOnCurrentOption();
            });
        };

        $ctrl.focusOnCurrentOption = () => {
            if($ctrl.currentOption) {
                $timeout(() => {
                    let currentOptionElement = $element.find(`[data-option-code="${$ctrl.currentOption}"]`);

                    if(currentOptionElement) {
                        currentOptionElement[0].focus();
                    }
                });
            }
        }

        $ctrl.manageAlertStatus = (ctrlOptions, alertOptions) => {
            ctrlOptions.forEach(option => {
                alertOptions.forEach(newOption => {
                    if (option.code === newOption.code) {
                        option.missedAlert = newOption.missedAlert || false;
                    }
                });
            });
        };

        $ctrl.showLastAlert = ($event) => {
            $rootScope.$broadcast('options-selector:show-missed-alert', {});

            $event.stopPropagation();
            $event.preventDefault();
        }

        $ctrl.getAffiliateLogoSource = () => {
            return $ctrl.affiliateLogoSource;
        }

        /**
         * This is a hacky solution to fix the height issue when using flexbox and column on ie11.
         * We calculate the number of swatches row there is and then set the container's
         * height according to the number of rows.
         */
        $ctrl.calculateHeightOfSwatchesContainer = () => {
            if (!$ctrl.exteriorColourOptions) {
                return;
            }
            // If browser is not IE, return has we do not need to do this.
            if (!(/MSIE|Trident/.test(navigator.userAgent))) {
                return;
            }
            const parentWidth = 0.59; // This section's parent has width: 59%.
            const swatchWidth = 100; // CSS width of the swatches items.
            const rowsHeight = 15; // CSS height of the swatches items.
            const browserWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * parentWidth;
            const swatchesByRow = Math.floor(browserWidth / swatchWidth);
            const numberRows = Math.ceil($ctrl.exteriorColourOptions.categories[1].options.length / swatchesByRow);
            $ctrl.exteriorColourContainerHeight = `${numberRows * rowsHeight}rem`;
        }

        $ctrl.initExteriorColourListener = () => {
            $scope.$on('trim-selector:options-exteriorColor-updated', (_, data) => {
                $ctrl.updateLabelDescription();
                $ctrl.exteriorColourOptions = colourService.getExteriorColoursOptionsSorted(data.options);
                $ctrl.calculateHeightOfSwatchesContainer();

                $ctrl.exteriorColourOptions.categories.forEach(category => {
                    category.options.forEach(option => {
                        if ($ctrl.checkIfOptionShouldBeSelected(option) && option.selected) {
                            // This is to prevent from the colour hover to screw up and change the colour name
                            $ctrl.exteriorSelectedLabelControl = option.description;

                            $ctrl.exteriorSelectedCode = option.code.substr(0,3).toLowerCase();

                            if ($ctrl.mode === 'colours') {
                                $ctrl.getPaintChipsVideo(option.code);
                            }

                            if (option.ecc === primaryColourEccCode) {
                                $ctrl.primaryColour = option;
                                $rootScope.$broadcast('options-selector:paint-updated', { paint: option.code, hexCode: option.color });
								$rootScope.$broadcast('packages-selector:exterior-color-update', option);
                            } else {
                                $ctrl.exteriorColour = option;
                                $rootScope.$broadcast('options-selector:tone-updated', { tone: option.code });
                            }
                        }
                        option.showPriceSign = $ctrl.showPriceSign(option);
                    })
                })
            });
        }

        $ctrl.initWheelListener = () => {
            $scope.$on('trim-selector:options-wheel-updated', (_, data) => {
                $ctrl.wheelsOptions = data.options;

                $ctrl.wheelsOptions.categories.forEach(category => {
                    category.options.forEach(option => {
                        option.selected = $ctrl.checkIfOptionShouldBeSelected(option);

                        if (option.selected) {
                            $ctrl.wheelsSelectedLabel = option.description;

                            const wheelsCode = configService.getWheelCodeOverride(option);
                            $rootScope.$broadcast('options-selector:wheels-updated', { wheels: wheelsCode });
                            $ctrl.wheel = option;
                        }
                        option.showPriceSign = $ctrl.showPriceSign(option);
                    })
                })
            });
        }

        $ctrl.initInteriorColourListener = () => {
            $scope.$on('trim-selector:options-interiorColor-updated', (_, data) => {
                $ctrl.interiorColourOptions = colourService.getInteriorColoursOptionsSorted(data.options);

                $ctrl.interiorColourOptions.categories.forEach(category => {
                    category.options.forEach(option => {
                        option.selected = $ctrl.checkIfOptionShouldBeSelected(option);

                        if (option.selected) {
                            $ctrl.interiorLabelDescription = option.description;
                            $rootScope.$broadcast('options-selector:fabric-updated', { fabric: option.code });
                            $ctrl.fabric = option;
                        }
                    })
                })
            });
        }

        $ctrl.selectOption = (option) => {
            if (!$ctrl.checkIfOptionShouldBeSelected(option)) {
                configService.selectOption(option, configService.scratchSave, "COMPLETE", false);
                $ctrl.isLoading = true;
            }

            $ctrl.currentOption = option.code;
        };

        $ctrl.selectOptionWithAlert = (option) => {
            if (!$ctrl.checkIfOptionShouldBeSelected(option)) {
                configService.selectOption(option, configService.scratchSave, "COMPLETE", true);
                $ctrl.isLoading = true;
            }

            $ctrl.currentOption = option.code;
        };

        /// Called when a new configuration is parsed.
        /// Checks if the colours or wheels options have changed and update data accordingly
        $ctrl.updateActive = () => {
            let currentExteriorColour = {};
            let currentPrimaryColour = {};
            let currentSecondaryColourCode = {};
            // The exteriorColor category is particular in that it contains 3 different categories of options.
            // The tone, the primary and the seconday colours.
            configService.categoriesObjects[exteriorCategory].forEach((element) => {
                if (element.ecc === toneEccCode) {
                    currentExteriorColour = element;
                } else if (element.ecc === primaryColourEccCode) {
                    currentPrimaryColour = element;
                } else if (element.ecc === secondaryColourEccCode) {
                    currentSecondaryColourCode = element;
                }
            })

            // The exterior colour options always contain 2 arrays.
            // One for the tone options and one for the colours options.
            $ctrl.exteriorColourOptions['categories'][0].options.forEach(element => {
                if ($ctrl.updateOptionState(element, element.code === currentExteriorColour.code)) {
                    $ctrl.exteriorColour = currentExteriorColour;
                }
            });

            $ctrl.exteriorColourOptions['categories'][1].options.forEach(element => {
                if ($ctrl.updateOptionState(element, element.code === currentPrimaryColour.code)) {
                    $ctrl.primaryColour = currentPrimaryColour;
                }
            });

            // The wheel category only contains a single array of options.
            const currentWheel = configService.categoriesObjects[wheelCategory][0];
            $ctrl.wheelsOptions['categories'][0].options.forEach(element => {
                if ($ctrl.updateOptionState(element, element.code === currentWheel.code)) {
                    $ctrl.wheel = currentWheel;
                }
            });

            // Same goes for the interiorColor category.
            const currentFabric = configService.categoriesObjects[interiorCategory][0];
            $ctrl.interiorColourOptions['categories'][0].options.forEach(element => {
                if ($ctrl.updateOptionState(element, element.code === currentFabric.code)) {
                    $ctrl.fabric = currentFabric;
                }
            });
            $ctrl.updateLabelDescription();
            $ctrl.isLoading = false;
        }

        /// Sets the option's state according to the value passed in parameter.
        /// Returns the state value so further actions can be processed.
        $ctrl.updateOptionState = (option, state) => {
            option.selected = state;
            option.state = state ? selectedState : notSelectedState;
            return state;
        }

        /// Switches between the exterior colours and wheels selection.
        $ctrl.switchTopView = (newPov) => {
            // The HTML elements use ng-click to call this function and pass in an arbitrary value of 1
            // for the exterior colour control.  Anything else should go to wheels.
            $ctrl.showExteriorColourBlock = newPov === 1;
            $ctrl.updateLabelDescription();
        }

        /// Update the labels describing the currently selected exterior colour / wheels and the interior colour.
        $ctrl.updateLabelDescription = () => {
            $timeout(() => {
                $ctrl.exteriorSelectedLabel = $ctrl.primaryColour ? $ctrl.primaryColour.description : '';

                $ctrl.wheelsSelectedLabel = $ctrl.wheel ? $ctrl.wheel.description : '';

                $ctrl.interiorLabelDescription = $ctrl.fabric ? $ctrl.fabric.description : '';

                if ($ctrl.mode === 'colours') {
                    $scope.$broadcast('paint-chips-ready-to-go');
                }
            });
        }

        /// Method used by the arrows in the colours and wheels tab to control the interior colour options.
        $ctrl.changeFabric = (direction) => {
            // The HTML elements use ng-click to call this function and pass in an arbitrary value of -1 or 1.
            if (direction === -1) {
                $ctrl.selectOption($ctrl.getPreviousFabric());
            } else if (direction === 1) {
                $ctrl.selectOption($ctrl.getNextFabric());
            }
        }

        /// Return the option for the next fabric, used to handle looping through the options.
        $ctrl.getNextFabric = () => {
            const fabricIndex = $ctrl.findFabricIndex($ctrl.fabric);
            if (fabricIndex >= $ctrl.interiorColourOptions['categories'][0].options.length - 1) {
                return $ctrl.interiorColourOptions['categories'][0].options[0];
            }
            return $ctrl.interiorColourOptions['categories'][0].options[fabricIndex + 1];
        }

        /// Return the option for the previous fabric, used to handle looping through the options.
        $ctrl.getPreviousFabric = () => {
            const fabricIndex = $ctrl.findFabricIndex($ctrl.fabric);
            if (fabricIndex <= 0) {
                return $ctrl.interiorColourOptions['categories'][0].options[$ctrl.interiorColourOptions['categories'][0].options.length - 1];
            }
            return $ctrl.interiorColourOptions['categories'][0].options[fabricIndex - 1];
        }

        /// Find index helper method.
        $ctrl.findFabricIndex = (fabric) => {
            for (let i = 0, len = $ctrl.interiorColourOptions['categories'][0].options.length; i < len; i++) {
                if ($ctrl.interiorColourOptions['categories'][0].options[i].code === fabric.code) {
                    return i;
                }
            }
            return -1;
        }

        $ctrl.interiorSwatchHoverIn = option => {
            $ctrl.selectedInteriorLabelDescription = $ctrl.interiorLabelDescription;
            $ctrl.interiorLabelDescription = option.description;
        }

        $ctrl.interiorSwatchHoverOut = () => {
            $ctrl.interiorLabelDescription = $ctrl.selectedInteriorLabelDescription;
        }

        /*
        * Keep it commented in case
        * we decide to use the hover on
        * exterior colours again
        $ctrl.exteriorColourSwatchHoverIn = option => {
            $ctrl.selectedExteriorColourLabelDescription = $ctrl.exteriorSelectedLabelControl;
            $ctrl.exteriorSelectedLabel = option.description;
            $ctrl.showPaintChips =
                $ctrl.paintChipsList.filter(
                    element => element.code === option.code.substr(0,3)
                ).length > 0 &&
                option.selected;
        }

        $ctrl.exteriorColourHoverOut = () => {
            $ctrl.exteriorSelectedLabel = $ctrl.selectedExteriorColourLabelDescription;
            $ctrl.showPaintChips = $ctrl.paintChipsVideoSrc !== '';
        }*/

        $ctrl.wheelsSwatchHoverIn = option => {
            $ctrl.selectedWheelsLabelDescription = $ctrl.wheelsSelectedLabel;
            $ctrl.wheelsSelectedLabel = option.description;
        }

        $ctrl.wheelsHoverOut = () => {
            $ctrl.wheelsSelectedLabel = $ctrl.selectedWheelsLabelDescription;
        }

        $ctrl.toggleAlerts = option => {
            configService.toggleShowAlerts();
            $ctrl.showAlerts = configService.getShowAlerts();
            $ctrl.selectOption(option);
        };

        $ctrl.checkIfOptionShouldBeSelected = option => {
            return option.state === "S" || option.state === "G"
        };

        $ctrl.showPriceSign = option => {
            return option.msrp.match(/\d+/g);
        };

        $ctrl.updateTrimRelatedInfos = data => {
            $ctrl.currentTrim = data;
            $ctrl.isReady = true;
            $ctrl.isLegacy = true;
            if (data.selectedTrimGroup && data.selectedTrimGroup.jellyRenderingType) {
                $ctrl.isLegacy = data.selectedTrimGroup.jellyRenderingType !== 'iris';
                if (data.selectedTrimGroup.trimIrisJellyOptions) {
                    $ctrl.hasInteriorImage = data.selectedTrimGroup.trimIrisJellyOptions.hasInteriorImage || false;
                    $ctrl.has360 = data.selectedTrimGroup.trimIrisJellyOptions.has360 || false;
                    if (data.selectedTrimGroup.trimIrisJellyOptions.irisJellyParameters) {
                        $ctrl.jellyParameters = data.selectedTrimGroup.trimIrisJellyOptions.irisJellyParameters;
                    } else {
                        $ctrl.jellyParameters = '';
                    }
                    if (data.selectedTrimGroup.trimIrisJellyOptions.hasWheelarizer !== undefined) {
                        $ctrl.jellyHasWheels = data.selectedTrimGroup.trimIrisJellyOptions.hasWheelarizer;
                    } else {
                        $ctrl.jellyHasWheels = true;
                    }
                    if (data.selectedTrimGroup.trimIrisJellyOptions.irisAdditionalOptions !== undefined) {
                        $ctrl.jellyAdditionalOptions = data.selectedTrimGroup.trimIrisJellyOptions.irisAdditionalOptions.join(',');
                    }
                }
            }
        };

        $ctrl.getPaintChipsList = () => {
            if ($ctrl.swatchesData) {
                $ctrl.swatchesData.forEach(element => {
                    element.options.forEach(paint => {
                        if (paint.paintOption && !$ctrl.paintChipsList.includes(paint.paintOption)) {
                            $ctrl.paintChipsList.push(paint.paintOption);
                        }
                    });
                });
            }
        };

        $ctrl.getPaintChipsVideo = colourCode => {
            $scope.$on('paint-chips-ready-to-go', () => {
                if ($ctrl.paintChipsList.length <= 0) {
                    return;
                }
                const paintCode = colourCode.substr(0,3);
                if (paintCode.startsWith('P')) {
                    const paintOption = $ctrl.paintChipsList.find(paint => paint.code === paintCode);
                    if(paintOption) {
                        $ctrl.showPaintChips = true;
                        $ctrl.paintChipsVideoSrc = `${$ctrl.baseUrl}/videos/common/paint/${paintOption.videoFileName}`;
                    } else {
                        $ctrl.showPaintChips = false;
                        $ctrl.paintChipsVideoSrc = '';
                    }
                }
            });
        };

        $ctrl.getLabelForMode = () => {
            return $ctrl.showExteriorColourBlock ? $ctrl.exteriorSelectedLabel : $ctrl.wheelsSelectedLabel;
        };

        $ctrl.getFirstHalf = (theArray) => {
            return Math.floor((theArray.length / 2) - 1);
        };

        $ctrl.colorLoaded = () => {
            let mobileBreakpoint = 667;
            let smallMobileBreakpoint = 320;
            let windowWidth = $window.innerWidth;
            let colorListEmpty = $('.-colors')[0];
            let colorListExist = $('.-colors').find('ul')[1];
            let eachBoxWidthExist = $(colorListExist).find('li')[0];
            let colorListContainer = $('.-colors').last();

            if (windowWidth < mobileBreakpoint && eachBoxWidthExist) {
                let colorList = $('.-colors').find('ul')[1];
                $(colorList).css('width', 'max-content');

                $(colorListEmpty).css('height', 0);

                let colorsNumber = $(colorList).find('li').length;
                let eachBoxWidth = $(colorList).find('li')[0].clientWidth;
                let allBoxesWidth = colorsNumber * eachBoxWidth;
                let widthCalculated = 0;

                if( windowWidth >= smallMobileBreakpoint ) {
                    let offset = 100;
                    widthCalculated = allBoxesWidth / 2 + offset;
                } else {
                    if(colorsNumber === 8) {
                        widthCalculated = allBoxesWidth / 2;
                    } else {
                        let offset = 40;
                        widthCalculated = allBoxesWidth / 2 + offset;
                    }
                }
                $(colorList).css('width', widthCalculated + 'px');


                if (colorListContainer && colorList) {
                    if (colorListContainer[0].scrollWidth <= colorListContainer[0].clientWidth) {
                        $('.arrow-color-list').css('display', 'none');
                        $(colorList).css('width', '');
                        $(colorList).css('justify-content', 'center');
                    } else {
                        $('.arrow-color-list').css('display', 'block');
                        $(colorList).css('justify-content', '');
                    }
                }
            } else {
                let colorList = $('.-colors').find('ul')[1];
                if (colorList) {
                    $(colorList).css('width', '');
                }
                $(colorListEmpty).css('height', 0);
            }
        };

        $ctrl.arrowClicked = (direction) => {
            let mobileBreakpoint = 667;
            let windowWidth = $window.innerWidth;

            if (windowWidth < mobileBreakpoint) {
                let colorList = $('.-colors').last();
                let scrollableWidth = colorList[0].scrollWidth - colorList[0].clientWidth;

                if (direction === 'left') {
                    colorList.animate( { scrollLeft: 0 }, 500);
                } else {
                    colorList.animate( { scrollLeft: scrollableWidth }, 500);
                }
            }
        };
    }
})();
