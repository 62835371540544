(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaSelectNameplate', {
			templateUrl: '/components/contact-dealer/select-nameplate/select-nameplate.html',
			bindings: {
				nameplates: '<',
				selectedVehicle: '=',
				allowChangeNameplate: '<',
				allowChangeNameplateModel: '<'
			},
			controller: daaSelectNameplateController
		});

	function daaSelectNameplateController(
		$rootScope,
		$timeout,
		externalConfigLoader) {
		'ngInject';

		let urlConfig = externalConfigLoader.loadConfig('DAA', 'CONFIG').getConfig();

		// Load brand name
		this.brand = externalConfigLoader.loadConfig('DAA', 'BRAND').getConfig('name');

		this.$onChanges = (changes) => {
			if (changes.nameplates && changes.nameplates.currentValue) {
				bindNameplateModels.apply(this);
			}
		};

		/* Flags to hide or show sections */
		this.isSelectingNameplate = false;
		this.isSelectingNameplateModel = false;

		this.currentLanguage = FCA_SITES_CONFIG.language;
		this.MQ_JELLY = $rootScope.MQ_JELLY;

		/* Force rerendering of jelly for selected vehicle. bh-src-responsive does not watch changes so need temporarily remove it from dom */
		let deregisterSelectNameplate = $rootScope.$on('contact-dealer.select-nameplate', (event, newNameplate) => { // eslint-disable-line max-len
			$timeout(() => {
				this.isChangingJelly = false;
			}, 5);
		});

		this.$onDestroy = () => {
			deregisterSelectNameplate();
		};

		this.selectNameplate = (nameplate, event) => {
			if (event) {
				event.preventDefault();
			}

			// remove the name (trim group) from the nameplate
			if (nameplate
				&& nameplate.name) {
				delete nameplate.name;
			}

			this.isChangingJelly = true;
			this.selectedVehicle = nameplate;
			this.changeNameplate(false);

			$rootScope.$emit('contact-dealer.select-nameplate', this.selectedVehicle);
		};

		this.selectedVehicleModelYear = (year, event) => {
			if (event) {
				event.preventDefault();
			}

			this.selectedVehicle.year = year;
		};

		this.formatBrand = brand => {
			let formatedBrand = '';

			if(typeof brand === 'object') {
				formatedBrand = brand[this.currentLanguage];
			} else {
				formatedBrand = brand;
			}

			return formatedBrand;
		}

		this.selectedVehicleModelTrim = (trim, event) => {
			if (event) {
				event.preventDefault();
			}

			if (!this.selectedVehicle.name) {
				this.selectedVehicle.name = {};
			}

			this.selectedVehicle.name = trim.name;

			$rootScope.$emit('contact-dealer.select-nameplate', this.selectedVehicle);
		};

		this.changeVehicleModelTrim = (isSelecting, event) => {
			if (event) {
				event.preventDefault();
			}

			this.isSelectingNameplateModel = isSelecting;
		};

		this.changeNameplate = (isSelecting, event) => {
			if (event) {
				event.preventDefault();
			}

			this.isSelectingNameplate = isSelecting;
		};

		this.isSelectedNameplate = (nameplate) => {
			return this.selectedVehicle.code === nameplate.code;
		};

		this.hasJelly = (nameplate) => {
			/*
			 * When the ui first loads the nameplate there is no jellies property until the ajax call
			 * retrieves the full nameplate object. Though it is possible the api returns an empty
			 * jellies array for models that do not have a jelly image so have to check for this.
			 */
			return nameplate.jellies && nameplate.jellies.length > 0;
		};

		function bindNameplateModels() {
			/* handle case where form has been initialized without a selected vehicle */
			if (!this.selectedVehicle) {
				this.selectedVehicle = {};
			}

			this.getSelectedNameplateDefinition = () => {
				/* if there isn't a nameplate selected yet, just choose the first one */
				if (!this.selectedVehicle.code) {
					return;
				}

				let foundDef = this.nameplates.filter((nameplate) => {
					return nameplate.code === this.selectedVehicle.code;
				})[0];

				return foundDef;
			};

			this.getSelectedNameplateModelYear = () => {
				let selectedVehicleDef = this.getSelectedNameplateDefinition();
				let foundModelYear = "";

				if (!this.selectedVehicle.code) {
					return;
				}

				if (selectedVehicleDef.modelYears) {
					foundModelYear = selectedVehicleDef.modelYears.filter((modelYear) => {
						return modelYear.year === this.selectedVehicle.year;
					})[0];
				}

				return foundModelYear;
			};

			/* Decorate the initial passed in vehicle object with its full object */
			let defs = this.getSelectedNameplateDefinition();
			this.selectedVehicle = angular.merge(defs, this.selectedVehicle);
		}
	}
})();
