(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaTrimGroupSelector', fcaAlfaTrimGroupSelector );
	function fcaAlfaTrimGroupSelector() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				selectedProvince: '@',
				selectedBrand: '@',
				selectedNameplate: '@',
				initialYear: '@'
			},
			controllerAs: '$alfaTGSCtrl',
			controller: FcaAlfaTrimGroupSelector
		}

		function FcaAlfaTrimGroupSelector(alfaBp, $rootScope, $location) {
			'ngInject';

			const $ctrl = this;

			$ctrl.language = FCA_SITES_CONFIG.language;
			$ctrl.apiBaseUrl = FCA_SITES_CONFIG.apiUrl;

			$ctrl.rearWheelDrive = {};
			$ctrl.allWheelDrive = {};
			$ctrl.drivetrainSwitch = false;
			$ctrl.disableSwitch = false;

			$ctrl.currentDrivetrain = {};

			$ctrl.namePlateElem = $('.js-commonNameplate');

			$ctrl.modelYears = [];

			$ctrl.nbOfNameplatesPerModelYear = 0;

			$ctrl.nameplateHolder = $('.alfa-commonNameplate-holder');

			$ctrl.drivetrain = {
				isActive: false,
				currentValue: null,
				list: []
			};

			$ctrl.trimGroupList = [];

			$ctrl.$onInit = () => {
				showNameplatesForModelYear();
				setElementHeight('.js-commonNameplate-accordeonBtn');

				$ctrl.modelYears.forEach(element => {
					if($ctrl.initialYear === element.modelYear) {
						getDrivetrains(element.modelYear, element.modelYearId);
					}
				});

				$rootScope.$on('trim-group-pricing:set-active-tab', (event, data) => {
					setElementHeight('.commonNameplate-tabsContainer');
				});
			};

			$ctrl.toggleYear = (argYear, toggleUrl) => {
				const year = argYear.toString();
				const prevYear = $ctrl.initialYear;
				if (year === prevYear) {
					return;
				}
				const currentYearElem = $(`[data-model-year="${year}"]`);

				// if we are in the single-year b&p selector, the toggle should link instead of switching
				if (currentYearElem.length === 0 && toggleUrl) {
					window.location = toggleUrl;
				} else {
					const prevYearElem = $(`[data-model-year="${prevYear}"]`);
					$ctrl.nbOfNameplatesPerModelYear = 0;
					$ctrl.initialYear = year;
					currentYearElem.show();
					prevYearElem.hide();
					$ctrl.nbOfNameplatesPerModelYear = currentYearElem.length;

					$location.search('modelYear', $ctrl.initialYear);

					$ctrl.modelYears.forEach(element => {
						if(year === element.modelYear) {
							getDrivetrains(element.modelYear, element.modelYearId);
						}
					});
				}
			};

			$ctrl.toggleDrivetrainCheckBoxSwitch = drivetrainId => {
				$ctrl.currentDrivetrain = drivetrainId;
				checkIfTrimIncludesDrivetrain();
			};

			$ctrl.toggleDrivetrainButtons = drivetrainSwitch => {
				$ctrl.currentDrivetrain = drivetrainSwitch ? $ctrl.allWheelDrive : $ctrl.rearWheelDrive;
				checkIfTrimIncludesDrivetrain();
			};

			const showNameplatesForModelYear = () => {
				$ctrl.namePlateElem.each((index, element) => {
					const modelYearId = $(element).data('modelYearId');
					const modelYear = $(element).data('modelYear').toString();
					if (modelYear === $ctrl.initialYear) {
						$(element).show();
						$ctrl.nbOfNameplatesPerModelYear++;
					} else {
						$(element).hide();
					}
					if($ctrl.modelYears.filter(e => e.modelYearId === modelYearId).length === 0) {
						$ctrl.modelYears.push({modelYearId : modelYearId, modelYear : modelYear});
					}

				});
			};

			const setElementHeight = elem => {
				let largest = 0;
				$(elem).each((index, element) => {
					let elemHeight = $(element).height();
					if (elemHeight > largest) {
						largest = elemHeight;
					}
				});

				$(elem).height(largest);
			};

			const getDrivetrains = (year, modelYearId) => {
				const apiUrl =
					$ctrl.apiBaseUrl +
					`/api/buildandprice/${$ctrl.language}` +
					`/brand/${$ctrl.selectedBrand}` +
					`/nameplate/${$ctrl.selectedNameplate}` +
					`/year/${year}/modelyear/${modelYearId}/trims`;

				alfaBp.getWithCallback(apiUrl, handleGetTrimsResponse, year, modelYearId);
			};

			const handleGetTrimsResponse = (data, year, modelYearId) => {
				$ctrl.trimGroupList.push({year : year, modelYearId: modelYearId, trimGroups : data.trimGroups });
				$ctrl.drivetrain.list = data.drivetrains;
				addAbbreviationToDrivetrains();
				checkIfTrimIncludesDrivetrain();
			}

			const addAbbreviationToDrivetrains = () => {
				$ctrl.drivetrain.list.forEach(drivetrain => {
					if (drivetrain.description.includes('Rear') || drivetrain.description.includes('propulsion arrière')) {
						drivetrain.customId = 'rwd';
						$ctrl.rearWheelDrive = drivetrain;
					} else if (drivetrain.description.includes('All') || drivetrain.description.includes('transmission intégrale')) {
						drivetrain.customId = 'awd';
						$ctrl.allWheelDrive = drivetrain;
					}
				});

				if ($ctrl.drivetrain.list.length === 1) {
					if ($ctrl.drivetrain.list[0].customId === 'rwd') {
						$ctrl.allWheelDrive = {
							id: '',
							description: '',
							customId: 'awd'
						};
						$ctrl.drivetrainSwitch = false;
					} else if ($ctrl.drivetrain.list[0].customId === 'awd') {
						$ctrl.rearWheelDrive = {
							id: '',
							description: '',
							customId: 'rwd'
						};
						$ctrl.drivetrainSwitch = true;
					}

					$ctrl.disableSwitch = true;
				} else {
					$ctrl.disableSwitch = false;
				}

				if($ctrl.drivetrain.list.length) {
					let isDrivetrainAWD = $ctrl.drivetrain.list.filter(drivetrain => drivetrain.customId === 'awd');

					if($ctrl.selectedNameplate === 'giulia' && isDrivetrainAWD.length) {
						$ctrl.currentDrivetrain = isDrivetrainAWD[0];
					} else {
						$ctrl.currentDrivetrain = $ctrl.drivetrain.list[0];
					}
				}
			};

			const checkIfTrimIncludesDrivetrain = () => {
				$ctrl.trimGroupList.forEach(years => {
					years.trimGroups.forEach(trimGroup => {
						let trims;
						let initialTrims;
						let nameplateTile;
						if (trimGroup.trims.length > 1) {
							trims = trimGroup.trims.find(element => element.drivetrain === $ctrl.currentDrivetrain.id);
							initialTrims = trimGroup.trims[0];
							let secondChanceInitialTrims = trimGroup.trims[1];
							let nameplateTileLength = $ctrl.nameplateHolder.find(`.js-trimgroup-acode-${initialTrims.acode}`).length;
							nameplateTile = nameplateTileLength > 0 ?
								$ctrl.nameplateHolder.find(`.js-trimgroup-acode-${initialTrims.acode}`) :
								$ctrl.nameplateHolder.find(`.js-trimgroup-acode-${secondChanceInitialTrims.acode}`);
							nameplateTile.attr('data-acode', trims.acode);
						} else {
							trims = trimGroup.trims[0];
							nameplateTile = $ctrl.nameplateHolder.find(`.js-trimgroup-acode-${trims.acode}`);
						}
						setNameplateAvailabilityState(trims, nameplateTile);
					});

					$rootScope.$broadcast('alfa-trim-group:update-drivetrain');
				});
			};

			const setNameplateAvailabilityState = (trims, nameplateTile) => {
				if (trims.drivetrain === $ctrl.currentDrivetrain.id) {
					nameplateTile
						.removeClass('-unavailable')
						.find('.alfa-commonNameplate-trim-unavailable')
						.removeClass('-active');
					setDrivetrainInTitle(nameplateTile, true);
				} else {
					nameplateTile
						.addClass('-unavailable')
						.find(`.alfa-commonNameplate-trim-unavailable.-${$ctrl.currentDrivetrain.customId}`)
						.addClass('-active');
					setDrivetrainInTitle(nameplateTile, false);
				}
			};

			const setDrivetrainInTitle = (nameplateTile , isAvailable) => {
				if (isAvailable) {
					if($ctrl.currentDrivetrain.customId === $ctrl.rearWheelDrive.customId) {
						nameplateTile.find(`.alfa-commonNameplate-title-drivetrain.-${$ctrl.rearWheelDrive.customId}`).removeClass('-invisible');
						nameplateTile.find(`.alfa-commonNameplate-title-drivetrain.-${$ctrl.allWheelDrive.customId}`).addClass('-invisible');
					} else {
						nameplateTile.find(`.alfa-commonNameplate-title-drivetrain.-${$ctrl.allWheelDrive.customId}`).removeClass('-invisible');
						nameplateTile.find(`.alfa-commonNameplate-title-drivetrain.-${$ctrl.rearWheelDrive.customId}`).addClass('-invisible');
					}
				} else {
					nameplateTile.find(`.alfa-commonNameplate-title-drivetrain`).addClass('-invisible');
				}
			};
		}
	}
})();
