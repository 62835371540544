(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaCurrencyWithDollarSign', CurrencyWithDollarSign);

    function CurrencyWithDollarSign($filter) {
        'ngInject';

        // Filter currency
        // Current language
        let language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');

        return function(pPrice) {
            if (!pPrice) {
                pPrice = 0;
            }
            let price = $filter('number')(pPrice, 0);

            // The `price` filter returns an empty string on failure to parse a string.
            // If a non-numerical string is passed into this filter, we simply pass it
            // along unchanged.
            if (price.length < 1) {
                return pPrice;
            }

            if (language === 'fr') {
                price = price.replace(/[,]/ig, '&nbsp;').replace(',', '&nbsp;') + "&nbsp;<span class='priceSign'>$</span>";
            } else {
                price = "<span class='priceSign'>$</span>" + price;
            }

            return price.replace(/\.\d+/ig, '');
        };
    }
})();

