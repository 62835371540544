(function () {
	angular
	.module('fca.brandApp')
	.directive('fcaHandraiserEmail',
		() => ({
			restrict: 'A',
			scope: true,
			bindToController: {
				email: '@',
				formAnalyticsCategory: '@',
				formAnalyticsId: '@',
				formAnalyticsValue: '@'
			},
			controllerAs: '$handraiserEmail',
			controller: [
				"$element",
				"$window",
				"$rootScope",
				"$location",
				"gtmAnalytics",
				($element, $window, $rootScope, $location, gtmAnalytics) => new FcaHandraiserEmailController($element, $window, $rootScope, $location, gtmAnalytics)
			]
		})
	);

	class FcaHandraiserEmailController {
		constructor($element, $window, $rootScope, $location, gtmAnalytics) {
			this.$element = $element;
			this.$window = $window;
			this.$rootScope = $rootScope;
			this.$location = $location;
			this.$gtmAnalytics = gtmAnalytics;
			this.emailError = false;

			this.emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		}


		$onInit() {
			// initialize configuration
			this.isPartial = this.$element[0].querySelector('div[data-fca-partial]');
			if(this.isPartial) {
				this.destinationId = this.isPartial.getAttribute('data-destination-id');
			}

			this.fieldErrors = {};
		}

		validateEmail(optionalValue="default") {
			//check if email matches regex
				if(this.email === undefined) {
					this.emailError = true;
				} else if (!this.email.match(this.emailRegex)) {
					this.emailError = true;
				} else if(optionalValue === this.destinationId) {
					this.$rootScope.$broadcast('partial:invoke-partial', optionalValue);
					this.sendAnalytics();
					this.emailError = false;
				} else {
					this.emailError = false;
					this.hrFormsOverlay();
				}
		}

		hrFormsOverlay() {
			this.$rootScope.$broadcast('vlp:initialize-hr-form-overlay', {
				'previousFocusedElement': $('#open-hr-forms-overlay'),
				'email': this.email
			});
		}

		sendAnalytics() {
			this.$gtmAnalytics.trackEvent('event', {
				category: 'App-Suite-' + this.formAnalyticsCategory,
				label: this.formAnalyticsId + this.formAnalyticsValue
			});
		}
	}

})();
