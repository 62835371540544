(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamAwards', FcaOnlyRamAwards);
	
	function FcaOnlyRamAwards() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@',
				paintCode: '@',
				paintImagePath: '@',
				paintVideoPath: '@',
				isCms: '@',
				cmsAnalyticsId: '@',
				section: '@',
				sectionId: '@',
				gtmCategory: '@'
			},
			controllerAs: '$fcaOnlyRamAwardsCtrl',
			controller: FcaOnlyRamAwardsCtrl
		};
		
		function FcaOnlyRamAwardsCtrl($element, $scope, gtmAnalytics) {

			const $ctrl = this;
			const $slickSlider = $($element.find('.OR-awards-carousel slick'));

			$slickSlider.on('init', function(event, slick) {
				var $items = $(slick.$dots).find('li');
				$items.on('click',function () {

					const currentSlide = $slickSlider.slick('slickCurrentSlide');
					const titleSelected = $(slick.$slides[currentSlide]).data('title');
					$ctrl.sendGtmTrackEvent($ctrl.sectionId+"-awards-slide-dots", titleSelected);
				});
			});

			$slickSlider.on('afterChange', function(event, slick, currentSlide) {

				const titleSelected = $(slick.$slides[currentSlide]).data('title');
				const slide = $(slick.$slides[currentSlide]);

				const nextSlideFeatureCode = slide.next().data('title');
				const prevSlideFeatureCode = slide.prev().data('title');

				if ($ctrl.isCms === "true") {
					$ctrl.sendGtmTrackEvent($ctrl.cmsAnalyticsId, currentSlide + 1);
				} else {
					$ctrl.addAnalytics(titleSelected, nextSlideFeatureCode,
							prevSlideFeatureCode);
				}
			});

			$slickSlider.on('swipe', function(event, slick, direction) {
				//Add analytics on the slick swipe
				const currentSlide = $slickSlider.slick('slickCurrentSlide');
				const titleSelected = $(slick.$slides[currentSlide]).data('title');
				const id_analytics = $ctrl.sectionId+"-awards-slide-";
				if ($ctrl.isCms !== "true") {
					if (direction === "left") {
						$ctrl.sendGtmTrackEvent(id_analytics + "next", titleSelected);
					} else {
						$ctrl.sendGtmTrackEvent(id_analytics + "prev", titleSelected);
					}
				}
			});

			$ctrl.addAnalytics = (titleSelected, nextSlideFeatureCode, prevSlideFeatureCode) => {
				//Add analytics on the slick arrow
				$element.find('.slick-prev').attr('data-analyticsid', $ctrl.sectionId+'-awards-slide-prev');
				$element.find('.slick-next').attr('data-analyticsid', $ctrl.sectionId+'-awards-slide-next');

				$element.find('.slick-prev').attr('data-analyticsvalue', prevSlideFeatureCode);
				$element.find('.slick-next').attr('data-analyticsvalue', nextSlideFeatureCode);
			};

			$ctrl.sendGtmTrackEvent = (id, value) => {

				gtmAnalytics.trackEvent('event', {
					category: $ctrl.section + '-' + $ctrl.gtmCategory,
					label: `${id}-${value}`
				});
			};
		}
	}
})();