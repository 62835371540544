(function() {
	angular
		.module('fca.commonDirectives')
		.directive('fcaSimpleAccordion', fcaSimpleAccordion);

	function fcaSimpleAccordion() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				accordionContentClass: '@'
			},
			controller: FcaSimpleAccordionController,
			controllerAs: '$FcaSimpleAccordionCtrl',
		};
	}
	function FcaSimpleAccordionController($element, $rootScope) {
		'ngInject';

		let $ctrl = this;

		this.$onInit = () => {
			let e = $element[0];
			let contentClass = $ctrl.accordionContentClass;

			$(e).click(function () {
				if ($(this).next('.'+contentClass)) {
					let contentDisplayProperty = $(this).next('.'+contentClass).css('display');

					if (contentDisplayProperty === 'none') {
							$element.next('.'+contentClass).slideDown(200);
							$element.addClass('-active');
							$element.attr('aria-expanded', 'true');
					} else {
							$element.next('.'+contentClass).slideUp(200);
							$element.removeClass('-active');
							$element.attr('aria-expanded', 'false');
					}
				}
			});
		};
	}
})();
