(function() {
	angular
		.module("fca.miniBuildAndPrice.exterior")
		.component("exteriorMiniBp", {
			controller: exteriorMiniBp,
			controllerAs: "$ctrl",
			templateUrl:
				'/shared/fca-mini-build-and-price/exterior/exterior-mini-bp.html',
			bindings: {
				jellyData: "<",
				jellyParameters: "<",
				jellyAnimationStates: '<',
				jellyAnimationZones: '<',
				optionBaseUrl: "@",
				placeholderImage: "@",
				alternateText: "@"
			}
		});

	function exteriorMiniBp(
		$scope,
		brandAnimation,
		brandPreloadStrategy,
		brandJellyBuilder,
		brandJellyLoader,
		povHelper
	) {
		"ngInject";

		const $ctrl = this;

		const desktopMediaQuery = "(min-width: 1024px)";

		// We don't want to be in the business of fighting the user for control
		// of the jelly angle. To that end, we track if the user has manually
		// rotated the vehicle. Once/if the user has changed the angle, we no
		// longer run the scrolling animation.
		$ctrl.userHasChangedPov = false;
		// For the rotation animation to be smooth, the images must be already
		// loaded in to memory. Simply relying on the browser cache isn't
		// sufficient, as the time to load the images from disk causes jerkiness
		// when rapidly changing the angle of the car. We instead force the
		// images to be in memory by keeping references to them in the image
		// cache. See alfaromeo-jelly-loader for associated code.
		$ctrl.imageCache = {};
		// The preload key is a sample jelly url that allows us to compare sets
		// of jelly data. There are many options inside the jelly data that can
		// change without affecting the actual iris image; to make sure that we
		// aren't unnecessarily blowing the image cache, we check the preload
		// keys when the jelly data changes to see if we need to load new images.
		//
		// The other usage for the preload key is differentiating load events.
		// Imagine that the user selects a yellow exterior color and then
		// immediately after the red color. To avoid accidentally polluting the
		// image cache with yellow jellies, we check that the preload key of the
		// load event matches the current preload key.
		$ctrl.preloadKey = null;

		$ctrl.defaultAnimation = "mainAngle";

		const preloadPov = povHelper.povMin;

		const apiKeys = {
			pov: 'pov',
			fabric: 'fabric',
			background: 'bkgnd',
			paint: 'paint',
			imageFormat: 'resp',
			xResolution: 'width',
			yResolution: 'height',
			width: 'w',
			height: 'h',
			xOffset: 'x',
			yOffset: 'y',
			noShadow: 'noshadow',
		}

		let exteriorOptions = {
			[apiKeys.background]: 'transparent',
			[apiKeys.width]: 6700,
			[apiKeys.height]: 3500,
			[apiKeys.xOffset]: 1500,
			[apiKeys.yOffset]: 3500,
			[apiKeys.imageFormat]: 'png',
		}

		const getPreloadKey = jellyData => brandJellyBuilder.jellyUrl(
			jellyData, preloadPov
		);

		const exteriorOptionsChanged = newJellyData =>
			!$ctrl.jellyData || getPreloadKey(newJellyData) !== $ctrl.preloadKey;

		const preloadViewpoints = (jellyData, viewpoints, currentPov) => {
			$ctrl.imageCache = {};
			const urlBuilder = pov => $ctrl.buildJellyUrl(jellyData, pov);

			brandJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				currentPov,
				viewpoints,
				$ctrl.onPovLoad
			);
		}

		$ctrl.loadPov = pov => {
			const urlBuilder = pov => $ctrl.buildJellyUrl($ctrl.jellyData, pov);
			brandJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				pov,
				[],
				$ctrl.onPovLoad
			);
		}

		const preloadStrategie = () => {
			if($ctrl.defaultAnimation) {
				return brandPreloadStrategy.preloadStrategies[$ctrl.defaultAnimation]
			}

			if ($ctrl.isDesktop()) {
				return brandPreloadStrategy.preloadStrategies.animation
			}

			return brandPreloadStrategy.preloadStrategies.ofInterest
		}

		const fullPreload = (jellyData, currentPov) => {

			const viewpoints = brandPreloadStrategy.generateViewpoints(preloadStrategie());

			preloadViewpoints(jellyData, viewpoints, currentPov);
		}

		$ctrl.$onChanges = changes => {
			if (!changes.jellyData || !changes.jellyData.currentValue) {
				return;
			}

			const jellyData = changes.jellyData.currentValue;

			// Check if the vehicle's exterior options have been changed (wheels,
			// color, etc.)
			if (!exteriorOptionsChanged(jellyData)) return;

			// If we need to download new jellies, we need to
			//   1. Set a new preload key, to differentiate the jellies we are
			//      about to load from previous jellies that may still be
			//      loading.
			$ctrl.preloadKey = getPreloadKey(jellyData);
			//   2. Reset our image cache, as we are about to download a new set
			//      of jellies.
			$ctrl.imageCache = {};

			// If the user has manually changed the angle of the car, the
			// rotation animations will not be run and thus there is no need to
			// preload the animation images. In this case, the individual jelly
			// components will notice that the image cache is empty and request
			// that we load their povs.
			if (!$ctrl.userHasChangedPov) {
				fullPreload(jellyData);
			}
		}

		$ctrl.$onInit = () => {

		}

		$ctrl.setIrisJellyParameters = () => {
			let parameters = getParameters($ctrl.jellyParameters);

			if (parameters) {
				if (parameters[apiKeys.width]) {
					exteriorOptions.w = parseInt(parameters[apiKeys.width]);
				}

				if (parameters[apiKeys.height]) {
					exteriorOptions.h = parseInt(parameters[apiKeys.height]);
				}

				if (parameters[apiKeys.xOffset]) {
					exteriorOptions.x = parseInt(parameters[apiKeys.xOffset]);
				}

				if (parameters[apiKeys.yOffset]) {
					exteriorOptions.y = parseInt(parameters[apiKeys.yOffset]);
				}
			}
		};

		function getParameters(parameters) {
			let vars = {};

			'&'.concat(parameters).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
					vars[key] = value;
				});

			return vars;
		}

		let desktopMode = null;
		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.onPovLoad = loadedImage => {
			if (loadedImage.preloadKey !== $ctrl.preloadKey) {
				return;
			}

			$scope.$apply(() => {
				const newImageCache = Object.assign(
					{ [loadedImage.pov]: loadedImage }, $ctrl.imageCache
				);

				$ctrl.imageCache = newImageCache;
			});
		}

		$ctrl.buildJellyUrl = (jellyData, pov) => {
			if (!jellyData) {
				return;
			}

			if ($ctrl.jellyParameters) {
				$ctrl.setIrisJellyParameters();
			}

			let jellyOptions = Object.assign(
				{
					'pov': povHelper.format(povHelper.wrap(pov)),
					'exteriorOptions': exteriorOptions
				},
				jellyData
			);

			return brandJellyBuilder.jellyUrl(jellyOptions);
		}
	}
})();
