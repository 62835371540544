// Directive to wrap slick carousel
// Allows for callbacks and other arguments need to be passed to slick to be defined in
// this directive's controller instead of higher up in application

(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselVideoAutoplay', fcaSlickCarouselVideoAutoplay);

	function fcaSlickCarouselVideoAutoplay() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				autoplay: '@'
			},
			controllerAs: 'carouselVideoAutoplay',
			controller: FcaSlickCarouselVideoAutoplayController
		};

		function FcaSlickCarouselVideoAutoplayController($element, $scope) {
			'ngInject';

			this.onInit = () => {
				playVideo($element);
			};

			/*
			 * Handler before slide is changed. Stops the video of the current slide if it's playing
			 */
			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let $currentSlideElem = $($element[0]).find('.slick-current');

				/* Stop any video tags playing */
				let $videoElem = $currentSlideElem.find('video').get(0);

				if ($videoElem && !$videoElem.paused) {
					$videoElem.pause();
				}

				/* Stop any YouTube videos playing */
				let $youTubePlayer = $currentSlideElem.find('.youtube-iframe').get(0);

				if ($youTubePlayer) {
					let messageString = '{"event": "command", "func":"' + 'pauseVideo' + '","args":""}';

					$youTubePlayer.contentWindow.postMessage(messageString, '*');
				}

			};

			this.afterChange = (event, slick, currentSlide, nextSlide) => {
				playVideo($element, $scope, this.autoplay);
			};
		}

		function playVideo($element, $scope, autoplay) {
			let $currentSlideElem = $($element[0]).find('.slick-current');
			let $videoElem = $currentSlideElem.find('video').eq(0)[0];

			if ($videoElem) {
				let started = false;

				if (!started && $scope) {
					$videoElem.play();
					started = true;

					// Restart carousel progress if autoplay enabled
					if (autoplay === 'true') {

						// Show controls on hover only
						$currentSlideElem.find('video').hover(function(event) {
							if(event.type === "mouseenter") {
								$(this).attr("controls", "");
							} else if(event.type === "mouseleave") {
								$(this).removeAttr("controls");
							}
						});

						$scope.$emit('carousel.pauseWithRestartedProgress');

						$videoElem.addEventListener('ended',function () {
							$scope.$emit('carousel.resumeWithRestartedProgress');
						});
					} else {
						$scope.$emit('carousel.pause');
					}
				};
			}
		}
	}
})();
