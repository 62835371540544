/* eslint-disable indent */
(function() {
    angular
    .module('fca.sni.comparedVehicleBar')
    .component('sniComparedVehicleBar',{
        controller: SniComparedVehicleBar,
        controllerAs: '$ctrl',
        templateUrl: '/sni/compared-vehicle-bar/compared-vehicle-bar.html',
        bindings: {
            numberSelected: "=", // "<" pour objet "@" pour string
            click: "&",
        }
    });
    
    function SniComparedVehicleBar() {
        'ngInject';
        let $ctrl = this;
    }
})();