/* eslint-disable indent */
(function () {
    angular
        .module('fca.userAccount')
        .service('userAccountService', UserAccountService);

    function UserAccountService($timeout, storageService) {
        let $service = this;

        $service.isLibraryAvailable = () => {
            return typeof(UA) !== 'undefined' && storageService.isCookiesEnabled();
        };
        /**
         *
         * @param vehicle
         * @returns a Promise object
         */
        $service.addToFavorites = (vehicle) => {
            vehicle.subBrandCode = vehicle.brand;

            return UA.addInventoryVehicle({
                vin: vehicle.vin,
                brandCode: vehicle.brand,
                subBrandCode: vehicle.subBrandCode,
                year: vehicle.modelYear,
                nameplateCode: vehicle.nameplateCode,
                trimGroupEn: vehicle.trimGroup.en,
                trimGroupFr: vehicle.trimGroup.fr,
                dealerCode: vehicle.dealerCode,
                modelYearId: vehicle.modelYearId,
                pricingInfo: {
                    netAmount: vehicle.regularPrice,
                    financeTerm: vehicle.financingTerm,
                    financeRate: vehicle.financingRate,
                    financePayment: vehicle.financingValue,
                    financePaymentFrequency: vehicle.financeFrequency,
                    leaseTerm: vehicle.leasingTerm,
                    leaseRate: vehicle.leasingRate,
                    leasePayment: vehicle.leasingValue,
                    leasePaymentFrequency: vehicle.leasingFrequency,
                    dealerPrice: vehicle.dealerPrice
                }
            });
        };

        /**
         * 'G1G 1G1' => 'g1g1g1'
         * @param postalCode
         * @returns {string}
         */
        $service.unformatPostalCode = (postalCode) => {
            let unformattedPostalCode = '';
            if(postalCode) {
                unformattedPostalCode = postalCode.replace(' ', '').toLowerCase();
            }
            return unformattedPostalCode;
        }
    }
})(angular);