(function() {
	angular
		.module('fca.simpleAccordion')
		.component('fcaSimpleAccordion', {
			templateUrl: ['$element', '$attrs', function($element, $attrs) {
				return $attrs.templateUrl || '/shared/fca-simple-accordion/fca-simple-accordion.html'; 
			}],
			controller: FcaSimpleAccordionController,
			transclude: {
				'content': 'accordionContent',
				'tooltip': '?titleTooltip'
			},
			bindings: {
				title: '@',
				icon: '@',
				analyticsCategory: '@?',
				analyticsLabel: '@?'
			}
		});

	function FcaSimpleAccordionController($element, gtmAnalytics) {
		'ngInject';

		/**
		 * @ngdoc property
		 * @name isActive
		 * @propertyOf fcaSimpleAccordion.controller:FcaSimpleAccordionController
		 * @description Determines if the accordion is opened
		 * @type Boolean
		 */
		this.isActive = false;

		/**
		 * @ngdoc property
		 * @name accordion
		 * @propertyOf fcaSimpleAccordion.controller:FcaSimpleAccordionController
		 * @description Select the element to act as accordion
		 * @type jQuery element
		 */
		this.accordion = $element.find('[data-accordion-container]');

		/**
		 * @ngdoc method
		 * @name toggle
		 * @propertyOf fcaSimpleAccordion.controller:FcaSimpleAccordionController
		 * @description Toggles the active state of the accordion
		 */
		this.toggle = () => {
			// TODO: bad way to do this need to refactor at some point
			if ($element.data('shouldBeActive') === true) {
				this.isActive = true;
				$element.data('shouldBeActive', false);
			}

			if (this.analyticsCategory != undefined && this.analyticsLabel != undefined) {
				this.triggerTracking();
			}

			if (this.isActive) {
				this.isActive = false;
				this.animateHeight(0);
			} else {
				this.isActive = true;
				this.calculateNewHeight();
			}
		};

		this.triggerTracking = () => {
			let trackingLabel = 'expand';
			if (this.isActive) {
				trackingLabel = 'collapse';
			}

			gtmAnalytics.trackEvent('event', {
				category: this.analyticsCategory,
				label: `${this.analyticsLabel} - ${trackingLabel}`
			});
		};

		/**
		 * @ngdoc method
		 * @name calculateNewHeight
		 * @propertyOf fcaSimpleAccordion.controller:FcaSimpleAccordionController
		 * @description Calculate the height of the accordion
		 */
		this.calculateNewHeight = () => {
			let newHeight = 0;
			this.accordion.css('height', 'auto');
			newHeight = this.accordion.height();
			this.accordion.css('height', 0);

			this.animateHeight(newHeight);
		};

		/**
		 * @ngdoc method
		 * @name animateHeight
		 * @propertyOf fcaSimpleAccordion.controller:FcaSimpleAccordionController
		 * @description Animates the accordion
		 */
		this.animateHeight = (newHeight) => {
			let animationLength = 250;

			this.accordion.stop().animate({
				height: newHeight
			}, animationLength, 'swing');
		};

		if ($element.data('toggle-onload') == true) {
			this.isActive = true;
			this.accordion.css('height', 'auto');
		}

		// TODO: bad way to do this need to refactor at some point

		if ($element.data('toggle-onload') != undefined) {
			if (!$element.next().length) {
				let parentContainer = $element.parent().first();
				let defaultOnLoad = false;
				parentContainer.children().each(function() {
					/* eslint-disable no-invalid-this */
					if ($(this).data('toggle-onload') === true) {
						defaultOnLoad = true;
					}
				});

				if (defaultOnLoad === false) {
					let firstAccordion = parentContainer.children().first();
					firstAccordion.find('[data-accordion-container]').css('height', 'auto');
					firstAccordion.attr('data-toggle-onload', 'true');
					firstAccordion.data('shouldBeActive', true);
				}
			}
		}
	}
})();
