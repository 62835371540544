(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaVr360Ui', fcaVr360Ui);

	function fcaVr360Ui() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				modelShown: '@',
				fullscreenNavTitle: '@'
			},
			controllerAs: 'vr360ui',
			controller: FcaVr360UiController,
			templateUrl: '/panels/vr360/fca-vr360-ui.html'
		};

		function FcaVr360UiController() {
			this.$onInit = () => {

			};
		}
	}
})();
