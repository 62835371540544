(function() {
    angular
        .module('fca.reservation')
        .directive('fcaVehicleReservationOverview', fcaVehicleReservationOverview);

    function fcaVehicleReservationOverview($filter, reservationService, userLocation) {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$ctrl',
            controller: vehicleReservationOverview
        };

        function vehicleReservationOverview() {
            'ngInject';
            const $ctrl = this;

            $ctrl.colorCode = reservationService.colorCode;
            $ctrl.colorName = reservationService.colorName;
            $ctrl.colorPrice = 0;

            $ctrl.interiorColorCode = reservationService.interiorColorCode;
            $ctrl.interiorColorName = reservationService.interiorColorName;
            $ctrl.interiorColorPrice = 0;

            $ctrl.interiorColorsToShow = null;
            $ctrl.pricesWithoutColor = {}; // contains price without color for each region
            $ctrl.reservationFee = null;
            $ctrl.basePrices = {}; // contains base prices for each region
            $ctrl.leaseRates = {}; // contains lease rates for each region
            $ctrl.leaseTerms = {}; // contains lease terms for each region
            $ctrl.colorsLeasePayment = {}; // contains color leases price for each region and main color
            $ctrl.provincialRebates = {};
            $ctrl.federalRebates = {};
            $ctrl.vehicleName = null;
            $ctrl.optionalEquipmentsPrice = null;
            $ctrl.destinationPrice = null;

            $ctrl.$onInit = () => {
                reservationService.getReservationData = $ctrl.getReservationData;
            };

            $ctrl.$postLink = () => {
                $ctrl.colors.forEach((color, index) => {
                    $ctrl.colorsLeasePayment[color] = {};
                    const lease = $ctrl.colorsLease[index];
                    Object.keys(lease).forEach(province => {
                        $ctrl.colorsLeasePayment[color][province.toUpperCase()] = $filter('fcaCurrencyWithDollarSign')(lease[province]);
                    })
                });
            };

            $ctrl.getReservationData = () => {
                let province = userLocation.getLocationProvince();
                reservationService.pricesWithoutColor = $ctrl.pricesWithoutColor;
                reservationService.reservationFee = $ctrl.reservationFee;
                reservationService.basePrice = $ctrl.basePrices[province] || 0;
                reservationService.incentives = ($ctrl.provincialRebates[province] || 0) + ($ctrl.federalRebates[province] || 0);
                reservationService.vehicleName = $ctrl.vehicleName;
                reservationService.optionalEquipmentsPrice = $ctrl.optionalEquipmentsPrice;
                reservationService.destinationPrice = $ctrl.destinationPrice;
				reservationService.wheelUrl = $ctrl.wheelUrl;
            };

            $ctrl.updateColor = (colorCode, colorName, colorPrice, interiorColorsToShow, interiorColorSelectedIndex = 0) => {
                $ctrl.colorCode = colorCode;
                $ctrl.colorName = colorName;
                $ctrl.colorPrice = colorPrice;
                $ctrl.interiorColorsToShow = interiorColorsToShow;
                $ctrl.interiorColorIndex = $ctrl.interiorColorsToShow[interiorColorSelectedIndex];

                reservationService.colorCode = colorCode;
                reservationService.colorName = colorName;
                reservationService.colorPrice = colorPrice;
            };

            $ctrl.updateInteriorColor = (interiorColorCode, interiorColorName, interiorColorPrice) => {
                $ctrl.interiorColorCode = interiorColorCode;
                $ctrl.interiorColorName = interiorColorName;
                $ctrl.interiorColorPrice = interiorColorPrice;
                $ctrl.interiorColorIndex = interiorColorCode;
                reservationService.interiorColorCode = interiorColorCode;
                reservationService.interiorColorName = interiorColorName;
                reservationService.interiorColorPrice = interiorColorPrice;
            };

            $ctrl.showInteriorColor = (code) => {
                return $ctrl.interiorColorsToShow.indexOf(code) !== -1;
            }
        }
    }
})();
