
// Usage:
//
// const viewport = new SniCompareViewport(totalNumSlides, numVisibleSlides);
(function() {
    function Viewport({ totalSize, visibleSize }) {
        this._totalSize = totalSize;
        this._visibleIndices = _initialize(visibleSize, 0);
    }

    const _initialize = (visibleSize, shiftFactor) => {
        const visibleIndices = [];
        for (let i = 0; i < visibleSize; i++) {
            visibleIndices.push(i + shiftFactor);
        }

        return visibleIndices;
    }

    Viewport.prototype.totalSize = function() {
        return this._totalSize;
    }

    // Number of elements visible in the viewport
    Viewport.prototype.numVisible = function() {
        return this._visibleIndices.length;
    }

    // The leftmost visible index
    Viewport.prototype.farLeftIndex = function() {
        return this._visibleIndices[0];
    }

    // The rightmost visible index
    Viewport.prototype.farRightIndex = function() {
        return this._visibleIndices[this._visibleIndices.length - 1];
    }

    // Returns `true` if the index is currently in the viewport
    Viewport.prototype.isVisible = function(index) {
        return this._visibleIndices.indexOf(index) > -1;
    };

    // Shift the viewport to the left
    Viewport.prototype.shiftLeft = function() {
        if (this.farLeftIndex() === 0) {
            return;
        }

        this._visibleIndices = this._visibleIndices.map(index => index - 1);
    }

    // Shift the viewport to the right
    Viewport.prototype.shiftRight = function() {
        if (this.farRightIndex() === this.totalSize() - 1) {
            return;
        }

        this._visibleIndices = this._visibleIndices.map(index => index + 1);
    }

    // Shift the viewport until the given index is in view. This function...
    //   * Does nothing if the index is in view
    //   * Moves the viewport the minimum possible
    Viewport.prototype.shiftIntoView = function(index) {
        if (index < this.farLeftIndex()) {
            this.shiftLeft();
        } else if (index > this.farRightIndex()) {
            this.shiftRight();
        }

        if (!this.isVisible(index)) {
            this.shiftIntoView(index);
        }
    }

    Viewport.prototype.shiftFactor = function() {
        return 0 - this.farLeftIndex();
    }

    Viewport.prototype.visibleIndices = function() {
        return this._visibleIndices.slice();
    }

    window.SniCompareViewport = Viewport;
})();
