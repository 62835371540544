(function() {
	angular
		.module('fca.brandApp')
		.directive('alfaromeoVlpGallery', alfaromeoVlpGallery);

	function alfaromeoVlpGallery() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'vlpGallery',
			controller: alfaromeoVlpGalleryController
		};

		function alfaromeoVlpGalleryController($rootScope, $scope, $element, $timeout, gtmAnalytics, AR_VLP_OVERLAY) {
			'ngInject';

			this.updateSlide = ($evt, newSlide) => {
				$evt.preventDefault();
				$evt.stopPropagation();

				$scope.$broadcast('vlp-gallery-slide-change', {slide: newSlide});
			};
		}
	}
})();
