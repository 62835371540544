(function() {
    angular
        .module('fca.commonDirectives')
        .directive('vehicleView', vehicleView);

    function vehicleView() {
        'ngInject';

        return {
            restrict: 'A',
            bindToController: {},
            controller: vehicleViewController,
            controllerAs: '$vehicleView',
            scope: true
        };

        function vehicleViewController ($scope) {
            'ngInject';

            let $ctrl = this;
            $ctrl.interiorInit = false;
            $ctrl.povMode = 'E';

            $ctrl.changePovMode = newMode => {
                if (newMode === 'interior') {
                    $ctrl.povMode = 'I';

                    if(!$ctrl.interiorInit) {
                        $scope.$broadcast('initInterior360');
                        $ctrl.interiorInit = true;
                    }
                } else if (newMode === 'exterior') {
                    $ctrl.povMode = 'E';
                }
            };
        }
    }
})(angular);
