(function() {
	angular
		.module('fcaWaypoints')
		.directive('fcaWaypoint', fcaWaypoint);

	function fcaWaypoint() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				groupId: '@'
			},
			controller: FcaWaypointController
		};

		function FcaWaypointController($rootScope, $element, $timeout, $window) {
			'ngInject';

			this.$onChanges = (changes) => {
				if (changes.groupId.currentValue && changes.groupId.currentValue != 'default') {
					angular.element(document).ready(() => {
						const $miniNavPanel = $('.sticky-panel.is-mini-nav');

						let waypoint = new Waypoint({
							element: $element[0],
							handler: (direction) => {
								// Refresh waypoints when using anchors
								if(window.location.hash) {
									// Prevent waypoint from firing too many times at once
									$timeout(() => {
										waypoint.context.refresh();
									}, 50);

								}
								$rootScope.$emit('fcaWaypoint.reached', {
									direction: direction,
									groupId: this.groupId
								});
							},
							offset: () => {
								return $miniNavPanel.length > 0 ? $miniNavPanel.height() : 0;
							}
						});

						$rootScope.$on('fcaSticky.something-stuck', (event, data) => {
							// prevent waypoint from firing too many times at once
							$timeout(() => {
								// Cause offset to recalculate (when mini-nav is sticky it becomes less tall)
								// http://imakewebthings.com/waypoints/api/context-refresh/
								waypoint.context.refresh();
							}, 50);
						});
					});
				}
			};
		}
	}
})();
