/* eslint-disable indent */
(function () {
    angular
        .module('fca.commonServices')
        .service('storageService', StorageService);

    function StorageService() {

        const $service = this;

        $service.isCookiesEnabled = () => {
            return navigator.cookieEnabled;
        };

        $service.sessionStorageGetItem = sessionItem => {
            let item;
            if ($service.isCookiesEnabled()) {
                item = sessionStorage.getItem(sessionItem);
            } else {
                item = null;
            }
            return item;
        };
    }
})(angular);