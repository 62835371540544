(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaModalVerticalScroll', fcaVerticalScroll);

	function fcaVerticalScroll() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'modalVerticalScroll',
			controller: fcaVerticalScrollController
		};

		function fcaVerticalScrollController($element, $window, $scope, $rootScope, $timeout) {
			'ngInject';

			let elementList;

			const currentState = [];

			this.containerList = [];
			this.currentScrollPosition = 0;
			this.windowHeight = $window.innerHeight;

			this.$postLink = () => {
				// Add '.js-modal-scroll-parent' class on parent scrolling div
				let parentElement = $($element[0]).parents('.js-modal-scroll-parent');

				if(parentElement) {
					angular.element(parentElement).bind('scroll', this.onScrollEvent);
				}

				// Timeout of 50ms inside app.module.js
				$timeout(() => {
					elementList = angular.element($element).find('.vertical-scroll-block-container');
					this.fillContainerList();
					$rootScope.$broadcast('onScrollingUpdate');
				}, 50);
			};

			this.fillContainerList = () => {
				this.containerList = [];
				elementList.each((i, val) => {
					if (val.dataset.containerId && !this.containerList[val.dataset.containerId]) {
						const containerData = fillContainerData(val);
						const currentContainerState = getElementState(containerData, $window.pageYOffset);
						currentState.push(currentContainerState);
						this.containerList.push(containerData);
					}
				})
			};

			this.onScrollEvent = () => {
				this.getDirectionPosition();
			};

			this.getDirectionPosition = () => {
				const currentPosition = $element[0].getBoundingClientRect().top;
				this.currentScrollPosition = currentPosition * -1;

				elementList.each((i, val) => {
					if (currentState[i] !== getElementState(this.containerList[i], currentPosition)) {
						currentState[i] = getElementState(this.containerList[i], currentPosition);
						$scope.$apply();
						$rootScope.$broadcast('onScrollingUpdate');
						$timeout(() => {
							this.fillContainerList();
						}, 200);
					}
				});

				$timeout(() => {
					$window.dispatchEvent(new Event('resize'));
				}, 200);
			};

			function fillContainerData(val) {
				return {
					topOffset: val.offsetTop,
					bottomOffset: val.offsetTop + val.offsetHeight,
					height: val.offsetHeight
				}
			}

			function getElementState(elem, pos) {
				let state = 'has-not-passed';
				const windowHeight = $window.innerHeight;

				if (pos + windowHeight > elem.topOffset && pos + windowHeight * 0.75 <= elem.bottomOffset) {
					state = 'half-seen';
				} else if (pos + windowHeight * 0.75 > elem.topOffset && pos + windowHeight < elem.bottomOffset) {
					state = 'is-current';
				} else if (pos > elem.bottomOffset) {
					state = 'has-passed';
				} else if (pos + windowHeight < elem.bottomOffset) {
					state = 'has-not-passed';
				}

				return state;
			}
		}
	}
})();
