(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamHrform', FcaOnlyRamHrform);
	
	function FcaOnlyRamHrform() {
		return {
			restrict: 'A',
			scope: true,

			controllerAs: '$fcaOnlyRamHrformCtrl',
			controller: FcaOnlyRamHrformCtrl
		};
		
		function FcaOnlyRamHrformCtrl($element, $scope, gtmAnalytics) {

			const $ctrl = this;

			$ctrl.$onInit = () => {
				if ($('.OR-page').length > 0) {
					$ctrl.sendGtmTrackEvent();
				}
			}

			$ctrl.sendGtmTrackEvent = (id, value) => {

				const page_code_active = $('.OR-tab-link, .is-active').data('page-code');

				$('.handraiser-result-content').find('.btn').attr('data-analyticsid', 'ram-fmb-form-submission-locate-a-dealer-');
				$('.handraiser-result-content').find('.btn').attr('data-analyticsvalue', page_code_active)
			};
		}
	}
})();