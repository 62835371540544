(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaPricing', AlfaRomeoPricing);

	function AlfaRomeoPricing($rootScope) {
		const $service = this;

		const cash = 'cash';
		const finance = 'finance';
		const lease = 'lease';

		$service.sumValueInArrayOfObjects = (objects, dataKey) => {
			return objects.reduce((total, object) => {
				return total + toFloat(object[dataKey])
			}, 0);
		};

		$service.hasLease = paymentsAndRates => {
			if (!paymentsAndRates) return false;
			return paymentsAndRates.leasePayment;
		}

		$service.onModeChanged = paymentType => {
			$rootScope.$broadcast('navigation: payment-type-changed', {
				'type': paymentType
			});
		}

		$service.changeActiveTab = (newTab, changeToCash, changeToFinance, changeToLease) => {
			switch (newTab) {
				case 'cash':
					changeToCash();
					break;
				case 'finance':
					changeToFinance();
					break;
				case 'lease':
					changeToLease();
					break;
				default:
					break;
			}
		}

		$service.getCurrentPaymentLabel = currentPaymentType => {
			switch (currentPaymentType) {
				case cash:
					return 'buildAndPrice.net-amount';
				case finance:
					return 'buildAndPrice.finance-for';
				case lease:
					return 'buildAndPrice.lease-for';
				default:
					return '';
			}
		}

		$service.getCurrentPayment = (paymentsAndRates, currentPaymentType) => {
			if (!paymentsAndRates) return;
			switch (currentPaymentType) {
				case cash:
					return paymentsAndRates.netAmount;
				case finance:
					return paymentsAndRates.financePayment;
				case lease:
					return paymentsAndRates.leasePayment;
				default:
					return;
			}
		}

		$service.getCurrentPaymentOccurence = (paymentsAndRates, currentPaymentType) => {
			if (!paymentsAndRates) return;
			switch (currentPaymentType) {
				case finance:
				case lease:
					return paymentsAndRates.frequency;
				default:
					return;
			}
		}

		$service.getCurrentEffectiveRate = (paymentsAndRates, currentPaymentType) => {
			if (!paymentsAndRates) return;
			switch (currentPaymentType) {
				case finance:
					return paymentsAndRates.currentRates.finance.eir;
				case lease:
					return paymentsAndRates.currentRates.lease.eir;
				default:
					return;
			}
		}

		$service.getCurrentApplicableRate = (paymentsAndRates, currentPaymentType) => {
			if (!paymentsAndRates) return;
			switch (currentPaymentType) {
				case finance:
					return paymentsAndRates.currentRates.finance.apr;
				case lease:
					return paymentsAndRates.currentRates.lease.apr;
				default:
					return;
			}
		}

		$service.getNumberPayments = (paymentsAndRates, currentPaymentType) => {
			if (!paymentsAndRates) return;
			switch (currentPaymentType) {
				case finance:
					return paymentsAndRates.financeTerm;
				case lease:
					return paymentsAndRates.leaseTerm;
				default:
					return;
			}
		}

		$service.getCurrentDiscounts = paymentsAndRates => {
			if (!paymentsAndRates) return;
			return paymentsAndRates.totalDiscount;
		}

		$service.toInt = toParse => {
			return parseInt(toParse) || 0;
		}
	}
})();
