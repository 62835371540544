(function () {
	angular
		.module('fca.buyOnlineServicesReview')
		.directive('fcaBuyOnlineServicesReview', FcaBuyOnlineServicesReview);

	function FcaBuyOnlineServicesReview() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineServicesReviewCtrl
		};

		function FcaBuyOnlineServicesReviewCtrl($scope, $rootScope, externalConfigLoader, userLocation, fcaBuyOnlineService) {
			'ngInject';

			let $ctrl = this;

			$ctrl.$onInit = () => {
				// initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
				$ctrl.language = $ctrl.config.getConfig('language');
				// check if we already have a trade-in-value
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				$ctrl.selectedServices = checkout.selectedServices;
				// make sure we have at lease one service that's selected
				$ctrl.hasSelectedServices = $ctrl.selectedServices
					&& Object
						.values($ctrl.selectedServices)
						.findIndex(val => val === true) !== -1;
			};

			$ctrl.isServiceSelected = (code) => {
				let selected = false;
				if ($ctrl.selectedServices) {
					let value = $ctrl.selectedServices[code];
					selected = value;
				}
				return selected;
			};

			$rootScope.$on('fca-custom-order:custom-order-services-review-update', () => {
				$ctrl.$onInit();
			});
		}
	}
})();
