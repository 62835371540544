(function() {
	angular
		.module('fca.dealerName')
		.directive('fcaDealerName', dealerName);

	function dealerName() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				brandCode: '@',
				dealerCode: '@',
			},
			controller: fcaDealerNameController,
			controllerAs: '$fcaDealerNameCtrl',
		};

		function fcaDealerNameController($rootScope, $scope, $element, $http) {
			'ngInject';

			this.$onInit = () => {
				if (this.brandCode && this.dealerCode) {
					this.fetchASingleDealer();
				} else {
					$($element).remove();
				}
			};

			this.fetchASingleDealer = () => {
				$http.get(`/data/dealers/${this.brandCode}/${this.dealerCode}`).then((result) => {
						this.dealerName = result.data.name;
				});
			};

		}
	}
})();
