(function() {
	'use strict';

	angular
		.module('fca.geolocator')
		.service('regionMappingService', RegionMappingService);

	// function RegionMappingService($translate) {
	function RegionMappingService() {
		'ngInject';

		// const _$ = $translate.instant;

		const REGIONS = [
			'alberta',
			'atlantic',
			'quebec',
			'ontario',
			'prairies',
			'pacific'
		];

		const DEFAULT_REGION = REGIONS[3];

		this.getRegions = () => {
			let regions = [];
			for (let i = 0, l = REGIONS.length; i < l; i++) {
				let slug = REGIONS[i];

				regions.push({
					slug: slug,
					// region: _$(`codes.regions.${slug}`)
					region: `codes.regions.${slug}`
				});
			}

			return regions;
		};

		this.getDefault = () => {
			return {
				slug: DEFAULT_REGION,
				// region: _$(`codes.regions.${DEFAULT_REGION}`)
				region: `codes.regions.${DEFAULT_REGION}`
			};
		};
	}
})();
