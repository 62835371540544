(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaRoundup', FcaRoundup);

    function FcaRoundup() {
        'ngInject';
        
        return function (value) {
            return Math.ceil(value);
        };
    }
})();
