(function(ng) {
	'use strict';

	angular
		.module('fca.daaContactDealer')
		.controller('daaContactDealerDialogController', DialogController);

	function DialogController($scope, $rootScope, ngDialog, $translate, fcaGeolocator) {
		'ngInject';

		let dialogData = $scope.ngDialogData;

		this.dialogTitle = $translate.instant(dialogData.dialogTitle);

		this.formOptions = dialogData.formOptions ? dialogData.formOptions : {};

		this.location = dialogData.location;

		$rootScope.$broadcast('new-inventory:close-calculator', {});

		this.dealer = dialogData.dealer;

		this.isFormSubmitted = false;

		this.submitted = () => {
			if (!this.isFormSubmitted) {
				this.isFormSubmitted = true;
				// Change title
				this.dialogTitle = $translate.instant('contact.sent.headline');
			}
		};

		this.close = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			ngDialog.close();
		};
	}
})(angular);
