(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021Hero', vlp2021HeroDirective);

	function vlp2021HeroDirective() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'vlpHero',
			controller: vlp2021HeroController
		};

		function vlp2021HeroController($scope, $timeout, fcaVideoFactory) {
			'ngInject';

			const $ctrl = this;

			$ctrl.autoplay = null;
			$ctrl.videoThumbnailVisible = false;
			$ctrl.bentoType = null;
			$ctrl.player = null;
			$ctrl.youtubeVideoId = null;
			$ctrl.videoRatioSpacing = false;
			$ctrl.mediaVisible = false;

			$ctrl.$onInit = () => {
				angular.element(document).ready( () => {
					$ctrl.videoThumbnail = document.querySelector('.bentoBox__video-thumbnail');

					if ($ctrl.videoThumbnail) {
						$ctrl.videoThumbnailVisible = $ctrl.videoThumbnail.classList.contains('bentoBox__video-thumbnail--visible');
						$ctrl.autoplay = $ctrl.videoThumbnail.dataset.autoplay;
						$ctrl.bentoType = $ctrl.videoThumbnail.dataset.bentotype;
						$ctrl.youtubeVideoId = $ctrl.videoThumbnail.dataset.videoid;
					}

					if ($ctrl.bentoType === 'video') {
						$ctrl.setVideo();
					} else {
						$ctrl.mediaVisible = true;
					}
				});
			};

			$ctrl.setVideo = () => {
				$ctrl.video = document.querySelector('.VLP2021-hero--video video');

				if ($ctrl.video) {
					$ctrl.video.onended = () => {
						$ctrl.videoThumbnailVisible = true;
						$scope.$apply();
					};
				}
			};

			$ctrl.playVideo = () => {
				$ctrl.mediaVisible = true;

				if ($ctrl.bentoType === 'youtube') {
					$ctrl.videoThumbnailVisible = false;
					$ctrl.videoRatioSpacing = true;

					fcaVideoFactory.onAPIReady.then(() => {
						$ctrl.player = new YT.Player('youtubePlayer', {
							height: '390',
							width: '640',
							host: 'https://www.youtube.com',
							videoId: `${$ctrl.youtubeVideoId.replace('embed/', '')}`,
							playerVars: {
								rel: 0,
								showinfo: 0
							},
							events: {
								'onReady': () => {
									$ctrl.player.playVideo();
									$scope.$apply();
								},
								'onStateChange': e => {
									// video done
									if(e.data === 0) {
										$ctrl.videoRatioSpacing = false;
										$ctrl.videoThumbnailVisible = true;
										$ctrl.player.destroy();
										$scope.$apply();
									}
								}
							}
						});
					});

				} else {
					if ($ctrl.video.play) {
						const playPromise = $ctrl.video.play();

						if (playPromise !== undefined) {
							playPromise.then(function() {
								$ctrl.videoThumbnailVisible = false;
								$scope.$apply();

								$ctrl.video.onended = () => {
									$ctrl.videoThumbnailVisible = true;
									$scope.$apply();
								};
							}).catch(function(error) {
								// Video playback failed.
								console.error('Error playing the video', error);
							});
						}
					}
				}
			};
		}
	}
})();
