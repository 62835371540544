/* eslint-disable indent */
(function () {
    angular
    .module('fca.buildAndPrice.trimUpsellWindow')
    .component('buildAndPriceTrimUpsellWindow', {
        controller: BuildAndPriceTrimUpsellWindow,
        controllerAs: '$ctrl',
        templateUrl: '/build-and-price/trim-upsell-window/trim-upsell-window.html',
        bindings: {
            brandName: '@',
            defaultFinanceRate: '@',
            trimDescription: '@',
        }
    });

    function BuildAndPriceTrimUpsellWindow($scope, $rootScope, $http, $document, $filter, configService,
        calculatorService,
        pricingDataService, financeProgramService, $location) {

        'ngInject';

        const $ctrl = this;
        const summaryBtn = $('.C_BP-main-navigation-summary');
        const DEFAULT_FREQUENCY = 'weekly';
        const DEFAULT_DURATION = 96;
        let lastScroll = 0;
        let scrollPaused = false;

        $ctrl.isActive = false;
        $ctrl.isExpanded = false;
        $ctrl.showExpandBtn = false;
        $ctrl.onStepSummary = false;
        $ctrl.isLoading = true;
        $ctrl.baseImageUrl = FCA_SITES_CONFIG.jelliesUrl;
        $ctrl.brandCode = FCA_SITES_CONFIG.name;
        $ctrl.nameplateName = '';
        $ctrl.upsellVehicleName = '';
        $ctrl.upsellVehicleJelly = '';
        $ctrl.currentVehiclePaintCode = '';
        $ctrl.currentTrimDescription = '';
        $ctrl.trimUpsellPostData = {};
        $ctrl.featureList = [];
        $ctrl.paymentDifferenceString = '';

        $ctrl.$postLink = () => {
            $rootScope.$on('navigation: active-step-changed', (_, data) => {
                $ctrl.onStepSummary = data.step === 'summary';
                if ($ctrl.onStepSummary && !angular.equals($ctrl.trimUpsellPostData, {})) {
                    getTrimUpsell();
                }
            });

            $rootScope.$on('trim-upsell:configuration-updated', (_, data) => {
                removeSessionStorage();
                $ctrl.trimUpsellPostData.language = FCA_SITES_CONFIG.language;
                $ctrl.trimUpsellPostData.acode = data.acode;
                $ctrl.trimUpsellPostData.provinceCode = data.provinceCode;
                if (data.package.code) {
                    $ctrl.trimUpsellPostData.packageCode = data.package.code;
                }
                if (data.paint) {
                    $ctrl.currentVehiclePaintCode = data.paint.substr(0, 3);
                }
                $ctrl.trimUpsellPostData.scratchSave = data.scratchSave;
                if (summaryBtn.hasClass('is-active')) {
                    getTrimUpsell();
                }
                if (data.driveTrain) {
                    $ctrl.driveTrain = data.driveTrain;
                }
            });

            addEvents();
        };

        $ctrl.closeWindow = () => {
            $ctrl.isActive = false;
            resumeScrolling();
        };

        function openWindow() {
            $ctrl.isActive = true;
            pauseScrolling();
        }

        function isInSessionStorage() {
            return sessionStorage.getItem('trimUpsell');
        }

        function setSessionStorage() {
            sessionStorage.setItem('trimUpsell', 'true');
        }

        function removeSessionStorage() {
            sessionStorage.removeItem('trimUpsell');
        }

        function getTrimUpsell() {
            const postUrl = '/api/buildandprice/trimupsell';
            if ($ctrl.trimUpsellPostData.acode && $ctrl.trimUpsellPostData.provinceCode &&
                $ctrl.trimUpsellPostData.language && $ctrl.trimUpsellPostData.scratchSave &&
                $ctrl.trimUpsellPostData.packageCode && $ctrl.currentVehiclePaintCode !== '' && !isInSessionStorage()) {
                $http.post(postUrl, $ctrl.trimUpsellPostData)
                .then(response => {
                    const trimUpsellData = response.data;
                    if (trimUpsellData && trimUpsellData.acode && trimUpsellData.vehicleCertificate) {
                        $ctrl.nameplateName = trimUpsellData.nameplateName;
                        $ctrl.featureList = trimUpsellData.features;
                        $ctrl.trimUpsellPricingData = trimUpsellData.pricing;
                        $ctrl.currentBuildPricingData = configService.pricing;

                        manageDescriptions(trimUpsellData.vehicleCertificate);
                        getUpsellVehicleJelly(trimUpsellData.jellyFiles, trimUpsellData.vehicleCertificate.year);
                        setBuildAndPriceUrl(trimUpsellData.vehicleCertificate, trimUpsellData.acode,
                            trimUpsellData.scratchSave, $ctrl.trimUpsellPostData.language);
                        managePaymentDifference();
                        openWindow();
                        setSessionStorage();
                    }
                }).catch(e => {
                    $ctrl.trimUpsellPostData = {};
                    $ctrl.closeWindow();
                    throw new Error('error retrieving Trim upsell Data', e);
                });
            }

        }

        function getUpsellVehicleJelly(jellyFiles, year) {
            let jellyFile = jellyFiles.find(file => file.filename.includes($ctrl.currentVehiclePaintCode));
            if (!jellyFile) {
                jellyFile = jellyFiles[0];
            }
            let url = $ctrl.baseImageUrl + year + '/800x510/';
            let filename = jellyFile.filename.split('.')[0];
            let hash = jellyFile.checksum;
            url += filename;
            url += "." + hash + ".png";
            $ctrl.upsellVehicleJelly = url;
            checkIfJellyIsLoaded($ctrl.upsellVehicleJelly);
        }

        function checkIfJellyIsLoaded(path) {
            const img = new Image();
            img.src = path;
            img.onload = () => {
                $scope.$apply(() => $ctrl.isLoading = false);
            }
        }

        function setBuildAndPriceUrl(vehicleInfo, acode, scratchSave, language) {
			let hashParameters = getHashParameters();
			hashParameters.scratchsave = `${LZString.compressToEncodedURIComponent(scratchSave)}`
			delete hashParameters.step;
			const hashParametersForUrl = buildHashParametersFoUrl(hashParameters);

			const headlessRenderer = $location.search()['view-name'] === 'headless_renderer' ?
				`?view-name=headless_renderer`:
				'';

			$ctrl.buildAndPriceUrl =
                `/${language}/build-and-price/` +
                `${vehicleInfo.brandCode}/` +
                `${vehicleInfo.nameplateCode}/` +
                `${vehicleInfo.year}/` +
                `${vehicleInfo.modelYearId}/${acode}` +
				`${headlessRenderer}` +
                `${hashParametersForUrl}`;
        }

        function addEvents() {
            window.addEventListener('scroll', () => {
                if (!scrollPaused) {
                    lastScroll = window.scrollY || window.pageYOffset;
                }
            });

            window.addEventListener(
                'keydown',
                event => $scope.$apply(onKeydown(event))
            );

            $document.on('click', event => {
                if (event.target.id === 'trim-upsell-window-container') {
                    $scope.$apply($ctrl.closeWindow());
                }
            });
        }

        function onKeydown(keyEvent) {
            switch (keyEvent.keyCode) {
                case 27 :
                    $ctrl.closeWindow();
                    break;
            }
        }

        function pauseScrolling() {
            scrollPaused = true;
            let heightValue = 'calc(100% + ' + lastScroll + 'px)';

            $('body').addClass('noScroll')
            .css({
                'top': -lastScroll,
                'height': heightValue
            });
        }

        function resumeScrolling() {
            scrollPaused = false;
            $('body').removeClass('noScroll')
                .removeAttr('style');

            if (lastScroll !== undefined) {
                window.scrollTo(0, lastScroll);
            }
        }

        let calculatePayment = (pricingData) => {

            let payment = 0;

            if (pricingData) {
                const netAmount = getNetAmount(pricingData);

                if (pricingData.finance) {

                    financeProgramService.sanitizeFinanceProgramTerms(
                        pricingData.finance.terms,
                        $ctrl.defaultFinanceRate);

                    const term = pricingData.finance.terms.find(element => {
                        return parseInt(element.duration) === DEFAULT_DURATION
                    });
                    if (term) {
                        const financeData = {
                            'amount': netAmount,
                            'interestRate': term.rate,
                            'term': term.duration,
                            'nbOfPaymentPerYear': calculatorService.getNbPaymentPerYear(DEFAULT_FREQUENCY)
                        };
                        const result = calculatorService.calculateFinancePayment(financeData);
                        if (result && result.payment) {
                            payment = result.payment;
                        }
                    }
                }
            }

            return payment;
        };

        let managePaymentDifference = () => {

            $ctrl.paymentDifferenceString = '';
            $ctrl.paymentLabel = '';

            const trimUpsellPayment = calculatePayment($ctrl.trimUpsellPricingData);
            const currentBuildPayment = calculatePayment($ctrl.currentBuildPricingData);
            const diff = trimUpsellPayment - currentBuildPayment;

            if (currentBuildPayment > 0) {
                if (diff < 0) {
                    $ctrl.paymentDifferenceString = $filter('fcaCurrencyWithDollarSign')(Math.abs(diff));
                    $ctrl.paymentLabel = 'build-and-price.trim-upsell.payment-info-less';
                } else if (diff === 0) {
                    $ctrl.paymentLabel = 'build-and-price.trim-upsell.payment-info-equal';
                } else if (diff > 0) {
                    $ctrl.paymentDifferenceString = $filter('fcaCurrencyWithDollarSign')(diff);
                    $ctrl.paymentLabel = 'build-and-price.trim-upsell.payment-info-more';
                } else {
                    console.error("The difference of payment for trim upsell is incoherent!");
                }
            } else {
                console.error("The difference of payment for trim upsell is incoherent!");
            }
        };

        let getNetAmount = (pricingData) => {

            return pricingDataService.getBasePrice(pricingData)
                + pricingDataService.getFreight(pricingData)
                + pricingDataService.getAcTax(pricingData)
                + pricingDataService.getGreenLevy(pricingData)
                + pricingDataService.getOptions(pricingData);
        };

        // if the trim description of trim upsell is the same than this of current build, we add the drive train
        // description in vehicle upsell name and trim description.
        let manageDescriptions = (vehicleCertificate) => {
            //default values
            $ctrl.currentTrimDescription = $ctrl.trimDescription;
            $ctrl.upsellVehicleName = $ctrl.nameplateName;

            if ($ctrl.trimDescription
                && $ctrl.driveTrain
                && $ctrl.driveTrain.currentValue
                && $ctrl.driveTrain.list) {

                //get the trim description of the upsell
                let upsellTrimDescription = '';
                if(vehicleCertificate && vehicleCertificate.trimDesc) {
                    upsellTrimDescription = vehicleCertificate.trimDesc[$ctrl.trimUpsellPostData.language];
                }

                //compare the trim description of the current build with this of the upsell
                if($ctrl.trimDescription === upsellTrimDescription) {

                    // retrieve the drive train to display his description in current trim description
                    const selectedDriveTrain = $ctrl.driveTrain.list.find(element => element.id === $ctrl.driveTrain.currentValue);
                    if(selectedDriveTrain) {
                        $ctrl.currentTrimDescription += ' ' + selectedDriveTrain.description;
                    }

                    // add drive type in upsell vehicle description
                    if(vehicleCertificate.driveType) {
                        $ctrl.upsellVehicleName += ' ' + vehicleCertificate.driveType;
                    }
                }
            }
        };
    }
})();
