(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSearchResult', fcaSearchResult);

	function fcaSearchResult() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				isShowMore: '<',
				buttonHref: '@',
				logo: '@',
				titleLabel: '@',
				buttonLabel: '@',
				sorryLabel: '@',
				brandCode: '@',
				brandName: '@',
				lang: '@',
				nbPerPage: '@',
				baseUrl: '@',
				isH1: '<'
			},
			controllerAs: 'searchResult',
			controller: FcaSearchResultController,
			templateUrl: '/components/search/fca-search-result.html',
			require: {
				fcaSearchCtrl: '^fcaSearch'
			}
		};

		function FcaSearchResultController($scope, $element, $http, $location, $interpolate, $window, $timeout, gtmAnalytics) {
			'ngInject';
			/* eslint max-len: ["error", 150] */

			this.isBusy = false;
			this.showMoreClicked = false;
			this.searchTerm = '';
			this.buttonLabelReplaced = '';
			this.isFirstSearchFromHash = true;

			this.$onInit = () => {
				this.reset();
				$element.addClass('search-result');
				$scope.$on('search.search', this.search);
				this.buttonLabelReplaced = this.buttonLabel.replace('{%nextPageCount%}', '{{nextPageCount}}');
				this.fcaSearchCtrl.registerResultsCtrl(this);

				this.originUrl = $window.location.origin;
			};

			this.$postLink = () => {
				let link = $('.search-noresults-link');

				link.bind('click', function (event) {
					event.preventDefault();
					$('#search-field').focus();
				});

			};

			this.search = (event, searchTerm) => {
				this.reset();
				this.searchTerm = searchTerm;
				this.isBusy = true;
				this.showMoreClicked = false;
				this.loadCount();
			};

			this.loadCount = () => {
				this.isBusy = true;
				// eslint-disable-next-line max-len
				const url = '/data/story/'+this.brandCode+'/'+this.lang+'/count?searchTerm='+encodeURIComponent(this.searchTerm);

				$http.get(url)
					.then((data) => {
						this.parseCount(data.data);
					})
					.catch((data, status, headers, config) => {});
			};

			this.onShowMoreResultsClicked = () => {
				this.loadNextPage();
				this.showMoreClicked = true;
			};

			this.loadNextPage = () => {
				this.currentPage++;
				this.isBusy = true;
				// eslint-disable-next-line max-len
				const url = '/story/search/'+this.brandCode+'/'+this.lang+'?searchTerm='+encodeURIComponent(this.searchTerm)+'&page='+this.currentPage+'&row='+this.nbPerPage;
				$http.get(url)
					.then((data) => {
						this.parseData(data.data);
					})
					.catch((data, status, headers, config) => {});

				const buttonLabelInterpolated = $interpolate(this.buttonLabelReplaced)(this);

				if($location.hash() !== '' && this.isFirstSearchFromHash) {
					this.isFirstSearchFromHash = false;
				} else {
					gtmAnalytics.trackEvent('event', {
						category: 'search',
						label: buttonLabelInterpolated.toLowerCase()
					});
				}
			};

			this.reset = () => {
				this.searchTerm = '';
				this.currentPage = -1;
				this.totalCount = -1;
				this.results = [];
				this.updateNextPageCount(-1);
			};

			this.highlight = (str) => {
				let cleanStr = str.replace(/<(?!\/?(sup|sub))[^>]+(>|$)/g, '');

				return cleanStr.replace(
					new RegExp('('+this.searchTerm+')', 'gi'),
					'<span class="search-result-item-keyword">$1</span>'
				);
			};

			this.parseCount = (data) => {
				this.totalCount = data.count;
				this.fcaSearchCtrl.updateTotalResults();
				if (this.totalCount > 0) {
					this.loadNextPage();
				} else {
					this.isBusy = false;
				}
			};

			this.parseData = (data) => {
				if (this.showMoreClicked) {
					this.showMoreClicked = false;

					if (data.length > 0) {
						this.focusOnItemFromHref(data[0].relativePath[this.lang]);
					}
				}
				for (let i=0; i<data.length; i++) {
					this.results.push({
						title: this.highlight(data[i].title[this.lang]),
						subTitle: this.highlight(data[i].subTitle[this.lang]),
						desc: this.highlight(data[i].desc[this.lang]),
						relativePath: data[i].relativePath[this.lang],
						titlePlain: data[i].title[this.lang],
						subTitlePlain: data[i].subTitle[this.lang]
					});
				}
				this.updateNextPageCount(Math.min(this.totalCount - this.results.length, this.nbPerPage));
				this.isBusy = false;
				$compile($element.contents())(scope);
			};

			this.updateNextPageCount = (value) => {
				this.nextPageCount = value;
				const buttonLabelInterpolated = $interpolate(this.buttonLabelReplaced)(this);
				$element.find('.buttonLabelInterpolated').html(buttonLabelInterpolated);
			};

			this.focusOnItemFromHref = (href) => {
				$timeout(() => {
					let item = $element.find('div[data-analytics-event=\'' + href + '\'] a');
					if (item && item.length > 0) {
						item.first().focus();
					}
				});
			};

			this.goToAllBrandResults = (value) => {
				const url = this.buttonHref+'#'+$scope.search.hashParam+$scope.search.searchTerm;

				gtmAnalytics.trackEvent('event', {
					category: 'search',
					label: this.buttonLabel.toLowerCase()
				});

				$window.location.href = url;
			};
		}
	}
})();
