/* eslint-disable indent */
(function() {
    angular
        .module('fca.chatPopup')
        .component('fcaChatPopup', {
            controller: FcaChatPopupController,
            controllerAs: 'fcaChatPopupCtrl',
            templateUrl: '/chat-popup/fca-chat-popup.content.html',
            bindings: {
                dealersLink: '@'
            }
        });

    function FcaChatPopupController($scope, $rootScope, $http, cookieLocation, fcaGeolocator,
                                    $cookies, $analytics, externalConfigLoader, dealerChatService) {
        'ngInject';

		let config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
        const COOKIE_NAME = config.getConfig('dealersWithChatCookieName');
        const BRAND = config.getConfig('name');
        let dialogRef;

        this.isDisplayed = false;
        this.isMinified = true;
        this.dealersCookieDaysExpiration = 1;

        $scope.location = cookieLocation.getLocation();

        $scope.dealers = [];

        this.setDealerChatUrls = (dealerList) => {
            dealerList.forEach(function(dealer, index) {
                let originationUrl = encodeURIComponent(window.location.href);
                if (dealer.chatProvider == 'Live Person' && dealer.province == 'QC') {
                    dealer.chatUrl = dealerChatService.getDealerChatUrl(dealer.chatProvider, dealer.chatProviderIdFr, dealer.code, originationUrl);
                } else {
                    dealer.chatUrl = dealerChatService.getDealerChatUrl(dealer.chatProvider, dealer.chatProviderIdEn, dealer.code, originationUrl);
                }
            });
        };

        this.getDealersWithChat = () => {

            let savedDealersWithChat = $cookies.getObject('dealersWithChat');

            // set dealersWithChat cookie if:
            // - cookie does not exist
            // - location is different than the location in the cookie
            // - the dealer list is empty or undefined
            // - Prevent old dealerChat cookie that contained an array instead of an object
            if (savedDealersWithChat === undefined || Array.isArray(savedDealersWithChat) || savedDealersWithChat.dealerList.length === 0 || !angular.equals(savedDealersWithChat.location, $scope.location)) {
                let url = ' /data/dealers/closest-chat?brand=' + BRAND + '&postalCode='
                    + $scope.location.postalCode.replace(' ', '+')
                    + '&minResults=3&maxResults=3';

                $http.get(url).then((r) => {
                    // only keep what we need
                    let dealerList = [];
                    let cookieObj = {};

                    r.data.dealers.forEach(function(data, index) {
                        let dealer = {};
                        dealer.name = data.name;
                        dealer.city = data.city;
                        dealer.province = data.province;
                        dealer.distance = data.distance;
                        dealer.code = data.code;
                        dealer.chatProvider = data.chatProvider;
                        dealer.chatProviderIdEn = data.chatProviderIdEn;
                        dealer.chatProviderIdFr = data.chatProviderIdFr;

                        dealerList.push(dealer);
                    });

                    cookieObj.dealerList = dealerList;
                    cookieObj.location = $scope.location;

                    $scope.dealers = dealerList;

                    let today = new Date();
                    let todayPlusDays = new Date(today.setDate(
                        today.getDate() + this.dealersCookieDaysExpiration)
                    );

                    $cookies.remove('dealersWithChat');
                    $cookies.putObject(COOKIE_NAME,
                      cookieObj, {path: '/', expires: todayPlusDays});

                    // only set urls after it's saved in cookie
                    this.setDealerChatUrls($scope.dealers);

                    if ($scope.dealers.length > 0) {
                        $rootScope.$broadcast('nearestDealersWithChatObtained');
                        $analytics.eventTrack('nearest-dealers-chat',
                            {category: 'nearest-dealers-chat', label: 'nearest-dealers-chat-shown'});
                        this.showPopup();
                    } else {
                        this.minifyPopup();
                        this.hidePopup();
                    }
                }).catch(function(e) {
                    console.error('error retrieving closest chat dealers', e);
                });
            } else {
                $scope.dealers = savedDealersWithChat.dealerList;
                // set dealers chat urls
                this.setDealerChatUrls($scope.dealers);
                if ($scope.dealers.length > 0) {
                    $rootScope.$broadcast('nearestDealersWithChatObtained');
                    $analytics.eventTrack('nearest-dealers-chat', {
                        category: 'nearest-dealers-chat', label: 'nearest-dealers-chat-shown'
                    });
                    this.showPopup();
                } else {
                    this.minifyPopup();
                    this.hidePopup();
                }
            }
        };

        this.showPopup = () => {
            this.isDisplayed = true;
        };

        this.hidePopup = () => {
            this.isDisplayed = false;
        };

        this.minifyPopup = () => {
            this.isMinified = true;
        };

        this.unMinifyPopup = () => {
            this.isMinified = false;
        };

        this.$onInit = () => {
            /* eslint-disable no-invalid-this */
            if ($scope.location !== null) {
                this.getDealersWithChat();
            }

            /* Listen if the location is changed outside this component */
            let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
            $scope.$on(eventUpdateName, (evt, args) => {
                $scope.location = args[0];
                $cookies.remove(COOKIE_NAME, {path: '/'});
                this.getDealersWithChat();
            });

            /* Listen if the chat popup must be opened */
            $scope.$on('openDealersChatPopup', () => {
                this.unMinifyPopup();
            });
        };

        this.focupTrapActive = () => {
            if (!dialogRef) {
                let navDialogEl = document.querySelector('.chat-popup-content');
                let dialogOverlay = document.querySelector('.chat-popup-overlay');

                dialogRef = new Dialog(navDialogEl, dialogOverlay);
                dialogRef.addEventListeners('.chat-popup-reopen', '.chat-popup-close');
            }
        };

        this.getDealerChatUrl = (dealer) => {
            return dealer.chatUrl;
        };
    }
})();
