(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFleetTabs', fcaFleetTabs);

	function fcaFleetTabs() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				defaultTab: '@'
			},
			controllerAs: '$fcaFleetTabs',
			controller: fcaFleetTabsController
		};

		function fcaFleetTabsController($rootScope) {
			'ngInject';

			const $ctrl = this;

			$ctrl.$onInit = () => {
				$ctrl.activeTab = $ctrl.defaultTab;
			};

			$ctrl.setActiveTab = (value) => {
				$ctrl.activeTab = value;
				$rootScope.$broadcast("tabSelection", value);
			};
		}
	}
})();
