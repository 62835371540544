
(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaMostPopular', fcaMostPopular);

    function fcaMostPopular() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$fcaMostPopular',
            controller: fcaMostPopularController
        };

        function fcaMostPopularController($scope, $element, $timeout, functionExecutionControl, functionDynamicSizing) { // eslint-disable-line max-len
            'ngInject';

            let $ctrl = this;

            $ctrl.$onInit = () => {
                $scope.$$postDigest(() => {
                    $timeout(() => {
                        $ctrl.updateAllDynamicHeight();
                    }, 600);

                    window.addEventListener('resize', (event) => {
                        functionExecutionControl.debounce($ctrl.updateAllDynamicHeight(), 400);
                    });
                });
            };

            $ctrl.titleElements = $element.find('.FTF-popular-builds__title');
            $ctrl.startingAtElements = $element.find('.FTF-popular-builds__starting-at');

            $ctrl.updateAllDynamicHeight = () => {
                functionDynamicSizing.equalHeight($ctrl.titleElements);
                functionDynamicSizing.equalHeight($ctrl.startingAtElements);
            };
        }
    }
})();
