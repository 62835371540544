(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaCurrencyWithDollar', CurrencyWithDollar);

    /**
     * Formats a number as a currency (through the fcaCurrencyFilter).
     * And adds the dollar sign at the current position.
     * Result is plain text.
     *
     * French: [currency amount][space][dollar sign]
     * English: [dollar sign][currency amount]
     *
     * @param $filter
     * @returns {function(*=): string}
     * @constructor
     */
    function CurrencyWithDollar($filter) {
        'ngInject';

        // Current language
        let language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');

        return function(pPrice) {
            let result = "";
            let fcaCurrencyFilter = $filter('fcaCurrency');
            let price = fcaCurrencyFilter(pPrice);

            if (language === 'fr') {
                result = `${price} $`;
            } else {
                result = `$${price}`;
            }

            return result;
        };
    }
})();