(function() {
  'use strict';

  angular
    .module('fca.commonDirectives')
    .directive('incentivesBlock', incentivesBlock);

  function incentivesBlock() {
    'ngInject';
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
            incentiveCode: '@',
            incentiveAlt: '@',
            incentiveMediaUrl: '@'
        },
        controllerAs: '$incentivesBlockCtrl',
        controller: IncentivesBlock
    };

      // Directive to display Incentive image in SNI - Use un inventory-overview.ftl
        function IncentivesBlock() {
            const $ctrl = this;
            $ctrl.mediaUrl = window.FCA_SITES_CONFIG.mediaBaseUrl.slice(0, -1);
            $ctrl.incentiveDisclaimersObject = {};

            $ctrl.$onInit = () => {
                $('.incentiveDisclaimers').each( index => {
                    let disclaimer = $('.incentiveDisclaimers').eq(index);
                    let id = disclaimer.data('id').replace('incentive.', '');
                    $ctrl.incentiveDisclaimersObject[id] = disclaimer.text();
                });

            }
        }
  }
})();
