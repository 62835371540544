(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculator')
		.service('daaMappingService', MappingService);

	/**
	 * @ngdoc service
	 * @name fca.daaCalculator.service:daaMappingService
	 * @description
	 * Mapping service to fetch injected object by backend.
	 * @requires fca.daaCalculatorWidget.service:daaFrequency
	 * <pre>
	 * ng.module('myModule')
	 *   .controller('MyCtrl', (daaMappingService) => {
	 *     this.$onInit = () => {
	 *       this.options = daaMappingService.mapOptions(this.options);
	 *     };
	 *   });
	 * </pre>
	 */
	function MappingService(daaFrequency) {
		'ngInject';

		/**
		 * @ngdoc method
		 * @name mapOptions
		 * @methodOf fca.daaCalculator.service:daaMappingService
		 * @param  {Object} options List of options to map
		 * @return {Object}         Mapped object ready to be used by calculator
		 */
		this.mapOptions = (options) => {
			let {
				msrp,
				offerPrice,
				finance,
				leasing,
				residuals,
				altResiduals,
				residual,
				leaseKilometer,
				termToolTip,
				provincialSalesTaxList,
				beforeTaxDiscount,
				afterTaxDiscount,
				effectiveRate,
				altEffectiveRate
			} = options;

			// [TODO] remove after backend plug normalize data
			if (!msrp) {
				msrp = offerPrice;
			} else if (!offerPrice) {
				offerPrice = msrp;
			}

			let defaultMaxResidualPercent = 100;
			let maxResidualPercent = defaultMaxResidualPercent;

			let isAlternativeProgram = false;
			if (!options.hideLeasing) {
				isAlternativeProgram = leasing.alternativeProgram
			} else {
				isAlternativeProgram = finance.alternativeProgram
			}

			let residualList = isAlternativeProgram ? altResiduals : residuals;
			if (ng.isObject(residualList)) {
				maxResidualPercent = residualList[Object.keys(residualList)[0]];
			}
			let maxTradeInValue = Math.floor((offerPrice - 10) / 2);
			let baseMaxLeaseTradeInValue = (offerPrice - 10) * maxResidualPercent / 100;
			let leaseMaxTradeInValue = Math.floor(baseMaxLeaseTradeInValue / 2);

			let leasingMaxTerm = 0;
			if (!options.hideLeasing) {
				let leasingRp = isAlternativeProgram ? leasing.altRp : leasing.rp;
				leasingMaxTerm = Math.max.apply(null, Object.keys(leasingRp));
			}

			let financeMaxTerm = 0;
			if (!options.hideFinance) {
				let financeRp = isAlternativeProgram ? finance.altRp : finance.rp;
				financeMaxTerm = Math.max.apply(null, Object.keys(financeRp));
			}

			if (!residual) {
				residual = 100;
			}

			let base = {
				residual: residual,
				residuals: residuals,
				altResiduals: altResiduals,
				msrp: msrp,
				offerPrice: offerPrice,
				maxDownPayment: maxTradeInValue,
				maxTradeInValue: maxTradeInValue,
				leaseKilometer: leaseKilometer,
				termToolTip: termToolTip || false,
				provincialSalesTaxList: provincialSalesTaxList,
				beforeTaxDiscount: beforeTaxDiscount,
				afterTaxDiscount: afterTaxDiscount,
				effectiveRate : effectiveRate,
				altEffectiveRate : altEffectiveRate
			};

			// Set default value if not exists
			leasing = defaultLeaseFinance(leasing);
			// Set default value if not exists
			finance = defaultLeaseFinance(finance);

			let r = {
				finance: ng.extend({}, base, finance, {
					toPay: finance.payment || 0,
					maxTerm: financeMaxTerm,
					tradeInValue: 0,
					frequency: daaFrequency.getFrequencyMappingValue(finance.frequency),
					type: 'finance',
					minFrequency: 0,
					nonResidualizedAmount: normalizeValue(finance.nonResidualizedAmount, 'int'),
					beforeTaxDiscount:finance.beforeTaxDiscount || 0,
					afterTaxDiscount: finance.afterTaxDiscount || 0,
					altBeforeTaxDiscount: finance.altBeforeTaxDiscount || 0,
					altAfterTaxDiscount: finance.altAfterTaxDiscount || 0
				}),
				leasing: ng.extend({}, base, leasing, {
					toPay: leasing.payment || 0,
					maxTerm: leasingMaxTerm,
					tradeInValue: 0,
					maxDownPayment: leaseMaxTradeInValue,
					maxTradeInValue: leaseMaxTradeInValue,
					frequency: daaFrequency.getFrequencyMappingValue(leasing.frequency),
					type: 'leasing',
					minFrequency: 0,
					nonResidualizedAmount: normalizeValue(leasing.nonResidualizedAmount, 'int'),
					beforeTaxDiscount:leasing.beforeTaxDiscount || 0,
					afterTaxDiscount: leasing.afterTaxDiscount || 0,
					altBeforeTaxDiscount: leasing.altBeforeTaxDiscount || 0,
					altAfterTaxDiscount: leasing.altAfterTaxDiscount || 0
				}),
				cash: ng.extend({}, base, {
					tradeInValue: 0,
					maxTradeInValue: maxTradeInValue,
					toPay: offerPrice,
					maxTerm: 0,
					term: 0,
					frequency: 0,
					rp: 0,
					alternativeProgram: false,
					altRp: 0,
					type: 'cash',
					minFrequency: 0,
					nonResidualizedAmount: 0
				})
			};

			return r;
		};

		/**
		 * @ngdoc method
		 * @name mapQueryParams
		 * @methodOf fca.daaCalculator.service:daaMappingService
		 * @param  {Object} opts List of options to map
		 * @param  {String} type Leasing or finance type
		 * @return {Object}      Mapped object ready to be used by a request to backend API
		 */
		this.mapQueryParams = (opts, type) => {
			let {
				financedAmount: financeAmount,
				downPayment,
				tradeInValue,
				residual,
				frequency,
				term,
				rp,
				altRp,
				leaseKilometer,
				nonResidualizedAmount,
				beforeTaxDiscount,
				afterTaxDiscount,
				altBeforeTaxDiscount,
				altAfterTaxDiscount,
				alternativeProgram,
				isBestPayment
			} = opts;

			let r = {
				financeAmount: financeAmount,
				downPayment: downPayment,
				frequency: daaFrequency.getFrequencyMappingValueForRequest(frequency),
				term: term,
				interestRate: rp[term],
				altInterestRate: altRp[term],
				otherNonResidualizedAmounts: nonResidualizedAmount,
				tradeInValue: tradeInValue,
				beforeTaxDiscount: beforeTaxDiscount,
				afterTaxDiscount: afterTaxDiscount,
				altBeforeTaxDiscount: altBeforeTaxDiscount,
				altAfterTaxDiscount: altAfterTaxDiscount,
				isAltProgram : alternativeProgram,
				isBestPayment : isBestPayment
			};

			if (type === 'leasing') {
				r = ng.extend({}, r, {
					mileageKMs: leaseKilometer,
					residual: residual
				});
			}

			if (type === 'financing') {
				r = ng.extend({}, r, {
				});
			}

			return r;
		};

		function defaultLeaseFinance(leaseOrFinance) {
			if (!leaseOrFinance) {
				leaseOrFinance = {
					payment: 0,
					frequency: 0,
					downPayment: 0
				};
			}

			if (leaseOrFinance.hasOwnProperty('downPayment')) {
				let {downPayment} = leaseOrFinance;
				if (!downPayment) {
					leaseOrFinance.downPayment = 0;
				}
			}

			return leaseOrFinance;
		}

		function normalizeValue(value, type) {
			if (type === 'int') {
				if (!value) {
					value = 0;
				}
			}

			return value;
		}
	}
})(angular);
