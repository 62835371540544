(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaTimelineNav', fcaTimelineNav);

	function fcaTimelineNav() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'timeline',
			controller: fcaTimelineNavController
		};

		function fcaTimelineNavController($element) {
			'ngInject';

			let $nav = $element.children();

			this.$onInit = () => {

				$element.css('height', $nav.outerHeight());
				let header_height = $('#zones_header_blocks_menu .main-nav').outerHeight();

				$nav.css({
					'position': 'static',
					'top' : header_height,
					'z-index': 10
				});

				new Waypoint({
					element: $element[0],
					handler: function(direction) {
						if (direction === "down") {
							$nav.css('position', 'fixed');
						} else {
							$nav.css('position', 'static');
						}
					},
					offset: header_height+'px'
				});
			};
		}
	}
})();
