(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaVideoBlock', fcaVideoBlock);

	function fcaVideoBlock() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'videoBlock',
			bindToController: {
				analyticsValue: '@'
			},
			controller: fcaVideoBlockController
		};


		function fcaVideoBlockController($element, $attrs, $timeout, $scope, $rootScope, gtmAnalytics) {
			'ngInject';

			this.isVolumeMuted = true;
			this.isVideoPlaying = false;


			let videoElement;
			let videoList = [];

			this.$onInit = () => {
				videoElement = $element[0].querySelector('video');
				if ($attrs.variousVideo) {
					const parentElem = $($element[0]).parents('.layout-module');
					if (parentElem && parentElem.find('.many-video-container').length === 1) {
						if (parentElem.find('.many-video-container')) {
							const container = parentElem.find('.many-video-container');
							container.find('.vehicles-links-single-video').each((i, val) => {
								videoList.push(val.querySelector('video'));
							})
						}
					}
				}
				this.isVideoOnCurrentBlock();
			};

			this.onVideoClick = () => {
				let analyticsId;

				if (this.isVideoPlaying) {
					analyticsId = 'alfa-videos-pause-';
					videoElement.pause();
					if ($attrs.variousVideo) {
						videoList.forEach(video => {
							video.pause();
						});
						$rootScope.$broadcast('onVideoPause');
					}
					this.isVideoPlaying = false;
				} else {
					analyticsId = 'alfa-videos-play-';
					videoElement.play();
					if ($attrs.variousVideo) {
						$rootScope.$broadcast('onVideoPlay');
					}
					this.isVideoPlaying = true;
				}

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
					label:  analyticsId + this.analyticsValue
				});

			}

			this.isVideoOnCurrentBlock = () => {
				const parentBlock = $($element[0]).parents('.vertical-scroll-block-container')[0];

				if ($attrs.isAutoplay && $attrs.isAutoplay === 'true') {
					if (parentBlock && parentBlock.classList.contains('is-current')) {
						videoElement.play();
						this.isVideoPlaying = true;
					} else {
						videoElement.pause();
						this.isVideoPlaying = false;
					}
				}
			}

			const onScroll = $rootScope.$on('verticalScrollUpdate', this.isVideoOnCurrentBlock);

			$scope.$on('$destroy', () =>{
				onScroll();
			});

		}
	}
})();
