(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.hoursStatus
	 * @description Module to manage dealer's business hours open/close status
	 */
	angular
		.module('fca.hoursStatus', []);
})(angular);
