(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaModelOverview', fcaModelOverview);

	function fcaModelOverview() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				modelYearId: '@',
				aCode: '@',
			},
			controllerAs: 'modelOverview',
			controller: fcaModelOverviewController,
		};

		function fcaModelOverviewController($http) {
			'ngInject';
			/* eslint-disable no-invalid-this */

			let $ctrl = this;
			$ctrl.language = window.FCA_SITES_CONFIG.language;
			$ctrl.vehicle = {};

			$ctrl.$onInit = () => {
				$ctrl.fetchAPIData();
			};


			$ctrl.fetchAPIData = () => {
				let ajaxUrl = (
					`/data/vehicleDetails/${$ctrl.modelYearId}/${$ctrl.aCode}`
				);

				$http.get(ajaxUrl).then(response => {
					$ctrl.vehicle = response.data;
				}, error => {
					console.error(error);
				})

			}
		}
	}
})();
