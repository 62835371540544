(function(ng) {
    angular
        .module('fca.newInventory.modelFeatures')
        .component('newInventoryModelFeatures', {
            controller: NewInventoryModelFeatures,
            controllerAs: '$ctrl',
            templateUrl: '/new-inventory/model-features/model-features.html',
            bindings: {
                vin: "@",
                analyticsLabel: '@?'
            },
        })

    function NewInventoryModelFeatures($http, $location, FCA_MQ_LAYOUT, $window) {
        'ngInject';

        const $ctrl = this;

        // Design has decreed that only one expander may be open at a time.
        // Accommodating as always, we introduce this variable to keep track of
        // the currently expander. See `onSubspecToggle` for the associated
        // logic.
        $ctrl.openSubspecIndex = -1;
        $ctrl.isExpanded = false;

        //setTimeout to throttle resize event for performance
        $ctrl.onresize = setTimeout(() => {
            window.addEventListener('resize', () => {
                $ctrl.isTablet = $window.matchMedia(FCA_MQ_LAYOUT.MEDIUM_PLUS).matches;
            });
        },500);

        $ctrl.featureIcons = {
            'POWERTRAIN': 'engine',
            'INTERIOR AND WHEELS': 'nav-seating',
            'TECHNOLOGY': 'nav-entertainment',
            'CAPABILITIES': 'capability'
        }

        $ctrl.$onInit = () => {
            $ctrl.language = FCA_SITES_CONFIG.language;

            const ajaxPath = (
                `/data/inventories/jeep/vehicle/features/` +
                `${$ctrl.vin.toUpperCase()}?language=${$ctrl.language}`
            );
            
            $http({
                url: ajaxPath,
                method: 'GET',
            }).then(response => {
                $ctrl.standardFeatures = response.data.standardFeatures;
                $ctrl.optionalFeatures = response.data.optionalFeatures;
                $ctrl.exteriorColor = response.data.exteriorColor;
                $ctrl.interiorColor = response.data.interiorColor;
                $ctrl.engine = response.data.engine;
                $ctrl.transmission = response.data.transmission;
                $ctrl.dealerDesc = response.data.dealerDesc;
                $ctrl.dealerDescOpen = false;
                $ctrl.features = response.data.features;
                
                if ($ctrl.optionalFeatures && $ctrl.optionalFeatures.length) {
                    for (let i = 0; i < $ctrl.optionalFeatures.length; i++) {
                        if (!$ctrl.optionalFeatures[i].msrp !== '') {
                            let msrpValue = $ctrl.optionalFeatures[i].msrp

                            if ($ctrl.language === 'en') {
                                if (msrpValue < 0) {
                                    $ctrl.optionalFeatures[i].msrpFormatted = '-<span class="priceSign">$</span>' + Math.abs(msrpValue);
                                } else {
                                    $ctrl.optionalFeatures[i].msrpFormatted = '<span class="priceSign">$</span>' + msrpValue;
                                }

                            } else {
                                $ctrl.optionalFeatures[i].msrpFormatted = msrpValue + '<span class="priceSign">$</span>';
                            }
                        }
                    }
                }

            }, error => {
                console.error(error);
            })
        }

        $ctrl.toggleDealerDesc = () => {
            $ctrl.dealerDescOpen = !$ctrl.dealerDescOpen;
        }

        $ctrl.onSubspecToggle = (index) => {

            if (index === $ctrl.openSubspecIndex) {
                // If the expander is already open, close it.
                $ctrl.openSubspecIndex = -1;
            } else {
                $ctrl.openSubspecIndex = index;
            }
        }
    }
})(angular);
