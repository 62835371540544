(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaSup', FcaSup);

    function FcaSup() {
        'ngInject';

      return function(string) {
          if (!string) {
              return '';
          }
          return string.replace(/\^[0-9]\^/g, '<sup>$&</sup>').replace(/\^/g, '');
      };
    }
})();
