(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselColorizerWheelarizer', FcaSlickCarouselColorizerWheelarizer);

	function FcaSlickCarouselColorizerWheelarizer() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselColorizerWheelarizerController,
			link: FcaSlickCarouselColorizerWheelarizerLink
		};

		function FcaSlickCarouselColorizerWheelarizerController(gtmAnalytics, $window, $element) {
			'ngInject';

			const $ctrl = this;

			$ctrl.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let dataAttr = slick.$slides.eq(nextSlide).data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label
					});
				}
			};


			$ctrl.$postLink = () => {
				$ctrl.colorsLoaded();
				angular.element($window).on('resize', function () {
					$ctrl.colorsLoaded();
				});
			};

			$ctrl.colorsLoaded = () => {
				let mobileBreakpoint = 667;
				let windowWidth = $window.innerWidth;
				let colorListContainer = $element.find('.customizer-content-container')[0];
				let colorList = $(colorListContainer).find('.customizer-content-selector.is-colorizer')[0];

				if (windowWidth < mobileBreakpoint && colorList) {
					$(colorList).css('width', 'max-content');
					let colorsNumber = $(colorList).find('.customizer-content-selector-swatch-container').length;
					let eachBoxWidth = $(colorList).find('.customizer-content-selector-swatch-container')[0].clientWidth;
					let allBoxesWidth = colorsNumber * eachBoxWidth;
					let widthCalculated = 0;

					if (colorsNumber === 8) {
						let offset = 20;
						widthCalculated = allBoxesWidth / 2 + offset;
					} else {
						let offset = 40;
						widthCalculated = allBoxesWidth / 2 + offset;
					}

					$(colorList).css('width', widthCalculated + 'px');
				} else {
					if (colorList) {
						$(colorList).css('width', '');
					}
				}

				if (windowWidth < mobileBreakpoint) {
					if (colorListContainer && colorList) {
						if (colorListContainer.scrollWidth <= colorListContainer.clientWidth) {
							$('.arrow-color-list').css('display', 'none');
							$(colorList).css('width', '');
							$(colorList).css('justify-content', 'center');
						} else {
							$('.arrow-color-list').css('display', 'block');
							$(colorList).css('justify-content', '');
						}
					}
				}
			};

			$ctrl.arrowClicked = (direction) => {
				let mobileBreakpoint = 667;
				let windowWidth = $window.innerWidth;

				if (windowWidth < mobileBreakpoint) {
					let colorList = $element.find('.customizer-content-container');
					let scrollableWidth = colorList[0].scrollWidth - colorList[0].clientWidth;

					if (direction === 'left') {
						colorList.animate({scrollLeft: 0}, 500);
					} else {
						colorList.animate({scrollLeft: scrollableWidth}, 500);
					}
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselColorizerWheelarizerLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
