/* eslint-disable indent */
(function () {
    angular
        .module('fca.newInventory.overlay')
        .component('fcaNewInventoryOverlay', {
            controller: fcaSniOverlay,
            controllerAs: '$newInvOverlayCtrl',
            templateUrl: '/new-inventory/overlay/overlay.html',
            bindings: {
                showLeaseTab: '<',
                showCtaFinancing: '<',
                showCtaContactDealer: '<',
                showIsDigital: '<',
                showCtaReturnToDetails: '<',
                showSalesTax: '<',
                showSeeOptions: '<',
                showPaymentFrequency: '<',
                showPaymentSummary: '<',
                disclaimerBasePrice: '@',
                disclaimerFinancing: '@',
                disclaimerLeasing: '@',
                disclaimerDealerAdminFees: '@',
                location: '<',
                brandCode: '@',
                calculatorDefaultFinanceRate: '<',
                calculatorSliderStepSize: '<',
                frequencyOverride: '<',
                disclaimers:'<'
            }
        });

    function fcaSniOverlay($scope, $rootScope, configService) {
        'ngInject';

        let $newInvOverlayCtrl = this;

        $newInvOverlayCtrl.language = "";
        $newInvOverlayCtrl.isActive = false;

        $newInvOverlayCtrl.$onInit = () => {
            $newInvOverlayCtrl.language = configService.getLanguage();
            // When the calculator button is clicked,
            $scope.$on('new-inventory:initialize-calculator', (event, data) => {
                $newInvOverlayCtrl.overlayTitle = data.overlayTitle.toUpperCase();
                $newInvOverlayCtrl.basePrice = data.basePrice;
                $newInvOverlayCtrl.gkrp = data.gkrp;
                $newInvOverlayCtrl.dealerPrice = data.dealerPrice;
                $newInvOverlayCtrl.incentivizedPrice = data.incentivizedPrice;
                $newInvOverlayCtrl.freight = data.freight;
                $newInvOverlayCtrl.acTax = data.acTax;
                $newInvOverlayCtrl.greenLevy = data.greenLevy;
                $newInvOverlayCtrl.incentives = data.incentives;
                $newInvOverlayCtrl.frequencyOverride = data.frequencyOverride;
                $newInvOverlayCtrl.dealerAdminFee = data.dealerAdminFee;
                $newInvOverlayCtrl.financeDownPayment = data.financeDownPayment;
                $newInvOverlayCtrl.leaseDownPayment = data.leaseDownPayment;
                // disable apply for finance if dealer doesn't have credit enabled
                $newInvOverlayCtrl.hideApplyForFinance = (data.dealerIsCredit !== true);

                // Vehicle attributes
                $newInvOverlayCtrl.vin = data.vin;
                $newInvOverlayCtrl.options = data.options;
                $newInvOverlayCtrl.modelYearId = data.modelYearId;
                $newInvOverlayCtrl.year = data.year;
                $newInvOverlayCtrl.acode = data.acode;
                $newInvOverlayCtrl.nameplateCode = data.nameplateCode;
                $newInvOverlayCtrl.trimFr = data.trimFr;
                $newInvOverlayCtrl.trimEn = data.trimEn;
                $newInvOverlayCtrl.packageAndOptionsCode = data.packageAndOptionsCode;
                $newInvOverlayCtrl.affiliateLogo = data.affiliateLogo;
                $newInvOverlayCtrl.affiliateMode = data.affiliateMode;
                $newInvOverlayCtrl.dealerCode = data.dealerCode;
                $newInvOverlayCtrl.selectedBrandCode = data.brandCode;

                // cash program
                $newInvOverlayCtrl.cash = data.cash;

                // finance programs
                $newInvOverlayCtrl.finance = data.finance;
                $newInvOverlayCtrl.altFinance = data.altFinance;

                // lease programs
                $newInvOverlayCtrl.lease = data.lease;
                $newInvOverlayCtrl.altLease = data.altLease;

                // previously focused element AKA the element that called this overlay
                $newInvOverlayCtrl.previousFocusedElement = data.previousFocusedElement;

                $newInvOverlayCtrl.showOverlay();
            });

            $scope.$on('new-inventory:close-calculator', () => {
                $newInvOverlayCtrl.cancel();
            });
        };

        $newInvOverlayCtrl.cancel = () => {
            // set focus to the button that called this overlay
            if ($newInvOverlayCtrl.previousFocusedElement) {
                $newInvOverlayCtrl.previousFocusedElement.focus();
            }
            // Do cancel action
            $newInvOverlayCtrl.hideOverlay();
        };

        $newInvOverlayCtrl.showOverlay = () => {
            // Note: fixed elements will also need the margin adjustment (like a fixed header, if you have one).
            let scrollBarWidth = window.innerWidth - document.body.offsetWidth;
            $('html')
                .css('margin-right', scrollBarWidth)
                .addClass('C_NID_OL-showing-modal');

            $('body').addClass('noScroll');

            $newInvOverlayCtrl.isActive = true;
        };

        $newInvOverlayCtrl.hideOverlay = () => {
            $('html')
                .css('margin-right', '')
                .removeClass('C_NID_OL-showing-modal');

            $('body').removeClass('noScroll');
            $newInvOverlayCtrl.isActive = false;
        };
    }
})();
