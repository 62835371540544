(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaFeatureComparisonStats', fcaFeatureComparisonStats);

	function fcaFeatureComparisonStats() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaFeatureComparisonStats',
			controller: fcaFeatureComparisonStatsController
		};

		function fcaFeatureComparisonStatsController($element, $window) {
			'ngInject';

			const $ctrl = this;
			$ctrl.isAnimating = false;

			$ctrl.$postLink = () => {
				angular.element($window).bind('scroll', $ctrl.onScrollEvent);
				$ctrl.featureComparisonStatsElementsArray = $element.find('.feature-comparison-stats__item');
				$ctrl.onScrollEvent(); // In case the user lands directly on the block without scrolling
			};

			$ctrl.onScrollEvent = () => {
				const rect = $element[0].getBoundingClientRect();

				if (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= ($window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= ($window.innerWidth || document.documentElement.clientWidth) &&
					!$ctrl.isAnimating
				) {
					$ctrl.isAnimating = true;
				}
			};
		}
	}
})();
