(function() {
    angular
        .module('fca.personalizationDaa')
        .directive('fcaPersonalizationDaaInitializer', PersonalizationDaaInitializer)
        .config(TrackEvents);

    function PersonalizationDaaInitializer() {
        return {
            scope: true,
            controller: PersonalizationDaaInitializerController
        };

        function PersonalizationDaaInitializerController(personalizationDaaScenario) {
            'ngInject';

            this.$onInit = () => {
                personalizationDaaScenario.activate(true);
            }
        }
    }

    function TrackEvents($analyticsProvider, $provide, personalizationDaaScenarioProvider) {
        'ngInject';

        $analyticsProvider.registerEventTrack(function (action, properties) {
            // Qualifying events conditions for the following
            let trigger = properties.trigger;
            const qualifyingEvents = ['start-buying-process', 'view-vehicle'];

            if (personalizationDaaScenarioProvider.enable && qualifyingEvents.includes(trigger)) {
                personalizationDaaScenarioProvider.createWith(properties)
            }
        })
    }
})();
