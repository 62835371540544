(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFeature', fcaFeature);

	function fcaFeature() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'feature',
			controller: FcaFeatureController,
			require: {
				featureBlockCtrl: '?^fcaFeatureBlock'
			}
		};

		function FcaFeatureController($scope, $element) {
			'ngInject';
			let vm = this;
			vm.expanded = false;
			vm.expandedHybrid = false;
			vm.$onInit = function() {
				if (vm.featureBlockCtrl) {
					vm.featureBlockCtrl.addFeature(vm);
				}
			};
			vm.expand = function() {
				vm.expanded = true;
				if (vm.featureBlockCtrl) {
					vm.featureBlockCtrl.expandAll();
				}
			};

			// need to duplicate for hybrid feature because the html is not the same as other
			vm.expandHybrid = function() {
				//this was made for hybrid module so on click of the CTA it opens all the drawers
				let elementList = document.querySelectorAll('div.expandable-content-hybrid');
				for(let i = 0; i < elementList.length; i++) {
					const element = elementList[i];
					element.classList.add('expanded');
				}
				vm.expandedHybrid = true;
				if (vm.featureBlockCtrl) {
					vm.featureBlockCtrl.expandAll();
				}
			};

			vm.showLess = function() {
				vm.expanded = false;
				vm.featureBlockCtrl.closeAll();
			};
		};
	}
})();
