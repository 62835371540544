(function () {
    'use strict';

    angular
        .module('fca.commonServices')
        .service('irisResponsive', irisResponsive);

    function irisResponsive () {
        const $service = this;

        // A util method for responsively calculating the dimensions of an Iris
        // image. This method takes in an object containing three parameters:
        //   * ratio: The desired aspect of the ratio of the image, given as a
        //     decimal of width / height.
        //
        //   * maxWidth: The *widest* this image will ever be. The easiest way to
        //     to find this value is to look at your page on desktop and use the
        //     image width as the maxWidth parameter here.
        //
        //   * qualityRatio (optional): An additional factor to be multiplied
        //     against the image dimensions. Useful if the images you're using
        //     are dynamically zoomed or are of abnormally low quality.
        $service.calcDimensions = ({ ratio, maxWidth, qualityRatio = 1 }) => {
            // We don't want the image width to exceed either `maxWidth` or the
            // viewport width.
            const naiveWidth = Math.min(maxWidth, window.innerWidth);

            // Certain devices have multiple "dots" to a (CSS) pixel. ex: Retina
            // displays, certain Android tablet displays. For these devices we
            // need to load a higher resolution image.
            const deviceWidth = naiveWidth * window.devicePixelRatio;

            const width = deviceWidth * qualityRatio;
            const height = width / ratio;

            return { width, height };
        }
    }
})();