(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaNumber', FcaNumber);

    /**
     * Formats a number localized for the current language.
     * Thousands separator is " " in french and "," in english.
     *
     * @param $filter
     * @returns {function(*=): string}
     * @constructor
     */
    function FcaNumber(
        $filter) {
        'ngInject';

        // Filter currency
        // Current language
        let language = window.FCA_SITES_CONFIG.preferredLanguage.replace('_CA', '');
        let numberFilter = $filter('number');

        return function(input) {
            // default to zero
            let result = numberFilter(input ? input : 0, 0);
            //  the number format already formats correctly for english
            if (language === 'fr') {
                result = result.replace(/[,]/ig, ' ').replace(',', ' ');
            }
            return result;
        };
    }
})();
