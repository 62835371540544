(function(angular) {
    'use strict';

    angular
        .module('fca.dealerLocator')
        .service('dealerLocator', DealerLocatorService);

    /**
     * @ngdoc DealerLocatorService
     * @name fca.dealerLocator.service:fca.dealerLocator
     * @description Find dealers HTTP service for backend API
     * @requires $http
     * @requires $filter
     */
    function DealerLocatorService($http, $filter) {
        const distanceFilter = $filter('distance');
        const dealerUrlFilter = $filter('dealerUrl');

        /**
         * @ngdoc method
         * @name findDealers
         * @methodOf fca.dealerLocator.service:fca.dealerLocator
         * @description Retrieve all dealers near a location within a specific radius
         * @param    {String} uri Backend API base URL
         * @param    {Object} location Latitude/Longitude object
         * @param    {Number} radius Radius around the location point to search
         * @param    {boolean} credit Find only dealers with online credit application
		 * @param	 {String} nameplate code like wagoneers
		 * @param 	 {String} year like 2024
		 * @param    {String} model year identifier like 26319
         * @return  {Object} AngularJS promise object with array of dealers (with distance and detail URL)
         */
        this.findDealers = function(uri, location, brand, digital = false, radius = 50, credit = false, nameplateCode = '', year = '', modelYearId = '') {
            const { latitude, longitude, postalCode } = location;
            const paramsFromUrl = new URLSearchParams(window.location.search);

            const params = {
                brand,
                longitude,
                latitude,
                radius,
                isDigital: digital || (paramsFromUrl.has('buildId') && paramsFromUrl.has('pid')),
                withoutBranchLocation: paramsFromUrl.has('buildId') && paramsFromUrl.has('pid'),
                isCreditAppDlr: credit,
                nameplateCode,
                year,
                modelYearId
            };

            const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));

            const url = `${uri}?${new URLSearchParams(filteredParams).toString()}`;

            return $http.get(url).then(response => {
                const data = response.data;
                data.dealers = data.dealers.map(dealer => (Object.assign(dealer, {
                    distance: distanceFilter({ longitude, latitude }, dealer),
                    externalUrl: dealer.url,
                    url: dealerUrlFilter(dealer)
                })));

                // If the postal code was entered manually, display it
                if (postalCode && postalCode !== data.userZipCode) {
                    data.manualPostalCode = postalCode;
                }

                return data;
            }).catch(error => {
                console.error('Error retrieving dealers:', error);
            });
        };
    }
})(angular);
