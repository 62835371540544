(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaGalleryFilter', fcaGalleryFilter);

	function fcaGalleryFilter() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$galleryFilter',
			controller: fcaGalleryFilterController
		};

		function fcaGalleryFilterController() {
			'ngInject';

			const $ctrl = this;

			$ctrl.filter = "all";
	
			$ctrl.filterBy = (type) => {	
				$ctrl.filter = type;
			};
		}
	}
})();
