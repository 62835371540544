(function() {
	angular
		.module("fca.miniBuildAndPrice.exterior.interior")
		.component("exteriorInteriorMiniBp", {
			controller: exteriorInteriorMiniBp,
			controllerAs: "$ctrl",
			templateUrl:
				'/shared/fca-mini-build-and-price/exterior-interior/exterior-interior-mini-bp.html',
			bindings: {
				jellyData: "<",
				jellyParameters: "@",
				optionBaseUrl: "@",
				sections: "<",
				onSelect: "<",
				onModelSelect: '<',
				placeholderImage: "@",
				alternateText: "@",
				interiorJellyTitle: "@",
				acode: "@",
				brand: '@',
				desktopBackgroundUrl: '@',
				iconModelsUrl: '@',
				iconPowerDesktopUrl: '@',
				iconPowerMobileUrl: '@',
				iconSpeedDesktopUrl: '@',
				iconSpeedMobileUrl: '@',
				iconTorqueDesktopUrl: '@',
				iconTorqueMobileUrl: '@',
				stats: '<',
				vehicleName: '@',
				vehicleStartingPrice: '@',
				modelsObject: '<',
				currentDrivetrain: '<',
				drivetrainsObject: '<',
				vehicleBuildAndPriceUrl: '@',
				vehicleSearchNewInventoryUrl: '@',
				statsFound: '<',
				statsTorque: '@',
				statsPower: '@',
				statsAcceleration: '@',
				withBackground: '<',
				hideBpCta: '<',
				hideSniCta: '<',
				withModelName: '<',
				hidePricing: '<',
				statisticStyle: '@',
				selectedNameplateName: '@',
				minibpAnalyticsIdBase: '@',
				disclaimers: '@'
			}
		});

	function exteriorInteriorMiniBp(
		$scope,
		$element,
		$timeout,
		brandAnimation,
		brandPreloadStrategy,
		brandJellyBuilder,
		brandJellyLoader,
		povHelper,
		focusFunctions
	) {
		"ngInject";

		const $ctrl = this;
		const desktopMediaQuery = "(min-width: 1248px)";
		$ctrl.miniBpHash = "#mini-bp-config=true";

		// We don't want to be in the business of fighting the user for control
		// of the jelly angle. To that end, we track if the user has manually
		// rotated the vehicle. Once/if the user has changed the angle, we no
		// longer run the scrolling animation.
		$ctrl.userHasChangedPov = false;
		// For the rotation animation to be smooth, the images must be already
		// loaded in to memory. Simply relying on the browser cache isn't
		// sufficient, as the time to load the images from disk causes jerkiness
		// when rapidly changing the angle of the car. We instead force the
		// images to be in memory by keeping references to them in the image
		// cache. See alfaromeo-jelly-loader for associated code.
		$ctrl.exteriorImageCache = {};
		// The preload key is a sample jelly url that allows us to compare sets
		// of jelly data. There are many options inside the jelly data that can
		// change without affecting the actual iris image; to make sure that we
		// aren't unnecessarily blowing the image cache, we check the preload
		// keys when the jelly data changes to see if we need to load new images.
		//
		// The other usage for the preload key is differentiating load events.
		// Imagine that the user selects a yellow exterior color and then
		// immediately after the red color. To avoid accidentally polluting the
		// image cache with yellow jellies, we check that the preload key of the
		// load event matches the current preload key.
		$ctrl.preloadKey = null;

		$ctrl.defaultAnimation = "mainAngle";

		const preloadExteriorPov = povHelper.povMin;

		let currentAnimationZone;
		let categoryAnimationZones;
		const jellyAnimationHeight = 60;
		const jellyAnimationOffset = 0;

		const categoryAnimationHeight = 90;
		const categoryAnimationOffset = 0;

		$ctrl.jellyAnimationStates = {
			front: {
				elemId: null,
				properties: {
					pov: 4,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			},
			side: {
				elemId: 'exterior-interior-side',
				properties: {
					pov: 28,
					scale: 1.4,
					translateX: -6,
					translateY: -4,
				}
			},
			rear: {
				elemId: 'exterior-interior-rear',
				properties: {
					pov: 23,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			}
		};

		const jellyAnimationArray = Object.values($ctrl.jellyAnimationStates);
		$ctrl.jellyAnimationZones = brandAnimation.createJellyZones(
			jellyAnimationArray, jellyAnimationHeight, jellyAnimationOffset
		);

		$ctrl.mobileJellyAnimationStates = {
			front: {
				elemId: null,
				properties: {
					pov: 4,
					scale: 1,
					translateX: -2,
					translateY: 0,
				}
			},
			side: {
				elemId: 'exterior-side',
				properties: {
					pov: 28,
					scale: 1,
					translateX: -12,
					translateY: 2,
				}
			},
			rear: {
				elemId: 'exterior-rear',
				properties: {
					pov: 23,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			}
		};

		$ctrl.$onInit = () => {
			$ctrl.originalStartingPrice = {};
			$ctrl.previousOpenedDropdown = null;
			$ctrl.currentSelectedCategory = null;
			$ctrl.currentExteriorInteriorType = 'exterior';

			const lastCategoryIds = Array.from($ctrl.sections.exterior.keys())
				.map(sectionCode => 'last-exterior-' + sectionCode);

			categoryAnimationZones = brandAnimation.createCategoryZones(
				lastCategoryIds, categoryAnimationHeight, categoryAnimationOffset
			);
		}

		$ctrl.$onChanges = changes => {
			if (!changes.jellyData || !changes.jellyData.currentValue) {
				return;
			}

			const jellyData = changes.jellyData.currentValue;

			// Check if the vehicle's exterior options have been changed (wheels,
			// color, etc.)
			if (!optionsChanged(jellyData)) return;

			$ctrl.refreshSections();

			// If we need to download new jellies, we need to
			//   1. Set a new preload key, to differentiate the jellies we are
			//      about to load from previous jellies that may still be
			//      loading.
			$ctrl.preloadKey = getPreloadKey(jellyData);
			//   2. Reset our image cache, as we are about to download a new set
			//      of jellies.
			$ctrl.exteriorImageCache = {};

			// If the user has manually changed the angle of the car, the
			// rotation animations will not be run and thus there is no need to
			// preload the animation images. In this case, the individual jelly
			// components will notice that the image cache is empty and request
			// that we load their povs.
			if (!$ctrl.userHasChangedPov) {
				fullPreload(jellyData);
			}
		}

		$ctrl.refreshSections = () => {
			$ctrl.exteriorSections = $ctrl.getSpecificSectionObject('exterior');
			$ctrl.interiorSections = $ctrl.getSpecificSectionObject('interior');

			$timeout(() => {
				$ctrl.openDefaultCategoryDropdown();
				$timeout(() => {
					$ctrl.manageTabIndex();
					$ctrl.onPriceUpdate();
				});
			});
		};

		const getPreloadKey = jellyData => brandJellyBuilder.jellyUrl(
			jellyData, preloadExteriorPov
		);

		const optionsChanged = newJellyData => {
			return !$ctrl.jellyData || getPreloadKey(newJellyData) !== $ctrl.preloadKey;
		};

		const preloadExteriorViewpoints = (jellyData, viewpoints, currentPov) => {
			$ctrl.exteriorImageCache = {};
			const urlBuilder = pov => $ctrl.buildJellyUrl(jellyData, pov);

			brandJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				currentPov,
				viewpoints,
				$ctrl.onExteriorPovLoaded
			);
		}

		$ctrl.loadExteriorPov = pov => {
			const urlBuilder = pov => $ctrl.buildJellyUrl($ctrl.jellyData, pov);
			brandJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				pov,
				[],
				$ctrl.onExteriorPovLoaded
			);
		}

		const preloadStrategie = () => {
			if($ctrl.defaultAnimation) {
				return brandPreloadStrategy.preloadStrategies[$ctrl.defaultAnimation]
			}

			if ($ctrl.isDesktop()) {
				return brandPreloadStrategy.preloadStrategies.animation
			}

			return brandPreloadStrategy.preloadStrategies.ofInterest
		}

		const fullPreload = (jellyData, currentPov) => {

			const viewpoints = brandPreloadStrategy.generateViewpoints(preloadStrategie());

			preloadExteriorViewpoints(jellyData, viewpoints, currentPov);
		}

		$ctrl.getInfoBackground = () => {
			if (!$ctrl.withBackground) return "";
			let backgroundStyle = {};
			if ($ctrl.isDesktop()) {
				backgroundStyle = {backgroundImage: 'url(\'' + $ctrl.desktopBackgroundUrl + '\')'};
			}
			return backgroundStyle;
		};

		$ctrl.getPreviewBackground = () => {
			if (!$ctrl.withBackground) return "";
			let backgroundStyle = {};
			if (!$ctrl.isDesktop()) {
				backgroundStyle = {backgroundImage: 'url(\'' + $ctrl.desktopBackgroundUrl + '\')'};
			}
			return backgroundStyle;
		};

		$ctrl.openDefaultCategoryDropdown = () => {
			let allDropdowns = $element.find('.mini-bp_director-accordion');
			let defaultDropdown = allDropdowns.first();

			if (!$ctrl.currentSelectedCategory) {
				$ctrl.currentSelectedCategory = defaultDropdown.attr('data-category');
				$ctrl.currentExteriorInteriorType = defaultDropdown.attr('data-exterior-interior-type');
			}
		};

		$ctrl.manageTabIndex = () => {
			let openedDropdowns = $element.find('.mini-bp_director-accordion.is-opened');
			let closedDropdowns = $element.find('.mini-bp_director-accordion:not(".is-opened")');

			let contents = openedDropdowns.find('.mini-bp_director-accordion-content');
			contents.each((index, content) => {
				focusFunctions.enableDisableFocusOnElements(content, true);
			});

			contents = closedDropdowns.find('.mini-bp_director-accordion-content');
			contents.each((index, content) => {
				focusFunctions.enableDisableFocusOnElements(content, false);
			});
		};

		$ctrl.onToggleDropdown = (dropdown, category, exteriorInteriorType) => {
			if ($ctrl.previousOpenedDropdown && $ctrl.previousOpenedDropdown != dropdown && $ctrl.previousOpenedDropdown.menuOpened) {
				$ctrl.previousOpenedDropdown.toggle();
			}

			$ctrl.previousOpenedDropdown = dropdown;
			dropdown.toggle();
			$ctrl.currentSelectedCategory = category.description;
			$ctrl.currentExteriorInteriorType = exteriorInteriorType;

			$timeout(() => {
				$ctrl.manageTabIndex();
			});
		};

		$ctrl.categoryAnimationListener = () => {
			if ($ctrl.isDesktop()) return;

			const animationZone = brandAnimation.findActiveAnimationZone(categoryAnimationZones);

			if (!animationZone) {
				if (currentAnimationZone) {
					$ctrl.animateCategory();
					currentAnimationZone = null;
				}

				return;
			}

			if (!currentAnimationZone) {
				currentAnimationZone = animationZone
				currentAnimationZone.startAnimation();
			}

			$ctrl.animateCategory();
		}

		$ctrl.animateCategory = () => {
			const categoryElem = currentAnimationZone.elem();
			const opacity = currentAnimationZone.interpolateProperty('opacity');

			requestAnimationFrame(() => categoryElem.style.opacity = opacity)
		}

		$ctrl.getSpecificSectionObject = (section) => {
			if (section === 'exterior') {
				return $ctrl.getSectionObjectFromList($ctrl.sections.exterior);
			} else {
				return $ctrl.sections.interior;
			}
		};

		$ctrl.getSectionObjectFromList = (sections) => {
			const sectionObj = {};

			sections.forEach((section, sectionCode) => {
				if (!section.categories || !section.categories.length) {
					return;
				}

				sectionObj[sectionCode] = section;

				const { last, allButLast } =
					$ctrl.splitCategories(section.categories);

				section.allCategoriesButLast = allButLast;
				section.lastCategory = last;
			});

			return sectionObj;
		}

		$ctrl.splitCategories = categories => {
			const categoriesCopy = categories.slice(0);

			return {
				last: categoriesCopy.pop(),
				allButLast: categoriesCopy
			};
		}

		let desktopMode = null;
		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.isAlfaRomeo = () => $ctrl.brand == 'alfaromeo'

		$ctrl.onSelectOption = option => {
			$ctrl.onSelect(option);
		};

		$ctrl.getSelectedOptionPrice = section => {
			let price = 0;

			if (section && section.categories && section.categories.length > 0) {
				for (let i = 0; i < section.categories.length; i++) {
					let category = section.categories[i];
					if (category && category.options) {
						let selectedOption = category.options.find(option => option.selected);
						if (selectedOption && !isNaN(selectedOption.msrp)) {
							price += Number(selectedOption.msrp);
						}
					}
				}
			}

			return price;
		};

		$ctrl.onPriceUpdate = () => {
			if(!$ctrl.originalStartingPrice.hasOwnProperty($ctrl.acode)) {
				$ctrl.originalStartingPrice[$ctrl.acode] = Number($ctrl.vehicleStartingPrice);
			}

			let price = $ctrl.originalStartingPrice[$ctrl.acode];

			if($ctrl.exteriorSections) {
				price += $ctrl.getSelectedOptionPrice($ctrl.exteriorSections.front);
				price += $ctrl.getSelectedOptionPrice($ctrl.exteriorSections.side);
				price += $ctrl.getSelectedOptionPrice($ctrl.exteriorSections.rear);
			}

			if($ctrl.interiorSections) {
				price += $ctrl.getSelectedOptionPrice($ctrl.interiorSections.colors);
			}

			$ctrl.vehicleStartingPrice = price.toString();
		}

		$ctrl.onExteriorPovLoaded = loadedImage => {
			if (loadedImage.preloadKey !== $ctrl.preloadKey) {
				return;
			}

			$scope.$apply(() => {
				const newImageCache = Object.assign(
					{ [loadedImage.pov]: loadedImage }, $ctrl.exteriorImageCache
				);

				$ctrl.exteriorImageCache = newImageCache;
			});
		}

		$ctrl.buildJellyUrl = (jellyData, pov) => {
			if (!jellyData) {
				return;
			}

			const jellyOptions = Object.assign(
				{ 'pov': povHelper.format(povHelper.wrap(pov)) },
				jellyData
			);
			return brandJellyBuilder.jellyUrl(jellyOptions);
		}

		$ctrl.userPovSelect = () => {
			$ctrl.userHasChangedPov = true;
		}

		$ctrl.getContinueBuildYoursUrl = () => {
			return $ctrl.vehicleBuildAndPriceUrl + $ctrl.miniBpHash;
		};

		$ctrl.getSearchNewInventoryUrl = () => {
			return $ctrl.vehicleSearchNewInventoryUrl + '&color=' + $ctrl.getSelectedExteriorPrimaryColour();
		};

		$ctrl.getSelectedExteriorPrimaryColour = () => {
			let colour = '';

			if ($ctrl.exteriorSections && $ctrl.exteriorSections.front && $ctrl.exteriorSections.front.categories && $ctrl.exteriorSections.front.categories.length > 0) {
				let primaryColours = $ctrl.exteriorSections.front.categories[0];
				if (primaryColours && primaryColours.options) {
					let selectedOption = primaryColours.options.find(option => option.selected);
					if (selectedOption) {
						colour = selectedOption.color.replace('#', '%23').toUpperCase();
					}
				}
			}

			return colour;
		};
	}
})();
