(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaDealerDropdownPostalCodeUpdate', fcaDealerDropdownPostalCodeUpdate);

	function fcaDealerDropdownPostalCodeUpdate() {
		return {
			restrict: 'A',
			controller: fcaDealerDropdownPostalCodeUpdateController,
			controllerAs: 'dealerDropdownUpdate',
			scope: true
		};

		function fcaDealerDropdownPostalCodeUpdateController($scope, $rootScope, $http, cookieLocation, fcaGeolocator,
													$cookies, $analytics, $filter, $timeout) {
			'ngInject';

			this.data = null;
			this.isValid = false;
			this.displayError = false;

			const regex = /^[a-zA-Z]\d[a-zA-Z][ ]?\d[a-zA-Z]\d$/;

			this.validatePostalCode = (e) => {
				let postalCode = e.currentTarget.value;
				let isValid = regex.exec(postalCode);

				if (!isValid && postalCode.length > 5) {
					this.displayError = true;
				} else if (isValid) {
					let formattedPostalCode = postalCode.replace(' ', '').toUpperCase();
					formattedPostalCode = formattedPostalCode.slice(0, 3) + ' ' + formattedPostalCode.slice(3, 6);
					this.displayError = false;
					$rootScope.$broadcast('dealer-dropdown-options:update', formattedPostalCode);
				}
			};
		}
	}
})();
