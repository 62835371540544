(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaTools', fcaTools);

	function fcaTools() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'fcaTools',
			controller: fcaToolsController
		};

		function fcaToolsController($element, $rootScope) {
			'ngInject';

			let waypoint;

			this.isAnimated = false;
			this.dynamicHeight = 0;

			this.$onInit = () => {
				const elemHeight = $element[0].offsetHeight;
				const imageHeight = $element[0].querySelector('.AR-tools-image-container').offsetHeight;

				const height = (imageHeight - elemHeight) * -1;

				this.dynamicHeight = `${$element[0].querySelector('.AR-tools-cta-section').offsetHeight - 200}px`;
				waypoint = new Waypoint({
					element: $element[0],
					handler: (direction) => {
						waypoint.destroy();
						waypoint = null;
						this.isAnimated = true;
					},
					offset: height
				});
			}

		}
	}
})();
