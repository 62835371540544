(function() {
    angular
        .module('fca.brandApp')
        .directive('fcaAnimatedStats', fcaAnimatedStats);

    function fcaAnimatedStats() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: 'fcaAnimatedStatsController',
            controller: fcaAnimatedStatsController
        };

        function fcaAnimatedStatsController($element, $window) {
            'ngInject';
            /* eslint-disable no-invalid-this */
            const $ctrl = this;
            const animationDuration = 2000;
            const lang = $('html').attr('lang');
            const thousandsSeparator = lang === 'en' ? ',' : '&nbsp;';
            const decimalSeparator = lang === 'en' ? '.' : ',';
            let statElementsArray = [];
            let isAnimating = false;

            $ctrl.$postLink = () => {
                angular.element($window).bind('scroll', $ctrl.onScrollEvent);
                statElementsArray = $element.find('.animated-stats__stat-number');
                $ctrl.onScrollEvent(); // In case the user lands directly on the block without scrolling

                statElementsArray.each((i, element) => {
                    $(element).text('0');
                });
            };

            $ctrl.onScrollEvent = () => {
                const rect = $element[0].getBoundingClientRect();

                if (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= ($window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                    rect.right <= ($window.innerWidth || document.documentElement.clientWidth) && /* or $(window).width() */
                    !isAnimating
                ) {
                    statElementsArray.each(i => {
                        this.startNumberIncrementation(i);
                    });
                }
            };

            $ctrl.startNumberIncrementation = index => {
                isAnimating = true;
                const currentElement = statElementsArray[index];
                const currentElementNumber = currentElement.dataset.number.replace(/[a-zA-Z "']/g, '');
                const currentElementNumberText = currentElement.dataset.number.replace(/[0-9.,]/g, '');
                const isFloat = currentElementNumber.includes('.');
                const isThousandOrMore = currentElementNumber >= 1000;

                $(currentElement).prop('Counter', 0).animate({
                    Counter: currentElementNumber
                }, {
                    duration: animationDuration,
                    easing: 'swing',
                    step: now => {
                        if (isFloat) {
							let currentFloatnumber = now.toFixed(1) + currentElementNumberText;
                            $(currentElement).text(currentFloatnumber.replace('.', decimalSeparator));
                        } else {
							if (isThousandOrMore) {
								let currentNumber = Math.ceil(now) + currentElementNumberText;
								$(currentElement)
									.html(
										currentNumber
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
									);
							} else {
								$(currentElement).text(Math.ceil(now) + currentElementNumberText);
							}
                        }
                    }
                });
            };
        }
    }
})();
