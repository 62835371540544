(function() {
    angular
    .module('fca.components.sniFinance')
    .component('componentsSniFinance',{
        controller: ComponentsSniFinanceController,
        controllerAs: '$ctrl',
        templateUrl: '/components/sni-finance/sni-finance.html',
        bindings: {
            // paymentMode
            hashData: '=hashData',
            index: '@',
            isNeutral: '<',
            // dealer, regular, finance, lease
            disclaimers: '=', 
            // dealerPrice, regularPrice,
            // financingValue, financeFrequency, financingRate, financingTerm
            // leasingValue, leasingFrequency, leasingRate, leasingTerm
            financeData: '<',
            cashAnalyticsId: '@',
            financeAnalyticsId: '@'
        }
    });

    function ComponentsSniFinanceController() {
        'ngInject';
        const $ctrl = this;

        $ctrl.openTab = () => {
            if ($ctrl.hashData.paymentMode !== 'leasing' ||
                $ctrl.financeData.leasingValue) {
                return $ctrl.hashData.paymentMode;
            }

            return $ctrl.hashData.previousTab;
        };

        $ctrl.changeTab = newTab => {
            $ctrl.hashData.previousTab = $ctrl.hashData.paymentMode;
            $ctrl.hashData.paymentMode = newTab;
        }
    }
})();