(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaSelectDealer', {
			templateUrl: '/components/contact-dealer/select-dealer/select-dealer.html',
			bindings: {
				selectedRegion: '<',
				selectedVehicle: '<?',
				selectedDealer: '<',
				selectedDealerCodes: '<',
				showDealerExtendedInfo: '<',
				allowChangeDealer: '<',
				brandCode: '<',
				analyticsCategory: '@',
				analyticsId: '@'
			},
			controller: daaSelectDealerController
		});

	function daaSelectDealerController(
		$rootScope,
		$filter,
		ContactDealer,
		externalConfigLoader,
        translateConfigurator,
		dealerChatService) {
		'ngInject';

		let configService = externalConfigLoader.loadConfig('DAA', 'CONFIG');
		// Find a dealer page url
		let findDealerUrl = configService.getConfig('urls').findDealer;

		this.findDealerUrl = null;

		this.$onChanges = (changes) => {
			if (changes.selectedDealer && changes.selectedDealer.currentValue) {
				// patch the dealer object returned by api...
				// sometimes the dealer object contains the distance, sometimes it contains the dealer hours
				// both are needed and require extra api calls to build the proper object
				// this may or may not arrive as an unresolved promise
				let patchDealer = this.addDealerHours(changes.selectedDealer.currentValue);

				if (patchDealer.then) {
					patchDealer.then((dealerObj) => {
						this.selectedDealer = this.addDealerDistance(dealerObj);
						// Adding chat url for updated dealer
						this.selectedDealer = this.addChatUrl(dealerObj);
						this.setFindDealerUrl();
					});
				} else {
					this.selectedDealer = this.addDealerDistance(patchDealer);
					// Adding chat url for updated dealer
					this.selectedDealer = this.addChatUrl(patchDealer);
					this.setFindDealerUrl();
				}
			}
		};

		this.isChangingDealer = false;

		this.showAllResults = true;

		this.dealersFullList = null;

		this.changeDealer = (context, event) => {
			if (event) {
				event.preventDefault();
			}

			this.isChangingDealer = true;
		};

		this.cancelChangeDealer = () => {
			return (event) => {
				if (event) {
					event.preventDefault();
				}

				this.isChangingDealer = false;
			};
		};

		this.selectDealer = () => {
			return (dealer, event) => {
				if (event) {
					event.preventDefault();
				}

				this.addDealerHours(dealer).then((dealerObj) => {
					let patchDealer = this.addDealerDistance(dealerObj);
					this.selectedDealer = patchDealer;
					this.setFindDealerUrl();
				});

				$rootScope.$emit('contact-dealer.select-dealer', dealer);
			};
		};

		this.addDealerDistance = (dealer) => {
			if (!dealer.distance) {
				// calculate distance here and add to dealer
				let distance = $filter('distance');

				dealer.distance = distance({
					latitude: this.selectedRegion.latitude,
					longitude: this.selectedRegion.longitude
				}, dealer);
			}

			return dealer;
		};

		this.addChatUrl = (dealer) => {
			let dealerWithChatUrl = dealer;

			let originationUrl = encodeURIComponent(location.href);
			dealerWithChatUrl.chatUrl = dealerChatService.getDealerChatUrl(dealerWithChatUrl.chatProvider, dealerWithChatUrl.chatProviderIdEn, dealerWithChatUrl.code, originationUrl);
			return dealerWithChatUrl;
		};

		this.addDealerHours = (dealer) => {

			let brand = externalConfigLoader.loadConfig('DAA', 'BRAND').getConfig('name');
			// Override brand to load nameplates
			if (this.brandCode !== undefined) {
				brand = this.brandCode;
			}
			// the backend api call that retrieves the list of closest dealers does not include the full dealer's hours
			// instead have to retrieve the dealer object separately and grab its hours
			if (!dealer.allHours) {
				return ContactDealer.getDealerObject(brand, dealer.code).then((fullDealer) => {
					// add the hours from the returned object
					// do not replace the entire object as it does not containe the distance calculation
					dealer.allHours = fullDealer.allHours;
					return addHours();
				});
			} else if (!dealer.openingHours && !dealer.openAt && !dealer.closeAt) {
				addHours();
			}

			return dealer;

			function addHours() {
				let salesItem = dealer.allHours.filter((item) => {
					return item.id === 'sales';
				});

				let salesHours = salesItem[0].days;

				let today = salesHours.filter((day) => {
					return day.isToday;
				})[0];

				let now = new Date();

				dealer.openingHours = salesHours;
				dealer.isOpenNow = new Date(today.openAt) < now && now < new Date(today.closeAt);

				return dealer;
			}
		};

		this.setFindDealerUrl = (dealer = null) => {
			if (dealer === null) {
				dealer = this.selectedDealer;
			}

			let {
				code,
			} = dealer;

			this.findDealerUrl = `${findDealerUrl}/${code}`;
		};
	}
})();
