(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021BuildAndPrice', vlp2021BuildAndPrice);

	function vlp2021BuildAndPrice() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$vlp2021BP',
			controller: vlp2021BuildAndPriceController
		};

		function vlp2021BuildAndPriceController(trimService, $scope, $timeout) {
			'ngInject';

			const $ctrl = this;

			$ctrl.showOnlyFavorites = true;

			$ctrl.$onInit = () => {
				trimService.forceCacheReset();
			};

			$ctrl.setShowOnlyFavorites = (value) => {
				trimService.forceCacheReset();
				$ctrl.showOnlyFavorites = value;

				$timeout(() => {
					$scope.$emit('tool-bar:refresh-focus');
				});
			};
		}
	}
})();
