/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.configurationsViewer')
        .component('buildAndPriceConfigurationsViewer', {
            controller: BuildAndPriceConfigurationsViewer,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/configurations-viewer/configurations-viewer.html',
            bindings: {
                hasJelly: '<',
                jellyPov: '@',
                jellyParameters: '@',
                modelYearId: '@',
                nameplateCode: '@',
                year: '@',
                brandCode: '@',
                provinceCode: '@',
                loadingImage: '@',
                nameplateName: '@',
                irisExternalJellyDisclaimer: '@?'
            }
        });

    function BuildAndPriceConfigurationsViewer($scope, $rootScope, $element, $translate, $filter, $window, configService) {
        'ngInject';

        let $ctrl = this;

        $ctrl.language = $('html').attr('lang');

        const exteriorPov = 0;
        const interiorPov = 1;

        $ctrl.pov = exteriorPov;

        $ctrl.hasInteriorImage = true;

        $ctrl.selectedTrim = {};

        $ctrl.isLegacy = true;

        $ctrl.exteriorColourAriaLabel = {
            'en': 'Exterior Colour:',
            'fr': 'Couleur extérieure :'
        };

        $ctrl.interiorColourAriaLabel = {
            'en': 'Interior Colour:',
            'fr': 'Couleur intérieure :'
        };

        $ctrl.selectedWheelsAriaLabel = {
            'en': 'Wheels:',
            'fr': 'Roues :'
        };

        $ctrl.$onInit = () => {
            $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                $ctrl.selectedTrim = data;
                // Selects the initial package so the data is populated if the user keeps the default selection.
                $ctrl.selectedPackage = data.selectedTrim.packages[0];
                $ctrl.isLegacy = data.selectedTrimGroup && data.selectedTrimGroup.jellyRenderingType !== 'iris';
                if (data.selectedTrimGroup.trimIrisJellyOptions) {
                    $ctrl.hasInteriorImage = data.selectedTrimGroup.trimIrisJellyOptions.hasInteriorImage || false;
                    if (data.selectedTrimGroup.trimIrisJellyOptions.irisJellyParameters) {
                        $ctrl.jellyParameters = data.selectedTrimGroup.trimIrisJellyOptions.irisJellyParameters;
                    } else {
                        $ctrl.jellyParameters = '';
                    }
                    if (data.selectedTrimGroup.trimIrisJellyOptions.hasWheelarizer !== undefined) {
                        $ctrl.jellyHasWheels = data.selectedTrimGroup.trimIrisJellyOptions.hasWheelarizer;
                    } else {
                        $ctrl.jellyHasWheels = true;
                    }
					if (data.selectedTrimGroup.trimIrisJellyOptions.irisAdditionalOptions !== undefined) {
						$ctrl.jellyAdditionalOptions = data.selectedTrimGroup.trimIrisJellyOptions.irisAdditionalOptions.join(',');
					}
                }
                $ctrl.formatPackageInformation();
            });

            $scope.$on('packages-selector:selected-package-updated', (_, data) => {
                $ctrl.selectedPackage = data.package;
                $ctrl.formatPackageInformation();
            });

            $scope.$on('alert-window:select-options-proceed', () => {
                $ctrl.categoriesObjects = configService.getCategoriesObjects();

				if (!$ctrl.exteriorColour && $ctrl.categoriesObjects['exteriorColor'][1]) {
					$ctrl.exteriorColour = $ctrl.categoriesObjects['exteriorColor'][1];
				}

                $ctrl.formatSelectedOptions();
            });

			/*eslint no-unused-vars: ["error", {"args": "after-used"}]*/

			$scope.$on('packages-selector:finished-sending-options', (event, data) => {
				$ctrl.categoriesObjects = data;

				if (!$ctrl.exteriorColour && $ctrl.categoriesObjects['exteriorColor'][1]) {
					$ctrl.exteriorColour = $ctrl.categoriesObjects['exteriorColor'][1];
				}

				$ctrl.formatSelectedOptions();
			});

			/*eslint no-unused-vars: ["error", {"args": "after-used"}]*/

			$scope.$on('packages-selector:exterior-color-update', (event, data) => {
				$ctrl.exteriorColour = data;
				$ctrl.formatSelectedOptions();
			});

            // Used to get the jelly options
            $scope.$on('packages-selector:sending-jelly-trim-data', (_, data) => {
                $ctrl.updateJellyOptions(data);
            });
        };

        $ctrl.updateJellyOptions = (data) => {
            let trimGroup = data.selectedTrimGroup;
            if (!trimGroup || !trimGroup.trimIrisJellyOptions) {
                return;
            }

            let irisOptions = trimGroup.trimIrisJellyOptions;
            $ctrl.hasInteriorImage = irisOptions.hasInteriorImage;
        }

        $ctrl.formatPackageInformation = () => {
            if (!$ctrl.selectedPackage && $ctrl.selectedPackage !== {}) {
                $ctrl.packageConfiguration = [];
                return;
            }

            $ctrl.packageConfiguration = [];

            if ($ctrl.selectedPackage.engine) {
                $ctrl.packageConfiguration.push({
                    key: 'engine',
                    description: $ctrl.selectedPackage.engine,
                    icon: 'engine',
                    iconLabel: {
                        'fr':'Informations sur le moteur',
                        'en': 'Engine informations'
                    }
                });
            }

            if ($ctrl.selectedPackage.transmission) {
                let transmissionIcon;

                if ($ctrl.language === 'fr') {
                  transmissionIcon = $ctrl.getTransmissionType($ctrl.selectedPackage.transmission, 'manuelle');
                } else {
                  transmissionIcon = $ctrl.getTransmissionType($ctrl.selectedPackage.transmission, 'manual');
                }

                $ctrl.packageConfiguration.push({
                    key: 'transmission',
                    description: $ctrl.formatStringAsTitle($ctrl.selectedPackage.transmission),
                    icon: transmissionIcon,
                    iconLabel: {
                        'fr':'Informations sur la transmission',
                        'en': 'transmission informations'
                    }
                });
            }

            const towing = $ctrl.formatTowing();
            if (towing) {
                const towingDesc = $translate.instant('build-and-price.summary.towing', { towing: $ctrl.formatTowing() });
                $ctrl.packageConfiguration.push({
                    key: 'towing',
                    description: $ctrl.formatStringAsTitle(towingDesc),
                    icon: 'medium',
                    iconLabel: {
                        'fr':'Informations sur le remorquage',
                        'en': 'towing informations'
                    }
                });
            }
        }

        $ctrl.getTransmissionType = (transmission, manual) => {
            // Sometimes manual can be Manual so we lowercase all the text
            transmission = transmission.toLowerCase();
            if (transmission.includes(manual)) {
                return 'transmission';
            } else {
                return 'automatic';
            }
        }

        $ctrl.formatTowing = () => {
            const number = $ctrl.trunc($ctrl.selectedPackage.towing);
            if (!(number > 0)) {
                return null;
            }
            return number.toLocaleString($ctrl.language) + " lb (" + (Math.floor(number*0.45359237)).toLocaleString($ctrl.language) + " kg)";
        }

        $ctrl.trunc = (value) => {
            value = +value;
            return (value - value % 1) || (!isFinite(value) || value === 0 ? value : value < 0 ? -0 : 0);
        }

        $ctrl.formatSelectedOptions = () => {
            if (!$ctrl.categoriesObjects) {
                return;
            }

            if ($ctrl.categoriesObjects['wheel']) {
                $ctrl.wheels = $ctrl.categoriesObjects['wheel'][0];
            }
            if ($ctrl.categoriesObjects['interiorColor']) {
                $ctrl.interiorColour = $ctrl.categoriesObjects['interiorColor'][0];
            }
        }

        $ctrl.formatStringAsTitle = (text) => {
            if (text) {
              return text.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            }
        }

        $ctrl.povChanged = (newPov) => {
            if (newPov === 'interior') {
                $ctrl.pov = interiorPov;
            } else if (newPov === 'exterior') {
                $ctrl.pov = exteriorPov;
            }
        }
    }
})();
