(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.dealersPageList
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dealersPageList', []);
})();
