(function () {
    'use strict';

    angular
        .module('fca.commonServices')
        .service('fcaScrollToElementService', fcaScrollToElementService);

    function fcaScrollToElementService() {
        const $service = this;

        /**
         * Scroll window to element
         * Takes alfaromeo fixed header height in consideration
         * sticky nav not yet taken into consideration
         *
         * @param element   duration (must be number string)
         *
         */

        $service.scrollToElement = (element, duration) => {
            let mainNav = $('.layout-main-nav .main-nav');
            let scrollOffset = 0;
            let scrollDuration = 0;

            if(mainNav.length) {
                if(angular.element(mainNav[0]).css('position') === 'fixed') {
                    scrollOffset = angular.element(mainNav[0]).outerHeight();
                }
            }

            if(duration !== undefined && (angular.isNumber(duration))) {
                scrollDuration = duration;
            }

            if(element !== undefined && element.length) {
                let targetHeight = $(element[0]).offset().top;

                $('html, body').animate({scrollTop: targetHeight - scrollOffset}, scrollDuration);
            }
        };
    }
})();
