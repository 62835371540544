(function () {
	angular
		.module('alfaromeo.buildAndPrice.summary')
		.component('alfaSummary', {
			controller: alfaSummary,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/summary/alfaromeo-summary.html',
			bindings: {
				drivetrain: '@',
				disclaimers: '<',
				calculatorDisclaimers: '<',
				jellyData: '<',
				location: '<',
				nameplate: '@',
				netAmount: '<',
				options: '<',
				selectedOptions: '<',
				paymentsAndRates: '<',
				trim: '<',
				trimGroupName: '@',
				vehicleName: '@',
				pricing: '<',
				modelYearId: '@',
				acode: '<',
				brand: '@',
				year: '@',
				contactDealerUrl: '@',
				searchInventoryUrl: '@',
				currentOffersUrl: '@',
				contactDealerDesktop: '@',
				contactDealerMobile: '@',
				findDealerDesktop: '@',
				findDealerMobile: '@',
				searchInventoryDesktop: '@',
				searchInventoryMobile: '@',
				testDriveDesktop: '@',
				testDriveMobile: '@',
				alfaPlaceholderImage: '@',
				selectedNameplateName: '@',
				programLogoPath: '@',
				programDescription: '@',
				programLogoAltText: '@',
				programShowShopOnlineLink: '@',
				programShowBanner: '@',
				programBaseSniBuildUrl: '@',
				customOrderActivated: '@',
				hasCustomOrderSni: '@',
				hasCustomOrderParam: '@',
				trimName: '@',
				trimNameEn: '@',
				trimNameFr: '@',
				engineIcon: '@',
				transmissionIcon: '@',
				provinceCode: '@',
                affiliateLogoSource: '@'
			}
		});

	function alfaSummary(alfaPricing, alfaSummary) {
		'ngInject';

		const $ctrl = this;
		$ctrl.packageAndOptionsCodes = [];

		$ctrl.$onInit = () => {
			storeAffiliateId();
			$ctrl.affiliateMode = isAffiliateMode();
		}

		$ctrl.$onChanges = changes => {
			if ($ctrl.selectedOptions && changes.selectedOptions) {
				$ctrl.packageAndOptionsCodes = $ctrl.packageAndOptions();
			}

		}

		$ctrl.basePrice = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.base.msrp);
		}

		$ctrl.freight = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.freight.msrp);
		}

		$ctrl.acTax = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.acTax.msrp);
		}

		$ctrl.greenLevy = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.greenLevy.msrp);
		}

		$ctrl.financeNet = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.financeNet.msrp);
		}

		$ctrl.optionsForCalc = () => {
			if (!$ctrl.pricing) return 0;
			return alfaPricing.toInt($ctrl.pricing.options.msrp);
		}

		$ctrl.adminFee = () => {
			// Is used in SNI
			return 0;
		}

		$ctrl.getPackage = () => {
			if (!$ctrl.options) return '';
			return alfaSummary.getOptionsForSection($ctrl.options.enginePackage)[0];
		}

		$ctrl.engineDescription = () => {
			if (!$ctrl.options) return '';
			return alfaSummary.getOptionsForSection($ctrl.options.mechanical)[0].description;
		}

		$ctrl.transmissionDescription = () => {
			if (!$ctrl.options) return '';
			return alfaSummary.getOptionsForSection($ctrl.options.mechanical)[1].description;
		}

		$ctrl.mfgCode = () => {
			if (!$ctrl.trim) return '';
			return $ctrl.trim.mfgCode;
		}

		$ctrl.packageAndOptions = () => {
			if (!$ctrl.selectedOptions) return [];
			return $ctrl.selectedOptions.map(option => option.code);
		}

		const desktopMediaQuery = "(min-width: 768px)";
		let desktopMode = null;

		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.getTestDriveImage = () => {
			return $ctrl.isDesktop() ? $ctrl.testDriveDesktop : $ctrl.testDriveMobile;
		}

	}
})()
