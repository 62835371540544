(function () {
	angular
		.module('alfaromeo.buildAndPrice.interior')
		.component('alfaInterior', {
			controller: alfaInterior,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/interior/alfaromeo-interior.html',
			bindings: {
				jellyData: '<',
				jellyTitle: '@',
				sections: '<',
				onSelect: '<',
				placeholderImage: '@'
			}
		});

	function alfaInterior($scope, $rootScope, $element, $document, $window, $timeout, alfaJellyBuilder, alfaJellyLoader, alfaInteriorPanorama) {
		'ngInject';

		const $ctrl = this;
		const defaultPov = 'I01';

		const isIE = /MSIE|Trident/.test(navigator.userAgent);
		const navOffset = isIE ? 0 : 57;

		$ctrl.preloadKey = '';

		const onImageLoad = loadedImage => {
			if (loadedImage.preloadKey !== $ctrl.preloadKey) return;

			$scope.$apply(() => {
				$ctrl.jellyUrl = loadedImage.url;
			});
		}

		$ctrl.$onChanges = changes => {
			if (!changes.jellyData || !changes.jellyData.currentValue) {
				return;
			}

			const jellyData = changes.jellyData.currentValue;
			const newPreloadKey = $ctrl.buildJellyUrl(jellyData);

			if (newPreloadKey === $ctrl.preloadKey) return;

			$ctrl.preloadKey = newPreloadKey;
			alfaJellyLoader.loadImages(
				newPreloadKey,
				$ctrl.buildJellyUrl.bind(jellyData),
				defaultPov,
				[],
				onImageLoad
			);
		}

		$ctrl.onSelectOption = option => {
			$ctrl.onSelect(option);
		}

		let interiorImage;
		let lastScrollY = -1;
		$ctrl.$postLink = () => {
			interiorImage = document.getElementById('js-AR-interior-jelly');

			window.addEventListener(
				'resize',
				() => requestAnimationFrame($ctrl.onResize)
			);

			window.addEventListener(
				'scroll', () => {
					if (!$ctrl.show360Viewer) {
						lastScrollY = window.pageYOffset;
					}
				});

			window.addEventListener(
				'keydown',
				event => $scope.$apply(onKeydown(event))
			);

			$('#AR_interior-overlay-container, .photosphereContainer').on('click', event => {
				event.preventDefault();
				if (event.target.id === 'AR_interior-overlay-container') {
					$scope.$apply(() => {
						kill360();
					});
				}
			});
			$rootScope.$on('interior-panorama:kill-360', () => {
				kill360();
			});

			$ctrl.onResize();
		};

		const imageRatio = 3200 / 1800;
		$ctrl.onResize = () => {
			requestAnimationFrame(() => {
				let heightMinusNav = innerHeight - navOffset;
				const contentWidth = Math.min(innerWidth, 1600);

				const ratio = contentWidth / heightMinusNav;

				$(interiorImage).toggleClass('-skinny', ratio < imageRatio);
			});
		};

		$ctrl.buildJellyUrl = (jellyData, newPov) => {
			jellyData = jellyData || $ctrl.jellyData;
			newPov = newPov || defaultPov;

			if (!jellyData) {
				return;
			}

			const jellyOptions = Object.assign(
				{ pov: newPov },
				$ctrl.jellyData
			);

			return alfaJellyBuilder.jellyUrl(jellyOptions);
		};

		$ctrl.imageIsLoaded = () => $ctrl.preloadKey === $ctrl.jellyUrl;

		$ctrl.toggle360Viewer = () => {
			const image = document.querySelector('.AR_interior-jelly-container');
			const mediaQuerySmallPlus = window.matchMedia("(min-width: 768px)");
			const portraitOrientation = window.matchMedia("(orientation: portrait)");
			const landscapeOrientation = window.matchMedia("(orientation: landscape)");
			const panoramaUrl = $ctrl.buildJellyUrl($ctrl.jellyData, 'centerpano');
			const photosphereElement = $element.find('.photosphereContainer').get(0);
			if (!$ctrl.show360Viewer) {
				if (mediaQuerySmallPlus.matches) {
					alfaInteriorPanorama.setImageDimensions({ width: (image.clientWidth - 100), height: (image.clientHeight - 100) });
				} else {
					if (portraitOrientation.matches) {
						alfaInteriorPanorama.setImageDimensions({ width: (image.clientWidth), height: (image.clientHeight + 100) });
					} else if (landscapeOrientation.matches) {
						alfaInteriorPanorama.setImageDimensions({ width: (image.clientWidth), height: (image.clientHeight) });
					}
				}
				alfaInteriorPanorama.toggle360Viewer(panoramaUrl, photosphereElement);
			}
			$ctrl.show360Viewer = !$ctrl.show360Viewer;
			if ($ctrl.show360Viewer) {
				$('body').addClass('noScroll').css('top', -lastScrollY);
			} else {
				kill360();
			}
		};

		function kill360() {
			$ctrl.show360Viewer = false;
			window.scrollTo(0, lastScrollY);
			$('body').removeClass('noScroll');
			$('.AR_interior-overlay-btn').first().focus();
		}

		function onKeydown(keyEvent) {
			switch (keyEvent.keyCode) {
				case 27 :
					kill360();
					break;
			}
		}
	}
})()
