// increase stack trace size on IE
try {
	var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
	var msie = ua.indexOf('MSIE '); // IE 10 or older
	var trident = ua.indexOf('Trident/'); //IE 11

	if ((msie > 0 || trident > 0)) {
		Error.stackTraceLimit = 100;
	}
} catch (exception) {}
(function() {
	angular
		.module('fca.brandApp', [
			/*
			 * ----- Core modules -----
			 *
			 * Shared modules and third-party modules.
			 * Usage: These modules likely break the application if removed.
			 */
			'fca.core.main',
			'fca.externalConfigLoader',
			'fca.scenarioOrchestratorService',
			'fca.brandTemplates',
			'fca.pageLayout',
			'fca.exceptionHandler',
			'pascalprecht.translate',
			'bhResponsiveImages',
			'slick',
			'ngDialog',
			'ngTouch',
			'matchmedia-ng',
			'puElasticInput',
			'angular-bind-html-compile',
			'fca.translateConfigurator',
			/*
			 * ----- Feature modules -----
			 *
			 * These are feature modules or widgets, example: Geolocation functionality, Chat widget, etc.
			 * Usage: Removing one of these may disable a particular feature but the app should still work
			 */
			'angulartics',
			'angulartics.personalization',
			'fca.commonLegalNotes',
			'fca.commonAjaxLegalTooltip',
			'fca.cookieBanner',
			'fca.simpleAccordion',
			'fca.signupPopup',
			'fca.overlay',
			'fca.partial',
			'fcaWaypoints',
			'fca.provincializeBlock',
			'fcaModelSpecs',
			'fca.core.competitionCompare',
			'fca.commonDialog',
			'fca.dialog.theme.transparency',
			'fca.dialog.theme.popover',
			'fca.dialog.theme.alfaromeowhite',
			'fca.dialog.theme.alfaromeoblack',
			'fca.dialog.theme.bodybuilder',
			'fca.userLocation',
			'fca.keypress',
			'fca.geolocator',
			'fca.autocomplete',
			'fca.postalCodeInput',
			'fca.countdown',
			'fca.chatPopup',
			'fca.chatMenuPopup',
			'fca.dealerLocator',
			'fca.gtmAnalytics',
			'fca.uiPanels',
			'fca.hoursStatus',
			'fca.salesAppointmentMap',
			'fca.dealerDetailsMap',
			'fca.dealerDetailsInventory',
			'fca.preferredDealer',
			'fca.calculator',
			'fca.kbb',
			'fca.buyOnlineApplyCredit',
			'fca.buyOnlineServices',
			'fca.buyOnlineAccessories',
			'fca.buyOnlineAccessoriesReview',
			'fca.buyOnlinePersonalInfo',
			'fca.buyOnlineServicesReview',
			'fca.buyOnlineDeliveryOptions',
			'fca.buyOnlineThankYou',
			'fca.buyOnlineNavigationBar',
			'fca.buyOnlineLead',
			'fca.buyOnline',
			'fca.buyOnline.calculator',
			'fca.daaCalculator',
			'fca.daaCalculatorWidget',
			'fca.daaCalculatorDialog',
			'fca.applyForFinancing',
			'fca.buildAndPrice.jelly',
			'fca.buildAndPrice.jellyLegacy',
			'fca.buildAndPrice.summaryTooltips',
			'fca.buildAndPrice.director',
			'fca.buildAndPrice.stepsManager',
			'fca.buildAndPrice.packageSelector',
			'fca.buildAndPrice.swatch',
			'fca.buildAndPrice.colourWheelsSelector',
			'fca.buildAndPrice.optionSelector',
			'fca.buildAndPrice.alertWindow',
			'fca.buildAndPrice.optionsSummary',
			'fca.buildAndPrice.paymentSummary',
			'fca.buildAndPrice.configurationsViewer',
			'fca.buildAndPrice.nameplatePricingOr',
			'fca.buildAndPrice.nameplatePricingSingle',
			'fca.buildAndPrice.trimUpsellWindow',
			'fca.buildAndPrice.directives',
			'fca.buildAndPrice.overlay',
			'fca.sni.compareVehicleWrapper',
			'fca.sni.vehicleInventoryList',
			'fca.sni.comparedVehicleBar',
			'fca.sni.compareVehiclePage',
			'fca.sni.compareVehicleFooter',
			'fca.sni.compareDetailsUrl',
			'fca.newInventory.addToFavourites',
			'fca.newInventory.modelFeatures',
			'fca.newInventory.otherVehicles',
			'fca.newInventory.overlay',
			'fca.newInventory.calculatorButton',
			'fca.contactADealer',
			'fca.components.formDealerSelector',
			'fca.components.formVehicleSelector',
			'fca.components.responsiveJelly',
			'fca.components.sniFinance',
			'fca.slider',
			'fca.userAccount',
			'fca.userAccount.myGarage',
			'fca.userAccount.myGarage.vehicleTile',
			'fca.commonDirectives',
			'fca.personalizationDaa',
			'fca.commonFilters',
			'fca.commonServices',
			'fca.userSignIn',
			'fca.userSignInForm',
			'fca.commonNameplate',
			'fca.uconnect',
			'fca.uconnect.service',
			'fca.closestDealer',
			'fca.bodyBuilder',
			'fca.bodyBuilder.dialog',
			'fca.mention',
			'fca.personalization.sniOffer',
			'fca.modal',
			'fca.daaContactDealer',
			'fca.daaForm.inputRange',
			'fca.brand.minivan',
			'fca.vlp.overlay',
			'fca.reservation',
			'fca.dealerAppointment',
			'fca.salesAppointment',
			'fca.programsBanner',
			'fca.featuredArticles',
			'fca.welcomeVideoAutoshow',
			'fca.topicVideoAutoshow',
			'fca.countrySelector',
			'fca.daaPersonalizationModal',
			'fca.miniBuildAndPrice.director',
			'fca.miniBuildAndPrice.exterior',
			'fca.miniBuildAndPrice.exterior.interior',
			'fca.miniBuildAndPrice.interior',
			'fca.miniBuildAndPrice.model.info',
			'fca.miniBuildAndPrice.model.selector',
			'fca.miniBuildAndPrice.stats',
			'fca.miniBuildAndPrice.jelly',
			'fca.miniBuildAndPrice.selector',
			'fca.miniBuildAndPrice.swatch',
			'fca.dealerName',
			'fca.loyaltyDrawerOpener',
			//////////////////////
			// Alfa Romeo 🚗
			/////////////////////
			'alfaromeo.audioPlayer',
			'alfaromeo.videoPlayer',
			'alfaromeo.buildAndPrice.service',
			'alfaromeo.buildAndPrice.icons',
			'alfaromeo.buildAndPrice.director',
			'alfaromeo.buildAndPrice.alertWindow',
			'alfaromeo.buildAndPrice.exterior',
			'alfaromeo.buildAndPrice.interior',
			'alfaromeo.buildAndPrice.navigation',
			'alfaromeo.buildAndPrice.powertrain',
			'alfaromeo.buildAndPrice.pricing-summary',
			'alfaromeo.buildAndPrice.programs-banner',
			'alfaromeo.buildAndPrice.jelly',
			'alfaromeo.buildAndPrice.presentational-components',
			'alfaromeo.buildAndPrice.selector',
			'alfaromeo.buildAndPrice.summary',
			'alfaromeo.buildAndPrice.sticky',
			'alfaromeo.buildAndPrice.compile-html',
			'alfaromeo.filter',
			'alfaromeo.iris',
			'alfaromeo.alfistiVideo',
			'alfaromeo.miniNav',
			'alfaromeo.imgDifference',
			'alfaromeo.counter',
			'alfaromeo.vlp',
			'alfaromeo.contactUs',
			'alfaromeo.quadrifoglioVideo',
			'alfaromeo.quadrifoglioMedia'
		])
		.config(configCompileProvider)
		.config(configLocationProvider)
		.config(configTranslateProvider)
		.config(configTranslateProvider)
		.config(configGtmAnalyticsProvider)
		.config((fcaDialogThemeProvider,
				fcaDialogThemeTransparencyProvider,
				fcaDialogThemePopoverProvider,
				fcaDialogThemeAlfaromeoblackProvider,
				fcaDialogThemeAlfaromeowhiteProvider,
				fcaDialogThemeBodybuilderProvider) => {
			'ngInject';
			let transparencyTheme = fcaDialogThemeTransparencyProvider;
			let popoverTheme = fcaDialogThemePopoverProvider;
			let alfaromeoblackTheme = fcaDialogThemeAlfaromeoblackProvider;
			let alfaromeowhiteTheme = fcaDialogThemeAlfaromeowhiteProvider;
			let bodybuilderTheme = fcaDialogThemeBodybuilderProvider;
			/* Register themes */
			fcaDialogThemeProvider
				.register(transparencyTheme.name, transparencyTheme.cfg)
				.register(popoverTheme.name, popoverTheme.cfg)
				.register(alfaromeowhiteTheme.name, alfaromeowhiteTheme.cfg)
				.register(alfaromeoblackTheme.name, alfaromeoblackTheme.cfg)
				.register(bodybuilderTheme.name, bodybuilderTheme.cfg);
		})
		.provider('gtmAnalytics', gtmAnalyticsProvider)
		.run(['$window', ($window) => {
			'ngInject';

			detectUserAgent();
			customEvent();
			bindPageInitPhase();
			copyrightSize();

			$window.onload = () => {
				let windowLoadedEvent = new Event('fca.brandApp.windowOnload');
				$window.dispatchEvent(windowLoadedEvent);
				preventDisclaimerBubbling();
			};

			angular.element('html').removeClass('no-js');

		}]);

	function customEvent() {
		// Fix new event in IE
		// Internet Explorer >= 9 adds a CustomEvent object to the window, but with correct implementations, this is a function.
		// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
		if ( typeof window.CustomEvent === "function" ) return false;

		function CustomEvent ( event, params ) {
			params = params || { bubbles: false, cancelable: false, detail: undefined };
			var evt = document.createEvent( 'CustomEvent' );
			evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
			return evt;
		}

		CustomEvent.prototype = window.Event.prototype;

		window.Event = CustomEvent;
	}

	function configCompileProvider($compileProvider) {
		$compileProvider.debugInfoEnabled(false);
	}

	function configLocationProvider($locationProvider) {
		'ngInject';

		$locationProvider.html5Mode({
			enabled: true,
			requireBase: false,
			rewriteLinks: false
		});
	}

	function configTranslateProvider($translateProvider) {
		'ngInject';

		$translateProvider.useSanitizeValueStrategy('sanitizeParameters');

		// https://angular-translate.github.io/docs/#/guide/12_asynchronous-loading
		$translateProvider.useUrlLoader(window.FCA_SITES_CONFIG.messagesPath);

		$translateProvider.preferredLanguage(window.FCA_SITES_CONFIG.preferredLanguage);
	}

	function configGtmAnalyticsProvider(gtmAnalyticsProvider) {
		'ngInject';

		/**
		 * Load GA config
		 */
		let options = window.BRANDS_GA;

		// console.log('options : ', options)

		// Clean-up options and remove null values
		for (let p in options) {
			if (options[p] === null || options[p] === 'null' || options[p] === '') {
				options[p] = undefined;
			}
		}

		// Extend gtmAnalytics page options
		gtmAnalyticsProvider.options = angular.extend({}, gtmAnalyticsProvider.options, options);

		/**
		 * Disabled automatic tracking
		 */
		gtmAnalyticsProvider.disabledDefaultTracking();

		// console.log("gtmAnalyticsProvider.options ", gtmAnalyticsProvider.options);
	}

	function gtmAnalyticsProvider($analyticsProvider) {
		'ngInject';
		/* eslint-disable no-invalid-this */

		this.options = {};

		this.eventPrefix = 'gaevent';

		gtmAnalyticsProvider.eventPrefix = this.eventPrefix;

		this.$get = ($analytics, matchmedia, $location, $window) => {
			'ngInject';

			return {
				trackPage: function(pOptions = {}, disabledTracking = false) {
					// console.log('pOtions : ', pOptions);
					// console.log('trackPage dataLayer : ', window.dataLayer);

					// Additional options
					let opts = angular.extend({}, pOptions);
					// Device type
					let device = 'desktop';

					if (!matchmedia.isDesktop()) {
						opts.mobileorientation = 'portrait';

						if (matchmedia.isLandscape()) {
							opts.mobileorientation = 'landscape';
						}

						device = 'tablet';

						if (matchmedia.isPhone()) {
							device = 'mobile';
						}
					}

					// Set device type
					opts.device = device;

					if (!disabledTracking) {
						let url = `${$window.location.pathname}`;
						let hash = $window.location.hash;
						if (hash.substr(-1) !== '/') {
							url += `${hash}`;
						}

						// console.log('opts : ', opts);

						$analytics.pageTrack(url, opts);
					}
				},

				trackEvent: function(event, pOptions = {}) {
					$analytics.eventTrack(event, pOptions);
				}
			};
		};

		/**
		 * [description]
		 * @return {[type]} [description]
		 */
		this.disabledDefaultTracking = () => {
			// Virtual page view
			$analyticsProvider.virtualPageviews(false);
			// Automatic first page tracking
			$analyticsProvider.firstPageview(false);

			return this;
		};

		$analyticsProvider.registerPageTrack((path, pOptions) => {
			let dataLayer = window.dataLayer = window.dataLayer || [];

			let gtmOptions = angular.extend({
				'event': 'content-view',
				'content-name': path,
				'pageurl': window.location.href.replace(window.location.search, '')
			}, this.options, pOptions);

			dataLayer.push(gtmOptions);
			window.dataLayer = dataLayer;

			// console.log('registerPageTrack dataLayer : ', window.dataLayer);

			// console.log('Push to dataLayer - Page track', dataLayer, gtmOptions);
		});

		$analyticsProvider.registerEventTrack((action, properties = {}) => {
			let dataLayer = window.dataLayer = window.dataLayer || [];
			let params = {
				event: 'gaevent',
				eventAction: window.location.href.replace(window.location.search, '')
			};

			// Update the key sent to the data layer
			//		category -> eventCategory
			//		label ->eventLabel
			let newKeyMappings = {
				category: 'eventCategory',
				label: 'eventLabel',
				action: 'eventAction',
				trigger: 'eventTrigger'
			};

			let mapped = Object.keys(properties).map((oldKey) => {
				let result = {};

				if(['category', 'label', 'action', 'trigger'].indexOf(oldKey) >= 0) {
					let newKey = newKeyMappings[oldKey];
					result[newKey] = properties[oldKey];

					if (oldKey === 'action') {
						delete params['eventAction'];
					}
				} else {
					result[oldKey] = properties[oldKey];
				}

				return result;
			});

			let newFormatPropertiesObj = mapped.reduce((result, item) => {
				let key = Object.keys(item)[0];
				result[key] = item[key];
				return result;
			}, {});


			let gtmOptions = angular.extend(params, newFormatPropertiesObj);
			dataLayer.push(gtmOptions);

			// console.log('Push to dataLayer', dataLayer, gtmOptions);
		});
	}

	function detectUserAgent() {
		'ngInject';

		let agent = window.navigator.userAgent;
		let msi = agent.indexOf('MSIE');

		if (msi > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			$('body').addClass('is-ie');
		}

		if (agent.toLowerCase().indexOf('firefox') > -1) {
			$('body').addClass('is-firefox');
		}
	}

	function bindPageInitPhase() {
		$('body').addClass('page-init-phase');
		angular.element(document).ready(() => {
			window.PubSubManager.publish("angular-ready");
			setTimeout(() => {
				$('body').removeClass('page-init-phase');
			}, 50);
		});
	}

	function copyrightSize() {
		angular.element(document).ready(() => {
			const smallArray = ['tm', 'mc', 'md'];
			$('sub, sup').each( function() {
				let $this = $(this);
				let content = $this.text().toLowerCase();

				if ($.inArray( content, smallArray) !== -1) {
					$this.addClass('sup-sub-small');
				}
			});
		});
	}

	function preventDisclaimerBubbling() {
		angular.element(document).ready(() => {
			angular.element('fca-legal-tooltip').on('click', function($event) {
				return false;
				$event.stopPropagation();
			});
		});
	}
})();
