/* eslint-disable indent */
(function(ng) {
    'use strict';

    angular
        .module('fca.dealerLocator')
        .controller('dealerLocatorController', Controller)
        .component('dealerLocator', {
            controller: 'dealerLocatorController',
            controllerAs: '$dealerLocator',
            templateUrl: '/dealer-locator/dealer-locator.html',
            bindings: {
                apiUrl: '@',
                location: '<',
                viewAllUrl: '@?',
                showDealerAppointmentSection: '<',
                customOrder: '@',
                isDealerListPaginated: '@',
                perPage: '@',
                eshopUrl: "@",
                customOrderActivated: "@",
                nameplateCode: "@",
                modelYear: "@"
            }
        });

    /**
     * @ngdoc controller
     * @name  fca.dealerLocator.controller:dealerLocatorController
     * @requires $scope
     * @requires $http
     * @requires $timeout
     * @requires $location
     * @requires fca.dealerLocator.service:dealerLocator
     * @requires matchmedia
     * @requires MEDIAQUERIES
     * @description [TODO]
     * @example
     * <pre>[TODO]</pre>
     */
    function Controller($scope,
                        $timeout,
                        $location,
                        dealerLocator,
                        gtmAnalytics,
                        $analytics,
                        dealerChatService) {
        'ngInject';

        /**
         * @const {String}
         * @name EVENT_SELECT
         * @description On select event name
         */
        const EVENT_SELECT = 'dealerLocator.event.onSelect';

        /**
         * @const {String}
         * @name EVENT_HOVER_LEAVE
         * @description On hover/leave event name
         */
        const EVENT_HOVER_LEAVE = 'dealerLocator.event.onHoverLeave';

        /**
         * @const {String}
         * @name EVENT_DATA_RESET
         * @description Event name when data is reset
         */
        const EVENT_DATA_RESET = 'dealerLocator.event.onResetData';

        const RADIUS = 500;

        const BRAND = window.FCA_SITES_CONFIG.name;

        const MAX_DISPLAYED_DEALERS = 50;

        /**
         * @description
         * Debouncer ($timeout) for hover behaviour
         */
        let hover$timeout;

        /**
         * @description
         * Debounce hover delay ($timeout)
         */
        let hover$timeoutDelay = 100;

        /**
         * @description
         * Url params selected code
         */
        let urlSelectedCode;

        /**
         * @ngdoc property
         * @name location
         * @description Ref. location of the center of the map
         * @type {Object}
         */
        let defaultLocation = {};

        /**
         * Analytics done
         */
        let analyticsTracked = false;

        /**
         * @ngdoc property
         * @name selected
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {Object}
         * @description Element selected in dealers list
         */
        this.selected = null;

        /**
         * @ngdoc property
         * @name dealers
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {Array}
         * @description List of dealers ($onInit call backend API)
         */
        this.dealers = [];

        /**
         * @ngdoc property
         * @name isInitialized
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {Boolean}
         * @description Set to true when the dealers load is done
         */
        this.isInitialized = false;

        /**
         * @ngdoc property
         * @name noResults
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {Boolean}
         * @description Set to true when component is initialized and the dealer list is empty
         */
        this.noResults = false;

        /**
         * @ngdoc property
         * @name isDealerListPaginated
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {Boolean}
         * @description Set to true when dealers list must be paginated
         */
        this.isDealerListPaginated = false;

        /**
         * @ngdoc property
         * @name dealerListEventPrefix
         * @propertyOf fca.dealerLocator.controller:dealerLocatorController
         * @type {String}
         * @description Event prefix name for events trigerred by dealers list component
         */
        this.dealerListEventPrefix = 'DEALER_PAGE_LIST';

        /**
         * @description
         * Model for locator dialog
         * @type {Object}
         */
        this.dialogModel = {};

        /**
         * @ngdoc method
         * @name  $onInit
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description Do initial call API server to get dealders list
         */
        this.$onInit = () => {

            this.dialogModel = ng.extend({}, this.dialogModel, {
                title: 'geolocation.user.location'
            });

            /**
             * Listener for event dealers list mouse clicked item
             */
            $scope.$on(`${this.dealerListEventPrefix}.clicked`, ($evt, args) => {
                this.selected = ng.copy(args);
                /* Trigger selected dealer event with dealer data */
                $scope.$broadcast(EVENT_SELECT, this.selected);

                this.gaTrackEvent('dealer selected', {
                    dealermap: 'dealer selected',
                    pagedealerid: args.code,
                    dealerposition: args.position
                });
            });

            /**
             * Listener for event dealers list mouse hover item
             */
            $scope.$on(`${this.dealerListEventPrefix}.hover`, ($evt, args) => {
                let [dealerId] = args;

                // Set new hover $timeout
                setHover$timeout(() => {
                    if (!this.selected || this.selected.dealerId !== dealerId) {
                        $scope.$broadcast(EVENT_HOVER_LEAVE, dealerId);
                    }
                });
            });

            /**
             * Listener for event dealers list mouse leave item
             */
            $scope.$on(`${this.dealerListEventPrefix}.leave`, () => {
                clearHover$timeout(() => {
                    $scope.$broadcast(EVENT_HOVER_LEAVE, null);
                });
            });
        };

        /**
         * @ngdoc method
         * @name  $onChanges
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description Catch location property changes to refresh data
         */
        let locationInitialized = false;
        this.$onChanges = o => {
            // Get from url the selected dealer
            let {code, latitude, longitude} = $location.search();
            if (ng.isString(code)) {
                urlSelectedCode = code;
            }

            if (latitude && longitude) {
                latitude = parseFloat(latitude);
                longitude = parseFloat(longitude);
                if (ng.isNumber(latitude) && ng.isNumber(longitude)) {
                    defaultLocation = ng.extend({}, defaultLocation, {
                        latitude: parseFloat(latitude),
                        longitude: parseFloat(longitude)
                    });
                }
            }

            let {location} = o;
            if (location !== undefined) {
                let currentLocation = location.currentValue;
                if (ng.isObject(currentLocation)) {
                    if (locationInitialized) {
                        this.setLocation(null, true);
                    } else {
                        // Set default value with url params
                        this.location = ng.copy(defaultLocation);
                        // Set defaultLocation to empty value
                        defaultLocation = {};
                        if (ng.equals(this.location, {})) {
                            // Set current user location if urls params not exists
                            this.location = currentLocation;
                        }

                        locationInitialized = true;
                        // Search with this.location value
                        this.findDealers().then(() => {
                            this.isInitialized = true;
                        });
                    }
                }
            }
        };

        /**
         * @ngdoc method
         * @name select
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @param  {Object} data Dealer selected data
         * @description Method call to select a dealer in dealers list
         */
        this.select = (data) => {
            $scope.$apply(() => {
                /* Set selected dealer */
                this.selected = ng.copy(data);
                /* Trigger selected dealer event with dealer data */
                $scope.$broadcast(EVENT_SELECT, this.selected);

                this.gaTrackEvent('pin click', {
                    dealermap: 'pin click',
                    pagedealerid: data.code,
                    dealerposition: data.position
                });
            });
        };

        this.gaTrackEvent = (eventName, event) => {
            let category = 'dealer map';
            event.category = category;
            event.label = category + ' - ' + eventName;
            $analytics.eventTrack(eventName, event);
        }

        /**
         * @ngdoc method
         * @name unselect
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @param  {Object} $event DOM event
         * @description Method call the selected dealer in dealers list is unselected
         */
        this.unselect = ($event) => {
            if ($event !== undefined) {
                $event.preventDefault();
            }

            /* Set selected dealer to null */
            this.selected = null;
            /* Trigger event selected with null value */
            $scope.$broadcast(EVENT_SELECT, this.selected);
        };

        /**
         * @ngdoc method
         * @name onCenterChange
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description
         * Callback function pass throw the map component and call when center changed.
         * Refresh data on map with a new set of dealers.
         * @param  {Object} data   Map center location (longitude/latitude)
         * @param  {Number} radius Radius of map viewport
         */
        this.onCenterChange = (data, options) => {
            this.findDealers(data, options.radius);
        };

        /**
         * @ngdoc method
         * @name onRadiusChange
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description
         * Callback function pass throw the map component and call when the radius of map viewport changed.
         * Refresh data on map with a new set of dealers.
         * @param  {Number} radius Radius of map viewport
         */
        this.onRadiusChange = (options) => {
            let location = this.location;
            if (options.location && ng.isObject(options.location)) {
                location = options.location;
            }

            this.findDealers(location, options.radius);
        };

        /**
         * @ngdoc method
         * @name setLocation
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description
         * Callback function pass throw the geolocator component and call when the user location changed.
         * Refresh data on map with a new set of dealers.
         * @param  {Object} data New location (longitude/latitude)
         */
        this.setLocation = (data, setCenter = false) => {
            // Location change tracking
            trackLocationChange(data);
            // Load data on map
            this.findDealers(data, RADIUS, setCenter);
        };

        /**
         * @ngdoc method
         * @name findDealers
         * @methodOf fca.dealerLocator.controller:dealerLocatorController
         * @description
         * Retrieve dealers near a location with a specific radius.
         * Broadcast event data reset after data are updated.
         * @param {Object} location Object (longitude/latitude) center reference
         * @param {Number} radius Distance radius around the reference point
         * @return {Object} AngularJS promise
         */
        this.findDealers = (location = null, radius = RADIUS, setCenter = false) => {
            if (location === null) {
                /* use current location */
                location = this.location;
            }


            return dealerLocator.findDealers(this.apiUrl, location, BRAND, false, radius).then(r => {
                if (r.manualPostalCode !== undefined) {
                    r.userZipCode = r.manualPostalCode;
                }

                // Don't display all dealers. 
                this.dealers = r.dealers.slice(0, MAX_DISPLAYED_DEALERS);

                // Add chat url for each dealer
                this.dealers.forEach((dealer, index) => {
                    if (dealer.isSubscribedToChat) {
                        let originationUrl = encodeURIComponent(window.location.href);
                        dealer.chatUrl = dealerChatService.getDealerChatUrl(dealer.chatProvider, dealer.chatProviderIdEn, dealer.code, originationUrl);
                    }
                    // dealer code is not unique, we create a dealer id that is unique
                    dealer.dealerId = dealer.code + '-' + index;
                });
                /* Get postal code and update location with */
                this.location = ng.extend({}, this.location, location, {
                    cp: r.userZipCode.replace(' ', '')
                });

                this.location.fitBounds = setCenter;

                this.selected = this.dealers[0];

                this.noResults = this.isInitialized && this.dealers.length === 0;

                /* Event to scroll list on top */
                $scope.$broadcast(EVENT_DATA_RESET, this.selected);

                /* Tracking */
                trackPage(this.location, this.dealers[0] || {});

                // Set flag to true for tracking once
                analyticsTracked = true;
            });
        };

        /**
         * @description
         * Clear hover timeout
         * @param  {Function} fn callback after timeout is cleared
         */
        function clearHover$timeout(fn = null) {
            if (hover$timeout) {
                $timeout.cancel(hover$timeout);
            }

            if (fn && ng.isFunction(fn)) {
                fn();
            }
        }

        /**
         * @description
         * Set hover timeout function
         * @param {Function} fn Function to delay
         */
        function setHover$timeout(fn) {
            clearHover$timeout();

            hover$timeout = $timeout(() => {
                fn();
                hover$timeout = null;
            }, hover$timeoutDelay);
        }

        /**
         * @description
         * Return selected item in all dealers list. Null if selected not found.
         * @param  {Array}    dealers Dealers list
         * @param  {String} code    Selected dealer code
         * @return {Object}            Selected dealer data or null
         */
        function getSelectedDealer(dealers, code) {
            let selected = null;
            for (let i = 0, l = dealers.length; i < l; i++) {
                let d = dealers[i];
                if (d.code === code) {
                    selected = d;
                    break;
                }
            }

            return selected;
        }

        function trackLocationChange(location) {
            if (location) {
                let {postalCode, name} = location;
                if (postalCode && name) {
                    this.gaTrackEvent('postalcodeselfreported', {
                        locatorgeosearch: `${name}:${postalCode}`
                    });
                }
            }
        }

        function trackPage(location = null, dealer = {}) {
            if (!analyticsTracked) {
                let region = '';
                if (location) {
                    region = location.region;
                }

                let {distance} = dealer;
                gtmAnalytics.trackPage({
                    pagedistonearestdealer: distance || 0,
                    pageregion: region
                });
            }
        }
    }
})(angular);
