(function () {
    angular
        .module('fca.uconnect')
        .component('fcaUconnectListView', {
            controller: fcaUconnectListView,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/list-view/list-view.html',
            bindings: {
                screenList: '<',
                updateScreenData: '<',
                updateFeatureCodeSelected: '<',
                featureOverlayIsVisible: '=',
                analyticsCategory: '@',
                analyticsId: '@'
            }
        });

    function fcaUconnectListView($element, $timeout, $scope, gtmAnalytics) {
        'ngInject';

        const $ctrl = this;

        $ctrl.language = window.FCA_SITES_CONFIG.language;

        $ctrl.$postLink = () => {
            if ($ctrl.analyticsCategory && $ctrl.analyticsId) {
                $scope.$on('uconnect-screen-data-ready', () => {
                    $element.find('.VLP2021-uconnect-link').on('click', (e) => {
                        $ctrl.sendAnalytics($ctrl.analyticsCategory, $ctrl.analyticsId + '-link', e.target.innerHTML);
                    });
                });
            }
        };

        //Show feature-overlay component and update screen corresponding to the selected hotspot
        $ctrl.showFeatureOverlayAndUpdateScreen = (featureCode, screenIndex) => {
            // Hide start overlay
            let overlayContainer = $element[0].parentElement.querySelector('div.C_UCS-container');
            if (overlayContainer) {
                let startOverlay = overlayContainer.querySelector('div.C_UCSO-container');
                if (startOverlay) {
                    startOverlay.click();
                }
            }
            
            $timeout(() => {
                // show overlay
                $ctrl.featureOverlayIsVisible = !$ctrl.featureOverlayIsVisible;
                $ctrl.updateFeatureCodeSelected(featureCode);
                // change displayed screen
                $ctrl.updateScreenData(screenIndex);
            })
            
        };

        $ctrl.analyticsList = () => {
            $timeout(function() {
                let itemsArray = $element[0].querySelectorAll('.C_UC-listview-hotspot-list-item');

                for (let item of itemsArray) {
                    let analyticsId = item.getAttribute("data-analyticsid");
                    let analyticsValue = item.getAttribute("data-analyticsvalue");
                    let $child = $(item).find('span, sup, sub');

                    if ($child.length > 0) {
                        $child.attr('data-analyticsid', analyticsId);
                        $child.attr('data-analyticsvalue', analyticsValue);
                    }
                }

            }, 0);
        };

        $ctrl.sendAnalytics = (category, id, value) => {
            gtmAnalytics.trackEvent('event', {
                category: 'App-Suite-' + category,
                label: id + '-' + value
            });
        }
    }
})();
