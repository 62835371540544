(function () {
    angular
        .module('fca.dealerDetailsMap')
        .directive('fcaDealerDetails', FcaDealerDetails);

    function FcaDealerDetails() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {},
            controllerAs: '$fcaDealerDetailsCtrl',
            controller: FcaDealerDetailsCtrl
        };

        function FcaDealerDetailsCtrl() {
            const $ctrl = this;

            $ctrl.activeTab = 'sales';

            $ctrl.buttonClicked = (tabSelected) => {
                $ctrl.activeTab = tabSelected;
            };
        }
    }
})();