(function () {
	angular
		.module('fca.buyOnlineApplyCredit')
		.service('fcaBuyOnlineApplyCreditService', FcaBuyOnlineApplyCreditService);

	function FcaBuyOnlineApplyCreditService($http, fcaBuyOnlineService) {
		'ngInject';
		let $service = this;

		$service.getLeadReferenceNumber = (vin) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			let leadRefNumber = checkout.leadReferenceNumber;
			return leadRefNumber;
		};

		$service.setLeadReferenceNumber = (vin, leadRefNumber) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			checkout.leadReferenceNumber = leadRefNumber;
			// Saving to session storage.
			fcaBuyOnlineService.setInStorage(vin, checkout);
		};
	}
})();
