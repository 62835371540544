(function () {
    angular
        .module('fca.uconnect')
        .component('fcaUconnectFeatureOverlay', {
            controller: FcaUconnectFeatureOverlay,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/feature-overlay/feature-overlay.html',
            bindings: {
                featureList: '<',
                updateFeatureCodeSelected: '<',
                featureOverlayIsVisible: '=',
                featureCodeSelected: '<',
                updateScreenData: '<',
                analyticsCategory: '@',
                analyticsId: '@'

            }
        });

    function FcaUconnectFeatureOverlay($sce, $scope, fcaVideoFactory, gtmAnalytics, $element, cleanCarousel) {
        'ngInject';

        const $ctrl = this;

        const $slickSlider = $('.C_UCFO-slick');

        $ctrl.language = window.FCA_SITES_CONFIG.language;

        $slickSlider.on('afterChange', function(event, slick, currentSlide) {
            const featureCodeSelected = $(slick.$slides[currentSlide]).data('feature-code');
            const slide = $(slick.$slides[currentSlide]);
            const screenIndex = slide.data("screen-index");
            // When scrolling through features in slick overlay, update screen
            $ctrl.updateScreenData(screenIndex);

            const nextSlideFeatureCode = slide.next().data('feature-code');
            const prevSlideFeatureCode = slide.prev().data('feature-code');
            $ctrl.addAnalytics(featureCodeSelected, nextSlideFeatureCode, prevSlideFeatureCode);

            $('.VLP2021-uconnect-link').attr('tabindex','-1');

            // Manage tabindex for Youtube videos
            $.each( slick.$slides, function(key, value) {
                let iframeRef = $(value).find('iframe');
                if (iframeRef) {
                    if (!value.classList.contains('slick-current')) {
                        iframeRef.attr('tabindex','-1');
                    } else {
                        iframeRef.attr('tabindex','0');
                    }
                }
            });
        });

        $ctrl.addAnalytics = (featureCodeSelected, nextSlideFeatureCode, prevSlideFeatureCode) => {
            //Add analytics on the slick arrow
            $element.find('.slick-next, .slick-prev').attr('data-analyticsid', $ctrl.analyticsId ? $ctrl.analyticsId : 'uconnect-key-feature-slide');
            $element.find('.slick-next').attr('data-analyticsvalue', nextSlideFeatureCode + '-forward');
            $element.find('.slick-prev').attr('data-analyticsvalue', prevSlideFeatureCode + '-backward');
        };

        $slickSlider.on('beforeChange', function (event, slick, currentSlide) {
            const featureCodeSelected = $(slick.$slides[currentSlide]).data('feature-code');
            // When scrolling through features in slick overlay, pause HTML5 and reset Youtube videos
            $scope.$broadcast('uconnect-feature-overlay:stop-video', featureCodeSelected);
        });

        $slickSlider.on('swipe', function(event, slick, direction) {
            //Add analytics on the slick swipe
            const currentSlide = $slickSlider.slick('slickCurrentSlide');
            const featureCodeSelected = $(slick.$slides[currentSlide]).data('feature-code');
            $ctrl.sendGtmTrackEvent($ctrl.analyticsId ? $ctrl.analyticsId : 'uconnect-key-feature-slide', featureCodeSelected, direction);
        });

        $ctrl.sendGtmTrackEvent = (id, value, dir) => {
            let direction = '';
            if (dir === 'left') {
                direction = 'forward';
            } else if (dir === 'right') {
                direction = 'backward';
            }

            gtmAnalytics.trackEvent('event', {
                category: 'Uconnect-vlp',
                label: `${id}-${value}-${direction}`
            });
        };
        /*
        * We need to initialize slick in a $postLink in a $$postDigest because
        * at first adding responsive properties in the data-slick directly in the DOM did not work, so we
        * needed to set the slick settings in JS to have a responsive Slider and and since the dom is not completely
        * loaded yet and even without the $$postDigest the Dom wasn't completely loaded yet, therefore the only solution
        * was to throw all that code in a $$postDigest in a $postLink
        * */
        $ctrl.$postLink = () => {
            $scope.$$postDigest(function () {
                $slickSlider.slick({
                    initOnLoad: true,
                    mobileFirst: true,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "30px",
                    data: $ctrl.featureList,
                    slidesToShow: 1,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                centerMode: true,
                                centerPadding: "40px"
                            }
                        },
                        {
                            breakpoint: 1425,
                            settings: {
                                centerMode: true,
                                centerPadding: "100px"
                            }
                        }
                    ]
                })
            });
            $('#C_UCFO').on('click', event => {
                event.preventDefault();
                if (event.target.id === 'C_UCFO' || event.target.id === 'C_UCFO-container') {
                    $scope.$apply(() => {
                        $ctrl.closeFeatureOverlay();
                    });
                }
            });
            $ctrl.cleanCarousel();
        };

        $ctrl.cleanCarousel = () => {
            cleanCarousel.makeAccessible($slickSlider);
        };

        $ctrl.closeFeatureOverlay = () => {
            $ctrl.featureOverlayIsVisible = !$ctrl.featureOverlayIsVisible;
            $scope.$broadcast('uconnect-feature-overlay:stop-video', $ctrl.featureCodeSelected);
            $('.VLP2021-uconnect-link').attr('tabindex', '0');
        };

        $ctrl.checkIfVideoThumbnail = url => {
            return url ? url : '';
        };

    }
})();
