(function () {
	angular
		.module('fca.buyOnlineAccessoriesReview')
		.directive('fcaBuyOnlineAccessoriesReview', FcaBuyOnlineAccessoriesReview);

	function FcaBuyOnlineAccessoriesReview() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				vin: '@'
			},
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineAccessoriesReviewCtrl
		};

		function FcaBuyOnlineAccessoriesReviewCtrl($scope, $rootScope, externalConfigLoader, userLocation, fcaBuyOnlineService) {
			'ngInject';

			let $ctrl = this;

			$ctrl.$onInit = () => {
				// initialize configuration
				$ctrl.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
				$ctrl.language = $ctrl.config.getConfig('language');
				let checkout = fcaBuyOnlineService.getFromStorage($ctrl.vin);
				$ctrl.selectedAccessories = checkout.selectedAccessories;
				// make sure we have at lease one accessory selected
				$ctrl.hasSelectedAccessories = $ctrl.selectedAccessories
					&& Object
						.values($ctrl.selectedAccessories)
						.findIndex(val => val === true) !== -1;
			};

			$ctrl.isAccessorySelected = (code) => {
				let selected = false;
				if ($ctrl.selectedAccessories) {
					let value = $ctrl.selectedAccessories[code];
					selected = value;
				}
				return selected;
			};

			$rootScope.$on('fca-custom-order:custom-order-accessories-review-update', () => {
				$ctrl.$onInit();
			});
		}
	}
})();
