(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaStackedScrolling', fcaStackedScrollingController);

		function fcaStackedScrollingController() {
			return {
				restrict: 'A',
				scope: true,
				bindToController: {
					mainNavSelector: '@',
					subNavSelector: '@',
					mainContentSelector: '@',
					groupSelectorPrefix: '@'
				},
				controllerAs: '$ctrl',
				controller: FcaStackedScrollingController,
			};
	
			function FcaStackedScrollingController(
				$rootScope,
				$element,
				$window,
				$location,
				$anchorScroll,
				$timeout,
				matchmedia,
				FCA_MQ_LAYOUT,
				functionExecutionControl
			) {
				'ngInject';
	
				const $ctrl = this;
	
		
				/* eslint max-len: ["error", 150] */

				let isInitialized = false;
				let isDesktop = false;
				let canCalculateHeight = false;
				let hashValue;

				this.$onInit = () => {
					hashValue = $location.hash();

					if (hashValue) {
						$anchorScroll();

						angular.element(document).ready(() => {
							this.goToActiveHashTarget();
						});
					}

					$timeout(() => {
						$element.addClass('init-ended');
					});
				};

				/**
				 * Detect the hash active in the URL to make sure the browser
				 * will scroll to the anchor div
				 */
				let hashTargetDiv;
				let offsetHashTargetDiv;
				let heightMiniNavBar;
				let hashTabTriggered = false;

				this.goToActiveHashTarget = () => {
					hashTargetDiv = $('#' + hashValue);

					if(!hashTabTriggered && hashTargetDiv.hasClass('tabs-bar-item')) {
						hashTabTriggered = true;
						hashTargetDiv.trigger('click');
					}

					offsetHashTargetDiv = hashTargetDiv.offset().top;
					heightMiniNavBar = $('[data-fca-sticky]').height();

					$timeout(() => {
						$window.scrollTo(0, (offsetHashTargetDiv - heightMiniNavBar));
					});
				};

				/**
				 * Checks that it is desktop and that not already initialized
				 *
				 * Note:
				 * Scenario 1) if the user starts off in desktop mode and resizes the window to smaller then the animation is initialized
				 * (i.e. DOM manipulation happened) but stacking will be turned off (for real tablets and phone the animation never gets added)
				 * Scenario 2) if the user starts with a small screen and resizes to a desktop size only then will the animation be initialized
				 */
				this.checkCanInitialize = () => {
					let canInitialize = !isInitialized && isDesktop && canCalculateHeight;
					return canInitialize;
				};


				this.$postLink = () => {
					// Warning this needs to wait for the pano carousel to initialize ("slickCarousel.panoActive" event)
					$rootScope.$on('slickCarousel.panoActive', (event, data) => {
						if (data === true) {
							canCalculateHeight = true;
						}

						angular.element(document).ready(() => {
							if (this.checkCanInitialize()) {
								this.initializeStackedScrolling();
							}
						});
					});

					matchmedia.on(FCA_MQ_LAYOUT.DESKTOP, (mediaQueryList) => {
						isDesktop = (mediaQueryList.matches) ? true : false;

						angular.element(document).ready(() => {
							if (this.checkCanInitialize()) {
								this.initializeStackedScrolling();
							}
						});
					});
				};

				this.initializeStackedScrolling = () => {
					let subNavSelector = `[data-group-id="${this.subNavSelector}"]`;
					let groupSelector = `[data-group-id^="${this.groupSelectorPrefix}-"]`;

					let $mainNav = $(`.${this.mainNavSelector}`).eq(0);
					let $subNav = $(subNavSelector).eq(0);
					let $mainContent = $(`.${this.mainContentSelector}`).eq(0);

					// Add classes needed for binding
					$mainNav.addClass('stacked-module stacked-header');
					$mainContent.addClass('stacked-module');
					$subNav.addClass('stacked-module');

					// Sort modules by groups
					let $stackingGroups = $(`${groupSelector}:not(${subNavSelector})`);
					let stackingOrder = 0;
					let groupedModules = [];

					$stackingGroups.each((index, elem) => {
						let $elem = $(elem);
						let groupName = $elem.data('groupId').replace(groupSelector, ''); // change waypoint group id data attribute
						let $groupWrapper = $(`#${groupName}`);

						if ($groupWrapper.length === 0) {
							$elem.wrap(`<div id="${groupName}" class="stacked-module"><div class="stacked-module-inner"></div></div>`); // eslint-disable-line max-len

							let $groupWrapper = $(`#${groupName}`);

							$groupWrapper.css('z-index', stackingOrder);
							stackingOrder++;

							// Move the group outside (moves from inside .stacked-content-main-stack div to right before)
							$mainContent.before($groupWrapper);

							// Keep reference to groupe
							groupedModules.push($groupWrapper);
						} else {
							$elem.appendTo($groupWrapper.find('.stacked-module-inner'));
						}
					});

					// Dynamically calculate height of each grouped module
					calculateGroupHeights(); // this causes fcaWaypoint.reached event to be fired

					// Recalculate in case page had not fully loaded yet
					angular.element($window).on('load', () => {
						calculateGroupHeights();
					});

					// Move nav into place (moves from inside .stacked-content-main-stack div to right before)
					$mainContent.before($subNav);

					// Fix z-index of remaining modules
					calculateZIndexes();

					angular.element($window).on('resize', functionExecutionControl.throttle(() => {
						calculateGroupHeights();
						calculateZIndexes();
					}, 200));

					angular.element('.handraiser .btn-close').on('click', $element, (e) => {
						let $handraiser = angular.element('.handraiser-wrapper');

						if(angular.element(window).width() > 1023) {
							$handraiser.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(e) {
								calculateGroupHeights();
							});
						}
					});

					// Bind waypoint to each stacked module
					$(groupedModules).each((index, elem) => {
						new Waypoint({
							element: elem[0],
							handler: (direction) => {
								// do nothing unless it's a desktop
								if (!isDesktop) {
									$(elem).removeClass('is-stacked');
									return;
								}

								if (direction === 'down') {
									$(elem).addClass('is-stacked');
								} else {
									$(elem).removeClass('is-stacked');
								}
							}
						});
					});

					isInitialized = true;

					$rootScope.$emit('stackedIsInitialized');

					function calculateGroupHeights() {
						if (isDesktop && canCalculateHeight) {
							$(groupedModules).each((index, elem) => {
								// Only reset height if the group is not stacked (due to position fixed)
								if (!$(elem).hasClass('is-stacked')) {
									// Flush previous height
									$(elem).css('height', 'auto');

									let currentHeight = $(elem).height();
									$(elem).css('height', currentHeight);
								}
							});
						} else {
							$(groupedModules).each((index, elem) => {
								$(elem).css('height', 'auto');
								$(elem).css('min-height', 'auto');
							});
						}
					}

					function calculateZIndexes() {
						if (isDesktop) {
							$mainNav.css('z-index', 100);
							$subNav.css('z-index', 98);
							$mainContent.css('z-index', stackingOrder);
						} else {
							$mainNav.css('z-index', '');
							$subNav.css('z-index', '');
							$mainContent.css('z-index', '');
						}
					}
				};
			}
		}
})();
