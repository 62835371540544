(function () {
	angular
		.module('alfaromeo.buildAndPrice.summary')
		.component('alfaFooter', {
			controller: alfaFooter,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/summary/alfaromeo-summary-footer/alfaromeo-summary-footer.html',
			bindings: {
				scratchsave: '@',
				contactDealerUrl: '@',
				searchInventoryUrl: '@',
				currentOffersUrl: '@',
				contactDealerDesktop: '@',
				contactDealerMobile: '@',
				findDealerDesktop: '@',
				findDealerMobile: '@',
				searchInventoryDesktop: '@',
				searchInventoryMobile: '@',
				brand: '@',
				nameplate: '@',
				year: '@',
				modelYearId: '@',
				acode: '<',
				vehicleName: '@',
				customOrderActivated: '@',
				hasCustomOrderSni: '@',
				hasCustomOderParam: '@',
				trimName: '@',
				trimNameEn: '@',
				trimNameFr: '@',
				optionsCodes: '<',
				package: '<',
				engineIcon: '@',
				transmissionIcon: '@',
				provinceCode: '@',
			}
		});

	function alfaFooter($location, $scope, $rootScope, $translate, socialShare, configService, fcaUserIdPersistentService, $filter, $http) {
		'ngInject';

		const $ctrl = this;

		$ctrl.language = window.FCA_SITES_CONFIG.language;
		$ctrl.queryParams = '';

		const desktopMediaQuery = "(min-width: 768px)";
		$ctrl.isPostLoading = false;

		$ctrl.$onInit = () => {
			$ctrl.queryParams = addQueryParametersToUrl($ctrl.queryParams);
			$ctrl.hashParameters = getHashParameters();
			$ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';

			storeAffiliateId();
			$ctrl.affiliateMode = isAffiliateMode();
			$ctrl.displaySNIMode();
			$ctrl.displayAffiliateMode();

			$scope.$on('calculator:update-payment', (_, data) => {
				$ctrl.calculatorData = data
			});

			$scope.$on('calculator:get-current-icentives-total', (_, data) => {
                $ctrl.totalIncentives = data;
            });

			$scope.$on('jelly:exterior-jelly', (_, data) => {
				$ctrl.jellyPath = data;
			})
		}

		$ctrl.$postLink = () => {
			$ctrl.initStickySummary();
		}

		$ctrl.initStickySummary = () => {
			new Waypoint.Sticky({
				element: $('.js-C_SB-holder')[0],
				direction: 'up',
				offset: 'bottom-in-view'
			});

			let refreshWaypointTimer = window.setInterval(() => {
				Waypoint.refreshAll();
			}, 500);
			$rootScope.$broadcast('cookie-sticky-init');
		};

		let desktopMode = null;
		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.findADealerCTA = () => {
			return `/${$ctrl.language}/dealers`;
		}


		$ctrl.onPrintClick = () => {
			window.print();
		}

		$ctrl.getSniImage = () => {
			return $ctrl.isDesktop() ? $ctrl.searchInventoryDesktop : $ctrl.searchInventoryMobile;
		}

		$ctrl.getFindDealerImage = () => {
			return $ctrl.isDesktop() ? $ctrl.findDealerDesktop : $ctrl.findDealerMobile;
		}

		$ctrl.getContactDealerImage = () => {
			return $ctrl.isDesktop() ? $ctrl.contactDealerDesktop : $ctrl.contactDealerMobile;
		}


		$ctrl.backgroundImageStyle = (imageUrl, alignment = center, size = '') => {
			return `{ 'background': 'url(${imageUrl}) ${cover} no-repeat scroll ${alignment} top transparent' }`;
		}

		$ctrl.facebookShare = () => {
			const text = encodeURI($translate.instant('buildAndPrice.facebook-share', {
				nameplate: $ctrl.vehicleName
			}));
			const hashtag = encodeURIComponent($translate.instant('buildAndPrice.facebook-hashtag'));
			return socialShare.facebookShare(text, hashtag, {
				scratchsave: LZString.compressToEncodedURIComponent($ctrl.scratchsave)
			});
		}

		$ctrl.twitterShare = () => {
			const text = encodeURI($translate.instant('buildAndPrice.twitter-share', {
				nameplate: $ctrl.vehicleName
			}));
			const hashtag = encodeURIComponent($translate.instant('buildAndPrice.twitter-hashtags'));
			return socialShare.twitterShare(text, [hashtag], {
				scratchsave: LZString.compressToEncodedURIComponent($ctrl.scratchsave)
			});
		}

		$ctrl.emailShare = () => {
			const subject = $translate.instant('buildAndPrice.email-subject', {
				nameplate: $ctrl.vehicleName
			});


			const body = $translate.instant('buildAndPrice.email-body', {
				nameplate: $ctrl.vehicleName
			});

			return socialShare.emailShare(subject, body, {
				scratchsave: LZString.compressToEncodedURIComponent($ctrl.scratchsave)
			});
		}

		$ctrl.displaySNIMode = () => {
			const hasCustomOrderParam = getCustomOrderParam() ? true : false;
            if (!$ctrl.inIframeMode && $ctrl.hasCustomOrderSni === 'true' && hasCustomOrderParam === true && !$ctrl.affiliateMode) {
                $ctrl.showSNIMode = true;
            } else {
                $ctrl.showSNIMode = false;
            }
        }

        $ctrl.displayAffiliateMode = () => {
            if (!$ctrl.inIframeMode && $ctrl.customOrderActivated === 'true' && $ctrl.affiliateMode) {
                $ctrl.showAffiliateMode = true;
            } else {
                $ctrl.showAffiliateMode = false;
            }
        }

		$ctrl.saveCustomOrder = () => {

			const affiliateId = getStoredAffiliateId()|| getAffiliateIdParam() || null;
            const affiliateEncryptedParam = getAffiliateParam('affiliateEncryptedParam');
            const affiliateEaProgram = getAffiliateParam('affiliateEaProgram');

            const exteriorColorUrl = $('#selected-exterior-color').attr('data-url') || null;
            const wheelColorUrl = $('#selected-wheel-color').attr('data-url') || null;

            const urlParams = new URLSearchParams(window.location.search);
            const urlBuildId = urlParams.get('buildId') || null;
            const fcaCurrency = $filter('fcaCurrency');

            const netAmount = fcaCurrency($ctrl.calculatorData.cashNetAmount).replace(' ', '');
			const incentives = fcaCurrency($ctrl.calculatorData.totalDiscount).replace(' ', '');

            const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;

			const pricing = pricingCustomOrder(
				configService.convertPriceInNumber(netAmount),
				$ctrl.calculatorData.financeMode,
				configService.convertPriceInNumber($ctrl.calculatorData.financeTerm) || null,
				configService.convertPriceInNumber($ctrl.calculatorData.financeRate) || null,
				$ctrl.calculatorData.frequency,
				configService.convertPriceInNumber($ctrl.calculatorData.leaseTerm) || null,
				configService.convertPriceInNumber($ctrl.calculatorData.leaseInterestRate)|| null,
				$ctrl.calculatorData.frequency,
				$ctrl.calculatorData.downPayment,
				$ctrl.calculatorData.tradeInValue,
				$ctrl.calculatorData.tradeOwed,
				$ctrl.calculatorData.options,
				configService.convertPriceInNumber(incentives),
			);

			const payload = payloadCustomOrder(
				$ctrl.provinceCode,
				$ctrl.year,
				$ctrl.brand,
				$ctrl.nameplate,
				$ctrl.language,
				affiliateId,
				affiliateEncryptedParam,
				affiliateEaProgram,
				$ctrl.campaignCode || null,
				$ctrl.trimName,
				$ctrl.trimNameEn,
				$ctrl.trimNameFr,
				$ctrl.acode,
				$ctrl.modelYearId,
				null,
				persistentUid,
				$ctrl.package.code,
				$ctrl.scratchsave,
				$ctrl.jellyPath,
				wheelColorUrl,
				exteriorColorUrl,
				$ctrl.engineIcon || null,
				$ctrl.transmissionIcon || null,
				$ctrl.optionsCodes.toString(),
				null,
				urlBuildId,
				pricing
			);

			$ctrl.isPostLoading = true;

			$http.post(saveCustomOrderAPI, payload).then(response => {
				const buildId = response.data.buildId;

				$ctrl.isPostLoading = false;
				redirectToDealersPage($ctrl.language, buildId, persistentUid);
			}).catch(function (e) {
				$ctrl.isPostLoading = false;
				throw new Error('error in saving custom order', e);
			});
		};
	}
})()
