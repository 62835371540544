(function() {
    angular
        .module('fca.newInventory.addToFavourites')
        .component('newInventoryAddToFavourites', {
            controller: NewInventoryAddToFavourites,
            controllerAs: '$ctrl',
            templateUrl: '/new-inventory/add-to-favourites/add-to-favourites.html',
            bindings: {
                brand: "@",
                nameplate: "@",
                lang: "@",
                vin: "@",
                year: "@",
                nameplateCode: "@",
                trimGroupEn: "@",
                trimGroupFr: "@",
                dealerCode: "@",
                modelYearId: "@",
                netAmount: "@",
                financingTerm: "@",
                financingRate: "@",
                financingPayment: "@",
                financingFrequency: "@",
                leasingTerm: "@",
                leasingRate: "@",
                leasingPayment: "@",
                leasingFrequency: "@",
                dealerPrice: "@",
                analyticsCategory: '@',
                analyticsId: '@',
                tileNumber: '@',
                loadUaLibrary: '@'
            },
        });

    function NewInventoryAddToFavourites($scope, $rootScope, $element) {
        'ngInject';

        const $ctrl = this;

        $ctrl.favoriteButton = null;
        $ctrl.isUALibraryAvailable = false;
        $ctrl.addedToFavorites = false;

        $ctrl.$onInit = () => {
            $ctrl.favoriteButton = $($element.find('.C_NID-add-to-favourites-container'));

            $rootScope.$on('ua:libraryInitialized', () => {

                UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                    const currentVehicle = {
                        vin: $ctrl.vin.toUpperCase(),
                        brandCode: $ctrl.brand,
                        nameplateCode: $ctrl.nameplate,
                        modelYearId: $ctrl.modelYearId
                    };

                    if (UA.isUserLoggedIn()) {
                        UA.addViewedVehicle(currentVehicle);

                        //handle favorite button
                        UA.getInventoryVehicles().then((vehicles) => {
                            const favoriteVehicle = vehicles.find((favorite) => favorite.vin === $ctrl.vin);
                            if (favoriteVehicle !== undefined) {
                                $ctrl.favoriteButton.addClass('C_NID-favorite');
                                $ctrl.favoriteButton.data('favorited', true);
                                $ctrl.favoriteButton.data('favoriteId', favoriteVehicle.id);
                            }
                        });
                    } else {
                        UA.addViewedVehicleToLocalStorage(currentVehicle);
                    }
                });
            });

            $ctrl.subBrandCode = $ctrl.brand;
        };

        $ctrl.$postLink = () => {
            if ($ctrl.loadUaLibrary) {
                UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                    const currentVehicle = {
                        vin: $ctrl.vin.toUpperCase(),
                        brandCode: $ctrl.brand,
                        nameplateCode: $ctrl.nameplate,
                        modelYearId: $ctrl.modelYearId
                    };

                    if (UA.isUserLoggedIn()) {
                        UA.addViewedVehicle(currentVehicle);

                        //handle favorite button
                        UA.getInventoryVehicles().then((vehicles) => {
                            const favoriteVehicle = vehicles.find((favorite) => favorite.vin === $ctrl.vin);
                            if (favoriteVehicle !== undefined) {
                                $ctrl.favoriteButton.addClass('C_NID-favorite');
                                $ctrl.favoriteButton.data('favorited', true);
                                $ctrl.favoriteButton.data('favoriteId', favoriteVehicle.id);
                            }
                        });
                    } else {
                        UA.addViewedVehicleToLocalStorage(currentVehicle);
                    }
                });
            }
        };

        $ctrl.checkFavorites = () => {
            if(!UA.isUserLoggedIn()) {

                $ctrl.addedToFavorites = true;

            } else {

                if ($ctrl.favoriteButton.data('favorited') === true) {
                    //backend call to remove favorite
                    let favoriteId = $ctrl.favoriteButton.data('favoriteId');
                    UA.removeInventoryVehicle(favoriteId).then(() => {
                        $ctrl.favoriteButton.removeClass('C_NID-favorite');
                        $ctrl.favoriteButton.data('favorited', false);
                    }).catch(fallback => {
                        console.error(fallback);
                    });
                } else {
                    addToFavorites();
                }

            }
        };

        $scope.$on('ua:userSignInSuccess', () => {
            UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                if ($ctrl.addedToFavorites) {
                    addToFavorites();
                }
                $ctrl.isClicked = false;
            });
        });

        let addToFavorites = () => {
            //backend call to add favorite
            UA.addInventoryVehicle({
                vin: $ctrl.vin,
                brandCode: $ctrl.brand,
                subBrandCode: $ctrl.subBrandCode,
                year: $ctrl.year,
                nameplateCode: $ctrl.nameplateCode,
                trimGroupEn: $ctrl.trimGroupEn,
                trimGroupFr: $ctrl.trimGroupFr,
                dealerCode: $ctrl.dealerCode,
                modelYearId: $ctrl.modelYearId,
                pricingInfo: {
                    netAmount: $ctrl.netAmount,
                    financeTerm: $ctrl.financingTerm,
                    financeRate: $ctrl.financingRate,
                    financePayment: $ctrl.financingPayment,
                    financePaymentFrequency: $ctrl.financingFrequency,
                    leaseTerm: $ctrl.leasingTerm,
                    leaseRate: $ctrl.leasingRate,
                    leasePayment: $ctrl.leasingPayment,
                    leasePaymentFrequency: $ctrl.leasingFrequency,
                    dealerPrice: $ctrl.dealerPrice
                }
            }).then((response) => {
                $ctrl.favoriteButton.addClass('C_NID-favorite');
                $ctrl.favoriteButton.data('favorited', true);
                $ctrl.favoriteButton.data('favoriteId', response.id);
            }).catch(fallback => {
                console.error(fallback);
            });
        }
    }
})(angular);
