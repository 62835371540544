(function () {
    angular
        .module('alfaromeo.buildAndPrice.icons')
        .component('alfaIconSelected', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-selected-icon.html',
        })
        .component('alfaIconAdd', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-add-icon.html',
        })
        .component('alfaIconDisabled', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-disabled-icon.html',
        })
        .component('alfaIconSpeaker', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-speaker-icon.html',
        })
        .component('alfaIconRotateLeftMobile', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-rotate-left-mobile-icon.html',
        })
        .component('alfaIconRotateRightMobile', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-rotate-right-mobile-icon.html',
        })
        .component('alfaIconRotateLeftDesktop', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-rotate-left-desktop-icon.html',
        })
        .component('alfaIconRotateRightDesktop', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-rotate-right-desktop-icon.html',
        })
        .component('alfaIconPause', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-pause-icon.html',
        })
        .component('alfaIconPlayCircle', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-play-circle-icon.html',
        })
        .component('alfaIconPlay', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-play-icon.html',
        })
		.component('alfaIconDownload', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-download-icon.html',
        })
		.component('alfaIconEnvelope', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-envelope-icon.html',
        })
		.component('alfaIconFacebook', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-facebook-icon.html',
        })
		.component('alfaIconLinkedin', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-linkedin-icon.html',
        })
		.component('alfaIconTwitter', {
            templateUrl: '/brand-specific/alfaromeo/icons/alfaromeo-twitter-icon.html',
        });
})();
