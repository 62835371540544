(function () {
    angular
        .module('fca.dealerAppointment', ['ngMessages'])
        .directive('fcaDealerAppointment', FcaDealerAppointment);

    function FcaDealerAppointment() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                apiUrl: '@',
                location: "<",
                languageLink: '@',
                isBuyOnline: '<?',
            },
            controllerAs: '$ctrl',
            controller: FcaDealerAppointmentController
        };

        function FcaDealerAppointmentController($scope, $rootScope, $http,
                                                $location, $timeout, $element, $sce, fcaScrollToElementService,
                                                gtmAnalytics, matchmedia, FCA_MQ_LAYOUT, fcaSalesAppointmentService,$filter) {
            'ngInject';

            const $ctrl = this;

            $ctrl.provinceList = FCA_SITES_CONFIG.location;
            $ctrl.brandCode = FCA_SITES_CONFIG.name;
            $ctrl.language = FCA_SITES_CONFIG.language;

            $ctrl.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            $ctrl.phoneRegex = /^\(?([0-9]{3})\)?[. ]?([0-9]{3})[ -. ]?([0-9]{4})$/;
            $ctrl.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

            $ctrl.formErrors = [];

            $ctrl.errorMsgVisible = false;

            $ctrl.showDealerSelection = true;
            $ctrl.showAppointmentSchedule = false;
            $ctrl.showInformationForm = false;
            $ctrl.showThankYou = false;

            $ctrl.formErrorMsg = null;
            $ctrl.appointmentErrorMsg = null;

            $ctrl.currentStep = 0;

            $ctrl.dealerSelected = null;
            $ctrl.dealerSelectedObj = null;

            $ctrl.appointmentDate = "";
            $ctrl.appointmentTime = "";
            $ctrl.leadId = "";
            $ctrl.formIsLoading = false;

            $ctrl.lead = {
                postType: 'appointment',
                leadType: 'appointment',
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                contactMethod: '',
                customerComments: '',
                dealerId : '',
                testDrive : '',
                year: '',
                brand: '',
                nameplate: '',
                model: '',
                language: $ctrl.language,
                postalCode: ''
            };


            $ctrl.$onInit = () => {
                fcaSalesAppointmentService.registerAppointmentListener($ctrl.selectAppointment);

                $rootScope.$on('form-dealer-selector:appointment-dealer-selected',
                    (evt, args) => {
                        $ctrl.dealerSelected = args.dealer;
                        $ctrl.getDealerInfo();
                    });

                $rootScope.$on('sales-appointment-dealer:dealer-selected',
                    (evt, args) => {
                        $ctrl.dealerSelected = args.dealer;
                        $ctrl.getDealerInfo();
                });
            };

            $rootScope.$on('form-dealer-selector:appointment-selected',
                () => {
                    $ctrl.goStep3();
                });

            $rootScope.$on('form-vehicle-selector:nameplate-selected',
                (event, data) => {
                    $ctrl.selectedNameplate = data.nameplate;
                    $ctrl.selectedYear = data.year;
                    $ctrl.selectedNameplateName = data.nameplateName;
                    $ctrl.selectedBrandName = data.brandName;
                    $ctrl.brandCode = data.brandCode;
                    $ctrl.selectedTrim = '';
                });
            $rootScope.$on('form-vehicle-selector:trim-selected',
                (event, data) => {
                    $ctrl.selectedTrim = data.trim;
                    $ctrl.selectedModelYearId = data.modelYearId;
                    $ctrl.selectedAcode = data.acode;
                    $ctrl.basePriceAndOptions = data.basePrice;
                    $ctrl.packageAndOptionsCode = data.packageAndOptionsCode;
                    $ctrl.otherFees = data.otherFees;
                    $ctrl.incentivesTotal = data.incentives;
                    $ctrl.visibleCalculator = true;
                    $ctrl.pricing = data.pricing;
                });

            $rootScope.$on('form-dealer-appointment:submit', () => {
                $ctrl.confirmAppointment();
            });

            $ctrl.getDealerInfo = () => {
                if($ctrl.dealerSelected && $ctrl.brandCode) {
                    $http.get(`/data/dealers/${$ctrl.brandCode}/dealer/${ $ctrl.dealerSelected}`).then((result) => {
                        if (result.data && result.status == 200) {
                            $ctrl.dealerSelectedObj = result.data;
                            $ctrl.goStep2();
                        } else {
                            console.error(" Error while retrieving dealer object in dealer appointment component.");
                        }
                    });
                }
            };

            $ctrl.goStep1 = () => {
                $ctrl.showDealerSelection = true;
                $ctrl.showAppointmentSchedule = false;
                $ctrl.showInformationForm = false;
                $ctrl.showThankYou = false;
                $ctrl.currentStep = 0;
            };

            $ctrl.goStep2 = () => {
                $ctrl.showDealerSelection = false;
                $ctrl.showAppointmentSchedule = true;
                $ctrl.showInformationForm = false;
                $ctrl.showThankYou = false;
                $ctrl.currentStep = 1;
                $ctrl.appointmentErrorMsg = null;
            };

            $ctrl.goStep3 = () => {
                $ctrl.showDealerSelection = false;
                $ctrl.showAppointmentSchedule = false;
                $ctrl.showInformationForm = true;
                $ctrl.showThankYou = false;
                $ctrl.currentStep = 2;
            };

            $ctrl.goThankYou = () => {
                $timeout(() => {
                    $ctrl.showDealerSelection = false;
                    $ctrl.showAppointmentSchedule = false;
                    $ctrl.showInformationForm = false;
                    $ctrl.showThankYou = true;
                    $ctrl.sendAnalytics('Request Submitted');
                });
            };

            $ctrl.loaded = () => {
                $('.C_DA-loader-container').addClass('invisible');
            };

            $ctrl.loading = () => {
                $('.C_DA-loader-container').removeClass('invisible');
            };

            $ctrl.setFormData = (form, data) => {
                for (let key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        $ctrl.setFormValue(form, key, data[key]);
                    }
                }
            };

            $ctrl.setFormValue = (form, key, value) => {
                let attribute = form[key];
                if (!attribute) {
                    attribute = document.createElement('input');
                    attribute.type = 'hidden';
                    attribute.name = key;
                    form.appendChild(attribute);
                }
                attribute.value = value;
            };

            $ctrl.isModifierKey = (event) => {
                const key = event.keyCode;
                return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
                    (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
                    (key > 36 && key < 41) || // Allow left, up, right, down
                    (
                        // Allow Ctrl/Command + A,C,V,X,Z
                        (event.ctrlKey === true || event.metaKey === true) &&
                        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
                    )
            };

            $ctrl.enforceFormat = (event) => {
                if (!$ctrl.isNumericInput(event) && !$ctrl.isModifierKey(event)) {
                    event.preventDefault();
                }
            };

            $ctrl.formatPhoneNumber = (event) => {
                if ($ctrl.isModifierKey(event)) {
                    return;
                }

                let target = event.target;
                const input = event.target.value.replace(/\D/g, '').substring(0, 10);
                const zip = input.substring(0, 3);
                const middle = input.substring(3, 6);
                const last = input.substring(6, 10);

                if (input.length > 6) {
                    target.value = `(${zip}) ${middle}-${last}`;
                } else if (input.length > 3) {
                    target.value = `(${zip}) ${middle}`;
                } else if (input.length > 0) {
                    target.value = `(${zip}`;
                }
            };

            /**
             *  alert the sales appointment component to post the appointment
             */
            $ctrl.confirmAppointment = () => {

                $rootScope.$broadcast('fca-contact-a-dealer:appointment-confirmation', {
                    brandCode: $ctrl.brandCode,
                    dealerCode: $ctrl.dealerSelected,
                    leadId: $ctrl.leadId,
                    date: $ctrl.appointmentDate,
                    time: $ctrl.appointmentTime,
                    testDrive: $ctrl.testDriveBooked()

                });

                $(".dealerFormErrors").html('');
                $ctrl.formErrorMsg = null;
                $ctrl.errorMsgVisible = false;
            };

            $ctrl.getUrlThankYou = () => {
                let url = '';
                const newInventoryUrl = $ctrl.language === 'fr' ? 'vehicules-neufs/concessionnaire' : 'new-inventory/dealer';
                const dealersUrl = 'dealers';
                const paymentParam = '#paymentMode=cash';
                if ($ctrl.selectedNameplate) { 
                    url = `${newInventoryUrl}/${$ctrl.dealerSelected}/${$ctrl.brandCode}/${$ctrl.selectedNameplate}${paymentParam}`;
                } else {
                    url = `${dealersUrl}/${$ctrl.dealerSelected}${paymentParam}`;
                }
                return url;
            };

            $rootScope.$on('fca-contact-a-dealer:appointment-is-confirmed', () => {
                $ctrl.goThankYou();
            });

            $rootScope.$on('fca-contact-a-dealer:appointment-not-confirmed', (event, data) => {
                let response = data.response;

                if(!response.success && response.message) {
                    $timeout(() => {
                        $ctrl.appointmentErrorMsg = response.message;
                    });
                }
            });

            $rootScope.$on('fca-buy-online:submit-form',
                (event, data) => {
                    $ctrl.leadId = data.leadId
                    $ctrl.confirmAppointment();
                });

            $ctrl.postLead = () => {
                let commentsFr = "";
                let commentsEn = "";
                let appointmentString = $ctrl.appointmentDate + " "
                    + $ctrl.appointmentTime;
                let dateTimeString = "";

                $ctrl.formIsLoading = true;

                if($ctrl.language === 'en') {
                    if($ctrl.lead.testDrive !== "") {
                        commentsEn +=
                            `The customer ${$ctrl.testDriveBooked() ? "has requested" : " is not interested in"} a test drive.\n`;
                    }
                    commentsEn +=
                        `The customer has requested an appointment for ${appointmentString}.\n`;

                    if($ctrl.lead.customerComments !== "") {
                        commentsEn +=
                            `The customer's comments: ${$ctrl.lead.customerComments}\n`;
                    }

                } else {
                    dateTimeString = appointmentString;

                    if($ctrl.lead.testDrive !== "") {
                        commentsFr +=
                            `Le client ${$ctrl.lead.testDrive==="yes" ? "est interessé" : "n'est pas interessé"} par un essai routier.\n`
                    }

                    commentsFr +=
                        `Le client a reservé un rendez-vous pour ${dateTimeString}\n`;

                    if($ctrl.lead.customerComments !== "") {
                        commentsFr +=
                            `Les commentaires du client: ${$ctrl.lead.customerComments}\n`;
                    }
                }

                const leadObject = {
                    postType:  $ctrl.testDriveBooked() ? "book_a_test_drive" : $ctrl.lead.postType,
                    leadType:  $ctrl.lead.leadType,
                    firstname:  $ctrl.lead.firstname,
                    lastname:  $ctrl.lead.lastname,
                    email:  $ctrl.lead.email,
                    phone:  $ctrl.lead.phone,
                    contactMethod:  $ctrl.lead.contactMethod,
                    customerComments: $ctrl.language ==='en'? commentsEn :commentsFr,
                    dealerId : $ctrl.dealerSelected ,
                    testDrive :  $ctrl.lead.testDrive === "yes",
                    year:  $ctrl.selectedYear,
                    trimDescription: $ctrl.selectedTrim,
                    brand:  $ctrl.lead.brand,
                    nameplateCode:  $ctrl.selectedNameplate,
                    model:  $ctrl.lead.model,
                    language:  $ctrl.lead.language,
                    dealerIds :$ctrl.dealerSelected,
                    postalCode :$ctrl.lead.postalCode
                };

                $.post(`/api/lead/${$ctrl.language}/brand/${$ctrl.brandCode}`, leadObject, function (data) {

                    //TBD
                    if (data.success) {
                        $ctrl.formIsLoading = false;
                        $("#submit-button").removeClass('btn-spinner');
                        $ctrl.leadId = data.leadResponse.leadId;
                        $rootScope.$broadcast('form-dealer-appointment:submit');

                        $timeout(function () {
                            const formContainer = $element.find('[data-fca-sales-appointment]');

                            if(formContainer.length) {
                                $("html, body").animate(
                                    {scrollTop: $(formContainer[0]).offset().top}, 300);
                            }
                        });

                    } else {
                        $ctrl.formIsLoading = false;
                        $ctrl.formErrors = data.fieldErrors;
                        $ctrl.showErrorMessages();
                    }
                });
            };

            $ctrl.showErrorMessages = () => {
                let totalErrors = Object.keys($ctrl.formErrors).length;

                if ($ctrl.language === "fr") {
                    if (totalErrors === 1) {
                        $ctrl.formErrorMsg = 'Il y a ' + totalErrors + ' erreurs dans le formulaire présentement. Veuillez la corriger afin de continuer';
                    } else {
                        $ctrl.formErrorMsg = 'Il y a ' + totalErrors + ' erreur dans le formulaire présentement. Veuillez la corriger afin de continuer';
                    }
                } else {
                    if (totalErrors === 1) {
                        $ctrl.formErrorMsg = 'There is ' + totalErrors + ' error in your form. Please correct it before submitting.';
                    } else {
                        $ctrl.formErrorMsg = 'There are ' + totalErrors + ' errors in your form. Please correct them before submitting.';
                    }
                }
                $ctrl.errorMsgVisible = true;

                $timeout(function () {
                    const formContainer = $element.find('[data-dealer-appointment-form]');
                    const formErrorsContainer = $element.find('[data-dealer-appointment-form-errors]');

                    if(formErrorsContainer.length) {
                        formErrorsContainer[0].focus();
                    }

                    if(formContainer.length) {
                        $("html, body").animate(
                            {scrollTop: $(formContainer[0]).offset().top}, 300);
                    }
                });
            };

            $ctrl.submitLead = () => {
                $scope.submitted = true;

                $("#submit-button").addClass('btn-spinner');
                if($ctrl.leadId === "") {
                    $ctrl.postLead();
                } else {
                    $ctrl.confirmAppointment();
                }
            };

            $ctrl.sendAnalytics = (label) => {
                gtmAnalytics.trackEvent('event', {
                    category: 'App-Suite-dealer-appointment',
                    label: label
                });
            };
            
            $ctrl.$onChanges = () => {
                if ($ctrl.showThankYou) {
                    $ctrl.goThankYou();
                }
            };

            $ctrl.animateScrollOnBtnClick = () => {
                $("html, body").animate(
                    {scrollTop: $('.C_DA-form').offset().top}, 300);
            };

            /**
             *  get the date and time values  send by sales appointment component
             */
            $ctrl.selectAppointment = () => {

                let appointmentObject = fcaSalesAppointmentService.getFromStorage();
                $ctrl.appointmentDate = appointmentObject.date;
                $ctrl.appointmentTime = appointmentObject.time;
                $ctrl.localizedAppointmentDate = fcaSalesAppointmentService.localizeDate(appointmentObject.date);
                $ctrl.localizedAppointmentTime = fcaSalesAppointmentService.localizeHour(appointmentObject.time);

                if($ctrl.dealerSelected == null) {
                    if ($ctrl.dealerSelectedObj != null) {
                        $ctrl.dealerSelected = $ctrl.dealerSelectedObj.code;
                    }
                }
            };

            $ctrl.testDriveBooked = () => {
                return $ctrl.lead['testDrive'] === 'yes';
            };
        }
    }
})();
