/* eslint-disable indent */
(function(ng) {
	'use strict';

	/**
	 * @ngdoc service
	 * @name fca.gmap.fcaGmapLoaderProvider
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.gmap')
		.provider('fcaGmapLoader', function() {
			'ngInject';

			const ERROR_MSG = `
        		fcaGmapLoader exception: Google Map API Key is missing.
      		`;

			/**
			 * @ngdoc property
			 * @propertyOf fca.gmap.fcaGmapLoaderProvider
			 * @description [TODO]
			 * @type {Object}
			 */
			this.defaults = {
				mapApiKey: window.FCA_SITES_CONFIG.mapApiKey
			};

			/**
			 * @ngdoc method
			 * @name $get
			 * @methodOf fca.gmap.fcaGmapLoader
			 * @requires $window
			 * @requires $q
			 * @requires $log
			 * @description [TODO]
			 * @return {Object} AngularJS Promise
			 * @example
			 * <pre>[TODO]</pre>
			 */
			this.$get = ($window, $q, $log) => {
				'ngInject';

				/**
				 * @description [TODO]
				 * @type {Object} AngularJS deferred promise
				 */
				let deferred = $q.defer();

				/**
				 * @description [TODO]
				 * @type {Boolean}
				 */
				let isAppended = false;
				let language = window.FCA_SITES_CONFIG.language;


				/**
				 * @description [TODO]
				 * @type {Boolean}
				 */
				let apiKey = this.defaults.mapApiKey;
				// Check API Key config.
				if (apiKey === null || !ng.isString(apiKey)) {
					// Throw error is API
					$log.error(ERROR_MSG);
				}

				/**
				 * @description [TODO]
				 * @type {String}
				 */
				let callback = 'onGMapReady';

				return function() {
					/* Check if the shared promise is already resolved */
					if (!deferred.promise.$$state.status && !isAppended) {
						/**
						 * [description]
						 * @return {[type]} [description]
						 */
						$window.onGMapReady = () => {
							deferred.resolve($window.google);
						};

						// Inject script once
						let document = $window.document;
						let s = document.createElement('script');
						let uri = '//maps.googleapis.com/maps/api/js';
						s.src = `
            				${uri}?v=3&libraries=geometry&key=${apiKey}&callback=${callback}&language=${language}
            			`;

						document.body.appendChild(s);
						/* Set flag to true to avoid multiple injection */
						isAppended = true;
					}

					return deferred.promise;
				};
			};
		});
})(angular);
