(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamMosaic', FcaOnlyRamMosaic);
	
	function FcaOnlyRamMosaic() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@',
				paintCode: '@',
				paintImagePath: '@',
				paintVideoPath: '@',
				isCmsMosaic: '@'
			},
			controllerAs: '$fcaOnlyRamMosaicCtrl',
			controller: FcaOnlyRamMosaicCtrl
		};
		
		function FcaOnlyRamMosaicCtrl($element, $timeout, gtmAnalytics, functionExecutionControl, $rootScope) {
			const $ctrl = this;
			$ctrl.shuffleInstance = null;
			$ctrl.$mosaicDesktop = $('.OR-Mosaic');
			$ctrl.$mosaicDesktopItems = $('.OR-Mosaic-item');
			$ctrl.$mosaicMobile = $('.OR-MosaicMobile');
			$ctrl.$mosaicMobileItems = $('.OR-MosaicMobile-slider');
			$ctrl.$mosaicTriggers = $('.OR-MosaicBlock [data-overlay-trigger]');
			$ctrl.$mosaicOverlays = $('.OR-MosaicOverlay[data-overlay-id]');
			$ctrl.$mosaicVisibleOverlay = $('.OR-MosaicOverlay[data-overlay-id].isVisible');
			$ctrl.$mosaicOverlayCloseBtn = $('.OR-MosaicOverlay-closeBtn');

			$ctrl.$mosaicOverlayPrev = $('.OR-MosaicOverlay-footerBtn.-prev');
			$ctrl.$mosaicOverlayNext = $('.OR-MosaicOverlay-footerBtn.-next');

			$ctrl.scrollPaused = false;
			$ctrl.lastScroll = 0;
			$ctrl.arrayFeatures = [];
			$ctrl.indexCurrentFeatureModal = 0;
			let dialogRef;
			$ctrl.$onInit = () => {
				$ctrl.initFromHash();
				$ctrl.initArrayFeatures();
				$ctrl.initMosaicDesktop();

				// Init Videos
				$ctrl.$mosaicTriggers.find('.OR-Mosaic-itemVideo').hover( $ctrl.hoverVideo, $ctrl.hideVideo );
				$($ctrl.$mosaicTriggers.find('.OR-Mosaic-itemVideo').find('video')).each(function() {
					this.controls = false;
					$(this).get(0).pause();
				});

				$ctrl.$mosaicTriggers.on('click', $ctrl.openMosaicOverlay);
				$ctrl.$mosaicOverlayCloseBtn.on('click', $ctrl.closeMosaicOverlay);
				$ctrl.$mosaicOverlayPrev.on('click', $ctrl.getPrevMosaicOverlay);
				$ctrl.$mosaicOverlayNext.on('click', $ctrl.getNextMosaicOverlay);
				$ctrl.$mosaicOverlays.on('click', (e) => {
					$ctrl.checkOverlayClose(e);
				});

				$(window).on('resize', functionExecutionControl.debounce($ctrl.initMosaicDesktop, 250) );

				$(window).on('scroll', function () {
					if (!$ctrl.scrollPaused) {
						$ctrl.lastScroll = window.scrollY || window.pageYOffset;
					}
				});

				$timeout(() => {
					//Analytics
					let dots = $('.OR-MosaicMobile-slider .slick-dots button');
					dots.each(function (index, value) {
						value.setAttribute("data-analyticsid", "ram-fmb-feature-stories-ram-1500-dots");
						value.setAttribute("data-analyticsvalue", value.innerText);
					});

					let modelTrayDots = $('.model-tray-container .slick-dots button');
					modelTrayDots.each(function (index, value) {
						value.setAttribute("data-analyticsid", "ram-fmb-model-tray-ram-1500-dots");
						value.setAttribute("data-analyticsvalue", value.innerText);
					});

					let legalNoteAsterix = $('.OR-Mosaic-itemTitle .legal-tooltip-label');
					legalNoteAsterix.each(function (index, asterix) {
						let mosaicFeatureId = $(asterix).closest('.OR-Mosaic-item').attr("data-mosaic-feature-id");

						if (mosaicFeatureId && mosaicFeatureId !== '') {
							$(asterix).on('click', e => {
								$ctrl.sendGtmTrackEvent('ram-fmb-feature-stories', mosaicFeatureId + '-asterix');
							});
						}
					});

				}, 500);

				let rowToBeSlicked = $element.find(".C_MS-mosaic-mobile");
				if(rowToBeSlicked && $ctrl.isCmsMosaic === "true") {
					$(window).on('load resize orientationchange', function() {
						/* Initializes a slick carousel only on mobile screens */
						if ($(window).width() >= 768) {
							if (rowToBeSlicked.hasClass('slick-initialized')) {
								rowToBeSlicked.slick('unslick');
								$timeout(() => {
									rowToBeSlicked.slick('unslick');
								}, 400);
							}
						} else {
							if (!rowToBeSlicked.hasClass('slick-initialized')) {
								rowToBeSlicked.slick({
									slidesToShow: 1,
									slidesToScroll: 1,
									mobileFirst: true,
									dots: true,
									arrows: true,
									infinite: false,
								});
							}
							rowToBeSlicked.slick('refresh');
							$timeout(() => {
								rowToBeSlicked.slick('refresh');
							}, 400);
						}
					});
				}
			};

			$ctrl.checkOverlayClose = (event) => {
				if (!$(event.target).parents().hasClass('OR-MosaicOverlay-holder')) {
					$ctrl.closeMosaicOverlay();
				}
			};
			
			$ctrl.initFromHash = function () {
				let cleanUrl = window.location.hash.substring(1);
				let objUrl = $.deparam(cleanUrl, true);
				if(objUrl.first_feature_group !== undefined && objUrl.first_feature_group !== "" ) {
					$ctrl.$mosaicDesktopItems.filter( `[data-feature-group=${objUrl.first_feature_group}]` ).prependTo($ctrl.$mosaicDesktop);
					$ctrl.$mosaicMobileItems.filter( `[data-feature-group=${objUrl.first_feature_group}]` ).css("order", -1);
					$ctrl.$mosaicDesktopItems = $('.OR-Mosaic-item');
				}
			};

			$ctrl.initArrayFeatures = function () {
				$ctrl.$mosaicDesktopItems.each(function() {
					$ctrl.arrayFeatures.push($(this).data('overlay-trigger'));
				});
			};

			$ctrl.initMosaicDesktop = function () {
				const mosaicWidth = $ctrl.$mosaicDesktop.width();

				const normalWidth = mosaicWidth / 3;
				const normalHeight = normalWidth * 0.73;

				const largeWidth = normalWidth * 2;
				const largeHeight = largeWidth * 0.73;

				let mosaicHeight = 0;

				$ctrl.$mosaicDesktopItems.each(function( index ) {
					const indexOutOfTwelve = index % 12;
					const currentRow = Math.floor(index / 3);

					let offsetY = Math.floor(currentRow / 2) * (largeHeight + normalHeight);
					mosaicHeight = offsetY + largeHeight;
					if ( currentRow % 2 !== 0 ) {
						if($ctrl.isCmsMosaic === "true" ) {
							offsetY = (Math.floor(currentRow / 2) * (normalHeight + normalHeight)) + largeHeight;
							mosaicHeight = offsetY;
						} else {
							offsetY = (Math.floor(currentRow / 2) * (largeHeight + normalHeight)) + largeHeight;
							mosaicHeight = offsetY + normalHeight;
						}
					}

					if($ctrl.isCmsMosaic !== "true") {
						if (indexOutOfTwelve === 0 || indexOutOfTwelve === 8) {
							$( this ).css({ "width": `${largeWidth}px`, "height": `${largeHeight}px` });
						} else {
							$( this ).css({ "width": `${normalWidth}px`, "height": `${normalHeight}px` });
						}
                    }

					if($ctrl.isCmsMosaic !== "true") {
						switch (indexOutOfTwelve) {
							case 0:
								$( this ).css("transform", `translate3d(0, ${ offsetY }px,0 )`);
								break;
							case 1:
								$( this ).css("transform", `translate3d(${ normalWidth * 2 }px, ${ offsetY }px, 0 )`);
								break;
							case 2:
								$( this ).css("transform", `translate3d(${ normalWidth * 2 }px, ${ offsetY + normalHeight }px, 0 )`);
								break;
							case 3:
								$( this ).css("transform", `translate3d(0, ${ offsetY }px, 0 )`);
								break;
							case 4:
								$( this ).css("transform", `translate3d(${ normalWidth }px, ${ offsetY }px, 0 )`);
								break;
							case 5:
								$( this ).css("transform", `translate3d(${ normalWidth * 2 }px, ${ offsetY }px, 0 )`);
								break;
							case 6:
								$( this ).css("transform", `translate3d(0, ${ offsetY }px, 0 )`);
								break;
							case 7:
								$( this ).css("transform", `translate3d(0, ${ offsetY + normalHeight }px, 0 )`);
								break;
							case 8:
								$( this ).css("transform", `translate3d(${ normalWidth }px, ${ offsetY }px, 0 )`);
								break;
							case 9:
								$( this ).css("transform", `translate3d(0, ${ offsetY }px, 0 )`);
								break;
							case 10:
								$( this ).css("transform", `translate3d(${ normalWidth }px, ${ offsetY }px, 0 )`);
								break;
							case 11:
								$( this ).css("transform", `translate3d(${ normalWidth * 2 }px, ${ offsetY }px, 0 )`);
								break;
						}
					}
				});
				if ($ctrl.isCmsMosaic !== "true") {
					$ctrl.$mosaicDesktop.height(mosaicHeight);
				}
			};

			$ctrl.getPrevMosaicOverlay = function () {
				const maxLength = $ctrl.arrayFeatures.length-1;
				if( $ctrl.indexCurrentFeatureModal-1 < 0 ) {
					$ctrl.checkIfOverlayIsDisabled(maxLength, 'prev');
				} else {
					$ctrl.checkIfOverlayIsDisabled($ctrl.indexCurrentFeatureModal-1, 'prev');
				}
				$rootScope.$broadcast('cms-mosaic-overlay.refresh');
			};

			$ctrl.getNextMosaicOverlay = function () {
				const maxLength = $ctrl.arrayFeatures.length-1;
				if( $ctrl.indexCurrentFeatureModal + 1 > maxLength ) {
					$ctrl.checkIfOverlayIsDisabled(0, 'next');
				} else {
					$ctrl.checkIfOverlayIsDisabled($ctrl.indexCurrentFeatureModal+1, 'next');
				}
				$rootScope.$broadcast('cms-mosaic-overlay.refresh');
			};

			$ctrl.checkIfOverlayIsDisabled = function (index, direction) {
				let slideSwitchTo = $ctrl.$mosaicDesktopItems.filter(`[data-overlay-disabled=true]`)[index];

				if (slideSwitchTo.hasAttribute('data-disable-overlay')) {
					$ctrl.indexCurrentFeatureModal = index;
					if (direction==='next') {
						$ctrl.getNextMosaicOverlay();
					} else {
						$ctrl.getPrevMosaicOverlay();
					}
				} else {
					$ctrl.switchMosaicOverlay(index);
				}
			};

			$ctrl.switchMosaicOverlay = function (newIndex) {
				$ctrl.indexCurrentFeatureModal = newIndex;
				$ctrl.$mosaicOverlays.removeClass('isVisible');
				$ctrl.$mosaicOverlays.filter(`[data-overlay-id=${$ctrl.arrayFeatures[newIndex]}]`).addClass('isVisible');

				$ctrl.$mosaicOverlays.filter(`[data-overlay-id=${$ctrl.arrayFeatures[newIndex]}]`).find('.slick-initialized').slick('setPosition');
				$timeout(() => {
					$ctrl.focusTrapActive($ctrl.arrayFeatures[newIndex]);
				});
			};

			$ctrl.openMosaicOverlay = function (e) {
				if(!e || e.target.parentElement.className === "legal-tooltip-label is-disclaimer") {
					return false;
				}

				let analyticsId = $(this).data('analyticsid');
				let analyticsValue = $(this).data('mosaic-feature-id');

				const overlayId = $(this).data('overlay-trigger');
				$ctrl.indexCurrentFeatureModal = $ctrl.arrayFeatures.indexOf(overlayId);
				$ctrl.pauseScrolling();
				$ctrl.$mosaicOverlays.removeClass('isVisible');
				$ctrl.$mosaicOverlays.filter(`[data-overlay-id=${overlayId}]`).addClass('isVisible');

				$ctrl.$mosaicOverlays.filter(`[data-overlay-id=${overlayId}]`).find('.slick-initialized').slick('setPosition');

				$rootScope.$broadcast('cms-mosaic-overlay.refresh');

				$ctrl.sendGtmTrackEvent(analyticsId, analyticsValue);
				$ctrl.focusTrapActive(overlayId);
			};

			$ctrl.closeMosaicOverlay = function () {
				$ctrl.resumeScrolling();
				$ctrl.$mosaicOverlays.removeClass('isVisible');

				// Close any open disclaimers
				tippy.hideAll();
			};

			$ctrl.pauseScrolling = function () {
				$ctrl.scrollPaused = true;
				$('body').addClass('overflow-hidden');
				$('html').addClass('overflow-hidden');
			};

			$ctrl.resumeScrolling = function () {
				$ctrl.scrollPaused = false;
				$('body').removeClass('overflow-hidden');
				$('html').removeClass('overflow-hidden');
			};

			$ctrl.hoverVideo = function () {
				let playPromise = $(this).find('video').get(0).play();

				if (playPromise !== undefined) {
					playPromise.then(function() {
						// Automatic playback started!
					}).catch(function(error) {
						// Automatic playback failed.
						console.warn('video playback failed');
					});
				}
			};

			$ctrl.hideVideo = function () {
				$(this).find('video').get(0).pause()
			};

			$ctrl.sendGtmTrackEvent = (id, value) => {
				gtmAnalytics.trackEvent('event', {
					category: window.FCA_SITES_CONFIG.pageCode,
					label: `${id}-${value}`
				});
			};

			$ctrl.focusTrapActive = (overlayID) => {
				//this needs to get refreshed everytime the modal changes
				let navDialogEl = document.querySelector(`div[data-overlay-id="${overlayID}"] .OR-MosaicOverlay-holder`);
				let dialogOverlay = document.querySelector(`.OR-MosaicOverlay[data-overlay-id="${overlayID}"]`);
				dialogRef = new Dialog(navDialogEl, dialogOverlay);
				dialogRef.addEventListeners(
					`div[data-overlay-id="${overlayID}"] .OR-MosaicOverlay-holder .OR-MosaicOverlay-footer>.-next`,
					`div[data-overlay-id="${overlayID}"] .OR-MosaicOverlay-holder .OR-MosaicOverlay-closeBtn`,
					$ctrl.closeMosaicOverlay);
				dialogRef.open();
			};
		}
	}
})();