(function() {
	angular
		.module("fca.miniBuildAndPrice.model.info")
		.component("modelInfoMiniBp", {
			controller: modelInfoMiniBp,
			controllerAs: "$ctrl",
			templateUrl:
				'/shared/fca-mini-build-and-price/model-info/model-info-mini-bp.html',
			bindings: {
				isDesktop: '<',
				brand: '@',
				vehicleName: '@',
				vehicleStartingPrice: '@',
				hidePricing: '<',
				currentDrivetrainId: '@'
			}
		});

	function modelInfoMiniBp(
		$scope
	) {
		"ngInject";

		const $ctrl = this;

		$ctrl.disclaimers = {
			msrpStartingAt: angular.element('[data-disclaimer-code="leg-pricing"]').html()
		};

		$ctrl.$onInit = () => {
			$ctrl.hideClass = ($ctrl.hidePricing === true) ? 'mini-bp-price-point' : '';
		}

		$ctrl.trimGroupSelector = () => {
			if ($ctrl.brand != 'alfaromeo') return ''
			return $ctrl.currentDrivetrainId == 'awd' ? 'common.trimGroupSelector.allWheelDrive' : 'common.trimGroupSelector.rearWheelDrive';
		}
	}
})();
