(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator.cookie')
		.service('GeolocatorCookie', GeolocatorCookieFactory);

	function GeolocatorCookieFactory(
		$base64,
		$cookies) {
		'ngInject';

		function CookieConnector(cookieName, expires) {
			this.cookieName = cookieName;
			this.expires = (ng.isDate(expires)) ? expires.toGMTString() : expires;
			this.cookieValue = null;
			return this;
		}

		ng.extend(CookieConnector.prototype, {
			get: function() {
				let value = null;
				
				if (this.isCookieExists() && ng.isObject(this.cookieValue)) {
					value = ng.copy(this.cookieValue);
				}

				return value;
			},
			set: function(cookieData) {
				if (ng.isObject(cookieData)) {
					// Prepare value string in base64
					let value = window.btoa(unescape(encodeURIComponent(ng.toJson(cookieData))));
					// Set cookie value
					$cookies.put(this.cookieName, value, {expires: this.expires, path: '/'});
					// Update cookieValue
					this.cookieValue = ng.copy(cookieData);
				}
	
				return this.cookieValue;
			},
			isCookieExists: function() {
				// Get base64 cookie value
				let cookie = $cookies.get(this.cookieName);
				// Flag to return
				let isExists = false;

				if (cookie !== undefined) {
					// Set value in instance to re-used
					if (!this.cookieValue) {
						this.cookieValue = ng.fromJson($base64.decode(cookie));
					}
					// Set true to returned value
					isExists = true;
				}

				return isExists;
			}
		});

		return CookieConnector;
	}
})(angular);
