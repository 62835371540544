(function() {
	angular
		.module('fca.pageLayout')
		.directive('fcaNavigationMenuItem', [fcaNavigationMenuItem]);

	function fcaNavigationMenuItem($timeout, matchmedia, MEDIAQUERIES) {
		return {
			require: '^fcaNavigationMenu',
			restrict: 'A',
			scope: true,
			bindToController: true,
			controllerAs: '$ctrl',
			controller: FcaNavigationMenuItemController,
			link: FcaNavigationMenuItemLink
		};

		function FcaNavigationMenuItemController($element, $timeout, $scope, gtmAnalytics) {
			'ngInject';

			this.isActive = false;
			this.secondSubmenu = false;
			this.scrollToSubmenu = false;

			// If a sub-menu with children is inside the main menu, we keep a reference to Main Menu Controller
			// Could be a problem if the submenu is inside another directive that is not navigation-menu-item.directive
			if ($scope.$parent && $scope.$parent.$ctrl) {
				this.parentController = $scope.$parent.$ctrl;
			}

			let $html = $('html');

			let $subNav = $element.find('[data-subnav]').first();

			const $mainNav = $('.main-nav');

			const isAlfa = FCA_SITES_CONFIG.name === 'alfaromeo';

			let dialogRef;

			// Check if this menu is part of another menu or not
			if ($element.attr('data-second-subnav') != undefined) {
				this.secondSubmenu = true;
			}

			// Check if the user should be anchored to the submenu on click
			if ($element.attr('data-scroll-to-submenu') != undefined) {
				this.scrollToSubmenu = true;
			}

			this.openMenu = () => {
				this.openVehicleMenu();

				this.isActive = true;
				$scope.$apply();

				// Opening the menu(s)
				$timeout(() => {
					if (this.secondSubmenu && checkForParentMenu($subNav)) {
						openMenuInsideMenu($subNav, this.secondSubmenu);
					} else {
						animateMenu($subNav, getElementHeight($subNav));
					}
				}, 150);

				if (this.scrollToSubmenu) {
					scrollToMenu($subNav);
				}

				$html.addClass('main-nav-active');

				// Simple select list links must keep the tabindex -1
				$subNav.find('.province-selector-list a').attr('tabindex', '-1');

			};

			this.resizeHeight = () => {
				$timeout(() => {
					$subNav.css('height', 'auto');
				});
			};

			// We need to handle opening the vehicle menu differently. An open time
			// We need to complete the nameplate row with a number of tiles.

			this.openVehicleMenu = () => {
				let isMenuVehicle = $subNav.hasClass('vehicles');

				removeOffersBtn();

				if (isMenuVehicle) {
					this.focusTrapActive($subNav[0],
						$subNav.parent()[0],
						'.navigation-group-item-vehicles .main-nav-top-link',
						'.navigation-group-item-vehicles>.section-header-container>button');

					$('.extra-page').addClass('page-to-remove');

					// Get the count of vehicles.
					let vehicleCount = $('.menu-vehicle-info').data('vehicle-count');
					// From Sling
					let columnNumber = $('.menu-vehicle-info').data('column-number');
					let sectionId = $('.menu-vehicle-info').data('section-id');
					// Size of the current viewport
					let viewPortSize = $(window).width();

					// Detect the different device sizes
					let isDesktop = viewPortSize >= 1024;
					let isTablet = viewPortSize < 1024 && viewPortSize >= 667;
					let isMobile = !isDesktop && !isTablet;
					let tilesToAdd = 0;

					// Mobile is always in one column. Tablet always 3.
					// For desktop we have a number of cars per row configured in sling.
					if (isMobile) {
						columnNumber = 1;
						if (sectionId == 'see-on-mobile') {
							tilesToAdd = 1;
						}
					} else if (isTablet) {
						columnNumber = 3;
					}

					// Use the modulo of the number of vehicle to deduce the number of empty tiles.
					if (columnNumber > 1) {
						let lastLineTileCount = vehicleCount % columnNumber;
						if (lastLineTileCount != 0) {
							tilesToAdd = columnNumber - lastLineTileCount;
						}
					}

					// We process the extra pages only if we need to.
					if (tilesToAdd > 0) {
						let extraPages = $('.extra-page');

						// We have 2 tiles to complete rows, if they exists, hide / show them
						// Based on the number of tiles to add.

						for (let i = 0; i < extraPages.length; i++) {
							let extraPage = extraPages[i];

							if (i < tilesToAdd) {
								$(extraPage).removeClass('page-to-remove');
							} else {
								$(extraPage).addClass('page-to-remove');
							}
						}
					}
				}

				setNameplateTitleHeight();
			};

			this.focusTrapActive = (navDialogEl, dialogOverlay, openDialogSel, closeDialogSel) => {
				if (!dialogRef) {
					setTimeout(()=>{
						dialogRef = new Dialog(navDialogEl, dialogOverlay);
						dialogRef.addEventListeners(openDialogSel, closeDialogSel);
						dialogRef.open();
					}, 500);
				}
			};

			this.closeMenu = ($event) => {
				dialogRef = null;
				this.isActive = false;
				if (!$scope.$$phase) {
					$scope.$apply();
				}

				// Closing the menu(s)
				if (this.secondSubmenu && checkForParentMenu($subNav)) {
					closeMenuInsideMenu($subNav);
				} else {
					animateMenu($subNav, 0);
					$html.removeClass('main-nav-active');

					// set the focus on main-menu wehicle item when the vehicle menu is closing
					const subNavClassList = $subNav[0].classList;

					// if click event is not fire by Mouse
					if ($event && $event.originalEvent.detail === 0) {
						// put focus back on main-nav link for keyboard navigation
						if(subNavClassList.contains('vehicles') && subNavClassList.contains('is-active')) {
							setVoiceOverFocus(document.querySelector('.navigation-group-item-vehicles .main-nav-top-link'));
						}
					}

					removeOffersBtn();
				}

				resetNameplateTitleHeight();
			};

			this.triggerSubMenuTracking = () => {
				gtmAnalytics.trackEvent('event', {
					category: 'top nav',
					label: 'vehicles - check out other great vehicles'
				});
			};

			this.setMenuSublinkFocusNavigation = () => {
				/* close subMenu when focus back from first item,
				close submenu when focus forward from the last item  */

				// for all subMenu except vehicles and offers
				if ( $subNav[0] ) {
					if (!$subNav[0].classList.contains('main-nav-subnav-offers') &&
						(!$subNav[0].classList.contains('vehicles') || isAlfa) ) {
						const $subNavFocusableElements = $subNav.find('a, button, input');
						const firstFocusableElement = $subNavFocusableElements[0];
						const lastFocusableElement = $subNavFocusableElements[$subNavFocusableElements.length -1];

						if (firstFocusableElement) {
							firstFocusableElement.addEventListener('keydown', e => {
								if (e.key === 'Tab' || e.code === 'Tab') {
									if ( e.shiftKey ) {
										// shift + tab (backward <-)
										this.closeMenu();
									}
								}
							});
						}

						if (lastFocusableElement) {
							lastFocusableElement.addEventListener('keydown', e => {
								if (e.key === 'Tab' || e.code === 'Tab') {
									if ( !e.shiftKey ) {
										// tab (forward ->)
										this.closeMenu();
									}
								}
							});
						}
					}
				}
			};

			this.$postLink = () => {
				$timeout(() => {
					this.setMenuSublinkFocusNavigation();
				}, 500);
			}
		}

		function FcaNavigationMenuItemLink(scope, element, attrs, fcaNavigationMenu) {
			let vmInst = scope.$ctrl;
			let menuTrigger = element.find('a[data-open-submenu]').first();
			vmInst.fcaNavigationMenu = fcaNavigationMenu;

			// adds this menu to the navigation controller
			vmInst.fcaNavigationMenu.addMenuToNavigation(vmInst);

			menuTrigger.bind('click', (event) => {
				onMenuItemClicked(event, scope, vmInst);
			});

			menuTrigger.bind('keypress', (event) => {
				if (event.originalEvent.code == "Enter" || event.originalEvent.code == "Space") {
					onMenuItemClicked(event, scope, vmInst);
				}
			});
		}

		function onMenuItemClicked(event, scope, controller) {
			event.preventDefault();
			controller.fcaNavigationMenu.menuClicked(controller);
			scope.$apply();
		}

		function animateMenu($menu, newHeight, animationLength = 150) {
			$menu.stop().animate({
				height: newHeight
			}, animationLength);
		}

		function closeMenuInsideMenu($menu) {

			let $parentMenu = $menu.parents('[data-subnav]');
			let currentHeight = getElementCurrentHeight($menu);
			let parentCurrentHeight = getElementCurrentHeight($parentMenu);
			let fullHeight;
			let parentNewHeight;

			// Temporarily setting to auto (to calculate the height)
			$parentMenu.css('height', 'auto');
			$menu.css('height', '0');

			// Calculate the height
			fullHeight = getElementCurrentHeight($menu);
			parentNewHeight = getElementCurrentHeight($parentMenu);

			// Reset the height to previous state
			$menu.css('height', currentHeight);
			$parentMenu.css('height', parentCurrentHeight);

			// Animating the height
			animateMenu($menu, fullHeight);
			animateMenu($parentMenu, parentNewHeight);

			removeOffersBtn();
		}

		function openMenuInsideMenu($menu, isSecondMenu) {
			let $parentMenu = $menu.parents('[data-subnav]');
			let currentHeight = getElementCurrentHeight($menu);
			let parentCurrentHeight = getElementCurrentHeight($parentMenu);
			let btnAllOffers = $(".offers-subnav-cta-btn-mobile");
			let fullHeight;
			let parentNewHeight;

			// Temporarily setting to auto (to calculate the height)
			$parentMenu.css('height', 'auto');
			$menu.css('height', 'auto');

			// Calculate the height
			fullHeight = getElementCurrentHeight($menu);
			parentNewHeight = getElementCurrentHeight($parentMenu);

			if($menu.hasClass("main-nav-subnav-offers")) {
				btnAllOffers.css( "display", "block" );

				animateMenu($parentMenu, "100vh");
				animateMenu($menu, "auto");
			} else {

				// Animating the $menu height
				animateMenu($menu, fullHeight);
				animateMenu($parentMenu, parentNewHeight);

				removeOffersBtn();
			}

		}

		function getElementHeight($menu) {
			let currentHeight = getElementCurrentHeight($menu);
			let fullHeight;

			// Temporarily setting to auto (to calculate the height)
			$menu.css('height', 'auto');

			// Calculate the height
			fullHeight = getElementCurrentHeight($menu);

			// Reset the height to previous state
			$menu.css('height', currentHeight);

			return fullHeight;
		}

		function getElementCurrentHeight($menu) {
			return $menu.height();
		}

		function checkForParentMenu($menu) {
			if ( $menu.parents('[data-subnav]').length > 0 ) {
				return true;
			} else {
				return false;
			}
		}

		function scrollToMenu($menu) {
			let $parentMenu = $menu.parents('[data-subnav]');

			setTimeout(() => {
				$parentMenu.animate({
					scrollTop: 2000
				});
			}, 150);
		}

		function setNameplateTitleHeight() {
			let largest = 0;
			let nameplateTitleBox = $(".main-nav-subnav-vehicle-details.jsHeightCalc");

			nameplateTitleBox.each(function () {
				let findHeight = $(this).height();
				if (findHeight > largest) {
					largest = findHeight;
				}
			});

			nameplateTitleBox.css({"height":largest + "px"});
		}

		function resetNameplateTitleHeight() {
			let nameplateTitleBox = $(".main-nav-subnav-vehicle-details.jsHeightCalc");

			nameplateTitleBox.css({"height":"auto"});
		}

		function removeOffersBtn() {
			$(".offers-subnav-cta-btn-mobile").css( "display", "none" );
		}

		function setVoiceOverFocus(element) {
			const initialItem = document.querySelector('.main-nav-subnav.vehicles .section-header-container > button');

			setTimeout(() => {
				initialItem.blur();
				initialItem.setAttribute('tabindex', '-1');

				element.setAttribute('tabindex', '0');
				element.focus();
			}, 500);
		}
	}
})();
