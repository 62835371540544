(function() {
	angular
		.module('fca.pageLayout')
		.directive('fcaNavigationMenuOffers', fcaNavigationMenuOffers);

	function fcaNavigationMenuOffers() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controller: FcaNavigationMenuOffersController,
			controllerAs: 'menuOffers'
		};

		function FcaNavigationMenuOffersController($timeout) {
			'ngInject';

			let $ctrl = this;


			$ctrl.$postLink = () => {
				$timeout(() => {
					const offersMenuElement = document.querySelector('.main-nav-subnav-offers .offers-subnav');

					if(offersMenuElement) {
						$ctrl.setVehicleMenuFocusTrap(offersMenuElement);
					}
				}, 500);
			};

			$ctrl.setVehicleMenuFocusTrap = (element) => {
				const focusableElements = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
				const firstFocusableElement = focusableElements[0];
				const lastFocusableElement = focusableElements[focusableElements.length - 1];

				element.addEventListener('keydown', e => {
					// Return if no Tab
					if (!(e.key === 'Tab' || e.code === 'Tab')) {
						return;
					}

					if ( e.shiftKey ) {
						// shift + tab (backward <-)
						if (document.activeElement === firstFocusableElement) {
							lastFocusableElement.focus();
							e.preventDefault();
						}
					} else {
						// tab (forward ->)
						if (document.activeElement === lastFocusableElement) {
							firstFocusableElement.focus();
							e.preventDefault();
						}
					}
				});
			};
		}

		// Disable keyboard navigation on contained elements
		function disableKeyboardNavigation(el) {
			el.find('a, button, input').attr('tabindex', '-1');
		}
	}
})();
