// Smooth scrolling directive. Add to any link that points to an element on the
// same page to *smoothly* scroll to the link target on click. Gracefully
// degrades if the link target is not valid.
//
// Usage:
//
// 1. With the default animation duration of 300 ms:
//    <a href="#element-id" fca-smooth-scroll>
//
// 2. With a custom animation duration of 900 ms:
//    <a href="#element-id" fca-smooth-scroll="900">
//
// 3. To scroll to a point offset of the target (for example, if you were to
//    have a sticky header in the way):
//    <a href="#element-id"
//       fca-smooth-scroll
//       fca-smooth-scroll-offset="-150"
//    >
//
//    The offset is in pixels; a negative offset will result in the scroll
//    animation stopping *above* the target element.
//
// The `href` value will be fed into `document.querySelector`, so any valid CSS
// selector will work. The directive will take the first match as the target.
(function () {
    'use strict';

    angular
        .module('fca.commonDirectives')
        .directive('fcaSmoothScroll', fcaSmoothScroll);

    const defaultAnimationDuration = 300;

    function fcaSmoothScroll () {
        return {
            restrict: 'A',

            controller: function ($element) {
                $element.bind('click', () => smoothScroll($element[0]));
            }
        };
    }

    function smoothScroll(element) {
        const href = element.getAttribute('href');

        let target;
        // If the href is an invalid selector, querySelector will throw an
        // exception. In that case, we want to catch the error and return `true`
        // to allow the click event to execute normally.
        try {
            target = document.querySelector(href);
        } catch(error) {
            return true;
        }

        const noMatchingElement = target == null;
        if (noMatchingElement) {
            return true;
        }

        const duration = parseInt(element.getAttribute('fca-smooth-scroll'))
            || defaultAnimationDuration;

        const offset =
            parseInt(element.getAttribute('fca-smooth-scroll-offset'))
            || 0;

        $('html, body').animate({
            scrollTop: target.offsetTop + offset
        }, duration);

        return true;
    }
})();
