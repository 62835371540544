/* eslint-disable indent */
(function() {
    let $openingHoursChecker = $('.js-openingHoursChecker');

    let dealershipHoursDropdownToggle = true;

    function init() {
        if ($openingHoursChecker.length > 0) {
            initHoursChecker();
        }
    }

    function initHoursChecker() {
        $openingHoursChecker.each(function(index, element) {
            let nowDate = new Date();
            let hoursDay = $(this).data('hoursDay');

            if(nowDate.getDay() === hoursDay) {
                // the current day is bolded in all tabs
                $(this).parent().css("font-weight", "bold");

                let departmentId = $(this).closest('.department-hours').data('department-id');
                let hoursOpen = new Date($(this).data('hoursOpen'));
                let hoursClose = new Date($(this).data('hoursClose'));

                let openToday = hoursOpen !== 'closed' && hoursClose !== 'closed';
                let openNow = false;

                if (openToday) {
                    // we know it's not always closed this day, but we don't know if it's open right now
                    // we don't care about the actual date, only about the hours/minutes
                    let formatedOpenHours = parseInt((hoursOpen.getHours()+''+('0'+hoursOpen.getMinutes()).slice(-2)),10);
                    let formatedCloseHours = parseInt((hoursClose.getHours()+''+('0'+hoursClose.getMinutes()).slice(-2)),10);
                    let formatedNowTime = parseInt((nowDate.getHours()+''+('0'+nowDate.getMinutes()).slice(-2)),10);

                    if (formatedOpenHours <= formatedNowTime && formatedNowTime < formatedCloseHours) {
                        openNow = true;
                    }
                }

                if (openNow) {
                    $(this).html('<i class="fcaicon fcaicon-open-invert" aria-hidden="true"></i>');
                } else {
                    $(this).html('<i class="fcaicon fcaicon-closed-invert" aria-hidden="true"></i>');
                }

                // we need to update the main opening hours label, that's the department we care about
                if (departmentId == "sales") {
                    // copy the opening hours from the sibling node
                    let openingHours = $(this).siblings('.js-labelOpeningHoursData').data('openingHours');
                    // only set the label opening hours if it's open right now, if that changes we can use the openToday condition
                    if (openNow) {
                        $('.js-labelOpeningHours').html(`<i class="fcaicon fcaicon-open-invert" aria-hidden="true"></i><p id="opening-hours-current-time-label"><span class="visuallyhidden" data-translate="sni.detail.dealers.current.title"></span>&nbsp;<span data-translate="dealers.hour.open"></span>&nbsp;(${openingHours})</p>`);
                    }
                }
            }
		});
    }
    $('#toggle-hours').click(function() {
        const labelOpeningHours = $('.js-labelOpeningHours');
        const accordeonTitle = $('.C_DD-accordeonTitle');
        const accordeonContent = $('.C_DD-accordeonContent');

        if(dealershipHoursDropdownToggle) {
            labelOpeningHours.addClass('C_DD-hide');
            accordeonTitle.addClass('C_DD-open').attr('aria-expanded', 'true');
            accordeonContent.addClass('C_DD-open');
            accordeonTitle.find('.fcaicon').removeClass('fcaicon-chevron-right').addClass('fcaicon-chevron-down');
        } else {
            labelOpeningHours.removeClass('C_DD-hide');
            accordeonTitle.removeClass('C_DD-open').attr('aria-expanded', 'false');
            accordeonContent.removeClass('C_DD-open');
            accordeonTitle.find('.fcaicon').removeClass('fcaicon-chevron-down').addClass('fcaicon-chevron-right');
        }
        dealershipHoursDropdownToggle = !dealershipHoursDropdownToggle;
    });
    init();
})();
