/* eslint-disable indent */
(function () {
    angular
    .module('fca.sni.vehicleInventoryList')
    .component('sniVehicleInventoryList', {
        controller: SniVehicleInventoryList,
        controllerAs: '$ctrl',
        template: '<ng-include src="getTemplateUrl()"/>',
        bindings: {
            userLocation: "<",

            brandCode: "@",
            brandName: "@",
            nameplateCode: "@",
            nameplateName: "@",
            siteGroupCode: "@",
            modelYear: "@",
            sniMode: "@",
            dealerCode: "@",
            showTitle: "=",
            hasDealerInfo: "=",
            hasNameplate: "=",

            buildAndPriceUrl: "@",
            eshopWindow: "@",
            eshopActivated: "@",
            eshopUrl: "@",
            eshopLabel: "@",
            brandUrl: "@",
            affiliateId: "@",
            sniDetailsUrl: "@",
            sniBuyUrl: "@",
            sniSearchBaseUrl: "@",
            sniSearchUrl: "@",
            sniDealerInventoryUrl: "@",

            disclaimerPrice: "@",
            disclaimerDealerPrice: "@",
            disclaimerEmployeePrice: "@",
            disclaimerPreferredPrice: "@",
            disclaimerAffiliatePrice: "@",
            disclaimerLease: "@",
            disclaimerFinance: "@",
            disclaimerNetAmountSni: "@",
            disclaimerMsrp: "@",
            affiliateLogoSource: '@',
            rawLogoUrls: "@",
            visibleOnBuildAndPrice: "@",
            maxIrisWidth: "@",

            showHeaderZone: "<?",
            showNumberOfResultsZone: "<?",
            showVehicleDetail: "<?",
            showCompareButton: "<?",
            showLoadMoreButton: "<?",
            showCtaHolder: "<?",
            showVehicleGallery: "<?",
            maxNumberOfVehicle: "@?",
            waitForLibraryInitialized: "<?",
            vlpAnalyticsId: "@",
            campaignCode: "@?",

            calloutPrice: "@",
            calloutJelly: "@",
            calloutIconOne: "@",
            calloutIconTwo: "@",
            calloutIconThree: "@",
            showCallout: "@",
            calloutModelYearId: "@",
            calloutModelYear: "@"
        }
    });

    function SniVehicleInventoryList($http, $scope, $rootScope, $translate, fcaGeolocator, $timeout, userAccountService, fcaSniTrimService, $attrs, gtmAnalytics, fcaUserIdPersistentService, dealerDigitalRetailingService) {
        'ngInject';
        const $ctrl = this;

        $ctrl.pageHeaderElement = $('#zones_body_blocks_page-title');
        $ctrl.dealerDetailsElement = $('#zones_body_blocks_dealer-info');
        $ctrl.compareBarZone = $('.C_VIL-compareBarZone');
        $ctrl.ctaHolder = $('.C_VIL-ctaHolder');

        $ctrl.pageIsReady = false;
        $ctrl.moreContentLoading = false;

        $ctrl.language = window.FCA_SITES_CONFIG.language;
        $ctrl.mediaUrl = window.FCA_SITES_CONFIG.mediaBaseUrl.slice(0, -1);
        $ctrl.siteGroupCode = window.FCA_SITES_CONFIG.siteGroupCode;

        $ctrl.apiData = {};

        $ctrl.vehiclesList = [];
        $ctrl.vinsToCompare = [];
        $ctrl.compareBarInitialized = false;
        $ctrl.incentivesImages = [];
        $ctrl.incentiveDisclaimersObject = {};
        $ctrl.incentiveDisclaimersArray = [];
        $ctrl.provincialIncentive = {};

        $ctrl.hashData = {};
        $ctrl.apiUrlOptions = {};
        $ctrl.filterManager = new SniInventoryFilter();

        $ctrl.sortOrFilter = "none";

        $ctrl.detailTarget = "_top";

        $ctrl.currentFilterTab = "price";

        $ctrl.filters = {};
        $ctrl.containsDisabledOptions = false;

        $ctrl.stickyCompareBar = {};

        $ctrl.showBPSummary = false;
        $ctrl.buildPriceTrimGroup = "";
        $ctrl.buildPriceDriveTrain = "";
        $ctrl.buildPriceBoxLength = "";
        $ctrl.buildPriceCabType = "";
        $ctrl.buildPricePackage = "";
        $ctrl.affiliateMode = false;
        $ctrl.buildAndPricePath = "";
        $ctrl.isVisible = true;
        $ctrl.affiliateParams = '';
        $ctrl.affiliateUrl = '';
        $ctrl.affiliateId = null;
        $ctrl.utmTag = '?utm_source=t2buyonline&utm_medium=referral';
        $ctrl.campaignParams = '';

        $ctrl.financeMode = null;

        // finance mode listeners
        $ctrl.financeModeListeners = [];

        $ctrl.inventoryIcon = {
            'IN_STOCK': 'map',
            'IN_TRANSIT': 'truck',
            'ON_ORDER': 'paper'
        };

        $ctrl.inventoryStatus = {
            'IN_STOCK': 'sni.list.nameplate.on-dealer-lot',
            'IN_TRANSIT': 'sni.list.nameplate.in-transit',
            'ON_ORDER': 'sni.list.nameplate.on-order'
        };

        $scope.getTemplateUrl = function() {
            // template handling
            if ($attrs.vlpRedesignTemplate && $attrs.vlpRedesignTemplate === "true") {
                return "/sni/vehicle-inventory-list/vehicle-inventory-list-vlp2021.html";
            } else {
                return "/sni/vehicle-inventory-list/vehicle-inventory-list.html";
            }
        };

        $ctrl.$onInit = () => {
            // $ctrl.hashData is a dependency for almost every single function,
            // so this function *must* always be first.

            if ($ctrl.showHeaderZone === undefined) {
                $ctrl.showHeaderZone = true;
            }

            if ($ctrl.showNumberOfResultsZone === undefined) {
                $ctrl.showNumberOfResultsZone = true;
            }

            if ($ctrl.showVehicleDetail === undefined) {
                $ctrl.showVehicleDetail = true;
            }
            if ($ctrl.showCompareButton === undefined) {
                $ctrl.showCompareButton = true;
            }
            if ($ctrl.showLoadMoreButton === undefined) {
                $ctrl.showLoadMoreButton = true;
            }
            if ($ctrl.showCtaHolder === undefined) {
                $ctrl.showCtaHolder = true;
            }
            if ($ctrl.showVehicleGallery === undefined) {
                $ctrl.showVehicleGallery = true;
            }
            if ($ctrl.waitForLibraryInitialized === undefined) {
                $ctrl.waitForLibraryInitialized = true;
            }

            $ctrl.getUrlHashData();
            $ctrl.checkAffiliateMode();
            $ctrl.checkCampaign();
            $ctrl.setInitialScope();
            $ctrl.initHashListener();
            $ctrl.maxDealerImages = 3;

            $ctrl.disclaimers = {
                dealer: $ctrl.disclaimerDealerPrice,
                regular: $ctrl.disclaimerPrice,
                finance: $ctrl.disclaimerFinance,
                lease: $ctrl.disclaimerLease,
                legAmountSni: $ctrl.disclaimerNetAmountSni,
                msrp: $ctrl.disclaimerMsrp
            };

            // use the active tab as the global payment mode
            if ($ctrl.hashData.activeTab) {
                $ctrl.financeMode = $ctrl.hashData.activeTab;
            }

            if ($ctrl.hashData.mode === 'compare') {
                $ctrl.goToCompare();
                return;
            }

            if ($ctrl.eshopWindow === "true") {
                $ctrl.detailTarget = "_blank";
            }

            if ($ctrl.hashData.dealer) {
                $ctrl.dealerCode = $ctrl.hashData.dealer;
                sessionStorage.setItem('dealerId', $ctrl.dealerCode);
            }

            if (userAccountService.isLibraryAvailable()) {
                if ($ctrl.dataWaitForLibraryInitialized) {
                    $rootScope.$on('ua:libraryInitialized', () => {
                        $ctrl.setupList();
                    });
                } else {
                    $ctrl.setupList();
                }
            } else {
                $ctrl.setupList();
            }
        };

        $ctrl.getBackUrl = () => {
            let result = $ctrl.sniSearchBaseUrl;
            if (!!$ctrl.financeMode) {
                result += `#activeTab=${$ctrl.financeMode}`;
            }
            return result;
        };

        $ctrl.getUserId = () => {
            return fcaUserIdPersistentService.getCookie('userId');
        };

        $ctrl.getProcessCssClass = (vehicle) => {
            return dealerDigitalRetailingService.getCssClass(vehicle);
        }

        $ctrl.onStartBuyingProcessClick = (event, sniBuyUrl) => {
            return dealerDigitalRetailingService.onStartBuyingProcessClick(event, sniBuyUrl);
        }

        $ctrl.getBuyOnlineUrl = (vehicle, hashParams) => {
            if (vehicle.dealerDigitalRetailing && vehicle.dealerDigitalRetailing.enabled) {
                let digitalRetailingUrl = $ctrl.language == 'en' ? vehicle.dealerDigitalRetailing.urlEn : vehicle.dealerDigitalRetailing.urlFr;
                return dealerDigitalRetailingService.getDealerDigitalRetailingUrl(digitalRetailingUrl, vehicle.dealer.url, vehicle.vin);
            }

            let buyUrl = $ctrl.sniBuyUrl + vehicle.vin;

            if (this.affiliateParams !== '') {
                buyUrl += (buyUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.affiliateParams;
            }

            if (this.campaignParams !== '') {
                buyUrl += (buyUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.campaignParams;
            }

            buyUrl += hashParams;

            return buyUrl;
        };

        $ctrl.getEshopUrl = (vehicle) => {
            let detailUrl = $ctrl.eshopUrl + "/t1?vin="+vehicle.vin+"&dealercode="+vehicle.dealerCode+"&pid="+$ctrl.getUserId()+"&vehicle_type=new";

            if (this.affiliateParams !== '') {
                detailUrl += (detailUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.affiliateParams;
            }

            if (this.campaignParams !== '') {
                detailUrl += (detailUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.campaignParams;
            }
            detailUrl += "#vehicleInformation";

            return detailUrl;
        };

        $ctrl.getDetailUrl = (vehicle, hashParams) => {
            let detailUrl = $ctrl.sniDetailsUrl + vehicle.vin;

            if (this.affiliateParams !== '') {
                detailUrl += (detailUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.affiliateParams;
            }

            if (this.campaignParams !== '') {
                detailUrl += (detailUrl.indexOf('?') == -1 ? '?' : '&')
                    + $ctrl.campaignParams;
            }
            
            detailUrl += hashParams;

            if (($ctrl.eshopActivated === 'true') && (vehicle.dealer.isDigital)) {
                detailUrl = $ctrl.getEshopUrl(vehicle);
            }            
   
            return detailUrl;
        };

        $ctrl.getWindowSticker = (vehicle) => {
            let wsUrl = $ctrl.brandUrl + "/" + $ctrl.language + "/windowsticker/"+ vehicle.vin + "?campaign=" + $ctrl.campaignCode + "&affiliate=" + $ctrl.affiliateId;
            return wsUrl;
        };

        $ctrl.changeFinanceMode = (newFinanceMode) => {
            $ctrl.financeMode = newFinanceMode;
            // add it to hash parameters
            $ctrl.hashData.activeTab = newFinanceMode;
            // update the url
            $ctrl.filterManager.writeUrlHash($ctrl.hashData);
            // save the url (so that back button goes to correct finance mode)
            $ctrl.saveUrl();
            $ctrl.notifyFinanceModeListeners(newFinanceMode);
        };

        /**
         * registers a new finance mode listener
         * @param listener
         */
        $ctrl.registerFinanceModeListener = (listener) => {
            $ctrl.financeModeListeners.push(listener)
        };

        /**
         * notifies all registered finance mode listeners.
         */
        $ctrl.notifyFinanceModeListeners = (newFinanceMode) => {
            if ($ctrl.financeModeListeners) {
                $ctrl.financeModeListeners.forEach(function(listener) {
                    if (listener) {
                        try {
                            listener(newFinanceMode);
                        } catch(exception) {
                            console.error("error calling listener");
                        }
                    }
                });
            }
        };

        $ctrl.setupList = () => {
            $ctrl.apiUrlOptions = {
                brandCode: $ctrl.brandCode,
                nameplateCode: $ctrl.nameplateCode,
                dealerCode: $ctrl.dealerCode,
            };

            /* Listen if the location is changed outside this component */
            let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
            $ctrl.moveDealerZone();
            $scope.$on(eventUpdateName, (evt, args) => {
                $ctrl.userLocation = args[0];
                $ctrl.getUrlHashData();
                $ctrl.getBuildPriceDataAPI();
                $ctrl.getAPIData(0);
            });

            if ($ctrl.userLocation) {
                $ctrl.getBuildPriceDataAPI();
                $ctrl.getAPIData(0);
            }
        };

        // On the dealer details page, "Models & Features" is replaced by the
        // "Brands & Models" section. For this section, we will need to...
        //     * fetch a list of nameplates for each brand
        //     * parse the logo urls passed in through the FTL template
        //     * set the active brand for brand tabs
        $ctrl.setupDealerDetails = () => {
            $ctrl.brandLogos =
                $ctrl.filterManager.parseLogoUrls($ctrl.rawLogoUrls);

            $ctrl.fetchNameplates();
        };

        $ctrl.setInitialScope = () => {
            const sort = $ctrl.hashData.sort;
            const order = $ctrl.hashData.sortOrder;

            if (sort === 'distance') {
                $ctrl.sort = 'distance';
            } else if (sort === 'price' && order === 'desc') {
                $ctrl.sort = 'priceDescending';
            } else if (sort) {
                $ctrl.sort = sort;
            } else {
                $ctrl.sort = 'priceAscending';
            }

            $ctrl.hashData.paymentMode = $ctrl.hashData.paymentMode || "cash";
        };

        $ctrl.changePaymentMode = () => {
            const associatedFilters = $ctrl.filterManager.financeParams[
                $ctrl.hashData.paymentMode
            ];

            associatedFilters.forEach(paramName => {
                if ($ctrl.filters[paramName] &&
                    $ctrl.filters[paramName].value != undefined) {

                    $ctrl.hashData[paramName] = $ctrl.filters[paramName].value;
                }
            });

            $ctrl.getAPIData(0);
        };

        $ctrl.openDealershipTab = () => {
            $ctrl.sortOrFilter = 'filter';
            $ctrl.currentFilterTab = 'dealership';
        };

        $ctrl.scrollToOptions = () => {
            if ($ctrl.sortOrFilter !== 'filter') {
                $ctrl.sortOrFilter = 'filter';
            }

            $('html, body').animate({
                scrollTop: $('body').offset().top
            }, 500);
        };

        $ctrl.changeSortOrFilter = (value) => {
            if (value === $ctrl.sortOrFilter) {
                $ctrl.sortOrFilter = 'none';
            } else {
                $ctrl.sortOrFilter = value;
            }
        };

        $ctrl.sendAnalytics = (label) => {
            gtmAnalytics.trackEvent('event', {
                category: 'App-Suite-sni',
                label: label
            });
        };

        $ctrl.brandLabel = () => {
            if (!$ctrl.brands || !$ctrl.brands[$ctrl.activeBrand]) {
                // Nameplate fetch hasn't yet occurred
                return;
            }

            const activeBrand = $ctrl.brands[$ctrl.activeBrand];
            const brandName = activeBrand.name[$ctrl.language];

            return $translate.instant(
                'sni.list.filter.brandVehicles',
                { brand: brandName }
            );
        };

        $ctrl.shouldDisplayFilter = filterName => {
            if (!$ctrl.filters || !$ctrl.filters[filterName]) return false;

            const filter = $ctrl.filters[filterName];

            // 'LOV' is the checkbox type filter. If there are zero options in
            // the filter, we shouldn't display it (this seems to only happen
            // for Alfa Romeo vehicles).
            if (filter.type === 'LOV') {
                return filter.filters && filter.filters.length;
            } else {
                return true;
            }
        }

        $ctrl.resetMiniNavPosition = (navClass) => {
            window.setTimeout(() => {
                $rootScope.$broadcast('event:resetMiniNavPosition', navClass);
            }, 300);
            // $rootScope.$broadcast('event:resetMiniNavPosition', navClass);
        };

        $ctrl.changeActiveFilter = (value) => {
            $ctrl.currentFilterTab = value;
        };

        $ctrl.resetFilters = () => {
            $ctrl.hashData = {};
            $ctrl.setInitialScope();
            $ctrl.getAPIData(0);
            if ($ctrl.hashData.modelYearId != undefined && $ctrl.hashData.acode != undefined && $ctrl.hashData.trimPkg != undefined) {
                $ctrl.showBPSummary = true;
            } else {
                $ctrl.showBPSummary = false;
            }
        };

        $ctrl.updateResults = () => {
            $ctrl.sortOrFilter = 'none';
            $ctrl.getAPIData($ctrl.getCurrentPage());
        };

        $ctrl.onKeyPress = (event) => {
            if (event.keyCode == 13) {
                let labelElement = $(event.currentTarget);
                if (labelElement[0].control) {
                    labelElement[0].control.click();
                }
            }
        };

        $ctrl.changeSort = () => {
            const newSortParams =
                $ctrl.filterManager.generateSortParams($ctrl.sort);

            $.extend($ctrl.hashData, newSortParams);
            $ctrl.getAPIData(0);
        };

        $ctrl.setFilter = (filterName, value) => {
            const filter = $ctrl.filters[filterName];

            if (!filter) {
                throw Error(`Could not find filter with name '${filterName}'`);
            }

            $ctrl.filterManager.updateFilter($ctrl.hashData, filter, value);
            $ctrl.getAPIData(0);
        };

        $ctrl.setRangeFilter = (filterName) => {
            const filter = $ctrl.filters[filterName];

            $ctrl.filterManager.updateFilter(
                $ctrl.hashData, filter, filter.value
            );

            $ctrl.getAPIData(0);
        };

        $ctrl.rangeFilterTicks = (filterName) => {
            const filter = $ctrl.filters[filterName];

            const tickValues = [];
            for (let i = filter.min; i <= filter.max; i += filter.step) {
                tickValues.push(i);
            }

            return tickValues;
        };

        $ctrl.getLovValue = (filterName, optionName) => (
            $ctrl.filterManager.getLovValue(
                $ctrl.filters[filterName], optionName.toString()
            )
        );

        $ctrl.getLovDisabled = (filterName, optionName) => (
            $ctrl.filterManager.getLovDisabled(
                $ctrl.filters[filterName], optionName.toString()
            )
        );

        $ctrl.initCompareBar = () => {
            if ($ctrl.compareBarInitialized) {
                return;
            }

            $ctrl.stickyCompareBar = new Waypoint.Sticky({
                element: $('.js-C_VIL-compareBar')[0],
                direction: 'up',
                offset: 'bottom-in-view'
            });

            $ctrl.compareBarInitialized = true;
        };

        $ctrl.checkAffiliateMode = () => {
            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();
            $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

            if($ctrl.affiliateMode && $ctrl.affiliateId) {
                $ctrl.affiliateParams = getAffiliateIdUrlParam('');
                $ctrl.affiliateUrl = `?${$ctrl.affiliateParams}`;
            }
        };

        $ctrl.checkCampaign = () => {
            if ($ctrl.campaignCode !== null && $ctrl.campaignCode !== 'null'
                && $ctrl.campaignCode !== '') {
                sessionStorage.setItem('campaignCode', $ctrl.campaignCode);
                $ctrl.campaignParams = `campaignCode=${$ctrl.campaignCode}`;
            } else {
                sessionStorage.removeItem('campaignCode');
            }
        };

        $ctrl.getUrlHashData = () => {
            $ctrl.hashData = $ctrl.filterManager.parseUrlHash();
        };

        $ctrl.getCalloutStartingAtPrice = (callback) => {
            $http.get('/api/buildandprice/modelYear/prices?provinceCode='+$ctrl.userLocation.province+'&modelYearId='+$ctrl.calloutModelYearId).then((r) => {
                $ctrl.provincialIncentive = r.data.provincialIncentive;
                callback(r.data.startingAtPrice);
            });
        };

        $ctrl.getLatestYear = () => {
            var latestYear = $ctrl.hashData.model_year;
            if (angular.isUndefined(latestYear)) {
                latestYear = Math.max(
                    ...$ctrl.vehiclesList.map(function (item) {
                        return item.modelYear;
                    }));
            } else {
                if (angular.isObject($ctrl.hashData.model_year)) {
                    latestYear = Math.max(
                        ...Array.from($ctrl.hashData.model_year)).valueOf();
                }
            }

            return latestYear;
        };

        $ctrl.getBuildAndPriceUrl = () =>{

            var latestYear = $ctrl.getLatestYear();
			$ctrl.isVisible = true;

			const visibleVehicles = $ctrl.vehiclesList.filter((vehicle) => {
                if(angular.isUndefined($ctrl.visibleOnBuildAndPrice) || $ctrl.visibleOnBuildAndPrice.lastIndexOf(vehicle.modelYearId + ':true') != -1) {
                    return vehicle;
                }
            });


			const latestVehicles = visibleVehicles.filter((vehicle) => {
                if(vehicle.modelYear == latestYear.valueOf()) {
                    return vehicle;
                }
			});

			var url = $ctrl.buildAndPriceUrl;
			var selectedVehicle = null;
			if (latestVehicles.length ) {
				selectedVehicle = latestVehicles[0];

			}else {
				// fallback if there is no vehicles found for the latest year
                if (visibleVehicles.length) {
					selectedVehicle = visibleVehicles[0];
                }
            }

            if (selectedVehicle) {
                if ($ctrl.brandCode === 'alfaromeo') {
                    url = url + '/'
                        + $ctrl.brandCode + '/'
                        + $ctrl.nameplateCode + '/'
                } else {
                    url = url + '/'
                        + $ctrl.brandCode + '/'
                        + $ctrl.nameplateCode + '/'
                        + selectedVehicle.modelYear + '/'
                        + selectedVehicle.modelYearId
                }
            }else{
				$ctrl.isVisible = false;
            }

            return url;
        };

        $ctrl.getAPIData = (pageIndex, keepResult) => {
            // Options that are needed for the API call, but which should
            // not be displayed in the hash go here.
            const hiddenOptions = {
                page: pageIndex,
                latitude: $ctrl.userLocation.latitude,
                longitude: $ctrl.userLocation.longitude,
                nameplate: $ctrl.nameplateCode,
                year: $ctrl.modelYear
            };

            if ($ctrl.affiliateMode !== false) {
                hiddenOptions.affiliateId = $ctrl.affiliateId;
            }

            if ($ctrl.campaignCode !== null && $ctrl.campaignCode !== 'null') {
                hiddenOptions.campaignCode = $ctrl.campaignCode;
            }

            const dealerId = sessionStorage.getItem('dealerId');

            if (dealerId) {
                hiddenOptions.dealerCode = dealerId;
            }

            const apiUrl = $ctrl.filterManager.buildAPIUrl(
                $ctrl.apiUrlOptions,
                $.extend({}, $ctrl.hashData, hiddenOptions)
            );

            $ctrl.filterManager.writeUrlHash($ctrl.hashData);
            $ctrl.saveUrl();

            if (!keepResult) {
                $ctrl.pageIsReady = false;
            } else {
                $ctrl.moreContentLoading = true;
            }

            $http.get(apiUrl).then((r) => {
                $ctrl.apiData = r.data;
                $ctrl.apiData.incentives.forEach(elem => {
                    $ctrl.incentivesImages.push(elem);
                });

                $ctrl.saveFilters($ctrl.apiData.filter.filterAttributs);
                if (!pageIndex) {
                    $ctrl.vehiclesList = [];
                }

                $ctrl.vehiclesList = $ctrl.vehiclesList
                    .concat(r.data.vehicles)
                    .filter(item => item.vin)
                    .filter(item => $ctrl.siteGroupCode != 'daa' || item.isDaa == true);

                if($ctrl.incentivesImages.length > 0) {
                    $ctrl.vehiclesList.forEach((elem) => {
                        if(elem.incentCodes
                            && elem.incentCodes.length) {
                            // take only one incentive image
                            elem.incentCodes.forEach((incentCode) => {
                                if (elem.incentiveImage == null) {
                                    elem.incentiveImage = $ctrl.getIncentiveImage(incentCode);
                                }
                            });
                        }
                    });
                }

                $ctrl.assignCustomIncentiveImageToVehicle(r.data.customIncentives, $ctrl.vehiclesList);

                fcaSniTrimService.setMfgCodeForMultipleVehicles($ctrl.vehiclesList, $ctrl.maxIrisWidth);
                $ctrl.pageIsReady = true;
                $ctrl.moreContentLoading = false;
                // mark favorite vehicles
                markFavoriteVehicles();
                $ctrl.getDisclaimers();
                $ctrl.filterManager.writeUrlHash($ctrl.hashData);
                $ctrl.buildAndPricePath = $ctrl.getBuildAndPriceUrl();

                //call-out-banner
                if ($ctrl.showCallout == 'true') {
                    $ctrl.getCalloutStartingAtPrice((startingAtPrice) => {
                        let callOutBannerObj = {
                            isCallOutBanner: true,
                            startingJelly: $ctrl.calloutJelly,
                            startingPrice: startingAtPrice,
                            startingIcon: {
                                pointOne: $ctrl.calloutIconOne,
                                pointTwo: $ctrl.calloutIconTwo,
                                pointThree: $ctrl.calloutIconThree
                            }
                        };

                        let modelYearId = $ctrl.hashData.modelYearId !== undefined ? $ctrl.hashData.modelYearId : $ctrl.calloutModelYearId;
                        let modelYear = $ctrl.calloutModelYear;
                        let customOrderFindDealerListUrl = '/'+ $ctrl.language+'/custom-order/find-a-dealer?'+'brandCode='+$ctrl.brandCode+'&namePlate='+$ctrl.nameplateCode+'&modelYear='+modelYear+'&modelYearId='+modelYearId;

                        callOutBannerObj.url = customOrderFindDealerListUrl;

                        $translate('custom-order.call-out-banner.title',{ namePlate : $ctrl.nameplateName }).then(function (headline) {
                            callOutBannerObj.title = headline;
                        });

                        $translate('custom-order.call-out-banner.subtitle',{ namePlate : $ctrl.nameplateName }).then(function (headline) {
                            callOutBannerObj.subtitle = headline;
                        });

                        let hasCalloutBanner = $ctrl.vehiclesList.some((element) => {
                            return element.isCallOutBanner;
                        });

                        if (!hasCalloutBanner) {
                            $ctrl.vehiclesList.splice(3, 0, callOutBannerObj);
                        }
                    });
                }

                $timeout(() => {
                    $scope.$emit('component:loaded');
                    MOTOINSIGHT.updateView();
                });
            });
        };

        $ctrl.assignCustomIncentiveImageToVehicle = (images, vehicles) => {
            if(images.length > 0) {
                vehicles.forEach((vehicle) => {
                    if(vehicle.customIncentCode) {
                        vehicle.customIncentiveImage = images
                            .filter((image) => image.incentiveCode === vehicle.customIncentCode)[0];
                    }
                });
            }
        };

        $scope.$on('ua:userSignInSuccess', (event, data) => {

            UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                userAccountService.addToFavorites($ctrl.favoritedVehicle).then((response) => {
                    //need $scope.$apply for update display; without, need to click outside the container to update the heart
                    $scope.$apply(() => {
                        $ctrl.favoritedVehicle.favorited = true;
                        $ctrl.favoritedVehicle.favoriteId = response.id;
                    });
                });
                markFavoriteVehicles();
            });
        });

        $ctrl.getDisclaimers = () => {
            $('.incentiveDisclaimers').each(function() {
                let id = $(this).data('id').replace('incentive.', '');
                $ctrl.incentiveDisclaimersObject[id] = $(this).text();
            });
        };

        $ctrl.getIncentiveImage = (incentCode) => {
            return $ctrl.incentivesImages.filter((e) => e.incentiveCode === incentCode)[0];
        };

        $ctrl.saveFilters = (filters) => {
            filters.forEach(f => $ctrl.filters[f.name] = f);

            $ctrl.containsDisabledOptions =
                $ctrl.filterManager.containsDisabledOptions($ctrl.filters);
        };

        $ctrl.getCurrentPage = () =>
            parseInt($ctrl.apiData.currentPage);

        $ctrl.getMoreResults = () => {
            let newPageIndex = $ctrl.getCurrentPage() + 1;
            $ctrl.getAPIData(newPageIndex, true);
            if (newPageIndex + 1 == $ctrl.apiData.nbPage) {
                $('.js-C_VIL-loadMoreBtn').remove();
            }

        };

        $ctrl.getBuildPriceDataAPI = () => {
            if ($ctrl.hashData.modelYearId != undefined && $ctrl.hashData.acode != undefined && $ctrl.hashData.trimPkg != undefined) {
                $ctrl.showBPSummary = true;
                let apiUrl = '/api/buildandprice/' + $ctrl.language;
                apiUrl += '/brand/' + $ctrl.brandCode;
                apiUrl +='/nameplate/' + $ctrl.nameplateCode;
                apiUrl +='/year/' + $ctrl.hashData.model_year;
                apiUrl +='/modelyear/'+ $ctrl.hashData.modelYearId +'/trims';
                $http.get(apiUrl).then((r) => {
                    let trimObj = {};
                    r.data.trimGroups.forEach( (trimGroup) => {
                        trimGroup.trims.forEach((trim) => {
                            if (trim.acode === $ctrl.hashData.acode) {
                                $ctrl.buildPriceTrimGroup = trimGroup.code;
                                trimObj = trim;
                            }
                        });
                    });
                    r.data.drivetrains.forEach((drivetrain) => {
                        if (drivetrain.id === trimObj.drivetrain) {
                            $ctrl.buildPriceDriveTrain = drivetrain.description;
                        }
                    });
                    r.data.boxLengths.forEach((boxLength) => {
                        if (boxLength.id === trimObj.boxLength) {
                            $translate('sni.list.page.build-and-price-summary.the-boxlength', { aBoxLength : boxLength.description }).then(function (headline) {
                                $ctrl.buildPriceBoxLength = headline;
                            });
                        }
                    });
                    r.data.cabTypes.forEach((cabType) => {
                        if (cabType.id === trimObj.cabType) {
                            $ctrl.buildPriceCabType = cabType.description;
                        }
                    });
                    r.data.packages.forEach((aPackage) => {
                        if (aPackage.id === $ctrl.hashData.trimPkg) {
                            $ctrl.buildPricePackage = aPackage.id;
                        }
                    });
                });
            }
        };

        $ctrl.fetchNameplates = () => {
            $ctrl.brands = {};

            const apiUrl = `/data/inventories/dealer/${$ctrl.dealerCode}`;
            $http.get(apiUrl).then(r => {
                r.data.forEach(nameplate => {
                    if ($ctrl.siteGroupCode != 'daa' || nameplate.isDaa == true) {
                        if (!$ctrl.brands[nameplate.brandCode]) {
                            $ctrl.brands[nameplate.brandCode] = {
                                brandCode: nameplate.brandCode,
                                name: nameplate.brandName,
                                logoUrl: $ctrl.brandLogos[nameplate.brandCode],
                                nameplates: [],
                            }
                        }

                        $ctrl.brands[nameplate.brandCode].nameplates.push(nameplate);
                    }

                });

                $ctrl.activeBrand = Object.keys($ctrl.brands)[0];
            });
        };

        $ctrl.selectToCompare = (vehicle) => {
            vehicle.compared = !vehicle.compared;

            $ctrl.vinsToCompare = $ctrl.vehiclesList
                .filter(vehicle => vehicle.compared)
                .map(vehicle => vehicle.vin);

            $ctrl.initCompareBar();

            Waypoint.refreshAll();
            window.setTimeout(() => {
                Waypoint.refreshAll();
            }, 250);
        };

        $ctrl.addToFavorites = (vehicle, event) => {
            if (userAccountService.isLibraryAvailable()) {
                if (UA.isUserLoggedIn()) {
                    if (vehicle.favorited === true) {
                        //backend call to remove favorite
                        UA.removeInventoryVehicle(vehicle.favoriteId).then(
                            () => $scope.$apply(() => vehicle.favorited = false)
                        );
                    } else {
                        userAccountService.addToFavorites(vehicle).then((response) => {
                            //need $scope.$apply for update display; without, need to click outside the container to update the heart
                            $scope.$apply(() => {
                                vehicle.favorited = true;
                                vehicle.favoriteId = response.id;
                            });
                        });
                    }
                } else {
                    $ctrl.favoritedVehicle = vehicle;
                }
            }
        };

        $ctrl.handleAccordion = (vehicleIndex, key) => {
            let $buttons = $('.commonTileBig-specHeader[data-vehicle-index="' + vehicleIndex + '"]');
            $buttons.not('[data-vehicle-spec-index="' + key + '"]').removeClass('open');
            $buttons.filter('[data-vehicle-spec-index="' + key + '"]').toggleClass('open');
            $buttons.filter('[data-vehicle-spec-index="' + key + '"]').attr('aria-expanded', function(index, attr) {
                return attr === 'false' ? 'true' : 'false';
            });
        };

        $ctrl.moveDealerZone = () => {
            $('.C_DD-container').appendTo(".C_VIL-dealerHolder");
            $('.js-mapwaipoint').addClass("-stickyDisabled");
        };

        $ctrl.saveUrl = () => {
            sessionStorage.setItem(
                'C_NID-vehicle-inventory-list-url',
                location.href
            );
        };

        let markFavoriteVehicles = () => {
            if (userAccountService.isLibraryAvailable() && UA.isUserLoggedIn()) {
                UA.getInventoryVehicles().then((favoriteVehiclesList) => {
                    $ctrl.vehiclesList.forEach((vehicle) => {
                        let favoriteVehicle = favoriteVehiclesList.find((favorite) => favorite.vin === vehicle.vin);
                        if (favoriteVehicle !== undefined) {
                            vehicle.favorited = true;
                            vehicle.favoriteId = favoriteVehicle.id;
                        }
                    });
                });
            }
        };

        $ctrl.route = () => {
            const hashCompareMode = location.hash.includes('mode=compare');
            if (!hashCompareMode && $ctrl.compareMode) {
                $ctrl.returnFromCompare();
            } else if (hashCompareMode && !$ctrl.compareMode) {
                $ctrl.goToCompare();
            }
        };

        $ctrl.initHashListener = () => {
            window.addEventListener('hashchange', $ctrl.route);
        };

        $ctrl.goToCompare = () => {
            $ctrl.pageIsReady = false;
            $ctrl.pageHeaderElement.hide();
            $ctrl.dealerDetailsElement.hide();
            $ctrl.compareBarZone.hide();
            $ctrl.ctaHolder.hide();

            if (!$ctrl.vinsToCompare.length) {
                if (typeof $ctrl.hashData.vins === 'string') {
                    $ctrl.vinsToCompare = [$ctrl.hashData.vins]
                } else {
                    $ctrl.vinsToCompare = Array.from($ctrl.hashData.vins);
                }
            }

            $timeout(() => $ctrl.compareMode = true);
        };

        $ctrl.returnFromCompare = () => {
            $ctrl.pageHeaderElement.show();
            $ctrl.compareBarZone.show();
            $ctrl.ctaHolder.show();

            $ctrl.pageIsReady = false;
            $ctrl.dealerDetailsElement.show();
            $ctrl.compareMode = false;
            $ctrl.compareBarInitialized = false;
            delete $ctrl.hashData.mode;
            delete $ctrl.hashData.compareMode;
            delete $ctrl.hashData.vins;
            $ctrl.vehicles = [];
            $ctrl.vinsToCompare = [];
            $ctrl.setupList();
        };

        $ctrl.checkIfNameplateIncludesYearOrBrand = (nameplate, yearOrBrand) => {
            return nameplate.includes(yearOrBrand);
        };

        $ctrl.removeColoursInBrackets = (colour) => {
            return colour.replace(/\[(.+?)\]/g, '');
        };

        $ctrl.getTrimName = label => {
            return label || $ctrl.nameplateName;
        };

        /**
         * This horribly named method actually serves to build the contact a dealer data model.
         */
        $ctrl.getTrimgroup = (vehicle) => {
            return {
                "dialogTitle": "dealer.request.info",
                "formOptions": {
                    "context": "441-300",
                    "selectedDealerCode": vehicle.dealerCode,
                    "brandCode": vehicle.brand,
                    "selectedVehicle": {
                        "name": {
                            "en": vehicle.trimGroup.en,
                            "fr": vehicle.trimGroup.fr
                        },
                        "code": vehicle.nameplateCode,
                        "year": vehicle.modelYear,
                        "vin" : vehicle.vin
                    },
                    "contactReason": "info",
                    "showDealerExtendedInfo": true,
                    "showFullDealerSelectFlow": false,
                    "allowChangeDealer": true,
                    "allowChangeNameplate": false,
                    "allowChangeNameplateModel": false
                }
            };
        };

        $ctrl.generateAltTag = (pModelYear, pBrandName, pNamepateName, pTrimGroup) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName + ' ' + pTrimGroup;
            if(window.FCA_SITES_CONFIG.language == 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear + ' ' + pTrimGroup;
            }
            return altText;
        }
    }
})();
