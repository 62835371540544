(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaSummary', alfaSummary);

	function alfaSummary() {
		const $service = this;

		$service.getOptionsForSections = (sections, ignoredEccs = [], specificEccs = []) => {
			return sections.reduce((result, section) => {
				result = result.concat($service.getOptionsForSection(section, ignoredEccs, specificEccs));
				return result;
			}, []);
		}

		$service.getOptionsForSection = (section, ignoredEccs = [], specificEccs = []) => {
			return section.categories.reduce((result, category) => {
				result = result.concat($service.getOptionsForCategory(category, ignoredEccs, specificEccs));
				return result;
			}, []);
		}

		$service.getOptionsForCategory = (category, ignoredEccs, specificEccs) => {
			if (ignoredEccs.length > 0) {
				return category.options.filter(option => {
					return option.selected && !ignoredEccs.includes(option.ecc);
				});
			} else if (specificEccs.length > 0) {
				return category.options.filter(option => {
					return option.selected && specificEccs.includes(option.ecc);
				});
			}
			return category.options.filter(option => option.selected);
		}
	}
})();
