(function() {
	angular
		.module('fca.daaContactDealer')
		.component('daaShotgunQuote', {
			templateUrl: '/components/contact-dealer/shotgun-quote/shotgun-quote.html',
			bindings: {
				selectedDealerCodes: '<',
				selectedNameplate: '<',
				selectedRegion: '<',
				show: '<',
				brandCode: '<',
				analyticsCategory: '@',
				analyticsId: '@'
			},
			controller: daaShotgunQuoteController
		});

	function daaShotgunQuoteController($rootScope, ContactDealer, externalConfigLoader) {
		'ngInject';

		let storeShotgunDealer = (dealer) => {
			this.isChangingDealer[dealer.code] = false;
			this.shotgunDealers.push(dealer);

			$rootScope.$emit('contact-dealer.add-shotgun-dealer', dealer);
		};

		let replaceShotgunDealer = (dealer) => {
			let position = this.shotgunDealers.indexOf(this.activeChangingDealer);

			this.removeDealer(this.activeChangingDealer);
			this.shotgunDealers.splice(position, 0, dealer);

			$rootScope.$emit('contact-dealer.add-shotgun-dealer', dealer);
		};

		this.activeChangingDealer = null;

		this.isChangingDealer = {};

		this.shotgunDealers = [];

		this.addDealer = (event) => {
			if (event) {
				event.preventDefault();
			}

			let loc = this.selectedRegion;
			let brand = externalConfigLoader.loadConfig('DAA', 'BRAND').getConfig('name');
			// Override brand to load nameplates
			if (this.brandCode !== undefined) {
				brand = this.brandCode;
			}

			ContactDealer
				.getOtherDealers(brand, this.selectedDealerCodes, loc.latitude, loc.longitude)
				.then((dealersArr) => {
					// automatically add the first from the list
					storeShotgunDealer(dealersArr[0]);
				});
		};

		this.removeDealer = (dealer, event) => {
			if (event) {
				event.preventDefault();
			}

			this.shotgunDealers.splice(this.shotgunDealers.indexOf(dealer), 1);

			$rootScope.$emit('contact-dealer.remove-shotgun-dealer', dealer);
		};

		this.changeDealer = (dealer, event) => {
			if (event) {
				event.preventDefault();
			}

			/* Cancel change on any other shotgun dealer */
			if (this.activeChangingDealer) {
				this.cancelChangeDealer(this.activeChangingDealer.code)(event);
			}

			this.activeChangingDealer = dealer;
			this.isChangingDealer[dealer.code] = true;
		};

		this.selectDealer = () => {
			return (dealer, event) => {
				if (event) {
					event.preventDefault();
				}

				replaceShotgunDealer(dealer);
				this.cancelChangeDealer(dealer.code)(event);
			};
		};

		this.cancelChangeDealer = (dealerCode) => {
			return (event) => {
				if (event) {
					event.preventDefault();
				}

				this.activeChangingDealer = null;
				this.isChangingDealer[dealerCode] = false;
			};
		};

		this.getCTADealerAnalyticForminteraction = () => {
			return `Add ${this.shotgunDealers.length ? 'another ': ''}dealer`;
		}

		this.getCTADealerLabel = () => {
			return `contact.add${this.shotgunDealers.length ? '.another': ''}.dealer`;
		}
	}
})();
