const Logger = (function() {
	let isProduction = window.FCA_SITES_CONFIG.environment === 'prod';

	// Automatically determine if production based on environment variable


	return {
		log: function(...messages) {
			if (!isProduction) {
				console.log(...messages);
			}
		},

		info: function(...messages) {
			if (!isProduction) {
				console.info(...messages);
			}
		},

		warn: function(...messages) {
			if (!isProduction) {
				console.warn(...messages);
			}
		},

		error: function(...messages) {
			console.error(...messages);
		}
	};
})();

