(function () {
	angular
		.module('fca.brandApp')
		.directive('autoshowLazyTopic', AutoshowLazyTopic);

	function AutoshowLazyTopic() {
		return {
			restrict: 'A',
			require: {
				autoshowOverlayCtrl: '?autoshowOverlay'
			},
			scope: true,
			bindToController: {
			},
			controllerAs: '$autoshowLazyTopicCtrl',
			controller: AutoshowLazyTopicController
		};

		function AutoshowLazyTopicController(fcaPartialService, $timeout, $rootScope) {
			'ngInject';

			const $autoshowLazyTopicCtrl = this;

			$autoshowLazyTopicCtrl.pageContent = null;

			$autoshowLazyTopicCtrl.loadTopic = (blockId, event) => {
				let eventTarget = event.target;

				if($autoshowLazyTopicCtrl.autoshowOverlayCtrl) {
					$autoshowLazyTopicCtrl.autoshowOverlayCtrl.setLoader(true);
				}

				fcaPartialService.getBlockContent(
					blockId,
					(responseContent) => {

						$timeout(() => {
							$autoshowLazyTopicCtrl.pageContent = responseContent;

							if($autoshowLazyTopicCtrl.autoshowOverlayCtrl) {
								$autoshowLazyTopicCtrl.autoshowOverlayCtrl.manageOverlayOpen(eventTarget);
							}
						});
					},
					(errorMessage) => {
						if($autoshowLazyTopicCtrl.autoshowOverlayCtrl) {
							$autoshowLazyTopicCtrl.autoshowOverlayCtrl.setLoader(false);
						}
					});
			}

			$rootScope.$on('autoshow-overlay:closed', () => {
				$timeout(() => {
					$autoshowLazyTopicCtrl.pageContent = null;
				});
			});
		}
	}
})();
