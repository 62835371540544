(function() {
	angular
		.module('fca.pageLayout')
		.directive('fcaNavigationMenuItemHighlight', [fcaNavigationMenuItemHighlight]);

	function fcaNavigationMenuItemHighlight($timeout, matchmedia, MEDIAQUERIES) {
		return {
            require: '^fcaNavigationMenu',
			restrict: 'A',
			scope: true,
			bindToController: true,
			controllerAs: '$ctrl',
			controller: FcaNavigationMenuItemHighlightController
		};

		function FcaNavigationMenuItemHighlightController($location, $element) {
			'ngInject';

			this.$onInit = () => {
				const currentPageUrl = $location.url();
				const linkElem = $element.find('.main-nav-top-link');
				const linkUrl = linkElem.data('relative-path');
				if (linkUrl && currentPageUrl.includes(linkUrl) && !linkElem.hasClass('is-current-page')) {
					linkElem.addClass('is-current-page');
				}
			}
		}
	}
})();
