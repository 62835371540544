(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaPersonalizationScenario3', fcaPersonalizationScenario3);

    function fcaPersonalizationScenario3() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                token: '@',
                userId: '@',
                analyticscategory: '@',
                analyticsid: '@'
            },
            controllerAs: '$fcaPersonalizationScenario3Ctrl',
            controller: fcaPersonalizationScenario3Controller
        };
    }

    function fcaPersonalizationScenario3Controller() {
        'ngInject';

        const $ctrl = this;

        $ctrl.$onInit = () => {
            // logic for scenario 3 triggers goes here
        };
    }
})();
