/* enableDisableFocusOnElements - enable or disable focus on all interactive elements
 to them
   enableTabIndex - enable or disable tab focus on an element by removing/adding tabindex -1
 */

(function() {
    angular
        .module('fca.commonServices')
        .service('focusFunctions', focusFunctionsController);
    
    function focusFunctionsController () {
        'ngInject';
        
        let $service = this;

        $service.init = () => {
            window.focusFunctions = this;
        }
        
        $service.enableDisableFocusOnElements = (container, enable) => {
            if (container) {
                const allLinks = container.querySelectorAll('* > a');
                if (allLinks && allLinks.length > 0) {
                    for (let item of allLinks) {
                        $service.enableTabIndex(item, enable);
                    }
                }
                const allButtons = container.querySelectorAll('* > button');
                if (allButtons && allButtons.length > 0) {
                    for (let item of allButtons) {
                        $service.enableTabIndex(item, enable);
                    }
                }
                const allInputs = container.querySelectorAll('* > input');
                if (allInputs && allInputs.length > 0) {
                    for (let item of allInputs) {
                        $service.enableTabIndex(item, enable);
                    }
                }
                
                const selectorTabindexEnabled = '[tabindex="0"]';
                const selectorTabindexDisabled = '[data-previous-tabIndex="0"]';
                let selectorTabindex = selectorTabindexEnabled;

                if (enable) {
                    selectorTabindex = selectorTabindexDisabled;
                }

                const allInteractiveDivs = container.querySelectorAll('* > div' + selectorTabindex);
                if (allInteractiveDivs && allInteractiveDivs.length > 0) {
                    for (let item of allInteractiveDivs) {
                        $service.enableTabIndex(item, enable);
                    }
                }

                const allInteractiveUl = container.querySelectorAll('* > ul' + selectorTabindex);
                if (allInteractiveUl && allInteractiveUl.length > 0) {
                    for (let item of allInteractiveUl) {
                        $service.enableTabIndex(item, enable);
                    }
                }

                const allInteractiveTr = container.querySelectorAll('* > tr' + selectorTabindex);
                if (allInteractiveTr && allInteractiveTr.length > 0) {
                    for (let item of allInteractiveTr) {
                        $service.enableTabIndex(item, enable);
                    }
                }
            }
        };
    
        $service.enableTabIndex = (item, enable) => {
            if (!enable) {
                if (item.getAttribute('tabindex') && item.getAttribute('tabindex') >= 0) {
                    item.setAttribute('data-previous-tabIndex', item.getAttribute('tabindex'));
                }
                item.setAttribute('tabindex', -1);
            } else {
                let newTabIndex = null;
                if (item.getAttribute('data-previous-tabIndex')) {
                    newTabIndex = item.getAttribute('data-previous-tabIndex');
                    item.setAttribute('tabindex', newTabIndex);
                    item.removeAttribute('data-previous-tabIndex');
                } else {
                    item.removeAttribute('tabindex');
                }
            }
        };
    }
})();