(function() {
	angular
		.module('fca.daaPersonalizationModal')
		.directive('daaPersonalizationModal', daaPersonalizationModal);

    function daaPersonalizationModal() {
        return {
            restrict: 'A',
            scope: true,
            controllerAs: '$daaModalCtrl',
            bindToController: {
                disclaimerDealerPrice: "@",
                disclaimerMsrp: "@",
                location: '<',
                previousArrowLabel: '@',
                nextArrowLabel: '@'
            },
            controller: daaPersonalizationModalCtrl
        }

		function daaPersonalizationModalCtrl($element, $rootScope, $scope, $timeout, $window, gtmAnalytics, $http, scenarioOrchestrator, personalizationDaaScenario, $q) {
			'ngInject';

            const $ctrl = this;
            $ctrl.vehicleObject = [];
            $ctrl.language = FCA_SITES_CONFIG.language;

            $ctrl.brandCode = FCA_SITES_CONFIG.name;

            $ctrl.nameplateCode = '';
            $ctrl.nameplateYear = '';
            $ctrl.nameplate = '';
            $ctrl.nameplateTrimgroup = '';
            $ctrl.year = '';

            $ctrl.trimGroups = {};
            $ctrl.trimGroup = '';
            $ctrl.trimGroupCode = '';
            $ctrl.modelYearId = '';
            $ctrl.mfgCode = '';
            $ctrl.fullVehicle = '';
            $ctrl.selectedTrim = '';
            $ctrl.acode = '';
            $ctrl.provinceCode = '';
            $ctrl.scratchSave = '';

            $ctrl.dealers = [];
            $ctrl.dealer = '';
            $ctrl.currentDealerData = [];
            $ctrl.maxAmountOfDealers = 3;
            $ctrl.mobileMaxDealers = 0;
            $ctrl.intNbOfDealersSelected = 0;

            $ctrl.dealerName = '';
            $ctrl.dealerUrl = '';
            $ctrl.dealerLongitude = '';
            $ctrl.dealerLatitude = '';
            $ctrl.dealerImages = [];

            $ctrl.sniVehicles = [];

            $ctrl.hashParameters = {};
            $ctrl.vin = '';
            $ctrl.isSelectedVin = true;
            $ctrl.isBuyingProcess = false;

            $ctrl.imageAlt = "";
            $ctrl.objJellyTrimGroupUrl = {};
            $ctrl.jellyUrl = '';
            $ctrl.allJellyUrls = '';
            $ctrl.rootIrisUrl = FCA_SITES_CONFIG.irisJellyBaseUrl + "/mediaserver/iris?client=FCAUS&market=U";

            $ctrl.legacyRootURl = FCA_SITES_CONFIG.jelliesUrl.replace('jellies/renditions/', '');
            $ctrl.vehicleType = '';
            $ctrl.paint = '';
            $ctrl.sa = '';
            $ctrl.vehicle = '';
            $ctrl.mfgCode = '';
            $ctrl.jellyBrandCode = '';
            $ctrl.jellyType = '';

            $ctrl.$onInit = () => {
                scenarioOrchestrator.registerScenario('fcaPersonalizationScenario_5_1');

                if (scenarioOrchestrator.canShowModal('fcaPersonalizationScenario_5_1')) {
                    $ctrl.startModalProcess(scenarioOrchestrator.daaScenarioData())
                }
            }

            $ctrl.startModalProcess = (data) => {
                $ctrl.getVehicleData(data.vin)
                    .then((sniVehicle) => {
                        return $ctrl.getDealerData(sniVehicle)
                    })
                    .then((sniVehicle) => {
                        $ctrl.getModalContent(sniVehicle, data.vin)
                    })
                    .catch(e => {
                        if (e.status == 404) { // If the VIN doesn't exist we search fo a similar one
                            $ctrl.getSimilarVehicle(scenarioOrchestrator.daaScenarioData())
                                .then((sniVehicle) => {
                                    return $ctrl.getSniOffer(sniVehicle)
                                })
                                .then((sniVehicle) => {
                                    return $ctrl.getDealerData(sniVehicle)
                                })
                                .then((sniVehicle) => {
                                    $ctrl.getModalContent(sniVehicle, sniVehicle.vin)
                                })
                        } else {
                            console.error(e);
                        }
                    });
            }

            $ctrl.getVehicleData = (vin) => {
                let defer = $q.defer();
                $http.get(`/data/inventories/${$ctrl.brandCode}/vehicle/offer/${vin}?language=${$ctrl.language}`).then((result) => {
                    let sniVehicle = result.data;
                    defer.resolve(sniVehicle);

                }).catch(reason => {
                    defer.reject(reason);
                });
                return defer.promise;
            };

            $ctrl.getSimilarVehicle = (data) => {
                // title change condition
                $ctrl.isSelectedVin = false;

                let defer = $q.defer();
                let latitude = $ctrl.location.latitude;
                let longitude = $ctrl.location.longitude;

                $http.get(`/data/inventories/${$ctrl.brandCode}/search/vehicles/${data.nameplatecode}?activeTab=finance&year=${data.year}&paymentMode=cash&trim=${data.trimgroup}&withPhoto=1&page=0&latitude=${latitude}&longitude=${longitude}&nameplate=${data.nameplatecode}`).then((result) => {
                    let sniVehicle = result.data.vehicles[0];
                    defer.resolve(sniVehicle);
                }).catch(reason => {
                    defer.reject(reason);
                });

                return defer.promise;
            }

            $ctrl.getSniOffer = (sniVehicle) => {
                let vin = sniVehicle.vin;
                let defer = $q.defer();

                $http.get(`/data/inventories/${$ctrl.brandCode}/vehicle/offer/${vin}?language=${$ctrl.language}`).then((result) => {
                    sniVehicle.offer = result.data.offer
                    sniVehicle.dealerPrice = result.data.dealerPrice
                    defer.resolve(sniVehicle);

                }).catch(reason => {
                    console.error('Offer call failed : ', reason);
                    defer.reject(reason);
                });

                return defer.promise;
            }

            $ctrl.getDealerData = (sniVehicle) => {
                let defer = $q.defer();

                $http.get(`/data/dealers/${$ctrl.brandCode}/${sniVehicle.dealerCode}`).then((r) => {
                    sniVehicle.dealerData = r.data;
                    $ctrl.sniVehicles.push(sniVehicle);

                    $ctrl.dealerLongitude = sniVehicle.dealerData.longitude;
                    $ctrl.dealerLatitude = sniVehicle.dealerData.latitude;
                    $ctrl.dealerName = sniVehicle.dealerData.name.toUpperCase();

                    if($ctrl.sniVehicles.length > 0) {
                        $ctrl.vehicleImages = $ctrl.sniVehicles[0].images;
                        $ctrl.vehicleJellies = $ctrl.sniVehicles[0].jellies;
                    }

                    defer.resolve(sniVehicle);

                }).catch(reason => {
                    defer.reject(reason);
                });

                return defer.promise;
            }

            $ctrl.getVehicleJelly = (argBrandCode, argNameplateCode, argYear, argModelYearId) => {
                let brandCode = argBrandCode;
                let nameplateCode = argNameplateCode;
                let year = argYear;
                let modelYearId = argModelYearId;

                const jellyApiUrl = `/api/lead/${$ctrl.language}/brand/${brandCode}/nameplate/${nameplateCode}/year/${year}/modelyear/${modelYearId}/trimgroup`;
                return $http.get(jellyApiUrl).then((result) => {
                    $ctrl.objJellyTrimGroupUrl = result.data;

                    if ($ctrl.objJellyTrimGroupUrl.legacyJellyUrl) {
                        $ctrl.jellyType = 'legacy';
                        $ctrl.jellyUrl = $ctrl.objJellyTrimGroupUrl.legacyJellyUrl;
                    } else if ($ctrl.objJellyTrimGroupUrl.hasOwnProperty('trimIrisJellyOptions') && $ctrl.objJellyTrimGroupUrl.trims[0].packages.length) {
                        $ctrl.jellyType = 'iris';

                        $ctrl.vehicleType = $ctrl.objJellyTrimGroupUrl.hasOwnProperty('cposVehicleType') ? $ctrl.objJellyTrimGroupUrl.trimIrisJellyOptions.cposVehicleType : '';

                        if (!$ctrl.hashParameters.paint) {
                            $ctrl.paint = $ctrl.objJellyTrimGroupUrl.trims[0].primaryColourCode;
                        }

                        $ctrl.mfgCode = $ctrl.objJellyTrimGroupUrl.trims[0].mfgCode;
                        $ctrl.jellyBrandCode = $ctrl.brandCode.substr(0, 1).toUpperCase();

                        if ($ctrl.brandCode === 'fiat') {
                            $ctrl.jellyBrandCode = 'X';
                        }
                        if ($ctrl.brandCode === 'alfaromeo') {
                            $ctrl.jellyBrandCode = 'Y';
                        }

                        if ($ctrl.vehicleType !== '') {
                            $ctrl.sa =
                                $ctrl.mfgCode + "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(0, 1) +
                                $ctrl.vehicleType + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(2, 1) +
                                "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code;
                        } else {
                            $ctrl.sa =
                                $ctrl.mfgCode + "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(0, 1) +
                                "D" + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(2, 1) +
                                "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code;
                        }

                        $ctrl.vehicle = year + "_" + $ctrl.mfgCode.substr(0, 2);

                        $ctrl.jellyUrl =
                            `${$ctrl.rootIrisUrl}&brand=${$ctrl.jellyBrandCode}` +
                            `&vehicle=${$ctrl.vehicle}&paint=${$ctrl.paint}&sa=${$ctrl.sa}` +
                            `&pov=${$ctrl.jellyPov}&width=${$ctrl.jellyMobileWidth}` +
                            `&height=${$ctrl.jellyMobileHeight}&bkgnd=${$ctrl.background}`;

                        $ctrl.allJellyUrls = '';

                        // Mobile
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.mobile.mediaQuery.url,
                            $ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 1) + "']";

                        // Mobile Retina
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.mobile.mediaQueryRetina.url,
                            $ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 2) + "']";

                        // Tablet
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.tablet.mediaQuery.url,
                            $ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 1) + "']";

                        // Tablet Retina
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.tablet.mediaQueryRetina.url,
                            $ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 2) + "']";

                        // Desktop
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.desktop.mediaQuery.url,
                            $ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 1) + "']";

                        // Desktop Retina
                        $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.desktop.mediaQueryRetina.url,
                            $ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 2) + "']";
                    }

                    if ($ctrl.jellyUrl !== '') {
                        // $ctrl.checkIfJellyIsLoading();
                    }
                });
            };

            $ctrl.getModalContent = (sniVehicle, vin) => {
                let offer = sniVehicle.offer;
                sniVehicle.vin = vin;
                $ctrl.vin = vin;
                $ctrl.numberOfUser = 0;
                $ctrl.brandCode = offer.brand;
                $ctrl.dealer = sniVehicle.dealerCode;
                $ctrl.acode = offer.acode;
                $ctrl.trimGroup = offer.trimGroup[$ctrl.language];
                $ctrl.modelYearId = offer.modelYearId;
                $ctrl.year = offer.modelYear;
                $ctrl.nameplateCode = offer.nameplatecode;
                $ctrl.nameplate = offer.nameplate[$ctrl.language];
                $ctrl.msrp = offer.msrp;
                $ctrl.dealerPrice = sniVehicle.dealerPrice;
                $ctrl.nameplateTrimgroup = $ctrl.nameplate.toUpperCase() + ' ' + $ctrl.trimGroup.toUpperCase();
                $ctrl.isBuyingProcess = scenarioOrchestrator.daaScenarioData().status == 'buy';

                if (sniVehicle.irisJellyUrl) {
                    sniVehicle.jellyUrl = sniVehicle.irisJellyUrl;
                } else if (sniVehicle.jellies) {

                    sniVehicle.jellyUrl = $ctrl.legacyRootURl + sniVehicle.jellies[0].jellies[1].path;
                } else {
                    $ctrl.getVehicleJelly($ctrl.brandCode, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId).then(result => {
                        sniVehicle.jellyUrl = $ctrl.jellyUrl;
                    });
                }

                if ($ctrl.language === 'en') {
                    $ctrl.nameplateYear = `${$ctrl.year} ${offer.nameplate.en}`;
                } else {
                    $ctrl.nameplateYear = `${offer.nameplate.fr} ${$ctrl.year}`;
                }

                let formatBrandCode = $ctrl.brandCode === 'ramtruck' ? 'Ram' : $ctrl.brandCode.charAt(0).toUpperCase();
                $ctrl.imageAlt = `${$ctrl.year} ${formatBrandCode} ${$ctrl.nameplateTrimgroup}`;

                //param vin, returns a Promise object
                UA.getOtherUsersViewsCount(vin)
                    .then(result => {
                        $ctrl.numberOfUser = Number.isInteger(parseInt(result)) ? result : 0;
                    })

                $ctrl.manageModal();

                gtmAnalytics.trackEvent('event', {
                    category: 'daa-personalization',
                    label: `daa-personalization-${$ctrl.isBuyingProcess ? 'start buying process' : 'view this vehicle'}-modal-trigger`
                });
            }

            // Send emit to open modal
            $ctrl.manageModal = () => {
                $scope.$emit('fcaModal:open');
                personalizationDaaScenario.addPreQuietThreshold();
            }

            $ctrl.scenarioEnded = () => personalizationDaaScenario.destroy();

            $ctrl.closeModal = () => $scope.$emit('fcaModal:close');

            // slick related
            $ctrl.initSlick = () => {
                $timeout(() => {
                    $('.DAAP-carousel-large').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        accessibility: true,
                        mobileFirst: true,
                        centerPadding: "10%",
                        centerMode: true,
                        infinite: true,
                        responsive: [
                            {
                                breakpoint: 1023,
                                settings: {
                                    centerPadding: "0px"
                                }
                            }
                        ]
                    });
                })
            }

            $ctrl.initThumbnailSlick = () => {
                $timeout(() => {
                    $ctrl.prevArrow = `<button role="button" class="left-arrow" aria-label="${$ctrl.previousArrowLabel}" data-analyticsid="daa-personalization-${$ctrl.isBuyingProcess ? 'start buying process' : 'view this vehicle'}-modal" data-analyticsvalue="Image Select Arrow Left"><i class="fcaicon fcaicon-chevron-left" data-analyticsid="daa-personalization-${$ctrl.isBuyingProcess ? 'start buying process' : 'view this vehicle'}-modal" data-analyticsvalue="Image Select Arrow Left"></i></button>`;
                    $ctrl.nextArrow = `<button role="button" class="right-arrow" aria-label="${$ctrl.nextArrowLabel}" data-analyticsid="daa-personalization-${$ctrl.isBuyingProcess ? 'start buying process' : 'view this vehicle'}-modal" data-analyticsvalue="Image Select Arrow Right"><i class="fcaicon fcaicon-chevron-right" data-analyticsid="daa-personalization-${$ctrl.isBuyingProcess ? 'start buying process' : 'view this vehicle'}-modal" data-analyticsvalue="Image Select Arrow Right"></i></button>`;

                    $('.DAAP-carousel-small').slick({
                        slidesToScroll: 1,
                        slidesToShow: 5,
                        arrows: true,
                        accessibility: true,
                        asNavFor: '.DAAP-carousel-large',
                        focusOnSelect: true,
                        mobileFirst: true,
                        infinite: true,
                        nextArrow: $ctrl.nextArrow,
                        prevArrow: $ctrl.prevArrow,
                    });
                })
            }
		}
	}
})();
