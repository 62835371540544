(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaWarranty', fcaAlfaWarranty);

	function fcaAlfaWarranty() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'warrantyCtrl',
			controller: fcaAlfaWarrantyController
		};

		function fcaAlfaWarrantyController($element, $interval, $timeout, $scope, $attrs) {
			'ngInject';

			this.modelList = [];
			this.pdfLinkList = [];
			const jsonData = JSON.parse($attrs.jsonData);

			this.$onInit = () => {
				const yearElement = $element[0].querySelector("select[name='year']");
				let currentYear = yearElement.options[yearElement.selectedIndex].value;

				this.updateModelList(currentYear);

				const modelElement = $element[0].querySelector("select[name='model']");
				if (this.modelList.length > 0 ) {
					this.updatePdf(currentYear, this.modelList[0].code);
				}

				yearElement.onchange = () => {
					currentYear = yearElement.options[yearElement.selectedIndex].value;
					this.updateModelList(currentYear);
					$scope.$apply();
					if (this.modelList.length > 0) {
						const currentModel = modelElement.options[modelElement.selectedIndex].value;
						this.updatePdf(currentYear, currentModel);
						$scope.$apply();
					}
				}
				modelElement.onchange = () => {
					const modelIndex = modelElement.selectedIndex > 0 ? modelElement.selectedIndex : 0;
					let currentModel = modelElement.options[modelIndex] && modelElement.options[modelIndex].value;
					currentModel = modelElement.options[modelElement.selectedIndex].value;
					this.updatePdf(currentYear, currentModel);
					$scope.$apply();
				}
			};

			this.updateModelList = year => {
				const getCurrentYear = jsonData.find(yearList => {
					return yearList.year === year;
				});

				this.modelList = getCurrentYear && getCurrentYear.models ? getCurrentYear.models : [];
				if (this.modelList.length <= 0) {
					this.pdfLinkList = [];
				}
			}

			this.updatePdf = (year, model) => {
				const getCurrentYear = jsonData.find(yearList => {
					return yearList.year === year;
				})
				if (getCurrentYear) {
					const getCurrentModel = getCurrentYear.models.find(modelList => {
						return modelList.code == model;
					});

					this.pdfLinkList = getCurrentModel && getCurrentModel.files ? getCurrentModel.files : [];
				}
			}
		}
	}
})();
