(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('fcaTel', fcaTel);

    function fcaTel() {
        'ngInject';

        return function (tel) {
            if (!tel) {
                return '';
            }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            var countryCode, areaCode, number;

            switch (value.length) {
                case 1:
                case 2:
                case 3:
                    areaCode = value;
                    break;

                default:
                    areaCode = value.slice(0, 3);
                    number = value.slice(3);
            }

            if(number) {
                if(number.length>3) {
                    number = number.slice(0, 3) + '-' + number.slice(3,7);
                } else {
                    number = number;
                }

                return ('(' + areaCode + ') ' + number).trim();
            } else {
                return '(' + areaCode;
            }
        };
    }
})();
