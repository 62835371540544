(function () {
	angular
		.module('alfaromeo.buildAndPrice.director')
		.component('alfaDirector', {
			controller: afDirector,
			controllerAs: '$ctrl',
			templateUrl: '/brand-specific/alfaromeo/components/director/alfaromeo-director.html',
			bindings: {
				disclaimerBasePrice: '@',
				disclaimerDealerAdminFees: '@',
				disclaimerNetPrice: '@',
				disclaimerFinancing: '@',
				disclaimerLeasing: '@',
				modelYearId: '@',
				acode: '@',
				brand: '@',
				nameplate: '@',
				year: '@',
				trimGroupName: '@',
				trimName: '@',
				trimNameEn: '@',
				trimNameFr: '@',
				engineThumbnail: '@',
				engineVideo: '@',
				engineDescription: '@',
				engineTitle: '@',
				engineSubtitle: '@',
				transmissionThumbnail: '@',
				transmissionDescription: '@',
				transmissionTitle: '@',
				transmissionSubtitle: '@',
				baseSniBuildUrl: '@',
				contactDealerDesktop: '@',
				contactDealerMobile: '@',
				findDealerDesktop: '@',
				findDealerMobile: '@',
				sniDesktop: '@',
				sniMobile: '@',
				testDriveDesktop: '@',
				testDriveMobile: '@',
				alfaPlaceholderImage: '@',
				sectionHeaderLabel: '@',
				calculatorDisclaimers: '<',
				selectedNameplateName: '@',
				programLogoPath: '@',
				programDescription: '@',
				programsBanner: '@',
				programLogoAltText: '@',
				programShowShopOnlineLink: '@',
				programShowBanner: '@',
				programBaseSniBuildUrl: '@',
				eshopUrl: '@',
				customOrderActivated: '@',
				hasCustomOrderSni: '@',
				engineIcon: '@',
				transmissionIcon: '@',
                affiliateLogoSource: '@'
			}
		});

	function afDirector(
		$scope,
		$window,
		alfaAlertState,
		alfaBp,
		alfaConfigPipeline,
		configService,
		externalConfigLoader,
		fcaGeolocator,
		cookieLocation,
		$http
	) {
		'ngInject';

		const $ctrl = this;
		let lastScroll = 0;
		$ctrl.downPayment = 5000;

		const paintEcc = '0082';

		const irisRootURl = externalConfigLoader
			.loadConfig('FCA_SITES_CONFIG')
			.getConfig('irisJellyBaseUrl') +
			"/mediaserver/iris?client=FCAUS&market=U&brand=Y";

		const SELECT_OPTION_STATUS = Object.freeze({
			complete: 'COMPLETE',
			musthave: 'MUSTHAVE',
		});

		$ctrl.language = window.FCA_SITES_CONFIG.language;
		$ctrl.queryParams = '';

		$ctrl.location = {
			province: 'ON'
		};

		$ctrl.navSections = [
			'exterior',
			'interior',
			'powertrain',
			'groups',
			'summary'
		];

		$scope.$on('navigation: payment-type-changed', (_, data) => {
			$ctrl.paymentType = data.type;
		});

		$ctrl.$onInit = () => {
			storeAffiliateId();
			$ctrl.isAffiliateMode = isAffiliateMode();
			$ctrl.affiliateId = $ctrl.isAffiliateMode ? getStoredAffiliateId() : null;
			$ctrl.location = cookieLocation.getLocation() || $ctrl.location;
			$ctrl.queryParams = addQueryParametersToUrl($ctrl.queryParams);
			$ctrl.hashParameters = getHashParameters();
			$ctrl.provinceCode = $ctrl.location.province;
			$ctrl.disclaimers = {
				leasing: $ctrl.disclaimerLeasing,
				financing: $ctrl.disclaimerFinancing,
				netAmount: $ctrl.disclaimerNetPrice,
				basePrice: $ctrl.disclaimerBasePrice,
				dealerAdminFees: $ctrl.disclaimerDealerAdminFees
			};

			storeCustomOrderParam();
			getSwatches();

			$scope.$on('calculator:update-payment', (_, data) => {
				$ctrl.handleCalculatorUpdatedData(data);
			});

			$scope.$on(fcaGeolocator.getLocationChangedEvent(), (_, data) => {
				$ctrl.location = data[0];
				$ctrl.provinceCode = data[0].province;
				if (!$ctrl.loadInProgress) {
					getSwatches();
				}
			});

			window.addEventListener('scroll', _ => {
				if (!$ctrl.scrollPaused) {
					lastScroll = window.scrollY || window.pageYOffset;
				}
			});

			$ctrl.hasCustomOrderParam = hasCustomOrderParam();

		}

		$scope.$on('$locationChangeSuccess', () => {
			const activeSection = location.hash.substr(1);
			if ($ctrl.navSections.includes(activeSection)) {
				$ctrl.onSummary = activeSection === 'summary';
				if ($ctrl.onSummary) {
					window.scroll(0, 0);
				}
			}
			if(activeSection.includes('calculator')) {
				gotoCalculator();
			}
		});

		function gotoCalculator() {
			$ctrl.onSummary = true;
			const calculatorElem = document.getElementById('zones_summary_blocks_calculator');
			const calculatorOffsetTop = calculatorElem.offsetTop + 400;
			$window.scroll(0, calculatorOffsetTop);
		}

		function getTaxes() {
			alfaBp.getTaxes(handleTaxesResponse);
		}

		function handleTaxesResponse(response) {
			$ctrl.taxes = response.find(taxes => taxes.provinceCode === $ctrl.provinceCode);

			getTrims();
		}

		function getSwatches() {
			$ctrl.loadInProgress = true;
			alfaBp.getSwatches({
				brand: $ctrl.brand,
				nameplate: $ctrl.nameplate,
				year: $ctrl.year,
				modelYearId: $ctrl.modelYearId
			}, handleSwatchesResponse);
		}

		function handleSwatchesResponse(response) {
			if (!response) {
				return;
			}

			$ctrl.swatches = response;
			getTaxes();
		}

		function getTrims() {
			alfaBp.getTrims({
				language: $ctrl.language,
				brand: $ctrl.brand,
				nameplate: $ctrl.nameplate,
				year: $ctrl.year,
				modelYear: $ctrl.modelYearId,
			}, handleTrimResponse);
		}

		function handleTrimResponse(response) {
			if (!response) {
				return;
			}

			$ctrl.trims = response;
			setCurrentTrimFromAcode($ctrl.trims.trimGroups);
			$ctrl.drivetrain = $ctrl.trims.drivetrains.find(drivetrain => drivetrain.id === $ctrl.currentTrim.drivetrain).description;
			$ctrl.vehicle = $ctrl.year + "_" + $ctrl.currentTrim.mfgCode.substr(0, 2);
			$ctrl.vehicleName = $ctrl.language === 'en' ?
			`${$ctrl.year} ALFA ROMEO ${$ctrl.nameplate.toUpperCase()}` :
			`ALFA ROMEO ${$ctrl.nameplate.toUpperCase()} ${$ctrl.year}`;
			getConfig();
		}

		function setCurrentTrimFromAcode(trimGroups) {
			if (!trimGroups) {
				return;
			}

			for (let i = 0; i < trimGroups.length; i++) {
				const trimGroup = trimGroups[i];
				for (let j = 0; j < trimGroup.trims.length; j++) {
					if (trimGroup.trims[j].acode === $ctrl.acode) {
						$ctrl.trimName = trimGroup.code;
						$ctrl.currentTrim = trimGroup.trims[j];
						$ctrl.currentTrimGroup = trimGroup;
						return;
					}
				}
			}
		}

		function getConfig() {

			const urlParams = new URLSearchParams(window.location.search);
            const buildId = urlParams.get('buildId') || null;

			if (buildId) {
				const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;
				const pid = persistentUid && `?pid=${persistentUid}`;

				const apiUrl = `/data/custom-order/${$ctrl.language}/${buildId}${pid}`;
				$http.get(apiUrl).then(response => {

					alfaBp.getConfig({
						acode: response.data.acode,
						language: $ctrl.language,
						packageCode: response.data.packageCode,
						provinceCode: response.data.region,
						modelYearId: response.data.modelYearId,
						nameplateCode: response.data.nameplate,
						year: response.data.year,
						scratchSave: response.data.scratchSave,
						brand: response.data.brandCode,
						affiliateId: response.data.affiliate.affiliateId
					}, handleConfigResponse);
				})
				.catch(function (e) {
					window.location.replace(window.location.pathname);
				});
			} else {
				const savedConfig = alfaBp.getSessionStoredConfiguration(
					$ctrl.brand,
					$ctrl.modelYearId,
					$ctrl.nameplate,
					$ctrl.currentTrimGroup,
					$ctrl.year
				);

				const scratchSave = savedConfig ? savedConfig.scratchSave :
					$ctrl.hashParameters.scratchsave ? LZString.decompressFromEncodedURIComponent($ctrl.hashParameters.scratchsave) : '';

				alfaBp.getConfig({
					acode: $ctrl.acode,
					language: $ctrl.language,
					packageCode: savedConfig ? savedConfig.packageCode : $ctrl.currentTrim.packages[0].code,
					provinceCode: $ctrl.provinceCode,
					modelYearId: $ctrl.modelYearId,
					nameplateCode: $ctrl.nameplate,
					year: $ctrl.year,
					scratchSave: scratchSave,
					brand: $ctrl.brand,
					affiliateId: $ctrl.affiliateId
				}, handleConfigResponse);
			}
		}

		function handleConfigResponse(response) {
			$ctrl.config = response.data;
			const mfgCode = $ctrl.currentTrim.mfgCode.substr(0, 2);
			$ctrl.mfgCode = mfgCode;
			const nameWithYear = `OC${$ctrl.year.substr(2,2)}U`
			const optionBaseUrl = irisRootURl +
				`&vehicle=${$ctrl.vehicle}` +
				`&name=${nameWithYear}___${mfgCode}_____`;

			const swatchData = {
				data: $ctrl.swatches,
				baseUrl: window.FCA_SITES_CONFIG.mediaBaseUrl
			};

			const {
				allSelectedOptions,
				sections,
				jellyCategories
			} =
			alfaConfigPipeline.makeViewConfig($ctrl.config, optionBaseUrl, swatchData);

			$ctrl.allSelectedOptions = allSelectedOptions;
			$ctrl.sections = sections;
			$ctrl.jellyCategories = jellyCategories;

			$ctrl.pricing = $ctrl.config.pricing;

			configService.parseConfiguration($ctrl.config);

			$ctrl.loadInProgress = false;
			setJellyData();

			alfaBp.saveConfigurationToSession(
				$ctrl.acode,
				$ctrl.brand,
				$ctrl.modelYearId,
				$ctrl.nameplate,
				$ctrl.currentTrim.packages[0].code,
				$ctrl.config.scratchSave,
				$ctrl.currentTrimGroup,
				$ctrl.year
			);
		}

		function setJellyData() {
			$ctrl.jellyData = {
				jellyCategories: $ctrl.jellyCategories,
				vehicle: $ctrl.vehicle,
				mfgCode: $ctrl.currentTrim.mfgCode,
				packageCode: $ctrl.currentTrim.packages[0].code
			};
		}

		$ctrl.selectOption = option => {
			if (!option ||
				!$ctrl.currentTrim ||
				(option.selected && !option.isDeselectable)) {
				return;
			}
			$ctrl.lastSelectedOption = option;

			const data = {
				acode: $ctrl.acode,
				affiliateId: $ctrl.affiliateId,
				modelYearId: $ctrl.modelYearId,
				nameplateCode: $ctrl.nameplate,
				year: $ctrl.year,
				brandCode: $ctrl.brand,
				provinceCode: $ctrl.provinceCode,
				packageCode: $ctrl.currentTrim.packages[0].code,
				optionCode: option.code,
				scratchSave: $ctrl.alertWindowStatus === SELECT_OPTION_STATUS.musthave ? $ctrl.musthaveScratchSave : $ctrl.config.scratchSave,
				language: $ctrl.language
			};
			alfaBp.selectOption(data, handleSelectOptionResponse);
		}

		function handleSelectOptionResponse(response) {
			const status = response.data.status;
			$ctrl.alertWindowStatus = status;
			if (status === SELECT_OPTION_STATUS.complete) {
				handleCompleteResponse(response.data);
			} else if (status === SELECT_OPTION_STATUS.musthave) {
				handleMusthaveReponse(response.data);
			}
		}

		function handleCompleteResponse(response) {
			const deltaWarrantsAlert = (response.delta.selected.length + response.delta.unselected.length) > 2;

			if (!deltaWarrantsAlert) {
				$ctrl.showAlert = false;
				resumeScrolling();
				handleConfigResponse({
					data: response.configuration
				});
				return;
			}

			const completedAlertState = {
				lastReceivedConfiguration: response.configuration,
				alertWindowMainOption: $ctrl.lastSelectedOption,
				alertWindowIncludedOptions: response.delta.selected,
				alertWindowRemovedOptions: response.delta.unselected,
				alertWindowStatus: response.status,
				alertWindowActionable: !alfaAlertState.alertsDisabled(),
			}

			if (alfaAlertState.alertsDisabled()) {
				$ctrl.missedAlert = completedAlertState;
				$ctrl.lastReceivedConfiguration = response.configuration;

				alfaAlertState.setMissedOption(
					$ctrl.lastReceivedConfiguration,
					$ctrl.lastSelectedOption.code
				);

				$ctrl.alertWindowConfirmed();
			} else {
				Object.assign($ctrl, completedAlertState);
				$ctrl.showAlert = true;
				pauseScrolling();
			}
		}

		function handleMusthaveReponse(response) {
			$ctrl.musthaveScratchSave = response.scratchSave;
			$ctrl.alertWindowMainOption = response.options.shift();
			$ctrl.alertWindowMusthaveChoices = response.options;
			$ctrl.alertWindowStatus = response.status;
			$ctrl.showAlert = true;
			pauseScrolling();
		}

		$ctrl.alertWindowConfirmed = () => {
			handleConfigResponse({
				data: $ctrl.lastReceivedConfiguration
			});
			$ctrl.alertWindowCancelled();
			$ctrl.lastReceivedConfiguration = null;
		}

		$ctrl.alertWindowCancelled = () => {
			$ctrl.musthaveScratchSave = $ctrl.alertWindowMainOption =
				$ctrl.alertWindowIncludedOptions = $ctrl.alertWindowRemovedOptions =
				$ctrl.alertWindowMusthaveChoices = $ctrl.alertWindowStatus = null;
			$ctrl.showAlert = false;
			resumeScrolling();
		}

		$ctrl.alertWindowSelection = (option) => {
			$ctrl.selectOption(option);
		}

		$ctrl.handleCalculatorUpdatedData = data => {
			$ctrl.paymentsAndRates = data;
			if ($ctrl.paymentsAndRates.netAmount < 0) {
				$ctrl.paymentsAndRates.netAmount = 0;
			}
		}

		$scope.$on('missedAlertClick', () => {
			Object.assign($ctrl, $ctrl.missedAlert);
			$ctrl.showAlert = true;
			pauseScrolling();
		});

		$ctrl.contactDealerCTA = () => {
			if (!$ctrl.config) return '';
			const contactPage = $ctrl.language === 'en' ? 'custom-build-dealer' : 'custom-build-concessionnaire';
			let url = `/${$ctrl.language}/${contactPage}` +
				`${$ctrl.queryParams}` +
				`#brand=${$ctrl.brand}` +
				`&nameplate=${$ctrl.nameplate}` +
				`&year=${$ctrl.year}` +
				`&modelYearId=${$ctrl.modelYearId}` +
				`&trim=${$ctrl.trimName}` +
				`&mfgCode=${$ctrl.mfgCode}` +
				`&paint=${getSelectedPaint()}` +
				`&acode=${$ctrl.acode}` +
				`&provinceCode=${$ctrl.provinceCode}` +
				`&scratchSave=${getCurrentScratchSave()}`;

			if ($ctrl.hashParameters.dealerId) {
				url += `dealer=${$ctrl.hashParameters.dealerId}`;
			}
			return url;
		}

		$ctrl.inventoryCTA = () => {
			if (!$ctrl.config) return '';
			let inventoryUrl = '';
			if ($ctrl.hashParameters && $ctrl.hashParameters.dealerURL) {
				$ctrl.hashParameters.dealerURL = decodeURIComponent($ctrl.hashParameters.dealerURL);
				if ($ctrl.hashParameters.dComVersion && $ctrl.hashParameters.dComVersion === 8) {
					inventoryUrl =
						($ctrl.hashParameters.dealerURL.startsWith('http') ? `` : `http://`) +
						`${$ctrl.hashParameters.dealerURL}` +
						`/new-inventory/index.htm?` +
						`SBmodel=${$ctrl.nameplate}` +
						`&SByear=${$ctrl.year}` +
						`&SBmake=${$ctrl.brand}`;
				} else {
					inventoryUrl =
						($ctrl.hashParameters.dealerURL.startsWith('http') ? `` : `http://`) +
						`${$ctrl.hashParameters.dealerURL}` +
						`/new-inventory/index.htm?type=new`;
					return;
				}
			} else {
				const queryParams = $ctrl.queryParams !== "" ? $ctrl.queryParams : "?";

				inventoryUrl =
					`${$ctrl.baseSniBuildUrl}` +
					`${queryParams}` +
					`paymentMode=cash&year=${$ctrl.year}&trim=${$ctrl.trimName}`;
			}

			inventoryUrl +=
				`&modelYearId=${$ctrl.modelYearId}` +
				`&acode=${$ctrl.acode}` +
				`&trimPkg=${$ctrl.currentTrim.packages[0].code}`;

			return inventoryUrl;
		}

		$ctrl.currentOffersCTA = () => {
			if (!$ctrl.config) return '';
			const language = $ctrl.language === 'en' ? '' : 'fr_CA/';
			const nameplateTrimmed = $ctrl.nameplate[0] === '4' ? $ctrl.nameplate.substr(0, 2) : $ctrl.nameplate;
			return `https://www.alfaromeo.ca/${language}car-shopping/current-offers-alfaromeo-${nameplateTrimmed}`;
		}

		const getSelectedPaint = () => {
			if (!$ctrl.allSelectedOptions) return '';
			const selectedPaint = $ctrl.allSelectedOptions.find(option => option.ecc === paintEcc);
			return selectedPaint ? selectedPaint.trimmedCode : $ctrl.trim.primaryColourCode;
		}

		const getCurrentScratchSave = () => {
			if (!$ctrl.config) return '';
			return $ctrl.config.scratchSave;
		}

		const pauseScrolling = () => {
			$ctrl.scrollPaused = true;
			$('body').addClass('noScroll')
				.css({
					top: -lastScroll
				});
		};

		const resumeScrolling = () => {
			$ctrl.scrollPaused = false;
			$('body').removeClass('noScroll');

			if (lastScroll !== undefined) {
				window.scrollTo(0, lastScroll);
			}
		};

		$ctrl.getSelectedColorDescription = () => {
			if (!$ctrl.allSelectedOptions) return '';
			const selectedPaint = $ctrl.allSelectedOptions.find(option => option.ecc === paintEcc);
			return selectedPaint.description;
		}

		$ctrl.generateAltText = () => {
			let altText = '';
			let colourDescription = $ctrl.getSelectedColorDescription();
			altText = $ctrl.year + ' ' + $ctrl.brand + ' ' + $ctrl.nameplate + ' ' + $ctrl.trimName + ' ' + colourDescription;
			if(window.FCA_SITES_CONFIG.language == 'fr') {
				altText = $ctrl.brand + ' ' + $ctrl.nameplate + ' ' + $ctrl.year + ' ' + $ctrl.trimName + ' ' + colourDescription;
			}
			return altText;
		};
	}
})();
