(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculatorWidget')
		.component('daaCalculatorWidget', {
			templateUrl: '/components/daa-calculator/widget/calculator-widget.html',
			controller: 'daaCalculatorWidgetController',
			controllerAs: '$calculator',
			bindings: {
				opts: '<',
				onCalculated: '&',
				applyForFinancingUrl: '@?',
				analyticsCategory: '@',
				analyticsId: '@',
				vehicle: '<?'
			}
		})
		.controller('daaCalculatorWidgetController', CalculatorWidgetController);

	/**
	 * @ngdoc controller
	 * @name fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function CalculatorWidgetController(
		$scope,
		daaCalculator,
		daaFrequency,
		$timeout,
		gtmAnalytics,
		cookieLocation) {
		'ngInject';

		const MAX_DOWNPAYMENT = 50000;
		const MAX_TRADE_IN_VALUE = 50000;
		const MAX_TERM = 120;
		const MIN_TERM = 0;
		const MIN_FREQUENCY = -2;
		this.isBestPayment = {finance: false, leasing: false};
		this.term = null;
		this.maxTerm = MAX_TERM;
		this.frequency = null;
		this.downPayment = null;
		this.msrp = null;
		this.offerPrice = null;
		this.maxDownPayment = MAX_DOWNPAYMENT;
		this.tradeInValue = 0;
		this.maxTradeInValue = MAX_TRADE_IN_VALUE;
		this.type = null;
		this.frequencyLbl = daaFrequency.getFrequencyStrFromInput(6);
		this.frequencyLbls = daaFrequency.getFrequenciesLbls();
		this.opts = null;
		this.rp = 0;
		this.residuals = null;
		this.residual = 100;
		this.applyForFinancingUrl = null;
		this.leaseKilometer = 0;
		this.minFrequency = 0;
		this.termToolTip = false;
		this.financedAmount = 0;
		this.nonResidualizedAmount = 0;
		this.includeSalesTax = false;
		this.location = cookieLocation.getLocation();

		let trackingDebounce;

		/**
		 * @ngdoc method
		 * @name $onInit
		 * @methodOf fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
		 * @description
		 * Initialize controller properties
		 */
		this.$onInit = () => {
			this.initialize(this.opts);
		};

		/**
		 * @ngdoc method
		 * @name initialize
		 * @methodOf fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
		 * @param  {Object} opts Options list to use for initialization
		 * @description
		 * Parse options list to set-up all controlelr properties
		 */
		this.initialize = (opts) => {
			this.alternativeProgram = opts.alternativeProgram;
			this.downPayment = opts.downPayment;
			this.maxDownPayment = MAX_DOWNPAYMENT;
			this.tradeInValue = opts.tradeInValue;
			this.maxTradeInValue = MAX_TRADE_IN_VALUE;
			this.maxTerm = MAX_TERM;
			this.term = opts.term;
			this.frequency = opts.frequency;
			this.msrp = opts.msrp;
			this.offerPrice = opts.offerPrice;
			this.rp = this.getSelectedProgramRp(opts);
			this.type = opts.type;
			this.frequencyLbl = daaFrequency.getFrequencyStrFromInput(this.frequency);
			this.residuals = (this.alternativeProgram ? opts.altResiduals : opts.residuals) || null;
			this.residual = parseInt(opts.residual || 100, 10);
			this.leaseKilometer = parseInt(opts.leaseKilometer, 10);
			this.minTerm = MIN_TERM;
			this.minFrequency = MIN_FREQUENCY;
			this.termToolTip = opts.termToolTip || false;
			this.financedAmount = opts.financedAmount || 0;
			this.nonResidualizedAmount = opts.nonResidualizedAmount;
			this.provincialSalesTaxList = opts.provincialSalesTaxList;
			//get the selected discount for the good program
			this.beforeTaxDiscount = this.alternativeProgram ? opts.altBeforeTaxDiscount : opts.beforeTaxDiscount;
			this.afterTaxDiscount = this.alternativeProgram ? opts.altAfterTaxDiscount : opts.afterTaxDiscount;
			this.selectedTermsRates = this.alternativeProgram ? opts.altRp : opts.rp;

			this.dynamicTerms = Object.keys(this.selectedTermsRates).sort();
			this.dynamicTerm = 1;
			this.maxSteps = this.dynamicTerms.length;

			for (let index = 0; index < this.maxSteps; index++) {
				if (this.dynamicTerms[index] === opts.term.toString()) {
					this.dynamicTerm = index + 1;
					break;
				}
			}

			// Change max values for range input after values are updated
			let $t = $timeout(() => {
				this.maxTradeInValue = opts.maxTradeInValue;
				this.maxDownPayment = opts.maxDownPayment;
				this.maxTerm = opts.maxTerm;
				this.minFrequency = opts.minFrequency;
				if (ng.isObject(this.selectedTermsRates)) {
					let minTerm = Object.keys(this.selectedTermsRates)[0];
					this.minTerm = minTerm;
				}

				// Clean timeout
				$timeout.cancel($t);
			}, 0);
			this.calculate();
		};

		/**
		 * @ngdoc method
		 * @name $onChanges
		 * @methodOf fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
		 * @param  {Object} obj List of changes properties
		 * @description
		 * Method Angular JS for component controller
		 */
		this.$onChanges = (obj) => {
			if (obj.hasOwnProperty('opts') && obj.previousValue !== 'UNINITIALIZED_VALUE') {
				this.initialize(this.opts);
			}
		};

		this.getSelectedProgramRp = (obj) => {
			let rp = obj.alternativeProgram ? obj.altRp : obj.rp;
			return (ng.isObject(rp)) ? rp[obj.term] : 0;
		}

		/**
		 * @ngdoc method
		 * @name changePropAndCalculate
		 * @methodOf fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
		 * @param {String} prop Controller property to change
		 * @param {Number|String} value New value for trade in value property
		 * @description
		 * Called by inputs trade in value model binding. Trigger a new calcul action.
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.changePropAndCalculate = (prop, value) => {
			if (['tradeInValue', 'downPayment'].indexOf(prop) > -1) {
				if (value !== undefined && value !== this[prop]) {
					this[prop] = value || 0;
				} else {
					// Force integer type in current value
					let current = parseInt(this[prop] || 0, 10);
					this[prop] = current;
				}
			} else {
				if (prop === 'dynamic-term') {
					this[prop] = value;
					this.term = this.dynamicTerms[value - 1];
				} else {
					this[prop] = value;
				}
				if (prop === 'term' || prop === 'dynamic-term') {
					// Set new tx in percent
					this.rp = this.opts.alternativeProgram ? this.opts.altRp[this.term] : this.opts.rp[this.term];
				} else {
					// Change frequency label
					this.frequencyLbl = daaFrequency.getFrequencyStrFromInput(this.frequency);
				}
			}

			if (trackingDebounce) {
				$timeout.cancel(trackingDebounce);
			}

			trackingDebounce = $timeout(() => {
				let trackingOpts = {};
				let trackingValue = value;
				if (prop === 'frequency') {
					trackingValue = daaFrequency.getFrequencyStrFromInput(this.frequency, true);
				}
				trackingOpts[`paymentcal${prop}`] = trackingValue;
				gtmAnalytics.trackEvent('paymentcal', trackingOpts);

				$timeout.cancel(trackingDebounce);
				trackingDebounce = null;
			}, 100);

			this.isBestPayment[this.opts.type] = true;
			this.calculate();
		};

		/**
		 * @ngdoc method
		 * @name calculate
		 * @methodOf fca.daaCalculatorWidget.controller:daaCalculatorWidgetController
		 * @description Calculate toPay property and output result object binding function onCalculated
		 */
		this.calculate = () => {
			let params = getCalculateParams(this);
			let altRp = !this.alternativeProgram ? this.opts.altRp : this.opts.rp;
			let altResidual = !this.alternativeProgram ? this.opts.altResiduals[this.term] : this.opts.residuals[this.term];
			let altParams = ng.extend({}, params, {
				rp: altRp[this.term],
				afterTaxDiscount: !this.alternativeProgram ? this.opts.altAfterTaxDiscount : this.opts.afterTaxDiscount,
				beforeTaxDiscount: !this.alternativeProgram ? this.opts.altBeforeTaxDiscount : this.opts.beforeTaxDiscount,
				residual : altResidual
			});
			let p;
			switch (this.type) {
			case 'cash':
				p = daaCalculator.preCalculateCash(params);
				break;
			case 'leasing':
				p = daaCalculator.preCalculateLeasing(params);
				if (altParams.rp != 'undefined' && this.isBestPayment[this.opts.type]) {
					let altP = daaCalculator.preCalculateLeasing(altParams);
					if (altP.realValueTopay < p.realValueTopay) {
						p = altP;
						p.programChanged = true;
					}
				}
				break;
			default:
				p = daaCalculator.preCalculateFinance(params);
				if (altParams.rp != 'undefined' && this.isBestPayment[this.opts.type]) {
					// Adjustment according to the discount before tax that is included in the DAA price.
					// If we change program we need to adjust the DAA price. daaPrice -// (alt - main)
					let adjustedAmount = altParams.financedAmount - (altParams.beforeTaxDiscount - p.beforeTaxDiscount);
					altParams.financedAmount = adjustedAmount;
					let altP = daaCalculator.preCalculateFinance(altParams);
					if (altP.realValueTopay < p.realValueTopay) {
						p = altP;
						p.programChanged = true;
					}
				}
			}

			/* Update component parent */
			let opts = ng.extend({}, p, {
				frequency: daaFrequency.getFrequencyInputFormYear(p.frequency),
				rp: this.opts.rp,
				altRp: this.opts.altRp,
				term: p.term,
				financedAmount :  this.financedAmount,
				afterTaxDiscount : this.opts.afterTaxDiscount,
				beforeTaxDiscount: this.opts.beforeTaxDiscount,
				altAfterTaxDiscount : this.opts.altAfterTaxDiscount,
				altBeforeTaxDiscount: this.opts.altBeforeTaxDiscount,
				alternativeProgram : this.alternativeProgram,
				isBestPayment : this.isBestPayment[this.opts.type]
			});

			if (!isNaN(opts.toPay)) {
				this.onCalculated({
					opts: opts
				});
			}
		};

		/**
		 * @description Return calcul params
		 * @param {Object} that Controller instance
		 * @return {Object} Params object to used calculator service methods
		 */
		function getCalculateParams(that) {
			let residual = 100;
			if (ng.isObject(that.residuals)) {
				residual = that.residuals[that.term];
			}

			return {
				msrp: that.msrp,
				offerPrice: that.offerPrice,
				dp: parseInt(that.downPayment, 10),
				tiv: parseInt(that.tradeInValue, 10),
				rp: that.rp,
				frequency: daaFrequency.getFrequencyPerYearFromInput(that.frequency),
				term: that.term,
				type: that.type,
				residual: residual,
				leaseKilometer: that.leaseKilometer,
				nonResidualizedAmount: that.nonResidualizedAmount,
				financedAmount: that.financedAmount,
				includeSalesTax: that.includeSalesTax,
				provincialSalesTaxList: that.provincialSalesTaxList,
				location: that.location,
				beforeTaxDiscount: that.beforeTaxDiscount,
				afterTaxDiscount: that.afterTaxDiscount
			};
		}

		this.toggleSalesTaxInclusion = ($evt) => {
			$evt.preventDefault();
			$('.salesTaxToggler').toggleClass('active');
			$('.price-box-container [data-with-taxes]').toggleClass('hidden');
			this.includeSalesTax = !this.includeSalesTax;
			this.calculate();
		};
	}
})(angular);
