/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.directives')
        .directive('fcaNameplatePricing', fcaNameplatePricing);

    function fcaNameplatePricing() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                location: '<',
                campaignCode: '@?'
            },
            controllerAs: '$ctrl',
            controller: FcaNameplatePricing
        };

        function FcaNameplatePricing($http, $scope, $rootScope, $element, $location, fcaGeolocator, $filter, calculatorService, externalConfigLoader) {
            'ngInject';

            let $ctrl = this;

            $ctrl.apiUrl = '/api/buildandprice/modelYears/prices';

            $ctrl.pricingObject = null;

            $ctrl.hashParameters = null;

            $ctrl.affiliateMode = false;

            $ctrl.calculatorService = calculatorService;

            $ctrl.affiliateId = null;

            $ctrl.pricingContainer = $element.find('.pricing-container');

            $ctrl.defaultFirstSelected = true;

            $ctrl.pricingObjectIsReady = false;

            $ctrl.mobileFilterOpen = false;

            /* Service Config */
            let configService = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

            const maxLeaseEir = configService.getConfig("maxLeaseEir");

            $ctrl.$onInit = () => {
                // Are we in iframe mode and Embedded inside a dealer site?
                $ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';

                // Init affiliateId & affiliateMode
                storeAffiliateId();
                $ctrl.affiliateMode = isAffiliateMode();
                $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

                // Parse hash parameters
                $ctrl.hashParameters = getHashParameters();

                if ($ctrl.hashParameters) {
                    $ctrl.restoreSession = $ctrl.hashParameters.restoreSession;

                    if ($ctrl.hashParameters.dealerId) {
                        sessionStorage.setItem('dealerId',
                            $ctrl.hashParameters.dealerId);
                    }
                }

                if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                    $ctrl.hashParameters.affiliateId = $ctrl.affiliateId;
                }

                if ($ctrl.campaignCode && $ctrl.campaignCode !== 'null'
                    && $ctrl.campaignCode !== '') {
                    $ctrl.hashParameters.campaignCode = $ctrl.campaignCode;
                    sessionStorage.setItem('campaignCode',$ctrl.campaignCode);
                } else {
                    sessionStorage.removeItem('campaignCode');
                }

                /* Listen if the location is changed outside this component */
                let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
                $scope.$on(eventUpdateName, (evt, args) => {
                    $ctrl.getModelYearPricing(args[0].province);
                });

                if ($ctrl.location !== null) {
                    $ctrl.getModelYearPricing($ctrl.location.province);
                }
            };

            $ctrl.getModelYearPricing = (provinceCode) => {
                let apiUrl = $('body').attr('brand') === 'alfaromeo' ?
                    `${$ctrl.apiUrl}?provinceCode=${provinceCode}&brands=alfaromeo`
                    :
                    `${$ctrl.apiUrl}?provinceCode=${provinceCode}&brands=chrysler,fiat,ramtruck,dodge,jeep`;
                if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                    apiUrl += getAffiliateIdUrlParam();
                }
                if ($ctrl.campaignCode) {
                    apiUrl += '&campaignCode=' + $ctrl.campaignCode;
                }

                if ($ctrl.inIframeMode && !!$ctrl.hashParameters.dealerId) {
                    // Dealer code parameter added to request if we are in iframe mode, it gives us the dealerStartingAtPrice
                    apiUrl += `&dealerCode=${$ctrl.hashParameters.dealerId}`;
                }

                $ctrl.pricingContainer.addClass('hidden');

                $http.get(apiUrl).then(r => {
                    $ctrl.pricingObject = r.data;
                    $ctrl.pricingContainer.removeClass('hidden');

                    for (let i = 0; i < $ctrl.pricingObject.length; i++) {

                        let cashObject = $ctrl.pricingObject[i].cash;
                        let financeObject = $ctrl.pricingObject[i].finance;
                        let altFinanceObject = $ctrl.pricingObject[i].altFinance;
                        // copy both lease objects, remove them, from the pricing object
                        let leaseObject = $ctrl.pricingObject[i].lease;
                        let altLeaseObject = $ctrl.pricingObject[i].altLease;
                        $ctrl.pricingObject[i].lease = null;
                        $ctrl.pricingObject[i].altLease = null;

                        // assign cash tab variable
                        if (cashObject) {
                            $ctrl.pricingObject[i].startingAtPrice = Math.ceil(cashObject.price);
                            $ctrl.pricingObject[i].incentAmount = Math.round(cashObject.incentivesAmount);
                        }

                        if (financeObject) {
                            // assign finance main program
                            $ctrl.pricingObject[i].finance = {
                                frequency: financeObject.frequencyCode,
                                payment: financeObject.payment,
                                rate: financeObject.rate,
                                term: financeObject.term,
                                incentive: Math.round(financeObject.incentivesAmount)
                            };

                            // assign alternative finance program if available
                            // compare rate to exclude same rate program
                            if (altFinanceObject && Number(financeObject.rate) > Number(altFinanceObject.rate)) {
                                $ctrl.pricingObject[i].altFinance = {
                                    frequency: altFinanceObject.frequencyCode,
                                    payment: altFinanceObject.payment,
                                    rate: altFinanceObject.rate,
                                    eir: altFinanceObject.eir,
                                    term: altFinanceObject.term,
                                    incentive: Math.round(altFinanceObject.incentivesAmount)
                                };
                            }else {
                                // remove assignation cause the rate is not better than main program
                                $ctrl.pricingObject[i].altFinance = null;
                            }
                        } else if(altFinanceObject) {
                            // assign alternative finance program as main finance program
                            $ctrl.pricingObject[i].finance = {
                                frequency: altFinanceObject.frequencyCode,
                                payment: altFinanceObject.payment,
                                rate: altFinanceObject.rate,
                                eir: altFinanceObject.eir,
                                term: altFinanceObject.term,
                                incentive: Math.round(altFinanceObject.incentivesAmount)
                            };
                        }

                        let selectedLease = null;
                        if (leaseObject && altLeaseObject) {
                            // if the effective rate is lower than the lease rate
                            // AND the alternative payment is lower
                            // AND the EIR is lower than the lease rate
                            // use the alternative lease
                            if (Number(leaseObject.payment) > Number(altLeaseObject.payment)
                                && Number(altLeaseObject.eir||0) < Number(leaseObject.rate)) {
                                selectedLease = altLeaseObject;
                            } else {
                                selectedLease = leaseObject;
                            }
                        } else if (leaseObject) {
                            // there's only a lease, use it
                            selectedLease = leaseObject;
                        } else if (altLeaseObject) {
                            // there's only an alternative lease, use it
                            selectedLease = altLeaseObject;
                        }

                        // do not use the lease if the EIR is over the max
                        if (selectedLease && Number(selectedLease.eir||0) > Number(maxLeaseEir)) {
                            selectedLease = null;
                        }

                        // if we have a selected lease, use it!
                        if (selectedLease) {
                            $ctrl.pricingObject[i].lease = {
                                frequency: selectedLease.frequencyCode,
                                frequencyCode: selectedLease.frequencyCode,
                                payment: selectedLease.payment,
                                rate: selectedLease.rate,
                                eir: selectedLease.eir,
                                term: selectedLease.term,
                                incentive: Math.round(selectedLease.incentivesAmount),
                                incentivesAmount: selectedLease.incentivesAmount
                            };
                        }

                        // manage incentives and discounts
                        if ($ctrl.pricingObject[i].provincialIncentive) {
                            $ctrl.pricingObject[i].incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].incentAmount,
                                $ctrl.pricingObject[i].provincialIncentive, "cash");

                            if ($ctrl.pricingObject[i].finance) {
                                $ctrl.pricingObject[i].finance.incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].finance.incentive,
                                    $ctrl.pricingObject[i].provincialIncentive, "finance");
                            }

                            if ($ctrl.pricingObject[i].lease) {
                                $ctrl.pricingObject[i].lease.incentivesAndDiscounts = $ctrl.calculateSumIncentives($ctrl.pricingObject[i].lease.incentive,
                                    $ctrl.pricingObject[i].provincialIncentive, "lease");
                            }

                            // manage cash disclaimer
                            if($ctrl.pricingObject[i].provincialIncentive.disclaimer) {
                                $ctrl.pricingObject[i].disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                            }
                            // manage finance disclaimer
                            if($ctrl.pricingObject[i].provincialIncentive.disclaimer
                                || $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance) {

                                if ($ctrl.pricingObject[i].finance) {
                                    $ctrl.pricingObject[i].finance.disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance
                                        ? $ctrl.pricingObject[i].provincialIncentive.disclaimerFinance
                                        : $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                                }
                            }
                            // manage lease disclaimer
                            if($ctrl.pricingObject[i].provincialIncentive.disclaimer
                                || $ctrl.pricingObject[i].provincialIncentive.disclaimerLease) {

                                if ($ctrl.pricingObject[i].lease) {
                                    $ctrl.pricingObject[i].lease.disclaimer = $ctrl.pricingObject[i].provincialIncentive.disclaimerLease
                                        ? $ctrl.pricingObject[i].provincialIncentive.disclaimerLease
                                        : $ctrl.pricingObject[i].provincialIncentive.disclaimer;
                                }
                            }
                        }
                    }

                    $scope.$$postDigest(function () {
                        $rootScope.$broadcast('pricing:pricing-object-is-ready', {pageType: 'vehicleSelector'});
                    });
                });
            };


            $ctrl.setActiveTab = (tab) => {
                $ctrl.activeTab = tab;
                let urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);
                $rootScope.$broadcast('pricing:set-active-tab-and-build-url', { activeTab: tab, urlSuffix: urlSuffix, pageType: 'vehicleSelector' });
            };

            $ctrl.calculateIncentivesAndDiscount = (discount, provincialIncentiveAmount, federalIncentiveAmount) => {
                let total = 0;
                if (discount && parseFloat(discount) > 0) {
                    total += parseFloat(discount);
                }
                if (provincialIncentiveAmount && parseFloat(provincialIncentiveAmount) > 0) {
                    total += parseFloat(provincialIncentiveAmount);
                }
                if (federalIncentiveAmount && parseFloat(federalIncentiveAmount) > 0) {
                    total += parseFloat(federalIncentiveAmount);
                }

                return Math.round(total);
            }

            $ctrl.calculateSumIncentives = (discount,provincialIncentive,paymentType) => {
                let total = 0;
                let federalDiscount = 0;
                let provincialDiscount = 0;

                switch (paymentType) {
                    case 'cash':
                        provincialDiscount = provincialIncentive.disableCashProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableCashFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;

                    case 'finance':
                        provincialDiscount = provincialIncentive.disableFinanceProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableFinanceFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;

                    case 'lease':
                        provincialDiscount = provincialIncentive.disableLeaseProvincialDiscounts ? 0.0 : provincialIncentive.incentive;
                        federalDiscount = provincialIncentive.disableLeaseFederalDiscounts ? 0.0 : provincialIncentive.federalIncentive;
                        break;
                }
                if (discount && parseFloat(discount) > 0) {
                    total += parseFloat(discount);
                }
                if (provincialDiscount && parseFloat(provincialDiscount) > 0) {
                    total += parseFloat(provincialDiscount);
                }
                if (federalDiscount && parseFloat(federalDiscount) > 0) {
                    total += parseFloat(federalDiscount);
                }
                return Math.round(total);
            }
        }
    }
})();
