(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaMosaicOverlay', fcaMosaicOverlay);

	function fcaMosaicOverlay() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'mosaicOverlay',
			controller: FcaMosaicOverlayController,
		};

		function FcaMosaicOverlayController($element, $rootScope) {
			'ngInject';

			this.$postLink = () => {
				$element.slick({
					slidesToShow: 2.5,
					slidesToScroll: 1,
					dots: true,
					mobileFirst: true,
					arrows: true,
					infinite: false,
					responsive: [
						{
							breakpoint: 666,
							settings: {
								slidesToShow: 4,
							}
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 3,
							}
						},
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 4,
							}
						}
					]
				});

				$(window).on('resize orientationchange', function() {
					if ($element) {
						$element.slick('refresh');
					}
				});

				$rootScope.$on('cms-mosaic-overlay.refresh', function () {
					if ($element) {
						$element.slick('refresh');
					}
				})
			};
		}

	}
})();
