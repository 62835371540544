(function() {
	angular
	.module('fca.brandApp')
	.directive('vlp2021Tabs', vlp2021Tabs);

	function vlp2021Tabs() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				defaultTab: '@'
			},
			controllerAs: '$vlp2021Tabs',
			controller: vlp2021TabsController
		};

		function vlp2021TabsController(gtmAnalytics, $element) {
			'ngInject';

			const $ctrl = this;

			$ctrl.$onInit = () => {
				$ctrl.activeTab = $ctrl.defaultTab;
			}

			$ctrl.setActiveTab = (value,label) => {
				$ctrl.activeTab = value;
				if (!!label) {
					gtmAnalytics.trackEvent('event', {
						category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
						label: 'body-link-tab-select-'+label
					});
				}
			};
		}
	}
})();
