(function() {
    'use strict';

    angular
        .module('fca.uconnect.service', [])
        .service('fcaUconnectService', FcaUconnectService);

    function FcaUconnectService() {
        'ngInject';

        const $service = this;

        //Return the screen object with top and left value in the hotspots items.
        $service.setHotspotPercentagePosition = (hotspots, width, height) => {
            for (let hotspot of hotspots) {
                let hotspotX = hotspot.xPosition || 0;
                let hotspotY = hotspot.yPosition || 0;
                let hotspotBottom, hotspotLeft;

                // The width and the height should be given in pixel via sling
                if (hotspotX) {
                    hotspotLeft = ((hotspotX / width) * 100).toFixed(2);
                    hotspot.left = hotspotLeft;
                }

                if (hotspotY) {
                    hotspotBottom = ((hotspotY / height) * 100).toFixed(2);
                    hotspot.bottom = hotspotBottom;
                }
            }

            return screen;
        };

        $service.setScreenPercentagePosition = (screens, width, height) => {
            for (let screen of screens) {
                let menuX = screen.xPosition || 0;
                let menuY = screen.yPosition || 0;
                let menuBottom, menuLeft;

                if (menuX) {
                    menuLeft = ((menuX / width) * 100).toFixed(2);
                    screen.left = menuLeft;
                }

                if (menuY) {
                    menuBottom = ((menuY / height) * 100).toFixed(2);
                    screen.bottom = menuBottom;
                }
            }
        };

        $service.addTitleToHotspot = (features, screens) => {
            for (let screen of screens) {
                for (let hotspot of screen.hotspots) {
                    for (let feature of features) {
                        if (feature.featureCode === hotspot.featureCode) {
                            hotspot.title = feature.title;
                        }
                    }
                }
            }
            return screens;
        };

        /**
         * This produce a list of feature ordered by the order of the hotspot.
         * And it adds the screen index for each feature.
         *
         * screenIndex   feature (possible to repeat)
         * 0             featureCode
         * 0             featureCode2
         * 1             featureCode
         * ...
         */
        $service.getFeatureListByHotspotOrder = (features, screens) => {
            let featureList = [];
            let i = 0;
            for (let screen of screens) {
                for (let hotspot of screen.hotspots) {
                    for (let feature of features) {
                        if (hotspot.featureCode === feature.featureCode) {
                            let featureObject = {};
                            featureObject.screenIndex = i;
                            featureObject.feature = feature;
                            featureObject.screenTitle = screen.title;
                            featureList.push(featureObject);
                        }
                    }
                }
                i = i + 1;
            }
            return featureList;
        }

    }
})();
