(function () {
	angular
		.module('alfaromeo.buildAndPrice.service')
		.service('alfaInteriorPanorama', AlfaInteriorPanorama);

	function AlfaInteriorPanorama($rootScope, $window, $timeout, $translate) {
		const $service = this;

		$service.setImageDimensions = ({width, height}) => {
			$service.imageSize = {width : width, height: height};
		};

		$service.toggle360Viewer = (argJellyUrl, photoSphereElement) => {
			$service.jellyUrl = argJellyUrl;
			$service.photosphereElement = photoSphereElement;
			init360();
			$timeout(() => {
				onPSVReady();
			}, 200);
		};

		function init360() {
			if ($service.PSV) {
				if ($service.PSV.parent && $service.PSV.container) {
					$service.PSV.parent.removeChild($service.PSV.container);
				}
				$service.PSV.destroy.bind(this);
			}
			$service.PSV = new PhotoSphereViewer({
				panorama: $service.jellyUrl,
				container: $service.photosphereElement,
				gyroscope: true,
				mousecursor: true,
				move_speed: 1,
				min_fov: 60,
				max_fov: 60,
				default_fov: 60,
				loading_txt: $translate.instant('common.loading'),
				navbar: [
					'fullscreen',
					{
						id: 'close-psv-button',
						className: 'close-psv-button',
						onClick: function () {
							$rootScope.$broadcast('interior-panorama:kill-360');
						}
					}
				],
				size: {
					height: $service.imageSize.height,
					width: $service.imageSize.width,
				}
			});

			$service.PSV.on('ready', function () {
				onPSVReady();
				update360NavbarStyle();
			});

			$service.PSV.on('fullscreen-updated', function () {
				$service.PSV.zoom(0);
				$service.PSV.stopAutorotate();
				$window.dispatchEvent(new Event('resize'));
				$('.psv-fullscreen-button .psv-button-svg').keydown(function(event) {
					if (event.keyCode == 13) {
						$service.PSV.enterFullscreen();
					}
				});
				$('.close-psv-button').attr('tabindex','0');
				$('.close-psv-button').keydown(function(event) {
					if (event.keyCode == 13) {
						$rootScope.$broadcast('interior-panorama:kill-360');
					}
				});
			});
		}

		function onPSVReady() {
			$window.dispatchEvent(new Event('resize'));
			$service.PSV.zoom(0);
			$service.PSV.stopAutorotate();
		}

		function update360NavbarStyle() {
			const navbarContainers = document.getElementsByClassName("psv-container psv-container--has-navbar");
			const navbars = document.getElementsByClassName("psv-navbar psv-navbar--open");
			const fullscreenButtons = document.getElementsByClassName("psv-button psv-button--hover-scale psv-fullscreen-button");
			const closePsvButtons = document.getElementsByClassName("close-psv-button");

			const arrow_keys_handler = function(e) {
				switch(e.keyCode) {
					case 37: case 39: case 38: case 40: e.preventDefault(); break;
					default: break;
				}
			};
			
			$('.psv-canvas').attr('tabindex','0');
			$('.psv-canvas').focus();
			$service.PSV.startKeyboardControl();
			window.addEventListener('keydown',arrow_keys_handler, false);

			$('.close-psv-button').attr('tabindex','0');
			$('.close-psv-button').keydown(function(event) {
				if (event.keyCode == 13) {
					$rootScope.$broadcast('interior-panorama:kill-360');
					window.removeEventListener('keydown',arrow_keys_handler, false);
				}
			});			
			$('.psv-canvas-container').attr('role','img');
			$('.psv-canvas-container').attr('aria-label',$translate.instant('interior360.v360'));
			
			$('.psv-canvas').focus(function(e) {		
				$service.PSV.startKeyboardControl();
				window.addEventListener('keydown',arrow_keys_handler, false);
			});
			$('.psv-canvas').blur(function() {				
				$service.PSV.stopKeyboardControl();
				window.removeEventListener('keydown',arrow_keys_handler, false);
			});
			$('.psv-fullscreen-button .psv-button-svg').attr('tabindex','0');
			$('.psv-fullscreen-button .psv-button-svg').keydown(function(event) {
				if (event.keyCode == 13) {
					$service.PSV.enterFullscreen();
				}
			});
			
			for (let i = 0; i < navbarContainers.length; i++) {
				navbarContainers[i].style.display = "flex";
				navbarContainers[i].style.alignItems = "center";
			}

			for (let i = 0; i < navbars.length; i++) {
				navbars[i].style.background = "none";
				navbars[i].style.justifyContent = "flex-end";
				navbars[i].style.position = "static";
				navbars[i].style.pointerEvents = "none";
				navbars[i].style.flexDirection = "column";
				navbars[i].style.alignItems = "flex-end";
			}

			for (let i = 0; i < fullscreenButtons.length; i++) {
				fullscreenButtons[i].style.background = "rgba(206,206,206,0.85)";
				fullscreenButtons[i].style.borderRadius = "3px 3px 0 0";
				fullscreenButtons[i].style.marginRight = "10px";
				fullscreenButtons[i].style.pointerEvents = "all";
				fullscreenButtons[i].setAttribute("data-analyticsid","360view-expand");
			}

			for (let i = 0; i < fullscreenButtons.length; i++) {
				closePsvButtons[i].style.background = "rgba(152,152,152,0.85)";
				closePsvButtons[i].style.borderRadius = "0 0 3px 3px";
				closePsvButtons[i].style.marginRight = "10px";
				closePsvButtons[i].style.pointerEvents = "all";
				if (closePsvButtons[i].childElementCount < 1) {
					let iconElement = document.createElement('i');
					iconElement.className = "fcaicon fcaicon-360";
					iconElement.style.fontSize = "1.5em";
					closePsvButtons[i].appendChild(iconElement);
					closePsvButtons[i].setAttribute("data-analyticsid","360view-close");
				}
			}
		}
	}
})();
