(function () {
    angular
        .module('alfaromeo.vlp')
		.directive('alfaromeoVlpVr360', alfaromeoVlpVR360);

	function alfaromeoVlpVR360() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				irisCposVehicleType: '@',
				irisVehicule: '@',
				irisMfgCode: '@',
				irisPackageCode: '@',
				irisJellyPaint: '@',
				irisJellyOptions: '@',
				vrDebug: '@',
				vrHotspots: '<',
				vrMarkerWidth: '@',
				vrMarkerHeight: '@',
				vrX: '@',
				vrY: '@',
				vrZoom: '@',
				vrZoomMin: '@',
				vrZoomMax: '@',
				vrZoomSpeed: '@',
				vrAria: '@',
				vrAriaThreesixty: '@',
				vrAriaAccessibility: '@',
				vrMarkerClose: '@',
				viewInteriorAnalyticsLabel: '@?'
			},
			controllerAs: '$ctrl',
			controller: alfaromeoVlpVR360Controller
		};

		function alfaromeoVlpVR360Controller($window, $rootScope, $timeout, $element, $document, gtmAnalytics, alfaIrisService, AR_IRIS_PRESETS) {
			'ngInject';

			const $ctrl = this;
			const irisUrlBuilder = alfaIrisService.getUrlBuilder().preset(AR_IRIS_PRESETS.PANORAMIC);

			let currentPanoramaImageURL = null;
			let psv;
			let dragging;

			const updatePanoramaImage = () => {
				currentPanoramaImageURL = $ctrl.getJellyUrl();
				if( currentPanoramaImageURL === null ) return;

				psv.setPanorama(currentPanoramaImageURL);
			};
			const onKeyDown = (event) => {
				// ESCAPE key pressed
				if( event.keyCode == 27 ) {
					$window.document.removeEventListener('keydown', onKeyDown);
					$ctrl.closeModal();
				}
			};

			const disableBodyScroll = bodyScrollLock.disableBodyScroll;
			const enableBodyScroll = bodyScrollLock.enableBodyScroll;

			$ctrl.started = false;
			$ctrl.selectedColor = null;
			$ctrl.selectedCode = null;
			$ctrl.selectedLabel = null;
			$ctrl.selectedCodeOption = null;
			$ctrl.hoveringLabel = null;
			$ctrl.selectedHotspot = null;
			$ctrl.modalEnabled = false;
			$ctrl.modalVisible = false;
			$ctrl.modalAnimationIsCompleted = false;
			$ctrl.colorSelectionVisible = false;
			$ctrl.showColorSelectionBtn = false;

			// default values
			$ctrl.vrDebug = false;
			$ctrl.vrMarkerWidth = 50;
			$ctrl.vrMarkerHeight = 50;
			$ctrl.vrX = 0;
			$ctrl.vrY = 0;
			$ctrl.vrZoomMax = 30;
			$ctrl.vrZoomMin = 90;
			$ctrl.vrZoom = 60;
			$ctrl.vrZoomSpeed = 10;

			$ctrl.$onInit = () => {
				psv = new PhotoSphereViewer({
					container: $element.find('[data-js-photosphereviewer]').get(0),
					gyroscope: false,
					time_anim: false,
					anim_speed : '1rpm',
					loading_txt: '',
					move_speed: 1,
					mousewheel: false,
					pinch_to_zoom: false,
					navbar: false,
					cache_texture: 3,
					usexmpdata: false,

					default_long: $ctrl.vrX,
					default_lat: $ctrl.vrY,
					min_fov: $ctrl.vrZoomMax,
					max_fov: $ctrl.vrZoomMin,
					default_fov: $ctrl.vrZoom,
				});

				psv.on('ready', function() {
					$('.psv-canvas-container').attr('role','img');
					$('.psv-canvas-container').attr('aria-label',$ctrl.vrAriaAccessibility);
				}); 
				psv.zoom($ctrl.vrZoom);
				psv.on('select-marker', (marker) => {
					// if there is already a selected hotspot
					if( $ctrl.selectedHotspot ) return;

					$ctrl.showModal(marker.data);

					gtmAnalytics.trackEvent('event', {
						category: 'vlp 360 interior',
						label: `${marker.data.analyticsLabel}`,
					});
				});

				if( $ctrl.vrDebug ) {
					psv.on('click', (event) => {
						console.log('longitude:', event.longitude, 'latitude:', event.latitude);
					});
				}

				$ctrl.viewInteriorAnalyticsLabel = $ctrl.viewInteriorAnalyticsLabel || 'view 360 interior';
				$ctrl.targetElement = $element[0].querySelector(".AR_VLP-VR360-modalContainer");
			};
			$ctrl.$onChanges = (changes) => {
				// check if new panorama image url has changed
				const newPanoramaImageURL = $ctrl.getJellyUrl();
				if( newPanoramaImageURL !== currentPanoramaImageURL ) updatePanoramaImage();

				// if PhotoSphereViewer has not been instanciated
				if( !psv ) return;

				// check if vr-x or vr-y has changed
				if( changes.vrX || changes.vrY ) {
					psv.rotate({x: $ctrl.vrX, y: $ctrl.vrY});
				}

				// check if vr-zoom-max or vr-zoom-min
				if( changes.vrZoomMax || changes.vrZoomMin ) {
					if( changes.vrZoomMax ) psv.change('min_fov', $ctrl.vrZoomMax);
					if( changes.vrZoomMin ) psv.change('max_fov', $ctrl.vrZoomMin);
				}

				// check if vr-zoom has changed
				if( changes.vrZoom ) {
					if( $ctrl.vrZoom > $ctrl.vrZoomMin ) $ctrl.vrZoom = $ctrl.vrZoomMin;
					if( $ctrl.vrZoom < $ctrl.vrZoomMax ) $ctrl.vrZoom = $ctrl.vrZoomMax;

					psv.zoom($ctrl.vrZoom);
				}
			};

			$ctrl.getJellyUrl = () => {
				// if paint is not defined, do nothing
				if( !$ctrl.irisJellyPaint ) return null;

				// if fabric is not selected, do nothing
				if( !$ctrl.selectedCode ) return null;

				// build jelly URL
				irisUrlBuilder
					.vehicule($ctrl.irisVehicule)
					.mfgCode($ctrl.irisMfgCode)
					.packageCode($ctrl.irisPackageCode)
					.cposVehicleType($ctrl.irisCposVehicleType)
					.paint($ctrl.irisJellyPaint)
					.fabric($ctrl.selectedCode);

				const options = [];

				if( $ctrl.selectedCodeOption ) options.push( $ctrl.selectedCodeOption );
				if( $ctrl.irisJellyOptions ) options.push( ...$ctrl.irisJellyOptions.split(',') );
				if( options.length > 0 ) irisUrlBuilder.options( options );

				return irisUrlBuilder.getURL();
			};

			$ctrl.selectColor = (event, colorContentPath, colorCode, colorDescription, colorOption) => {
				// if this color is already selected, do nothing
				if( $ctrl.selectedColor === colorContentPath ) return;

				$ctrl.selectedColor = colorContentPath;
				$ctrl.selectedCode = colorCode;
				$ctrl.selectedLabel = colorDescription;
				$ctrl.selectedCodeOption = colorOption;

				updatePanoramaImage();
			};
			$ctrl.rolloverColor = (event, colorDescription) => {
				$ctrl.hoveringLabel = colorDescription;
			};
			$ctrl.rolloutColor = (event) => {
				$ctrl.hoveringLabel = null;
			};

			$ctrl.start = (event) => {
				const hud = psv.hud;
				const hotspots = $ctrl.vrHotspots;
				const arrow_keys_handler = function(e) {
					switch(e.keyCode) {
						case 37: case 39: case 38: case 40: e.preventDefault(); break;
						default: break;
					}
				};

				// force a resize event the get the correct initial ratio by photosphere viewer
				$window.dispatchEvent(new Event('resize'));

				// add markers but do not render them immediatly
				hotspots.forEach((hotspot) => {
					const position = hotspot.tooltipPosition ? hotspot.tooltipPosition : 'left';
					hud.addMarker({
						id: hotspot.id,
						name: hotspot.title,
						tooltip: {
							content: hotspot.title,
							position: ['center', position],
						},
						longitude: hotspot.x,
						latitude: hotspot.y,
						image: '/assets/images/1x1.png',
						width: parseInt($ctrl.vrMarkerWidth),
						height: parseInt($ctrl.vrMarkerHeight),
						data: hotspot,
					}, false);
				});

				// render all markers
				hud.renderMarkers(true);

				// set component as started
				$ctrl.started = true;
				$ctrl.showColorSelectionBtn = true;

				$('.psv-canvas-container').attr('role','img');
				$('.psv-canvas-container').attr('aria-label',$ctrl.vrAriaThreesixty);
				$('.psv-marker').attr('role','img');
				$('.psv-marker').attr('aria-label',$ctrl.vrAria);
				$('.AR_VLP-VR360-modalClose').attr('aria-label',$ctrl.vrMarkerClose);
				$('.psv-marker').attr('tabindex','0');
				$('.psv-marker').keyup(function(event) {
					if (event.keyCode == 13) {
						let theMarkerID = $(this).attr('id').split('-');
						let theMarker = parseInt(theMarkerID[2])-1;
						$ctrl.showModal($ctrl.vrHotspots[theMarker]);
					}
				}); 
				
				$('.psv-canvas').attr('tabindex','0');
				$('.psv-canvas').focus(function(e) {		
					psv.startKeyboardControl();
					window.addEventListener('keydown',arrow_keys_handler, false);
				});
				$('.psv-canvas').blur(function() {				
					psv.stopKeyboardControl();
					window.removeEventListener('keydown',arrow_keys_handler, false);
				});

				gtmAnalytics.trackEvent('event', {
					category: 'vlp design',
					label: $ctrl.viewInteriorAnalyticsLabel
				});
			};
			$ctrl.zoomIn = (event) => {
				$ctrl.vrZoom = parseInt($ctrl.vrZoom) + parseInt($ctrl.vrZoomSpeed);
				if( $ctrl.vrZoom > $ctrl.vrZoomMin ) $ctrl.vrZoom = $ctrl.vrZoomMin;

				psv.zoom( $ctrl.vrZoom );
			};
			$ctrl.zoomOut = (event) => {
				$ctrl.vrZoom = parseInt($ctrl.vrZoom) - parseInt($ctrl.vrZoomSpeed);
				if( $ctrl.vrZoom < $ctrl.vrZoomMax ) $ctrl.vrZoom = $ctrl.vrZoomMax;

				psv.zoom( $ctrl.vrZoom );
			};

			$ctrl.showModal = (hotspot) => {
				if( !hotspot ) return;
				if( hotspot === $ctrl.selectedHotspot ) return;
				if( $ctrl.modalEnabled === true || $ctrl.modalVisible === true ) return;

				angular.element('html').css('overflow', 'hidden');
				if (disableBodyScroll && bodyScrollLock.disableBodyScroll) {
					disableBodyScroll($ctrl.targetElement);
				}
				$window.document.addEventListener('keydown', onKeyDown);

				$ctrl.selectedHotspot = hotspot;
				$ctrl.modalEnabled = true;
				$ctrl.modalAnimationIsCompleted = false;

				// open modal after a 100ms delay
				$timeout(() => {
					$ctrl.modalVisible = true;
				}, 100);

				// set animation as completed after a 500ms delay (100ms startup delay + 400ms for animation)
				$timeout(() => {
					$ctrl.modalAnimationIsCompleted = true;
					$('.AR_VLP-VR360-modalClose').focus();
				}, 500);
			};
			$ctrl.closeModal = (event) => {
				if( event ) {
					event.preventDefault();
					event.stopPropagation();
				}

				if( $ctrl.modalVisible === false ) return;

				$window.document.removeEventListener('keydown', onKeyDown);
				angular.element('html').css('overflow', '');
				if (enableBodyScroll && bodyScrollLock.enableBodyScroll) {
					enableBodyScroll($ctrl.targetElement);
				}

				$ctrl.modalVisible = false;
				$ctrl.modalAnimationIsCompleted = false;


				$timeout(() => {
					$ctrl.selectedHotspot = null;
					$ctrl.modalEnabled = false;
				}, 500);
			};
			$ctrl.onModalBackgroundClicked = (event) => {
				if( event ) {
					event.preventDefault();
					event.stopPropagation();
				}

				if( $ctrl.modalAnimationIsCompleted === true ) $ctrl.closeModal();
			};

			$ctrl.isZoomInDisabled = () => {
				return parseInt($ctrl.vrZoom) >= parseInt($ctrl.vrZoomMin);
			};
			$ctrl.isZoomOutDisabled = () => {
				return parseInt($ctrl.vrZoom) <= parseInt($ctrl.vrZoomMax);
			};

			$element.on('touchstart mousedown', vr360mousedown);

			function vr360mousedown (event) {
				$document.on('touchmove mousemove', vr360mousemove);
				$document.on('touchend mouseup', vr360mouseup);
			};

			function vr360mouseup(event) {
				$document.off('touchmove mousemove', vr360mousemove);
				$document.off('touchend mouseup', vr360mouseup);

				// Send Analytics Once Experience Done
				if(dragging) {
					gtmAnalytics.trackEvent('event', {
						category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
						label: 'vlp-360-interior-drag-'
					});
				}

				dragging = false;
			}

			function vr360mousemove(event) {
				dragging = true;
			}

			$rootScope.$on('onScrollingUpdate', () => {
				const parentElem = $($element[0]).parents('.vertical-scroll-block-container');
				const panoramaOverlay = parentElem[0].querySelector('.AR_VLP-VR360-panoramaOverlay');
				if (parentElem[0].classList.contains('is-current')) {
					panoramaOverlay.classList.add('-overlay-visible');
				}
			});
		}
	}
})();
