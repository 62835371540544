(function(ng) {
	'use strict';

	angular
		.module('fca.postalCodeInput')
		.controller('fcaPostalCodeInputController', PostalCodeInputController)
		.component('fcaPostalCodeInput', {
			templateUrl: '/postal-code-input/postalCodeInput.html',
			controller: 'fcaPostalCodeInputController',
			controllerAs: '$ctrl',
			bindings: {
				placeholder: '@',
				onSelected: '&'
			}
		});

	function PostalCodeInputController() {
		'ngInject';

		this.data = null;
		this.isValid = false;
		this.postalCode = '';
		this.displayError = false;
		this.ariaInvalid = false;

		const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

		this.validatePostalCode = (e) => {
			this.postalCode = e.currentTarget.value;
			this.isValid = regex.exec(this.postalCode);
			this.ariaInvalid = false;

			if (!this.isValid && this.postalCode.length >= 6) {
				this.displayError = true;
			}

			if (this.isValid) {
				this.displayError = false;
			}
		};

		this.select = ($evt) => {
			$evt.preventDefault();

			this.displayError = false;

			if (this.isValid) {
				let data = this.postalCode;

				/* Pass data outside component */
				this.onSelected({
					data: data
				});
			} else {
				this.displayError = true;
				this.ariaInvalid = true;
			}
		};
	}
})(angular);
