(function () {
	angular
		.module('fca.buyOnlineNavigationBar')
		.directive('fcaBuyOnlineNavigationBar', FcaBuyOnlineNavigationBar);

	function FcaBuyOnlineNavigationBar() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: FcaBuyOnlineNavigationBarCtrl
		};

		function FcaBuyOnlineNavigationBarCtrl($rootScope, $timeout) {
			'ngInject';

			let $ctrl = this;

			$ctrl.isSubmitting = false;

			$rootScope.$on('fca-buy-online:enable-last-step-btn', () => {
				$timeout(() => {
					$ctrl.isSubmitting = false;
				});
			});

			$ctrl.onSubmitClick = () => {
				$rootScope.$broadcast('fca-buy-online:last-step-click');
				$ctrl.isSubmitting = true;
			}
		}
	}
})();
