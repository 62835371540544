(function () {
    angular
        .module('alfaromeo.buildAndPrice.selector')
        .component('alfaSelector', {
            controller: alfaSelector,
            controllerAs: '$ctrl',
            templateUrl: '/brand-specific/alfaromeo/components/selector/alfaromeo-selector.html',
            bindings: {
                onSelect: '<',
                options: '<',
                type: '@',
                withBorder: '<',
                withTitle: '<',
                withoutSwatchLabels: '<',
                categoryName: '@',
                placeholderImage: '@'
            }
        });

    function alfaSelector($rootScope, alfaConfigPipeline) {
        'ngInject';

        const $ctrl = this;

        $ctrl.validTypes = Object.values(alfaConfigPipeline.selectorTypes);

        $ctrl.$onInit = () => {
            if ($ctrl.validTypes.indexOf($ctrl.type) === -1) {
                console.error(
                    `Invalid type in alfa-selector, expected one of ` +
                    `${JSON.stringify($ctrl.validTypes)}, got ${$ctrl.type}`
                );
            }
        }

        $ctrl.selectedOption =
            () => $ctrl.options.find(option => option.selected);

        $ctrl.onOptionClick = (option) => {
            $ctrl.onSelect(option);
        }

        $ctrl.onOptionMouseEnter = (option) => {
            $ctrl.currentlyHoveredOption = option;
        }

        $ctrl.onOptionMouseLeave = () => $ctrl.currentlyHoveredOption = null;

        $ctrl.selectorTitle = () => {
            if ($ctrl.currentlyHoveredOption) {
                return $ctrl.currentlyHoveredOption.description;
            } else if ($ctrl.options) {
                return $ctrl.selectedOption().description;
            }
        }

        $ctrl.selectedOptionPrice = () => {
            if ($ctrl.currentlyHoveredOption) {
                return $ctrl.currentlyHoveredOption.msrp;
            } else if ($ctrl.options) {
                return $ctrl.selectedOption().msrp;
            }
        }

        $ctrl.onMissedAlertClick = event => {
            event.stopPropagation();
            $rootScope.$broadcast('missedAlertClick')
        }
    }
})();
