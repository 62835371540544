(function () {
    angular
        .module('fca.welcomeVideoAutoshow')
        .directive('fcaWelcomeVideoAutoshow', fcaWelcomeVideoAutoshow);

    function fcaWelcomeVideoAutoshow() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {isMainVideo : '@?'},
            controllerAs: '$fcaWelcomeVideoAutoshowCtrl',
            controller: fcaWelcomeVideoAutoshowController
        };

        function fcaWelcomeVideoAutoshowController($scope, $rootScope, $cookies, $element) {
            'ngInject';

            const $ctrl = this;
            this.isVideoEnded = false;
			this.isMainVideo = false;

            $ctrl.$onInit = () => {
                $ctrl.checkCookie();
            }

            $rootScope.$on('fca-modal-is-closed', ()=>{
                $ctrl.setCookie();
            });

            $ctrl.checkCookie = () => {
                var welcomeVideoPlayed = $cookies.get('fcaWelcomeVideoPlayed');
				var openModal = ($ctrl.isMainVideo == false);

                if (!welcomeVideoPlayed && openModal) {
                    $scope.$broadcast('fcaModal:open');
                }
            };

            $ctrl.setCookie = () => {
                $cookies.put('fcaWelcomeVideoPlayed', true);
            };

			$ctrl.addVideoEndedEvent = () => {
				$($element).find('video').get(0).addEventListener('ended', videoIsEnded);
			};

			$ctrl.restartVideo = () => {
				$ctrl.isVideoEnded = false;
			};

			function videoIsEnded() {
				$ctrl.isVideoEnded = true;
				$scope.$apply();
			}

        }
    }
})();
