(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselCustomizer', FcaSlickCarouselCustomizer);

	function FcaSlickCarouselCustomizer() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselCustomizerController
		};

		function FcaSlickCarouselCustomizerController(gtmAnalytics, $scope, $element, $window, $attrs, $timeout, matchmedia, FCA_MQ_LAYOUT, functionExecutionControl) {
			'ngInject';

			const $ctrl = this;

			$ctrl.maxVisibleSwatch = JSON.parse($attrs.maxVisibleSwatches)[0];
			$ctrl.numberOfLines = JSON.parse($attrs.numberOfLines)[0];
			$ctrl.maxNumberPerRow = JSON.parse($attrs.maxNumberPerRow)[0];		
			$ctrl.currentNumberOfLines = 2;
			$ctrl.currentMaxNumberPerRow = 3;
			const totalSwatches = $attrs.totalSwatches;

			$ctrl.activeDot = 'left';
    
            $ctrl.$postLink = () => {
				$timeout(() => {
					const contentContainer = angular.element('.customizer-content-container');
					$ctrl.swatchesLoaded();

					contentContainer.on('scroll', functionExecutionControl.debounce(() => {
						$ctrl.activeDot = contentContainer[0].scrollLeft < (contentContainer[0].scrollWidth - contentContainer[0].offsetWidth) / 2 ? 'left' : 'right';
						$scope.$apply();
					}, 200));
				}, 200); // todo: remove this timeout and ensure dots/arrows are still showing correctly

				angular.element($window).on('resize', function () {
					$ctrl.swatchesLoaded();
				});

				// update nb of visible swatches for current resolution
				matchmedia.on(FCA_MQ_LAYOUT.MOBILE, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						$ctrl.currentNumberOfLines = (totalSwatches > $ctrl.maxNumberPerRow.mobile) ? (totalSwatches > 20) ? $ctrl.numberOfLines.mobile + 1 : $ctrl.numberOfLines.mobile : 1;
						$ctrl.currentMaxNumberPerRow = $ctrl.maxNumberPerRow.mobile;
					}
				});
				matchmedia.on(FCA_MQ_LAYOUT.MOBILE_LANDSCAPE, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						$ctrl.currentNumberOfLines = (totalSwatches > $ctrl.maxNumberPerRow.mobileLandscape) ? (totalSwatches > 20) ? $ctrl.numberOfLines.mobileLandscape + 1 : $ctrl.numberOfLines.mobileLandscape : 1;
						$ctrl.currentMaxNumberPerRow = $ctrl.maxNumberPerRow.mobileLandscape;
					}
				});
				matchmedia.on(FCA_MQ_LAYOUT.TABLET, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						$ctrl.currentNumberOfLines = (totalSwatches > $ctrl.maxNumberPerRow.tablet) ? (totalSwatches > 20) ? $ctrl.numberOfLines.tablet + 1 : $ctrl.numberOfLines.tablet : 1;
						$ctrl.currentMaxNumberPerRow = $ctrl.maxNumberPerRow.tablet;
					}
				});
				matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_SMALL, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						$ctrl.currentNumberOfLines = (totalSwatches > $ctrl.maxNumberPerRow.desktopSmall) ? (totalSwatches > 20) ? $ctrl.numberOfLines.desktopSmall + 1 : $ctrl.numberOfLines.desktopSmall : 1;
						$ctrl.currentMaxNumberPerRow = $ctrl.maxNumberPerRow.desktopSmall;
					}
				});
				matchmedia.on(FCA_MQ_LAYOUT.DESKTOP_LARGE, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						$ctrl.currentNumberOfLines = (totalSwatches > $ctrl.maxNumberPerRow.desktopLarge) ? (totalSwatches > 20) ? $ctrl.numberOfLines.desktopLarge + 1 : $ctrl.numberOfLines.desktopLarge : 1;
						$ctrl.currentMaxNumberPerRow = $ctrl.maxNumberPerRow.desktopLarge;
					}
				});
			};
            
            $ctrl.swatchesLoaded = () => {
				
				// get elements
				let customizerContainer = $element.find('.customizer-content-container');
				let customizerContent = $element.find('.customizer-content-selector')[0];
				let spanCount = customizerContent.querySelectorAll('button').length;

				// get boundingBox with buffer
				let eachBoxWidth = $(customizerContent).find('button')[0].clientWidth + 4.5;
				var elementPerRow = (spanCount / $ctrl.currentNumberOfLines);

				if (((spanCount / $ctrl.currentNumberOfLines) < $ctrl.currentMaxNumberPerRow) || (spanCount <= $ctrl.maxVisibleSwatch)) {
					elementPerRow = $ctrl.currentMaxNumberPerRow;
				}
				
				// calculate width
				let calcWidth = (Math.ceil(elementPerRow) * eachBoxWidth);
				let containerWidth = $element.find('.customizer-content-container')[0].clientWidth;

				// apply width to child
				$(customizerContent).css('width', calcWidth);

				// show arrows ? if container is wider
				let minTreshold = containerWidth + (eachBoxWidth / 2);
				$ctrl.showArrow = (minTreshold < calcWidth);
				$(customizerContainer).css('overflow-x', (containerWidth < minTreshold) ? 'scroll' : 'hidden');
            };
            
            $ctrl.arrowClicked = (direction) => {
				let colorList = $element.find('.customizer-content-container');
				let scrollableWidth = colorList[0].scrollWidth - colorList[0].clientWidth;

				if (direction === 'left') {
					colorList.animate({scrollLeft: 0}, 200);
					$ctrl.activeDot = 'left';
				} else {
					colorList.animate({scrollLeft: scrollableWidth}, 200);
					$ctrl.activeDot = 'right';
				}
			};
		}
	}
})();
