
(function() {
    angular
        .module('fca.bodyBuilder')
        .directive('bodyBuilderDropdown', bodyBuilderDropdown);

    function bodyBuilderDropdown() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
            },
            controllerAs: '$bodyBuilderDropdown',
            controller: bodyBuilderDropdownController
        };

        function bodyBuilderDropdownController() {
            'ngInject';

            let $ctrl = this;
            $ctrl.menuOpened = false;

            $ctrl.toggleMenu = (event) => {
                if (event) {
                    event.preventDefault();
                }

                if (this.menuOpened) {
                    this.menuOpened = false;
                } else {
                    this.menuOpened = true;
                }
            }
        }
    }
})();
