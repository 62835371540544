(function () {
    angular
        .module('fca.uconnect')
        .component('fcaStartOverlay', {
            controller: fcaStartOverlay,
            controllerAs: '$ctrl',
            templateUrl: '/uconnect/components/start-overlay/start-overlay.html',
            bindings: {
                closeStartOverlay: '<',
                analyticsId: '@'
            }
        });

    function fcaStartOverlay($element, $scope) {
        'ngInject';

        const $ctrl = this;
        let defaultAnalyticsId = 'uconnect-key-feature-initial';

        $ctrl.closeOverlay = () => {
            $ctrl.closeStartOverlay();
        };

        $ctrl.$onInit = () => {
            const startOverlay = $element[0].querySelector('div.C_UCSO-container');
            if (!$ctrl.analyticsId) {
                $ctrl.analyticsId = defaultAnalyticsId;
            }

            startOverlay.addEventListener('click', () => {
                if (event.target.id === 'C_UCSO-container') {
                    $scope.$apply($ctrl.closeOverlay());
                }
            });
        }
    }
})();
