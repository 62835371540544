(function() {
    'use strict';

    angular
        .module('fca.commonFilters')
        .filter('htmlToPlainText', htmlToPlainText);

    function htmlToPlainText() {
        'ngInject';

        return function(text) {
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        };
    }
})();
