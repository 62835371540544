// Allows the usage of the following attributes:
//     * ng-durationchange
//     * ng-ended
//     * ng-loadedmetadata
//     * ng-pause
//     * ng-playing
//     * ng-timeupdate
(function () {
  'use strict';

  angular
    .module('fca.commonDirectives')
    .directive('ngDurationchange', onDurationChange)
    .directive('ngEnded', onEnded)
    .directive('ngLoadedmetadata', onLoadedMetadata)
    .directive('ngPause', onPause)
    .directive('ngPlaying', onPlaying)
    .directive('ngProgress', onProgress)
    .directive('ngTimeupdate', onTimeUpdate)
    .directive('ngVolumechange', onVolumeChange)
    .directive('ngFullscreen', onFullscreen);

  function onDurationChange() {
    return {
      controller: function ($scope, $element) {
        $element.bind('durationchange', onDurationChange);

        function onDurationChange(event) {
          var method = $element.attr('ng-durationchange');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onEnded() {
    return {
      controller: function ($scope, $element) {
        $element.bind('ended', onEnded);

        function onEnded(event) {
          var method = $element.attr('ng-ended');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onLoadedMetadata() {
    return {
      controller: function ($scope, $element) {
        $element.bind('loadedmetadata', onLoadedMetadata);

        function onLoadedMetadata(event) {
          var method = $element.attr('ng-loadedmetadata');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onPause() {
    return {
      controller: function ($scope, $element) {
        $element.bind('pause', onPause);

        function onPause(event) {
          var method = $element.attr('ng-pause');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onPlaying() {
    return {
      controller: function ($scope, $element) {
        $element.bind('playing', onPlaying);

        function onPlaying(event) {
          var method = $element.attr('ng-playing');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onProgress() {
    return {
      controller: function ($scope, $element) {
        $element.bind('progress', onProgress);

        function onProgress(event) {
          var method = $element.attr('ng-progress');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onTimeUpdate() {
    return {
      controller: function ($scope, $element) {
        $element.bind('timeupdate', onTimeUpdate);

        function onTimeUpdate(event) {
          var method = $element.attr('ng-timeupdate');
          $scope.$event = event;
          $scope.$apply(method);
        }
      }
    };
  };

  function onVolumeChange() {
    return {
      controller: function ($scope, $element) {
        $element.bind('volumechange', onVolumeChange);

        function onVolumeChange(event) {          
          var method = $element.attr('ng-volumechange');
          $scope.$event = event;
          $scope.$volume = $element[0].volume;
          $scope.$muted = $element[0].muted;
          $scope.$apply(method);
        }
      }
    };
  };

  function onFullscreen() {
    return {
      controller: function ($scope, $element, $window) {
        ["", "webkit", "moz", "ms"].forEach(prefix => $element.bind(`${prefix}fullscreenchange`, onFullscreen));

        function onFullscreen(event) {
          var doc = $window.document;
          var isFullscreen = Boolean(
            doc.fullscreenElement ||
            doc.webkitFullscreenElement ||
            doc.webkitCurrentFullScreenElement ||
            doc.mozFullScreenElement ||
            doc.msFullscreenElement
          );

          var method = $element.attr('ng-fullscreen');
          $scope.$event = event;
          $scope.$isFullscreen = isFullscreen;
          $scope.$apply(method);
        }
      }
    };
  };

})();
