(function() {
	angular
		.module('alfaromeo.miniNav')
		.directive('alfaMiniNavItem', alfaMiniNavItem);

	function alfaMiniNavItem() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				miniNavGroupId: '@'
			},
			controllerAs: '$ctrl',
			controller: AlfaMiniNavItemController
		};

		function AlfaMiniNavItemController($rootScope, $element, $timeout, miniNavService, gtmAnalytics) {
			'ngInject';

			const ACTIVE_CSS_CLASSNAME = 'is-active';

			const $ctrl = this;
			let highlightApplied = false;
			let shouldTriggerWaypoint = true;
			let scrollTarget;

			$rootScope.$on('alfaScrollTo.active', (event, data) => {
				shouldTriggerWaypoint = false;
				scrollTarget = data;
			});

			$rootScope.$on('alfaScrollTo.inactive', () => {
				shouldTriggerWaypoint = true;
			});

			const trackVirtualPageView = () => {
				gtmAnalytics.trackEvent('event', {
					category: 'vlp section nav',
					label: $ctrl.miniNavGroupId,
				});
			};
			const onWaypointReached = (event, data) => {
				// if waypoint event is related to our mini nav items
				const eventFromValidGroup = miniNavService.itemCollection.indexOf(data.groupId) >= 0;
				const isHidden = $element[0].offsetParent === null;

				if( !eventFromValidGroup ) return;

				// if waypoint is not temporarily deactivated by an user click
				if(shouldTriggerWaypoint && !isHidden) {
					// if waypoint reached event is not related to this element, do nothing
					if( data.groupId === $ctrl.miniNavGroupId ) {
						// set element as active
						highlightApplied = true;
						$('[data-alfa-mini-nav-item]').removeClass(ACTIVE_CSS_CLASSNAME);
						$('[data-alfa-mini-nav-item]').blur();
						$element.addClass( ACTIVE_CSS_CLASSNAME );
						miniNavService.setActiveItem( $ctrl.miniNavGroupId );

						// Handle analytics tracking
						if( !$element[0].dataset.viewed ) {
							// Warning: Set timeout prevents the same groupId from being tracked multiple times
							// also prevents false tracking of virtual views when doing a scrollTo
							$timeout(() => {
								const activeItem = miniNavService.getActiveItem();

								if( activeItem === $ctrl.miniNavGroupId && !$element[0].dataset.viewed ) {
									// tag the groupId so it does not get tracked multiple times (backend returns multiple divs with same id)
									if (!$element[0].dataset.viewed) {
										trackVirtualPageView();
										$element[0].dataset.viewed = true;
									}
								}
							}, 1000);
						}
					} else {
						// if element is highlighted, remove highlight
						if( highlightApplied ) {
							$element.removeClass( ACTIVE_CSS_CLASSNAME );
							highlightApplied = false;

							// removes highlight in case element was still active (in the event there's a scroll up right after the link click)
							$element.blur();
						}
					}
				} else {

					if(scrollTarget && !isHidden && scrollTarget === data.groupId) {

						if(scrollTarget === $ctrl.miniNavGroupId) {
							$element.addClass( ACTIVE_CSS_CLASSNAME );
							miniNavService.setActiveItem( $ctrl.miniNavGroupId );
						} else {
							$element.removeClass( ACTIVE_CSS_CLASSNAME );
						}

						scrollTarget = null;
						$timeout(() => {
							$rootScope.$broadcast('alfaScrollTo.inactive');
						}, 500);
					}
				}
			};

			$ctrl.$onInit = () => {
				// Listen for each section enetering the page (note: backend returns multiple divs with same id)
				$rootScope.$on('fcaWaypoint.reached', onWaypointReached);
			};
			$ctrl.$onChanges = (changes) => {
				// if miniNavGroupId binding has changed
				if( changes.miniNavGroupId && changes.miniNavGroupId.currentValue ) {
					// if is not default value ...
					// and if groupId is not already in miniNavService ...
					// store miniNavGroupId value in the miniNavService
					const groupId = changes.miniNavGroupId.currentValue;
					if( groupId !== 'default' && miniNavService.itemCollection.indexOf(groupId) < 0 ) {
						miniNavService.itemCollection.push( groupId );
					}
				}
			};
		}
	}
})();
