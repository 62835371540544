(function(ng) {
	'use strict';

	angular
		.module('fca.commonDialog')
		.provider('fcaDialogTheme', FcaDialogThemeProvider);

	function FcaDialogThemeProvider() {
		'ngInject';

		let cfg = {
			closeByEscape: true,
			overlay: true,
			template: '/dialogs/dialog.html',
			controller: 'fcaDialogController',
			controllerAs: '$modalCtrl',
			appendClassName: 'fca-dialog-overlay',
			name: 'fca-modal',
			showClose: true
		};

		let themes = {
			default: ng.copy(cfg)
		};

		this.$get = () => {
			return {
				get: (theme, opts) => {
					let isExits = themes.hasOwnProperty(theme);
					if (isExits === false) {
						/* Throw exception */
						throw Error(`fcaDialog Module exception: theme ${theme} does'nt exists!`);
					}

					/* Return theme options extended */
					return ng.extend({}, themes[theme], opts || {});
				}
			};
		};

		this.register = (theme, opts) => {
			if (themes.hasOwnProperty(theme)) {
				/* Throw exception */
				throw Error(`fcaDialog Module exception: theme ${theme} already exists!`);
			}

			/* Register new theme to override defaults options */
			themes[theme] = ng.extend({}, cfg, opts || {});

			/* Return provider instance for chaining */
			return this;
		};
	}
})(angular);
