// There is nearly duplicated code with Built Vehicles, Inventory Vehicles and Viewed Vehicles,
// The only difference being which UA functions are being called

(function () {
	angular
	.module('fca.userAccount.myGarage')
	.directive('fcaUserAccountMyGarageBuiltVehicles', BuiltVehicles);

	function BuiltVehicles() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				pricingInvalidationDate: '@',
				pricingValidityInHours: '@',
				calculatorDefaultFinanceRate: '@',
				maxAcceptableLeaseRate: '@'
			},
			controllerAs: '$builtVehiclesCtrl',
			controller: BuiltVehiclesCtrl
		};

		function BuiltVehiclesCtrl($scope, $rootScope, pricingService, $window) {

			let $builtVehiclesCtrl = this;

			$builtVehiclesCtrl.vehicles = [];
			$builtVehiclesCtrl.selectedIds = {};
			$builtVehiclesCtrl.sliderActive = true;
			$builtVehiclesCtrl.sliderDeactivated = false;

			$builtVehiclesCtrl.$onInit = () => {
				$rootScope.$on('ua:libraryInitialized', () => {
					$builtVehiclesCtrl.loadVehiclesAndPricing();
					if (!UA.isUserLoggedIn()) {
						$builtVehiclesCtrl.sliderDeactivated = true;
					}
					$builtVehiclesCtrl.checkVisibleSlides();
				});
			};

			$builtVehiclesCtrl.loadVehiclesAndPricing = () => {
				if (UA.isUserLoggedIn()) {
					UA.getBuiltVehicles().then((vehicles) => {
						$scope.$apply(() => {
							$builtVehiclesCtrl.selectedIds = {};
							$builtVehiclesCtrl.vehicles = vehicles;
							vehicles.forEach((vehicle) => {
								$builtVehiclesCtrl.selectedIds[vehicle.id] = false;
							});
						});

						// initialize pricing of built vehicles
						let initializedVehicleIds = pricingService.initPricingService(
							$builtVehiclesCtrl.pricingInvalidationDate,
							$builtVehiclesCtrl.pricingValidityInHours,
							$builtVehiclesCtrl.vehicles,
							$builtVehiclesCtrl.calculatorDefaultFinanceRate,
							$builtVehiclesCtrl.maxAcceptableLeaseRate);
						$builtVehiclesCtrl.checkVisibleSlides();

						// remove vehicles not correctly initialized
						$builtVehiclesCtrl.vehicles = $builtVehiclesCtrl.vehicles.filter(vehicle => {
							return initializedVehicleIds.findIndex(id => id === vehicle.id) > -1;
						});

						$scope.$broadcast('vehicleListUpdated');
					}).catch(err => {
						throw new Error('error fetching vehicles', err)
					});
				}
			};

			$builtVehiclesCtrl.getBuildAndPriceUrl = (vehicle, commonPath) => {
				const subBrandOrBrandCode = vehicle.subBrandCode ? vehicle.subBrandCode : vehicle.brandCode;

				return window.FCA_SITES_CONFIG.brandSiteUrls[subBrandOrBrandCode]
					+ commonPath
					+ "/" + subBrandOrBrandCode
					+ "/" + vehicle.nameplateCode
					+ "/" + vehicle.year
					+ "/" + vehicle.modelYearId
					+ "/" + vehicle.acode
					+ "#builtVehicleId=" + vehicle.id;
			};

			$builtVehiclesCtrl.getSniUrl = (vehicle, baseUrl) => {
				return baseUrl + "/" + vehicle.subBrandCode + "/" + vehicle.nameplateCode;
			};

			$builtVehiclesCtrl.countSelected = () => {
				let selected = Object.keys($builtVehiclesCtrl.selectedIds).filter(key => {
					return $builtVehiclesCtrl.selectedIds[key] === true;
				});
				return selected.length;
			};

			$builtVehiclesCtrl.updateVehicle = (id, titleAndNotes) => {
				UA.updateBuiltVehicle(id, titleAndNotes).then((response) => {
					if(response) {
						$builtVehiclesCtrl.vehicles.forEach(elem => {
							if (elem.id === id) {
								elem.title = response.title;
								elem.notes = response.notes;
								$scope.$apply();
							}
						});
					} else {
						// response is empty, that means this vehicle doesn't exist anymore
						$builtVehiclesCtrl.loadVehiclesAndPricing();
					}
				})
			};

			$builtVehiclesCtrl.removeVehicles = () => {
				let selectedVehicleIds = Object.keys($builtVehiclesCtrl.selectedIds).filter(key => {
					return $builtVehiclesCtrl.selectedIds[key];
				});

				selectedVehicleIds = selectedVehicleIds.map(elem => {
					return parseInt(elem);
				});

				UA.removeBuiltVehicles(selectedVehicleIds).then((response) => {
					$builtVehiclesCtrl.loadVehiclesAndPricing();
				});
			};

			$builtVehiclesCtrl.checkVisibleSlides = () => {
				let visibleSlides;
				let mobileBreakpoint = 667;
				let desktopBreakpoint = 1024;
				let windowWidth = $window.innerWidth;

				if(windowWidth < mobileBreakpoint) {
					visibleSlides = 1;
				} else if(windowWidth < desktopBreakpoint) {
					visibleSlides = 2;
				} else {
					visibleSlides = 3;
				}

				if($builtVehiclesCtrl.vehicles.length == 0 ||
					$builtVehiclesCtrl.vehicles.length <= visibleSlides ||
					$builtVehiclesCtrl.sliderDeactivated == true) {
					$builtVehiclesCtrl.sliderActive = false;
				} else {
					$builtVehiclesCtrl.sliderActive = true;
				}
			};

			angular.element($window).on('resize', function () {
				$builtVehiclesCtrl.checkVisibleSlides();
			});

			$builtVehiclesCtrl.showAll = () => {
				$builtVehiclesCtrl.sliderDeactivated = true;
				$builtVehiclesCtrl.checkVisibleSlides();
				$scope.$broadcast('destroySlider');
			}
		}
	}
})();
