
(function() {
	angular
		.module('fca.bodyBuilder')
		.directive('bodyBuilderNameplateSelector', bodyBuilderNameplateSelector);

	function bodyBuilderNameplateSelector() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				initialSlide: '='
			},
			controllerAs: '$bodyBuilderNameplateSelector',
			controller: bodyBuilderNameplateSelectorController
		};

		function bodyBuilderNameplateSelectorController($timeout, $element, $window, gtmAnalytics) { // eslint-disable-line max-len
			'ngInject';

			let $ctrl = this;

			$ctrl.mainSlider = $element.find('[data-nameplate-selector-slider]');
			$ctrl.menuOpened = false;

			$ctrl.initMasterCarousel = () => {
				this.mainSlider.slick({
					arrows: true,
					dots: true,
					slidesToShow: 4,
					initialSlide: $ctrl.initialSlide,
					infinite: false,
					responsive: [
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 3
							}
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2
							}
						},
						{
							arrows: false,
							breakpoint: 480,
							settings: {
								slidesToShow: 1
							}
						}
					]
				}).slick('refresh');
			};

			$ctrl.beforeSlideChange = () => {
				this.mainSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					let nextSlideNumber = nextSlide + 1;
					let trackingMessage = 'go to slide ' + nextSlideNumber;

					if(currentSlide !== nextSlide) {
						gtmAnalytics.trackEvent('event', {
							category: this.analyticsCategory,
							label: trackingMessage
						});
					}
				});
			};

			$ctrl.initSlickCarousels = () => {
				this.initMasterCarousel();
				this.beforeSlideChange();
			};

			$ctrl.toggleMenu = (event) => {
				if (event) {
					event.preventDefault();
				}

				if (this.menuOpened) {
					this.menuOpened = false;
				} else {
					this.menuOpened = true;
				}
			}

			$ctrl.$onInit = () => {

				$timeout(() => {
					this.initSlickCarousels();
				});
			};
		}
	}
})();
