(function() {
	angular
		.module('alfaromeo.counter')
		.directive('alfaCounter', alfaCounter);

	function alfaCounter() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				decimal: '@', // number of decimals (between 0 and 3)
				duration: '@', // animation duration (in milliseconds)
				endValue: '@',
				startValue: '@',
				locale: '@',
				ready: '<',
				onStart: '<',
				onEnd: '<',
			},
			controllerAs: '$ctrl',
			controller: AlfaCounter,
		};

		function AlfaCounter($element, $timeout) {
			'ngInject';

			const $ctrl = this;

			let initialized = false;
			let running = false;
			let raq = null;
			let currentValue = null;
			let increment = null;

			$ctrl.$onInit = () => {
				initialized = true;

				// set default text to $ctrl.startValue
				$element[0].textContent = format(currentValue);

				if( $ctrl.ready === true ) start();
			};
			$ctrl.$onChanges = (changes) => {

				// make sure to convert binded datas to numbers
				if( changes.decimal ) {
					$ctrl.decimal = parseFloat(changes.decimal.currentValue);
					if( $ctrl.decimal < 0 || $ctrl.decimal > 3 ) throw new Error('Decimal can only be between 0 and 3.');
				};

				if( changes.duration ) {
					$ctrl.duration = parseFloat(changes.duration.currentValue);
					if( $ctrl.duration < 0 ) throw new Error('Duration can not be negative.');
				};

				if( changes.startValue ) $ctrl.startValue = parseFloat(changes.startValue.currentValue);
				if( changes.endValue ) $ctrl.endValue = parseFloat(changes.endValue.currentValue);

				if( changes.ready ) {
					$ctrl.ready = changes.ready.currentValue === true || changes.ready.currentValue === "true";
					if( $ctrl.ready === true && initialized === true ) start();
				};
			};
			$ctrl.$onDestroy = () => {
				initialized = false;
				running = false;

				if( raq ) cancelAnimationFrame( raq );
				raq = null;
			};


			const start = () => {
				if( running === true ) return;
				running = true;

				currentValue = $ctrl.startValue;
				increment = ( $ctrl.endValue - $ctrl.startValue ) / ($ctrl.duration / 1000 * 60);

				if( $ctrl.onStart && typeof $ctrl.onStart === 'function' ) $timeout($ctrl.onStart, 0);

				if( raq ) cancelAnimationFrame( raq );
				raq = requestAnimationFrame( run );
			};
			const stop = () => {
				if( running !== true ) return;

				if( raq ) cancelAnimationFrame( raq );
				raq = null;

				running = false;
				if( $ctrl.onEnd && typeof $ctrl.onEnd === 'function' ) $timeout($ctrl.onEnd, 0);
			};
			const run = () => {
				if( running !== true ) return;

				// increase current value
				currentValue += increment;

				// apply limits depending on incrementation direction
				if( increment > 0 ) {
					// limit value to be smaller than $ctrl.endValue
					if( currentValue > $ctrl.endValue ) currentValue = $ctrl.endValue;
				} else {
					// limit value to be greater than $ctrl.endValue
					if( currentValue < $ctrl.endValue ) currentValue = $ctrl.endValue;
				}

				// format value before updating the DOM
				$element[0].textContent = format(currentValue);

				// if currentValue is equal to $ctrl.endValue, stop animation
				if( currentValue === $ctrl.endValue ) stop();

				// request another animation cycle
				raq = requestAnimationFrame( run );
			};
			const format = (number) => {
				const roundedNumber = round(number, $ctrl.decimal).toString();

				// if formatted value do not accept decimals, return result
				if( $ctrl.decimal === 0 ) return roundedNumber;

				// split formatted value into [integer, decimal];
				const decimals = roundedNumber.split('.');

				// if formatted value does not have decimal, automatically add 0 as decimal
				if( decimals.length < 2 ) decimals.push('0');

				// if formatted value does not have the required amount of decimals, add as many as required
				if( decimals[1].length < $ctrl.decimal ) {
					while( decimals[1].length < $ctrl.decimal ) {
						decimals[1].push('0');
					};
				}

				// return re-formatted value
				if ($ctrl.locale && $ctrl.locale === 'fr') {
					return decimals.join(',');
				}
				return decimals.join('.');
			};
			const round = (number, precision = 3) => {
				if( precision < 0 || precision > 3 ) throw new Error('Precision can only be between 0 and 3.');

				precision = Math.pow(10, precision);
				return Math.round(number * precision) / precision;
			};
		}
	}
})();
