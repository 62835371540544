(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.dialog
	 * @description Dialog module wrapper for custom overlay with ngDialog
	 * @example
	 * <pre>
	 * ng.module('MyModule', ['fca.dialog'])
	 *   .config((fcaDialogThemeProvider) => {
	 *     fcaDialogThemeProvider.register('myTheme', {
	 *       controller: 'MyCustomController',
	 *       template: '/dialogs/themes/myTheme.html',
	 *       name: 'myCustomThemeModal',
	 *       appendClassName: 'myCustomTheme'
	 *     })
	 *   })
	 *   .run(($templateCache) => {
	 *     let tpl = `<div>My Custom theme</div>`;
	 *     $templateCache.put('/dialogs/themes/myTheme.html', tpl);
	 *   });
	 * </pre>
	 */
	angular
		.module('fca.commonDialog', ['ngDialog'])
		.config((ngDialogProvider) => {
			'ngInject';
			/* Change ngDialog provider default configurations */
			ngDialogProvider.setOpenOnePerName(false);
		});
})();
