/* eslint-disable indent */
(function(ng) {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.preferredDealer
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.preferredDealer', []);
})(angular);
