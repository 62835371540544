angular
    .module('fca.brandApp')
    .factory('fcaVideoFactory', fcaVideoFactory);

function fcaVideoFactory($q, $window) {
    const deferred = $q.defer();
    const apiReady = deferred.promise;
    const getAPIScript = document.querySelector(`script[src="https://www.youtube.com/iframe_api"]`);

    if(!getAPIScript) {
        const youtubeAPI ="https://www.youtube.com/iframe_api";
        const tag = document.createElement('script');
        const scriptTag = document.getElementsByTagName('script')[0];
        
        tag.src = youtubeAPI;
        scriptTag.parentNode.insertBefore(tag, scriptTag);
    }

    $window.onYouTubeIframeAPIReady = () => {
        deferred.resolve();
    }

    return {
        onAPIReady: apiReady
    }   
}
