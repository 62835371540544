(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaBuyOnlineAnalytics', fcaBuyOnlineAnalytics);

	function fcaBuyOnlineAnalytics() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				vin: "@"
			},
			controllerAs: '$fcaBuyOnlineAnalyticsController',
			controller: fcaBuyOnlineAnalyticsController,
		};

		function fcaBuyOnlineAnalyticsController(fcaUserIdPersistentService, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			$ctrl.$onInit = () => {
				let uuid = fcaUserIdPersistentService.getCookie("userId");
				let date = new Date().getTime().toString();
				let transactionId = $ctrl.createTransactionId(uuid, date, $ctrl.vin);
				let objPurchaseDetails = {
					currency: 'CAD',
					transaction_id: transactionId
				};

				$ctrl.firePurchaseEvent("purchase", objPurchaseDetails);
			};

			$ctrl.createTransactionId = (uuid, date, vin) => {
				return date + "_" + uuid + "_" + vin;
			};

			$ctrl.firePurchaseEvent = (eventType, eventObj) => {
				window.dataLayer = window.dataLayer || {};

				window.dataLayer.push({
					event: eventType,
					ecommerce: eventObj
				});
			}
		}

	}
})();

