/* eslint-disable indent */
(function(ng) {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.closestDealer
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.closestDealer', [
			'fca.gmap',
			'fca.closestDealerList',
			'fca.closestDealerItem',
			'fca.pageLayout',
			'fca.gtmAnalytics'
		]);
})(angular);
