(function() {
	'use strict';

	angular
		.module('alfaromeo.iris')
		.constant('AR_IRIS_PRESETS', {
			EXTERIOR: {
				background: 'white',
				w: 7700,
				h: 3500,
				x: 1200,
				y: 3500,
			},
			INTERIOR: {
			},
			PANORAMIC: {
				width: 3840,
				height: 1920,
				pov: 'centerpano'
			},
		});
})();
