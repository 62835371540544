(function() {
  angular
    .module('fca.mqConstant')
    .constant('FCA_MQ_LAYOUT', {
      /* Mediaquery values used by matchmedia */
      // note mediaqueries in the styles should correspond to these values
      TINY_PLUS: 'only screen and (min-width: 0)',
      XSMALL_PLUS: 'only screen and (min-width: 320px)',
      SMALL_PLUS: 'only screen and (min-width: 667px)',
      MEDIUM_PLUS: 'only screen and (min-width: 768px)',
      LARGE_PLUS: 'only screen and (min-width: 1024px)',
      XLARGE_PLUS: 'only screen and (min-width: 1248px)',

      MOBILE: 'only screen and (min-width: 0) and (max-width: 666px)',
      MOBILE_LANDSCAPE: 'only screen and (min-width: 667px) and (max-width: 767px)',
      TABLET: 'only screen and (min-width: 768px) and (max-width: 1024px)',
      DESKTOP: 'only screen and (min-width: 1025px)',

      DESKTOP_SMALL: 'only screen and (min-width: 1025px) and (max-width: 1247px)',
      DESKTOP_LARGE: 'only screen and (min-width: 1248px)',
      DESKTOP_FOOTER: 'only screen and (min-width: 851px)',

      RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
        'only screen and (min--moz-device-pixel-ratio: 2),' +
        'only screen and (-o-min-device-pixel-ratio: 2/1),' +
        'only screen and (min-device-pixel-ratio: 2),' +
        'only screen and (min-resolution: 192dpi),' +
        'only screen and (min-resolution: 2dppx)',

      NOT_MOBILE: 'only screen and (min-width: 768px)',
      NOT_DESKTOP: 'only screen and (max-width: 1024px)'
    })
    .constant('FCA_MQ_IMAGES', {
      /* Mediaquery values used for responsive images */
      MOBILE: '(min-width: 1px)',
      MOBILE_RETINA: '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),' +
        '(min-width: 1px) and (min--moz-device-pixel-ratio: 2),' +
        '(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),' +
        '(min-width: 1px) and (min-device-pixel-ratio: 2),' +
        '(min-width: 1px) and (min-resolution: 192dpi),' +
        '(min-width: 1px) and (min-resolution: 2dppx)',
      TABLET: '(min-width: 768px)',
      TABLET_RETINA: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),' +
        '(min-width: 768px) and (min--moz-device-pixel-ratio: 2),' +
        '(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),' +
        '(min-width: 768px) and (min-device-pixel-ratio: 2),' +
        '(min-width: 768px) and (min-resolution: 192dpi),' +
        '(min-width: 768px) and (min-resolution: 2dppx)',
      DESKTOP: '(min-width: 1025px)',
      DESKTOP_RETINA: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),' +
        '(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),' +
        '(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),' +
        '(min-width: 1025px) and (min-device-pixel-ratio: 2),' +
        '(min-width: 1025px) and (min-resolution: 192dpi),' +
        '(min-width: 1025px) and (min-resolution: 2dppx)',

      NOT_RETINA: 'not screen and (-webkit-min-device-pixel-ratio: 2),' +
        'not screen and (min--moz-device-pixel-ratio: 2),' +
        'not screen and (-o-min-device-pixel-ratio: 2/1),' +
        'not screen and (min-device-pixel-ratio: 2),' +
        'not screen and (min-resolution: 192dpi),' +
        'not screen and (min-resolution: 2dppx)',
      RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
        'only screen and (min--moz-device-pixel-ratio: 2),' +
        'only screen and (-o-min-device-pixel-ratio: 2/1),' +
        'only screen and (min-device-pixel-ratio: 2),' +
        'only screen and (min-resolution: 192dpi),' +
        'only screen and (min-resolution: 2dppx)'
    })
      // Use for the <picture> html5 tag
    .constant('FCA_MQ_PICTURE', {
        /* Mediaquery values used for responsive images using <picture> + <source> */
        MOBILE: '(max-width: 767px)',
        MOBILE_RETINA: '(max-width: 767px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (min--moz-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(max-width: 767px) and (min-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (min-resolution: 192dpi),' +
            '(max-width: 767px) and (min-resolution: 2dppx)',
        TABLET: '(min-width: 768px) and (max-width: 1024px)',
        TABLET_RETINA: '(min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (min--moz-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-resolution: 192dpi),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-resolution: 2dppx)',
        DESKTOP: '(min-width: 1025px)',
        DESKTOP_RETINA: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(min-width: 1025px) and (min-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (min-resolution: 192dpi),' +
            '(min-width: 1025px) and (min-resolution: 2dppx)',

        NOT_RETINA: 'not screen and (-webkit-min-device-pixel-ratio: 2),' +
            'not screen and (min--moz-device-pixel-ratio: 2),' +
            'not screen and (-o-min-device-pixel-ratio: 2/1),' +
            'not screen and (min-device-pixel-ratio: 2),' +
            'not screen and (min-resolution: 192dpi),' +
            'not screen and (min-resolution: 2dppx)',
        RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
            'only screen and (min--moz-device-pixel-ratio: 2),' +
            'only screen and (-o-min-device-pixel-ratio: 2/1),' +
            'only screen and (min-device-pixel-ratio: 2),' +
            'only screen and (min-resolution: 192dpi),' +
            'only screen and (min-resolution: 2dppx)'
    })
    .constant('MEDIAQUERIES', {
      /* Deprecated: Use MQ_LAYOUT instead */
      /* Mediaqueries used on DAA, still needed in brands site for header and footer */
      MOBILE_SMALL: 'only screen and (max-width: 374px)',
      MOBILE: 'only screen and (min-width: 375px) and (max-width: 767px)',
      TABLET: 'only screen and (min-width: 768px) and (max-width: 991px)',
      DESKTOP: 'only screen and (min-width: 992px)',
      DESKTOP_LARGE: 'only screen and (min-width: 1280px)',
      DESKTOP_FOOTER: 'only screen and (min-width: 851px)',

      RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
        'only screen and (min--moz-device-pixel-ratio: 2),' +
        'only screen and (-o-min-device-pixel-ratio: 2/1),' +
        'only screen and (min-device-pixel-ratio: 2),' +
        'only screen and (min-resolution: 192dpi),' +
        'only screen and (min-resolution: 2dppx)',

      NOT_MOBILE: 'only screen and (min-width: 768px)',
      NOT_DESKTOP: 'only screen and (max-width: 991px)',
      NOT_DESKTOP_LARGE: 'only screen and (max-width: 1279px)',
      NOT_MOBILE_OR_DESKTOP_LARGE: 'only screen and (min-width: 768px) and (max-width: 1279px)', // eslint-disable-line max-len

      MOBILE_RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px),' +  // eslint-disable-line max-len
        'only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 768px),' +
        'only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 768px),' +
        'only screen and (min-device-pixel-ratio: 2) and (max-width: 768px),' +
        'only screen and (min-resolution: 192dpi) and (max-width: 768px),' +
        'only screen and (min-resolution: 2dppx) and (max-width: 768px)'
    });
})();
