(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaGalleryPageFilter', fcaAlfaGalleryPageFilter );
	function fcaAlfaGalleryPageFilter() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$alfaGalleryPageFilter',
			controller: FcaAlfaGalleryPageFilter
		}

		function FcaAlfaGalleryPageFilter($scope) {
			'ngInject';

			const $ctrl = this;

			$ctrl.currentType = 0;

			$ctrl.filterByType = type => {
				$ctrl.currentType = $ctrl.parseCode(type);
				$scope.$broadcast('galleryPageFilter.filterUpdated', {type : $ctrl.currentType});
				$scope.$broadcast('galleryPage.collapse');
			};

			$ctrl.parseCode = code => {
				return parseInt(code, 10);
			};

		}
	}
})();
