(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaVlpOffer', fcaVlpOffer);

    function fcaVlpOffer() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                token: '@',
                userId: '@',
                analyticscategory: '@',
                analyticsid: '@'
            },
            controllerAs: '$fcaVlpOfferCtrl',
            controller: FcaVlpOffer
        };
    }

    function FcaVlpOffer($element, $http, $compile, $scope, userLocation, gtmAnalytics, scenarioOrchestrator) {
        'ngInject';
        const $ctrl = this;
        $ctrl.data = {};
        $ctrl.initialState = "block";
        let dialogRef;
        this.$onInit = () => {

            scenarioOrchestrator.registerScenario('fcaPersonalizationScenario_1_1');

            $(document).ready(function() {

                let brandCode = document.getElementById("vlp-offer-brandCode").innerHTML;
                let nameplate = document.getElementById("vlp-offer-nameplate").innerHTML;
                let language = document.getElementById("vlp-offer-language").innerHTML;
                let basePath = document.getElementById("vlp-offer-basePath").innerHTML;
                let userRegion = userLocation.getLocationRegion();

                if (scenarioOrchestrator.canShowModal('fcaPersonalizationScenario_1_1')) {
                    $.ajax({
                        url: `${basePath}/${language}/personalization/offer?nameplateCode=${nameplate}&region=${userRegion}&brandCode=${brandCode}`,
                        type: "GET",
                        dataType: "html",
                        success: function (data, textStatus, jqXHR) {
                            // wrap the html data, sizzle fails compiling if the data doesn't start with an html tag
                            let htmlData = '<div>' + data + '</div>';
                            let angularObject = angular.element($compile(htmlData)($scope));
                            $('#personalization').append(angularObject);
                            showModal();
                            emptyLocalStorageAndSetPostQuietThreshold();
                        },
                        error: function (xhr, status) {
                            emptyLocalStorageAndSetPostQuietThreshold();
                        },
                        complete: function (xhr, status) {

                        }
                    });
                }

                function showModal() {
                    let miniNav = document.getElementById("zones_body_blocks_mini-nav");
                    let closeLink = $element[0].querySelector('.vlp-offers-container-close-cta');
                    let closeIcon = $element[0].querySelector(".vlp-offers-container-close-cta>span");
                    let modal = document.getElementById("personalization");

                    closeLink.addEventListener("click",closeModal);
                    closeIcon.addEventListener("click",closeModal);
                    if (modal) {
                        modal.classList.add("vlp-offer-modal-shown");
                        modal.classList.remove("vlp-offer-modal-hidden");
                    }
                    if (miniNav) {
                        miniNav.style.display="none";
                    }

                    window.addEventListener('resize', manageMiniNav);
                    document.querySelector('html').classList.add("ngdialog-open");
                    document.addEventListener("keydown",event=>{
                        if(event.key==="Escape") {
                            closeModal();
                        }
                    });
                    focusTrapActive();
                }

                function closeModal() {
                    let miniNav = document.getElementById("zones_body_blocks_mini-nav");
                    let modal = document.getElementById("personalization");

                    if (miniNav) {
                        miniNav.style.display="block";
                        miniNav.removeEventListener("resize", manageMiniNav);
                    }
                    if (modal) {
                        modal.classList.remove("vlp-offer-modal-shown");
                        modal.classList.add("vlp-offer-modal-hidden");
                    }
                    document.querySelector('html').classList.remove("ngdialog-open");
                }

                function manageMiniNav() {
                    let miniNav = document.getElementById("zones_body_blocks_mini-nav");
                    miniNav.style.display=$ctrl.initialState;
                    miniNav.style.display = "none";
                }

                function emptyLocalStorageAndSetPostQuietThreshold() {
                    // Clear local storage attribute
                    let localStorageName = getLocalStorageName('fcaPersonalizationScenario_1_1');
                    let fcaPersonalizationObject = JSON.parse(window.localStorage.getItem(localStorageName));
                    fcaPersonalizationObject.triggerScenario_1_1 = false;
                    fcaPersonalizationObject.qualifyingEventCounterScenario_1_1 = new Set();
                    fcaPersonalizationObject.preQuietThresholdScenario_1_1 = undefined;
                    console.debug('Empty local storage:', localStorageName);
                    // Add post quiet delay
                    setPostQuietThreshold(fcaPersonalizationObject);
                    window.localStorage.setItem(localStorageName, JSON.stringify(fcaPersonalizationObject));
                }

                function setPostQuietThreshold(fcaPersonalizationObject) {
                    let postQuietDelayInMinutes = window.FCA_SITES_CONFIG.personalizationPostQuietDelay;
                    let nowDate = new Date();
                    let postQuietThreshold = addMinutes(nowDate, postQuietDelayInMinutes);
                    fcaPersonalizationObject.postQuietThresholdScenario_1_1 = postQuietThreshold;
                    console.debug('Added post threshold:', postQuietThreshold);
                }

                function getLocalStorageName(scenarioName) {
                    let localStorageModelYearId = (window.FCA_SITES_CONFIG.modelYearIds && window.FCA_SITES_CONFIG.modelYearIds.length > 0) ? window.FCA_SITES_CONFIG.modelYearIds[0]: "";
                    let localStorageName = scenarioName + '_' + localStorageModelYearId;
                    return localStorageName;
                }

                function addMinutes(dt, minutes) {
                    return new Date(dt.getTime() + minutes*60000);
                }

                function focusTrapActive() {
                    if (!dialogRef) {
                        let navDialogEl = document.querySelector("#personalization");
                        let dialogOverlay = document.querySelector('.vlp-offers-container-text>.vlp-offers-container-rebate>.offers-vehicle-txt>div>section>div>div>.intro');
                        setTimeout(()=>{
                            dialogRef = new Dialog(navDialogEl, dialogOverlay);
                            dialogRef.addEventListeners(
                                '.vlp-offers-container-text>.vlp-offers-container-rebate>.offers-vehicle-txt>div>section>div>div>.intro>*>span>button',
                                '.vlp-offers-container-close-cta'
                            );
                            dialogRef.open();
                        }, 1000);
                    }
                };
            });

            this.updateAnalyticsValue = (newValue) => {
                let wrapper = angular.element($element);
                wrapper.attr('data-analyticsvalue', newValue)
                gtmAnalytics.trackEvent('event', {
                    category: `${$ctrl.analyticsid}`,
                    label: newValue
                });
            }
        };
    }
})();
