(function(ng) {
    'use strict';

    angular
        .module('fca.geolocator')
        .component('fcaGeolocator', {
            controller: 'fcaGeolocatorComponentController',
            controllerAs: '$ctrl',
            templateUrl: '/geolocator/geolocator.html',
            bindings: {
                onLocationChange: '&'
            }
        }).controller('fcaGeolocatorComponentController', GeolocatorComponentController);

    /**
     * @ngdoc controller
     * @name fca.fcaGeolocator.controller:GeolocatorComponentController
     * @requires fca.fcaGeolocator.service:fcaGeolocator
     * @description [TODO]
     * @example
     * <pre>
     * [TODO]
     * </pre>
     */
    function GeolocatorComponentController(fcaGeolocator, userLocation, $rootScope, gtmAnalytics) {
        'ngInject';
        const LOCATION_BASE_FIELD = fcaGeolocator.getLocationBaseField();
        const LOCATION_BASE = fcaGeolocator.getLocationBase();
        const LOCATION_BASED_ON_BROWSER = fcaGeolocator.getLocationBasedOnBrowser();

        const LOCATION_UPDATE_METHOD = "locationUpdateMethod";
        const LOCATION_CITY_LABEL = "locationCityLabel";

        const urlPath = window.location.pathname;


        /**
         * @ngdoc property
         * @name location
         * @propertyOf fca.fcaGeolocator.controller:GeolocatorComponentController
         * @type {Object}
         * @description [TODO]
         */
        this.location = null;

        /**
         * @ngdoc property
         * @name apiUrl
         * @propertyOf fca.fcaGeolocator.controller:GeolocatorComponentController
         * @type {String}
         * @description Api url for autocomplete component
         */
        this.apiUrl = fcaGeolocator.getApiUrl('autoCompleteServiceUrl');

        this.isLoading = false;
		this.geolocButtonClicked = false;
        /**
         * @ngdoc method
         * @name detectCurrentPosition
         * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
         * @param {Object} $evt [TODO]
         * @return {Object} [TODO]
         * @description [TODO]
         * @example
         * <pre>
         * [TODO]
         * </pre>
         */
        this.detectCurrentPosition = ($evt) => {
            $evt.preventDefault();
			this.geolocButtonClicked = true;
            this.isLoading = true;

            return fcaGeolocator.detectCurrentPosition().then((position) => {
                if (ng.isObject(position)) {
                    fcaGeolocator.reverseGeocode(position).then((location) => {
                        this.isLoading = false;
                        this.locationChange(ng.extend(location, {
                            [LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
                            [LOCATION_UPDATE_METHOD]: "detection",
							forceByIp: false
                        }));
                    });
                } else {
                    // this happens mostly when the user refuses geo-location, fall back to ip
                    fcaGeolocator.getCurrentLocation().then((location) => {
                        this.isLoading = false;
                        this.locationChange(ng.extend(location, {
                            [LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
                            [LOCATION_UPDATE_METHOD]: "detection",
							forceByIp: false,
							usingDefaults: true
                        }));
                    })
                    .catch((reason) => {
                        this.isLoading = false;
                        this.onLocationChange(null);
                    });
                }
            });
        };


        /**
         * @ngdoc method
         * @name setLocation
         * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
         * @param {Object} data [TODO]
         * @description [TODO]
         * @example
         * <pre>
         * [TODO]
         * </pre>
         */
        this.setLocation = (data) => {
            if (data !== null) {
                let locationBase = LOCATION_BASE[1];
                if (fcaGeolocator.isFullLocationObject(data)) {
                    this.locationChange(ng.extend(data, {
                        [LOCATION_BASE_FIELD]: locationBase,
                        [LOCATION_UPDATE_METHOD]: "city",
                        [LOCATION_CITY_LABEL]: data.name
                    }));
                } else {
                    fcaGeolocator.geocode(data).then((location) => {
                        this.location = ng.extend(location, {
                        [LOCATION_BASE_FIELD]: locationBase,
                        [LOCATION_UPDATE_METHOD]: "city",
                        [LOCATION_CITY_LABEL]: data.name
                    });
                    // Callback
                    this.locationChange(this.location);
                }, () => {
                        this.onLocationChange(null);
                    });
                }
            }
        };

        this.setLocationWithPostalCode = (data) => {
            let locationBase = LOCATION_BASE[1];
            fcaGeolocator.geocodeWithPostalCode(data).then((location) => {
                this.location = ng.extend(location, {
                [LOCATION_BASE_FIELD]: locationBase,
                [LOCATION_UPDATE_METHOD]: "postalCode"
            });
            // Callback
            this.locationChange(this.location);
        }, () => {
                this.onLocationChange(null);
            });
        };

        /**
         * @ngdoc method
         * @name locationChange
         * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
         * @param {Object} location [TODO]
         * @description [TODO]
         * @example
         * <pre>
         * [TODO]
         * </pre>
         */
        // This method is called for precise geolocation selection with city or postal code.
        this.locationChange = (location) => {
            // Create provinceObject for offers-region cookie set by userLocation.setLocation(location).
            var provinceObject = {'region': `${location.province},${location.region}`};
            // Set region/province in offers-region cookie.
            userLocation.setLocation(provinceObject);

            // Set province in brd-province cookie.
            fcaGeolocator.setProvinceCookie(location.province)
				.then(() => {
                    const pathname = window.location.pathname;
                    const normalizedPathname = pathname.endsWith('/')? pathname.slice(0, -1) : pathname;
                    const locationsNeededReload = [
                        '/en/compare',
                        '/en/build-and-price',
                        '/fr/compare',
                        '/fr/build-and-price'
                    ];
                    if (locationsNeededReload.includes(normalizedPathname)) {
                        window.location.reload();
                    }
				})
				.catch(error => {
					console.error('Error:', error);
				});

			// Set location attributs in brd-location cookie.

            // condition to verify if we are in iframe mode
            let hashParameters = getHashParameters();
            let hasHashParameter = hashParameters.dealerId && hashParameters.dealerId !== 'null';
            let dealerId = sessionStorage.getItem('dealerId');


			if(!dealerId && !hasHashParameter) {
				$rootScope.$broadcast(fcaGeolocator.getLocationChangedEvent(), [location]);
			}

			this.onLocationChange({
				location: location
			});

        };

        this.checkIfAlfaRomeo = () => {
            return $('body').hasClass('brand-alfaromeo');
        };

        this.customOrderLocationChange = (data) => {
            const path = window.location.pathname;
            const params = new URLSearchParams(window.location.search);
            const brandCode = params.get('brandCode');
            const nameplateCode = params.get('namePlate');
            const year = params.get('modelYear');
            const modelYearId = params.get('modelYearId');
            let queryParams = '';

            $rootScope.$broadcast('geolocator: custom-order-set-location', {location: data});

            queryParams = '?brandCode='+brandCode+'&namePlate='+nameplateCode+'&modelYear='+year+'&modelYearId='+modelYearId;
            window.location.replace(path + queryParams);
        };


		this.geolocAvailable = true;
		this.$onInit = () => {

			navigator.permissions
				.query({ name: "geolocation" })
				.then((permissionStatus) => {
					if (!permissionStatus) {
						return;
					}
					const updateGeolocationStatus = (analyticsLabelGetter) => {
						this.geolocAvailable = permissionStatus.state === "granted";
						const analyticsLabel = analyticsLabelGetter();
						if (analyticsLabel) {
							gtmAnalytics.trackEvent("event", {
								category: "App-Suite-" + window.FCA_SITES_CONFIG.pageCode,
								label: analyticsLabel,
							});
						}
					};
					updateGeolocationStatus(() => {
						return this.geolocAvailable ? "" : "footer-tools-location-update-browser-location-block";
					});
					permissionStatus.onchange = () => {
						updateGeolocationStatus(() => {
							return this.geolocAvailable ? "footer-tools-location-update-browser-location-update-granted" : "footer-tools-location-update-browser-location-update-denied";
						});
					};
				});
		};
    }

})(angular);
