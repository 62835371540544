(function (ng) {
    angular
    .module('fca.calculator')
    .service('financeProgramService', financeProgramService);

    function financeProgramService() {

        let $service = this;

        /**
         * Add missing terms with default rate if we're missing any.
         */
        $service.sanitizeFinanceProgramTerms = (financeTerms, defaultFinanceRate) => {
            if (financeTerms) {
                let financeKeys = [36, 48, 60, 72, 84, 96];
                financeKeys.forEach(financeKey => {
                    if (!financeTerms.find(term => Number(term.duration) === financeKey)) {
                        financeTerms.push({
                            duration: financeKey,
                            rate: defaultFinanceRate
                        });
                    }
                });

                financeTerms.sort((a, b) => {
                    return a.duration - b.duration;
                });
            }
        };
    }
})(angular);