(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaHotspot', fcaHotspot);

	function fcaHotspot() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'hotspotCtrl',
			controller: fcaHotspotController
		};

		function fcaHotspotController() {
			'ngInject';

			this.isTooltipClose = true;

			this.onTooltipToggle = () => {
				this.isTooltipClose = !this.isTooltipClose;
			}
		}
	}
})();
