(function() {
	angular
		.module('fca.overlay')
		.component('fcaOverlay', {
			controller: FcaOverlayController,
			controllerAs: 'fcaOverlayCtrl',
			transclude: true,
			bindings: {
				contentType: '@',
				offerId: '@?'
			},
			templateUrl: '/shared/fca-overlay/fca-overlay.html'
		});

	function FcaOverlayController($scope, $element, $rootScope, $http, userLocation) {
		'ngInject';

		this.isActive = false;

		this.currentLang = 'en';

		this.currentRegion = 'ontario';

		this.isAjax = false;

		this.$onInit = () => {
			if (userLocation && userLocation.getLocationRegion()) {
				this.location = userLocation.getLocationRegion();
			}

			this.checkLanguage();
			this.checkActions();
			this.activeControls();
			$element.appendTo('body');
		};

		this.activeControls = () => {
			angular.element(document).bind('keyup', (event) => {
				if (event.keyCode === 27) {
					this.closeOverlay();
					$scope.$apply();
				}
			});
		};

		this.checkActions = () => {
			let eventName;

			if (this.contentType == 'global-legal') {
				this.initAjax();
				eventName = 'fcaOverlay.openLegal';

				// Watching location change in fca-userLocation.service
				$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => {
					this.currentRegion = region;
					this.requestData();
				});

			} else if (this.contentType == 'global-offers') {
				eventName = 'fcaOverlay.openGlobalOffers';
			} else if (this.offerId != undefined && this.offerId != '') {
				eventName = `fcaOverlay.${this.offerId}`;
			}

			$rootScope.$on(eventName, this.openOverlay);
		};

		this.initAjax = () => {
			this.isAjax = true;
			this.requestData();
		};

		this.checkLanguage = () => {
			if (window.FCA_SITES_CONFIG.preferredLanguage != 'en_CA') {
				this.currentLang = 'fr';
			}
		};

		this.requestData = () => {
			$http({
				method: 'GET',
				url: window.FCA_SITES_CONFIG.urls.legal[this.currentLang]
				+ "/" + this.currentLang
				+ "/" + this.currentRegion
				+ "/" + window.FCA_SITES_CONFIG.name
			}).then((response) => {
				this.overlayData = response.data;
			}, (response) => {
				let errorMessage = window.FCA_SITES_CONFIG.urls.legal[this.currentLang + 'Error'];
				this.overlayData = `<p>${errorMessage}</p>`;
			});
		};

		this.openOverlay = () => {
			this.isActive = true;
			$('html').addClass('overlay-active');

			$element.bind('click', (event) => {
				if ($(event.target).hasClass('overlay')) {
					this.closeOverlay();
					$scope.$apply();
				}
			});
		};

		this.closeOverlay = () => {
			this.isActive = false;
			$('html').removeClass('overlay-active');
			$element.unbind('click');
		};
	}
})();
