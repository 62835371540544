(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.geolocator
	 * @description [TODO]
	 * @example
	 * <pre></pre>
	 */
	angular
		.module('fca.geolocator', [
			'fca.autocomplete',
			'ngCookies',
			'base64',
			'fca.geolocator.cookie'
		]);
})();
