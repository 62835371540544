(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaAlfaVideoBlock', fcaAlfaVideoBlock);

	function fcaAlfaVideoBlock() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: 'alfaVideoBlock',
			controller: fcaAlfaVideoBlockController
		};

		function fcaAlfaVideoBlockController($scope, $element, $document, $window, gtmAnalytics) {
			'ngInject';

			/* eslint-disable no-invalid-this */
			const $ctrl = this;

			// Scroll variables
			let $el = $element[0];
			$ctrl.videoIsVisible = false;
			$ctrl.showVideo = false;
			$ctrl.videoHasEnded = false;

			this.bindScrolling = () => {
				$window.addEventListener('scroll', (event) => {
					this.checkIfVisible();
				});
			};

			this.bindResize = () => {
				angular.element($window).on('resize', () => {
					this.checkIfShowVideo();
				});
			};

			this.checkIfShowVideo = () => {
				let videoBreakPoint = 1024;
				let windowWidth = $window.innerWidth;

				if(windowWidth >= videoBreakPoint) {
					if(!$ctrl.showVideo) {
						$ctrl.showVideo = true;
						this.checkIfVisible();
					}
				} else {
					$ctrl.showVideo = false;
					$ctrl.videoIsVisible = false;
					$ctrl.videoHasEnded = false;
				}
			};

			this.checkIfVisible = () => {
				let elScrollTop = $el.getBoundingClientRect().top;
				let elScrollBottom = $el.getBoundingClientRect().bottom;
				let windowHeight = $window.innerHeight;

				if(elScrollTop <= windowHeight && elScrollBottom >= 0 && $ctrl.showVideo && !$ctrl.videoIsVisible) {
					let $blockVideo = $element.find('video')[0];
					let videoSource = $element.attr('data-video-url');

					$blockVideo.src = videoSource;
					$blockVideo.load();
					$blockVideo.addEventListener('ended', isEnded, false);

					this.playVideo();
					$ctrl.videoIsVisible = true;
				}
			};

			this.playVideo = () => {
				let $blockVideo = $element.find('video')[0];
				let playPromise = $blockVideo.play();
				$ctrl.videoHasEnded = false;

				if (playPromise !== undefined) {
					playPromise.then(function() {
					}).catch(function(error) {
						$ctrl.videoHasEnded = true;
					});
				}
			}

			this.replayVideo = () => {
				this.playVideo();
				gtmAnalytics.trackEvent('event', {
					category: 'video hero block',
					label: 'replay video'
				});
			}

			function isEnded() {
				$ctrl.videoHasEnded = true;
				$scope.$apply();
			}

			this.$onInit = () => {
				this.checkIfShowVideo();
				this.bindResize();
				this.bindScrolling();
			};
		}
	}
})();
