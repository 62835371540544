(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSlickCarouselFabric', FcaSlickCarouselFabric);

	function FcaSlickCarouselFabric() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				slickTheme: '@'
			},
			controllerAs: 'carousel',
			controller: FcaSlickCarouselFabricController,
			link: FcaSlickCarouselFabricLink
		};

		function FcaSlickCarouselFabricController(gtmAnalytics) {
			'ngInject';

			this.beforeChange = (event, slick, currentSlide, nextSlide) => {
				let dataAttr = slick.$slides.eq(nextSlide).data();

				if (dataAttr) {
					let category = dataAttr.analyticsCategory;
					let label = dataAttr.analyticsLabel;
					let trigger = '';
					if(dataAttr.analyticsTrigger) {
						trigger = dataAttr.analyticsTrigger;
					}

					gtmAnalytics.trackEvent('event', {
						category: category,
						label: label,
						trigger: trigger
					});
				}
			};
		}

		/*
		 * Adds a css class to allow for custom slick carousel styles
		 */
		function FcaSlickCarouselFabricLink(scope, element, attrs, $ctrl) {
			element.addClass($ctrl.slickTheme);
		}
	}
})();
