(function () {
	angular
	.module('fca.commonDirectives')
	.directive('fcaOnlyRamMotortrend', FcaOnlyRamMotortrend);
	
	function FcaOnlyRamMotortrend() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				baseUrl: '@',
				paintCode: '@',
				paintImagePath: '@',
				paintVideoPath: '@',
			},
			controllerAs: '$fcaOnlyRamMotortrendCtrl',
			controller: FcaOnlyRamMotortrendCtrl
		};
		
		function FcaOnlyRamMotortrendCtrl($element, $scope, gtmAnalytics) {

			const $ctrl = this;
			const $slickSlider = $('.OR-carousel-content slick');


			$slickSlider.on('init', function(event, slick) {
				var $items = slick.$dots.find('li');
				$items.on('click',function () {

					const currentSlide = $slickSlider.slick('slickCurrentSlide');
					const titleSelected = $(slick.$slides[currentSlide]).attr('data-title');
					$ctrl.sendGtmTrackEvent("ram-fmb-motortread-comments-", titleSelected);
				});
			});

			$slickSlider.on('afterChange', function(event, slick, currentSlide) {

				const titleSelected = $(slick.$slides[currentSlide]).data('data-title');
				const slide = $(slick.$slides[currentSlide]);

				const nextSlideFeatureCode = slide.next().attr('data-title');
				const prevSlideFeatureCode = slide.prev().attr('data-title');

				$ctrl.addAnalytics(titleSelected, nextSlideFeatureCode, prevSlideFeatureCode);
			});

			$slickSlider.on('swipe', function(event, slick, direction) {
				//Add analytics on the slick swipe
				const currentSlide = $slickSlider.slick('slickCurrentSlide');

				const titleSelected = $(slick.$slides[currentSlide]).attr('data-title');

				const id_analytics = "ram-fmb-motortread-comments-";

				if(direction === "left") {
					$ctrl.sendGtmTrackEvent(id_analytics, titleSelected);
				} else {
					$ctrl.sendGtmTrackEvent(id_analytics, titleSelected);
				}

			});

			$ctrl.addAnalytics = (titleSelected, nextSlideFeatureCode, prevSlideFeatureCode) => {
				//Add analytics on the slick arrow
				$element.find('.slick-prev').attr('data-analyticsid', 'ram-fmb-motortread-comments-');
				$element.find('.slick-next').attr('data-analyticsid', 'ram-fmb-motortread-comments-');

				$element.find('.slick-prev').attr('data-analyticsvalue', prevSlideFeatureCode);
				$element.find('.slick-next').attr('data-analyticsvalue', nextSlideFeatureCode);
			};


			$ctrl.sendGtmTrackEvent = (id, value) => {

				const page_code_active = $('.OR-tab-link, .is-active').data('page-code');

				gtmAnalytics.trackEvent('event', {
					category: 'App-Suite-' + page_code_active,
					label: `${id}-${value}`
				});
			};

		}
	}
})();