(function() {
	'use strict';

	angular
		.module('fca.hoursStatus')
		.component('hoursStatus', {
			controller: HoursStatusController,
			controllerAs: '$hoursStatusCtrl',
			bindings: {
				closeAt: '@?',
				openAt: '@?'
			},
			templateUrl: '/hours-status/hours-status.html'
		});

	/**
	 * @ngdoc controller
	 * @name fca.hoursStatus.controller:hoursStatusController
	 * @description hoursStatus component controller
	 * @requires $translate
	 * @requires fca.hoursStatus.service:hoursStatus
	 */
	function HoursStatusController($translate, hoursStatus) {
		'ngInject';

		/**
		 * @ngdoc property
		 * @name label
		 * @propertyOf fca.hoursStatus.controller:HoursStatusController
		 * @type {String}
		 * @description
		 * Translated label display in hours boxe status (closed/opened)
		 */
		this.label = $translate.instant('global.closed.now');

		/**
		 * @ngdoc property
		 * @name isOpenNow
		 * @propertyOf fca.hoursStatus.controller:HoursStatusController
		 * @type {Boolean}
		 * @description
		 * Status opened/closed. True if the current date match the openAt/closeAt values
		 */
		this.isOpenNow = false;

		/**
		 * @ngdoc property
		 * @name isToday
		 * @propertyOf fca.hoursStatus.controller:HoursStatusController
		 * @type {Boolean}
		 * @description
		 * True if the current date match the day of the openAt/closeAt values
		 */
		this.isToday = false;

		/**
		 * @ngdoc property
		 * @name hideStatus
		 * @propertyOf fca.hoursStatus.controller:HoursStatusController
		 * @type {Boolean}
		 * @description
		 * True if the status boxe need to be hidden (not the current day or no openAt/closeAt values)
		 */
		this.hideStatus = false;

		/**
		 * @ngdoc method
		 * @name $onInit
		 * @methodOf fca.hoursStatus.controller:HoursStatusController
		 * @description
		 * Set all property to display informations in status boxe
		 */
		this.$onInit = () => {
			// Set isToday prop.
			this.isToday = hoursStatus.isToday(this.openAt);
			// Set isOpenNow prop.
			this.isOpenNow = hoursStatus.isOpenNow(this.openAt, this.closeAt);
			if (this.isOpenNow) {
				// Change the label if the isOpenNow prop. is true
				this.label = $translate.instant('global.opened.now');
			}

			// Check if the hideStatus must be true
			if ((!this.closeAt && !this.openAt) || !this.isToday) {
				this.hideStatus = true;
			}
		};
	}
})();
