(function () {
    angular
        .module('fca.userAccount.myGarage')
        .directive('fcaUserAccountMyGarageCarousel', Carousel);

    function Carousel() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {},
            controllerAs: '$fcaUserAccountMyGarageCarouselCtrl',
            controller: CarouselCtrl
        };

        function CarouselCtrl($scope, $element, $timeout, cleanCarousel) {
            'ngInject';

            let $this = this;
			let slickSettings = {
				arrows: true,
				dots: true,
				infinite: false,
				slidesToShow: 3,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 667,
						settings: {
							slidesToShow: 1
						}
					},
				]
			};

			$this.slickSlider = $element;

			$this.$onInit = () => {
				$this.isInitialized = false;

				$scope.$on('vehicleListUpdated', function() {
					$timeout(() => {
						$this.initSlickCarousel();
						cleanCarousel.makeAccessible($this.slickSlider);
					});
				});

				$scope.$on('destroySlider', function() {
					$timeout(() => {
						$this.slickSlider = $element.slick('destroy');
					});
				});
			};

			$this.initSlickCarousel = () => {
				if ($this.isInitialized) {
					$this.slickSlider = $element.slick('slickRemove').slick('destroy').slick(slickSettings);
				} else {
					$this.slickSlider = $element.slick(slickSettings);
					$this.isInitialized = true;
				}

				$('.slick-slide').find('a, button, input, select').attr('tabindex', '-1');
				$('.slick-active').find('a, button, input, select').attr('tabindex', '0');
			};

		}
    }
})();
