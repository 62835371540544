(function () {
	angular
		.module('fca.commonServices')
		.service('fcaSniTrimService', FcaSniTrimService);

	function FcaSniTrimService($rootScope, $http, $q, irisResponsive) {
		const $service = this;
		const baseApiUrl = FCA_SITES_CONFIG.apiUrl;
		const language = FCA_SITES_CONFIG.language;
		const baseIrisJellyUrl = FCA_SITES_CONFIG.irisJellyBaseUrl + "/mediaserver/iris?client=FCAUS&market=U";
		let trimGroupArray = [];

		$service.getSniTrimData = (brandCode, nameplateCode, year, modelYearId) => {
			if (!brandCode || !nameplateCode || !year || !modelYearId) {
				return Promise.resolve();
			}
			let deferredObject = $q.defer();

			const apiUrl = baseApiUrl+'/'+
				'api/buildandprice/' + language +
				'/brand/' + brandCode +
				'/nameplate/' + nameplateCode +
				'/year/' + year + '/modelyear/' +
				modelYearId + '/trims';

			$http({
				method : "GET",
				url : apiUrl
			}).then(response => {
				deferredObject.resolve(response);
			},
			response => {
				deferredObject.reject(response);
			});

			return deferredObject.promise;
		};

		$service.findTrim = (trimGroupName, trimGroupsArray, acode) => {
			return trimGroupsArray
				.find(element => element.code === trimGroupName)
				.trims.find(element => element.acode === acode);
		};

		$service.setMfgCodeForMultipleVehicles = (vehiclesList, maxIrisWidth) => {
			$('.sub-nav-previous-link').hide();
			let modelYearIdArray = [];
			for (let vehicle of vehiclesList) {
				if (vehicle.brand !== 'alfaromeo' || modelYearIdArray.includes(vehicle.modelYearId)) {
					return;
				}
				modelYearIdArray.push(vehicle.modelYearId);
				$service.getSniTrimData(vehicle.brand, vehicle.nameplateCode, vehicle.modelYear, vehicle.modelYearId).then(value => {
						trimGroupArray = value.data.trimGroups;
						vehiclesList.forEach((elem) => {
							// const mfgCode = $service.findTrim(elem.trimGroup[language], trimGroupArray, elem.acode).mfgCode;
							// const fallbackPackageCode = $service.findTrim(elem.trimGroup[language], trimGroupArray, elem.acode).packages[0].code;
							// const fallbackPaintCode = $service.findTrim(elem.trimGroup[language], trimGroupArray, elem.acode).primaryColourCode;
							// elem.irisJellyUrl = $service.getIrisJellyData(vehicle.modelYear, mfgCode, elem.trimOpts, fallbackPackageCode, fallbackPaintCode, 'fronthero', maxIrisWidth);
							// elem.interiorIrisJellyUrl = $service.getIrisJellyData(vehicle.modelYear, mfgCode, elem.trimOpts, fallbackPackageCode, fallbackPaintCode, 'I01', maxIrisWidth);
							elem.isPageReady = true;
						});
					},
					value => {
						console.error('api call for trims return 404, return value:', value);
						trimGroupArray = [];
					}).catch(e => {
				});
			}
		};

		$service.getIrisJellyData = (year, mfgCode, packageAndOptions, fallbackPackageCode, fallbackPaintCode, pov, maxIrisWidth) => {
			const irisJellyDimensions = irisResponsive.calcDimensions(
				{ ratio: 1920 / 1080, maxWidth: maxIrisWidth}
			);
			const jellyBrandCode = 'Y';
			const vehicle = `${year}_${mfgCode.substr(0,2)}`;
			let trimOpts = [];
			let packageAndOptionsCode = '';

			if (packageAndOptions) {
				if (typeof packageAndOptions === 'object') {
					trimOpts = packageAndOptions.packageAndOptionsCode;
				} else {
					trimOpts = packageAndOptions.split(',');
				}
				packageAndOptionsCode = trimOpts[0];
			} else {
				packageAndOptionsCode = fallbackPackageCode
			}

			// const sa = mfgCode + "," + packageAndOptionsCode;
			const sa = `${mfgCode },${packageAndOptionsCode.substr(0,1)}D${packageAndOptionsCode.substr(2,1)},${packageAndOptionsCode}`;
			let paint = trimOpts.find(trim => trim.startsWith('P') && trim.length === 3);
			let fabric = trimOpts.find(trim => (trim.startsWith('X') || trim.endsWith('X')) && trim.length === 2);
			paint = paint || fallbackPaintCode;
			fabric = fabric || '';

			return `${baseIrisJellyUrl}&brand=${jellyBrandCode}`+
				`&vehicle=${vehicle}&paint=${paint}&fabric=${fabric}`+
				`&sa=${sa}&bkgnd=white&pov=${pov}`+
				`&width=${irisJellyDimensions.width}`+
				`&height=${irisJellyDimensions.height}`;
		};
	}
})();