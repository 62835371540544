(function () {
	angular
		.module('fca.buyOnline')
		.directive(
			'fcaDeposit',
			() => ({
				restrict: 'A',
				scope: true,
				bindToController: {
					vin: '@',
					dealerCode: '@'
				},
				controllerAs: '$ctrl',
				controller: [
					"$scope",
					"$timeout",
					"fcaBuyOnlineService",
					"$http",
					"externalConfigLoader",
					"gtmAnalytics",
					($scope, $timeout, fcaBuyOnlineService, $http, externalConfigLoader, gtmAnalytics) => new FcaDepositController($scope, $timeout, fcaBuyOnlineService, $http, externalConfigLoader, gtmAnalytics)
				]
			})
		);

	class FcaDepositController {
		constructor($scope, $timeout, fcaBuyOnlineService, $http, externalConfigLoader, gtmAnalytics) {
			this.$scope = $scope;
			this.$timeout = $timeout;
			this.fcaBuyOnlineService = fcaBuyOnlineService;
			this.$http = $http;
			this.gtmAnalytics = gtmAnalytics;
			// initialize configuration
			this.config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');
			this.language = this.config.getConfig('language');
		}

		$onInit() {
			let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);
			// check if we already have a deposit
			if (checkout.deposit != null) {
				this.deposit = checkout.deposit;
			}
		}

		/**
		 * Safe scope apply method.
		 * This will call apply on the next digest cycle.
		 */
		scopeApply() {
			this.$timeout(() => {
				this.$scope.$apply();
			});
		}

		createIntent() {
			let data = {vin: this.vin, dealerCode: this.dealerCode};

			this.$http.post(
				'/api/deposit/create',
				data,
				{
					transformRequest: (data) => $.param(data),
					headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
				}
			).then(response => {
				this.createdIntent(response.data);
			}, response => {
				console.error("error creating intent");
			});
		}

		/**
		 * Calls stripe.confirmCardPayment which creates a pop-up modal to
		 * prompt the user to enter extra authentication details.
		 *
		 */
		pay() {
			this.error = null;
			this.stripe
				.confirmCardPayment(
					this.secret,
					{
						payment_method: {
							card: this.stripeCard
						}
					})
				.then(result => {
					if (result.error) {
						this.paymentError(result.error);
					} else {
						this.paymentSuccess(result.paymentIntent);
					}
				});
		}

		/**
		 *	 Once the payment is done, this is where we receive it
		 *	 paymentIntent: {
		 *		id: "pi_1Ga5N4LAs1fJsDMuP8GFwSqW"
		 *		object: "payment_intent"
		 *		amount: 50
		 *		capture_method: "automatic"
		 *		client_secret: "pi_1Ga5N4LAs1fJsDMuP8GFwSqW_secret_dWBEXQI4rh3ossKlVLLPkVCrW"
		 *		confirmation_method: "automatic"
		 *		created: 1587411214
		 *		currency: "cad"
		 *		payment_method: "pm_1Ga5NTLAs1fJsDMuYwHrqJgq"
		 *		payment_method_types: [ "card" ]
		 *		status: "succeeded"
		 *	}
		 *	 @param result
		 **/
		paymentSuccess(paymentIntent) {
			if (paymentIntent.status === 'succeeded') {
				// update the checkout object
				let checkout = this.fcaBuyOnlineService.getFromStorage(this.vin);
				let deposit = {};
				// amount in payment intent is in cents
				deposit.amount = paymentIntent.amount / 100;
				checkout.deposit = deposit;

				// save the object
				this.fcaBuyOnlineService.setInStorage(this.vin, checkout);

				this.deposit = checkout.deposit;

				// angular won't figure it out by itself
				this.scopeApply();

				// send analytics event to confirm approval
				this.sendGAevent('submitamount-approved');
			}
		}

		/**
		 *	 Once the payment is done, this is where we receive it
		 *	 {
		 *	     type
		 *	     code
		 *	     message
		 *	 }
		 *	 @param error
		 **/
		paymentError(error) {
			this.error = error;
			// angular doesn't know we've updated
			this.scopeApply();
		}

		/**
		 * Once the intent is created, we initialize the stripe form
		 *
		 * @param data
		 */
		createdIntent(data) {
			this.secret = data.secret;
			this.stripe = Stripe(data.key);
			let stripeElements = this.stripe.elements({locale: this.language});
			this.stripeCard = stripeElements.create('card', {
				style: {
					base: {
						'color': '#32325d',
						'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
						'fontSmoothing': 'antialiased',
						'fontSize': '16px',
						'::placeholder': {
							color: '#aab7c4'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			});
			this.stripeCard.mount("#card-element");

			// let angular know
			this.scopeApply();
		}

		sendGAevent(GAlabel) {
			let page_code_active = window.BRANDS_GA.pagetype;

			this.gtmAnalytics.trackEvent('event', {
				category: 'App-Suite-' + page_code_active,
				label: GAlabel
			});
		};
	}
})();
