
(function() {
    angular
        .module('fca.bodyBuilder')
        .directive('bodyBuilderBulletinUpdate', bodyBuilderBulletinUpdate);

    function bodyBuilderBulletinUpdate() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                defaultCategory: '@',
                defaultCategoryLabel: '@'
            },
            controllerAs: '$bodyBuilderBulletinUpdate',
            controller: bodyBuilderBulletinUpdateController
        };

        function bodyBuilderBulletinUpdateController($element) {
            'ngInject';

            let $ctrl = this;
            let defaultDocumentsDisplayed = 10;
            let addedDocuments = 5;
            $ctrl.documentsDisplayed = defaultDocumentsDisplayed;

            $ctrl.toggleCategory = (event) => {
                let newCategory = event.currentTarget.getAttribute('data-category');
                let newCategoryLabel = event.currentTarget.getAttribute('data-category-label');
                $ctrl.currentCategory = newCategory;
                $ctrl.currentCategoryLabel = newCategoryLabel;
                $ctrl.documentsDisplayed = defaultDocumentsDisplayed;
                $ctrl.getMaxDocuments();
            }

            $ctrl.showMore = () => {
                $ctrl.documentsDisplayed = $ctrl.documentsDisplayed + addedDocuments;
            }

            $ctrl.getMaxDocuments = () => {
                let currentList = $ctrl.mainSlider = $element.find('[data-list-category="' + $ctrl.currentCategory + '"]');
                $ctrl.maxDocuments = currentList.children().length;
            }

            $ctrl.$onInit = () => {
                $ctrl.documentsDisplayed = defaultDocumentsDisplayed;
                $ctrl.currentCategory = this.defaultCategory;
                $ctrl.currentCategoryLabel = this.defaultCategoryLabel;
                $ctrl.getMaxDocuments();
            }
        }
    }
})();
