(function() {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.dialog.theme.popover
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dialog.theme.popover', []);
})();
