/* eslint-disable indent */
(function () {
    angular
        .module('fca.calculator')
        .component('fcaSummaryCalculatorController', {
            controller: FcaSummaryCalculatorController,
            controllerAs: '$summaryCalculatorCtrl',
            templateUrl: '/calculator/summary-calculator.html',
            bindings: {
                showLeaseTab: '<',
                showCtaFinancing: '<',
                showCtaContactDealer: '<',
                showCtaReturnToDetails: '<',
                showSalesTax: '<',
                showSeeOptions: '<',
                showPaymentFrequency: '<',
                showPaymentSummary: '<',
                disclaimerBasePrice: '@',
                disclaimerFinancing: '@',
                disclaimerLeasing: '@',
                disclaimerDealerAdminFees: '@',
                location: '<',
                isTradeIn: '<',
                calculatorDefaultFinanceRate: '<',
                calculatorSliderStepSize: '<',
                affiliateLogoSource: '@',
                financePreferredTerm: "@",
                leasePreferredTerm: "@",
                disclaimers:'<'
            }
        });

    function FcaSummaryCalculatorController($scope, $rootScope, configService) {
        let $summaryCalculatorCtrl = this;

        $summaryCalculatorCtrl.$onInit = () => {

            // Variables from pricing object
            $summaryCalculatorCtrl.basePrice = 0;
            $summaryCalculatorCtrl.freight = 0;
            $summaryCalculatorCtrl.acTax = 0;
            $summaryCalculatorCtrl.greenLevy = 0;
            $summaryCalculatorCtrl.incentives = [];
            $summaryCalculatorCtrl.discountBeforeTax = 0;
            $summaryCalculatorCtrl.discountAfterTax = 0;
            $summaryCalculatorCtrl.discountBeforeTaxTotal = 0;
            $summaryCalculatorCtrl.discountAfterTaxTotal = 0;
            $summaryCalculatorCtrl.discountTotal = 0;
            $summaryCalculatorCtrl.maxPrice = 0;
            $summaryCalculatorCtrl.adminFee = 0;

            // Vehicle attributes
            $summaryCalculatorCtrl.options = 0;
            $summaryCalculatorCtrl.modelYearId = configService.modelYearId;
            $summaryCalculatorCtrl.acode = configService.acode;
            $summaryCalculatorCtrl.packageAndOptionsCode = [];

            $scope.$on('packages-selector:pricing', (event, data) => {
                $summaryCalculatorCtrl.onPackageSelectorPricing(event, data);
            });

            // Reset calculator parameter handler
            $scope.$on('options-selector:reset-calculator-parameters', (event, data) => {
                $summaryCalculatorCtrl.onOptionsSelectorResetCalculatorParameters(event, data);
            });
        };

        /**
         * Called on event 'packages-selector:pricing'
         * Update Calculator parameter with new selected package
         */
        $summaryCalculatorCtrl.onPackageSelectorPricing = (event, data) => {
            // Update the package to change the displayed information
            // Use the monroney pricing
            $summaryCalculatorCtrl.basePrice = parseFloat(data.pricing.base.msrp);
            $summaryCalculatorCtrl.freight = parseFloat(data.pricing.freight.msrp);
            $summaryCalculatorCtrl.acTax = parseFloat(data.pricing.acTax.msrp);
            $summaryCalculatorCtrl.incentives = data.pricing.incentives;
            $summaryCalculatorCtrl.options = parseFloat(data.pricing.options.msrp);
            $summaryCalculatorCtrl.greenLevy = parseFloat(data.pricing.greenLevy.msrp);
            // The adminFee attribute is the value added to the calculation when we are in iframe mode, embedded into a dealer site
            $summaryCalculatorCtrl.adminFee = parseFloat(data.pricing.adminFee);
        };

        /**
         * Called on event 'options-selector:reset-calculator-parameters'
         * 
         * This handler is triggered when an option selection is confirmed (through the window dialog)
         */
        $summaryCalculatorCtrl.onOptionsSelectorResetCalculatorParameters = (event, data) => {
            $summaryCalculatorCtrl.trimName = data.trimName;
            $summaryCalculatorCtrl.nameplateCode = data.nameplateCode;
            $summaryCalculatorCtrl.brandCode = data.brandCode;
            $summaryCalculatorCtrl.acode = data.acode;
            $summaryCalculatorCtrl.modelYearId = data.modelYearId;
            $summaryCalculatorCtrl.year = data.year;
            $summaryCalculatorCtrl.packageAndOptionsCode = configService.extractPackageAndOptionsCode();
            $summaryCalculatorCtrl.scratchSave = data.scratchSave;

            // Triggers updateCalculatorParameters(); function in calculator.component.js
            $rootScope.$broadcast('calculator:set-programs');
        };
    }
})(angular);