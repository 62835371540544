(function() {
	angular
		.module('fcaModelSpecs')
		.directive('fcaModelSpecsCarousel', fcaModelSpecsCarousel);

	function fcaModelSpecsCarousel($timeout, $rootScope) {
		return {
			restrict: 'A',
			require: '^?fcaModelSpecsCategory',
			scope: true,
			bindToController: {
				masterCarousel: '@',
				fixedCarousel: '@',
				analyticscategory: '@',
				analyticsid: '@'
			},
			controllerAs: 'modelSpecsCarousel',
			controller: FcaModelSpecsCarouselController,
			link: FcaModelSpecsCarouselLink
		};

		function FcaModelSpecsCarouselController($rootScope, $scope, $timeout, $element, modelSpecsGrid, matchmedia, FCA_MQ_LAYOUT, $window, gtmAnalytics) { // eslint-disable-line max-len
			'ngInject';

			const $ctrl = this;

			this.slickSlider = $element;

			this.prevSlickArrowLabel = $element.attr('data-arrow-left-label');
			this.nextSlickArrowLabel = $element.attr('data-arrow-right-label');

			this.allStringsIdentical = false;

			this.activeSlideIndexes = [];

			this.buildStrings = () => {
				let numberOfSlides = $element.find('[data-show-difference="true"]').length;
				let currentText = '';
				let $currentSlide = null;
				let currentSlideStrings = [];

				if (numberOfSlides != 0) {
					for (let i = 0; i <= numberOfSlides-1; i++) {
						$currentSlide = angular.element($element.find('td')[i]);
						currentText = $currentSlide.text().trim();
						currentSlideStrings.push(currentText);
					}
					this.allStringsIdentical = this.checkIdenticalValues(currentSlideStrings);
				}
			};

			this.checkIdenticalValues = (stringsArray) => {
				for (let i = 0; i < stringsArray.length; i++) {
					if (stringsArray[i] != stringsArray[0]) {
						return false;
					}
				}
				return true;
			};

			this.initMasterCarousel = () => {

				this.slickSlider = $element.slick({
					arrows: true,
					dots: true,
					prevArrow: `<button type="button" class="slick-prev">${this.prevSlickArrowLabel}</button>`,
					nextArrow: `<button type="button" class="slick-next">${this.nextSlickArrowLabel}</button>`,
					slidesToShow: 3,
					infinite: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
				this.slickSlider.on('init', this.getClickEvents());
			};

			this.initFixedCarousel = () => {
				this.slickSlider = $element.slick({
					arrows: true,
					dots: false,
					slidesToShow: 3,
					infinite: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
				this.slickSlider.on('init', this.getClickEvents(true));
			};

			this.getClickEvents = (isMiniNav = false) => {
				let $arrow = $element.find('.slick-arrow');
				if ($arrow) {
					$arrow.on('click', function () {
						if (this.classList.contains('slick-next')) {
							$ctrl.sendAnalytics('-forward', isMiniNav);
						}
						if (this.classList.contains('slick-prev')) {
							$ctrl.sendAnalytics( '-backward', isMiniNav);
						}
					});
				}
			};

			this.sendAnalytics = (value, isMiniNav = false) => {
				if (this.analyticscategory && this.analyticsid) {
					let addtionalValue = isMiniNav ? '-mininav' : '';
					let analyticsLabel = this.analyticsid + addtionalValue + value;
					gtmAnalytics.trackEvent('event', {
						category: 'App-Suite-' + this.analyticscategory,
						label: analyticsLabel
					});
				}
			};

			this.beforeSlideChange = () => {
				this.slickSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					// GA Tracking
					if (this.masterCarousel) {
						let trackingMessage = 'prev';
						if (nextSlide > modelSpecsGrid.currentSlideIndex) {
							trackingMessage = 'next';
						}

						let triggerValue = 'models-and-specs-' + trackingMessage;

						gtmAnalytics.trackEvent('event', {
							category: 'models and specs',
							label: trackingMessage,
							// used for personalisation scenario 1.1
							trigger: triggerValue
						});
					}

					modelSpecsGrid.masterCarouselChange(nextSlide);
				});
			};

			this.slickSlider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
				this.setAriaHiddenProperty();
			});

			this.setTotalSlides = () => {
				this.slickSlider.on('init', (event, slick) => {
					modelSpecsGrid.setTotalTrims(slick.slideCount);
					this.totalTrims = slick.slideCount;
				});
			};

			this.isDesktop = () => {
				let matchesMedia = false;

				matchmedia.on(FCA_MQ_LAYOUT.LARGE_PLUS, function(mediaQueryList) {
					if (mediaQueryList.matches) {
						matchesMedia = true;
					} else {
						matchesMedia = false;
					}
				});

				return matchesMedia;
			};

			this.calculateNewCarouselPosition = (slideRemoved) => {
				let trimRemoved = Number(slideRemoved);
				let newPosition = 0;

				if (this.isDesktop()) {
					if (trimRemoved <= 3) {
						newPosition = 0;
					} else {
						newPosition = Math.floor(trimRemoved/3);
					}
				}

				this.setCarouselPosition(newPosition);
			};

			this.setCarouselPosition = (position) => {
				this.slickSlider.slick('slickGoTo', position);
			};

			this.requestSlideRemoval = (event) => {
				let $slideToRemove = angular.element(event.currentTarget).parents('.slick-slide');
				let slideIndex = $slideToRemove.attr('data-trim');

				modelSpecsGrid.hideTrim(slideIndex);
				modelSpecsGrid.showResetButton();

				gtmAnalytics.trackEvent('event', {
					category: 'models and specs',
					label: 'close'
				});
			};

			this.setAriaHiddenProperty = () => {
				let _this = this;
				var indexList = [];
				$('.specs-module-tray-item.slick-active').each( function() {
					let $current_item = $(this);
					indexList.push($current_item.attr('data-slick-index'));
				});

				$('.slave-carousel td').each( function() {
					let $current_item = $(this);
					let index = ($current_item.attr('data-trim'));
					if (indexList.includes(index)) {
						$current_item.attr('aria-hidden','false');
						$current_item.find("button").each( function() {
							let $current_item = $(this);
							_this.addFocusTo($current_item);
						});
						$current_item.find("a").each( function() {
							let $current_item = $(this);
							_this.addFocusTo($current_item);
						});
					} else {
						$current_item.attr('aria-hidden','true');
						$current_item.find("button").each( function() {
							let $current_item = $(this);
							_this.removeFocusFrom($current_item);
						});
						$current_item.find("a").each( function() {
							let $current_item = $(this);
							_this.removeFocusFrom($current_item);
						});
					}
				});
			};

			this.removeFocusFrom = (element) => {
				element.attr('tabindex','-1');
				element.attr('aria-hidden','true');
			};

			this.addFocusTo = (element) => {
				element.attr('tabindex','0');
				element.attr('aria-hidden','false');
			};

			this.slaveCarouselsRemovalWatcher = () => {
				$rootScope.$on('modelSpecsGrid.hideTrim', (event, slide, hiddenTrims) => {
					let $slideToFade = $element.find(`[data-trim="${slide}"]`);

					$slideToFade.addClass('fade-away');

					$timeout(() => {
						$slideToFade.css('display', 'none');
					}, 400);
				});
			};

			this.slickCarouselRemovalWatcher = () => {
				let numberOfSlides = $element.find('.slick-slide').length;

				$rootScope.$on('modelSpecsGrid.hideTrim', (event, slide, hiddenTrims) => {
					let slidesToKeep = [];
					let $slideToFade = $element.find(`[data-trim="${slide}"]`);

					for (let i = 0; i <= numberOfSlides-1; i++) {
						if (hiddenTrims.indexOf(i) == -1) {
							slidesToKeep.push(`[data-trim="${i}"]`);
						}
					}

					$slideToFade.addClass('fade-away');
					this.removeSlides(String(slidesToKeep), slide);
				});
			};

			this.removeSlides = (slidesToKeep, slide) => {
				$timeout(() => {
					this.slickSlider.slick('slickUnfilter');
					this.slickSlider.slick('slickFilter', slidesToKeep);

					if (this.masterCarousel && this.isDesktop()) {
						let currentIndex = this.slickSlider.slick('slickCurrentSlide');
						let totalTrims = modelSpecsGrid.totalVisibleTrims;

						if (totalTrims <= 3) {
							this.setCarouselPosition(0);
						} else if (currentIndex > 3) {
							this.setCarouselPosition(totalTrims-3);
						} else {
							this.setCarouselPosition(currentIndex-1);
						}
					}
				}, 425);
			};

			this.resetCarousels = () => {
				this.slickSlider.slick('slickUnfilter');
				this.slickSlider.find('.slick-slide').removeClass('fade-away');

				this.setCarouselPosition(0);
			};

			this.stickyCarousel = () => {
				let fixedNavTrigger = $('.master-carousel').position().top + 450;

				$(document).on('scroll', function() {
					if ($(this).scrollTop() >= fixedNavTrigger) { // eslint-disable-line no-invalid-this
						$('.fixed-model-spec').addClass('is-sticky');
					} else {
						$('.fixed-model-spec').removeClass('is-sticky');
					}
				});
			};

			this.calculateTrimSizes = () => {
				let slidesToShow = 1;

				if (this.isDesktop()) {
					slidesToShow = 3;
				}

				let trimSize = Math.ceil($('.master-carousel').width() / slidesToShow);
				let trimCount = $('.specs-module-tray-item').length;
				let carouselSize = trimCount * trimSize;

				$('.slave-carousel td').css('width', `${trimSize}px`);
				$('.specs-module-carousel').attr('data-current-width', `${trimSize}px`);
				$('tr.slave-carousel').css('width', `${carouselSize}px`);

				if(trimCount === 1) {
					$('tr.slave-carousel').css('margin', 'auto');
					$('tr.slave-carousel td').css('text-align', 'center');
					$('tr.slave-carousel.fixed-over-title td div').addClass('one-trim').css('width', `${trimSize}px`);
				}
			};

			this.windowResizeWatcher = () => {
				this.realTime;
				this.timeoutActive = false;
				this.resizeDelay = 300;

				angular.element($window).on('resize', () => {
					this.realTime = new Date();

					if (this.timeoutActive == false) {
						this.timeoutActive = true;
						$timeout(this.windowWasResized, this.resizeDelay);
					}
				});
			};

			this.windowWasResized = () => {
				if (new Date() - this.realTime < this.resizeDelay) {
					$timeout(this.windowWasResized, this.resizeDelay);
				} else {
					this.timeoutActive = false;
					this.calculateTrimSizes();
					modelSpecsGrid.windowWasResized();
				}
			};

			this.initSlickCarousels = () => {
				this.setTotalSlides();

				if (this.masterCarousel) {
					this.initMasterCarousel();
					this.calculateTrimSizes();
					this.windowResizeWatcher();
					$scope.$broadcast('modelTray.init');
				}

				if (this.fixedCarousel) {
					this.initFixedCarousel();
				}

				this.slickCarouselRemovalWatcher();
				this.beforeSlideChange();
				this.slickCarouselsWatchers();
				this.setAriaHiddenProperty();
			};

			this.slickCarouselsWatchers = () => {
				$rootScope.$on('modelSpecsGrid.resetCarousels', (event, slide) => {
					this.resetCarousels();
				});

				$rootScope.$on('modelSpecsGrid.masterCarouselChange', (event, slide) => {
					this.slickSlider.slick('slickGoTo', slide);
				});

				$rootScope.$on('modelSpecsGrid.totalVisibleTrims', (event, visibleSlidesCount) => {
					this.totalVisibleTrims = visibleSlidesCount;
				});

				$rootScope.$on('modelSpecsGrid.toggleTriggers', (event) => {
					this.calculateTrimSizes();
				});
			};

			this.updateAccordionsHeight = () => {
				$('[data-accordion-container]').each(function() {
					// eslint-disable-next-line no-invalid-this
					if ($(this).height() > 0) {
						// eslint-disable-next-line no-invalid-this
						$(this).css('height', 'auto');
					}
				});
			};

			this.$onInit = () => {
				this.buildStrings();
				this.stickyCarousel();

				if (this.masterCarousel || this.fixedCarousel) {
					$timeout(() => {
						this.initSlickCarousels();
					});
				} else {
					this.slaveCarouselsRemovalWatcher();
				}
			};
		}

		// This watches the "hide identical" toggle
		function FcaModelSpecsCarouselLink(scope, element, attrs, controller) {
			if (controller != null) {
				controller.addToCategory();
			}

			$rootScope.$on('modelSpecsGrid.hideIndenticalTrims', () => {
				if (scope.modelSpecsCarousel.allStringsIdentical && controller != null) {
					controller.hideCategory();
				}

				if (scope.modelSpecsCarousel.masterCarousel) {
					scope.modelSpecsCarousel.updateAccordionsHeight();
				}
			});

			$rootScope.$on('modelSpecsGrid.showIdenticalTrims', () => {
				if (controller != null) {
					controller.showCategory();
				}
			});
		}
	}
})();
