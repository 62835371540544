(function() {
	angular
		.module('fca.brand.minivan')
		.directive('fcaMinivanHero', fcaMinivanHero);

	function fcaMinivanHero($window, $rootScope, $timeout) {
		'ngInject';

		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$fcaMinivanHeroCtrl',
			bindToController: {
				isNameplateCompare: '@',
			},
			controller: fcaMinivanHeroController
		};

		function fcaMinivanHeroController($location, $element, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;
			$ctrl.$postLink = () => {
				if($ctrl.isNameplateCompare) {
					//settings if its the nameplate compare block
					$ctrl.settings = {
						infinite: false,
						dots: true,
						arrows: true,
						autoplay: false,
						slidesToShow: 3,
						responsive: [
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 1
								}
							},
							{
								breakpoint: 1024,
								settings: {
									slidesToShow: 2
								}
							}
						]
					};
				} else {
					//basic settings
					$ctrl.settings = {
						infinite: true,
						dots: true,
						arrows: true,
						autoplay: true,
						autoplaySpeed: 4000
					};
				}
				$element.slick($ctrl.settings);
			};
		}
	}
})();
