(function () {
	angular
		.module('fca.partial')
		.directive('fcaPartialClose', fcaPartialClose);

	function fcaPartialClose() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				zoneName: '@',
				activeNameplate: '@'
			},
			controllerAs: '$partialCloseCtrl',
			controller: FcaPartialCloseCtrl
		};
	}

	function FcaPartialCloseCtrl($rootScope) {
		'ngInject';
		let $partialClose = this;

		$partialClose.closePartial = () => {
			$rootScope.$emit('partial:close-partial');
		}

		$rootScope.$on('subnameplate-change', (event, subNameplate) => {
			$partialClose.activeNameplate = subNameplate;
		})
	}
})();
