(function() {
	angular
		.module('fca.brandApp')
		.directive('vlp2021FuelEngine', vlp2021FuelEngine);

	function vlp2021FuelEngine() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				selectOptions: '<',
				analyticsCategory: '@',
				analyticsId: '@'
			},
			controllerAs: '$vlpFuelEngine',
			controller: vlp2021FuelEngineController
		};

		function vlp2021FuelEngineController($scope, $timeout, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;

			$ctrl.$onInit = () => {
				$scope.selectedTransmission = $ctrl.selectOptions[0];
				$ctrl.activeTransmission = $ctrl.selectOptions[0].value;
			}

			$ctrl.setActiveTransmission = (option) => {
				$timeout(() => {
					$ctrl.activeTransmission = option.value;
					gtmAnalytics.trackEvent('event', {
						category: `${$ctrl.analyticsCategory}`,
						label: 'body-engine-dropdown-transmission ' + $ctrl.analyticsId + " : " + option.name
					});

				});
			}
		}
	}
})();
