(function () {
    angular
        .module('fca.buildAndPrice.colourWheelsSelector')
        .service('colourService', ColourService);

    function ColourService() {

        const language = FCA_SITES_CONFIG.language;
        let sortTable = {};
        // Table used to sort colours as defined by business rules.
        if(language === 'en') {
            sortTable = {
                'White': 10,
                'Off-white': 9,
                'Silver': 8,
                'Grey': 7,
                'Black': 6,
                'Blue': 5,
                'Red': 4,
                'Orange': 3,
                'Yellow': 2,
                'Green': 1,
            };
        } else {
            sortTable = {
                'Blanc': 10,
                'Blanc cassé': 9,
                'Argent': 8,
                'Gris': 7,
                'Noir': 6,
                'Bleu': 5,
                'Rouge': 4,
                'Orange': 3,
                'Jaune': 2,
                'Vert': 1,
            };
        }

        this.getExteriorColoursOptionsSorted = options => {
            options.categories[1].options = this.sortColoursByPriority(options.categories[1].options);

            return options;
        };

        this.getInteriorColoursOptionsSorted = options => {
            options.categories[0].options = this.sortColoursByPriority(options.categories[0].options);

            return options;
        };

        this.sortColoursByPriority = colourOptions => {
            return colourOptions.sort((c1, c2) =>
                this.getTotalSortValueForColour(c2) - this.getTotalSortValueForColour(c1)
            );
        };

        // Return value based on hexa sum number. The number outputed is not decimal value, it's a value used only for sorting.
        // Trying to convert to exact decimal value is longer and useless in that case.
        this.getSortValueForHexa = hexaNumber => {
            let sortValue = 0;

            for (let i = 0; i < hexaNumber.length; i++) {
                sortValue += parseInt(hexaNumber[i], 16);
            }

            return sortValue;
        };

        // Return total value score for a color based on sortTable and on hexa value.
        this.getTotalSortValueForColour = colour => {
            let hexaValue = colour.color.replace('#', '');
            let sortTableValue = sortTable[colour.genericColor];

            let totalSortValue = this.getSortValueForHexa(hexaValue);

            if (!sortTableValue) {
                sortTableValue = 0;
            }

            totalSortValue += sortTableValue * 10000; // Value in sort table get higher priority

            return totalSortValue;
        }
    }
})(angular);