(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaSignUp', fcaSignUp);

	function fcaSignUp() {
		return {
			restrict: 'A',
			controller: FcaSignUpController,
			controllerAs: 'signup',
			scope: true,
			bindToController: {
				signUpSuccess: '=',
				thanksRedirect: '@',
				whenPurchaseNotNeeded: '@',
				divIdInitialEmail: '@',
				currentBrand: '@',
				globalMessageOneError : '@',
				globalMessageManyErrors : '@'
			}
		};

		function FcaSignUpController($scope, $rootScope, $http, $location, gtmAnalytics, matchmedia, FCA_MQ_LAYOUT, $timeout, $cookies, $filter, $translate, fcaUserIdPersistentService) { // eslint-disable-line max-len
			'ngInject';
			this.fieldErrors = {};
			this.hasFieldErrors = false;
			this.submitError = false;
			this.preciseSubmitError = '';
			this.hasBrochure = false;
			this.isBusy = false;
			this.brandCount = [];
			this.pcaControl;
			this.activeBrand = 0;
			this.subscribe = {
				title: '',
				firstName: null,
				lastName: '',
				email: '',
				postalCode: '',
				agree: '',
				addressOne: '',
				addressTwo: '',
				city: '',
				province: '',
				mailActive: false,
				chosenNameplate: [],
				chosenNameplateBrochureByMail: [],
				type: '',
				lang: 'en',
				nameplateCode: '',
				brandCode: '',
				year: '',
				sourceCode: 'WS',
				whenPurchase: '',

				utmCampaign: '',
				utmSource: '',
				utmMedium: '',
				utmContent: '',
				persistentID: ''
			};
			this.contest = {
				email: '',
				language: '',
				nameplateCode: '',
				dealerCode: '',
				brand: '',
				folder: '',
				pinCode: ''
			};
			this.signupContest = {
				agree: '',
				optIn: '',
				firstName: null,
				lastName: '',
				phoneNumber: '',
				email: '',
				postalCode: '',
				language: '',
				nameplateCode: '',
				nameplateCodes: [],
				extraBallot: '',
				dealerCode: '',
				whenPurchase: '',
				brand: '',
				folder: '',
				pinCode: '',
				srcSrc: '',

				utmCampaign: '',
				utmSource: '',
				utmMedium: '',
				utmContent: ''
			};

			this.errorMsgVisible = false;
			this.globalMessage = '';
			this.currentLocale = '';

			this.$onInit = () => {
				// pca -> Canada Post AddressComplete
				if (window.pca) this.initPca();
				matchmedia.on(FCA_MQ_LAYOUT.MOBILE, (mediaQueryList) => {
					if (mediaQueryList.matches) {
						this.subscribe.sourceCode = 'MB';
					} else {
						this.subscribe.sourceCode = 'WS';
					}
				});

				if (this.divIdInitialEmail) {
					this.subscribe.email = $("#" + this.divIdInitialEmail).val();
				}

				$scope.$on('vlp:initialize-hr-form-overlay',
						(event, data) => {
							if (this.divIdInitialEmail) {
								this.subscribe.email = $("#" + this.divIdInitialEmail).val();
							}
						});

				this.selectDefaultBrand();
				this.selectDefaultNameplate();
			};

			this.selectDefaultBrand = () => {
				let defaultBrandId = $(`[data-brand-name="${this.currentBrand}"]`).attr('data-brand-id');

				if (defaultBrandId != undefined) {
					this.onSelectBrand(Number(defaultBrandId), this.currentBrand);
				}
			};

			this.selectDefaultNameplate = () => {
				let urlParams;

				if($location.search().nameplate) {
					urlParams = $location.search().nameplate;
				} else if($location.search().nameplateCode) {
					urlParams = $location.search().nameplateCode;
				} else if($location.search().nameplaceCode) {
					urlParams = $location.search().nameplaceCode;
				}

				$timeout(function() {
					if ($(`#nameplateCheckboxId-${urlParams}`).length) {
						$(`#nameplateCheckboxId-${urlParams}`).click();
					}
				});
			};

			this.initPca = (options = {}) => {
				let pcaFields = [
					{element: 'addressOne', field: 'Line1'},
					{element: 'addressTwo', field: 'Line2', mode: pca.fieldMode.POPULATE},
					{element: 'city', field: 'City', mode: pca.fieldMode.POPULATE},
					{element: 'province', field: 'ProvinceName', mode: pca.fieldMode.POPULATE},
					{element: 'postalCode', field: 'PostalCode', mode: pca.fieldMode.POPULATE},
					{element: 'postalCode-1', field: 'PostalCode', mode: pca.fieldMode.POPULATE}
				];
				let pcaOptions = angular.extend({}, window.FCA_SITES_CONFIG.pca.options, options);
				this.pcaControl = new pca.Address(pcaFields, pcaOptions);
				this.pcaControl.listen('populate', this.onPcaPopulate);
			};

			this.onPcaPopulate = (address) => {
				this.subscribe.addressOne = address.Line1;
				this.subscribe.addressTwo = address.Line2;
				this.subscribe.city = address.City;
				this.subscribe.province = address.ProvinceCode;
				this.subscribe.postalCode = address.PostalCode;
			};

			this.updateHasBrochure = () => {
				this.hasBrochure = false;
				angular.forEach(this.subscribe.chosenNameplateBrochureByMail, (value, key) => {
					if (value.mailMeBrochure == true) {
						this.hasBrochure = true;
					}
				});
			};

			this.onSelectBrand = (indexBrand, brandName) => {
				this.activeBrand = indexBrand;
			};

			this.updateBrandCount = (brandIndex, doAdd) => {
				if (this.brandCount[brandIndex] == null) this.brandCount[brandIndex] = 0;
				this.brandCount[brandIndex] += doAdd ? 1 : -1;
			};

			this.modifyNameplate = (doAdd, nameplate, brandIndex, forceMail = false, brandName) => {
				if (doAdd) {
					this.subscribe.chosenNameplate.push({
						nameplateCode: nameplate,
						mailMeBrochure: forceMail,
						brand: brandName
					});
					this.sendGAevent(`${brandName}-${nameplate}-add`);
				} else {
					angular.forEach(this.subscribe.chosenNameplate, (value, key) => {
						if (value.nameplateCode === nameplate) {
							this.subscribe.chosenNameplate.splice(key, 1);
						}
					});
					this.sendGAevent(`${brandName}-${nameplate}-remove`);
				}

				this.updateBrandCount(brandIndex, doAdd);
			};

			this.modifyBrochure = (doAdd, nameplate) => {
				if (doAdd) {
					this.subscribe.chosenNameplateBrochureByMail.push({
						nameplateCode: nameplate,
						mailMeBrochure: true
					});
				} else {
					angular.forEach(this.subscribe.chosenNameplateBrochureByMail, (value, key) => {
						if (value.nameplateCode === nameplate) {
							this.subscribe.chosenNameplateBrochureByMail.splice(key, 1);
						}
					});
				}

				this.updateHasBrochure();
			};

			this.submitAjax = (currentLocale, type, brandCode) => {
				this.isBusy = true;
				this.subscribe.type = type;
				this.errorMsgVisible = false;
				this.currentLocale = currentLocale;

				if (type === 'gab') {
					this.subscribe.mailActive = true;
				}

				let postUrl;
				let postObject;

				// recover utm parameters
				let urlParams = $location.search();

				if (type === 'contest') {
					postUrl = window.FCA_SITES_CONFIG.urls.contestSignUpPost[currentLocale];
					postObject = this.contest;
				} else if(type === 'signupContest') {
					// recover utm parameters
					let urlParams = $location.search();

					this.hasCampaign = urlParams.utm_campaign;
					this.currentCampaign = {};

					if (this.hasCampaign) {
						this.signupContest.utmCampaign = urlParams.utm_campaign || '';
						this.signupContest.utmSource = urlParams.utm_source || '';
						this.signupContest.utmMedium = urlParams.utm_medium || '';
						this.signupContest.utmContent = urlParams.utm_content || '';
						this.signupContest.utmAudience = urlParams.utm_utmAudience || '';
					}

					// apply filter for phone number
					if (this.signupContest.phoneNumber) {
						this.signupContest.phoneNumber = $filter('fcaTel')(this.signupContest.phoneNumber, false)
					}

					postUrl = window.FCA_SITES_CONFIG.urls.dealerEventContestSignUpPost[currentLocale];
					postObject = this.signupContest;
				} else {
					postUrl = window.FCA_SITES_CONFIG.urls.signUpPost[currentLocale];

					//set utm parameters
					this.setUrlParameters(urlParams, this.subscribe);

					postObject = this.subscribe;
					if (this.whenPurchaseNotNeeded) {
						this.subscribe.whenPurchaseNotNeeded = true;
					}
					if (this.choiceOfVehicle.length > 0) {						
						this.subscribe.nameplateCode = this.choiceOfVehicle.map(vehicle => vehicle.nameplateCode).join(',');
						this.subscribe.brandCode = this.choiceOfVehicle.map(vehicle => vehicle.brandCode).join(',');
						this.subscribe.year = this.choiceOfVehicle.map(vehicle => vehicle.year).join(',');
					}
				}
				
				this.subscribe.lang = currentLocale;

				let campaignID = $cookies.get('nurunCampaignID');

				if (campaignID != null) {
					this.subscribe.campaignID = campaignID;
				}

				// updating userId cookie with persistentId
				if (postObject.email != "" && ( postObject.type == "signup" || postObject.type == "hrforms")) {
					fcaUserIdPersistentService.getUserId(postObject.email, this.getPersistentId, (error) => console.error(error),postUrl, postObject);
				} else {
					this.postAjax(postUrl,postObject);
				}
			};

			this.showGlobalErrorMessage = () => {
				this.errorCount = Object.keys(this.fieldErrors).length;
				this.errorMsgVisible = this.errorCount > 0;

				if (this.errorMsgVisible) {
					let globalMessageKey = (this.errorCount == 1)? this.globalMessageOneError : this.globalMessageManyErrors;
					this.globalMessage = $translate.instant(globalMessageKey, { errorCount: this.errorCount });
				}

				$timeout(() => {
					$('.signup-errors').focus();
					$rootScope.$broadcast('sign-up-submit-failed');
				});
			};

			this.sendGAevent = (GAlabel, GAcategory = 'vehicle signup', GAaction = $location.url()) => {
				gtmAnalytics.trackEvent('event', {
					category: GAcategory,
					label: GAlabel
				});
			};

			$rootScope.$on('form-vehicle-selector:nameplate-selected',
				(event, data) => {
					this.signupContest['nameplateCode'] = data.nameplate;
					this.signupContest['brand'] = data.brandCode;
					this.signupContest['year'] = data.year;
				});

			$rootScope.$on('autoshow-contest-chosen-nameplates', (event, data) => {
				this.signupContest.nameplateCodes = data.chosenNameplates;
				console.log(this.signupContest.nameplateCodes);
			});

			this.setUrlParameters = (urlParams, postObject) => {
				let hasCampaign = urlParams.utm_campaign;
				if (hasCampaign) {
					postObject.utmCampaign = urlParams.utm_campaign || '';
					postObject.utmSource = urlParams.utm_source || '';
					postObject.utmMedium = urlParams.utm_medium || '';
					postObject.utmContent = urlParams.utm_content || '';
					postObject.utmAudience = urlParams.utm_utmAudience || '';
				}
			}

			this.getPersistentId = (uid, postUrl, postObject) => {
				if (uid != null && uid != '') {
					//setting the parameter in the post object
					this.subscribe.persistentID = uid;

					//allows to save the userId in case we clear the cookie
					sessionStorage.setItem("persistentID", uid);

					this.postAjax(postUrl,postObject);
				}
			}

			this.redirectMe = () => {
				window.location.href=this.thanksRedirect;
			}

			this.postAjax = (postUrl,postObject) => {
				let type = postObject.type;
				let currentLocale = this.currentLocale;
				$http.post(postUrl, postObject, {})
					.then((data) => {
						if(type === 'signup' || type === 'gab') {
							let result = Object.keys(data.data.chosenNameplate).map((k) => {
								return data.data.chosenNameplate[k].nameplateCode;
							});
							$rootScope.$broadcast('sign-up-submit-has-succeed');
							this.sendGAevent('submit form');
							let redirectUrl = window.FCA_SITES_CONFIG.urls.signUpThankYou[currentLocale];
							window.location = redirectUrl +'?data='+JSON.stringify(result);
						} else if(type === 'signupContest') {
							if (this.signUpSuccess !== null) {
								this.signUpSuccess();
							}
							$rootScope.$broadcast('sign-up-submit-has-succeed');
							this.sendGAevent('submit form');
						} else {
							if (this.signUpSuccess !== null) {
								this.signUpSuccess();
								if ((this.thanksRedirect) && (this.thanksRedirect != "undefined") && (this.thanksRedirect != "")) {
									setTimeout(this.redirectMe,10000);
								}
								if (type === 'contest') {
									let contestSuccessPin = data.data.pin;
									$rootScope.contestPin = contestSuccessPin;
									$rootScope.$broadcast('setContestPin');
								}
							}
							$rootScope.$broadcast('sign-up-submit-has-succeed');
							this.sendGAevent('submit form');
						}
					})

					.catch((data, status, headers, config) => {
						if (typeof data !== 'undefined' && typeof data.data !== 'undefined') {
							this.fieldErrors = data.data.fieldErrorMessages;
							this.submitError = data.data.errorMessage;
							let errorsCount = Object.keys(this.fieldErrors).length;

							if (this.language === "fr") {
								if (errorsCount === 1) {
									this.preciseSubmitError = 'Vous avez ' + errorsCount + ' erreurs dans le formulaire présentement. Veuillez la corriger afin de continuer';
								} else {
									this.preciseSubmitError = 'Vous avez ' + errorsCount + ' erreur dans le formulaire présentement. Veuillez la corriger afin de continuer';
								}
							} else {
								if (errorsCount === 1) {
									this.preciseSubmitError = 'You have ' + errorsCount + ' error in your form. Please correct it before submitting.';
								} else {
									this.preciseSubmitError = 'You have ' + errorsCount + ' errors in your form. Please correct them before submitting.';
								}
							}

							if (typeof this.fieldErrors !== 'undefined') {
								if(Object.keys(this.fieldErrors).length > 0) {
									this.hasFieldErrors = true;
									this.showGlobalErrorMessage();
								} else {
									this.hasFieldErrors = false;
								}
							}
							this.isBusy = false;
						}
					});
			}

			this.choiceOfVehicle = [];

			this.toogleCheckbox = (nameplateCode, year, brandCode) => {
				this.resetChoiceFields();
				let index = this.choiceOfVehicle.findIndex( 
					(vehicle) => 	vehicle.nameplateCode === nameplateCode && 
									vehicle.year === year && 
									vehicle.brandCode === brandCode
				);			
				if( index === -1 ) {					
					this.choiceOfVehicle.push( {
						nameplateCode: nameplateCode,
						year: year,
						brandCode: brandCode,
					});
				} else {
					this.choiceOfVehicle.splice(index, 1);
				}
			}

			this.toogleRadioButton = (nameplateCode, year, brandCode) => {			
				this.resetChoiceFields();
				this.choiceOfVehicle.length = 0;		
				this.choiceOfVehicle.push({
					nameplateCode: nameplateCode,
					year: year,
					brandCode: brandCode,
				});
			}

			this.resetChoiceFields = (nameplateCode = '', brandCode = '', year = '') => {
				this.subscribe.nameplateCode = nameplateCode;
				this.subscribe.brandCode = brandCode;
				this.subscribe.year = year;
			}
		}
	}
})();