(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaMasterFeaturedOffer', fcaMasterFeaturedOffer);

	function fcaMasterFeaturedOffer() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'masterFeaturedOffer',
			controller: FcaMasterFeaturedOfferController,
		};

		function FcaMasterFeaturedOfferController($element) {
			'ngInject';

			this.activeYear = 0;
			this.showTile = true;

			this.$postLink = () => {
				/* Show the year that contains a featured offer first */
				const nameplateOffers = $element.find("[data-is-featured-offer]");
				for (let i = 0; i < nameplateOffers.length; i++) {
					const offerTile = nameplateOffers[i];
					if ( offerTile.getAttribute('data-is-featured-offer') === "true" ) {
						this.setActiveYear( parseInt(offerTile.getAttribute('data-model-year')) );
						return;
					}
				}
			};

			this.setActiveYear = (year) => {
				this.activeYear = year;
			};
		}
	}
})();
