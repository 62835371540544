(function() {
    'use strict';

    angular
        .module('fca.salesAppointmentMap', [
                'fca.gmap'
            ])
        .controller('appointmentMapCtrl', function() {
            'ngInject';

            /**
             * [dealers description]
             * @type {Array}
             */
            this.dealers = [];

            /**
             * [location description]
             * @type {[type]}
             */
            this.location = null;

            this.markerSize = null;

            /**
             * [selected description]
             * @type {[type]}
             */
            this.selected = null;

            this.options = {
                draggable: false,
                zoomControl: false,
                maxZoomLevel: 18,
                zoomLevel: 15,
                clickable: false,
                responsive: true,
                markerActive: true,
                withLabelIndex: false,
                withInfoWindow: false,
                fitWithingBounds: false,
                hasSingleMarkerStyle: true,
            };

            this.$onInit = () => {
                this.initialize();
            };

            this.$onChanges = (o) => {
                this.initialize();
            };

            this.initialize = () => {
                if (this.isReady()) {
                    let lat = this.latitude;
                    let lng = this.longitude;
                    let dealer = {
                        latitude: lat,
                        longitude: lng
                    };

                    this.dealers.push(dealer);
                    this.location = dealer;
                    this.selected = dealer;
                }
            };

            this.isReady = () => {
                let isReady = false;
                if (this.latitude && this.longitude) {
                    if (this.latitude !== '' && this.longitude !== '') {
                        isReady = true;
                    }
                }

                return isReady;
            };
        })
        .component('appointmentMap', {
            controller: 'appointmentMapCtrl',
            controllerAs: '$appointmentMapCtrl',
            templateUrl: '/sales-appointment/map.html',
            bindings: {
                latitude: '@',
                longitude: '@',
                markerSize: '@',
            }
        });
})();
