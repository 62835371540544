(function () {
	angular
		.module('fca.buyOnlineAccessories')
		.service('fcaBuyOnlineAccessoriesService', FcaBuyOnlineAccessoriesService);

	function FcaBuyOnlineAccessoriesService($http, fcaBuyOnlineService) {
		'ngInject';
		let $service = this;

		$service.getAccessoriesSelection = (vin) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			let accessories = checkout.selectedAccessories;
			return accessories;
		};

		$service.updateAccessoriesSelection = (vin, accessories) => {
			let checkout = fcaBuyOnlineService.getFromStorage(vin);
			checkout.selectedAccessories = accessories;
			// Saving to session storage.
			fcaBuyOnlineService.setInStorage(vin, checkout);
		};
	}
})();
