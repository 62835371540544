(function() {
	angular
		.module("alfaromeo.buildAndPrice.exterior")
		.component("alfaExterior", {
			controller: alfaExterior,
			controllerAs: "$ctrl",
			templateUrl:
				"/brand-specific/alfaromeo/components/director/exterior/alfaromeo-exterior.html",
			bindings: {
				jellyData: "<",
				optionBaseUrl: "@",
				sections: "<",
				onSelect: "<",
				placeholderImage: "@",
				generateAltText: "@",
				title: "@"
			}
		});

	function alfaExterior(
		$scope,
		alfaAnimation,
		alfaPreloadStrategy,
		alfaJellyBuilder,
		alfaJellyLoader,
		povHelper
	) {
		"ngInject";

		const $ctrl = this;

		const desktopMediaQuery = "(min-width: 1024px)";

		// We don't want to be in the business of fighting the user for control
		// of the jelly angle. To that end, we track if the user has manually
		// rotated the vehicle. Once/if the user has changed the angle, we no
		// longer run the scrolling animation.
		$ctrl.userHasChangedPov = false;
		// For the rotation animation to be smooth, the images must be already
		// loaded in to memory. Simply relying on the browser cache isn't
		// sufficient, as the time to load the images from disk causes jerkiness
		// when rapidly changing the angle of the car. We instead force the
		// images to be in memory by keeping references to them in the image
		// cache. See alfaromeo-jelly-loader for associated code.
		$ctrl.imageCache = {};
		// The preload key is a sample jelly url that allows us to compare sets
		// of jelly data. There are many options inside the jelly data that can
		// change without affecting the actual iris image; to make sure that we
		// aren't unnecessarily blowing the image cache, we check the preload
		// keys when the jelly data changes to see if we need to load new images.
		//
		// The other usage for the preload key is differentiating load events.
		// Imagine that the user selects a yellow exterior color and then
		// immediately after the red color. To avoid accidentally polluting the
		// image cache with yellow jellies, we check that the preload key of the
		// load event matches the current preload key.
		$ctrl.preloadKey = null;
		const preloadPov = povHelper.povMin;

		const getPreloadKey = jellyData => alfaJellyBuilder.jellyUrl(
			jellyData, preloadPov
		);

		const exteriorOptionsChanged = newJellyData =>
			!$ctrl.jellyData || getPreloadKey(newJellyData) !== $ctrl.preloadKey;

		const preloadViewpoints = (jellyData, viewpoints, currentPov) => {
			$ctrl.imageCache = {};
			const urlBuilder = pov => $ctrl.buildJellyUrl(jellyData, pov);

			alfaJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				currentPov,
				viewpoints,
				$ctrl.onPovLoad
			);
		}

		$ctrl.loadPov = pov => {
			const urlBuilder = pov => $ctrl.buildJellyUrl($ctrl.jellyData, pov);
			alfaJellyLoader.loadImages(
				$ctrl.preloadKey,
				urlBuilder,
				pov,
				[],
				$ctrl.onPovLoad
			);
		}

		const fullPreload = (jellyData, currentPov) => {
			const strategy = $ctrl.isDesktop() ?
				alfaPreloadStrategy.preloadStrategies.animation:
				alfaPreloadStrategy.preloadStrategies.ofInterest;

			const viewpoints = alfaPreloadStrategy.generateViewpoints(strategy);

			preloadViewpoints(jellyData, viewpoints, currentPov);
		}

		$ctrl.$onChanges = changes => {
			if (!changes.jellyData || !changes.jellyData.currentValue) {
				return;
			}

			const jellyData = changes.jellyData.currentValue;

			// Check if the vehicle's exterior options have been changed (wheels,
			// color, etc.)
			if (!exteriorOptionsChanged(jellyData)) return;

			// If we need to download new jellies, we need to
			//   1. Set a new preload key, to differentiate the jellies we are
			//      about to load from previous jellies that may still be
			//      loading.
			$ctrl.preloadKey = getPreloadKey(jellyData);
			//   2. Reset our image cache, as we are about to download a new set
			//      of jellies.
			$ctrl.imageCache = {};

			// If the user has manually changed the angle of the car, the
			// rotation animations will not be run and thus there is no need to
			// preload the animation images. In this case, the individual jelly
			// components will notice that the image cache is empty and request
			// that we load their povs.
			if (!$ctrl.userHasChangedPov) {
				fullPreload(jellyData);
			}
		}

		const jellyAnimationHeight = 60;
		const jellyAnimationOffset = 350;

		const categoryAnimationHeight = 90;
		const categoryAnimationOffset = 200;

		$ctrl.jellyAnimationStates = {
			front: {
				elemId: null,
				properties: {
					pov: 33,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			},
			side: {
				elemId: 'exterior-side',
				properties: {
					pov: 28,
					scale: 1.4,
					translateX: -6,
					translateY: -4,
				}
			},
			rear: {
				elemId: 'exterior-rear',
				properties: {
					pov: 23,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			}
		};

		const jellyAnimationArray = Object.values($ctrl.jellyAnimationStates);
		$ctrl.jellyAnimationZones = alfaAnimation.createJellyZones(
			jellyAnimationArray, jellyAnimationHeight, jellyAnimationOffset
		);

		$ctrl.mobileJellyAnimationStates = {
			front: {
				elemId: null,
				properties: {
					pov: 33,
					scale: 1,
					translateX: -2,
					translateY: 0,
				}
			},
			side: {
				elemId: 'exterior-side',
				properties: {
					pov: 28,
					scale: 1,
					translateX: -12,
					translateY: 2,
				}
			},
			rear: {
				elemId: 'exterior-rear',
				properties: {
					pov: 23,
					scale: 1,
					translateX: 0,
					translateY: 0,
				}
			}
		};

		let categoryAnimationZones;
		$ctrl.$onInit = () => {
			const lastCategoryIds = Array.from($ctrl.sections.keys())
				.map(sectionCode => 'last-exterior-' + sectionCode);

			categoryAnimationZones = alfaAnimation.createCategoryZones(
				lastCategoryIds, categoryAnimationHeight, categoryAnimationOffset
			);
		}

		$ctrl.$postLink = () => {
			window.addEventListener('scroll', $ctrl.categoryAnimationListener);
		}

		let currentAnimationZone;
		$ctrl.categoryAnimationListener = () => {
			if ($ctrl.isDesktop()) return;

			const animationZone = alfaAnimation.findActiveAnimationZone(categoryAnimationZones);

			if (!animationZone) {
				if (currentAnimationZone) {
					$ctrl.animateCategory();
					currentAnimationZone = null;
				}

				return;
			}

			if (!currentAnimationZone) {
				currentAnimationZone = animationZone
				currentAnimationZone.startAnimation();
			}

			$ctrl.animateCategory();
		}

		$ctrl.animateCategory = () => {
			const categoryElem = currentAnimationZone.elem();
			const opacity = currentAnimationZone.interpolateProperty('opacity');

			requestAnimationFrame(() => categoryElem.style.opacity = opacity)
		}

		$ctrl.sectionObj = () => {
			const sectionObj = {};

			$ctrl.sections.forEach((section, sectionCode) => {
				if (!section.categories || !section.categories.length) {
					return;
				}

				sectionObj[sectionCode] = section;

				const { last, allButLast } =
					$ctrl.splitCategories(section.categories);

				section.allCategoriesButLast = allButLast;
				section.lastCategory = last;
			});

			return sectionObj;
		}

		$ctrl.splitCategories = categories => {
			const categoriesCopy = categories.slice(0);

			return {
				last: categoriesCopy.pop(),
				allButLast: categoriesCopy
			};
		}

		let desktopMode = null;
		$ctrl.setDesktopMode = matchEvent => {
			desktopMode = matchEvent.matches;
		}

		$ctrl.isDesktop = () => {
			if (desktopMode != null) return desktopMode;

			const mediaMatch = window.matchMedia(desktopMediaQuery);

			mediaMatch.addListener(matchEvent => $scope.$apply(
				() => $ctrl.setDesktopMode(matchEvent)
			));

			$ctrl.setDesktopMode(mediaMatch);
			return desktopMode;
		}

		$ctrl.onSelectOption = option => {
			$ctrl.onSelect(option);
		};

		$ctrl.onPovLoad = loadedImage => {
			if (loadedImage.preloadKey !== $ctrl.preloadKey) {
				return;
			}

			$scope.$apply(() => {
				const newImageCache = Object.assign(
					{ [loadedImage.pov]: loadedImage }, $ctrl.imageCache
				);

				$ctrl.imageCache = newImageCache;
			});
		}

		$ctrl.buildJellyUrl = (jellyData, pov) => {
			if (!jellyData) {
				return;
			}

			const jellyOptions = Object.assign(
				{ 'pov': povHelper.format(povHelper.wrap(pov)) },
				jellyData
			);
			return alfaJellyBuilder.jellyUrl(jellyOptions);
		}

		$ctrl.userPovSelect = () => {
			$ctrl.userHasChangedPov = true;
		}
	}
})();
