/* eslint-disable indent */
(function(ng) {
	'use strict';

	/**
	 * @ngdoc overview
	 * @name fca.dealerLocator
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	angular
		.module('fca.dealerLocator', [
			'fca.gmap',
			'fca.dealersPageList',
			'fca.dealerItem',
			'fca.pageLayout',
			'fca.gtmAnalytics'
		]);
})(angular);
