(function() {
	'use strict';

	angular
		.module('fca.geolocator')
		.service('urlRegionMapping', UrlMappingService);

	function UrlMappingService(translateConfigurator, regionMappingService) {
		'ngInject';

		let currentLanguage = translateConfigurator.language;

		// url patern to determinate the region; the region value is the 3rd group in the regex.
		const URL_PATTERN = "(en|fr)/(current-offers|offres-courantes)/([^/.]*)(.*)";

		//isMoparPage => determine if URL is for current offers or Mopar offers
		const isMoparPage = document.querySelector('body').classList.contains('mopar-page-redesign');

		let urls;

		if (isMoparPage) {
			urls = {
				ontario: [
					'/en/mopar/offers/ontario',
					'/fr/mopar/offres/ontario'
				],
				quebec: [
					'/en/mopar/offers/quebec',
					'/fr/mopar/offres/quebec'
				],
				pacific: [
					'/en/mopar/offers/pacific',
					'/fr/mopar/offres/pacifique'
				],
				atlantic: [
					'/en/mopar/offers/atlantic',
					'/fr/mopar/offres/atlantique'
				],
				prairies: [
					'/en/mopar/offers/prairies',
					'/fr/mopar/offres/prairies'
				],
				alberta: [
					'/en/mopar/offers/alberta',
					'/fr/mopar/offres/alberta'
				]
			};
		} else {
			urls = {
				ontario: [
					'/en/current-offers/ontario',
					'/fr/offres-courantes/ontario'
				],
				quebec: [
					'/en/current-offers/quebec',
					'/fr/offres-courantes/quebec'
				],
				pacific: [
					'/en/current-offers/pacific',
					'/fr/offres-courantes/pacifique'
				],
				atlantic: [
					'/en/current-offers/atlantic',
					'/fr/offres-courantes/atlantique'
				],
				prairies: [
					'/en/current-offers/prairies',
					'/fr/offres-courantes/prairies'
				],
				alberta: [
					'/en/current-offers/alberta',
					'/fr/offres-courantes/alberta'
				]
			};
		}

		let localizedRegion = {
			ontario: [
				'ontario',
				'ontario'
			],
			quebec: [
				'quebec',
				'quebec'
			],
			pacific: [
				'pacific',
				'pacifique'
			],
			atlantic: [
				'atlantic',
				'atlantique'
			],
			prairies: [
				'prairies',
				'prairies'
			],
			alberta: [
				'alberta',
				'alberta'
			]
		};

		this.getRegionByUrl = (url) => {
			let region = null;
			for (let r in urls) {
				if (urls[r]) {
					let regionUrls = urls[r];
					for (let i = 0, l = regionUrls.length; i < l; i++) {
						let current = regionUrls[i];
						if (url.indexOf(current) !== -1) {
							region = r;
							break;
						}
					}
				}
			}

			return region;
		};

		this.getLocalizedRegionNameByUrl = (url, language) => {
			let region = this.getRegionByUrl(url);
			let regionLocalized = null;
			for (let r in localizedRegion) {
				if (localizedRegion[r]) {
					let regionValues = localizedRegion[r];
					if (regionValues[0] == region) {
						if (language == 'fr') {
							regionLocalized = regionValues[1];
						} else {
							regionLocalized = regionValues[0];
						}
						break;
					}
				}
			}

			return regionLocalized;
		};

		this.getUrlByRegion = (pRegion) => {
			let regions = Object.keys(urls);
			let index = (window.FCA_SITES_CONFIG.language === 'en') ? 0 : 1;
			let url = urls[regions[0]][index];

			if (regions.indexOf(pRegion) !== -1) {
				url = urls[pRegion][index];
			}

			return url;
		};

	}
})();
