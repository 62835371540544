(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator.cookie')
		.service('cookieLocation', CookieLocation);

	function CookieLocation(
		GeolocatorCookie,
		geolocatorCookieExpire,
		externalConfigLoader) {
		'ngInject';

		const cookieName = externalConfigLoader.loadConfig('FCA_SITES_CONFIG').getConfig('locationCookieName');
		const EXPIRES = geolocatorCookieExpire.getDefault();
		const COOKIE_CONNECTOR = new GeolocatorCookie(cookieName, EXPIRES);

    // Return current stored location or null
		this.getLocation = () => {
			return COOKIE_CONNECTOR.get();
		};

		// Set the current location in cookie
		this.setLocation = (locParam) => {
			return COOKIE_CONNECTOR.set(locParam);
		};

		// Check if cookie exists
		this.isCookieExists = () => {
			return COOKIE_CONNECTOR.isCookieExists();
		};
	}
})(angular);
